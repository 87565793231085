// /**=====================
//   3.13 Dashboard_default CSS Start
// ==========================**/
@each $badge-light-name,
$badge-light-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $badge-light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .badge-light-#{$badge-light-name} {
    background-color: lighten($badge-light-color, 38%);
    color: $badge-light-color;
  }
}
.badge-light-light {
  background-color: lighten($badge-light-color, 55%);
}
.badge-light-primary {
  background-color: rgba($primary-color, 0.1);
}
.badge-light-success {
  background-color: rgba($success-color, 0.15);
}
.badge-light-secondary {
  background-color: rgba($secondary-color, 0.1);
}
.badge-light-danger {
  background-color: rgba($danger-color, 0.1);
}
.notification {
  .recent-images {
    ul {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
    }
    li {
      padding-bottom: 22px;
      border: 1px dashed var(--recent-dashed-border);
      padding: 3px;
      border-radius: 2px;
    }
  }
  ul {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      border: 1px dashed $theme-body-sub-title-color;
      opacity: 0.3;
      top: 12px;
      left: 2px;
      height: calc(100% - 12px);
      [dir="rtl"] & {
        left: unset;
        right: 2px;
      }
    }
  }
  div[class*="activity-dot-"] {
    margin-top: 3px;
    animation: round 1.3s ease-in-out infinite;
  }
  .card {
    .d-flex {
      .w-100 {
        p {
          .badge {
            color: $white;
            margin-left: 10px;
            [dir="rtl"] & {
              margin-left: unset;
              margin-right: 10px;
            }
            font-weight: 500;
          }
        }
        h6 {
          margin-bottom: 5px;
          position: relative;
          ~p {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-emphasis: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 260px;
            @media (max-width: 767px) {
              -webkit-line-clamp: unset;
              text-emphasis: unset;
              white-space: unset;
              text-overflow: unset;
              display: block;
              width: unset;
              overflow: visible;
            }
          }
          @keyframes round {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }
        }
        span {
          color: $theme-body-sub-title-color;
        }
      }
      &:nth-child(2) {
        .media-body {
          h6 {
            .dot-notification {
              background-color: $warning-color;
              border: 5px solid lighten($warning-color, 30%);
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  .date-content {
    padding: 4px 8px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .notification {
    li {
      padding-bottom: 20px;
    }
    .card {
      .card-body {
        .d-flex {
          .w-100 {
            p {
              margin: 0;
            }
            h6 {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
.default-dashboard {
  div.dataTables_wrapper {
    position: unset;
    .dataTables_length {
        margin-bottom: 26px;
        label {
            display: none !important;
        }
    }
    div.dataTables_filter {
        position: absolute;
        top: 10px;
        right: 53px;
        @media (max-width: 575px) {
          top: 44px;
          left: 20px;
          text-align: start;
          [dir="rtl"] & {
            left: unset !important;
            right: 20px !important;
        }
        }
        [dir="rtl"] & {
            left: 23px;
            right: unset;
        }
        input[type=search] {
            width: 150px !important;
            height: 34px;
            border-radius: 6px;
            border-style: solid;
        }
    }
    table.dataTable {
        margin-top: 13px !important;
        border: none;
        @media (max-width: 575px) {
            margin-top: 42px !important;
        }
        &.display {
            tbody {
                tr.odd,
                tr.even {
                    background-color: transparent !important;
                    >.sorting_1 {
                        background-color: transparent !important;
                    }
                }
            }
        }
        thead {
            background-color: $header-light-bg;
            >tr {
                >th {
                    border: none !important;
                    text-transform: capitalize;
                    font-family: $font-outfit;
                    font-weight: 500;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    &:before,
                    &:after {
                        display: none;
                    }
                    [dir="rtl"] & {
                        padding-right: 0px !important;
                    }
                    &:first-child {
                      padding-left: 24px;
                      [dir="rtl"] & {
                        padding-left: unset;
                        padding-right: 24px !important;
                      }
                    }
                    &:last-child {
                        padding-left: 24px;
                    }
                }
            }
        }
        tbody {
            >tr {
                >td {
                    font-family: $font-outfit;
                    font-weight: 500;
                    &:first-child {
                        padding-left: 24px;
                        [dir="rtl"] & {
                          padding-left: unset;
                          padding-right: 24px;
                        }
                    }
                    &:last-child {
                        .btn {
                          border-radius: 25px;
                          padding: 7px 27px;
                          min-width: 50px;
                        }
                    }
                }
            }
        }
    }
  }
  .welcome-card {
      &:hover {
        transform: translateY(-5px);
      }
      .card-body {
          background-image: url(../../assets/images/dashboard/bg.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: block;
          border-radius: 5px;
      }
      h1 {
          color: $white;
      }
      p {
          font-size: 16px;
          margin-top: 5px;
          margin-bottom: 0;
          color: $font-light;
      }
      .btn {
          margin-top: 25px;
          border: 1px solid $white;
          color: $white;
          padding: 12px 25px;
      }
  }
  .total-earning {
      &:hover {
        transform: translateY(-5px);
      }
      .d-flex {
        align-items: center;
      }
      h3 {
          font-size: 16px;
          font-weight: 500;
      }
      h5 {
        font-weight: 500;
        font-size: 24px;
        margin-top: 5px;
      }
      span {
        color: $theme-body-sub-title-color;
        font-size: 18px;
        font-weight: 400;
        margin-top: 12px;
      }
      .incom-chart {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
  .news-update {
      .d-flex {
          padding: 30px 0px 0px 0px;
        img {
          border-radius: 10px;
        }
        .flex-grow-1 {
          h5 {
            font-size: 15px;
            font-weight: 500;
            color: $theme-font-color;
            line-height: 17.64px;
            letter-spacing: 0;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: $theme-body-sub-title-color;
            letter-spacing: 0;
          }
        }
        .flex-shrink-0 {
          button {
            border-radius: 50px;
            padding: 7px 14px; 
            background-color: $light-color;
            font-size: 12px;
            min-width: 85px;
            font-weight: 500;
            color: $theme-font-color;
          }
        }
      }
  }
  .product-chart {
      .d-flex {
          align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          color: $theme-body-sub-title-color;
        }
        h2 {
          font-weight: 500;
        }
      }
  }
  .recent {
    table {
        thead {
            tr {
                th {
                    .form-check {
                        input {
                            height: 15px;
                            &:checked {
                                background-color: var(--theme-default);
                                border: 1px solid var(--theme-default);
                            }
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                    padding-bottom: 8px;
                    color: $theme-font-color;
                    border-bottom: 1px solid $light-gray;
                    font-weight: 500;
                    &:first-child,
                    &:last-child {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    &:first-child {
                        min-width: 0px;
                    }
                }
            }
        }
        tbody {
            tr {
                .form-check {
                    input {
                        height: 15px;
                        &:checked {
                            background-color: var(--theme-default);
                            border: 1px solid var(--theme-default);
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
                td {
                    &:last-child {
                        @media (max-width: 991px) {
                            min-width: 70px;
                        }
                        text-align: center;
                    }
                    &:last-child,
                    &:first-child {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    border-bottom: 1px dashed $light-gray;
                    font-weight: 500;
                    padding: 17px 10px;
                    border-top: none;
                    color: $dark-color-light;
                    .d-flex {
                        img {
                            margin-left: 0px;
                        }
                        .flex-shrink-0 {
                            img {
                                margin-left: 0px;
                            }
                        }
                        .flex-grow-1.ms-2 {
                            a {
                                h6 {
                                    font-weight: 500;
                                    color: $dark-color-light;
                                    transition: all 0.4s ease-in;
                                    @media (max-width: 991px) {
                                        min-width: 110px;
                                    }
                                }
                            }
                        }
                    }
                    h6 {
                        transition: all 0.4s ease-in;
                        font-weight: 500;
                        color: $dark-color-light;
                    }
                    span {
                        font-size: 13px;
                        font-weight: 400;
                        color: $theme-body-sub-title-color;
                    }
                }
                .project-dot {
                    .d-flex {
                        align-items: center;
                        gap: 8px;
                        .flex-shrink-0 {
                            span {
                                width: 10px;
                                height: 10px;
                                border-radius: 50px;
                            }
                        }
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
  }
  .sales {
    .d-flex {
      .flex-shrink-0 {
        span {
          width: 10px;
          height: 10px;
          border-radius: 50px;
          &.bg-light-primary {
            background-color: rgba($primary-color, 0.6) !important;
          }
        }
      }
    }
    p {
      text-align: center;
      margin: 15px 0;
      font-size: 16px;
      font-weight: 400;
      color: $theme-body-sub-title-color;
    }
    .btn {
      &:last-child {
        background-color: $light-button;
        padding: 14px 15px;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .product-chart {
    position: relative;
    .shap-block {
      .rounded-shap {
        i {
          right: 43%;
          top: -44%;
        }
      }
    }
  }
  .shap-block {
    .rounded-shap i {
      background-color: rgba($danger-color, 0.4);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      opacity: 0.3;
      transform: scale(1.3);
      animation: ripple1 3s linear infinite;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      right: 0;
      &:first-child {
        animation: ripple1 3s linear infinite;
      }
      &:nth-child(2) {
        animation: ripple2 3s linear infinite;
      }
    }
  }
  .animated-bg i {
    background-color: $white;
    box-shadow: 0 15px 30px 0 $white;
    position: absolute;
    height: 8%;
    width: 8%;
    border-radius: 50%;
    opacity: 0.3;
    transform: scale(1.3);
    animation: ripple1 3s linear infinite;
    position: absolute;
    top: 0;
    right: 0;
    &:first-child {
        animation: ripple1 3s linear infinite;
    }
    &:nth-child(2) {
        animation: ripple2 3s linear infinite;
    }
  }
  @keyframes ripple1 {
    0% {
      transform: scale(5.5);
      opacity: 0.3;
    }
    100% {
      transform: scale(8.5);
      opacity: 0;
    }
  }
  @keyframes ripple2 {
    0% {
      transform: scale(3.5);
    }
    100% {
      transform: scale(5.5);
    }
  }
  .since {
    &:hover {
      transform: translateY(-5px);
      .customer-card {
        .dashboard-user {
          animation: tada 1s ease-out infinite;
        }
      }
    }
  }
  .customer-card {
    height: 55px;
    align-items: center;
    justify-content: space-between;
    h3 {
        font-size: 16px;
        font-weight: 400;
        color: $theme-body-sub-title-color;
    }
    h5 {
        font-size: 24px;
        font-weight: 500;
    }
    .dashboard-user {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
          width: 22px;
          height: 22px;
      }
      &::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(180deg, rgba(255,0,0,0), rgba($primary-color,1));
      }
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to right, rgba($primary-color,1) 60% , $transparent-color 40%);
      }
      span {
        &::after {
          position: absolute;
          content: '';
          width: 2px;
          height: 100%;
          top: 0;
          right: 0;
          background-image: linear-gradient(360deg, rgba(255,0,0,0), rgba($primary-color,1));
        }
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          top: 0;
          left: 0;
          background-image: linear-gradient(to left, rgba($primary-color,1) 60% , $transparent-color 40%);
        }
      }
    }
  }
  .money {
    .customer-card {
      .dashboard-user {
        &::after {
          position: absolute;
          content: '';
          width: 2px;
          height: 100%;
          top: 0;
          left: 0;
          background-image: linear-gradient(180deg, rgba(255,0,0,0), rgba($secondary-color,1));
        }
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to right, rgba($secondary-color,1) 60% , $transparent-color 40%);
        }
        span {
          &::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 100%;
            top: 0;
            right: 0;
            background-image: linear-gradient(360deg, rgba(255,0,0,0), rgba($secondary-color,1));
          }
          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            top: 0;
            left: 0;
            background-image: linear-gradient(to left, rgba($secondary-color,1) 60% , $transparent-color 40%);
          }
        }
      }
    }
  }
  .profit {
    .customer-card {
      .dashboard-user {
        &::after {
          position: absolute;
          content: '';
          width: 2px;
          height: 100%;
          top: 0;
          left: 0;
          background-image: linear-gradient(180deg, rgba(255,0,0,0), rgba($danger-color,1));
        }
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to right, rgba($danger-color,1) 60% , $transparent-color 40%);
        }
        span {
          &::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 100%;
            top: 0;
            right: 0;
            background-image: linear-gradient(360deg, rgba(255,0,0,0), rgba($danger-color,1));
          }
          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            top: 0;
            left: 0;
            background-image: linear-gradient(to left, rgba($danger-color,1) 60% , $transparent-color 40%);
          }
        }
      }
    }
  }
  .invoice-profit {
    .customer-card {
      .dashboard-user {
        &::after {
          position: absolute;
          content: '';
          width: 2px;
          height: 100%;
          top: 0;
          left: 0;
          background-image: linear-gradient(180deg, rgba(255,0,0,0), rgba($success-color,1));
        }
        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to right, rgba($success-color,1) 60% , $transparent-color 40%);
        }
        span {
          &::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 100%;
            top: 0;
            right: 0;
            background-image: linear-gradient(360deg, rgba(255,0,0,0), rgba($success-color,1));
          }
          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            top: 0;
            left: 0;
            background-image: linear-gradient(to left, rgba($success-color,1) 60% , $transparent-color 40%);
          }
        }
      }
    }
  }
  .customer {
    margin-left: -7px;
    [dir="rtl"] & {
      margin-left: unset;
      margin-right: -7px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        color: $theme-body-sub-title-color;
        svg {
            width: 15px;
            height: 15px;
            fill: $success-color;
            stroke: $success-color;
        }
    }
  }
  .review-slider {
    .review {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        img {
            width: 72px;
            height: 72px;
            margin: 0 auto;
        }
        .review-content {
            margin: 12px 0;
            h2 {
                font-size: 16px;
                font-weight: 600;
            }
        }
        .d-flex {
            align-items: center;
            background-color: $light-color;
            height: 28px;
            padding: 8px;
            width: 92px;
            margin: 0 auto;
            h5 {
                font-size: 14px;
                font-weight: 400;
            }
            .flex-grow-1 {
                border-left: 2px solid $light-gray;
                h5 {
                    margin-left: 6px;
                    [dir="rtl"] & {
                      margin-left: unset;
                      margin-right: 6px;
                    }
                }
            }
        }
      p {
        color: $theme-body-sub-title-color;
        margin-top: 15px;
      }
    }
  }
  .sales.overview {
    position: relative;
    .shap-2 {
      .rounded-shap {
        i {
            right: 79.5%;
            top: 43%;
        }
      }
      &:nth-child(2) {
        .rounded-shap {
            i {
                right: 62.4%;
                top: 4%;
            }
          }
      }
      &:nth-child(3) {
        .rounded-shap {
            i {
                right: 45.2%;
                top: 25%;
            }
          }
      }
      &:nth-child(4) {
        .rounded-shap {
            i {
                right: 19.5%;
                top: 17%;
            }
          }
      }
    }
  }
  .shap-2 {
    .rounded-shap i {
      background-color: var(--theme-default);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      opacity: 0.3;
      transform: scale(1.3);
      animation: ripple1 3s linear infinite;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      right: 0;
      &:first-child {
        animation: ripple1 3s linear infinite;
      }
      &:nth-child(2) {
        animation: ripple2 3s linear infinite;
      }
    }
  }
  .animated-bg i {
    background-color: $white;
    box-shadow: 0 15px 30px 0 $white;
    position: absolute;
    height: 8%;
    width: 8%;
    border-radius: 50%;
    opacity: 0.3;
    transform: scale(1.3);
    animation: ripple1 3s linear infinite;
    position: absolute;
    top: 0;
    right: 0;
    &:first-child {
        animation: ripple1 3s linear infinite;
    }
    &:nth-child(2) {
        animation: ripple2 3s linear infinite;
    }
  }
  @keyframes ripple1 {
    0% {
      transform: scale(5.5);
      opacity: 0.3;
    }
    100% {
      transform: scale(8.5);
      opacity: 0;
    }
  }
  @keyframes ripple2 {
    0% {
      transform: scale(3.5);
    }
    100% {
      transform: scale(5.5);
    }
  }
  .active-task {
    ul {
        li {
            border-bottom: 1px dashed $light-gray;
            padding: 13px 0;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            &.d-flex {
                align-items: center;
                .flex-shrink-0 {
                    .form-check {
                        .form-check-input {
                            border: 1px solid $light-semi-gray;
                        }
                    }
                }
                .flex-grow-1 {
                    margin-left: 10px;
                    [dir="rtl"] & {
                      margin-left: unset;
                      margin-right: 10px;
                    }
                    h5 {
                        font-weight: 500;
                        color: $theme-font-color;
                    }
                    p {
                        font-size: 13px;
                        font-weight: 400;
                        color: $theme-body-sub-title-color;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
            }
            .delete-option {
              width: 28px;
              height: 28px;
              display: flex;
              justify-content: center;
              background-color: rgba($danger-color, 0.1);
              border-radius: 5px;
              transition: all 0.4s ease-in;
              svg {
                width: 26px;
                height: 15px;
                vertical-align: bottom;
                stroke: $danger-color;
                fill: $danger-color;
              }
              &:hover {
                transform: translateY(-5px);
              }
            }
        }
    }
  }
  .investment-card {
    ul {
      display: flex;
      margin-top: 25px;
      li {
        display: inline-block;
        width: 100%;
        text-align: center;
        position: relative;
        h5 {
          font-weight: 500;
          color: $theme-font-color;
          margin-bottom: 5px;
        }
        span {
          font-size: 13px;
          font-weight: 400;
          color: $theme-body-sub-title-color;
        }
      }
    }
  }
  .notification-box {
    li {
        padding: 24px 0px;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0px;
            border-bottom: none;
        }
        &.d-flex {
            align-items: center;
            gap: 10px;
            .flex-shrink-0 {
                width: 38px;
                height: 38px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .flex-grow-1 {
                a {
                    text-decoration: none;
                    h5 {
                        color: $theme-body-font-color;
                        font-weight: 500;
                        transition: all .4s ease-in-out;
                        font-size: 14px;
                        text-overflow:ellipsis;
                        overflow:hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                    &:hover {
                        h5 {
                            color: var(--theme-default);
                        }
                    }
                }
                p {
                    font-size: 13px;
                    font-weight: 400;
                    color: $theme-body-sub-title-color;
                }
            }
        }
    }
  }
  .leads {
      .lead-status {
        display: flex;
        justify-content: space-between;
          ul {
              li {
                &:first-child {
                  font-size: 14px;
                  font-weight: 400;
                  margin-bottom: 4px;
                  color: $theme-body-sub-title-color;
                  background-color: unset;
                  padding: 0;
                }
                padding: 5px 12px;
                margin-bottom: 5px;
                border-radius: 4px;
                min-width: 110px;
                background-color: $light-button;
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                  [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 8px;
                  }
                }
              }
          }
      }
  }
  .statistics {
    &:hover{
      transform: translateY(-5px);
    }
    .d-flex {
      .flex-shrink-0 {
        border-right: 1px dashed $light-gray;
        [dir="rtl"] & {
          border-right: unset;
          border-left: 1px dashed $light-gray;
        }
        h4 {
          font-weight: 500;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: var(--theme-default);
          margin-top: 6px;
        }
        .progress {
          width: 130px;
          height: 5px;
          margin-top: 20px;
          margin-right: 20px;
          [dir="rtl"] & {
            margin-right: unset;
            margin-left: 20px;
          }
        }
      }
      .flex-grow-1 {
        margin-left: 20px;
          [dir="rtl"] & {
            margin-left: unset;
            margin-right: 20px;
          }
        h4 {
          font-weight: 500;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: var(--theme-secondary);
          margin-top: 6px;
        }
        .progress {
          width: 130px;
          height: 5px;
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 1870px) { 
  .default-dashboard {
    .product-chart {
      .shap-block {
        .rounded-shap {
          i {
            right: 38%;
            top: -53%;
          }
        }
      }
    }
  }
}
@media (max-width: 1750px) and (min-width: 1441px) { 
  .default-dashboard {
    .leads {
      .lead-status {
        ul {
          &:last-child {
            display: none;
          }
        }
      }
    }
    .review-slider {
      .review {
        p {
          margin-top: 8px;
          text-overflow:ellipsis;
          overflow:hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
      .owl-theme {
        .owl-nav {
          &.disabled {
            + .owl-dots {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .recent {
      table {
        thead {
          tr {
            th {
              &:nth-child(4) {
                display: none;
              }
              &:nth-child(5) {
                display: none;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              &:nth-child(4) {
                display: none;
              }
              &:nth-child(5) {
                display: none;
              }
            }
          }
        }
      }
    }
    .sales {
      p {
        font-size: 14px;
      }
    }
    .total-earning {
      span {
        text-overflow:ellipsis;
        overflow:hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
    .news-update {
      .d-flex {
        padding: 22px 0px 0px 0px;
        .flex-shrink-0 {
          button {
            display: none;
          }
        }
        .flex-grow-1 {
          h5 {
            text-overflow:ellipsis;
            overflow:hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1750px) and (min-width: 1441px) { 
  .default-dashboard {
    .statistics {
      .d-flex {
        .flex-shrink-0 {
          h4 {
            font-size: 14px;
          }
          span {
            font-size: 13px;
          }
          .progress {
            width: 85px;
            margin-right: 12px;
            [dir="rtl"] & {
              margin-right: unset;
              margin-left: 12px;
            }
          }
        }
        .flex-grow-1 {
          margin-left: 12px;
          [dir="rtl"] & {
            margin-left: unset;
            margin-right: 12px;
          }
          h4 {
            font-size: 14px;
          }
          span {
            font-size: 13px;
          }
          .progress {
            width: 85px;
          }
        }
      }
    }
  }
}
@media (max-width: 1660px) and (min-width: 1441px) { 
  .default-dashboard {
    .sales {
      .d-flex {
        margin-top: 15px;
        .flex-grow-1 {
          h5 {
            text-align: center;
            strong {
              display: none;
            }
          }
        }
      }
    }
    .since {
       .customer {
        span {
          &:last-child {
            display: none;
          }
        }
       }
    }
    .welcome-card {
      .d-flex {
        .flex-grow-1 {
          text-align: center;
        }
        .flex-shrink-0 {
          img {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1580px) {  
  .default-dashboard {
    .notification-box {
      li {
        padding: 10px 0;
      }
    }
  }
}
@media (max-width: 1550px) and (min-width: 1441px) {  
  .default-dashboard {
    .since {
      .card-body {
        padding: 12px;
        .customer-card {
          h3 {
            font-size: 14px;
          }
          h5 {
            font-size: 20px;
          }
          .dashboard-user {
            width: 35px;
            height: 35px;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    .investment-card {
      ul {
        li {
          &:first-child {
            display: none;
          }
        }
      }
    }
    .leads {
      .lead-status {
        ul {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) and (min-width: 992px) {  
  .default-dashboard {
    .col-xl-100 {
      flex: 0 0 auto;
      width: 100%;
    }
    .col-xl-50 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-40 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xl-70 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .welcome-card {
      .card-body {
        height: 296px;
        .d-flex {
          margin-top: 45px;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .default-dashboard {
    .product-chart {
      .shap-block {
        .rounded-shap {
          i {
            right: 43%;
            top: -45%;
          }
        }
      }
    }
    .news-update {
      margin-bottom: 0;
    }
    .left-background {
      margin-bottom: 30px;
    }
    .sales {
      .d-flex {
        margin-top: 15px;
        margin-left: 75px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 75px;
        }
      }
    }
  }
}
@media (max-width: 1313px) and (min-width: 1200px) {
  .default-dashboard {
    .product-chart {
      .shap-block {
        .rounded-shap {
          i {
            right: 38%;
            top: -54%;
          }
        }
      }
    }
  }
}
@media (max-width: 1280px) and (min-width: 1200px) {
  .default-dashboard {
    .news-update {
      .d-flex {
        .flex-grow-1 {
          span {
            display: none;
          }
        }
      }
    }
    .sales {
      .d-flex {
        margin-left: 25px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 25px;
        }
      }
      p {
        margin: 10px 0;
      }
    }
    .review-slider {
      .review {
        p {
          font-size: 16px;
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  .default-dashboard {
    .notification-card {
      .card {
        height: 150px;
        overflow: auto;
      }
    }
  }
}
@media (max-width: 1138px){
  .default-dashboard {
    .product-chart {
      .shap-block {
        .rounded-shap {
          i {
            right: 38%;
            top: -54%;
          }
        }
      }
    }
  }
}
@media (max-width: 1080px) and (min-width: 992px) {
  .default-dashboard {
    .total-earning {
      span {
        font-size: 16px;
      }
    }
    .news-update {
      .d-flex {
        .flex-grow-1 {
          span {
            display: none;
          }
        }
      }
    }
    .sales {
      .d-flex {
        margin-left: 55px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 55px;
        }
      }
    }
    .welcome-card {
      h1 {
        font-size: 28px;
      }
    }
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .default-dashboard {
    .total-earning {
      span {
        font-size: 14px;
      }
    }
    .news-update {
      .d-flex {
        .flex-grow-1 {
          span {
            display: none;
          }
        }
        .flex-shrink-0 {
          button {
            display: none;
          }
        }
      }
    }
    .sales {
      .d-flex {
        margin-left: 28px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 28px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .default-dashboard {
    .news-update {
      margin-bottom: 30px;
    }
    .product-chart {
      .shap-block {
        .rounded-shap {
          i {
            right: 42%;
            top: -45%;
          }
        }
      }
    }
    .recent {
      table {
        thead {
          tr {
            th {
              min-width: 120px;
              &:first-child {
                min-width: none;
              }
            }
          }
        }
      }
    }
    .leads {
      .lead-status {
        ul {
          &:nth-child(3) {
            display: none;
          }
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 588px) {
  .default-dashboard { 
    .product-chart {
      .shap-block {
        .rounded-shap {
          i {
            right: 38%;
            top: -53%;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .default-dashboard {
    .news-update {
      .d-flex {
        .flex-grow-1 {
          span {
            display: none;
          }
        }
        .flex-shrink-0 {
          button {
            display: none;
          }
        }
      }
    }
    .sales {
      .d-flex {
        margin-left: 30px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .notification {
    li {
      padding-bottom: 15px;
    }
  }
  .media-dropdown {
    display: block;
    .flex-grow-1 {
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 480px) {
  .default-dashboard {
    .welcome-card {
      .d-flex {
        .flex-grow-1 {
          text-align: center;
        }
        .flex-shrink-0 {
          img {
            display: none;
          }
        }
      }
    }
    .total-earning {
      span {
        font-size: 16px;
      }
    }
    .news-update {
      .d-flex {
        padding: 16px 0px 0px 0px;
        img {
          width: 55px;
          height: 55px;
        }
        .flex-grow-1 {
          a {
            h5 {
              text-overflow:ellipsis;
              overflow:hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
            }
          }
        }
      }
    }
    .product-chart {
      .d-flex {
        span {
          display: none;
        }
      }
    }
    .sales {
      .d-flex {
        margin-left: 15px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 15px;
        }
        text-align: center;
      }
    }
    .sales.overview {
      .shap-2 {
        .rounded-shap {
          i {
            display: none;
          }
        }
      }
    }
    .notification-box {
      li {
        &.d-flex {
          .flex-grow-1 {
            p {
              text-overflow:ellipsis;
              overflow:hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: normal;
            }
          }
        }
      }
    }
    .statistics {
      .d-flex {
        .flex-shrink-0 {
          border: none;
          .progress {
            width: 180px;
            margin-right: 0;
          }
        }
        .flex-grow-1 {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .default-dashboard {
    .leads {
      .lead-status {
        ul {
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
    .total-earning {
      span {
        display: none;
      }
    }
    .sales {
      .d-flex {
        margin-left: 0;
      }
    }
  }
}
// /**=====================
//   3.13 Dashboard_default CSS Ends
// ==========================**/