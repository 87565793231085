/*! -----------------------------------------------------------------------------------

    Template Name: Dunzo Admin
    Template URI: https://admin.pixelstrap.net/dunzo/template/
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

    1.Base css
        1.1 Reset CSS
        1.2 Typography CSS

    2.Components css
        2.1 According CSS
        2.2 Alert CSS
        2.3 Avatars CSS
        2.4 Badge CSS
        2.5 Bookmark CSS
        2.6 Breadcrumb CSS
        2.7 Basic-card CSS        
        2.8 Scrollbar CSS
        2.9 Buttons CSS
        2.10 Card CSS
        2.11 Color CSS
        2.12 Datatable CSS
        2.13 Datapicker CSS
        2.14 Dropdown CSS
        2.15 Typeahead-search CSS Start
        2.16 Form-input CSS
        2.17 Form-wizard CSS
        2.18 Forms CSS
        2.19 Icons CSS
        2.20 List CSS
        2.21 Loader CSS 
        2.22 Modal CSS
        2.23 Popover CSS
        2.24 Print CSS
        2.25 Radio CSS
        2.26 Ribbon CSS
        2.27 Range-slider CSS
        2.28 Switch CSS
        2.29 Tab CSS
        2.30 Table CSS
        2.31 Toasts CSS
        2.32 Touchspin CSS
        2.33 Tour CSS
        2.34 Tree CSS



    3. Pages
        3.1 Blog CSS
        3.2 Bookmark-app CSS
        3.3 Cart CSS
        3.4 Chart CSS
        3.5 Chat CSS
        3.6 Checkout CSS
        3.7 Comingsoon CSS
        3.8 Contacts CSS
        3.9 Dashboard_2 CSS
        3.10 Dashboard_3 CSS 
        3.11 Dashboard_4 CSS
        3.12 Dashboard_5 CSS
        3.13 Dashboard_default CSS
        3.14 Ecommerce CSS
        3.15 Email-application CSS
        3.16 Errorpage CSS
        3.17 Faq CSS
        3.18 File CSS
        3.19 Gallery CSS
        3.20 Helper CSS
        3.21 Internationalization CSS
        3.22 Job-search CSS
        3.23 Jsgrid CSS
        3.24 Kanban CSS
        3.25 Knowledgebase CSS
        3.26 Landing CSS
        3.27 Language CSS
        3.28 Learning CSS
        3.29 Login CSS
        3.30 Megaoption CSS
        3.31 Order-history CSS
        3.32 Page CSS
        3.33 Pricing CSS
        3.34 Progress CSS
        3.35 Projectlist CSS
        3.36 Rating CSS
        3.37 Social-app CSS
        3.38 Sweet-alert CSS
        3.39 Task CSS
        3.40 Timeline-v CSS
        3.41 User-profile CSS
        3.42 Wishlist CSS
        3.43 Dashboard_6.CSS
        3.44 Dashboard_7 CSS
        3.45 Dashboard_8.CSS

    4. Themes
        4.1. Dark CSS
        4.2 Theme-customizer CSS
        4.3 Update CSS

    5. Layout
        5.1 Footer CSS
        5.2 Grid CSS
        5.3 Header CSS
        5.4 Navs CSS
        5.5 Search CSS
        5.6 Select2 CSS
        5.7 Sidebar CSS
        5.8 Rtl CSS
        5.9 Box-layout CSS

 */
:root {
  --theme-default: #307EF3;
  --theme-secondary: #EBA31D;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2F2F3B;
  --chart-border: #ECF3FA;
  --recent-chart-bg: #FCFCFD;
  --light-bg: #EEF1F6;
  --white: #fff;
  --light2: #F5F6F9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #F2F4F7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #F2F3F7;
  --course-bg: #F7F7F7;
  --balance-profie-bg: #E0DFEF;
  --view-grid-bg: #ECF3FA80;
  --view-border-marker: rgba(48, 126, 243, 0.2);
  --light-slate: #F5F6F9;
  --text-gray: rgba(82, 82, 108, 0.8);
  --product-shadow: 0px 1px 0px rgba(82, 82, 108, 0.15);
  --light-shade-primary: rgba(48, 126, 243, 0.2);
  --dark-primary: rgba(115, 102, 255);
  --bs-heading-color: var(--body-font-color);
  --bs-card-color: var(--body-font-color);
  --bs-table-bg: transparent;
  --bs-body-bg: transparent;
  --fc-button-bg-color: var(--theme-default);
  --fc-button-active-bg-color: var(--theme-default);
  --fc-button-active-border-color: var(--theme-default);
  --fc-button-border-color: var(--theme-default);
  --fc-button-hover-bg-color: var(--theme-default);
  --fc-button-hover-border-color: var(--theme-default);
  --bs-heading-color: #363636;
  --bs-card-cap-color: #9B9B9B;
  --normal: #ECEAF3;
  --normal-shadow: #D9D8E3;
  --normal-mouth: #9795A4;
  --normal-eye: #595861;
  --active: #F8DA69;
  --active-shadow: #F4B555;
  --active-mouth: #F05136;
  --active-eye: #313036;
  --active-tear: #76b5e7;
  --active-shadow-angry: #e94f1d;
  /* Do not show the "remove tag" (x) button when only a single tag remains */
}
:root .customLook {
  --tag-bg: var(--theme-default);
  --tag-hover: var(--theme-secondary);
  --tag-text-color: var(--white);
  --tags-border-color: silver;
  --tag-border-radius: 25px;
  --tag-text-color--edit: #111;
  --tag-remove-bg: var(--tag-hover);
  --tag-pad: .6em 1em;
  --tag-inset-shadow-size: 1.35em;
  --tag-remove-btn-bg--hover: #000;
  --tagify-dd-color-primary: var(var(--theme-default));
  --tagify-dd-bg-color: var(--course-light-btn);
}

body[class=dark-only] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --chart-text-color: rgba(101, 101, 113, 1);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --light-slate: #1c1d26;
  --text-gray: rgba(153, 153, 169, 0.8);
  --product-shadow: 0px 1px 0px rgb(55 70 87);
  --light-shade-primary: rgba(48, 126, 243, 0.2);
  --light-gray-1: linear-gradient(0deg, #FCFCFD, #FCFCFD),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);
  --bs-heading-color: #ffffffd7;
  --bs-card-color: #ffffffd7;
  --bs-table-bg: transparent;
  --bs-body-bg: transparent;
}

body[class="rtl dark-only"] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --light-slate: #1c1d26;
  --text-gray: rgba(153, 153, 169, 0.8);
  --product-shadow: 0px 1px 0px rgb(55 70 87);
  --light-shade-primary: rgba(48, 126, 243, 0.2);
  --light-bg-primary: rgba(239, 238, 255);
}

body[class="dark-only modal-open"] {
  --light-shade-primary: rgba(48, 126, 243, 0.2);
  --light-slate: #1c1d26;
}

body[class="rtl dark-only modal-open"] {
  --light-shade-primary: rgba(48, 126, 243, 0.2);
  --light-slate: #1c1d26;
}

body.dark-only.box-layout {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --light-slate: #1c1d26;
  --text-gray: rgba(153, 153, 169, 0.8);
  --product-shadow: 0px 1px 0px rgb(55 70 87);
  --light-shade-primary: rgba(48, 126, 243, 0.2);
}

/**=====================
    1.1 Reset CSS start
==========================**/
.f-light {
  color: rgba(155, 155, 155, 0.8);
}

svg.f-light {
  fill: #9B9B9B;
  opacity: 0.8;
}

.light-card {
  background-color: var(--light-background);
}

.light-background {
  background-color: var(--light-bg);
}

.icon-arrow-down.icon-rotate,
.icon-arrow-up.icon-rotate {
  transform: rotate(-45deg);
  display: inline-block;
}

/*====== Padding css starts ======*/
.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/
/*====== Border-radius css starts ======*/
.b-r-0 {
  border-radius: 0px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

.b-r-11 {
  border-radius: 11px !important;
}

.b-r-12 {
  border-radius: 12px !important;
}

.b-r-13 {
  border-radius: 13px !important;
}

.b-r-14 {
  border-radius: 14px !important;
}

.b-r-15 {
  border-radius: 15px !important;
}

.b-r-16 {
  border-radius: 16px !important;
}

.b-r-17 {
  border-radius: 17px !important;
}

.b-r-18 {
  border-radius: 18px !important;
}

.b-r-19 {
  border-radius: 19px !important;
}

.b-r-20 {
  border-radius: 20px !important;
}

.b-r-21 {
  border-radius: 21px !important;
}

.b-r-22 {
  border-radius: 22px !important;
}

.b-r-23 {
  border-radius: 23px !important;
}

.b-r-24 {
  border-radius: 24px !important;
}

.b-r-25 {
  border-radius: 25px !important;
}

.b-r-26 {
  border-radius: 26px !important;
}

.b-r-27 {
  border-radius: 27px !important;
}

.b-r-28 {
  border-radius: 28px !important;
}

.b-r-29 {
  border-radius: 29px !important;
}

.b-r-30 {
  border-radius: 30px !important;
}

.b-r-31 {
  border-radius: 31px !important;
}

.b-r-32 {
  border-radius: 32px !important;
}

.b-r-33 {
  border-radius: 33px !important;
}

.b-r-34 {
  border-radius: 34px !important;
}

.b-r-35 {
  border-radius: 35px !important;
}

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-26 {
  font-size: 26px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-34 {
  font-size: 34px !important;
}

.f-36 {
  font-size: 36px !important;
}

.f-38 {
  font-size: 38px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-42 {
  font-size: 42px !important;
}

.f-44 {
  font-size: 44px !important;
}

.f-46 {
  font-size: 46px !important;
}

.f-48 {
  font-size: 48px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-52 {
  font-size: 52px !important;
}

.f-54 {
  font-size: 54px !important;
}

.f-56 {
  font-size: 56px !important;
}

.f-58 {
  font-size: 58px !important;
}

.f-60 {
  font-size: 60px !important;
}

.f-62 {
  font-size: 62px !important;
}

.f-64 {
  font-size: 64px !important;
}

.f-66 {
  font-size: 66px !important;
}

.f-68 {
  font-size: 68px !important;
}

.f-70 {
  font-size: 70px !important;
}

.f-72 {
  font-size: 72px !important;
}

.f-74 {
  font-size: 74px !important;
}

.f-76 {
  font-size: 76px !important;
}

.f-78 {
  font-size: 78px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-82 {
  font-size: 82px !important;
}

.f-84 {
  font-size: 84px !important;
}

.f-86 {
  font-size: 86px !important;
}

.f-88 {
  font-size: 88px !important;
}

.f-90 {
  font-size: 90px !important;
}

.f-92 {
  font-size: 92px !important;
}

.f-94 {
  font-size: 94px !important;
}

.f-96 {
  font-size: 96px !important;
}

.f-98 {
  font-size: 98px !important;
}

.f-100 {
  font-size: 100px !important;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  text-decoration: dashed;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  text-decoration: solid;
}

.text-wavy {
  text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  text-decoration: double;
}

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.p-static {
  position: static;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.p-initial {
  position: initial;
}

.p-inherit {
  position: inherit;
}

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-10 {
  width: 10px !important;
}

.img-h-10 {
  height: 10px !important;
}

.img-20 {
  width: 20px !important;
}

.img-h-20 {
  height: 20px !important;
}

.img-30 {
  width: 30px !important;
}

.img-h-30 {
  height: 30px !important;
}

.img-40 {
  width: 40px !important;
}

.img-h-40 {
  height: 40px !important;
}

.img-50 {
  width: 50px !important;
}

.img-h-50 {
  height: 50px !important;
}

.img-60 {
  width: 60px !important;
}

.img-h-60 {
  height: 60px !important;
}

.img-70 {
  width: 70px !important;
}

.img-h-70 {
  height: 70px !important;
}

.img-80 {
  width: 80px !important;
}

.img-h-80 {
  height: 80px !important;
}

.img-90 {
  width: 90px !important;
}

.img-h-90 {
  height: 90px !important;
}

.img-100 {
  width: 100px !important;
}

.img-h-100 {
  height: 100px !important;
}

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.font-primary {
  color: #307EF3 !important;
}

.font-secondary {
  color: #EBA31D !important;
}

.font-success {
  color: #53A653 !important;
}

.font-danger {
  color: #DC3545 !important;
}

.font-info {
  color: #01A1B9 !important;
}

.font-light {
  color: #F3F3F3 !important;
}

.font-dark {
  color: #2c323f !important;
}

.font-warning {
  color: #FFAA05 !important;
}

.font-primary {
  color: var(--theme-default) !important;
}

.font-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-theme {
  background-color: #307EF3;
}

.label-primary {
  background-color: #307EF3;
}

.label-secondary {
  background-color: #EBA31D;
}

.label-success {
  background-color: #53A653;
}

.label-danger {
  background-color: #DC3545;
}

.label-info {
  background-color: #01A1B9;
}

.label-light {
  background-color: #F3F3F3;
}

.label-dark {
  background-color: #2c323f;
}

.label-warning {
  background-color: #FFAA05;
}

/*======= Label-color css ends  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background-color: #307EF3;
}

.badge-secondary {
  background-color: #EBA31D;
}

.badge-success {
  background-color: #53A653;
}

.badge-danger {
  background-color: #DC3545;
}

.badge-info {
  background-color: #01A1B9;
}

.badge-light {
  background-color: #F3F3F3;
}

.badge-dark {
  background-color: #2c323f;
}

.badge-warning {
  background-color: #FFAA05;
}

.badge-primary {
  background-color: var(--theme-default) !important;
}

.badge-secondary {
  background-color: var(--theme-secondary) !important;
}

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-primary {
  background-color: #307EF3 !important;
  color: #fff;
}

.bg-light-primary {
  background-color: rgba(48, 126, 243, 0.1) !important;
}

.bg-secondary {
  background-color: #EBA31D !important;
  color: #fff;
}

.bg-light-secondary {
  background-color: rgba(235, 163, 29, 0.1) !important;
}

.bg-success {
  background-color: #53A653 !important;
  color: #fff;
}

.bg-light-success {
  background-color: rgba(83, 166, 83, 0.1) !important;
}

.bg-danger {
  background-color: #DC3545 !important;
  color: #fff;
}

.bg-light-danger {
  background-color: rgba(220, 53, 69, 0.1) !important;
}

.bg-info {
  background-color: #01A1B9 !important;
  color: #fff;
}

.bg-light-info {
  background-color: rgba(1, 161, 185, 0.1) !important;
}

.bg-light {
  background-color: #F3F3F3 !important;
  color: #fff;
}

.bg-light-light {
  background-color: rgba(243, 243, 243, 0.1) !important;
}

.bg-dark {
  background-color: #2c323f !important;
  color: #fff;
}

.bg-light-dark {
  background-color: rgba(44, 50, 63, 0.1) !important;
}

.bg-warning {
  background-color: #FFAA05 !important;
  color: #fff;
}

.bg-light-warning {
  background-color: rgba(255, 170, 5, 0.1) !important;
}

.bg-primary {
  background-color: var(--theme-default) !important;
}

.bg-secondary {
  background-color: var(--theme-secondary) !important;
}

/*======= Background-color css end  ======= */
/*======= Font-color css starts  ======= */
.txt-primary {
  color: #307EF3 !important;
}

.txt-secondary {
  color: #EBA31D !important;
}

.txt-success {
  color: #53A653 !important;
}

.txt-danger {
  color: #DC3545 !important;
}

.txt-info {
  color: #01A1B9 !important;
}

.txt-light {
  color: #F3F3F3 !important;
}

.txt-dark {
  color: #2c323f !important;
}

.txt-warning {
  color: #FFAA05 !important;
}

.txt-google-plus {
  color: #c64e40 !important;
}

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077B5 !important;
}

.txt-fb {
  color: #50598e !important;
}

.txt-primary {
  color: var(--theme-default) !important;
}

.txt-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Font-color css end  ======= */
/*======= Button-color css starts  ======= */
@keyframes push {
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
.btn-primary {
  background-color: #307EF3 !important;
  border-color: #307EF3 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #307EF3 !important;
  border-color: #307EF3 !important;
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #c1d8fb;
}

.btn-secondary {
  background-color: #EBA31D !important;
  border-color: #EBA31D !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #EBA31D !important;
  border-color: #EBA31D !important;
}
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #f7dcaa;
}

.btn-success {
  background-color: #53A653 !important;
  border-color: #53A653 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-success.disabled, .btn-success:disabled {
  background-color: #53A653 !important;
  border-color: #53A653 !important;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem #b7dbb7;
}

.btn-danger {
  background-color: #DC3545 !important;
  border-color: #DC3545 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-danger.disabled, .btn-danger:disabled {
  background-color: #DC3545 !important;
  border-color: #DC3545 !important;
}
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem #f3b7bd;
}

.btn-info {
  background-color: #01A1B9 !important;
  border-color: #01A1B9 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  color: #fff;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  color: #fff;
}
.btn-info.disabled, .btn-info:disabled {
  background-color: #01A1B9 !important;
  border-color: #01A1B9 !important;
}
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem #55e8fe;
}

.btn-light {
  background-color: #F3F3F3 !important;
  border-color: #F3F3F3 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-light.disabled, .btn-light:disabled {
  background-color: #F3F3F3 !important;
  border-color: #F3F3F3 !important;
}
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem white;
}

.btn-dark {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
}
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem #6c7a98;
}

.btn-warning {
  background-color: #FFAA05 !important;
  border-color: #FFAA05 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}
.btn-warning.disabled, .btn-warning:disabled {
  background-color: #FFAA05 !important;
  border-color: #FFAA05 !important;
}
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem #ffde9e;
}

.btn-light {
  background-color: rgba(48, 126, 243, 0.06) !important;
  border-color: rgba(48, 126, 243, 0.06) !important;
}
.btn-light:hover {
  background-color: rgba(48, 126, 243, 0.1) !important;
  border-color: rgba(48, 126, 243, 0.1) !important;
}

.btn-primary {
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default) !important;
}

.btn-secondary {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
}

.btn-hover-effect:hover {
  animation: push 0.5s linear 1;
}

/*======= Button-color css ends  ======= */
.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #307EF3;
  color: #307EF3;
  background-color: transparent;
}
.btn-outline-primary-2x:hover, .btn-outline-primary-2x:focus, .btn-outline-primary-2x:active, .btn-outline-primary-2x.active {
  color: white;
  background-color: #0d63e3 !important;
  border-color: #0d63e3 !important;
  box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #EBA31D;
  color: #EBA31D;
  background-color: transparent;
}
.btn-outline-secondary-2x:hover, .btn-outline-secondary-2x:focus, .btn-outline-secondary-2x:active, .btn-outline-secondary-2x.active {
  color: white;
  background-color: #c48511 !important;
  border-color: #c48511 !important;
  box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #53A653;
  color: #53A653;
  background-color: transparent;
}
.btn-outline-success-2x:hover, .btn-outline-success-2x:focus, .btn-outline-success-2x:active, .btn-outline-success-2x.active {
  color: white;
  background-color: #428442 !important;
  border-color: #428442 !important;
  box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #DC3545;
  color: #DC3545;
  background-color: transparent;
}
.btn-outline-danger-2x:hover, .btn-outline-danger-2x:focus, .btn-outline-danger-2x:active, .btn-outline-danger-2x.active {
  color: white;
  background-color: #bd2130 !important;
  border-color: #bd2130 !important;
  box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #01A1B9;
  color: #01A1B9;
  background-color: transparent;
}
.btn-outline-info-2x:hover, .btn-outline-info-2x:focus, .btn-outline-info-2x:active, .btn-outline-info-2x.active {
  color: white;
  background-color: #017586 !important;
  border-color: #017586 !important;
  box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #F3F3F3;
  color: #F3F3F3;
  background-color: transparent;
}
.btn-outline-light-2x:hover, .btn-outline-light-2x:focus, .btn-outline-light-2x:active, .btn-outline-light-2x.active {
  color: white;
  background-color: #dadada !important;
  border-color: #dadada !important;
  box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}
.btn-outline-dark-2x:hover, .btn-outline-dark-2x:focus, .btn-outline-dark-2x:active, .btn-outline-dark-2x.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
  box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #FFAA05;
  color: #FFAA05;
  background-color: transparent;
}
.btn-outline-warning-2x:hover, .btn-outline-warning-2x:focus, .btn-outline-warning-2x:active, .btn-outline-warning-2x.active {
  color: white;
  background-color: #d18a00 !important;
  border-color: #d18a00 !important;
  box-shadow: none;
}

.btn-outline-primary {
  border-color: #307EF3;
  color: #307EF3;
  background-color: transparent;
}
.btn-outline-primary.disabled {
  color: #307EF3;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
  color: white;
  background-color: #0d63e3 !important;
  border-color: #0d63e3 !important;
}

.btn-outline-secondary {
  border-color: #EBA31D;
  color: #EBA31D;
  background-color: transparent;
}
.btn-outline-secondary.disabled {
  color: #EBA31D;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
  color: white;
  background-color: #c48511 !important;
  border-color: #c48511 !important;
}

.btn-outline-success {
  border-color: #53A653;
  color: #53A653;
  background-color: transparent;
}
.btn-outline-success.disabled {
  color: #53A653;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active {
  color: white;
  background-color: #428442 !important;
  border-color: #428442 !important;
}

.btn-outline-danger {
  border-color: #DC3545;
  color: #DC3545;
  background-color: transparent;
}
.btn-outline-danger.disabled {
  color: #DC3545;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active {
  color: white;
  background-color: #bd2130 !important;
  border-color: #bd2130 !important;
}

.btn-outline-info {
  border-color: #01A1B9;
  color: #01A1B9;
  background-color: transparent;
}
.btn-outline-info.disabled {
  color: #01A1B9;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active {
  color: white;
  background-color: #017586 !important;
  border-color: #017586 !important;
}

.btn-outline-light {
  border-color: #F3F3F3;
  color: #F3F3F3;
  background-color: transparent;
  color: #363636;
}
.btn-outline-light.disabled {
  color: #F3F3F3;
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active {
  color: white;
  background-color: #dadada !important;
  border-color: #dadada !important;
}

.btn-outline-dark {
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}
.btn-outline-dark.disabled {
  color: #2c323f;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning {
  border-color: #FFAA05;
  color: #FFAA05;
  background-color: transparent;
}
.btn-outline-warning.disabled {
  color: #FFAA05;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active {
  color: white;
  background-color: #d18a00 !important;
  border-color: #d18a00 !important;
}

.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #0d63e3;
  border-color: #0d63e3;
  box-shadow: none !important;
}
.btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active:active, .btn-primary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0d63e3;
  border-color: #0d63e3;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #c48511;
  border-color: #c48511;
  box-shadow: none !important;
}
.btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active:active, .btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c48511;
  border-color: #c48511;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #428442;
  border-color: #428442;
  box-shadow: none !important;
}
.btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active:active, .btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #428442;
  border-color: #428442;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #bd2130;
  border-color: #bd2130;
  box-shadow: none !important;
}
.btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active:active, .btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #bd2130;
  border-color: #bd2130;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #017586;
  border-color: #017586;
  box-shadow: none !important;
}
.btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active:active, .btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #017586;
  border-color: #017586;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #dadada;
  border-color: #dadada;
  box-shadow: none !important;
}
.btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active:active, .btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #dadada;
  border-color: #dadada;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #171a21;
  border-color: #171a21;
  box-shadow: none !important;
}
.btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active:active, .btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #171a21;
  border-color: #171a21;
}

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #d18a00;
  border-color: #d18a00;
  box-shadow: none !important;
}
.btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active:active, .btn-warning:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #d18a00;
  border-color: #d18a00;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #307EF3;
  border-color: #307EF3;
  box-shadow: none !important;
  color: white;
}
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #0d63e3 !important;
  border-color: #0d63e3 !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #EBA31D;
  border-color: #EBA31D;
  box-shadow: none !important;
  color: white;
}
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #c48511 !important;
  border-color: #c48511 !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #53A653;
  border-color: #53A653;
  box-shadow: none !important;
  color: white;
}
.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-success-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-success-2x:not([disabled]):not(.disabled).active:active, .btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #428442 !important;
  border-color: #428442 !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #DC3545;
  border-color: #DC3545;
  box-shadow: none !important;
  color: white;
}
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:active, .btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #bd2130 !important;
  border-color: #bd2130 !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #01A1B9;
  border-color: #01A1B9;
  box-shadow: none !important;
  color: white;
}
.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-info-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-info-2x:not([disabled]):not(.disabled).active:active, .btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #017586 !important;
  border-color: #017586 !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  box-shadow: none !important;
  color: white;
}
.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-light-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-light-2x:not([disabled]):not(.disabled).active:active, .btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #dadada !important;
  border-color: #dadada !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2c323f;
  border-color: #2c323f;
  box-shadow: none !important;
  color: white;
}
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:active, .btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #FFAA05;
  border-color: #FFAA05;
  box-shadow: none !important;
  color: white;
}
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:active, .btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #d18a00 !important;
  border-color: #d18a00 !important;
}

/*======= Table-Border-Bottom-color css starts  ======= */
table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #307EF3 !important;
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #EBA31D !important;
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #53A653 !important;
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #DC3545 !important;
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #01A1B9 !important;
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #F3F3F3 !important;
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2c323f !important;
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #FFAA05 !important;
}

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #307EF3;
  color: #fff;
  border: 3px solid #307EF3;
}
.table-styling .table-primary thead,
.table-styling.table-primary thead {
  background-color: #0d63e3;
  border: 3px solid #0d63e3;
}
.table-styling .table-primary th,
.table-styling .table-primary td,
.table-styling.table-primary th,
.table-styling.table-primary td {
  background-color: transparent;
}

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #EBA31D;
  color: #fff;
  border: 3px solid #EBA31D;
}
.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
  background-color: #c48511;
  border: 3px solid #c48511;
}
.table-styling .table-secondary th,
.table-styling .table-secondary td,
.table-styling.table-secondary th,
.table-styling.table-secondary td {
  background-color: transparent;
}

.table-styling .table-success,
.table-styling.table-success {
  background-color: #53A653;
  color: #fff;
  border: 3px solid #53A653;
}
.table-styling .table-success thead,
.table-styling.table-success thead {
  background-color: #428442;
  border: 3px solid #428442;
}
.table-styling .table-success th,
.table-styling .table-success td,
.table-styling.table-success th,
.table-styling.table-success td {
  background-color: transparent;
}

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #DC3545;
  color: #fff;
  border: 3px solid #DC3545;
}
.table-styling .table-danger thead,
.table-styling.table-danger thead {
  background-color: #bd2130;
  border: 3px solid #bd2130;
}
.table-styling .table-danger th,
.table-styling .table-danger td,
.table-styling.table-danger th,
.table-styling.table-danger td {
  background-color: transparent;
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #01A1B9;
  color: #fff;
  border: 3px solid #01A1B9;
}
.table-styling .table-info thead,
.table-styling.table-info thead {
  background-color: #017586;
  border: 3px solid #017586;
}
.table-styling .table-info th,
.table-styling .table-info td,
.table-styling.table-info th,
.table-styling.table-info td {
  background-color: transparent;
}

.table-styling .table-light,
.table-styling.table-light {
  background-color: #F3F3F3;
  color: #fff;
  border: 3px solid #F3F3F3;
}
.table-styling .table-light thead,
.table-styling.table-light thead {
  background-color: #dadada;
  border: 3px solid #dadada;
}
.table-styling .table-light th,
.table-styling .table-light td,
.table-styling.table-light th,
.table-styling.table-light td {
  background-color: transparent;
}

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2c323f;
  color: #fff;
  border: 3px solid #2c323f;
}
.table-styling .table-dark thead,
.table-styling.table-dark thead {
  background-color: #171a21;
  border: 3px solid #171a21;
}
.table-styling .table-dark th,
.table-styling .table-dark td,
.table-styling.table-dark th,
.table-styling.table-dark td {
  background-color: transparent;
}

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #FFAA05;
  color: #fff;
  border: 3px solid #FFAA05;
}
.table-styling .table-warning thead,
.table-styling.table-warning thead {
  background-color: #d18a00;
  border: 3px solid #d18a00;
}
.table-styling .table-warning th,
.table-styling .table-warning td,
.table-styling.table-warning th,
.table-styling.table-warning td {
  background-color: transparent;
}

/*======= Table styling css ends  ======= */
/*======= Svg stroke css start  ======= */
.stroke-primary {
  stroke: #307EF3 !important;
}

.stroke-secondary {
  stroke: #EBA31D !important;
}

.stroke-success {
  stroke: #53A653 !important;
}

.stroke-danger {
  stroke: #DC3545 !important;
}

.stroke-info {
  stroke: #01A1B9 !important;
}

.stroke-light {
  stroke: #F3F3F3 !important;
}

.stroke-dark {
  stroke: #2c323f !important;
}

.stroke-warning {
  stroke: #FFAA05 !important;
}

/*======= Svg stroke css end  ======= */
/*======= Checkbox color css start  ======= */
.checkbox-primary.form-check-input:checked {
  background-color: #307EF3;
  border-color: #307EF3;
}

.checkbox-secondary.form-check-input:checked {
  background-color: #EBA31D;
  border-color: #EBA31D;
}

.checkbox-success.form-check-input:checked {
  background-color: #53A653;
  border-color: #53A653;
}

.checkbox-danger.form-check-input:checked {
  background-color: #DC3545;
  border-color: #DC3545;
}

.checkbox-info.form-check-input:checked {
  background-color: #01A1B9;
  border-color: #01A1B9;
}

.checkbox-light.form-check-input:checked {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
}

.checkbox-dark.form-check-input:checked {
  background-color: #2c323f;
  border-color: #2c323f;
}

.checkbox-warning.form-check-input:checked {
  background-color: #FFAA05;
  border-color: #FFAA05;
}

/*======= Checkbox color css end  ======= */
/*======= All-Borders-color css starts  ======= */
.b-primary {
  border: 1px solid #307EF3 !important;
}

.b-t-primary {
  border-top: 1px solid #307EF3 !important;
}

.border-t-primary {
  border-top: 1px solid #307EF3 !important;
}

.b-b-primary {
  border-bottom: 1px solid #307EF3 !important;
}

.border-b-primary {
  border-bottom: 1px solid #307EF3 !important;
}

.b-l-primary {
  border-left: 1px solid #307EF3 !important;
}

.border-l-primary {
  border-left: 1px solid #307EF3 !important;
}

.b-r-primary {
  border-right: 1px solid #307EF3 !important;
}

.border-r-primary {
  border-right: 1px solid #307EF3 !important;
}

.b-secondary {
  border: 1px solid #EBA31D !important;
}

.b-t-secondary {
  border-top: 1px solid #EBA31D !important;
}

.border-t-secondary {
  border-top: 1px solid #EBA31D !important;
}

.b-b-secondary {
  border-bottom: 1px solid #EBA31D !important;
}

.border-b-secondary {
  border-bottom: 1px solid #EBA31D !important;
}

.b-l-secondary {
  border-left: 1px solid #EBA31D !important;
}

.border-l-secondary {
  border-left: 1px solid #EBA31D !important;
}

.b-r-secondary {
  border-right: 1px solid #EBA31D !important;
}

.border-r-secondary {
  border-right: 1px solid #EBA31D !important;
}

.b-success {
  border: 1px solid #53A653 !important;
}

.b-t-success {
  border-top: 1px solid #53A653 !important;
}

.border-t-success {
  border-top: 1px solid #53A653 !important;
}

.b-b-success {
  border-bottom: 1px solid #53A653 !important;
}

.border-b-success {
  border-bottom: 1px solid #53A653 !important;
}

.b-l-success {
  border-left: 1px solid #53A653 !important;
}

.border-l-success {
  border-left: 1px solid #53A653 !important;
}

.b-r-success {
  border-right: 1px solid #53A653 !important;
}

.border-r-success {
  border-right: 1px solid #53A653 !important;
}

.b-danger {
  border: 1px solid #DC3545 !important;
}

.b-t-danger {
  border-top: 1px solid #DC3545 !important;
}

.border-t-danger {
  border-top: 1px solid #DC3545 !important;
}

.b-b-danger {
  border-bottom: 1px solid #DC3545 !important;
}

.border-b-danger {
  border-bottom: 1px solid #DC3545 !important;
}

.b-l-danger {
  border-left: 1px solid #DC3545 !important;
}

.border-l-danger {
  border-left: 1px solid #DC3545 !important;
}

.b-r-danger {
  border-right: 1px solid #DC3545 !important;
}

.border-r-danger {
  border-right: 1px solid #DC3545 !important;
}

.b-info {
  border: 1px solid #01A1B9 !important;
}

.b-t-info {
  border-top: 1px solid #01A1B9 !important;
}

.border-t-info {
  border-top: 1px solid #01A1B9 !important;
}

.b-b-info {
  border-bottom: 1px solid #01A1B9 !important;
}

.border-b-info {
  border-bottom: 1px solid #01A1B9 !important;
}

.b-l-info {
  border-left: 1px solid #01A1B9 !important;
}

.border-l-info {
  border-left: 1px solid #01A1B9 !important;
}

.b-r-info {
  border-right: 1px solid #01A1B9 !important;
}

.border-r-info {
  border-right: 1px solid #01A1B9 !important;
}

.b-light {
  border: 1px solid #F3F3F3 !important;
}

.b-t-light {
  border-top: 1px solid #F3F3F3 !important;
}

.border-t-light {
  border-top: 1px solid #F3F3F3 !important;
}

.b-b-light {
  border-bottom: 1px solid #F3F3F3 !important;
}

.border-b-light {
  border-bottom: 1px solid #F3F3F3 !important;
}

.b-l-light {
  border-left: 1px solid #F3F3F3 !important;
}

.border-l-light {
  border-left: 1px solid #F3F3F3 !important;
}

.b-r-light {
  border-right: 1px solid #F3F3F3 !important;
}

.border-r-light {
  border-right: 1px solid #F3F3F3 !important;
}

.b-dark {
  border: 1px solid #2c323f !important;
}

.b-t-dark {
  border-top: 1px solid #2c323f !important;
}

.border-t-dark {
  border-top: 1px solid #2c323f !important;
}

.b-b-dark {
  border-bottom: 1px solid #2c323f !important;
}

.border-b-dark {
  border-bottom: 1px solid #2c323f !important;
}

.b-l-dark {
  border-left: 1px solid #2c323f !important;
}

.border-l-dark {
  border-left: 1px solid #2c323f !important;
}

.b-r-dark {
  border-right: 1px solid #2c323f !important;
}

.border-r-dark {
  border-right: 1px solid #2c323f !important;
}

.b-warning {
  border: 1px solid #FFAA05 !important;
}

.b-t-warning {
  border-top: 1px solid #FFAA05 !important;
}

.border-t-warning {
  border-top: 1px solid #FFAA05 !important;
}

.b-b-warning {
  border-bottom: 1px solid #FFAA05 !important;
}

.border-b-warning {
  border-bottom: 1px solid #FFAA05 !important;
}

.b-l-warning {
  border-left: 1px solid #FFAA05 !important;
}

.border-l-warning {
  border-left: 1px solid #FFAA05 !important;
}

.b-r-warning {
  border-right: 1px solid #FFAA05 !important;
}

.border-r-warning {
  border-right: 1px solid #FFAA05 !important;
}

/*======= All-Borders-color css ends  ======= */
/*====== Border width css starts ======*/
.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

/*====== Border width css ends ======*/
.map-js-height {
  height: 500px;
}

/**====== custom scrollbar css start ======**/
.custom-scrollbar::-webkit-scrollbar-track,
.datatable-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #EAEAEA;
}
.custom-scrollbar::-webkit-scrollbar,
.datatable-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.datatable-container::-webkit-scrollbar-thumb {
  background-color: rgba(48, 126, 243, 0.1);
}

/**====== Custom scrollbar css end ======**/
/**====== Animation css Start ======**/
.line {
  color: #2c323f;
}
.line pre {
  font-size: 100%;
}

/**====== Animation css end ======**/
/**====== Modal style css Start ======**/
.theme-close {
  opacity: 1;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: #fff !important;
}

/**====== Modal style css end ======**/
/**====== Animation css start ======**/
.options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.options > div {
  color: #cccccc;
  padding: 2px 10px;
  border: 1px solid;
}
.options > div:hover {
  background-color: #307EF3;
  color: #fff;
  transition: background-color 0.3s ease;
}

/**====== Animation css Ends ======**/
.modal-footer {
  flex-wrap: wrap;
}

.img-cropper .img-container {
  min-height: auto;
  margin-bottom: 0;
}
.img-cropper .docs-data > .input-group:last-child {
  margin-bottom: 0;
}
.img-cropper .docs-preview {
  margin-top: 20px;
  margin-bottom: 10px;
}

.dropzone .dz-preview .dz-error-message {
  background: #fff !important;
  color: #307EF3 !important;
  border: 1px solid #307EF3;
}
.dropzone .dz-preview .dz-error-message:after {
  border-bottom: 6px solid #307EF3 !important;
}

.typeahead .theme-form .form-group {
  margin-bottom: 0;
}

.primary .svg-fill {
  fill: var(--theme-default);
}
.primary .svg-fill:not(.half-circle) {
  stroke: var(--theme-default);
}
.primary .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(48, 126, 243, 0.1) 19.06%, rgba(48, 126, 243, 0) 79.03%) !important;
}

.secondary .svg-fill {
  fill: var(--theme-secondary);
}
.secondary .svg-fill:not(.half-circle) {
  stroke: var(--theme-secondary);
}
.secondary .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(235, 163, 29, 0.1) 19.06%, rgba(235, 163, 29, 0) 79.03%) !important;
}

.success .svg-fill {
  fill: #53A653;
}
.success .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(83, 166, 83, 0.1) 19.06%, rgba(83, 166, 83, 0) 79.03%) !important;
}

.warning .svg-fill {
  fill: #FFAA05;
}
.warning .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(255, 170, 5, 0.1) 19.06%, rgba(255, 170, 5, 0) 79.03%) !important;
}

.dotted {
  border-style: dotted;
}

.dashed {
  border-style: dashed;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.ridge {
  border-style: ridge;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

@keyframes fadeIncustom {
  0% {
    opacity: 0;
    top: 50px;
  }
  75% {
    opacity: 0.6;
    top: 0;
  }
  100% {
    opacity: 1;
  }
}
.code-box-copy {
  width: 100%;
  overflow: auto;
}

@media (max-width: 1660px) and (min-width: 1400px) {
  .custom-rs-4 {
    width: 33.33333333%;
  }
  .custom-rs-12 {
    width: 100%;
  }
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-marker {
  width: 10px;
  height: 10px;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-goals-group,
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-z-group {
  display: none;
}

.bg-gray {
  background-color: var(--text-gray);
}

.text-gray {
  color: var(--text-gray) !important;
}

.common-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Override the default tooltip arrow color */
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: rgba(48, 126, 243, 0.2);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: rgba(48, 126, 243, 0.2);
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: rgba(48, 126, 243, 0.2);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: rgba(48, 126, 243, 0.2);
}

@media screen and (max-width: 1440px) and (min-width: 768px) {
  .proorder-xl-1 {
    order: 1;
  }
  .proorder-xl-2 {
    order: 2;
  }
  .proorder-xl-3 {
    order: 3;
  }
  .proorder-xl-4 {
    order: 4;
  }
  .proorder-xl-5 {
    order: 5;
  }
  .proorder-xl-6 {
    order: 6;
  }
  .proorder-xl-7 {
    order: 7;
  }
  .proorder-xl-8 {
    order: 8;
  }
  .proorder-xl-9 {
    order: 9;
  }
  .proorder-xl-10 {
    order: 10;
  }
  .proorder-xl-11 {
    order: 11;
  }
  .proorder-xl-12 {
    order: 12;
  }
  .proorder-xl-13 {
    order: 13;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .proorder-1 {
    order: 1;
  }
  .proorder-2 {
    order: 2;
  }
  .proorder-3 {
    order: 3;
  }
  .proorder-4 {
    order: 4;
  }
  .proorder-5 {
    order: 5;
  }
  .proorder-6 {
    order: 6;
  }
  .proorder-7 {
    order: 7;
  }
  .proorder-8 {
    order: 8;
  }
  .proorder-9 {
    order: 9;
  }
  .proorder-10 {
    order: 10;
  }
  .proorder-11 {
    order: 11;
  }
  .proorder-12 {
    order: 12;
  }
  .proorder-13 {
    order: 13;
  }
}
/**=====================
    1.1 Reset CSS Ends
==========================**/
/**=====================
    1.2 Typography CSS start
==========================**/
body {
  font-size: 14px;
  overflow-x: hidden;
  font-family: Outfit, sans-serif;
  color: #363636;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
}

* a {
  color: var(--theme-default);
  text-decoration: none;
  letter-spacing: 1px;
}

.f-m-light {
  margin: 0;
}

a {
  font-size: 0.875rem;
  text-decoration: none;
  color: var(--theme-default);
  transition: 0.5s all;
}
a:hover {
  color: var(--theme-default);
  transition: all 0.5s ease-in;
}
a:focus-visible {
  outline: none;
}

.btn {
  font-size: 14px;
}

*.btn:focus {
  box-shadow: none !important;
}

p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

code {
  color: var(--theme-default) !important;
  border-radius: 2px;
}

span {
  display: inline-block;
}

.d-flex {
  display: flex;
  align-items: flex-start;
}
.d-flex .flex-grow-1 {
  flex: 1;
}

blockquote {
  border-left: 4px solid #307EF3;
  padding: 18px;
}
[dir=rtl] blockquote {
  border-left: none;
  border-right: 4px solid #307EF3;
}

.figure.text-center blockquote {
  border: none !important;
}
.figure.text-end blockquote {
  border-left: none !important;
  border-right: 4px solid #307EF3;
}
[dir=rtl] .figure.text-end blockquote {
  border-right: none;
  border-left: 4px solid #307EF3 !important;
}

.row > div {
  position: relative;
}

:focus {
  outline-color: var(--theme-default);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input:focus {
  outline-color: transparent;
}

label {
  margin-bottom: 0.5rem;
}

.media-widgets .flex-grow-1 {
  margin-bottom: 30px;
}

.typography h1,
.typography .h1,
.typography h2,
.typography .h2,
.typography h3,
.typography .h3,
.typography h4,
.typography .h4,
.typography h5,
.typography .h5,
.typography h6,
.typography .h6 {
  margin: 18px 0;
  margin-top: 0;
}
.typography h1:last-child,
.typography .h1:last-child,
.typography h2:last-child,
.typography .h2:last-child,
.typography h3:last-child,
.typography .h3:last-child,
.typography h4:last-child,
.typography .h4:last-child,
.typography h5:last-child,
.typography .h5:last-child,
.typography h6:last-child,
.typography .h6:last-child {
  margin-bottom: 0;
}
.typography small {
  padding-left: 10px;
  color: #2c323f;
}

pre {
  background-color: rgba(48, 126, 243, 0.03);
  padding: 20px 0;
}

.owl-theme .owl-dots .owl-dot span {
  background: #90baf9;
  width: 8px;
  height: 8px;
  margin: 5px 3px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--theme-default);
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-bottom: -10px;
  margin-top: 20px;
}

.click2edit ~ .note-editor.note-frame {
  margin-bottom: 30px;
}

.note-editor.note-frame {
  border-color: #F3F3F3;
}
.note-editor.fullscreen .note-toolbar-wrapper {
  height: 0 !important;
}

.note-editor.note-frame {
  border-color: #F3F3F3;
}

.modal .modal-body .card {
  box-shadow: none !important;
}

.modal-dialog .modal-content .modal-body p a {
  margin-right: 0;
}

.notify-alert .close {
  color: #fff;
}
[dir=rtl] .notify-alert .close {
  right: unset !important;
  left: 10px !important;
}
.notify-alert.alert-light .close {
  color: #363636;
}

.alert-theme button {
  top: 45px !important;
  right: 20px !important;
  color: var(--theme-default);
}
[dir=rtl] .alert-theme button {
  right: unset !important;
  left: 20px !important;
}
.alert-theme span + span + span {
  background-color: #fff;
  color: var(--theme-default);
  border-radius: 4px;
  box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
}
.alert-theme .close {
  color: var(--theme-default);
}
.alert-theme i {
  padding: 20px;
  padding-right: 0 !important;
  margin-right: 20px !important;
}
.alert-theme .progress {
  margin-top: -2px !important;
  height: 2px;
  width: 100%;
}
.alert-theme .progress .progress-bar {
  background-color: var(--theme-default);
}

.alert-copy {
  border: 1px solid var(--theme-default);
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: #fff;
  background-color: var(--theme-default);
}
.alert-copy .close {
  box-shadow: none;
  color: #fff;
  line-height: 1.4;
  font-weight: 100;
  top: -15px;
}
[dir=rtl] .alert-copy .close {
  right: unset !important;
  left: 10px !important;
}
.alert-copy .close:focus {
  outline: none;
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.editor-toolbar {
  width: 100% !important;
}
.editor-toolbar.fullscreen {
  z-index: 100;
}

.CodeMirror {
  top: 0 !important;
}

.CodeMirror-fullscreen {
  top: 50px !important;
}

.irs-from,
.irs-to,
.irs-single {
  background: var(--theme-default);
}
.irs-from:after,
.irs-to:after,
.irs-single:after {
  border-top-color: var(--theme-default);
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
  background-color: var(--theme-default);
}

.swal-button {
  background-color: var(--theme-default);
}
.swal-button:active {
  background-color: #186ff2;
}

.swal-button--cancel {
  background-color: #EFEFEE !important;
}

.clipboaard-container h6 {
  line-height: 1.6;
  font-size: 14px;
}

fieldset .bootstrap-touchspin-postfix,
fieldset .bootstrap-touchspin-prefix {
  border-radius: 0;
  padding: 0.4rem 0.75rem;
}
fieldset .input-group-lg .touchspin {
  height: 50px;
}

.f1 .f1-steps .f1-step .f1-step-icon {
  padding: 12px;
}

.form-inline .form-group {
  display: flex;
}

.input-group-text i {
  line-height: 1.5;
}

.animated-modal .form-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.card-wrapper {
  padding: 14px;
}
.card-wrapper dl dt {
  font-weight: 600;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #f1e6f5 #ffffff;
}

table tbody tr {
  vertical-align: middle;
}

.common-flex {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.typography-table thead tr th {
  font-size: 16px;
}
.typography-table tbody tr td:last-child {
  min-width: 280px;
}
.typography-table tbody tr td:first-child {
  min-width: 100px;
}

/**=====================
    1.2 Typography CSS Ends
==========================**/
/**=====================
    2.1 According CSS start
==========================**/
.default-according .avatar-showcase {
  margin-bottom: 0;
}
.default-according .card {
  margin-bottom: 0;
  box-shadow: none;
}
.default-according .card + .card {
  margin-top: 10px;
}
.default-according .card .btn-link {
  font-weight: 500;
  color: #363636;
  text-decoration: none;
}
.default-according .card .btn-link:focus, .default-according .card .btn-link:hover {
  text-decoration: none;
}
.default-according .card .card-header {
  padding: 0.75rem 1.25rem;
  border: 1px solid #EAEAEA;
}
.default-according .card .card-header i {
  position: absolute;
  left: 18px;
  font-size: 20px;
  top: 20px;
}
.default-according .card .card-header h5 {
  margin-top: 2px;
}
.default-according .card .card-body {
  border: 1px solid #EAEAEA;
  border-top: none;
}

.default-according.style-1 button {
  width: 100%;
  text-align: left;
}
.default-according.style-1 button:before {
  right: 20px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 575px) {
  .default-according.style-1 button:before {
    right: 15px;
  }
}
.default-according.style-1 button[aria-expanded=true]:before {
  content: "\eb73";
  font-family: IcoFont;
}
.default-according.style-1 button[aria-expanded=false]:before {
  content: "\eb7a";
  font-family: IcoFont;
}

.accordion-button .svg-color {
  position: absolute;
  right: 20px;
}
[dir=rtl] .accordion-button .svg-color {
  left: 20px;
  right: unset;
}
.accordion-button .svg-color::after {
  content: "";
  background-image: unset;
}
.accordion-button:not(.collapsed) {
  background-color: unset;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: unset;
}
.accordion-button::after {
  background-image: unset;
}
.accordion-button:focus {
  box-shadow: none;
}

.accordion-button.accordion-light-primary:not(.collapsed) {
  background-color: rgba(48, 126, 243, 0.2);
}
.accordion-button.accordion-light-primary:active, .accordion-button.accordion-light-primary.active {
  background-color: rgba(48, 126, 243, 0.2);
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-primary {
  background-color: rgba(48, 126, 243, 0.2) !important;
}

.accordion-button.accordion-light-secondary:not(.collapsed) {
  background-color: #FFE0E8;
}
.accordion-button.accordion-light-secondary:active, .accordion-button.accordion-light-secondary.active {
  background-color: #FFE0E8;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-secondary {
  background-color: #FFE0E8 !important;
}

.accordion-button.accordion-light-success:not(.collapsed) {
  background-color: #D6EED4;
}
.accordion-button.accordion-light-success:active, .accordion-button.accordion-light-success.active {
  background-color: #D6EED4;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-success {
  background-color: #D6EED4 !important;
}

.accordion-button.accordion-light-warning:not(.collapsed) {
  background-color: #FFF0D2;
}
.accordion-button.accordion-light-warning:active, .accordion-button.accordion-light-warning.active {
  background-color: #FFF0D2;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-warning {
  background-color: #FFF0D2 !important;
}

.accordion-button.accordion-light-info:not(.collapsed) {
  background-color: #D5F5FE;
}
.accordion-button.accordion-light-info:active, .accordion-button.accordion-light-info.active {
  background-color: #D5F5FE;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-info {
  background-color: #D5F5FE !important;
}

.accordion-button.accordion-light-light:not(.collapsed) {
  background-color: #F9F9FB;
}
.accordion-button.accordion-light-light:active, .accordion-button.accordion-light-light.active {
  background-color: #F9F9FB;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-light {
  background-color: #F9F9FB !important;
}

.accordion-button.accordion-light-danger:not(.collapsed) {
  background-color: #FEDDDB;
}
.accordion-button.accordion-light-danger:active, .accordion-button.accordion-light-danger.active {
  background-color: #FEDDDB;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-danger {
  background-color: #FEDDDB !important;
}

.accordion-button.accordion-light-dark:not(.collapsed) {
  background-color: #EAEBF2;
}
.accordion-button.accordion-light-dark:active, .accordion-button.accordion-light-dark.active {
  background-color: #EAEBF2;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-dark {
  background-color: #EAEBF2 !important;
}

.accordion-item .accordion-button .svg-wrapper {
  width: 18px;
  height: 18px;
}

.accordion-wrapper:active {
  border: 1px solid #307EF3;
  border-top: 1px solid #307EF3 !important;
}

.accordion-border .accordion-item {
  border: 0;
}

.icons-accordion .accordion-item:last-child .accordion-body {
  padding-bottom: 0 !important;
}

.accordions-content li {
  color: #2c323f;
}

.collapse-accordion .btn-dark.show {
  border-radius: 15px;
}

/**=====================
    2.1 According CSS Ends
==========================**/
/**=====================
     2.2 Alert CSS start
==========================**/
.notify-alert .close {
  background: unset;
  border: none;
  font-size: 25px;
}
[dir=rtl] .notify-alert .close {
  right: unset !important;
  left: 10px !important;
}
.notify-alert.alert-light .close {
  color: #363636;
}

.alert {
  border-radius: 0.15rem;
  padding: 15px;
}
.alert svg {
  vertical-align: middle;
}
.alert .progress {
  margin-top: 15px;
}
.alert [data-notify=icon] {
  margin-right: 10px;
  line-height: 20px;
  position: absolute;
}
.alert [data-notify=message] {
  display: inline-block;
  width: 100%;
}
.alert i {
  margin-right: 5px;
  font-size: 16px;
}
.alert p {
  margin-bottom: 0;
}
.alert .close {
  opacity: 1;
}
.alert .close span {
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
  padding-right: 0;
}

.alert-dismissible {
  padding-right: 2.5rem;
}
[dir=rtl] .alert-dismissible {
  padding-right: 15px;
  padding-left: 2.5rem;
}
.alert-dismissible .close {
  padding: 10px 1.25rem;
  top: 4px;
  padding-right: 13px;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.alert-dismissible .close:hover {
  transition: all 0.3s ease;
  opacity: 1;
  color: inherit;
}

.card-body button:focus {
  outline: none;
}
.card-body .alert:last-child {
  margin-bottom: 0;
}
.card-body .alert svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  position: absolute;
  top: 18px;
}
.card-body .alert svg ~ p {
  padding-left: 20px;
}

strong {
  display: inline-block;
}
strong ~ p {
  display: inline-block;
}

.alert-primary {
  background-color: rgba(48, 126, 243, 0.8);
  border-color: rgba(48, 126, 243, 0.9);
  color: #fff;
}
.alert-primary .progress {
  height: 5px;
  background-color: #2b7bf3;
  border-radius: 0;
}
.alert-primary .progress-bar {
  background-color: white;
}
.alert-primary .btn-close {
  filter: brightness(1) invert(1);
}
.alert-primary .alert-link {
  color: #307EF3;
}
.alert-primary hr {
  border-top-color: #307EF3;
}

.border-primary {
  border-color: #307EF3 !important;
}

.income-wrapper ul li .income-dot.dot-primary {
  background-color: #307EF3;
}

.list-group-horizontal-sm .list-group-item.border-left-primary, .list-group-horizontal-md .list-group-item.border-left-primary, .list-group-horizontal-lg .list-group-item.border-left-primary, .list-group-horizontal-xl .list-group-item.border-left-primary, .list-group-horizontal-xxl .list-group-item.border-left-primary {
  border-left: 4px solid #307EF3;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-primary {
  border-left: unset;
  border-right: 4px solid #307EF3;
}

.touchspin-wrapper .btn-touchspin.touchspin-primary {
  background-color: #307EF3;
  border: 1px solid #307EF3;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-primary {
  background-color: unset;
  border: 1px solid #307EF3;
  color: #307EF3;
}
.touchspin-wrapper input[type=number].spin-outline-primary {
  outline: 1px solid #307EF3;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-primary {
  background-color: #307EF3;
  border: 1px solid #307EF3;
  color: #fff;
}

.form-switch .form-check-input.switch-primary:checked {
  background-color: #307EF3;
  border-color: #307EF3;
}

.payment-second .mega-icons.stroke-primary {
  stroke: #307EF3;
}

.toast-primary {
  color: #307EF3 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-primary, .list-group-horizontal-md .list-group-item.border-left-primary, .list-group-horizontal-lg .list-group-item.border-left-primary, .list-group-horizontal-xl .list-group-item.border-left-primary, .list-group-horizontal-xxl .list-group-item.border-left-primary {
  border-left: 4px solid #307EF3;
}

.main-radio-toggle .btn-check.radio-light-primary:focus + .btn {
  box-shadow: 0 0 0 4px rgba(48, 126, 243, 0.55);
}

.fill-radios .radio.radio-primary input[type=radio]:checked + label::before {
  border: 10px solid #307EF3;
}
.fill-radios .radio.radio-primary input[type=radio]:checked + label::after {
  background-color: #307EF3;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-primary,
.list-group [type=radio].checkbox-primary {
  background-color: unset;
  border-color: #307EF3;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-primary:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-primary:active, .list-group .form-check-input:checked[type=checkbox].checkbox-primary.active,
.list-group [type=radio].checkbox-primary:focus,
.list-group [type=radio].checkbox-primary:active,
.list-group [type=radio].checkbox-primary.active {
  border-color: #307EF3;
  outline: 0;
  background-color: #307EF3;
}
.list-group .alert-primary.light {
  background-color: rgba(48, 126, 243, 0.4);
  border-color: rgba(48, 126, 243, 0.7);
}
.list-group .alert-primary.dark {
  background-color: #307EF3;
  border-color: #307EF3;
  color: #fafafa;
}
.list-group .alert-primary.dark .alert-link {
  color: #fff;
}
.list-group .alert-primary.outline,
.list-group .alert-primary.outline-2x {
  background-color: transparent;
  border-color: #307EF3;
  color: #307EF3;
}
.list-group .alert-primary.outline .btn-close,
.list-group .alert-primary.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-primary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #307EF3;
  color: #363636;
}
.list-group .alert-primary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #307EF3;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-primary.inverse:before {
    left: 45px;
  }
}
.list-group .alert-primary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #307EF3;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-primary.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-secondary {
  background-color: rgba(235, 163, 29, 0.8);
  border-color: rgba(235, 163, 29, 0.9);
  color: #fff;
}
.alert-secondary .progress {
  height: 5px;
  background-color: #eba118;
  border-radius: 0;
}
.alert-secondary .progress-bar {
  background-color: white;
}
.alert-secondary .btn-close {
  filter: brightness(1) invert(1);
}
.alert-secondary .alert-link {
  color: #EBA31D;
}
.alert-secondary hr {
  border-top-color: #EBA31D;
}

.border-secondary {
  border-color: #EBA31D !important;
}

.income-wrapper ul li .income-dot.dot-secondary {
  background-color: #EBA31D;
}

.list-group-horizontal-sm .list-group-item.border-left-secondary, .list-group-horizontal-md .list-group-item.border-left-secondary, .list-group-horizontal-lg .list-group-item.border-left-secondary, .list-group-horizontal-xl .list-group-item.border-left-secondary, .list-group-horizontal-xxl .list-group-item.border-left-secondary {
  border-left: 4px solid #EBA31D;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-secondary {
  border-left: unset;
  border-right: 4px solid #EBA31D;
}

.touchspin-wrapper .btn-touchspin.touchspin-secondary {
  background-color: #EBA31D;
  border: 1px solid #EBA31D;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-secondary {
  background-color: unset;
  border: 1px solid #EBA31D;
  color: #EBA31D;
}
.touchspin-wrapper input[type=number].spin-outline-secondary {
  outline: 1px solid #EBA31D;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-secondary {
  background-color: #EBA31D;
  border: 1px solid #EBA31D;
  color: #fff;
}

.form-switch .form-check-input.switch-secondary:checked {
  background-color: #EBA31D;
  border-color: #EBA31D;
}

.payment-second .mega-icons.stroke-secondary {
  stroke: #EBA31D;
}

.toast-secondary {
  color: #EBA31D !important;
}

.list-group-horizontal-sm .list-group-item.border-left-secondary, .list-group-horizontal-md .list-group-item.border-left-secondary, .list-group-horizontal-lg .list-group-item.border-left-secondary, .list-group-horizontal-xl .list-group-item.border-left-secondary, .list-group-horizontal-xxl .list-group-item.border-left-secondary {
  border-left: 4px solid #EBA31D;
}

.main-radio-toggle .btn-check.radio-light-secondary:focus + .btn {
  box-shadow: 0 0 0 4px rgba(235, 163, 29, 0.55);
}

.fill-radios .radio.radio-secondary input[type=radio]:checked + label::before {
  border: 10px solid #EBA31D;
}
.fill-radios .radio.radio-secondary input[type=radio]:checked + label::after {
  background-color: #EBA31D;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-secondary,
.list-group [type=radio].checkbox-secondary {
  background-color: unset;
  border-color: #EBA31D;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-secondary:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-secondary:active, .list-group .form-check-input:checked[type=checkbox].checkbox-secondary.active,
.list-group [type=radio].checkbox-secondary:focus,
.list-group [type=radio].checkbox-secondary:active,
.list-group [type=radio].checkbox-secondary.active {
  border-color: #EBA31D;
  outline: 0;
  background-color: #EBA31D;
}
.list-group .alert-secondary.light {
  background-color: rgba(235, 163, 29, 0.4);
  border-color: rgba(235, 163, 29, 0.7);
}
.list-group .alert-secondary.dark {
  background-color: #EBA31D;
  border-color: #EBA31D;
  color: #fafafa;
}
.list-group .alert-secondary.dark .alert-link {
  color: #fff;
}
.list-group .alert-secondary.outline,
.list-group .alert-secondary.outline-2x {
  background-color: transparent;
  border-color: #EBA31D;
  color: #EBA31D;
}
.list-group .alert-secondary.outline .btn-close,
.list-group .alert-secondary.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-secondary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #EBA31D;
  color: #363636;
}
.list-group .alert-secondary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #EBA31D;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-secondary.inverse:before {
    left: 45px;
  }
}
.list-group .alert-secondary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #EBA31D;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-secondary.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-success {
  background-color: rgba(83, 166, 83, 0.8);
  border-color: rgba(83, 166, 83, 0.9);
  color: #fff;
}
.alert-success .progress {
  height: 5px;
  background-color: #51a351;
  border-radius: 0;
}
.alert-success .progress-bar {
  background-color: #fbfdfb;
}
.alert-success .btn-close {
  filter: brightness(1) invert(1);
}
.alert-success .alert-link {
  color: #53A653;
}
.alert-success hr {
  border-top-color: #53A653;
}

.border-success {
  border-color: #53A653 !important;
}

.income-wrapper ul li .income-dot.dot-success {
  background-color: #53A653;
}

.list-group-horizontal-sm .list-group-item.border-left-success, .list-group-horizontal-md .list-group-item.border-left-success, .list-group-horizontal-lg .list-group-item.border-left-success, .list-group-horizontal-xl .list-group-item.border-left-success, .list-group-horizontal-xxl .list-group-item.border-left-success {
  border-left: 4px solid #53A653;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-success {
  border-left: unset;
  border-right: 4px solid #53A653;
}

.touchspin-wrapper .btn-touchspin.touchspin-success {
  background-color: #53A653;
  border: 1px solid #53A653;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-success {
  background-color: unset;
  border: 1px solid #53A653;
  color: #53A653;
}
.touchspin-wrapper input[type=number].spin-outline-success {
  outline: 1px solid #53A653;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-success {
  background-color: #53A653;
  border: 1px solid #53A653;
  color: #fff;
}

.form-switch .form-check-input.switch-success:checked {
  background-color: #53A653;
  border-color: #53A653;
}

.payment-second .mega-icons.stroke-success {
  stroke: #53A653;
}

.toast-success {
  color: #53A653 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-success, .list-group-horizontal-md .list-group-item.border-left-success, .list-group-horizontal-lg .list-group-item.border-left-success, .list-group-horizontal-xl .list-group-item.border-left-success, .list-group-horizontal-xxl .list-group-item.border-left-success {
  border-left: 4px solid #53A653;
}

.main-radio-toggle .btn-check.radio-light-success:focus + .btn {
  box-shadow: 0 0 0 4px rgba(83, 166, 83, 0.55);
}

.fill-radios .radio.radio-success input[type=radio]:checked + label::before {
  border: 10px solid #53A653;
}
.fill-radios .radio.radio-success input[type=radio]:checked + label::after {
  background-color: #53A653;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-success,
.list-group [type=radio].checkbox-success {
  background-color: unset;
  border-color: #53A653;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-success:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-success:active, .list-group .form-check-input:checked[type=checkbox].checkbox-success.active,
.list-group [type=radio].checkbox-success:focus,
.list-group [type=radio].checkbox-success:active,
.list-group [type=radio].checkbox-success.active {
  border-color: #53A653;
  outline: 0;
  background-color: #53A653;
}
.list-group .alert-success.light {
  background-color: rgba(83, 166, 83, 0.4);
  border-color: rgba(83, 166, 83, 0.7);
}
.list-group .alert-success.dark {
  background-color: #53A653;
  border-color: #53A653;
  color: #fafafa;
}
.list-group .alert-success.dark .alert-link {
  color: #fff;
}
.list-group .alert-success.outline,
.list-group .alert-success.outline-2x {
  background-color: transparent;
  border-color: #53A653;
  color: #53A653;
}
.list-group .alert-success.outline .btn-close,
.list-group .alert-success.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-success.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #53A653;
  color: #363636;
}
.list-group .alert-success.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #53A653;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-success.inverse:before {
    left: 45px;
  }
}
.list-group .alert-success.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #53A653;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-success.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.8);
  border-color: rgba(220, 53, 69, 0.9);
  color: #fff;
}
.alert-danger .progress {
  height: 5px;
  background-color: #db3141;
  border-radius: 0;
}
.alert-danger .progress-bar {
  background-color: white;
}
.alert-danger .btn-close {
  filter: brightness(1) invert(1);
}
.alert-danger .alert-link {
  color: #DC3545;
}
.alert-danger hr {
  border-top-color: #DC3545;
}

.border-danger {
  border-color: #DC3545 !important;
}

.income-wrapper ul li .income-dot.dot-danger {
  background-color: #DC3545;
}

.list-group-horizontal-sm .list-group-item.border-left-danger, .list-group-horizontal-md .list-group-item.border-left-danger, .list-group-horizontal-lg .list-group-item.border-left-danger, .list-group-horizontal-xl .list-group-item.border-left-danger, .list-group-horizontal-xxl .list-group-item.border-left-danger {
  border-left: 4px solid #DC3545;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-danger {
  border-left: unset;
  border-right: 4px solid #DC3545;
}

.touchspin-wrapper .btn-touchspin.touchspin-danger {
  background-color: #DC3545;
  border: 1px solid #DC3545;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-danger {
  background-color: unset;
  border: 1px solid #DC3545;
  color: #DC3545;
}
.touchspin-wrapper input[type=number].spin-outline-danger {
  outline: 1px solid #DC3545;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-danger {
  background-color: #DC3545;
  border: 1px solid #DC3545;
  color: #fff;
}

.form-switch .form-check-input.switch-danger:checked {
  background-color: #DC3545;
  border-color: #DC3545;
}

.payment-second .mega-icons.stroke-danger {
  stroke: #DC3545;
}

.toast-danger {
  color: #DC3545 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-danger, .list-group-horizontal-md .list-group-item.border-left-danger, .list-group-horizontal-lg .list-group-item.border-left-danger, .list-group-horizontal-xl .list-group-item.border-left-danger, .list-group-horizontal-xxl .list-group-item.border-left-danger {
  border-left: 4px solid #DC3545;
}

.main-radio-toggle .btn-check.radio-light-danger:focus + .btn {
  box-shadow: 0 0 0 4px rgba(220, 53, 69, 0.55);
}

.fill-radios .radio.radio-danger input[type=radio]:checked + label::before {
  border: 10px solid #DC3545;
}
.fill-radios .radio.radio-danger input[type=radio]:checked + label::after {
  background-color: #DC3545;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-danger,
.list-group [type=radio].checkbox-danger {
  background-color: unset;
  border-color: #DC3545;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-danger:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-danger:active, .list-group .form-check-input:checked[type=checkbox].checkbox-danger.active,
.list-group [type=radio].checkbox-danger:focus,
.list-group [type=radio].checkbox-danger:active,
.list-group [type=radio].checkbox-danger.active {
  border-color: #DC3545;
  outline: 0;
  background-color: #DC3545;
}
.list-group .alert-danger.light {
  background-color: rgba(220, 53, 69, 0.4);
  border-color: rgba(220, 53, 69, 0.7);
}
.list-group .alert-danger.dark {
  background-color: #DC3545;
  border-color: #DC3545;
  color: #fafafa;
}
.list-group .alert-danger.dark .alert-link {
  color: #fff;
}
.list-group .alert-danger.outline,
.list-group .alert-danger.outline-2x {
  background-color: transparent;
  border-color: #DC3545;
  color: #DC3545;
}
.list-group .alert-danger.outline .btn-close,
.list-group .alert-danger.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-danger.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #DC3545;
  color: #363636;
}
.list-group .alert-danger.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #DC3545;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-danger.inverse:before {
    left: 45px;
  }
}
.list-group .alert-danger.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #DC3545;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-danger.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-warning {
  background-color: rgba(255, 170, 5, 0.8);
  border-color: rgba(255, 170, 5, 0.9);
  color: #fff;
}
.alert-warning .progress {
  height: 5px;
  background-color: #ffa800;
  border-radius: 0;
}
.alert-warning .progress-bar {
  background-color: white;
}
.alert-warning .btn-close {
  filter: brightness(1) invert(1);
}
.alert-warning .alert-link {
  color: #FFAA05;
}
.alert-warning hr {
  border-top-color: #FFAA05;
}

.border-warning {
  border-color: #FFAA05 !important;
}

.income-wrapper ul li .income-dot.dot-warning {
  background-color: #FFAA05;
}

.list-group-horizontal-sm .list-group-item.border-left-warning, .list-group-horizontal-md .list-group-item.border-left-warning, .list-group-horizontal-lg .list-group-item.border-left-warning, .list-group-horizontal-xl .list-group-item.border-left-warning, .list-group-horizontal-xxl .list-group-item.border-left-warning {
  border-left: 4px solid #FFAA05;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-warning {
  border-left: unset;
  border-right: 4px solid #FFAA05;
}

.touchspin-wrapper .btn-touchspin.touchspin-warning {
  background-color: #FFAA05;
  border: 1px solid #FFAA05;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-warning {
  background-color: unset;
  border: 1px solid #FFAA05;
  color: #FFAA05;
}
.touchspin-wrapper input[type=number].spin-outline-warning {
  outline: 1px solid #FFAA05;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-warning {
  background-color: #FFAA05;
  border: 1px solid #FFAA05;
  color: #fff;
}

.form-switch .form-check-input.switch-warning:checked {
  background-color: #FFAA05;
  border-color: #FFAA05;
}

.payment-second .mega-icons.stroke-warning {
  stroke: #FFAA05;
}

.toast-warning {
  color: #FFAA05 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-warning, .list-group-horizontal-md .list-group-item.border-left-warning, .list-group-horizontal-lg .list-group-item.border-left-warning, .list-group-horizontal-xl .list-group-item.border-left-warning, .list-group-horizontal-xxl .list-group-item.border-left-warning {
  border-left: 4px solid #FFAA05;
}

.main-radio-toggle .btn-check.radio-light-warning:focus + .btn {
  box-shadow: 0 0 0 4px rgba(255, 170, 5, 0.55);
}

.fill-radios .radio.radio-warning input[type=radio]:checked + label::before {
  border: 10px solid #FFAA05;
}
.fill-radios .radio.radio-warning input[type=radio]:checked + label::after {
  background-color: #FFAA05;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-warning,
.list-group [type=radio].checkbox-warning {
  background-color: unset;
  border-color: #FFAA05;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-warning:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-warning:active, .list-group .form-check-input:checked[type=checkbox].checkbox-warning.active,
.list-group [type=radio].checkbox-warning:focus,
.list-group [type=radio].checkbox-warning:active,
.list-group [type=radio].checkbox-warning.active {
  border-color: #FFAA05;
  outline: 0;
  background-color: #FFAA05;
}
.list-group .alert-warning.light {
  background-color: rgba(255, 170, 5, 0.4);
  border-color: rgba(255, 170, 5, 0.7);
}
.list-group .alert-warning.dark {
  background-color: #FFAA05;
  border-color: #FFAA05;
  color: #fafafa;
}
.list-group .alert-warning.dark .alert-link {
  color: #fff;
}
.list-group .alert-warning.outline,
.list-group .alert-warning.outline-2x {
  background-color: transparent;
  border-color: #FFAA05;
  color: #FFAA05;
}
.list-group .alert-warning.outline .btn-close,
.list-group .alert-warning.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-warning.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #FFAA05;
  color: #363636;
}
.list-group .alert-warning.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #FFAA05;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-warning.inverse:before {
    left: 45px;
  }
}
.list-group .alert-warning.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #FFAA05;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-warning.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-info {
  background-color: rgba(1, 161, 185, 0.8);
  border-color: rgba(1, 161, 185, 0.9);
  color: #fff;
}
.alert-info .progress {
  height: 5px;
  background-color: #019db4;
  border-radius: 0;
}
.alert-info .progress-bar {
  background-color: #baf6ff;
}
.alert-info .btn-close {
  filter: brightness(1) invert(1);
}
.alert-info .alert-link {
  color: #01A1B9;
}
.alert-info hr {
  border-top-color: #01A1B9;
}

.border-info {
  border-color: #01A1B9 !important;
}

.income-wrapper ul li .income-dot.dot-info {
  background-color: #01A1B9;
}

.list-group-horizontal-sm .list-group-item.border-left-info, .list-group-horizontal-md .list-group-item.border-left-info, .list-group-horizontal-lg .list-group-item.border-left-info, .list-group-horizontal-xl .list-group-item.border-left-info, .list-group-horizontal-xxl .list-group-item.border-left-info {
  border-left: 4px solid #01A1B9;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-info {
  border-left: unset;
  border-right: 4px solid #01A1B9;
}

.touchspin-wrapper .btn-touchspin.touchspin-info {
  background-color: #01A1B9;
  border: 1px solid #01A1B9;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-info {
  background-color: unset;
  border: 1px solid #01A1B9;
  color: #01A1B9;
}
.touchspin-wrapper input[type=number].spin-outline-info {
  outline: 1px solid #01A1B9;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-info {
  background-color: #01A1B9;
  border: 1px solid #01A1B9;
  color: #fff;
}

.form-switch .form-check-input.switch-info:checked {
  background-color: #01A1B9;
  border-color: #01A1B9;
}

.payment-second .mega-icons.stroke-info {
  stroke: #01A1B9;
}

.toast-info {
  color: #01A1B9 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-info, .list-group-horizontal-md .list-group-item.border-left-info, .list-group-horizontal-lg .list-group-item.border-left-info, .list-group-horizontal-xl .list-group-item.border-left-info, .list-group-horizontal-xxl .list-group-item.border-left-info {
  border-left: 4px solid #01A1B9;
}

.main-radio-toggle .btn-check.radio-light-info:focus + .btn {
  box-shadow: 0 0 0 4px rgba(1, 161, 185, 0.55);
}

.fill-radios .radio.radio-info input[type=radio]:checked + label::before {
  border: 10px solid #01A1B9;
}
.fill-radios .radio.radio-info input[type=radio]:checked + label::after {
  background-color: #01A1B9;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-info,
.list-group [type=radio].checkbox-info {
  background-color: unset;
  border-color: #01A1B9;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-info:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-info:active, .list-group .form-check-input:checked[type=checkbox].checkbox-info.active,
.list-group [type=radio].checkbox-info:focus,
.list-group [type=radio].checkbox-info:active,
.list-group [type=radio].checkbox-info.active {
  border-color: #01A1B9;
  outline: 0;
  background-color: #01A1B9;
}
.list-group .alert-info.light {
  background-color: rgba(1, 161, 185, 0.4);
  border-color: rgba(1, 161, 185, 0.7);
}
.list-group .alert-info.dark {
  background-color: #01A1B9;
  border-color: #01A1B9;
  color: #fafafa;
}
.list-group .alert-info.dark .alert-link {
  color: #fff;
}
.list-group .alert-info.outline,
.list-group .alert-info.outline-2x {
  background-color: transparent;
  border-color: #01A1B9;
  color: #01A1B9;
}
.list-group .alert-info.outline .btn-close,
.list-group .alert-info.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-info.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #01A1B9;
  color: #363636;
}
.list-group .alert-info.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #01A1B9;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-info.inverse:before {
    left: 45px;
  }
}
.list-group .alert-info.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #01A1B9;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-info.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-light {
  background-color: rgba(243, 243, 243, 0.8);
  border-color: rgba(243, 243, 243, 0.9);
  color: #fff;
  color: #2c323f;
  background-color: #F3F3F3;
}
.alert-light .progress {
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 0;
}
.alert-light .progress-bar {
  background-color: white;
}
.alert-light .btn-close {
  filter: brightness(1) invert(1);
}
.alert-light .alert-link {
  color: #F3F3F3;
  color: #2c323f;
}
.alert-light .btn-close {
  filter: unset;
}
.alert-light hr {
  border-top-color: #F3F3F3;
}

.border-light {
  border-color: #F3F3F3 !important;
}

.income-wrapper ul li .income-dot.dot-light {
  background-color: #F3F3F3;
}

.list-group-horizontal-sm .list-group-item.border-left-light, .list-group-horizontal-md .list-group-item.border-left-light, .list-group-horizontal-lg .list-group-item.border-left-light, .list-group-horizontal-xl .list-group-item.border-left-light, .list-group-horizontal-xxl .list-group-item.border-left-light {
  border-left: 4px solid #F3F3F3;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-light {
  border-left: unset;
  border-right: 4px solid #F3F3F3;
}

.touchspin-wrapper .btn-touchspin.touchspin-light {
  background-color: #F3F3F3;
  border: 1px solid #F3F3F3;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-light {
  background-color: unset;
  border: 1px solid #F3F3F3;
  color: #F3F3F3;
}
.touchspin-wrapper input[type=number].spin-outline-light {
  outline: 1px solid #F3F3F3;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-light {
  background-color: #F3F3F3;
  border: 1px solid #F3F3F3;
  color: #fff;
}

.form-switch .form-check-input.switch-light:checked {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
}

.payment-second .mega-icons.stroke-light {
  stroke: #F3F3F3;
}

.toast-light {
  color: #F3F3F3 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-light, .list-group-horizontal-md .list-group-item.border-left-light, .list-group-horizontal-lg .list-group-item.border-left-light, .list-group-horizontal-xl .list-group-item.border-left-light, .list-group-horizontal-xxl .list-group-item.border-left-light {
  border-left: 4px solid #F3F3F3;
}

.main-radio-toggle .btn-check.radio-light-light:focus + .btn {
  box-shadow: 0 0 0 4px rgba(243, 243, 243, 0.55);
}

.fill-radios .radio.radio-light input[type=radio]:checked + label::before {
  border: 10px solid #F3F3F3;
}
.fill-radios .radio.radio-light input[type=radio]:checked + label::after {
  background-color: #F3F3F3;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-light,
.list-group [type=radio].checkbox-light {
  background-color: unset;
  border-color: #F3F3F3;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-light:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-light:active, .list-group .form-check-input:checked[type=checkbox].checkbox-light.active,
.list-group [type=radio].checkbox-light:focus,
.list-group [type=radio].checkbox-light:active,
.list-group [type=radio].checkbox-light.active {
  border-color: #F3F3F3;
  outline: 0;
  background-color: #F3F3F3;
}
.list-group .alert-light.light {
  background-color: rgba(243, 243, 243, 0.4);
  border-color: rgba(243, 243, 243, 0.7);
}
.list-group .alert-light.dark {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #fafafa;
  color: #2c323f;
}
.list-group .alert-light.dark .alert-link {
  color: #fff;
  color: #2c323f;
}
.list-group .alert-light.outline,
.list-group .alert-light.outline-2x {
  background-color: transparent;
  border-color: #F3F3F3;
  color: #F3F3F3;
  color: #2c323f;
}
.list-group .alert-light.outline .btn-close,
.list-group .alert-light.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-light.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #F3F3F3;
  color: #363636;
}
.list-group .alert-light.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #F3F3F3;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-light.inverse:before {
    left: 45px;
  }
}
.list-group .alert-light.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #F3F3F3;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-light.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.alert-dark {
  background-color: rgba(44, 50, 63, 0.8);
  border-color: rgba(44, 50, 63, 0.9);
  color: #fff;
}
.alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}
.alert-dark .progress-bar {
  background-color: #a8b0c2;
}
.alert-dark .btn-close {
  filter: brightness(1) invert(1);
}
.alert-dark .alert-link {
  color: #2c323f;
}
.alert-dark hr {
  border-top-color: #2c323f;
}

.border-dark {
  border-color: #2c323f !important;
}

.income-wrapper ul li .income-dot.dot-dark {
  background-color: #2c323f;
}

.list-group-horizontal-sm .list-group-item.border-left-dark, .list-group-horizontal-md .list-group-item.border-left-dark, .list-group-horizontal-lg .list-group-item.border-left-dark, .list-group-horizontal-xl .list-group-item.border-left-dark, .list-group-horizontal-xxl .list-group-item.border-left-dark {
  border-left: 4px solid #2c323f;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-dark {
  border-left: unset;
  border-right: 4px solid #2c323f;
}

.touchspin-wrapper .btn-touchspin.touchspin-dark {
  background-color: #2c323f;
  border: 1px solid #2c323f;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-dark {
  background-color: unset;
  border: 1px solid #2c323f;
  color: #2c323f;
}
.touchspin-wrapper input[type=number].spin-outline-dark {
  outline: 1px solid #2c323f;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-dark {
  background-color: #2c323f;
  border: 1px solid #2c323f;
  color: #fff;
}

.form-switch .form-check-input.switch-dark:checked {
  background-color: #2c323f;
  border-color: #2c323f;
}

.payment-second .mega-icons.stroke-dark {
  stroke: #2c323f;
}

.toast-dark {
  color: #2c323f !important;
}

.list-group-horizontal-sm .list-group-item.border-left-dark, .list-group-horizontal-md .list-group-item.border-left-dark, .list-group-horizontal-lg .list-group-item.border-left-dark, .list-group-horizontal-xl .list-group-item.border-left-dark, .list-group-horizontal-xxl .list-group-item.border-left-dark {
  border-left: 4px solid #2c323f;
}

.main-radio-toggle .btn-check.radio-light-dark:focus + .btn {
  box-shadow: 0 0 0 4px rgba(44, 50, 63, 0.55);
}

.fill-radios .radio.radio-dark input[type=radio]:checked + label::before {
  border: 10px solid #2c323f;
}
.fill-radios .radio.radio-dark input[type=radio]:checked + label::after {
  background-color: #2c323f;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-dark,
.list-group [type=radio].checkbox-dark {
  background-color: unset;
  border-color: #2c323f;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-dark:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-dark:active, .list-group .form-check-input:checked[type=checkbox].checkbox-dark.active,
.list-group [type=radio].checkbox-dark:focus,
.list-group [type=radio].checkbox-dark:active,
.list-group [type=radio].checkbox-dark.active {
  border-color: #2c323f;
  outline: 0;
  background-color: #2c323f;
}
.list-group .alert-dark.light {
  background-color: rgba(44, 50, 63, 0.4);
  border-color: rgba(44, 50, 63, 0.7);
}
.list-group .alert-dark.dark {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fafafa;
}
.list-group .alert-dark.dark .alert-link {
  color: #fff;
}
.list-group .alert-dark.outline,
.list-group .alert-dark.outline-2x {
  background-color: transparent;
  border-color: #2c323f;
  color: #2c323f;
}
.list-group .alert-dark.outline .btn-close,
.list-group .alert-dark.outline-2x .btn-close {
  filter: unset;
}
.list-group .alert-dark.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #2c323f;
  color: #363636;
}
.list-group .alert-dark.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #2c323f;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-dark.inverse:before {
    left: 45px;
  }
}
.list-group .alert-dark.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #2c323f;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-dark.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  filter: unset;
  top: -3px;
}

.outline-2x {
  border-width: 2px;
  padding: 11px 1.25rem;
}

.alert-icons.outline-2x {
  padding: 15px;
}
.alert-icons svg {
  position: absolute !important;
  top: 18px !important;
}

.alert-dark .btn-close,
.alert-info .btn-close {
  filter: none;
}

.border-left-wrapper {
  border-left: 4px solid #2c323f;
}

.alert-light-primary {
  background-color: rgba(48, 126, 243, 0.2);
}

.alert-light-secondary {
  background-color: #FFE0E8;
}

.alert-light-success {
  background-color: #D6EED4;
}

.alert-light-warning {
  background-color: #FFF0D2;
}

.alert-light-info {
  background-color: #D5F5FE;
}

.alert-light-light {
  background-color: #F9F9FB;
}

.alert-light-danger {
  background-color: #FEDDDB;
}

.alert-light-dark {
  background-color: #EAEBF2;
}

.alert-arrow {
  min-width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.alert-arrow i {
  margin-right: 0;
}

@media (max-width: 576px) {
  .ellipsis.content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px;
  }
}
@media (max-width: 575px) {
  .ellipsis.content {
    max-width: 180px;
  }
}

.alert-dismissible {
  display: flex;
  align-items: center;
  gap: 8px;
}

.alert.notify-alert.alert-copy {
  display: flex !important;
}

/**=====================
   2.2 Alert CSS end
==========================**/
/**=====================
  2.3 Avatars CSS start
==========================**/
.avatars .avatar {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  width: auto;
}
.avatars .avatar:last-child {
  margin-right: 0;
}
.avatars .avatar.ratio img {
  width: auto;
}
.avatars .avatar .status {
  position: absolute;
  height: 12%;
  width: 12%;
  border-radius: 100%;
  bottom: 12%;
  right: 4%;
}

.customers ul {
  display: inline-block;
}
.customers ul li img {
  border: 2px solid var(--white);
  transition: 0.5s;
}
.customers ul li img:hover {
  transform: translateY(-4px) scale(1.02);
  transition: 0.5s;
}
.customers ul li + li {
  margin-left: -7%;
}
.customers.avatar-group {
  margin-right: 30px;
}
.customers.avatar-group:last-child {
  margin-right: 0;
}

.avatar-showcase .avatars .avatar {
  margin-bottom: 10px;
}
.avatar-showcase .avatar-group {
  margin-bottom: 10px;
}
.avatar-showcase .ratio > * {
  position: relative;
  top: unset;
  left: unset;
}

/**=====================
    2.3 Avatars CSS Ends
==========================**/
/**=====================
    2.4 Badge CSS start
==========================**/
.badge-spacing {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.badge-spacing .badge {
  padding: 6px 12px;
  font-size: 12px;
}
.badge-spacing .badge.badge svg {
  padding-top: 0px;
}
.badge-spacing .badge.rounded-circle {
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.badge-spacing .badge.badge-p-space {
  padding: 10px 13px;
}
.badge-spacing .badge + .badge {
  margin-left: 0px;
}
.badge-spacing .badge.btn-p-space {
  padding: 8px 10px;
}

a.badge:hover {
  color: inherit;
}
a.badge:hover.badge-dark:hover {
  color: #fff;
}

.badge {
  padding: 0.44em 0.7em;
  font-weight: 500;
}
.badge + .badge {
  margin-left: 5px;
}
.badge svg {
  width: 16px;
  height: 16px;
  padding-top: 3px;
}

.b-ln-height {
  line-height: 5px;
}

.pill-badge-primary {
  background-color: var(--theme-default);
  box-shadow: -0.5px 4.33px 16px 0px rgba(var(--theme-default), 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-secondary {
  background-color: #EBA31D;
  box-shadow: -0.5px 4.33px 16px 0px rgba(235, 163, 29, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-success {
  background-color: #53A653;
  box-shadow: -0.5px 4.33px 16px 0px rgba(83, 166, 83, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-danger {
  background-color: #DC3545;
  box-shadow: -0.5px 4.33px 16px 0px rgba(220, 53, 69, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-info {
  background-color: #01A1B9;
  box-shadow: -0.5px 4.33px 16px 0px rgba(1, 161, 185, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-light {
  background-color: #F3F3F3;
  box-shadow: -0.5px 4.33px 16px 0px rgba(243, 243, 243, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-dark {
  background-color: #2c323f;
  box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-warning {
  background-color: #FFAA05;
  box-shadow: -0.5px 4.33px 16px 0px rgba(255, 170, 5, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-primary {
  background-color: var(--theme-default);
}

.pill-badge-secondary {
  background-color: var(--theme-secondary);
}

.round-badge-primary {
  border-radius: 6px;
  background-color: var(--theme-default);
  box-shadow: -0.5px -6.67px 14px 0px rgba(var(--theme-default), 0.18);
  color: #fff !important;
}

.round-badge-secondary {
  border-radius: 6px;
  background-color: #EBA31D;
  box-shadow: -0.5px -6.67px 14px 0px rgba(235, 163, 29, 0.18);
  color: #fff !important;
}

.round-badge-success {
  border-radius: 6px;
  background-color: #53A653;
  box-shadow: -0.5px -6.67px 14px 0px rgba(83, 166, 83, 0.18);
  color: #fff !important;
}

.round-badge-danger {
  border-radius: 6px;
  background-color: #DC3545;
  box-shadow: -0.5px -6.67px 14px 0px rgba(220, 53, 69, 0.18);
  color: #fff !important;
}

.round-badge-info {
  border-radius: 6px;
  background-color: #01A1B9;
  box-shadow: -0.5px -6.67px 14px 0px rgba(1, 161, 185, 0.18);
  color: #fff !important;
}

.round-badge-light {
  border-radius: 6px;
  background-color: #F3F3F3;
  box-shadow: -0.5px -6.67px 14px 0px rgba(243, 243, 243, 0.18);
  color: #fff !important;
}

.round-badge-dark {
  border-radius: 6px;
  background-color: #2c323f;
  box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
  color: #fff !important;
}

.round-badge-warning {
  border-radius: 6px;
  background-color: #FFAA05;
  box-shadow: -0.5px -6.67px 14px 0px rgba(255, 170, 5, 0.18);
  color: #fff !important;
}

.round-badge-primary {
  background-color: var(--theme-default);
}

.round-badge-secondary {
  background-color: var(--theme-secondary);
}

.badge-heading {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 8px;
}

/**=====================
  2.4 Badge CSS Ends
==========================**/
/**=====================
    2.5 Bookmark CSS start
==========================**/
.bookmark ul li {
  display: inline-block;
}
.bookmark ul li .search-form {
  position: absolute;
  bottom: 53px;
  z-index: 1;
}
.bookmark ul li .search-form .form-control-search {
  top: 65px;
  position: absolute;
  transition: all linear 0.3s;
  right: -20px;
  transform: translateY(-35px) scaleY(0);
  opacity: 0;
  visibility: hidden;
}
.bookmark ul li .search-form .form-control-search input {
  width: 240px;
  background-color: #fff;
  box-shadow: 0 5px 21px rgba(0, 0, 0, 0.1);
}
.bookmark ul li .search-form .form-control-search.open {
  transform: translateY(0px) scaleY(1);
  opacity: 1;
  visibility: visible;
  transition: all linear 0.3s;
  z-index: 100;
}
.bookmark ul li .search-form .form-control-search:after {
  top: 12px;
  left: 20px;
  z-index: 1;
}
.bookmark ul li .search-form .form-control-search:before {
  left: 50px;
  top: 9px;
  z-index: 2;
}
.bookmark ul li a {
  position: relative;
  color: #363636;
}
.bookmark ul li a svg {
  vertical-align: middle;
  height: 20px;
  width: 20px;
}
.bookmark ul li + li {
  margin-left: 10px;
}
.bookmark ul li:last-child {
  padding-right: 0;
}
.bookmark ul li:last-child a {
  border-radius: 5px;
}
.bookmark ul li:last-child a svg {
  color: #ff9f40;
}

.offcanvas-bookmark.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body .bookmark ul li a {
  z-index: 99999;
  color: rgba(255, 255, 255, 0.5);
}
.offcanvas-bookmark:before {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 1;
  right: 0;
  left: 0;
}

body.modal-open .select2-container--open {
  width: unset !important;
  display: block;
}
body.modal-open .select2-container--open .select2-dropdown {
  z-index: 1076;
}

/**=====================
    2.5 Bookmark CSS Ends
==========================**/
/**=====================
2.6 Breadcrumb CSS Start
==========================**/
.breadcrumb-colored {
  padding: 12px 16px;
  border-radius: 4px;
}
.breadcrumb-colored li a {
  color: #fff;
}
.breadcrumb-colored li.active {
  color: #fff;
  opacity: 0.7;
}
.breadcrumb-colored .breadcrumb-item:before {
  color: #fff;
}
.breadcrumb-colored .breadcrumb-item.txt-dark:before {
  color: #000;
}

.breadcrumb-icon {
  --bs-breadcrumb-divider: ">";
}
.breadcrumb-icon li {
  display: inline-block;
}

.breadcrumb-no-divider {
  --bs-breadcrumb-divider: "";
}

.breadcrumb-item.active {
  text-transform: capitalize;
}

.breadcrumb-space .breadcrumb-item::before {
  padding-right: 0;
}

/**=====================
	2.6 Breadcrumb CSS ends
==========================**/
/**=====================
    2.7 Basic-card CSS Start
==========================**/
.dark-color-header {
  background-color: unset !important;
  color: #307EF3;
}
.dark-color-header ul li:first-child i, .dark-color-header ul li:last-child i {
  color: #307EF3 !important;
}
.dark-color-header ul li i {
  color: #F3F3F3 !important;
}

/**=====================
  2.7 Basic-card CSS Ends
==========================**/
/**=====================
  2.9 Buttons CSS start
==========================**/
.btn-link {
  color: var(--theme-default);
}

.purchase-btn {
  background: linear-gradient(90deg, var(--theme-default) -18.33%, #563EE0 62.78%);
  padding: 12px 20px;
  min-width: 200px;
  border: none;
  transition: 0.5s;
}
.purchase-btn:hover {
  background: linear-gradient(90deg, #563EE0 -18.33%, var(--theme-default) 62.78%);
  transition: 0.5s;
}
@media (max-width: 991px) {
  .purchase-btn {
    min-width: 160px;
  }
}
@media (max-width: 767px) {
  .purchase-btn {
    min-width: 140px;
    padding: 10px 17px;
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .purchase-btn {
    font-size: 14px;
  }
}

.btn-light:not([disabled]):not(.disabled).active.active {
  color: #363636;
  background-color: #eeeeee !important;
}

.btn-primary-gradien {
  background-image: linear-gradient(to right, #5796f5 0%, #0e67ec 51%, #5796f5 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
  color: #fff;
}
.btn-primary-gradien:focus, .btn-primary-gradien:active, .btn-primary-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #5796f5, 0%, #0e67ec, 100%, #fff) !important;
}

.btn-secondary-gradien {
  background-image: linear-gradient(to right, #eeb242 0%, #cd8c12 51%, #eeb242 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-secondary-gradien:hover, .btn-secondary-gradien:focus, .btn-secondary-gradien:active, .btn-secondary-gradien.active, .btn-secondary-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #eeb242, 0%, #cd8c12, 100%, #fff) !important;
}

.btn-success-gradien {
  background-image: linear-gradient(to right, #6cb66c 0%, #458b45 51%, #6cb66c 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-success-gradien:hover, .btn-success-gradien:focus, .btn-success-gradien:active, .btn-success-gradien.active, .btn-success-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #6cb66c, 0%, #458b45, 100%, #fff) !important;
}

.btn-danger-gradien {
  background-image: linear-gradient(to right, #e25865 0%, #c62232 51%, #e25865 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-danger-gradien:hover, .btn-danger-gradien:focus, .btn-danger-gradien:active, .btn-danger-gradien.active, .btn-danger-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #e25865, 0%, #c62232, 100%, #fff) !important;
}

.btn-warning-gradien {
  background-image: linear-gradient(to right, #ffb82e 0%, #db9100 51%, #ffb82e 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-warning-gradien:hover, .btn-warning-gradien:focus, .btn-warning-gradien:active, .btn-warning-gradien.active, .btn-warning-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #ffb82e, 0%, #db9100, 100%, #fff) !important;
}

.btn-info-gradien {
  background-image: linear-gradient(to right, #01c4e2 0%, #017e90 51%, #01c4e2 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-info-gradien:hover, .btn-info-gradien:focus, .btn-info-gradien:active, .btn-info-gradien.active, .btn-info-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #01c4e2, 0%, #017e90, 100%, #fff) !important;
}

.btn-light-gradien {
  background-image: linear-gradient(to right, white 0%, #dfdfdf 51%, white 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-light-gradien:hover, .btn-light-gradien:focus, .btn-light-gradien:active, .btn-light-gradien.active, .btn-light-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, white, 0%, #dfdfdf, 100%, #fff) !important;
}

.btn-dark-gradien {
  background-image: linear-gradient(to right, #3d4557 0%, #1b1f27 51%, #3d4557 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-dark-gradien:hover, .btn-dark-gradien:focus, .btn-dark-gradien:active, .btn-dark-gradien.active, .btn-dark-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #3d4557, 0%, #1b1f27, 100%, #fff) !important;
}

.btn {
  padding: 0.375rem 1.75rem;
}

.btn-lg {
  font-size: 18px;
}

.btn-sm {
  font-size: 12px;
}

.btn-xs {
  padding: 0.05rem 0.4rem;
  font-size: 11px;
}

.large-btn {
  margin-bottom: -20px;
}
.large-btn .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

.btn-outline-white {
  border: 1px solid #fff;
  color: #fff;
}
.btn-outline-white:hover, .btn-outline-white:focus {
  background: #fff;
}

.btn-air-primary {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-primary:hover, .btn-air-primary:active, .btn-air-primary:not([disabled]):not(.disabled):active {
  background-color: #0d63e3;
  border-color: #0d63e3;
}

.input-air-primary {
  box-shadow: 0 3px 5px 1px rgba(48, 126, 243, 0.1) !important;
}
.input-air-primary:focus {
  border-color: #307EF3;
}

.btn-air-secondary {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-secondary:hover, .btn-air-secondary:active, .btn-air-secondary:not([disabled]):not(.disabled):active {
  background-color: #c48511;
  border-color: #c48511;
}

.input-air-secondary {
  box-shadow: 0 3px 5px 1px rgba(235, 163, 29, 0.1) !important;
}
.input-air-secondary:focus {
  border-color: #EBA31D;
}

.btn-air-success {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-success:hover, .btn-air-success:active, .btn-air-success:not([disabled]):not(.disabled):active {
  background-color: #428442;
  border-color: #428442;
}

.input-air-success {
  box-shadow: 0 3px 5px 1px rgba(83, 166, 83, 0.1) !important;
}
.input-air-success:focus {
  border-color: #53A653;
}

.btn-air-danger {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-danger:hover, .btn-air-danger:active, .btn-air-danger:not([disabled]):not(.disabled):active {
  background-color: #bd2130;
  border-color: #bd2130;
}

.input-air-danger {
  box-shadow: 0 3px 5px 1px rgba(220, 53, 69, 0.1) !important;
}
.input-air-danger:focus {
  border-color: #DC3545;
}

.btn-air-info {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-info:hover, .btn-air-info:active, .btn-air-info:not([disabled]):not(.disabled):active {
  background-color: #017586;
  border-color: #017586;
}

.input-air-info {
  box-shadow: 0 3px 5px 1px rgba(1, 161, 185, 0.1) !important;
}
.input-air-info:focus {
  border-color: #01A1B9;
}

.btn-air-light {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-light:hover, .btn-air-light:active, .btn-air-light:not([disabled]):not(.disabled):active {
  background-color: #dadada;
  border-color: #dadada;
}

.input-air-light {
  box-shadow: 0 3px 5px 1px rgba(243, 243, 243, 0.1) !important;
}
.input-air-light:focus {
  border-color: #F3F3F3;
}

.btn-air-dark {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-dark:hover, .btn-air-dark:active, .btn-air-dark:not([disabled]):not(.disabled):active {
  background-color: #171a21;
  border-color: #171a21;
}

.input-air-dark {
  box-shadow: 0 3px 5px 1px rgba(44, 50, 63, 0.1) !important;
}
.input-air-dark:focus {
  border-color: #2c323f;
}

.btn-air-warning {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-warning:hover, .btn-air-warning:active, .btn-air-warning:not([disabled]):not(.disabled):active {
  background-color: #d18a00;
  border-color: #d18a00;
}

.input-air-warning {
  box-shadow: 0 3px 5px 1px rgba(255, 170, 5, 0.1) !important;
}
.input-air-warning:focus {
  border-color: #FFAA05;
}

.btn-gradient {
  color: #fff !important;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
  background: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
  letter-spacing: 0.06rem;
}
.btn-gradient:hover {
  background-size: 100% !important;
}

.btn-warning {
  color: #fff;
}
.btn-warning:hover, .btn-warning.disabled {
  color: #fff;
}

[class*=-gradien]:hover {
  background-size: 50% 100%;
  transition: all 0.3s ease;
  color: #fff;
}

.btn-square {
  border-radius: 0px;
}

.btn-pill {
  border-radius: 60px;
}

.btn-group-pill .btn:first-child {
  border-radius: 60px 0 0 60px;
}
.btn-group-pill .btn:last-child {
  border-radius: 0 60px 60px 0;
}

.btn-group-square .btn:first-child {
  border-radius: 0px 0 0 0px;
}
.btn-group-square .btn:last-child {
  border-radius: 0 0px 0px 0;
}

.btn-showcase .btn {
  margin-bottom: 10px;
  margin-right: 10px;
}

.btn-dropdown-showcase {
  margin-bottom: -10px;
}
.btn-dropdown-showcase .dropdown {
  margin-bottom: 10px;
  margin-right: 18px;
}
.btn-dropdown-showcase .btn-group {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-group .radio label::before {
  top: 2px;
}
.btn-group .radio label::after {
  top: 7px;
}
.btn-group .checkbox label {
  margin-top: 3px;
}

.btn-group-showcase > div {
  margin-bottom: -20px;
}
.btn-group-showcase > div label {
  margin-bottom: 0;
}
.btn-group-showcase .btn-group {
  margin-right: 20px;
  margin-bottom: 20px;
}
.btn-group-showcase .checkbox input[type=checkbox] {
  display: none;
}

.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.card-header-right-icon .btn.badge-light-primary:hover {
  color: var(--white);
}

/**=====================
    2.9 Buttons CSS end
==========================**/
/**=====================
    2.10 Card CSS Start
==========================**/
.card {
  margin-bottom: 30px;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
}
.card:hover {
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  transition: all 0.3s ease;
}
.card .card-header {
  background-color: #fff;
  padding: 18px;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
}
.card .card-header.card-no-border {
  border-bottom: none !important;
}
.card .card-header h5:not(.mb-0), .card .card-header h5:not(.m-0) {
  margin-bottom: 0;
  text-transform: capitalize;
}
.card .card-header p {
  color: #9B9B9B;
}
.card .card-header > span {
  font-size: 12px;
  color: #9B9B9B;
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
}
.card .card-header .card-header-right {
  border-radius: 25px;
  right: 12px;
  top: 12px;
  display: inline-block;
  float: right;
  padding: 8px 0;
  position: absolute;
  background-color: rgba(48, 126, 243, 0.2) !important;
  z-index: 1;
}
.card .card-header .card-header-right .card-option {
  text-align: center;
  width: 35px;
  height: 20px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.card .card-header .card-header-right .card-option li {
  display: inline-block;
}
.card .card-header .card-header-right .card-option li:first-child i {
  transition: 1s;
  font-size: 16px;
  color: #307EF3 !important;
}
.card .card-header .card-header-right .card-option li:first-child i.icofont {
  color: unset;
}
.card .card-header .card-header-right i {
  margin: 0 5px;
  cursor: pointer;
  color: #2c323f;
  line-height: 20px;
}
.card .card-header .card-header-right i.icofont-refresh {
  font-size: 13px;
}
.card .card-body {
  padding: 18px;
  border-radius: 5px;
  background-color: #fff;
}
.card .card-body p:last-child {
  margin-bottom: 0;
}
.card .sub-title {
  padding-bottom: 12px;
  font-size: calc(15px + 3 * (100vw - 320px) / 1600);
}
.card .card-footer {
  background-color: #fff;
  border-top: 1px solid #EAEAEA;
  padding: 18px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  align-items: center;
  justify-content: center;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: var(--theme-default);
  font-size: 20px;
}
.card.full-card {
  position: fixed;
  top: 0;
  z-index: 99999;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #EAEAEA;
  width: calc(100vw - 12px);
  height: 100vh;
}
.card.full-card .card-body {
  overflow: auto;
}

.page-body-wrapper .card .sub-title {
  font-family: Outfit, sans-serif;
  font-weight: normal;
  color: #363636;
}

.card-absolute {
  margin-top: 20px;
}
.card-absolute .card-header {
  position: absolute;
  top: -20px;
  left: 15px;
  border-radius: 0.25rem;
  padding: 10px 15px;
}
.card-absolute .card-header h5 {
  font-size: 17px;
}
.card-absolute .card-body {
  margin-top: 10px;
}

.card-header .border-tab {
  margin-bottom: -13px;
}

.custom-card {
  overflow: hidden;
  padding: 30px;
}
.custom-card .card-header {
  padding: 0;
}
.custom-card .card-header img {
  border-radius: 50%;
  margin-top: -100px;
  transform: scale(1.5);
}
.custom-card .card-profile {
  text-align: center;
}
.custom-card .card-profile img {
  height: 110px;
  padding: 7px;
  background-color: #fff;
  z-index: 1;
  position: relative;
}
.custom-card .card-social {
  text-align: center;
}
.custom-card .card-social li {
  display: inline-block;
  padding: 15px 0;
}
.custom-card .card-social li:last-child a {
  margin-right: 0;
}
.custom-card .card-social li a {
  padding: 0;
  margin-right: 15px;
  color: rgb(188, 198, 222);
  font-size: 16px;
  transition: all 0.3s ease;
}
.custom-card .card-social li a:hover {
  color: var(--theme-default);
  transition: all 0.3s ease;
}
.custom-card .profile-details h6 {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #9B9B9B;
  font-size: 14px;
}
.custom-card .card-footer {
  padding: 0;
}
.custom-card .card-footer > div {
  padding: 15px;
  text-align: center;
}
.custom-card .card-footer > div + div {
  border-left: 1px solid #EAEAEA;
}
.custom-card .card-footer > div h3 {
  margin-bottom: 0;
  font-size: 24px;
}
.custom-card .card-footer > div h6 {
  font-size: 14px;
  color: #9B9B9B;
}
.custom-card .card-footer > div h5 {
  font-size: 16px;
  margin-bottom: 0;
}
.custom-card .card-footer > div i {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 15px;
}
.custom-card .card-footer > div .m-b-card {
  margin-bottom: 10px;
}

.social-profile {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.social-profile .card-body {
  padding-left: 20px;
  padding-right: 20px;
  background: rgba(48, 126, 243, 0.05);
}
@media (max-width: 1399px) {
  .social-profile .card-body {
    padding: 23px 15px;
  }
}
.social-details {
  margin-top: 15px;
}
.social-details h5 a {
  color: var(--body-font-color);
}
.social-details .social-follow {
  column-count: 3;
  column-gap: 30px;
  margin-top: 25px;
}
@media (max-width: 1399px) {
  .social-details .social-follow {
    column-gap: 23px;
  }
}
.social-details .social-follow li {
  position: relative;
}
.social-details .social-follow li + li::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 14px;
  background-color: var(--recent-dashed-border);
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}
[dir=rtl] .social-details .social-follow li + li::before {
  left: unset;
  right: -15px;
}
.social-details .card-social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}
.social-img-wrap {
  background: linear-gradient(253deg, var(--theme-default) 11.51%, rgba(115, 102, 255, 0) 82.07%);
  display: inline-block;
  border-radius: 100%;
  padding: 2px;
  position: relative;
}
.social-img-wrap .edit-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.social-img-wrap .edit-icon svg {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid var(--white);
  background: var(--white);
}
.social-img {
  padding: 5px;
  background: var(--white);
  border-radius: 100%;
}
.social-img img {
  height: 68px;
  border-radius: 100%;
}

/**=====================
    2.10 Card CSS End
==========================**/
/**=====================
    2.12 Datatable CSS Start
==========================**/
div.dt-button-background {
  display: none !important;
}
div.DTS div.dataTables_scrollBody table {
  z-index: 1;
}
div .table-avtar {
  height: 32px;
  margin-right: 10px;
}
[dir=rtl] div .table-avtar {
  margin-right: unset;
  margin-left: 10px;
}
div .action {
  display: flex;
}
div .action i {
  font-size: 16px;
}
div .action .pdf i {
  font-size: 20px;
  color: #DC3545;
}
div .action .edit {
  margin-right: 5px;
}
div .action .edit i {
  color: #53A653;
}
[dir=rtl] div .action .edit {
  margin-left: 5px;
}
div .action .delete i {
  color: #DC3545;
}

#auto-generate-content_wrapper.dataTables_wrapper button {
  background-color: white;
  border: 1px solid #307EF3;
  color: #307EF3;
}

.dataTables_wrapper {
  width: calc(100% - 1px);
  padding: 0;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #EAEAEA;
}
.dataTables_wrapper .dt-buttons .btn {
  border-radius: 0.25rem !important;
}
.dataTables_wrapper .btn-group button {
  margin-right: 5px;
}
.dataTables_wrapper button {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
  border: none;
}
.dataTables_wrapper + .dataTables_wrapper {
  margin-top: 20px;
}
.dataTables_wrapper .dataTables_paginate {
  margin-left: 15px !important;
  border: 1px solid #F3F3F3;
  border-radius: 0.25rem;
  padding-top: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #307EF3;
  color: #fff !important;
  box-shadow: none;
  border: 1px solid #307EF3;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #307EF3;
  color: #2c323f !important;
  background: transparent !important;
}
.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}
.dataTables_wrapper .dataTables_length select {
  border-color: #F3F3F3;
  color: #2c323f;
  padding: 0 10px;
  margin: 0 10px;
  height: 2.7142em;
  background-color: #fff;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox,
.dataTables_wrapper table.dataTable tbody th.select-checkbox {
  padding-right: 40px !important;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
.dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
  right: 20px;
  top: 22px;
  left: unset;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox:after,
.dataTables_wrapper table.dataTable tbody th.select-checkbox:after {
  margin-top: -5px;
  margin-left: 21px;
}
.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead td {
  border-bottom: 1px solid #EAEAEA;
}
.dataTables_wrapper table.dataTable th,
.dataTables_wrapper table.dataTable td {
  padding: 0.75rem;
}
.dataTables_wrapper .dataTables_filter {
  margin-bottom: 25px;
  margin-left: 15px;
}
.dataTables_wrapper .dataTables_filter input[type=search] {
  border: 1px solid #EAEAEA;
  padding: 0 10px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

table .fixedHeader-floating {
  position: fixed !important;
  background-color: #fff;
}
table .box > div {
  padding: 5px 15px;
  background-color: #307EF3;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}
table.dataTable.fixedHeader-locked, table.dataTable.fixedHeader-floating {
  width: calc(100vw - 250px) !important;
  max-width: calc(100vw - 250px) !important;
  overflow: hidden !important;
  right: 0 !important;
  z-index: 99;
  left: 260px !important;
}
table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
  width: 100% !important;
  border-bottom: none;
}
table.dataTable {
  border-collapse: collapse !important;
  margin-top: 0 !important;
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px !important;
}
table.dataTable input,
table.dataTable select {
  border: 1px solid #EAEAEA;
  height: 37px;
}

.user-datatable tr td:first-child,
.user-datatable tr th:first-child {
  min-width: 150px;
}

.jsgrid .jsgrid-button {
  width: 20px;
  height: 20px;
}
.jsgrid .jsgrid-delete-button {
  background-position: 0 -78px;
  width: 22px;
  height: 22px;
}
.jsgrid .jsgrid-edit-button {
  background-position: -2px -122px;
  width: 22px;
  height: 22px;
}
.jsgrid .jsgrid-insert-mode-button {
  background-position: -2px -160px;
  width: 25px;
  height: 25px;
}
.jsgrid .jsgrid-insert-button {
  background-position: -3px -202px;
  width: 16px;
  height: 16px;
}

.toolbar {
  padding: 5px 15px;
  background-color: #307EF3;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

code.option {
  color: #DC3545 !important;
}
code.api {
  color: #53A653 !important;
}

.dt-ext .dataTables_wrapper .page-item.active .page-link {
  background-color: #307EF3 !important;
  border-color: #307EF3 !important;
  color: #fff;
}
.dt-ext .dataTables_wrapper .dataTables_paginate {
  border: none;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  border: none;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.hover, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.active {
  border: none !important;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
  margin-left: 0px;
  color: #307EF3;
}
.dt-ext .dataTables_wrapper button.dt-button,
.dt-ext .dataTables_wrapper div.dt-button,
.dt-ext .dataTables_wrapper a.dt-button,
.dt-ext .dataTables_wrapper button.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button.active:not(.disabled) {
  background-color: #307EF3;
  border-color: #307EF3;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-success,
.dt-ext .dataTables_wrapper div.dt-button.btn-success,
.dt-ext .dataTables_wrapper a.dt-button.btn-success {
  background-color: #53A653;
  border-color: #53A653;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-primary,
.dt-ext .dataTables_wrapper div.dt-button.btn-primary,
.dt-ext .dataTables_wrapper a.dt-button.btn-primary {
  background-color: #307EF3;
  border-color: #307EF3;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-danger,
.dt-ext .dataTables_wrapper div.dt-button.btn-danger,
.dt-ext .dataTables_wrapper a.dt-button.btn-danger {
  background-color: #DC3545;
  border-color: #DC3545;
}
.dt-ext .dataTables_wrapper table.dataTable th.focus,
.dt-ext .dataTables_wrapper table.dataTable td.focus {
  outline: 3px solid #307EF3;
}
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected > .sorting_1,
.dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
  background-color: #e7f0fe;
}
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd > .selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd > .selected {
  background-color: #e7f0fe;
}
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.even.selected > .sorting_1,
.dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
  background-color: #e7f0fe;
}
.dt-ext .dataTables_wrapper table.dataTable tbody > tr.selected,
.dt-ext .dataTables_wrapper table.dataTable tbody > tr > .selected {
  background-color: #e7f0fe;
}

td.highlight {
  background-color: #F3F3F3 !important;
}

td.details-control {
  text-align: center;
  cursor: pointer;
  font-family: "themify";
}
td.details-control:before {
  content: "\e61a";
  border: 1px solid #307EF3;
  padding: 3px;
  border-radius: 20px;
  color: #307EF3;
}

.dataTables_scroll ~ .dataTables_paginate {
  margin-top: 20px !important;
}

.product-table th:last-child {
  min-width: 120px;
}
.product-table h4 {
  font-weight: 600;
  color: #2c323f;
  margin-bottom: 5px;
}

table.dataTable.display tbody tr.odd > .sorting_1 {
  background-color: #fdfeff;
}
table.dataTable.display tbody tr.odd .badge {
  line-height: 15px;
}
table.dataTable.display tbody tr.even .badge {
  line-height: 15px;
}

.jsgrid-row .jsgrid-cell .badge,
.jsgrid-alt-row .jsgrid-cell .badge {
  line-height: 15px !important;
}

/**=====================
     2.12 Datatable CSS Ends
==========================**/
/**=====================
    2.13 Datapicker CSS Start
==========================**/
.clockpicker-canvas line {
  stroke: var(--theme-default);
}

.clockpicker-canvas-fg,
.clockpicker-canvas-bearing {
  fill: var(--theme-default);
}

.datepicker {
  z-index: 99;
}

.datepicker--day-name {
  color: #307EF3;
  font-weight: bold;
}

.datepicker--cell.-current- {
  color: #000;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid var(--theme-default);
}
.datepicker--cell.-focus- {
  background: var(--theme-default);
  color: #fff;
}
.datepicker--cell.-selected- {
  background: var(--theme-default) !important;
}
.datepicker--cell-day {
  width: 14.2857142857%;
}

.datepicker--cell-day {
  height: 45px;
}

.datepickers-container,
.bootstrap-datetimepicker-widget {
  z-index: 7;
}

.datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}
.datetime-picker .input-group-text i {
  line-height: 1.3;
}

.clockpicker-popover {
  border: none;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  z-index: 7;
}
.clockpicker-popover .popover-content {
  background-color: rgba(48, 126, 243, 0.05);
}

.clockpicker-canvas-bg {
  fill: #e7f0fe;
}

.clockpicker-tick:hover {
  color: inherit;
  background-color: #e7f0fe;
}

.calendar-basic .md-sidebar-aside {
  background-color: #fff;
}
.calendar-basic #external-events {
  padding: 20px;
  border: 1px solid #d7e2e9;
}
.calendar-basic #external-events h4 {
  margin-bottom: 14px;
}
.calendar-basic #external-events p {
  margin-top: 12px;
}
.calendar-basic #external-events .fc-h-event {
  padding: 12px;
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.calendar-basic #external-events .fc-h-event + .fc-h-event {
  margin-top: 10px;
}
.calendar-basic #external-events .fc-h-event .fc-event-main {
  font-size: 13px;
}
.calendar-basic .calendar-default .fc-daygrid-dot-event .fc-event-title {
  text-overflow: ellipsis;
}
.calendar-basic .calendar-default .fc td[role=presentation] {
  border: 0;
}
.calendar-basic .calendar-default .fc .fc-daygrid-day-number,
.calendar-basic .calendar-default .fc .fc-col-header-cell-cushion {
  color: #363636;
}
.calendar-basic .calendar-default .fc .fc-button .fc-icon {
  height: 23px;
}
.calendar-basic .calendar-default .fc-h-event {
  padding: 2px;
  background-color: #307EF3;
  border: 1px solid #307EF3;
}
.calendar-basic .calendar-default .fc-toolbar-chunk .fc-button-group ~ .fc-today-button {
  margin: 0 8px;
}

.fc-event-dragging {
  padding: 10px;
  background-color: var(--theme-default);
}

.fc .fc-toolbar {
  flex-wrap: wrap;
  gap: 10px;
}
.fc .fc-button-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 126, 243, 0.5);
}

.main-inline-calender.input-group {
  flex-direction: column;
}
.main-inline-calender.input-group .form-control {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-calendar.inline {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-innerContainer {
  display: block;
}
.main-inline-calender.input-group .flatpickr-rContainer {
  display: block;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-weekdays {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer {
  width: 100%;
  max-width: none;
  min-width: unset;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day {
  border-radius: 5px;
  width: 100%;
  max-width: unset;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:hover {
  color: var(--body-font-color);
}

.datepicker-inline .datepicker {
  width: 100%;
}

.flatpickr-day.selected,
.flatpickr-day .endRange {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}
.flatpickr-day.selected:hover,
.flatpickr-day .endRange:hover {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}

.main-flatpickr .form-control:read-only {
  background-color: unset;
}

.flatpickr-months {
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
}

.flatpickr-current-month {
  font-size: calc(14px + 5 * (100vw - 320px) / 1600);
}

@media (max-width: 400px) {
  .flatpickr-calendar.animate {
    width: 77%;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
.flatpickr-calendar .flatpickr-days {
  width: unset;
}
.flatpickr-calendar .flatpickr-days .dayContainer {
  width: unset;
  min-width: unset;
  max-width: unset;
}

@media (max-width: 768px) {
  .flatpicker-calender .form-control {
    font-size: 14px;
  }
}

.timepicker-wrapper .row {
  margin-bottom: 16px;
}
.timepicker-wrapper .row:last-child {
  margin-bottom: 0;
}

/**=====================
   2.13 Datapicker CSS End
==========================**/
/**=====================
	2.14 Dropdown CSS Start
==========================**/
.img-cropper .dropup .dropdown-toggle::after {
  content: "";
  display: none;
}

.dropdown-auto {
  overflow: auto;
}

.dropdown-divider {
  margin: 0;
}

.dropdown .dropdown-toggle {
  background-color: rgba(48, 126, 243, 0.08);
  color: #307EF3;
  border-radius: 10px;
  padding: 5px 10px;
  width: 100px;
  text-align: left;
}
.dropdown .dropdown-toggle:after {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f107";
  border: none;
  font-family: "FontAwesome";
  font-size: 18px;
  margin: 0;
}
.dropdown .dropdown-toggle.btn-primary {
  color: #fff;
}
.dropdown .dropdown-menu {
  font-family: Outfit, sans-serif;
}

.docs-toggles .dropdown .dropdown-toggle {
  width: auto;
}

.nav-tabs .dropdown .dropdown-toggle {
  width: 120px;
}
@media only screen and (max-width: 575.98px) {
  .nav-tabs .dropdown .dropdown-toggle {
    width: 100%;
    text-align: center;
  }
}

.icon-dropdown .dropdown-toggle {
  background: transparent;
  color: #9B9B9B;
  padding: 0;
  width: auto;
}
.icon-dropdown .dropdown-toggle i {
  font-size: 16px;
}
.icon-dropdown .dropdown-toggle::after {
  display: none;
}
.icon-dropdown .dropdown-item {
  line-height: 1.5;
}

.dropdown-basic {
  margin-bottom: -10px;
}
.dropdown-basic .btn-group .btn-round {
  border-radius: 50px;
}
.dropdown-basic .separated-btn {
  margin-left: -6px;
}
[dir=rtl] .dropdown-basic .separated-btn {
  margin-left: unset;
  margin-right: -6px;
}
.dropdown-basic .separated-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
}
.dropdown-basic .separated-btn.dropdown {
  margin-bottom: 0;
}
.dropdown-basic button {
  max-height: 43px;
}
.dropdown-basic .dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}
.dropdown-basic .dropdown .dropbtn {
  color: white;
  padding: 12px 35px;
  border: none;
  cursor: pointer;
}
.dropdown-basic .dropdown .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 175px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0;
  top: 45px;
}
.dropdown-basic .dropdown .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-basic .dropdown .dropdown-content .dropdown-header {
  padding: 12px 16px;
}
.dropdown-basic .dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown-basic .dropdown:hover .dropdown-content {
  display: block;
}

.dropup-basic .dropup {
  position: relative;
  display: inline-block;
}
.dropup-basic .dropup .dropbtn {
  color: white;
  padding: 12px;
  border: none;
}
.dropup-basic .dropup .dropup-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  bottom: 45px;
  z-index: 999;
  left: 0;
}
.dropup-basic .dropup .dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropup-basic .dropup .dropup-content a:hover {
  background-color: #EAEAEA;
}
.dropup-basic .dropup:hover .dropup-content {
  display: block;
}
.dropup-basic .dropup:hover .dropup-content a.active {
  background-color: #EAEAEA;
}

.dropdown-block li .dropdown-item:hover {
  background-color: #cccccc;
}
.dropdown-block li .helper-truncate:hover {
  background-color: unset !important;
}

.dropdown-basic .dropdown .dropdown-content a:hover {
  background-color: #cccccc !important;
}

.dropdown-menu .input-group {
  background-color: unset;
  padding: 6px 12px;
  border-top: 1px solid #EAEAEA;
  background-color: #fff;
  opacity: 0.6;
}
.dropdown-menu .input-group:hover, .dropdown-menu .input-group:active {
  background-color: #cccccc;
}
.dropdown-menu .input-group .input-group-text {
  background-color: unset;
  border: none;
}

.dark-form .form-label {
  color: var(--text-gray);
}
.dark-form .form-control {
  border: 1px solid var(--text-gray) !important;
}
.dark-form .form-check .form-check-input {
  border: 1px solid var(--text-gray);
}
.dark-form .form-check .form-check-label {
  color: var(--text-gray);
}
.dark-form h6 {
  color: var(--text-gray);
}
.dark-form p.dropdown-item {
  padding: 7px 0 0 0;
  border-top: 1px solid var(--chart-dashed-border);
}
.dark-form .helper-truncate {
  color: var(--text-gray);
  background-color: transparent;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 8px;
  vertical-align: 4px;
  content: "";
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-bottom: 0;
  border-left: 4px solid transparent;
}
[dir=rtl] .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 8px;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 8px;
  content: "";
  border-top: 4px solid transparent;
  border-right: 4px solid;
  border-bottom: 4px solid transparent;
}
[dir=rtl] .dropstart .dropdown-toggle::before {
  margin-left: 8px;
  margin-right: unset;
}

.dropup .dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  vertical-align: 4px;
  border-top: 0;
  border-right: 4px solid transparent;
  border-bottom: 4px solid;
  border-left: 4px solid transparent;
}
[dir=rtl] .dropup .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 8px;
}

.dropend .dropdown-toggle::after {
  content: "";
  display: inline-block;
  content: "";
  border-top: 4px solid transparent;
  border-right: 0;
  border-bottom: 4px solid transparent;
  border-left: 4px solid;
  margin-left: 8px;
}
[dir=rtl] .dropend .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 8px;
}

.form-wrapper {
  width: 260px;
}

@media (max-width: 400px) {
  .dropdown-menu .helper-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dark-dropdown .dropdown-block li .dropdown-item:hover, .dark-dropdown .dropdown-block li .dropdown-item.active {
  background-color: #2c323f;
  color: #fff;
}

btn-group .dropdown-wrapper {
  z-index: 2;
}
btn-group .dropdown-wrapper .input-group .input-group-text {
  padding: 0px 8px 0px 0px;
}
[dir=rtl] btn-group .dropdown-wrapper .input-group .input-group-text {
  padding: 0px 0px 0px 8px;
}

[dir=rtl] .rtl-dropdown .dropdown-menu li .dropdown-item {
  text-align: right;
}
[dir=rtl] .rtl-dropdown .btn-group .dropdown-menu {
  text-align: right;
}

.heading-dropdown .dropdown-menu > li:first-child > a {
  border-bottom: 4px solid var(--recent-border);
  border-top: 0;
  background-color: #d7e2e9;
}

/**=====================
	2.14 Dropdown CSS Ends
==========================**/
/**=====================
    2.16 Form-input CSS Start
==========================**/
.icon-addon .addon-md .fa, .icon-addon .addon-md .glyphicon {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon .glyphicon,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.input-group-square .input-group-text,
.input-group-square .form-control {
  border-radius: 0;
}

.input-group-air {
  box-shadow: 0 3px 20px 0 #EAEAEA;
}

.icon-addon {
  position: relative;
  color: #4c5667;
  display: block;
}
.icon-addon:after {
  display: table;
  content: "";
  clear: both;
}
.icon-addon:before {
  display: table;
  content: "";
}
.icon-addon .addon-md .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}
.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}
.input-group-wrapper .input-group {
  margin-bottom: 16px;
}
.input-group-wrapper .input-group .form-control {
  font-size: 16px;
}
.input-group-wrapper .input-group .form-control:focus {
  border-color: var(--recent-border);
  box-shadow: unset;
}
.input-group-wrapper .input-group:last-child {
  margin-bottom: 0;
}
.input-group-wrapper .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-custom-form label {
  margin-bottom: 0;
}
.main-custom-form .form-select:focus {
  box-shadow: unset;
  border-color: var(--recent-border);
}
.main-custom-form .dropdown-menu {
  z-index: 3;
}
.main-custom-form .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.main-custom-form .form-select,
.main-custom-form .form-control {
  font-size: calc(14px + 1 * (100vw - 320px) / 1600);
}
.main-custom-form .input-group-text {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
@media (max-width: 338px) {
  .main-custom-form .dropdown-toggle.btn {
    padding: 6px 11px;
  }
}

.main-segment-btn .dropdown-toggle::after {
  content: "";
  margin-left: 0;
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  vertical-align: 2px;
}
.main-segment-btn .dropdown-menu {
  z-index: 3;
}

#exampleDataList::-webkit-calendar-picker-indicator:hover, #exampleDataList::-webkit-calendar-picker-indicator:focus {
  display: none;
}

.form-control-color {
  max-width: 70rem;
}

.theme-form select.form-select option {
  padding: 8px 10px;
  border-radius: 100px;
}

.input-radius .form-control:focus {
  box-shadow: unset;
  border-color: var(--recent-border);
}
.input-radius .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.floating-wrapper .form-floating .form-control {
  height: 54px;
}

/**=====================
    2.16 Form-input CSS Ends
	==========================**/
/**=====================
    2.17 Form-wizard CSS Start
==========================**/
/*Form Wizard One Start*/
.form-wizard .tab {
  display: none;
}
.form-wizard .step {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #307EF3;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}
.form-wizard .step.active {
  opacity: 1;
}
.form-wizard .step.finish {
  background-color: var(--theme-secondary);
}

/*Form Wizard One ends*/
/*Form Wizard Four Start*/
.wizard-4 .action-bar {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
[dir=rtl] .wizard-4 .action-bar {
  right: unset;
  left: 50px;
}
.wizard-4 .action-bar .btn {
  float: right;
  margin: 0 5px;
  background-color: rgba(48, 126, 243, 0.2) !important;
  border-color: rgba(48, 126, 243, 0.2) !important;
  color: #307EF3;
}
.wizard-4 .action-bar .buttonDisabled {
  color: #59667a;
  background-color: rgba(89, 102, 122, 0.05) !important;
  border: 1px solid rgba(89, 102, 122, 0.05) !important;
  cursor: text;
}
.wizard-4 .step-container {
  background: rgba(48, 126, 243, 0.05);
  position: relative;
  margin: 0;
  padding: 0;
  border: 0 solid rgba(48, 126, 243, 0.2);
  overflow: hidden;
  clear: right;
  width: 70%;
}
.wizard-4 .step-container div.content {
  display: block;
  position: absolute;
  float: left;
  text-align: left;
  overflow: auto;
  z-index: 1;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
}
[dir=rtl] .wizard-4 .step-container div.content {
  text-align: unset;
  text-align: right;
}
.wizard-4 .step-container div.content .wizard-title {
  width: 450px;
  margin: 0 auto;
}
.wizard-4 .loader {
  display: none;
}
.wizard-4 .msg-box {
  position: fixed;
  display: none;
  padding: 5px 10px;
  background-color: #609cf6;
  top: 30px;
  border-radius: 5px;
  right: 60px;
  padding: 10px 20px;
}
.wizard-4 .msg-box .content {
  padding: 0;
  float: left;
  padding-top: 3px;
  color: #fff;
}
.wizard-4 .msg-box .close {
  color: #fff;
  margin-left: 10px;
  opacity: 1;
  font-size: 18px;
  line-height: 1.4;
}
[dir=rtl] .wizard-4 .msg-box .close {
  margin-right: 10px;
  margin-left: unset;
}
.wizard-4 ul.anchor {
  float: left;
  width: 30%;
  height: calc(100vh - 300px);
  padding: 40px 30px;
  overflow: auto;
}
.wizard-4 ul.anchor .disabled {
  opacity: 0.7;
  color: #59667a;
}
.wizard-4 ul.anchor .disabled h4 {
  background-color: rgba(89, 102, 122, 0.15);
}
.wizard-4 ul.anchor .logo {
  padding-bottom: 20px;
}
.wizard-4 ul.anchor li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 19px;
  float: left;
  clear: both;
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
}
.wizard-4 ul.anchor li + li a {
  padding-left: 80px;
}
[dir=rtl] .wizard-4 ul.anchor li + li a {
  padding-left: unset;
  padding-right: 80px;
}
.wizard-4 ul.anchor li:last-child {
  position: absolute;
  bottom: 0;
  width: auto;
  padding-bottom: 0;
  left: 9%;
}
[dir=rtl] .wizard-4 ul.anchor li:last-child {
  left: unset;
  right: 9%;
}
.wizard-4 ul.anchor li:last-child img {
  height: 300px;
}
@media (max-width: 1199px) {
  .wizard-4 ul.anchor li:last-child img {
    height: 220px;
  }
}
.wizard-4 ul.anchor li h4 {
  display: inline-block;
  background-color: rgba(48, 126, 243, 0.2);
  padding: 16px 23px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 6px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir=rtl] .wizard-4 ul.anchor li h4 {
  left: unset;
  right: 0;
}
.wizard-4 ul.anchor li a {
  display: block;
  position: relative;
  float: left;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  outline-style: none;
  z-index: 1;
  font-size: 18px;
}
.wizard-4 ul.anchor li a small {
  display: block;
  font-size: 14px;
}
.wizard-4 ul.anchor li a .stepNumber {
  position: relative;
  float: left;
  width: 30px;
  text-align: center;
  padding: 5px;
  padding-top: 0;
}
.wizard-4 ul.anchor li a.done h4 {
  color: #fff;
  background: #307EF3;
}
.wizard-4 ul.anchor li a.done h4:before {
  font-size: 12px;
  position: absolute;
  left: 5px;
  top: -3px;
  content: "\e64c";
  font-family: "themify";
  background-color: #fff;
  color: #307EF3;
  border-radius: 15px;
  padding: 2px;
  border: 1px solid;
}
.wizard-4 ul.anchor li a.error {
  color: #9B9B9B;
  background: #fe8a7d;
  border: 1px solid #DC3545;
}
.wizard-4 ul.anchor li a.error:hover {
  color: #000;
}

.wizard {
  max-width: 670px;
  margin: 0 auto;
}
.wizard .wizard-content {
  box-shadow: unset;
  background-color: #fff;
  border-radius: 5px;
}

.vertical-main-wizard .header-vertical-wizard {
  background-color: var(--light-background);
  height: 100%;
  text-align: unset;
}
.vertical-main-wizard .header-vertical-wizard .nav-link {
  border-bottom: 1px solid var(--recent-border);
  border-radius: 0;
  padding: 20px;
}
.vertical-main-wizard .header-vertical-wizard .nav-link:last-child {
  border-bottom: 0;
}
.vertical-main-wizard .nav-pills .nav-link.active {
  background-color: transparent;
}
.vertical-main-wizard .nav-pills.show > .nav-link {
  background-color: transparent;
}
.vertical-main-wizard .nav-link .vertical-wizard {
  display: flex;
  align-items: center;
  gap: 14px;
}
.vertical-main-wizard .nav-link .vertical-wizard .stroke-icon-wizard {
  width: 33px;
  height: 33px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  border: 1px solid #52526c;
}
.vertical-main-wizard .nav-link .vertical-wizard .stroke-icon-wizard i {
  margin-right: 0;
  color: #52526c;
}
.vertical-main-wizard .nav-link .vertical-wizard .vertical-wizard-content h3 {
  font-weight: 400;
  line-height: 1;
  color: var(--body-font-color);
}
.vertical-main-wizard .nav-link .vertical-wizard .vertical-wizard-content p {
  color: #6C757D;
}
.vertical-main-wizard .nav-link:active, .vertical-main-wizard .nav-link.active {
  background-color: unset;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .stroke-icon-wizard, .vertical-main-wizard .nav-link.active .vertical-wizard .stroke-icon-wizard {
  background-color: #307EF3;
  border: 1px solid #307EF3;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .stroke-icon-wizard i, .vertical-main-wizard .nav-link.active .vertical-wizard .stroke-icon-wizard i {
  color: #fff;
  font-size: 18px;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .stroke-icon-wizard::before, .vertical-main-wizard .nav-link.active .vertical-wizard .stroke-icon-wizard::before {
  content: "";
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid #307EF3;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .vertical-wizard-content h6, .vertical-main-wizard .nav-link.active .vertical-wizard .vertical-wizard-content h6 {
  color: #307EF3;
}
.vertical-main-wizard .form-check .form-check-label {
  margin-bottom: 0;
}
.vertical-main-wizard .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.basic-wizard .stepper-horizontal {
  display: flex;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  margin-bottom: 24px;
}
.basic-wizard .stepper-horizontal .step {
  position: relative;
  padding-inline: 24px;
  z-index: 2;
  width: 100%;
}
.basic-wizard .stepper-horizontal .step:first-child {
  padding-left: 0;
}
.basic-wizard .stepper-horizontal .step:last-child {
  padding-right: 0;
}
.basic-wizard .stepper-horizontal .step:last-child .step-bar-left, .basic-wizard .stepper-horizontal .step:last-child .step-bar-right {
  display: none;
}
.basic-wizard .stepper-horizontal .step .step-circle {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #cccccc;
  background: var(--white);
}
.basic-wizard .stepper-horizontal .step.done .step-circle {
  background-color: var(--white);
  border: 2px solid #9B9B9B;
  color: #307EF3;
  z-index: 4;
  position: relative;
}
.basic-wizard .stepper-horizontal .step.done .step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00c";
}
.basic-wizard .stepper-horizontal .step.done .step-circle * {
  display: none;
}
.basic-wizard .stepper-horizontal .step.done .step-title {
  color: #9B9B9B;
}
.basic-wizard .stepper-horizontal .step.editing .step-circle {
  background: var(--white);
  border-color: #9B9B9B;
  color: #307EF3;
}
.basic-wizard .stepper-horizontal .step .step-title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: var(--chart-text-color);
}
.basic-wizard .stepper-horizontal .step .step-title,
.basic-wizard .stepper-horizontal .step .step-optional {
  text-align: center;
}
.basic-wizard .stepper-horizontal .step .step-bar-left,
.basic-wizard .stepper-horizontal .step .step-bar-right {
  position: absolute;
  top: 14px;
  height: 4px;
  background-color: rgba(48, 126, 243, 0.2);
  border: solid rgba(48, 126, 243, 0.2) r;
  border-width: 2px 0;
}
.basic-wizard .stepper-horizontal .step .step-bar-left {
  width: 100%;
  left: 50%;
  z-index: -1;
}
[dir=rtl] .basic-wizard .stepper-horizontal .step .step-bar-left {
  left: unset;
  right: 50%;
  margin-left: unset;
  margin-right: 1rem;
}
.basic-wizard .stepper-horizontal .step .step-bar-right {
  width: 0;
  left: 50%;
  z-index: -1;
  transition: width 500ms ease-in-out;
}
[dir=rtl] .basic-wizard .stepper-horizontal .step .step-bar-right {
  right: 50%;
  left: unset;
}
.basic-wizard .stepper-horizontal .step.done .step-bar-right {
  background-color: #307EF3;
  border-color: #307EF3;
  z-index: 3;
  width: 100%;
}
.basic-wizard form.none {
  display: none;
}
.basic-wizard form.block {
  display: block;
}
.basic-wizard form .form-check-label {
  margin-bottom: 0;
}

.important-validation .form-label span {
  color: #DC3545;
}

.select-size.form-select {
  font-size: 14px;
}

.wizard-footer button.disabled {
  background-color: #EAEBF2;
}
.wizard-footer button.back {
  background-color: #FFAA05;
}

.successful-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 24px;
}
.successful-form img {
  width: 100px;
  height: 100px;
}

.inquiries-form h6 {
  margin-bottom: 14px;
}
.inquiries-form .choose-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.inquiries-form .choose-option .form-check:last-child {
  margin-bottom: 0;
}

.form-completed img {
  height: 100px;
  margin: 0 auto;
  display: block;
}
.form-completed h6 {
  text-align: center;
  margin-top: 24px;
}

.horizontal-wizard-wrapper .main-horizontal-header {
  padding: 0 0 20px 0;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 14px;
}
@media (max-width: 1182px) {
  .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options {
    justify-content: flex-start;
  }
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a .horizontal-wizard {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--body-font-color);
  transition: all 0.3s ease;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a .horizontal-wizard .stroke-icon-wizard i {
  font-size: 18px;
  color: var(--body-font-color);
}
[dir=rtl] .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a .horizontal-wizard .stroke-icon-wizard i {
  margin-right: unset;
  margin-left: 10px;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard {
  color: var(--white);
  transition: all 0.3s ease;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard .stroke-icon-wizard i, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard .stroke-icon-wizard i {
  color: var(--white);
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard .stroke-icon-wizard span, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard .stroke-icon-wizard span {
  color: var(--white);
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard .horizontal-wizard-content h6, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard .horizontal-wizard-content h6 {
  color: var(--white);
}
.horizontal-wizard-wrapper .main-horizontal-header .form-check {
  margin-bottom: 0;
}
.horizontal-wizard-wrapper .bank-search {
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .horizontal-wizard-wrapper .bank-search {
    margin-bottom: 0;
  }
}
.horizontal-wizard-wrapper .bank-selection {
  padding: 10px 0 0 0;
}
@media (max-width: 401px) {
  .horizontal-wizard-wrapper .bank-selection .radio-wrapper {
    justify-content: center;
  }
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li .form-check-label {
  margin-bottom: 0;
  padding: 6px;
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li .form-check-label img {
  height: calc(45px + 7 * (100vw - 320px) / 1600);
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li .form-check-label span {
  font-size: 14px;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options {
  gap: 12px;
}
@media (max-width: 1400px) {
  .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options {
    justify-content: flex-start;
  }
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link {
  color: var(--body-font-color);
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard {
  gap: 10px;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard .stroke-icon-wizard {
  width: 30px;
  height: 30px;
  background-color: rgba(48, 126, 243, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard .stroke-icon-wizard i {
  margin-right: 0;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active {
  background-color: unset;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard {
  color: var(--body-font-color);
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard .stroke-icon-wizard, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard .stroke-icon-wizard {
  background-color: #307EF3;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard .stroke-icon-wizard i, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard .stroke-icon-wizard i {
  margin-right: 0;
  color: var(--white);
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard h6, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard h6 {
  color: var(--body-font-color) !important;
}
.horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li {
  padding: 20px;
  display: grid;
  width: 100%;
  height: 100%;
  place-content: baseline;
  text-align: unset;
  align-items: unset;
  max-width: unset;
}
.horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li .form-check-label {
  justify-content: unset;
  display: flex;
  align-items: center;
}
.horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li .form-check-label span > span + span {
  font-size: 13px;
}
@media (max-width: 400px) {
  .horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li .form-check-label span > span + span {
    display: none;
  }
}

.horizontal-wizard-wrapper.vertical-options .main-horizontal-header {
  padding-bottom: 0;
}
.horizontal-wizard-wrapper.vertical-options .main-horizontal-header .horizontal-options {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.authentication-options .radio-wrapper {
  display: flex;
  gap: calc(8px + 8 * (100vw - 320px) / 1600);
  flex-wrap: wrap;
  justify-content: center;
}
.authentication-options .radio-wrapper li {
  padding: 20px;
  display: grid;
  width: 100%;
  height: 100%;
  place-content: baseline;
  text-align: unset;
  align-items: unset;
  max-width: unset;
}
.authentication-options .radio-wrapper li .form-check-label {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 14px;
}
@media (max-width: 400px) {
  .authentication-options .radio-wrapper li .form-check-label {
    align-items: baseline;
  }
}
.authentication-options .radio-wrapper li .form-check-label i {
  padding: 0;
}
[dir=rtl] .authentication-options .radio-wrapper li .form-check-label i {
  padding: 0;
}
.authentication-options .radio-wrapper li .form-check-label span > span {
  font-size: 16px;
  color: #1f2533;
  font-weight: 500;
}
.authentication-options .radio-wrapper li .form-check-label span > span + span {
  color: var(--text-gray);
  font-size: 14px;
}

.main-qr-code .modal-toggle-wrapper .modal-img {
  flex-direction: column;
  align-items: center;
  margin-bottom: 22px;
}
@media (max-width: 480px) {
  .main-qr-code .modal-toggle-wrapper .modal-img {
    align-items: unset;
  }
}
@media (max-width: 480px) {
  .main-qr-code .modal-toggle-wrapper .modal-img .qr-img {
    text-align: center;
  }
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-img img {
  height: 100px;
  width: 100px;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content {
  margin-top: 16px;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert {
  align-items: unset;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert div {
  word-break: break-word;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content i {
  font-size: 20px;
  margin-right: 0;
}
.main-qr-code .modal-toggle-wrapper .btn {
  display: block;
  margin: auto;
  margin-top: 12px;
}

.authentication-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  text-align: center;
}
.authentication-body img {
  height: calc(200px + 220 * (100vw - 320px) / 1600);
}

.shopping-wizard .shipping-form .shipping-border {
  border-right: 1px solid var(--recent-dashed-border);
}
@media (max-width: 1200px) {
  .shopping-wizard .shipping-form .shipping-border {
    border-right: unset;
  }
}
.shopping-wizard .shipping-form .shipping-options {
  display: flex;
  align-items: center;
  background-color: rgba(48, 126, 243, 0.2);
  flex-wrap: nowrap;
  margin-bottom: 18px;
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .shipping-options {
    flex-direction: column;
  }
}
.shopping-wizard .shipping-form .shipping-options .nav-link {
  width: 100%;
  color: #307EF3;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .shopping-wizard .shipping-form .shipping-options .nav-link {
    padding: calc(8px + 0 * (100vw - 300px) / 1620) calc(6px + 10 * (100vw - 300px) / 1620);
  }
}
.shopping-wizard .shipping-form .shipping-options .nav-link:active i, .shopping-wizard .shipping-form .shipping-options .nav-link.active i {
  background-color: rgba(255, 255, 255, 0.8);
  color: #307EF3;
  transition: all 0.3s ease-in-out;
}
.shopping-wizard .shipping-form .shipping-options .nav-link:active h3, .shopping-wizard .shipping-form .shipping-options .nav-link.active h3 {
  color: var(--white);
}
.shopping-wizard .shipping-form .shipping-options .cart-options {
  display: flex;
  align-items: center;
  gap: 11px;
}
.shopping-wizard .shipping-form .shipping-options .cart-options i {
  font-size: 18px;
  color: var(--white);
  width: 40px;
  height: 40px;
  background-color: #307EF3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .shopping-wizard .shipping-form .shipping-options .cart-options i {
    font-size: calc(14px + 4 * (100vw - 1200px) / 720);
    width: calc(30px + 10 * (100vw - 1200px) / 720);
    height: calc(30px + 10 * (100vw - 1200px) / 720);
  }
}
.shopping-wizard .shipping-form .shipping-options .cart-options h6 {
  color: #307EF3;
}
.shopping-wizard .shipping-form .shipping-info .table thead th {
  white-space: nowrap;
}
.shopping-wizard .shipping-form .shipping-info .table thead th:last-child {
  text-align: end;
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr td img {
  height: 50px;
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr td:last-child {
  text-align: end;
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr td span {
  color: var(--text-gray);
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr:last-child td {
  border: 0;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot {
  font-weight: 400;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr td {
  white-space: nowrap;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr td:first-child {
  max-width: 370px;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr td:last-child {
  min-width: 0;
  text-align: end;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr:first-child {
  font-weight: 500;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr:last-child {
  font-weight: 600;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr:last-child td {
  border-bottom: 0;
}
.shopping-wizard .shipping-form .shipping-content {
  height: 100%;
  padding: 0;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-title > .btn {
  background: none !important;
  border: 0;
  padding: 0 28px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-title > .btn i {
  color: #307EF3;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .collect-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .collect-address .card-icon {
  display: flex;
  align-items: center;
  gap: 12px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .collect-address .card-icon i {
  font-size: 16px;
  color: #307EF3;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-address {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-address h6 {
  font-size: 15px;
  font-weight: 500;
}
.shopping-wizard .shipping-form .shipping-content > .active.finish-wizard1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 55px - 18px);
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .shipping-content > .active.finish-wizard1 {
    height: unset;
  }
}
.shopping-wizard .shipping-form .shipping-method p {
  padding-left: 25px;
  color: var(--text-gray);
}
[dir=rtl] .shopping-wizard .shipping-form .shipping-method p {
  padding-right: 25px;
  padding-left: unset;
}
.shopping-wizard .shipping-form .proceed-next {
  transform: scaleX(-1);
}
.shopping-wizard .shipping-form .payment-info-wrapper .card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .payment-info-wrapper .card-wrapper {
    justify-content: unset;
    align-items: flex-start;
    flex-direction: column;
  }
}
.shopping-wizard .shipping-form .payment-info-wrapper .card-wrapper img {
  height: 50px;
}
.shopping-wizard .shipping-form .payment-info-wrapper .pay-info {
  display: block;
}
.shopping-wizard .shipping-form .payment-info-wrapper .pay-info > div {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .payment-info-wrapper .pay-info > div {
    flex-direction: column;
    align-items: flex-start;
  }
}
.shopping-wizard .shipping-form .order-confirm {
  text-align: center;
}
@media (max-width: 1200px) {
  .shopping-wizard .shipping-form .order-confirm {
    margin-bottom: 18px;
  }
}
.shopping-wizard .shipping-form .order-confirm img {
  height: calc(95px + 45 * (100vw - 320px) / 1600);
}

.avatar-upload {
  flex-direction: column;
}
.avatar-upload div:first-child {
  position: relative;
  max-width: 205px;
  margin: auto;
}
.avatar-upload div:first-child .avatar-edit {
  position: absolute;
  right: 25px;
  z-index: 1;
  top: 4px;
}
.avatar-upload div:first-child .avatar-edit input {
  display: none;
}
.avatar-upload div:first-child .avatar-edit label {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--light-bg);
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload div:first-child .avatar-edit label::after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--body-font-color);
  position: absolute;
  top: 8px;
  left: 3px;
  right: 0;
  text-align: center;
  margin: auto;
}
[dir=rtl] .avatar-upload div:first-child .avatar-edit label::after {
  right: 3px;
  left: 0;
}
.avatar-upload div:first-child .avatar-preview {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin: auto;
}
.avatar-upload div:first-child .avatar-preview > div {
  background-image: url("../images/forms/user.png");
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.avatar-upload h6 {
  text-align: center;
  padding-top: 14px;
}

.email-verify .card-wrapper {
  display: grid;
  place-content: center;
}
.email-verify .card-wrapper > div {
  align-items: center;
}
.email-verify .card-wrapper .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.authenticate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.authenticate span:nth-of-type(1) {
  margin-top: 12px;
}
.authenticate h4 {
  margin-bottom: 12px;
}
.authenticate img {
  height: calc(145px + 55 * (100vw - 320px) / 1600);
}
.authenticate form {
  flex-direction: column;
  text-align: center;
}
.authenticate form .otp-generate {
  display: flex;
  gap: 12px;
  margin: 14px 0;
  justify-content: center;
}
.authenticate form .form-control {
  width: calc(25px + 10 * (100vw - 320px) / 1600);
  height: calc(25px + 10 * (100vw - 320px) / 1600);
  border-radius: 5px;
  text-align: center;
  padding: 0;
  display: block;
  min-width: 0;
}
.authenticate form div {
  display: inline-grid;
  gap: 2px;
  margin-top: 12px;
}

.accordion .accordion-item .accordion-body .choose-bank .form-check .form-check-label {
  color: var(--body-font-color);
}

/*Form Wizard Four Ends*/
/**=====================
   2.17 Form-wizard CSS Ends
==========================**/
/**=====================
  2.18 Forms CSS Start
==========================**/
.form-label {
  margin-bottom: 4px;
}

.col-form-label {
  padding-bottom: 4px;
}

.form-space {
  margin-bottom: -10px;
}
.form-space > div {
  margin-bottom: 10px;
}

.theme-form .form-control :focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}
.theme-form textarea {
  border-color: #EAEAEA;
}
.theme-form textarea::-webkit-input-placeholder {
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}
.theme-form input[type=text],
.theme-form input[type=email],
.theme-form input[type=search],
.theme-form input[type=password],
.theme-form input[type=number],
.theme-form input[type=tel],
.theme-form input[type=date],
.theme-form input[type=datetime-local],
.theme-form input[type=time],
.theme-form input[type=datetime-local],
.theme-form input[type=month],
.theme-form input[type=week],
.theme-form input[type=url],
.theme-form input[type=file],
.theme-form textarea,
.theme-form select {
  border-color: #EAEAEA;
  background-color: #fff;
  font-size: 14px;
  color: #9B9B9B;
  padding: 12px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.theme-form input[type=text]::-webkit-input-placeholder,
.theme-form input[type=email]::-webkit-input-placeholder,
.theme-form input[type=search]::-webkit-input-placeholder,
.theme-form input[type=password]::-webkit-input-placeholder,
.theme-form input[type=number]::-webkit-input-placeholder,
.theme-form input[type=tel]::-webkit-input-placeholder,
.theme-form input[type=date]::-webkit-input-placeholder,
.theme-form input[type=datetime-local]::-webkit-input-placeholder,
.theme-form input[type=time]::-webkit-input-placeholder,
.theme-form input[type=datetime-local]::-webkit-input-placeholder,
.theme-form input[type=month]::-webkit-input-placeholder,
.theme-form input[type=week]::-webkit-input-placeholder,
.theme-form input[type=url]::-webkit-input-placeholder,
.theme-form input[type=file]::-webkit-input-placeholder,
.theme-form textarea::-webkit-input-placeholder,
.theme-form select::-webkit-input-placeholder {
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}
.theme-form input[type=file] {
  height: auto;
}
.theme-form select.form-control:not([size]):not([multiple]) {
  border-color: #EAEAEA;
  font-size: 14px;
}
.theme-form .checkbox label {
  padding-left: 10px;
}
[dir=rtl] .theme-form .checkbox label {
  padding-right: 10px;
  padding-left: unset;
}
.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #EAEAEA;
}
.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "Or Signup With";
  color: #363636;
  background: #fff;
}
[dir=rtl] .theme-form .form-divider:before {
  left: unset;
  right: 40%;
  margin-left: unset;
  margin-right: -10px;
}
.theme-form .login-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #EAEAEA;
}
.theme-form .login-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #363636;
  background: #fff;
  content: "Or Login With";
}
[dir=rtl] .theme-form .login-divider:before {
  left: unset;
  right: 40%;
  margin-left: unset;
  margin-right: -10px;
}
.theme-form .btn-fb,
.theme-form .btn-twitter,
.theme-form .btn-google,
.theme-form .btn-linkedin {
  color: #fff;
  width: 100%;
}
.theme-form .btn-fb {
  background-color: #50598e;
}
.theme-form .btn-twitter {
  background-color: #6fa2d8;
}
.theme-form .btn-google {
  background-color: #c64e40;
}
.theme-form .btn-linkedin {
  background-color: #0077B5;
}

.form-inline {
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}
.form-inline .col-form-label {
  margin-right: 5px;
}
[dir=rtl] .form-inline .col-form-label {
  margin-left: 5px;
  margin-right: unset;
}

.search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 82px;
  top: 50px;
}
[dir=rtl] .search-form .form-group:before {
  left: unset;
  right: 82px;
}
.search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: "FontAwesome";
  top: 53px;
  left: 53px;
  color: #8e8e8e;
}
[dir=rtl] .search-form .form-group:after {
  left: unset;
  right: 53px;
}
.search-form input {
  border: 1px solid #EAEAEA;
  padding: 10px 10px 10px 70px;
  border-radius: 5px;
  background-color: rgba(243, 243, 243, 0.3);
}
[dir=rtl] .search-form input {
  padding: 10px 70px 10px 10px;
}
.search-form input:focus {
  outline: none !important;
}

.form-label-align-right label {
  text-align: right;
  padding-top: 17px;
  font-weight: 500;
}

.custom-input.was-validated .form-control:valid,
.custom-input.was-validated .form-select:valid {
  border-color: #53A653;
}
.custom-input.was-validated .form-control:invalid,
.custom-input.was-validated .form-select:invalid {
  border-color: #DC3545;
}
.custom-input .valid-tooltip {
  background-color: #53A653;
}
.custom-input .invalid-tooltip {
  background-color: rgba(220, 53, 69, 0.8);
}
.custom-input .invalid-feedback {
  color: #DC3545 !important;
}
.custom-input .form-check .form-check-input:valid {
  border-color: #53A653;
}
.custom-input .form-check .form-check-input:invalid {
  border-color: #DC3545;
}
.custom-input .form-check .form-check-input:invalid ~ .form-check-label {
  color: #DC3545;
}
.custom-input .form-check .form-check-input:valid ~ .form-check-label {
  color: #53A653;
}
.custom-input .form-check .form-check-input:checked {
  background-color: #53A653;
}
.custom-input .form-check .invalid-feedback {
  color: #DC3545;
}
.custom-input #validationCustom03.form-control,
.custom-input #validationCustom03 .form-select,
.custom-input #validationCustom05.form-control,
.custom-input #validationCustom05 .form-select,
.custom-input #validationCustomUsername.form-control,
.custom-input #validationCustomUsername .form-select {
  border-color: #EAEAEA;
}
.custom-input #validationCustom03.was-validated .form-control:valid,
.custom-input #validationCustom03.was-validated .form-select:valid,
.custom-input #validationCustom05.was-validated .form-control:valid,
.custom-input #validationCustom05.was-validated .form-select:valid,
.custom-input #validationCustomUsername.was-validated .form-control:valid,
.custom-input #validationCustomUsername.was-validated .form-select:valid {
  border-color: #53A653;
}
.custom-input #validationCustom03.was-validated .form-control:invalid,
.custom-input #validationCustom03.was-validated .form-select:invalid,
.custom-input #validationCustom05.was-validated .form-control:invalid,
.custom-input #validationCustom05.was-validated .form-select:invalid,
.custom-input #validationCustomUsername.was-validated .form-control:invalid,
.custom-input #validationCustomUsername.was-validated .form-select:invalid {
  border-color: #DC3545;
}
[dir=rtl] .custom-input .form-check.form-switch .form-check-input {
  margin-left: 7px;
}

.form-check-reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0;
}
.form-check-reverse .form-check-input {
  float: left;
  margin-right: 0;
  margin-left: 10px;
}

.checkbox-checked.card-wrapper h6.sub-title {
  font-weight: 500;
}
.checkbox-checked .form-check-input:checked {
  background-color: #307EF3;
  border-color: #307EF3;
}
.checkbox-checked .form-check-input:focus {
  border-color: #307EF3;
  box-shadow: 0 0 0 4px rgba(48, 126, 243, 0.25);
}
.checkbox-checked .card-wrapper .form-check:last-child {
  min-height: 0;
  margin-bottom: 0;
}
.checkbox-checked .card-wrapper .form-check:last-child label {
  margin-bottom: 0;
}

.form-check-size {
  display: flex;
  gap: 22px;
  align-items: center;
  flex-wrap: wrap;
}
.form-check-size .form-check-inline {
  margin: 0;
  min-height: 0;
}
[dir=rtl] .form-check-size .form-check-inline {
  margin: 0;
}
.form-check-size .form-check-inline .form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 0;
}
.form-check-size .form-check-inline .check-size {
  width: 36px;
  height: 18px;
  margin-top: 0;
}
.form-check-size .form-check-inline label {
  margin: 0;
}

.main-img-checkbox .img-checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-img-checkbox .img-checkbox .main-img-cover {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .main-img-checkbox .img-checkbox .main-img-cover {
  left: unset;
  right: 15px;
}
.main-img-checkbox .img-checkbox .form-check-label {
  width: 100%;
}
.main-img-checkbox .img-checkbox img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.main-icon-checkbox.checkbox label {
  margin-top: 0;
}
.main-icon-checkbox.checkbox label::before {
  content: "";
  width: 100%;
  height: 100%;
  margin-left: 1px;
  background-color: transparent;
  top: 0;
  display: block;
}
[dir=rtl] .main-icon-checkbox.checkbox label::before {
  margin-left: unset;
  margin-right: 1px;
}
.main-icon-checkbox.checkbox-primary input[type=checkbox]:checked + label::before {
  color: transparent;
  border: 2px solid #307EF3;
}
.main-icon-checkbox.checkbox-primary input[type=checkbox] {
  opacity: 1;
}
.main-icon-checkbox.checkbox-primary .form-check-input {
  border: 1px solid #EAEAEA;
}
.main-icon-checkbox.checkbox-primary .form-check-input.checkbox-shadow:focus {
  box-shadow: none;
}
.main-icon-checkbox.checkbox-primary .form-check-input:checked[type=checkbox] {
  background-image: none;
}

.main-radio-toggle .btn,
.main-checkbox-toggle .btn {
  margin-bottom: 0;
}

.radio-wrapper,
.checkbox-wrapper {
  display: flex;
  gap: calc(8px + 8 * (100vw - 320px) / 1600);
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 1200px) {
  .radio-wrapper,
  .checkbox-wrapper {
    justify-content: unset;
  }
}
.radio-wrapper li,
.checkbox-wrapper li {
  position: relative;
  width: 100%;
  padding: 12px;
  border: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 130px;
}
@media (max-width: 1335px) {
  .radio-wrapper li,
  .checkbox-wrapper li {
    max-width: 113px;
  }
}
@media (max-width: 1232px) {
  .radio-wrapper li,
  .checkbox-wrapper li {
    padding: 10px;
    max-width: 105px;
  }
}
.radio-wrapper li .form-check-input,
.checkbox-wrapper li .form-check-input {
  position: absolute;
  width: 100%;
  height: 100%;
  float: none;
  margin: 0;
  top: 0;
  left: 0;
  opacity: 1;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #EAEAEA;
}
.radio-wrapper li .form-check-input:focus,
.checkbox-wrapper li .form-check-input:focus {
  box-shadow: none;
}
.radio-wrapper li .form-check-input[type=radio],
.checkbox-wrapper li .form-check-input[type=radio] {
  opacity: 1;
}
.radio-wrapper li .form-check-input:checked,
.checkbox-wrapper li .form-check-input:checked {
  border: 2px solid #307EF3;
  background-color: transparent;
}
.radio-wrapper li .form-check-input:checked[type=radio],
.checkbox-wrapper li .form-check-input:checked[type=radio] {
  background-image: none;
  box-shadow: unset;
}
.radio-wrapper li .form-check-label,
.checkbox-wrapper li .form-check-label {
  position: unset;
  padding: 0;
  margin-bottom: 0;
}
.radio-wrapper li .form-check-label:after,
.checkbox-wrapper li .form-check-label:after {
  content: none;
}
.radio-wrapper li .form-check-label::before,
.checkbox-wrapper li .form-check-label::before {
  content: none;
}
.radio-wrapper li .form-check-label i,
.checkbox-wrapper li .form-check-label i {
  font-size: 18px;
  padding-right: 8px;
}
[dir=rtl] .radio-wrapper li .form-check-label i,
[dir=rtl] .checkbox-wrapper li .form-check-label i {
  padding-right: 0;
  padding-left: 8px;
}
.radio-wrapper li .form-check-label span,
.checkbox-wrapper li .form-check-label span {
  font-size: 16px;
}

[dir=rtl] .rtl-input .form-check.form-check-inline {
  padding: 0;
}
[dir=rtl] .rtl-input .form-check.form-switch {
  padding-right: 40px;
}
[dir=rtl] .rtl-input .form-check.form-switch .form-check-input {
  margin-left: unset;
  margin-right: -40px;
  float: right;
}

.radio-form {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
.radio-form .form-check {
  min-height: 0;
  margin-bottom: 0;
}
.radio-form .form-check .form-check-label {
  margin-bottom: 0;
}

.basic-form {
  position: relative;
}
.basic-form svg {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
[dir=rtl] .basic-form svg {
  right: 0;
  left: 12px;
}
.basic-form svg.stroke-icon {
  width: 25px;
  height: 25px;
  stroke: #2c323f;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
[dir=rtl] .basic-form svg.stroke-icon {
  right: unset;
  left: 12px;
}

.form-check {
  margin-bottom: 0;
}

.theme-form .custom-input .row .col .row:last-child label {
  margin: 0;
}
.theme-form.dark-inputs input::file-selector-button {
  border-radius: 30px;
}
[dir=rtl] .theme-form .form-floating > label {
  left: unset;
  right: 0;
}

/**=====================
  2.18 Forms CSS Ends
==========================**/
/**=====================
	2.19 Icons CSS Start
==========================**/
.icon-lists div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.icon-lists div:hover {
  background-color: #fafafa;
}
.icon-lists div i {
  margin: 0 15px 0 10px;
  font-size: 24px;
}
.icon-lists.whether-icon svg {
  height: 50px;
  width: 50px;
}
.icon-lists.feather-icons .d-flex svg {
  margin: 0 15px 0 10px;
}
.icon-lists .feather-icon .icon-first h6 {
  display: none;
}

.flag-icons,
.feather-icons {
  margin-bottom: -15px;
  margin-top: -15px;
}
.flag-icons div,
.feather-icons div {
  line-height: 1;
  word-wrap: break-word;
  overflow: auto;
}
.flag-icons div .flag-icon,
.feather-icons div .flag-icon {
  height: 50px;
  width: 50px;
}
.flag-icons div .d-flex,
.feather-icons div .d-flex {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.flag-icons div .flex-grow-1 h6,
.feather-icons div .flex-grow-1 h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flag-icons div .flex-grow-1 h5,
.feather-icons div .flex-grow-1 h5 {
  background-color: var(--theme-default);
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
  color: #fff;
}

.icon-hover-bottom {
  background-color: #fdfeff;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 1px 1px #F3F3F3;
  z-index: 999;
}
.icon-hover-bottom .icon-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}
.icon-hover-bottom .icon-popup .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
}
[dir=rtl] .icon-hover-bottom .icon-popup .close-icon {
  right: unset;
  left: 10px;
}
.icon-hover-bottom .icon-popup > div {
  display: inline-block;
}
.icon-hover-bottom .icon-popup > div .flag-icon {
  width: 60px;
  height: 60px;
}
.icon-hover-bottom .icon-popup .icon-class {
  margin: 0 20px;
  cursor: pointer;
}
.icon-hover-bottom .icon-popup .icon-last .form-inline .form-group {
  display: initial;
}
.icon-hover-bottom .icon-title {
  display: block;
  color: #2c323f;
  text-transform: uppercase;
  font-weight: 500;
}
.icon-hover-bottom span,
.icon-hover-bottom label,
.icon-hover-bottom .btn {
  font-family: Roboto, sans-serif;
}
.icon-hover-bottom .form-group input {
  display: inline-block;
  width: 300px;
  background-color: #fff;
  font-family: Roboto, sans-serif;
}

.fa-fa-icon-show-div,
.icons-show-div {
  display: none;
}

.icon-first i {
  color: #9B9B9B;
}
.icon-first h6 {
  display: none;
}

/**=====================
    2.19 Icons CSS Ends
==========================**/
/**=====================
   2.20 List CSS Start
==========================**/
.list-group-item.active {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}

.list-group i {
  margin-right: 10px;
}
.list-group img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.list-light-primary {
  background-color: rgba(48, 126, 243, 0.2);
}
.list-light-primary:hover, .list-light-primary:active, .list-light-primary:focus {
  background-color: rgba(48, 126, 243, 0.2);
}

.list-hover-primary {
  background-color: unset;
}
.list-hover-primary:hover, .list-hover-primary:active, .list-hover-primary.active, .list-hover-primary:focus {
  background-color: rgba(48, 126, 243, 0.2);
  color: #2c323f;
  z-index: 0;
}

.list-light-secondary {
  background-color: #FFE0E8;
}
.list-light-secondary:hover, .list-light-secondary:active, .list-light-secondary:focus {
  background-color: #FFE0E8;
}

.list-hover-secondary {
  background-color: unset;
}
.list-hover-secondary:hover, .list-hover-secondary:active, .list-hover-secondary.active, .list-hover-secondary:focus {
  background-color: #FFE0E8;
  color: #2c323f;
  z-index: 0;
}

.list-light-success {
  background-color: #D6EED4;
}
.list-light-success:hover, .list-light-success:active, .list-light-success:focus {
  background-color: #D6EED4;
}

.list-hover-success {
  background-color: unset;
}
.list-hover-success:hover, .list-hover-success:active, .list-hover-success.active, .list-hover-success:focus {
  background-color: #D6EED4;
  color: #2c323f;
  z-index: 0;
}

.list-light-warning {
  background-color: #FFF0D2;
}
.list-light-warning:hover, .list-light-warning:active, .list-light-warning:focus {
  background-color: #FFF0D2;
}

.list-hover-warning {
  background-color: unset;
}
.list-hover-warning:hover, .list-hover-warning:active, .list-hover-warning.active, .list-hover-warning:focus {
  background-color: #FFF0D2;
  color: #2c323f;
  z-index: 0;
}

.list-light-info {
  background-color: #D5F5FE;
}
.list-light-info:hover, .list-light-info:active, .list-light-info:focus {
  background-color: #D5F5FE;
}

.list-hover-info {
  background-color: unset;
}
.list-hover-info:hover, .list-hover-info:active, .list-hover-info.active, .list-hover-info:focus {
  background-color: #D5F5FE;
  color: #2c323f;
  z-index: 0;
}

.list-light-light {
  background-color: #F9F9FB;
}
.list-light-light:hover, .list-light-light:active, .list-light-light:focus {
  background-color: #F9F9FB;
}

.list-hover-light {
  background-color: unset;
}
.list-hover-light:hover, .list-hover-light:active, .list-hover-light.active, .list-hover-light:focus {
  background-color: #F9F9FB;
  color: #2c323f;
  z-index: 0;
}

.list-light-danger {
  background-color: #FEDDDB;
}
.list-light-danger:hover, .list-light-danger:active, .list-light-danger:focus {
  background-color: #FEDDDB;
}

.list-hover-danger {
  background-color: unset;
}
.list-hover-danger:hover, .list-hover-danger:active, .list-hover-danger.active, .list-hover-danger:focus {
  background-color: #FEDDDB;
  color: #2c323f;
  z-index: 0;
}

.list-light-dark {
  background-color: #EAEBF2;
}
.list-light-dark:hover, .list-light-dark:active, .list-light-dark:focus {
  background-color: #EAEBF2;
}

.list-hover-dark {
  background-color: unset;
}
.list-hover-dark:hover, .list-hover-dark:active, .list-hover-dark.active, .list-hover-dark:focus {
  background-color: #EAEBF2;
  color: #2c323f;
  z-index: 0;
}

.horizontal-list-wrapper .list-group .list-group-item {
  padding: 8px;
}

.list-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
@media (max-width: 414px) {
  .list-wrapper {
    flex-wrap: unset;
    word-break: break-word;
  }
}
@media (max-width: 366px) {
  .list-wrapper {
    padding-bottom: 8px;
  }
}
.list-wrapper .list-img {
  width: calc(40px + 15 * (100vw - 320px) / 1600);
  height: calc(40px + 15 * (100vw - 320px) / 1600);
  margin-bottom: 10px;
  border-radius: 50%;
}
.list-wrapper .list-content p {
  margin-bottom: 0;
}

.scrollbar-wrapper {
  max-height: 270px;
  overflow: auto;
}

@media (max-width: 480px) {
  .main-lists-content small {
    display: none;
  }
}

@media (max-width: 1199px) {
  .list-behavior-1 {
    gap: 12px;
  }
}
@media (max-width: 575px) {
  .list-behavior-1:first-child {
    margin-bottom: 14px;
  }
}
@media (max-width: 1999px) {
  .list-behavior-1 img {
    height: 86px;
  }
}
@media (max-width: 992px) {
  .list-behavior-1 img {
    height: 70px;
  }
}
.list-behavior-1 .flex-grow-1 {
  margin-left: 16px;
}
@media (max-width: 1199px) {
  .list-behavior-1 .flex-grow-1 {
    margin-left: 0;
  }
  .list-behavior-1 .flex-grow-1 p {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
[dir=rtl] .list-behavior-1 .flex-grow-1 {
  margin-right: 16px;
  margin-left: unset;
}

.list-behaviors > div:nth-child(even) .flex-grow-1 {
  order: -1;
  margin-left: unset;
  margin-right: 16px;
}
@media (max-width: 1199px) {
  .list-behaviors > div:nth-child(even) .flex-grow-1 {
    margin-left: 0;
    margin-right: 0;
    order: unset;
  }
}

.list-group .list-group-item {
  padding: 11px 16px;
}
.list-group .list-group-item .form-check-input:focus {
  box-shadow: unset !important;
}
[dir=rtl] .list-group .list-group-item i {
  margin-left: 10px;
  margin-right: unset;
}

@media (max-width: 1216px) {
  .contact-profile {
    background-color: unset;
  }
  .contact-profile.list-light-dark:hover {
    background-color: unset;
  }
}

.badge-list .list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.badge-list .list-group-item div {
  margin-right: auto;
  margin-left: 8px;
}
[dir=rtl] .badge-list .list-group-item div {
  margin-left: auto;
  margin-right: 8px;
}

/**=====================
   2.20 List CSS Ends
==========================**/
/**=====================
    2.21 Loader CSS Start
==========================**/
.loader-wrapper {
  position: fixed;
  z-index: 999999;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loader-wrapper .theme-loader {
  height: 100px;
  width: 100px;
  position: relative;
}
.loader-wrapper .theme-loader .loader-p {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(50vw - 75px);
}
.loader-wrapper .theme-loader .loader-p:before {
  content: "";
  border: 1em solid var(--theme-default);
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader 2s linear infinite;
  opacity: 0;
  animation-delay: 0.5s;
}
.loader-wrapper .theme-loader .loader-p:after {
  content: "";
  border: 1em solid var(--theme-default);
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader 2s linear infinite;
  opacity: 0;
}
@keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/**=====================
    2.21 Loader CSS Ends
==========================**/
/**=====================
    2.22 Modal CSS start
==========================**/
.modal-toggle-wrapper .form-control::placeholder {
  font-size: 13px;
}
.modal-toggle-wrapper .modal-img {
  display: flex;
  justify-content: center;
}
.modal-toggle-wrapper .modal-img img {
  width: 200px;
  height: 200px;
}
@media (max-width: 992px) {
  .modal-toggle-wrapper .modal-img img {
    width: calc(136px + 64 * (100vw - 320px) / 672);
    height: calc(136px + 64 * (100vw - 320px) / 672);
  }
}
.modal-toggle-wrapper .modal-img li img {
  width: 100px;
  height: 100px;
}
@media (max-width: 992px) {
  .modal-toggle-wrapper .modal-img li img {
    width: calc(75px + 25 * (100vw - 320px) / 672);
    height: calc(75px + 25 * (100vw - 320px) / 672);
  }
}
.modal-toggle-wrapper .modal-img li + li {
  margin-left: -39px;
}
.modal-toggle-wrapper button svg {
  width: 16px;
  height: 16px;
}

.large-modal-header,
.large-modal-body {
  display: flex;
  align-items: center;
  color: #2c323f;
}
.large-modal-header svg,
.large-modal-body svg {
  width: 20px;
  height: 20px;
}

.modal-padding-space {
  padding-left: 20px;
}

.svg-modal {
  width: 15px;
  height: 15px;
  stroke: #EBA31D;
  vertical-align: middle;
}

.card-wrapper .modal-heading h5 {
  font-size: calc(15px + 3 * (100vw - 320px) / 1080);
}
@media (min-width: 750px) and (max-width: 1200px) {
  .card-wrapper .balance-modal {
    display: flex;
    flex-direction: column;
  }
}

.modal-footer .btn {
  margin: 0 4px;
}

.modal-content .modal-header .btn-close:focus {
  box-shadow: unset;
}

.modal-details h6 {
  padding-bottom: 12px;
}
.modal-details .web-content {
  margin-bottom: 16px;
}
.modal-details .web-content .d-flex p {
  padding-bottom: 8px;
}
.modal-details .web-content .d-flex:last-child p {
  padding-bottom: 0;
}
.modal-details .web-content:last-child {
  margin-bottom: 0;
}

/**=====================
    2.22 Modal CSS end
==========================**/
/**=====================
  2.23 Popover CSS Start
==========================**/
.popover-header {
  background-color: #2c323f;
  color: #fff;
}

.popover-main .btn-showcase a {
  color: #fff;
}
.popover-main .btn-showcase button:last-child {
  margin-right: 0;
}

/**=====================
  2.23 Popover CSS Ends
==========================**/
/**=====================
  2.24 Print CSS Start
==========================**/
@media print {
  .sidebar-wrapper,
  .page-header {
    display: none;
  }
  .page-body {
    margin: 0 !important;
  }
  .customizer-links {
    display: none;
  }
  .page-wrapper .page-title {
    visibility: hidden;
  }
  .page-wrapper .page-body-wrapper .page-title {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin: 0;
  }
  table.fixedHeader-floating {
    display: none;
  }
  .email-left-aside {
    display: none;
  }
  .footer {
    display: none;
  }
}
.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px;
}

.invoice .text-right input {
  margin-top: 6px;
}
.invoice .d-flex {
  align-items: center;
}

/**=====================
  2.24 Print CSS Ends
==========================**/
/**=====================
    2.25 Radio CSS Start
==========================**/
.animate-chk label {
  line-height: 1.6;
  cursor: pointer;
}
.animate-chk label:last-child {
  margin-bottom: 0;
}

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 1rem 0 0;
}
.checkbox_animated:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 0.225rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.375rem;
  border: 2px solid var(--theme-default);
  border-top-style: none;
  border-right-style: none;
}
.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.3rem;
  height: 1.3rem;
  background: #fff;
  border: 2px solid #EAEAEA;
  cursor: pointer;
}
.checkbox_animated:checked:before {
  transform: rotate(-45deg) scale(1, 1);
}

.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
}
.radio_animated:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: var(--theme-default);
  border-radius: 50%;
}
.radio_animated:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border: 2px solid #EAEAEA;
  border-radius: 50%;
}
.radio_animated:checked:before {
  transform: scale(1, 1);
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -16px;
  border: 1px solid #EAEAEA;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #363636;
}
.checkbox input[type=checkbox] {
  opacity: 0;
}
.checkbox input[type=checkbox]:focus {
  outline-offset: -2px;
}
.checkbox input[type=checkbox]:focus + label::before {
  outline-offset: -2px;
}
.checkbox input[type=checkbox]:checked + label::before {
  font-family: themify;
  content: "\e64c";
  text-align: center;
  line-height: 1.2;
}
.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox]:disabled + label::before {
  background-color: #F3F3F3;
  cursor: not-allowed;
}
.checkbox .checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox .checkbox-inline {
  margin-top: 0;
}

.m-squar label::before {
  border-radius: 0;
}
.m-squar .checkbox-circle label::before {
  border-radius: 50%;
}
.m-squar .checkbox-inline {
  margin-top: 0;
}

.checkbox-primary label::before {
  border-color: var(--theme-default);
}
.checkbox-primary input[type=checkbox]:checked + label::before {
  border-color: var(--theme-default);
  color: var(--theme-default);
}
.checkbox-primary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-primary label:before {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
  color: #fff;
}
.checkbox-solid-primary input[type=checkbox]:checked + label::before {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
  color: #fff;
}
.checkbox-solid-primary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-primary input[type=radio] + label::before {
  border-color: var(--theme-default);
}
.radio-primary input[type=radio] + label::after {
  background-color: var(--theme-default);
}
.radio-primary input[type=radio]:checked + label::before {
  border-color: var(--theme-default);
}
.radio-primary input[type=radio]:checked + label::after {
  background-color: var(--theme-default);
}

.checkbox-secondary label::before {
  border-color: #EBA31D;
}
.checkbox-secondary input[type=checkbox]:checked + label::before {
  border-color: #EBA31D;
  color: #EBA31D;
}
.checkbox-secondary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-secondary label:before {
  background-color: #EBA31D;
  border-color: #EBA31D;
  color: #fff;
}
.checkbox-solid-secondary input[type=checkbox]:checked + label::before {
  background-color: #EBA31D;
  border-color: #EBA31D;
  color: #fff;
}
.checkbox-solid-secondary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-secondary input[type=radio] + label::before {
  border-color: #EBA31D;
}
.radio-secondary input[type=radio] + label::after {
  background-color: #EBA31D;
}
.radio-secondary input[type=radio]:checked + label::before {
  border-color: #EBA31D;
}
.radio-secondary input[type=radio]:checked + label::after {
  background-color: #EBA31D;
}

.checkbox-success label::before {
  border-color: #53A653;
}
.checkbox-success input[type=checkbox]:checked + label::before {
  border-color: #53A653;
  color: #53A653;
}
.checkbox-success input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-success label:before {
  background-color: #53A653;
  border-color: #53A653;
  color: #fff;
}
.checkbox-solid-success input[type=checkbox]:checked + label::before {
  background-color: #53A653;
  border-color: #53A653;
  color: #fff;
}
.checkbox-solid-success input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-success input[type=radio] + label::before {
  border-color: #53A653;
}
.radio-success input[type=radio] + label::after {
  background-color: #53A653;
}
.radio-success input[type=radio]:checked + label::before {
  border-color: #53A653;
}
.radio-success input[type=radio]:checked + label::after {
  background-color: #53A653;
}

.checkbox-danger label::before {
  border-color: #DC3545;
}
.checkbox-danger input[type=checkbox]:checked + label::before {
  border-color: #DC3545;
  color: #DC3545;
}
.checkbox-danger input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-danger label:before {
  background-color: #DC3545;
  border-color: #DC3545;
  color: #fff;
}
.checkbox-solid-danger input[type=checkbox]:checked + label::before {
  background-color: #DC3545;
  border-color: #DC3545;
  color: #fff;
}
.checkbox-solid-danger input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-danger input[type=radio] + label::before {
  border-color: #DC3545;
}
.radio-danger input[type=radio] + label::after {
  background-color: #DC3545;
}
.radio-danger input[type=radio]:checked + label::before {
  border-color: #DC3545;
}
.radio-danger input[type=radio]:checked + label::after {
  background-color: #DC3545;
}

.checkbox-info label::before {
  border-color: #01A1B9;
}
.checkbox-info input[type=checkbox]:checked + label::before {
  border-color: #01A1B9;
  color: #01A1B9;
}
.checkbox-info input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-info label:before {
  background-color: #01A1B9;
  border-color: #01A1B9;
  color: #fff;
}
.checkbox-solid-info input[type=checkbox]:checked + label::before {
  background-color: #01A1B9;
  border-color: #01A1B9;
  color: #fff;
}
.checkbox-solid-info input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-info input[type=radio] + label::before {
  border-color: #01A1B9;
}
.radio-info input[type=radio] + label::after {
  background-color: #01A1B9;
}
.radio-info input[type=radio]:checked + label::before {
  border-color: #01A1B9;
}
.radio-info input[type=radio]:checked + label::after {
  background-color: #01A1B9;
}

.checkbox-light label::before {
  border-color: #F3F3F3;
}
.checkbox-light input[type=checkbox]:checked + label::before {
  border-color: #F3F3F3;
  color: #F3F3F3;
}
.checkbox-light input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-light label:before {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #fff;
}
.checkbox-solid-light input[type=checkbox]:checked + label::before {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  color: #fff;
}
.checkbox-solid-light input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-light input[type=radio] + label::before {
  border-color: #F3F3F3;
}
.radio-light input[type=radio] + label::after {
  background-color: #F3F3F3;
}
.radio-light input[type=radio]:checked + label::before {
  border-color: #F3F3F3;
}
.radio-light input[type=radio]:checked + label::after {
  background-color: #F3F3F3;
}

.checkbox-dark label::before {
  border-color: #2c323f;
}
.checkbox-dark input[type=checkbox]:checked + label::before {
  border-color: #2c323f;
  color: #2c323f;
}
.checkbox-dark input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-dark label:before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}
.checkbox-solid-dark input[type=checkbox]:checked + label::before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}
.checkbox-solid-dark input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-dark input[type=radio] + label::before {
  border-color: #2c323f;
}
.radio-dark input[type=radio] + label::after {
  background-color: #2c323f;
}
.radio-dark input[type=radio]:checked + label::before {
  border-color: #2c323f;
}
.radio-dark input[type=radio]:checked + label::after {
  background-color: #2c323f;
}

.checkbox-warning label::before {
  border-color: #FFAA05;
}
.checkbox-warning input[type=checkbox]:checked + label::before {
  border-color: #FFAA05;
  color: #FFAA05;
}
.checkbox-warning input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-warning label:before {
  background-color: #FFAA05;
  border-color: #FFAA05;
  color: #fff;
}
.checkbox-solid-warning input[type=checkbox]:checked + label::before {
  background-color: #FFAA05;
  border-color: #FFAA05;
  color: #fff;
}
.checkbox-solid-warning input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-warning input[type=radio] + label::before {
  border-color: #FFAA05;
}
.radio-warning input[type=radio] + label::after {
  background-color: #FFAA05;
}
.radio-warning input[type=radio]:checked + label::before {
  border-color: #FFAA05;
}
.radio-warning input[type=radio]:checked + label::after {
  background-color: #FFAA05;
}

.m-checkbox-inline .checkbox {
  display: inline-block;
}
.m-checkbox-inline .radio {
  display: inline-block;
}
.m-checkbox-inline label {
  margin-right: 20px;
}

.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  cursor: pointer;
}
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #EAEAEA;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #9B9B9B;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type=radio] {
  opacity: 0;
}
.radio input[type=radio]:focus + label::before {
  outline-offset: -2px;
}
.radio input[type=radio]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type=radio]:disabled + label {
  opacity: 0.65;
}
.radio input[type=radio]:disabled + label::before {
  cursor: not-allowed;
}
.radio .radio-inline {
  margin-top: 0;
}

/**=====================
    2.25 Radio CSS Ends
==========================**/
/**=====================
  2.26 Ribbon CSS Start
==========================**/
[class*=ribbon-wrapper] {
  position: relative;
  padding: 58px 18px 28px 18px;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: 0px;
  color: #fff;
  z-index: 2;
}

.ribbon-clip {
  left: -14px;
}
.ribbon-clip:before {
  position: absolute;
  top: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}

.ribbon-vertical-right-wrapper {
  padding-right: 40px;
}

.ribbon-wrapper-bottom {
  padding-bottom: 40px;
  padding-top: 0;
}

.ribbon-wrapper-right-bottom {
  padding-right: 40px;
}

.ribbon-space-bottom {
  top: 0px;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 15px solid #2c323f;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-clip-right {
  right: -14px;
}
.ribbon-clip-right:before {
  position: absolute;
  top: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom {
  left: -14px;
  bottom: 12px;
  top: auto;
}
.ribbon-clip-bottom:before {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom-right {
  right: -14px;
  left: auto;
  bottom: 12px;
  top: auto;
}
.ribbon-clip-bottom-right:before {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #2c323f;
  border-left: 10px solid transparent;
}

.ribbon-vertical-left {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-right {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  top: -2px;
  left: auto;
  right: 20px;
}
[dir=rtl] .ribbon-vertical-right {
  left: auto !important;
  right: 20px !important;
}

.ribbon-bookmark.ribbon-vertical-left:before, .ribbon-bookmark.ribbon-vertical-right:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #2c323f;
  border-bottom: 10px solid transparent;
}

.ribbon-primary {
  background: #307EF3;
}

.ribbon-primary {
  background: #307EF3;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #307EF3;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #307EF3;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
  border-right-color: #307EF3;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #307EF3;
  border-left-color: #307EF3;
}
.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #307EF3;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #307EF3;
}

.ribbon-primary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #0d63e3;
  border-right-color: #0d63e3;
}

.ribbon-primary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #0d63e3;
  border-left-color: #0d63e3;
}

.ribbon-primary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #0d63e3;
  border-right-color: #0d63e3;
}

.ribbon-primary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #0d63e3;
  border-right-color: transparent;
  border-left-color: #0d63e3;
}

.ribbon-secondary {
  background: #EBA31D;
}

.ribbon-secondary {
  background: #EBA31D;
}

.ribbon-bookmark.ribbon-secondary:before {
  border-color: #EBA31D;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
  border-right-color: #EBA31D;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
  border-right-color: #EBA31D;
  border-bottom-color: transparent;
}

.ribbon-secondary.ribbon-corner:before {
  border-top-color: #EBA31D;
  border-left-color: #EBA31D;
}
.ribbon-secondary.ribbon-corner.ribbon-right:before {
  border-right-color: #EBA31D;
  border-left-color: transparent;
}
.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #EBA31D;
}

.ribbon-secondary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #c48511;
  border-right-color: #c48511;
}

.ribbon-secondary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #c48511;
  border-left-color: #c48511;
}

.ribbon-secondary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #c48511;
  border-right-color: #c48511;
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #c48511;
  border-right-color: transparent;
  border-left-color: #c48511;
}

.ribbon-success {
  background: #53A653;
}

.ribbon-success {
  background: #53A653;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #53A653;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #53A653;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
  border-right-color: #53A653;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #53A653;
  border-left-color: #53A653;
}
.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #53A653;
  border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #53A653;
}

.ribbon-success.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #428442;
  border-right-color: #428442;
}

.ribbon-success.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #428442;
  border-left-color: #428442;
}

.ribbon-success.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #428442;
  border-right-color: #428442;
}

.ribbon-success.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #428442;
  border-right-color: transparent;
  border-left-color: #428442;
}

.ribbon-danger {
  background: #DC3545;
}

.ribbon-danger {
  background: #DC3545;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #DC3545;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #DC3545;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
  border-right-color: #DC3545;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #DC3545;
  border-left-color: #DC3545;
}
.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #DC3545;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #DC3545;
}

.ribbon-danger.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #bd2130;
  border-right-color: #bd2130;
}

.ribbon-danger.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #bd2130;
  border-left-color: #bd2130;
}

.ribbon-danger.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #bd2130;
  border-right-color: #bd2130;
}

.ribbon-danger.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #bd2130;
  border-right-color: transparent;
  border-left-color: #bd2130;
}

.ribbon-info {
  background: #01A1B9;
}

.ribbon-info {
  background: #01A1B9;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #01A1B9;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #01A1B9;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-right-color: #01A1B9;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #01A1B9;
  border-left-color: #01A1B9;
}
.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #01A1B9;
  border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #01A1B9;
}

.ribbon-info.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #017586;
  border-right-color: #017586;
}

.ribbon-info.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #017586;
  border-left-color: #017586;
}

.ribbon-info.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #017586;
  border-right-color: #017586;
}

.ribbon-info.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #017586;
  border-right-color: transparent;
  border-left-color: #017586;
}

.ribbon-light {
  background: #F3F3F3;
}

.ribbon-light {
  background: #F3F3F3;
}

.ribbon-bookmark.ribbon-light:before {
  border-color: #F3F3F3;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-light:before {
  border-right-color: #F3F3F3;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
  border-right-color: #F3F3F3;
  border-bottom-color: transparent;
}

.ribbon-light.ribbon-corner:before {
  border-top-color: #F3F3F3;
  border-left-color: #F3F3F3;
}
.ribbon-light.ribbon-corner.ribbon-right:before {
  border-right-color: #F3F3F3;
  border-left-color: transparent;
}
.ribbon-light.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #F3F3F3;
}

.ribbon-light.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #dadada;
  border-right-color: #dadada;
}

.ribbon-light.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #dadada;
  border-left-color: #dadada;
}

.ribbon-light.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #dadada;
  border-right-color: #dadada;
}

.ribbon-light.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #dadada;
  border-right-color: transparent;
  border-left-color: #dadada;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-bookmark.ribbon-dark:before {
  border-color: #2c323f;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-bottom-color: transparent;
}

.ribbon-dark.ribbon-corner:before {
  border-top-color: #2c323f;
  border-left-color: #2c323f;
}
.ribbon-dark.ribbon-corner.ribbon-right:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}
.ribbon-dark.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2c323f;
}

.ribbon-dark.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #171a21;
  border-left-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: transparent;
  border-left-color: #171a21;
}

.ribbon-warning {
  background: #FFAA05;
}

.ribbon-warning {
  background: #FFAA05;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #FFAA05;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #FFAA05;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
  border-right-color: #FFAA05;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #FFAA05;
  border-left-color: #FFAA05;
}
.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #FFAA05;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #FFAA05;
}

.ribbon-warning.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #d18a00;
  border-right-color: #d18a00;
}

.ribbon-warning.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #d18a00;
  border-left-color: #d18a00;
}

.ribbon-warning.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #d18a00;
  border-right-color: #d18a00;
}

.ribbon-warning.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #d18a00;
  border-right-color: transparent;
  border-left-color: #d18a00;
}

.vertical-lp-space {
  padding: 58px 28px 28px 50px;
}

.vertical-rp-space {
  padding: 58px 50px 28px 28px;
}

.clip-bp-space {
  padding: 28px 18px 58px 18px;
}

/**=====================
  2.26 Ribbon CSS Ends
==========================**/
/**=====================
   2.27 Range-slider CSS Start
==========================**/
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar {
  background-color: #307EF3;
}
.irs--flat .irs-from::before,
.irs--flat .irs-to::before,
.irs--flat .irs-single::before,
.irs--flat .irs-bar::before {
  border-top-color: #307EF3;
}
.irs--flat .irs-handle i:first-child {
  background-color: #307EF3;
}

.irs--round .irs-single {
  background-color: #EBA31D;
}
.irs--round .irs-single::before {
  border-top-color: #EBA31D;
}
.irs--round .irs-handle {
  border: 4px solid #EBA31D;
}
.irs--round .irs-bar {
  background-color: #EBA31D;
}

.irs--square .irs-handle {
  border: 3px solid #53A653;
}
.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-bar {
  background-color: #53A653;
}

.irs--sharp .irs-from::before,
.irs--sharp .irs-to::before {
  border-top-color: #01A1B9;
}
.irs--sharp .irs-min,
.irs--sharp .irs-max,
.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-bar {
  background-color: #01A1B9;
}
.irs--sharp .irs-line {
  background-color: #cccccc;
}
.irs--sharp .irs-handle {
  background-color: #01A1B9;
}
.irs--sharp .irs-handle i:first-child {
  border-top-color: #01A1B9;
}

.irs--big .irs-bar {
  border: 1px solid #FFAA05;
  background: #FFAA05;
  box-shadow: unset;
}
.irs--big .irs-from,
.irs--big .irs-to {
  background-color: #FFAA05;
  background: unset;
}
.irs--big .irs-handle {
  background: #FFAA05;
}
.irs--big .irs-grid-pol {
  background-color: #FFAA05;
}
.irs--big .irs-grid-text {
  color: #FFAA05;
}

.irs--modern .irs-single {
  background-color: #DC3545;
}
.irs--modern .irs-single::before {
  border-top-color: #DC3545;
}
.irs--modern .irs-bar {
  background: #DC3545;
}
.irs--modern .irs-grid-text {
  color: #DC3545;
}

/**=====================
    2.27 Range-slider CSS Ends
==========================**/
/**=====================
    2.28 Switch CSS Start
==========================**/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  display: none;
}
.switch input:checked + .switch-state {
  background-color: var(--theme-default);
}
.switch input:checked + .switch-state:before {
  transform: translateX(26px);
}
.switch input:focus + .switch-state {
  box-shadow: 0 0 1px var(--theme-default);
}

.switch-state {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F3F3F3;
  transition: 0.4s;
}
.switch-state:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 20px;
}

.icon-state .switch-state:after {
  content: "\efb8";
  font-family: IcoFont;
  left: 10px;
  position: absolute;
  top: 10px;
  color: #2c323f;
  transition: 0.4s;
  opacity: 0.3;
}
.icon-state input:checked + .switch-state:after {
  content: "\efad";
  left: 36px;
  opacity: 1;
  transition: 0.4s;
}

.switch-lg .switch {
  width: 75px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.switch-lg .switch .switch-state:before {
  height: 30px;
  width: 30px;
  left: 7px;
  bottom: 5px;
  top: 4px;
}

.switch-lg.icon-state .switch .switch-state:after {
  left: 12px;
  font-size: 18px;
}
.switch-lg.icon-state .switch input:checked + .switch-state:after {
  left: 47px;
}
.switch-lg.icon-state .switch input:checked + .switch-state:before {
  transform: translateX(32px);
}

.switch-sm.icon-state .switch .switch-state:after {
  top: 4px;
  left: 4px;
  font-size: 8px;
}
.switch-sm.icon-state .switch input:checked + .switch-state:after {
  left: 13px;
  top: 4px;
}
.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.switch-sm .switch .switch-state:before {
  height: 8px;
  width: 8px;
  left: 4px;
  bottom: 4px;
}
.switch-sm input:checked + .switch-state:before {
  left: -13px;
}

.switch-outline .switch-state {
  background-color: #fff;
  border: 1px solid var(--theme-default);
  transition: 0.4s;
}
.switch-outline .switch-state:before {
  background-color: #F3F3F3;
  bottom: 3px;
  color: #fff;
}

.switch-outline .switch input {
  display: none;
}
.switch-outline .switch input:checked + .switch-state {
  background-color: transparent;
  border: 1px solid var(--theme-default);
}
.switch-outline .switch input:checked + .switch-state:before {
  background-color: var(--theme-default);
  bottom: 3px;
  transform: translateX(26px);
}
.switch-outline .switch input:checked + .switch-state:after {
  color: #fff;
}

.switch-outline .switch-state.bg-primary {
  border: 1px solid var(--theme-default);
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid var(--theme-default);
}
.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: var(--theme-default);
}
.switch-outline .switch-state.bg-dark {
  border: 1px solid #2c323f;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-dark {
  background-color: transparent !important;
  border: 1px solid #2c323f;
}
.switch-outline .switch input:checked + .switch-state.bg-dark:before {
  background-color: #2c323f;
}
.switch-outline .switch-state.bg-warning {
  border: 1px solid #FFAA05;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-warning {
  background-color: transparent !important;
  border: 1px solid #FFAA05;
}
.switch-outline .switch input:checked + .switch-state.bg-warning:before {
  background-color: #FFAA05;
}
.switch-outline .switch-state.bg-success {
  border: 1px solid #53A653;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-success {
  background-color: transparent !important;
  border: 1px solid #53A653;
}
.switch-outline .switch input:checked + .switch-state.bg-success:before {
  background-color: #53A653;
}
.switch-outline .switch-state.bg-info {
  border: 1px solid #01A1B9;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-info {
  background-color: transparent !important;
  border: 1px solid #01A1B9;
}
.switch-outline .switch input:checked + .switch-state.bg-info:before {
  background-color: #01A1B9;
}
.switch-outline .switch-state.bg-danger {
  border: 1px solid #DC3545;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-danger {
  background-color: transparent !important;
  border: 1px solid #DC3545;
}
.switch-outline .switch input:checked + .switch-state.bg-danger:before {
  background-color: #DC3545;
}
.switch-outline .switch-state.bg-primary {
  border: 1px solid var(--theme-default);
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid var(--theme-default);
}
.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: var(--theme-default);
}
.switch-outline .switch-state.bg-secondary {
  border: 1px solid var(--theme-secondary);
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-secondary {
  background-color: transparent !important;
  border: 1px solid var(--theme-secondary);
}
.switch-outline .switch input:checked + .switch-state.bg-secondary:before {
  background-color: var(--theme-secondary);
}

.switch-showcase {
  margin-bottom: -8px;
}
.switch-showcase .d-flex {
  line-height: 1;
}
.switch-showcase .form-check .form-check-input {
  float: right;
}

.form-switch .form-check-input:checked {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}

.tg-list {
  text-align: center;
  display: flex;
  align-items: center;
}

.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-skewed + .tgl-btn {
  overflow: hidden;
  transform: skew(-10deg);
  backface-visibility: hidden;
  transition: all 0.2s ease;
  background: #EBA31D;
}
.tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: var(--white);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}
.tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}
.tgl-skewed + .tgl-btn:active {
  background: #EBA31D;
}
.tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}
.tgl-skewed:checked + .tgl-btn {
  background: #307EF3;
}
.tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}
.tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}
.tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}

.tgl-flip + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  perspective: 100px;
}
.tgl-flip + .tgl-btn:after, .tgl-flip + .tgl-btn:before {
  display: inline-block;
  transition: all 0.4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 4px;
}
.tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #FFAA05;
  transform: rotateY(-180deg);
}
.tgl-flip + .tgl-btn:before {
  background: #DC3545;
  content: attr(data-tg-off);
}
.tgl-flip + .tgl-btn:active:before {
  transform: rotateY(-20deg);
}
.tgl-flip:checked + .tgl-btn:before {
  transform: rotateY(180deg);
}
.tgl-flip:checked + .tgl-btn:after {
  transform: rotateY(0);
  left: 0;
  background: #53A653;
}
.tgl-flip:checked + .tgl-btn:active:after {
  transform: rotateY(20deg);
}

.radius-none .form-check-input {
  border-radius: 3px;
}

.tg-list .tg-list-item .square-checked .switch-state::before {
  content: "";
  border-radius: 0;
}

.switch-wrapper .d-flex {
  margin-right: 20px;
}
.switch-wrapper .d-flex .col-form-label {
  padding-bottom: 0;
}
.switch-wrapper .tg-list p {
  margin: 0 8px 0 0;
}

.default-square .form-switch .form-check-input {
  background-image: url(../images/switch/square-gray.png);
}
.default-square .form-switch .form-check-input:checked {
  background-image: url(../images/switch/square.svg);
}

/**=====================
    2.28 Switch CSS Ends
==========================**/
/**=====================
  2.29 Tab CSS Start
==========================**/
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--theme-default);
}

.nav i {
  margin-right: 10px;
}

.nav-right {
  justify-content: flex-end;
}

.nav-theme .nav-link,
.nav-pills.nav-theme .nav-link {
  color: var(--theme-default);
}
.nav-theme .nav-link.active,
.nav-theme .show > .nav-link,
.nav-pills.nav-theme .nav-link.active,
.nav-pills.nav-theme .show > .nav-link {
  background-color: var(--theme-default);
  color: #fff;
}

.nav-primary .nav-link,
.nav-pills.nav-primary .nav-link {
  color: var(--theme-default);
}
.nav-primary .nav-link.active,
.nav-primary .show > .nav-link,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-primary .show > .nav-link {
  background-color: var(--theme-default);
  color: #fff;
}

.nav-secondary .nav-link,
.nav-pills.nav-secondary .nav-link {
  color: #EBA31D;
}
.nav-secondary .nav-link.active,
.nav-secondary .show > .nav-link,
.nav-pills.nav-secondary .nav-link.active,
.nav-pills.nav-secondary .show > .nav-link {
  background-color: #EBA31D;
  color: #fff;
}

.nav-success .nav-link,
.nav-pills.nav-success .nav-link {
  color: #53A653;
}
.nav-success .nav-link.active,
.nav-success .show > .nav-link,
.nav-pills.nav-success .nav-link.active,
.nav-pills.nav-success .show > .nav-link {
  background-color: #53A653;
  color: #fff;
}

.nav-danger .nav-link,
.nav-pills.nav-danger .nav-link {
  color: #DC3545;
}
.nav-danger .nav-link.active,
.nav-danger .show > .nav-link,
.nav-pills.nav-danger .nav-link.active,
.nav-pills.nav-danger .show > .nav-link {
  background-color: #DC3545;
  color: #fff;
}

.nav-info .nav-link,
.nav-pills.nav-info .nav-link {
  color: #01A1B9;
}
.nav-info .nav-link.active,
.nav-info .show > .nav-link,
.nav-pills.nav-info .nav-link.active,
.nav-pills.nav-info .show > .nav-link {
  background-color: #01A1B9;
  color: #fff;
}

.nav-light .nav-link,
.nav-pills.nav-light .nav-link {
  color: #F3F3F3;
}
.nav-light .nav-link.active,
.nav-light .show > .nav-link,
.nav-pills.nav-light .nav-link.active,
.nav-pills.nav-light .show > .nav-link {
  background-color: #F3F3F3;
  color: #fff;
}

.nav-dark .nav-link,
.nav-pills.nav-dark .nav-link {
  color: #2c323f;
}
.nav-dark .nav-link.active,
.nav-dark .show > .nav-link,
.nav-pills.nav-dark .nav-link.active,
.nav-pills.nav-dark .show > .nav-link {
  background-color: #2c323f;
  color: #fff;
}

.nav-warning .nav-link,
.nav-pills.nav-warning .nav-link {
  color: #FFAA05;
}
.nav-warning .nav-link.active,
.nav-warning .show > .nav-link,
.nav-pills.nav-warning .nav-link.active,
.nav-pills.nav-warning .show > .nav-link {
  background-color: #FFAA05;
  color: #fff;
}

.nav-tabs.nav-bottom .nav-item .nav-link.active,
.nav-tabs.nav-bottom .nav-item .nav-link.focus,
.nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #fff #dee2e6 #dee2e6;
}

.tab-content ~ .nav-tabs.border-tab {
  margin-bottom: 0;
  margin-top: 30px;
}

.border-tab.nav-tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: none !important;
  margin-bottom: 30px;
}
.border-tab.nav-tabs .nav-item .nav-link {
  font-size: 16px;
  color: #2c323f;
  text-align: center;
  padding: 10px 25px;
  border: none;
  border-bottom: none;
  transition: 0.3s;
}
@media (min-width: 576px) and (max-width: 1920px) {
  .border-tab.nav-tabs .nav-item .nav-link {
    padding-top: 0;
  }
}
.border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus, .border-tab.nav-tabs .nav-item .nav-link:hover {
  color: var(--theme-default);
}
.border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus {
  border-bottom: none;
}

.border-tab.nav-left .nav-link {
  transition: all 0.3s ease;
  color: #2c323f;
}
.border-tab.nav-left .nav-link.active,
.border-tab.nav-left .show > .nav-link {
  background-color: transparent;
  border-left-color: var(--theme-default);
  color: var(--theme-default);
  border-radius: 0;
  transition: all 0.3s ease;
}

.border-tab.nav-right .nav-link {
  border-right: 2px solid transparent;
  transition: all 0.3s ease;
  color: #2c323f;
  text-align: right;
}
.border-tab.nav-right .nav-link.active,
.border-tab.nav-right .show > .nav-link {
  background-color: transparent;
  border-right-color: var(--theme-default);
  color: var(--theme-default);
  border-radius: 0;
  transition: all 0.3s ease;
}

.border-tab.nav-theme .nav-link.active,
.border-tab.nav-theme .nav-item.show,
.border-tab.nav-theme .nav-link:focus,
.border-tab.nav-theme .nav-link:hover {
  color: var(--theme-default);
  background-color: transparent;
}
.border-tab.nav-theme .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-theme .nav-item .nav-link.active, .border-tab.nav-theme .nav-item .nav-link.show, .border-tab.nav-theme .nav-item .nav-link:focus {
  border-bottom: 2px solid var(--theme-default);
}
.border-tab.nav-theme .nav-item .nav-link.active, .border-tab.nav-theme .nav-item .nav-link.show, .border-tab.nav-theme .nav-item .nav-link:focus, .border-tab.nav-theme .nav-item .nav-link:hover {
  color: var(--theme-default);
}

.border-tab.nav-left.nav-theme .nav-link.active,
.border-tab.nav-left.nav-theme .show > .nav-link {
  border-left-color: var(--theme-default);
  color: var(--theme-default);
}

.border-tab.nav-right.nav-theme .nav-link.active,
.border-tab.nav-right.nav-theme .show > .nav-link {
  border-right-color: var(--theme-default);
  color: var(--theme-default);
}

.border-tab.nav-primary .nav-link.active,
.border-tab.nav-primary .nav-item.show,
.border-tab.nav-primary .nav-link:focus,
.border-tab.nav-primary .nav-link:hover {
  color: var(--theme-default);
  background-color: transparent;
}
.border-tab.nav-primary .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus {
  border-bottom: 2px solid var(--theme-default);
}
.border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus, .border-tab.nav-primary .nav-item .nav-link:hover {
  color: var(--theme-default);
}

.border-tab.nav-left.nav-primary .nav-link.active,
.border-tab.nav-left.nav-primary .show > .nav-link {
  border-left-color: var(--theme-default);
  color: var(--theme-default);
}

.border-tab.nav-right.nav-primary .nav-link.active,
.border-tab.nav-right.nav-primary .show > .nav-link {
  border-right-color: var(--theme-default);
  color: var(--theme-default);
}

.border-tab.nav-secondary .nav-link.active,
.border-tab.nav-secondary .nav-item.show,
.border-tab.nav-secondary .nav-link:focus,
.border-tab.nav-secondary .nav-link:hover {
  color: #EBA31D;
  background-color: transparent;
}
.border-tab.nav-secondary .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-secondary .nav-item .nav-link.active, .border-tab.nav-secondary .nav-item .nav-link.show, .border-tab.nav-secondary .nav-item .nav-link:focus {
  border-bottom: 2px solid #EBA31D;
}
.border-tab.nav-secondary .nav-item .nav-link.active, .border-tab.nav-secondary .nav-item .nav-link.show, .border-tab.nav-secondary .nav-item .nav-link:focus, .border-tab.nav-secondary .nav-item .nav-link:hover {
  color: #EBA31D;
}

.border-tab.nav-left.nav-secondary .nav-link.active,
.border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: #EBA31D;
  color: #EBA31D;
}

.border-tab.nav-right.nav-secondary .nav-link.active,
.border-tab.nav-right.nav-secondary .show > .nav-link {
  border-right-color: #EBA31D;
  color: #EBA31D;
}

.border-tab.nav-success .nav-link.active,
.border-tab.nav-success .nav-item.show,
.border-tab.nav-success .nav-link:focus,
.border-tab.nav-success .nav-link:hover {
  color: #53A653;
  background-color: transparent;
}
.border-tab.nav-success .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-success .nav-item .nav-link.active, .border-tab.nav-success .nav-item .nav-link.show, .border-tab.nav-success .nav-item .nav-link:focus {
  border-bottom: 2px solid #53A653;
}
.border-tab.nav-success .nav-item .nav-link.active, .border-tab.nav-success .nav-item .nav-link.show, .border-tab.nav-success .nav-item .nav-link:focus, .border-tab.nav-success .nav-item .nav-link:hover {
  color: #53A653;
}

.border-tab.nav-left.nav-success .nav-link.active,
.border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #53A653;
  color: #53A653;
}

.border-tab.nav-right.nav-success .nav-link.active,
.border-tab.nav-right.nav-success .show > .nav-link {
  border-right-color: #53A653;
  color: #53A653;
}

.border-tab.nav-danger .nav-link.active,
.border-tab.nav-danger .nav-item.show,
.border-tab.nav-danger .nav-link:focus,
.border-tab.nav-danger .nav-link:hover {
  color: #DC3545;
  background-color: transparent;
}
.border-tab.nav-danger .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-danger .nav-item .nav-link.active, .border-tab.nav-danger .nav-item .nav-link.show, .border-tab.nav-danger .nav-item .nav-link:focus {
  border-bottom: 2px solid #DC3545;
}
.border-tab.nav-danger .nav-item .nav-link.active, .border-tab.nav-danger .nav-item .nav-link.show, .border-tab.nav-danger .nav-item .nav-link:focus, .border-tab.nav-danger .nav-item .nav-link:hover {
  color: #DC3545;
}

.border-tab.nav-left.nav-danger .nav-link.active,
.border-tab.nav-left.nav-danger .show > .nav-link {
  border-left-color: #DC3545;
  color: #DC3545;
}

.border-tab.nav-right.nav-danger .nav-link.active,
.border-tab.nav-right.nav-danger .show > .nav-link {
  border-right-color: #DC3545;
  color: #DC3545;
}

.border-tab.nav-info .nav-link.active,
.border-tab.nav-info .nav-item.show,
.border-tab.nav-info .nav-link:focus,
.border-tab.nav-info .nav-link:hover {
  color: #01A1B9;
  background-color: transparent;
}
.border-tab.nav-info .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-info .nav-item .nav-link.active, .border-tab.nav-info .nav-item .nav-link.show, .border-tab.nav-info .nav-item .nav-link:focus {
  border-bottom: 2px solid #01A1B9;
}
.border-tab.nav-info .nav-item .nav-link.active, .border-tab.nav-info .nav-item .nav-link.show, .border-tab.nav-info .nav-item .nav-link:focus, .border-tab.nav-info .nav-item .nav-link:hover {
  color: #01A1B9;
}

.border-tab.nav-left.nav-info .nav-link.active,
.border-tab.nav-left.nav-info .show > .nav-link {
  border-left-color: #01A1B9;
  color: #01A1B9;
}

.border-tab.nav-right.nav-info .nav-link.active,
.border-tab.nav-right.nav-info .show > .nav-link {
  border-right-color: #01A1B9;
  color: #01A1B9;
}

.border-tab.nav-light .nav-link.active,
.border-tab.nav-light .nav-item.show,
.border-tab.nav-light .nav-link:focus,
.border-tab.nav-light .nav-link:hover {
  color: #F3F3F3;
  background-color: transparent;
}
.border-tab.nav-light .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-light .nav-item .nav-link.active, .border-tab.nav-light .nav-item .nav-link.show, .border-tab.nav-light .nav-item .nav-link:focus {
  border-bottom: 2px solid #F3F3F3;
}
.border-tab.nav-light .nav-item .nav-link.active, .border-tab.nav-light .nav-item .nav-link.show, .border-tab.nav-light .nav-item .nav-link:focus, .border-tab.nav-light .nav-item .nav-link:hover {
  color: #F3F3F3;
}

.border-tab.nav-left.nav-light .nav-link.active,
.border-tab.nav-left.nav-light .show > .nav-link {
  border-left-color: #F3F3F3;
  color: #F3F3F3;
}

.border-tab.nav-right.nav-light .nav-link.active,
.border-tab.nav-right.nav-light .show > .nav-link {
  border-right-color: #F3F3F3;
  color: #F3F3F3;
}

.border-tab.nav-dark .nav-link.active,
.border-tab.nav-dark .nav-item.show,
.border-tab.nav-dark .nav-link:focus,
.border-tab.nav-dark .nav-link:hover {
  color: #2c323f;
  background-color: transparent;
}
.border-tab.nav-dark .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-dark .nav-item .nav-link.active, .border-tab.nav-dark .nav-item .nav-link.show, .border-tab.nav-dark .nav-item .nav-link:focus {
  border-bottom: 2px solid #2c323f;
}
.border-tab.nav-dark .nav-item .nav-link.active, .border-tab.nav-dark .nav-item .nav-link.show, .border-tab.nav-dark .nav-item .nav-link:focus, .border-tab.nav-dark .nav-item .nav-link:hover {
  color: #2c323f;
}

.border-tab.nav-left.nav-dark .nav-link.active,
.border-tab.nav-left.nav-dark .show > .nav-link {
  border-left-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-right.nav-dark .nav-link.active,
.border-tab.nav-right.nav-dark .show > .nav-link {
  border-right-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-warning .nav-link.active,
.border-tab.nav-warning .nav-item.show,
.border-tab.nav-warning .nav-link:focus,
.border-tab.nav-warning .nav-link:hover {
  color: #FFAA05;
  background-color: transparent;
}
.border-tab.nav-warning .nav-item .nav-link {
  transition: 0.3s;
}
.border-tab.nav-warning .nav-item .nav-link.active, .border-tab.nav-warning .nav-item .nav-link.show, .border-tab.nav-warning .nav-item .nav-link:focus {
  border-bottom: 2px solid #FFAA05;
}
.border-tab.nav-warning .nav-item .nav-link.active, .border-tab.nav-warning .nav-item .nav-link.show, .border-tab.nav-warning .nav-item .nav-link:focus, .border-tab.nav-warning .nav-item .nav-link:hover {
  color: #FFAA05;
}

.border-tab.nav-left.nav-warning .nav-link.active,
.border-tab.nav-left.nav-warning .show > .nav-link {
  border-left-color: #FFAA05;
  color: #FFAA05;
}

.border-tab.nav-right.nav-warning .nav-link.active,
.border-tab.nav-right.nav-warning .show > .nav-link {
  border-right-color: #FFAA05;
  color: #FFAA05;
}

.flex-space {
  display: flex;
  gap: 16px;
}
@media (max-width: 576px) {
  .flex-space {
    justify-content: center;
  }
}

.tab-img {
  height: 100px;
}

.nav-item .navlink-title {
  background-color: unset;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.simple-wrapper .dropdown-collection .dropdown-item:hover, .simple-wrapper .dropdown-collection .dropdown-item:active {
  background-color: #cccccc !important;
}

.blog-wrapper .blog-img {
  width: 100px;
  height: 100px;
}

.designer-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 1400px) {
  .designer-details {
    gap: calc(14px + 21 * (100vw - 320px) / 1080);
  }
}
.designer-details .designer-profile {
  display: inline-block;
}
.designer-details .designer-profile .designer-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}
.designer-details .designer-profile .designer-wrap .designer-img {
  height: 50px;
  border-radius: 100%;
}
.designer-details .designer-profile .designer-wrap .designer-content h6 {
  font-weight: 500;
}
.designer-details .designer-profile .designer-wrap .designer-content p {
  color: #999999;
}

.nav-tabs .nav-border {
  color: #000;
  border: none;
}

.ico-color {
  color: #FFAA05;
  font-size: 16px;
}

.main-inbox .header-inbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #999999;
  padding-bottom: 8px;
}
.main-inbox .header-inbox .header-left-inbox {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.main-inbox .header-inbox .header-left-inbox .inbox-img img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.main-inbox .header-inbox .header-right-inbox {
  display: flex;
  gap: 12px;
}
@media (max-width: 560px) {
  .main-inbox .header-inbox .header-right-inbox {
    display: none;
  }
}
.main-inbox .body-inbox .body-h-wrapper {
  display: flex;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--light-border);
  align-items: center;
}
@media (max-width: 630px) {
  .main-inbox .body-inbox .body-h-wrapper {
    align-items: unset;
  }
}

.tab-space {
  font-size: 14px;
}

#bottom-tab .nav-border.tab-primary:hover {
  color: #307EF3;
}
#bottom-tab .nav-border.tab-primary:focus, #bottom-tab .nav-border.tab-primary:active, #bottom-tab .nav-border.tab-primary.active {
  color: #307EF3;
  border-bottom: 2px solid #307EF3;
}

#bottom-tab .nav-border.tab-secondary:hover {
  color: #EBA31D;
}
#bottom-tab .nav-border.tab-secondary:focus, #bottom-tab .nav-border.tab-secondary:active, #bottom-tab .nav-border.tab-secondary.active {
  color: #EBA31D;
  border-bottom: 2px solid #EBA31D;
}

#bottom-tab .nav-border.tab-success:hover {
  color: #53A653;
}
#bottom-tab .nav-border.tab-success:focus, #bottom-tab .nav-border.tab-success:active, #bottom-tab .nav-border.tab-success.active {
  color: #53A653;
  border-bottom: 2px solid #53A653;
}

#bottom-tab .nav-border.tab-danger:hover {
  color: #DC3545;
}
#bottom-tab .nav-border.tab-danger:focus, #bottom-tab .nav-border.tab-danger:active, #bottom-tab .nav-border.tab-danger.active {
  color: #DC3545;
  border-bottom: 2px solid #DC3545;
}

#bottom-tab .nav-border.tab-warning:hover {
  color: #FFAA05;
}
#bottom-tab .nav-border.tab-warning:focus, #bottom-tab .nav-border.tab-warning:active, #bottom-tab .nav-border.tab-warning.active {
  color: #FFAA05;
  border-bottom: 2px solid #FFAA05;
}

#bottom-tab .nav-border.tab-info:hover {
  color: #01A1B9;
}
#bottom-tab .nav-border.tab-info:focus, #bottom-tab .nav-border.tab-info:active, #bottom-tab .nav-border.tab-info.active {
  color: #01A1B9;
  border-bottom: 2px solid #01A1B9;
}

#bottom-tab .nav-border.tab-light:hover {
  color: #F3F3F3;
}
#bottom-tab .nav-border.tab-light:focus, #bottom-tab .nav-border.tab-light:active, #bottom-tab .nav-border.tab-light.active {
  color: #F3F3F3;
  border-bottom: 2px solid #F3F3F3;
}

#bottom-tab .nav-border.tab-dark:hover {
  color: #2c323f;
}
#bottom-tab .nav-border.tab-dark:focus, #bottom-tab .nav-border.tab-dark:active, #bottom-tab .nav-border.tab-dark.active {
  color: #2c323f;
  border-bottom: 2px solid #2c323f;
}

#sideline-tab .nav-effect {
  display: inline-block;
  position: relative;
}
#sideline-tab .nav-effect::after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 14px;
  width: 80%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(0, 1);
}
#sideline-tab .nav-effect:hover::after {
  opacity: 1;
  transform: scale(1);
}

.tabs-responsive-side .border-tab.nav-secondary.nav-left .nav-link:hover, .tabs-responsive-side .border-tab.nav-secondary.nav-left .nav-link.active {
  background-color: unset;
  border-left: none;
  border-radius: 0;
  transition: none;
}

@media (max-width: 576px) {
  .pills-blogger {
    justify-content: center;
    flex-wrap: wrap;
  }
}

::placeholder {
  font-size: 14px;
}

.material-wrapper {
  display: flex;
  gap: 12px;
}
@media (max-width: 576px) {
  .material-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

/**=====================
    2.29 Tab CSS Ends
==========================**/
/**=====================
    2.30 Table CSS Start
==========================**/
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #dee2e6;
}

.pills-component {
  width: 20%;
}

#row_create td.highlight {
  font-weight: 600;
  color: #307EF3;
}
#row_create td.danger {
  font-weight: 600;
  color: #DC3545;
}

.table {
  margin-bottom: 0px;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #EAEAEA;
}
.table th,
.table td {
  padding: 0.75rem;
}
.table.table-primary th {
  color: #fff;
}
.table thead.bg-primary tr th {
  color: #fff;
}
.table th {
  color: #363636;
  font-weight: 600;
}
.table th.f-light {
  color: var(--chart-text-color);
  opacity: 0.8;
}
.table td {
  color: #363636;
}
.table [class*=bg-] {
  color: #fff;
}
.table .bg-light {
  color: #363636;
}
.table tfoot {
  font-weight: bold;
}

.table-dark th {
  color: #fff;
}

.table-bordered .dropdown-menu.show {
  background-color: #fff;
}

.table-inverse {
  color: #fff;
  background-color: #191f31;
}
.table-inverse th,
.table-inverse td {
  border-color: #fff;
  color: #fff;
  background-color: #191f31;
}
.table-inverse.table-bordered {
  border: 0;
}

.table[class*=bg-] th,
.table[class*=bg-] td {
  color: #fff;
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: #f6f6f6;
}
.table-hover tbody tr {
  vertical-align: middle;
}
.table-hover tbody tr:hover {
  background-color: rgba(48, 126, 243, 0.1);
}
.table-hover tbody tr td svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}
[dir=rtl] .table-hover tbody tr td svg {
  margin-right: unset;
  margin-left: 10px;
}

.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(48, 126, 243, 0.1) !important;
  --bs-table-accent-bg:unset;
}
.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) th, .grid-wrrapper .table-striped tbody tr:nth-of-type(odd) td {
  color: #307EF3;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--light-background);
  --bs-table-accent-bg: unset;
}

.typography-table tr:last-child {
  border-bottom: none;
}
.typography-table tr:last-child td {
  border-bottom: none;
}

.table-double {
  border-left: 4px double #dee2e6;
  border-right: 4px double #dee2e6;
}
.table-double thead,
.table-double tbody,
.table-double tfoot,
.table-double tr,
.table-double td,
.table-double th {
  border-style: double;
  border-width: 4px;
  border-right: 0;
  border-left: 0;
}

.table-dotted {
  border-left: 2px dotted #dee2e6;
  border-right: 2px dotted #dee2e6;
}
.table-dotted thead,
.table-dotted tbody,
.table-dotted tfoot,
.table-dotted tr,
.table-dotted td,
.table-dotted th {
  border-style: dotted !important;
  border-width: 2px;
  border-right: 0;
  border-left: 0;
}

.table-dashed {
  border-left: 2px dashed #dee2e6;
  border-right: 2px dashed #dee2e6;
}
.table-dashed thead,
.table-dashed tbody,
.table-dashed tfoot,
.table-dashed tr,
.table-dashed td,
.table-dashed th {
  border-style: dashed !important;
  border-width: 2px;
  border-right: 0;
  border-left: 0;
}

.table-xl td,
.table-xl th {
  padding: 1.25rem 2rem;
  font-size: 120%;
}

.table-lg td,
.table-lg th {
  padding: 0.9rem 2rem;
  font-size: 110%;
}

.table-de td,
.table-de th {
  padding: 0.75rem 2rem;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem 2rem;
  font-size: 90%;
}

.table-xs th,
.table-xs td {
  padding: 0.2rem 2rem;
  font-size: 80%;
}

.table-border-horizontal tr,
.table-border-horizontal th,
.table-border-horizontal td {
  border-top: 1px solid #f2f4ff;
  padding: 0.75rem 2rem;
}

.table-border-vertical tr,
.table-border-vertical th,
.table-border-vertical td {
  border-top: 0;
  border-right: 1px solid #f2f4ff;
}
.table-border-vertical tr td {
  border-bottom: 0;
}
.table-border-vertical tr td:last-child {
  border-right: none;
}
.table-border-vertical tbody tr th {
  border-bottom: 0;
}

.table-bordernone td {
  border: none !important;
}
.table-bordernone thead th {
  border: none !important;
}
.table-bordernone .u-s-tb {
  padding: 11px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: rgba(48, 126, 243, 0.1) !important;
  --bs-table-accent-bg:unset;
}

.table-bordered thead,
.table-bordered tbody,
.table-bordered tfoot,
.table-bordered tr,
.table-bordered td,
.table-bordered th {
  border-color: rgba(153, 153, 153, 0.3);
}

.extra_data .card-body .table-responsive {
  overflow-x: hidden;
}

/**=====================
    2.30 Table CSS Ends
==========================**/
/**=====================
   2.31 Toasts CSS Start
==========================**/
.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast-index {
  z-index: 12;
}
.toast-index.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast-index.toast-fade {
  transition: opacity 0.15s linear;
}
.toast-index.show {
  display: block;
}

.toast-img img {
  width: 30px;
  height: 30px;
}

.bd-example-toasts {
  min-height: 400px;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast-content {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 65px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir=rtl] .toast-rtl .toast .toast-header strong {
  margin-right: unset !important;
  margin-left: auto !important;
}
[dir=rtl] .toast-rtl .toast .btn-close {
  margin-right: 6px;
  margin-left: unset;
}

@media (max-width: 1920px) {
  .toast-dark .btn {
    padding: calc(6px + 0 * (100vw - 320px) / 1600) calc(4px + 24 * (100vw - 320px) / 1600);
  }
}

.toast-icons {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .toast-icons {
  margin-right: unset;
  margin-left: 6px;
}

/**=====================
   2.31 Toasts CSS Ends
==========================**/
/**=====================
  2.32 Touchspin CSS Start
==========================**/
.bootstrap-touchspin input.touchspin {
  padding: 0 12px;
}
.bootstrap-touchspin .input-group .btn {
  padding: 0.5rem 1.75rem;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical i {
  position: absolute;
  top: 4px;
  left: 7px;
  font-size: 10px;
  font-weight: normal;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 9px;
  margin-left: -1px;
  position: relative;
}
.bootstrap-touchspin .input-group {
  font-family: Outfit, sans-serif;
}
.bootstrap-touchspin .input-group .btn {
  border-radius: 0 !important;
}
.bootstrap-touchspin .dropdown-basic .dropdown .dropbtn {
  padding: 9px 30px;
}
.bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
  z-index: 9999;
}

.touchspin-wrapper .btn-touchspin {
  border-radius: 5px;
  width: 32px;
  height: 32px;
}
.touchspin-wrapper input[type=number] {
  width: 85px;
  padding: 3px;
  text-align: center;
  height: 30px;
}

.hover-touchspin .btn-touchspin.spin-border-dark:hover {
  background-color: #2c323f;
  color: #fff;
}

.pre-post-touchspin .decrement-touchspin,
.pre-post-touchspin .increment-touchspin,
.pre-post-touchspin .input-group-text {
  padding: 7px 14px;
  border: 0;
}
.pre-post-touchspin .input-group-text {
  background-color: var(--light-bg);
}
.pre-post-touchspin .input-touchspin {
  border: 1px solid var(--course-light-btn);
  border-width: 2px;
  text-align: center;
}
.pre-post-touchspin .input-group {
  width: auto;
}
@media (max-width: 768px) {
  .pre-post-touchspin .input-group .input-touchspin {
    width: 100px;
  }
}
.pre-post-touchspin.bg-touchspin button[type=button] {
  border: 1px solid var(--course-light-btn);
  border-width: 2px;
  text-align: center;
}
.pre-post-touchspin .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-touchspin .btn-touchspin {
  border-radius: 50%;
}
.rounded-touchspin .touchspin-wrapper input[type=number].input-touchspin {
  border-radius: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/**=====================
    2.32 Touchspin CSS Ends
==========================**/
/**=====================
  2.33 Tour CSS Start
==========================**/
.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.introjs-tooltipReferenceLayer {
  background-color: transparent;
}

.introjs-helperNumberLayer {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #DC3545;
  background: linear-gradient(to bottom, var(--theme-default) 0%, var(--theme-default) 100%);
  border: 3px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.introjs-arrow {
  border: 12px solid #fff;
}
.introjs-arrow.top {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--theme-default);
  border-left-color: transparent;
}
.introjs-arrow.top-right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--theme-default);
  border-left-color: transparent;
}
.introjs-arrow.top-middle {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--theme-default);
  border-left-color: transparent;
}
.introjs-arrow.right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--theme-default);
}
.introjs-arrow.right-bottom {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--theme-default);
}
.introjs-arrow.bottom {
  border-top-color: var(--theme-default);
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left {
  border-top-color: transparent;
  border-right-color: var(--theme-default);
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left-bottom {
  border-top-color: transparent;
  border-right-color: var(--theme-default);
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-tooltip {
  background-color: var(--theme-default);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  border-color: var(--theme-default);
  min-width: calc(275px + 35 * (100vw - 320px) / 1600);
  max-width: 400px;
  min-height: 90px;
}

.introjs-button {
  border: 1px solid #EAEAEA;
  text-shadow: 1px 1px 0 #fff;
  color: #363636 !important;
  background-color: #fff;
  background-image: linear-gradient(#fff, #fff);
  font-size: 13px;
}
.introjs-button:hover {
  border-color: #EAEAEA;
}
.introjs-button:focus, .introjs-button:active {
  background-image: linear-gradient(#EAEAEA, #EAEAEA);
}

.introjs-skipbutton {
  color: var(--theme-default);
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #EAEAEA;
}

.introjs-disabled {
  color: var(--theme-default);
  border-color: #EAEAEA;
}
.introjs-disabled:hover, .introjs-disabled:focus {
  color: var(--theme-default);
  border-color: #EAEAEA;
}

.introjs-bullets ul li a {
  background: #EAEAEA;
}
.introjs-bullets ul li a:hover {
  background: #fff;
}
.introjs-bullets ul li a.active {
  background: #fff;
}

.introjs-progress {
  background-color: #F3F3F3;
}

.introjs-progressbar {
  background-color: var(--theme-default);
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.27);
  background-color: rgba(136, 136, 136, 0.24);
}

.introjs-hint-dot {
  border: 10px solid rgba(146, 146, 146, 0.36);
}

.flex-grow-1 .tour-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.flex-grow-1 .tour-wrapper .tour-dot {
  font-size: 5px;
}

.tour-blog {
  height: 350px;
}
@media (max-width: 992px) {
  .tour-blog {
    height: calc(200px + 150 * (100vw - 320px) / 672);
  }
}
.tour-blog img {
  height: 100%;
  object-fit: cover;
}

.info h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.info span {
  font-size: calc(13px + 1 * (100vw - 320px) / 1600);
}

.tour-email {
  display: flex;
  flex-direction: column;
}
@media (max-width: 576px) {
  .tour-email {
    align-items: center;
  }
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 26px !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-img-style h5 {
  line-height: 25px;
}

.list-inline .list-inline-item.b-r-gray {
  border-right: 1px solid #cccccc;
}

.social-tour {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 26px;
}
.social-tour .list-inline {
  display: flex;
  justify-content: center;
  gap: calc(12px + 14 * (100vw - 320px) / 1600);
}
.social-tour .list-inline .list-inline-item {
  margin-right: 0;
}
.social-tour .list-inline .list-inline-item a {
  padding: 0px;
}
.social-tour .list-inline .list-inline-item a i {
  font-size: 18px;
}
@media (max-width: 599px) {
  .social-tour .list-inline {
    display: none;
  }
}
.social-tour .float-sm-end {
  min-width: 81px;
}

.tour-mb-space {
  margin-bottom: calc(2px + 6 * (100vw - 320px) / 1600);
}

.like-comment .list-inline {
  justify-content: flex-start;
}
@media (max-width: 324px) {
  .like-comment .list-inline {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
  }
}
[dir=rtl] .like-comment .list-inline .list-inline-item {
  border-right: 0;
  border-left: 1px solid #cccccc;
}
[dir=rtl] .like-comment .list-inline .list-inline-item:last-child {
  border-right: 0;
  border-left: unset;
}

/**=====================
  2.33 Tour CSS Ends
==========================**/
/**=====================
   2.34 Tree CSS Start
==========================**/
[dir=rtl] .treejs .treejs-nodes {
  padding-left: unset;
  padding-right: 20px;
}
[dir=rtl] .treejs .treejs-node.treejs-placeholder {
  padding-right: 20px;
  padding-left: unset;
}
.treejs .treejs-node__halfchecked > .treejs-checkbox:before {
  background-color: #307EF3 !important;
  border-color: #307EF3 !important;
}
[dir=rtl] .treejs .treejs-node__halfchecked > .treejs-checkbox:after {
  right: 3px;
  left: unset;
}
.treejs .treejs-node__checked > .treejs-checkbox:before {
  background-color: #53A653 !important;
  border-color: #2c323f !important;
}
[dir=rtl] .treejs .treejs-node__checked > .treejs-checkbox:after {
  right: 5px;
  left: unset;
}

/**=====================
   2.34 Tree CSS Ends
==========================**/
/**=====================
    2.35 Typeahead-search CSS Start
==========================**/
.Typeahead {
  width: 100%;
}
.Typeahead * {
  box-sizing: border-box;
}
.Typeahead *:before {
  box-sizing: border-box;
}
.Typeahead *:after {
  box-sizing: border-box;
}
.Typeahead .tt-hint {
  display: none !important;
}

.u-cf:before {
  content: " ";
  display: table;
}
.u-cf:after {
  content: " ";
  display: table;
  clear: both;
}

.u-hidden {
  display: none !important;
}

.u-posRelative {
  position: relative;
}

.Typeahead-spinner {
  position: absolute;
  top: 14px;
  right: 18px;
  display: none;
  color: #c5c5c5;
}

.ProfileCard-avatar svg {
  height: 18px;
  vertical-align: bottom;
}
.ProfileCard-avatar svg path {
  color: #7e7c7c !important;
}
.ProfileCard-avatar svg polygon {
  color: #7e7c7c !important;
}

.Typeahead-hint {
  width: 100%;
  padding: 5px 8px;
  font-size: 24px;
  line-height: 30px;
  border: 1px solid #024e6a;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ccd6dd;
  opacity: 1;
}

.Typeahead-menu {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px;
  z-index: 100;
  display: none;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.Typeahead-menu.is-open {
  display: block;
}

.Typeahead-selectable {
  cursor: pointer;
}
.Typeahead-selectable + .Typeahead-selectable {
  border-top: 1px solid #ccd6dd;
}

.ProfileCard {
  position: relative;
  padding: 10px;
  border: 1px solid #cccccc !important;
  margin-bottom: 10px;
  border-radius: 10px;
}
.ProfileCard:hover {
  color: #414345;
  background: #f8f8f8;
}
.ProfileCard:hover .ProfileCard-avatar {
  border-color: #f5f8fa;
}
.ProfileCard:hover .ProfileCard-screenName {
  color: #fff;
}
.ProfileCard:hover .ProfileCard-stat-label {
  color: #fff;
}

.ProfileCard-avatar {
  position: absolute;
  border-radius: 5px;
}

.ProfileCard-details {
  padding-left: 35px;
  float: left;
}

.ProfileCard-realName {
  display: inline-block;
  color: #9B9B9B;
}

.ProfileCard-screenName {
  display: inline-block;
  color: #9B9B9B;
}

.ProfileCard-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
}

.ProfileCard-stats {
  float: right;
  text-align: right;
}

.ProfileCard-stat {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}
.ProfileCard-stat + .ProfileCard-stat {
  margin-left: 5px;
}

.ProfileCard-stat-label {
  color: #9B9B9B;
  font-weight: 500;
}

.ProfileCard.is-active {
  color: #fff;
  background: var(--theme-default);
}
.ProfileCard.is-active .ProfileCard-screenName {
  color: #fff;
}
.ProfileCard.is-active .ProfileCard-stat-label {
  color: #fff;
}

.EmptyMessage {
  position: relative;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

.Typeahead-spinner {
  width: 1rem;
  height: 1rem;
}

.sey-container {
  display: none;
  position: absolute;
  box-shadow: 1px 2px 6px;
  background-color: #fff;
  color: #333;
  transition: left 0.1s ease-in-out;
  z-index: 1;
}

.sey-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sey-show {
  display: block;
}

.sey-hide {
  display: none;
}

.sey-empty {
  cursor: default;
  padding: 7px;
}

.sey-item {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px;
}
.sey-item:hover {
  background-color: #444;
  color: #fff;
}

.sey-selected {
  background-color: #333;
  color: #fff;
}

.sey-char-highlight {
  font-weight: bold;
}

.sey-category-id {
  background-color: #eee;
  color: #aaa;
  text-align: right;
  text-transform: capitalize;
  font-style: italic;
  font-size: 12px;
  box-shadow: 1px 0px 1px;
  padding: 7px;
}

/**=====================
  2.35 Typeahead-search CSS Ends
==========================**/
/**=====================
    2.36 Scrollbar CSS Start
==========================**/
[data-simplebar] {
  position: unset;
}

.simplebar-mask {
  top: 100px;
}

.page-wrapper.horizontal-wrapper .simplebar-offset {
  top: 0px;
}

.simplebar-track {
  right: -2px;
}
.simplebar-track.simplebar-vertical {
  top: 100px;
  width: 10px;
}
.simplebar-track.simplebar-horizontal {
  visibility: hidden !important;
}

.simplebar-offset {
  top: -10px;
}

.sidebar-wrapper.close_icon .simplebar-offset {
  top: 0;
}

.simplebar-scrollbar:before {
  background: rgba(48, 126, 243, 0.2);
}

.scrollbar-images {
  margin-bottom: 12px;
}

.visible-wrapper img {
  width: calc(230px + 100 * (100vw - 320px) / 1600) !important;
  height: calc(230px + 100 * (100vw - 320px) / 1600);
}

.horizontal-scroll .horz-scroll-content .horizontal-img {
  height: 100%;
  max-width: 240px;
}
@media (max-width: 576px) {
  .horizontal-scroll .horz-scroll-content .horizontal-img {
    max-width: calc(220px + 20 * (100vw - 320px) / 256);
  }
}
.horizontal-scroll .horz-scroll-content .horizontal-img img {
  height: 100%;
  object-fit: cover;
}

.scroll-demo.scroll-b-none {
  padding: 0;
  border: none;
}
[dir=rtl] .scroll-demo .scroll-rtl .list-group-item span {
  margin-right: auto;
}

/**=====================
    2.36 Scrollbar CSS Ends
==========================**/
/**=====================
   3.1 Blog CSS start
==========================**/
.blog-box img {
  border-radius: 0.25rem;
}
.blog-box .top-radius-blog {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.blog-box h6 {
  font-weight: 600;
  margin: 15px 0;
}
.blog-box .blog-details {
  padding-right: 20px;
}
.blog-box .blog-details .blog-social {
  margin-top: 30px;
}
.blog-box .blog-details .blog-social li {
  display: inline-block;
  font-size: 12px;
  color: #9B9B9B;
}
.blog-box .blog-details .blog-social li + li {
  padding-left: 20px;
  line-height: 1;
  padding-right: 0;
}
.blog-box .blog-details .blog-social li i {
  padding-right: 5px;
  font-weight: 300;
}
.blog-box .blog-details .blog-social li:first-child {
  border-right: 1px solid gray;
  padding-right: 20px;
}
.blog-box .blog-details-main hr {
  background-color: #cccccc;
}
.blog-box .blog-details-main .blog-social {
  margin: 20px 0;
}
.blog-box .blog-details-main .blog-social li {
  display: inline-block;
  font-size: 14px;
  color: #9B9B9B;
  padding: 0 24px;
  border-right: 1px dotted;
}
@media (max-width: 1676px) {
  .blog-box .blog-details-main .blog-social li {
    padding: 0 10px;
  }
}
.blog-box .blog-details-main .blog-social li:last-child {
  border-right: 0;
}
.blog-box .blog-details-main .blog-social li i {
  padding-right: 5px;
}
.blog-box .blog-details-main .blog-bottom-details {
  margin: 16px;
  font-size: 14px;
  line-height: 1.714;
  font-weight: 500;
}
.blog-box .blog-date {
  color: #9B9B9B;
}
.blog-box .blog-date span {
  font-size: 36px;
  font-weight: 500;
  padding-right: 5px;
  color: #307EF3;
}

.blog-box.blog-shadow:before {
  box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  border-radius: 5px;
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  bottom: 0;
}
.blog-box.blog-shadow:hover:before {
  box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
  transition: all 0.3s ease;
}
.blog-box.blog-shadow .blog-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
}
.blog-box.blog-shadow .blog-details h4 {
  color: #fff;
  margin-bottom: 0;
}
.blog-box.blog-shadow .blog-details p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}
.blog-box.blog-shadow .blog-details .blog-social li {
  color: #fff;
}

.blog-list .blog-details .blog-social {
  margin-top: 0;
}
.blog-list .blog-details h3 {
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 10px;
}
.blog-list .blog-details hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.blog-list img {
  padding: 17px;
}

.blog-grid img {
  width: 100%;
}
.blog-grid .blog-details {
  padding: 20px;
}
.blog-grid .blog-details h6 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.8;
}

.blog-box {
  position: relative;
  max-height: 100%;
  align-items: center;
}
.blog-box .blog-details h6 {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
@media (max-width: 1570px) {
  .blog-box .blog-details p {
    -webkit-line-clamp: 2;
  }
}
.blog-box .blog-details h4 {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: 500;
}
.blog-box .blog-details .single-blog-content-top {
  margin-top: 20px;
}
.blog-box .blog-details .blog-social li,
.blog-box .blog-details .comment-social li {
  font-size: 14px;
}

.comment-box {
  padding-top: 50px;
  padding-bottom: 50px;
}
.comment-box hr {
  margin-top: 20px;
  margin-bottom: 30px;
}
.comment-box li {
  margin-bottom: 30px;
}
.comment-box li:last-child {
  margin-bottom: 0;
}
.comment-box ul ul {
  margin-left: 135px;
}
.comment-box p {
  font-size: 14px;
  line-height: 2;
  color: #9B9B9B;
  text-align: justify;
  margin-bottom: 0;
}
.comment-box h4 {
  font-weight: 600;
}
.comment-box .d-flex img {
  margin-right: 30px;
  border-radius: 10px;
  height: 90px;
  padding: 7px;
  width: 90px;
  border: 1px solid #EAEAEA;
}
.comment-box .d-flex h4 {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 16px;
}
.comment-box .d-flex h4 span {
  padding-left: 10px;
  font-size: 13px;
  color: #9B9B9B;
  font-weight: 400;
}
.comment-box .comment-social li {
  display: inline-block;
  color: #2c323f;
  padding-left: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.comment-box .comment-social li:first-child {
  border-right: 1px solid #2c323f;
  padding-right: 20px;
}
.comment-box .comment-social li i {
  padding-right: 10px;
}

/**=====================
   3.1 Blog CSS ends
==========================**/
/**=====================
    3.2 Bookmark-app CSS Start
==========================**/
.modal-bookmark .modal-body,
.modal-bookmark .modal-header {
  padding: 30px;
}

.bookmark-wrap .details-bookmark .row > div + div {
  padding-right: 15px !important;
}
.bookmark-wrap .left-bookmark {
  height: 700px;
}
.bookmark-wrap .left-bookmark ul li button {
  border: none;
  cursor: pointer;
}
.bookmark-wrap .left-bookmark ul li button:focus {
  outline: none;
}
.bookmark-wrap .left-bookmark ul li svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.bookmark-wrap .left-bookmark ul li > .main-title {
  color: var(--theme-default);
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  font-size: 16px;
}
.bookmark-wrap .left-bookmark ul li > .main-title span a:hover {
  background-color: transparent;
}
.bookmark-wrap .left-bookmark ul li > .main-title svg {
  color: #9B9B9B;
  stroke-width: 1px;
}
.bookmark-wrap .left-bookmark ul li a > .title {
  text-transform: capitalize;
}
.bookmark-wrap.todo-wrap .left-bookmark {
  height: unset;
}

.form-bookmark a {
  text-transform: capitalize !important;
  color: var(--theme-default) !important;
  line-height: 1 !important;
  font-weight: 500;
}
.form-bookmark a:hover {
  background-color: transparent !important;
}
.form-bookmark input#index_var {
  display: none;
}
.form-bookmark input,
.form-bookmark select {
  font-size: 14px;
}
.form-bookmark .select2 {
  width: 100% !important;
}
.form-bookmark .error {
  margin: 0 !important;
  font-size: 11px;
  color: red !important;
}
.form-bookmark button {
  margin-top: 10px;
}

@keyframes displayTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bookmark-tabcontent .details-bookmark {
  height: auto;
  min-height: 359px;
}
.bookmark-tabcontent .details-bookmark .bookmark-card {
  position: relative;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  padding: 3px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon i {
  color: #fff;
  font-size: 16px;
  vertical-align: text-bottom;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon.favourite i {
  color: #fed112;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data {
  width: 100%;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data .content-general {
  display: none;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  transition: 0.5s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: left;
  padding: 15px;
  border-top: 1px solid #F3F3F3;
  position: relative;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark h3 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark p {
  animation: displayTransition 1s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block {
  opacity: 0;
  position: absolute;
  width: 100%;
  padding: 15px;
  top: 30px;
  left: 0;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li {
  display: inline-block;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li + li {
  margin-left: 5px;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li svg {
  width: 16px;
  height: 16px;
  color: #9B9B9B;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover {
  box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
  transition: 0.5s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .title-bookmark p {
  opacity: 0;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .title-bookmark .hover-block {
  opacity: 1;
  animation: displayTransition 2s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .favourite-icon {
  opacity: 1;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .row > div {
  flex: 0 0 100%;
  max-width: 100%;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .desciption-data .content-general {
  display: block;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website {
  display: flex;
  align-items: center;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
  opacity: 1;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
  position: absolute;
  right: 30px;
  opacity: 1;
  text-align: right;
}
[dir=rtl] .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
  text-align: left;
  right: unset;
  left: 30px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block {
  opacity: 1;
  animation: displayTransition 0s;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark {
  border-top: none;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark p,
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6 {
  margin-top: 0;
  display: inline-block;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6 {
  position: relative;
  padding-right: 15px;
  margin-bottom: -5px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6::after {
  position: absolute;
  content: "-";
  right: 0;
  color: #9B9B9B;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general {
  margin-top: 5px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general p {
  width: 68%;
  margin-right: 20px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
  width: 20%;
}
.bookmark-tabcontent .tab-content .card .d-flex {
  justify-content: space-between;
}
.bookmark-tabcontent .tab-content .card .d-flex svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.bookmark-tabcontent .tab-content .card .d-flex ul li {
  display: inline-block;
}
.bookmark-tabcontent .tab-content .card .d-flex ul li + li {
  margin-left: 5px;
}
.bookmark-tabcontent .tab-content .tab-pane > .card {
  box-shadow: none;
}

@media only screen and (max-width: 1660px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general p {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .hover-block {
    margin-top: 6px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .bookmark-wrap .left-bookmark ul li .btn-block svg {
    display: none;
  }
}
@media only screen and (max-width: 1366px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 40%;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .hover-block {
    top: 80px;
  }
}
@media only screen and (max-width: 1199px) {
  .bookmark-wrap .left-bookmark {
    height: auto;
  }
  .bookmark-tabcontent .details-bookmark {
    min-height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 172px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block p {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block ul {
    position: relative;
    right: unset;
    top: unset;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 45%;
  }
}
@media only screen and (max-width: 575px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 100%;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website {
    display: block;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark {
    padding-left: 15px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
    position: relative;
    right: unset;
    text-align: left;
    top: unset;
    padding: 0;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .content-general {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p,
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark h6 {
    display: block;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark h6::after {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
    margin-top: 15px;
  }
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 15px;
  }
}
/**=====================
  3.2 Bookmark-app CSS Ends
==========================**/
/**=====================
  3.14 Add-product CSS Start
==========================**/
.sidebar-left-wrapper {
  border-right: 1px solid var(--recent-dashed-border);
}
@media (max-width: 1199px) {
  .sidebar-left-wrapper {
    border-right: unset;
  }
}
[dir=rtl] .sidebar-left-wrapper {
  border-right: unset;
  border-left: 1px solid var(--recent-dashed-border);
}
.sidebar-left-wrapper .sidebar-left-icons {
  display: block;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item {
  position: relative;
  padding-bottom: 50px;
}
@media (max-width: 1199px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item {
    padding-bottom: 25px;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:last-child {
  padding-bottom: 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item::before {
  content: "";
  position: absolute;
  border: 1px dashed var(--recent-dashed-border);
  top: 60px;
  height: 50px;
  left: 20px;
}
[dir=rtl] .sidebar-left-wrapper .sidebar-left-icons .nav-item::before {
  left: unset;
  right: 20px;
}
@media (max-width: 1199px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item::before {
    height: 25px;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:last-child::before {
  content: none;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active {
  background-color: unset;
  color: unset;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .nav-rounded, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .nav-rounded {
  border: 1px solid var(--theme-default);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .nav-rounded .product-icons, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .nav-rounded .product-icons {
  background-color: var(--theme-default) !important;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .nav-rounded .product-icons svg, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .nav-rounded .product-icons svg {
  stroke: var(--white);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .product-tab-content h6, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .product-tab-content h6 {
  color: var(--theme-default);
  font-weight: 500;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded {
  min-width: 42px;
  height: 42px;
  border: 1px solid var(--recent-dashed-border);
  position: relative;
  z-index: 2;
  border-radius: 100%;
  padding: 2px;
  box-shadow: inset 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded .product-icons {
  min-width: 100%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded .product-icons svg {
  width: 18px;
  height: 18px;
  stroke: var(--chart-text-color);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content h6 {
  color: var(--body-font-color);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}
@media (max-width: 575px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content h6 {
    text-align: left;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content p {
  color: var(--chart-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}
@media (max-width: 575px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content p {
    text-align: left;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .nav-rounded {
  border: 1px solid var(--theme-default);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .nav-rounded .product-icons {
  background-color: var(--light-shade-primary, 1);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .nav-rounded .product-icons svg {
  stroke: var(--theme-default);
  animation: tada 1.5s ease infinite;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .product-tab-content h6 {
  color: var(--theme-default);
}

.sidebar-body form .form-control:focus,
.sidebar-body form .form-select:focus {
  box-shadow: unset;
  border-color: unset;
}
[dir=rtl] .sidebar-body .ql-snow .ql-picker-label {
  padding-right: 8px;
  padding-left: 2px;
}
@media (max-width: 575px) {
  .sidebar-body .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
  }
}
[dir=rtl] .sidebar-body .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: unset;
  left: 0;
}
[dir=rtl] .sidebar-body .form-select {
  padding: 6px 12px 6px 36px !important;
}
.sidebar-body .ql-formats {
  border-right: 1px solid var(--recent-dashed-border);
}
[dir=rtl] .sidebar-body .ql-formats {
  border-left: 1px solid var(--recent-dashed-border);
  border-right: unset;
}
@media (max-width: 575px) {
  .sidebar-body .ql-formats {
    border-right: unset;
  }
  [dir=rtl] .sidebar-body .ql-formats {
    border-left: unset;
  }
}
.sidebar-body .ql-formats:last-of-type {
  border-right: unset;
}
[dir=rtl] .sidebar-body .ql-formats:last-of-type {
  border-left: unset;
}
.sidebar-body #editor2 {
  min-height: 250px;
}

.sidebar-body input[type=number]::-webkit-inner-spin-button,
.sidebar-body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button;
}
.sidebar-body .form-select,
.sidebar-body .form-control {
  font-size: 15px;
  color: #4c5667;
}
.sidebar-body .product-upload p {
  margin-bottom: 18px;
  line-height: 0;
}
.sidebar-body .product-upload:nth-child(1) {
  margin-bottom: 30px;
}
@media (max-width: 400px) {
  .sidebar-body .product-upload:nth-child(1) {
    margin-bottom: 26px;
  }
}
.sidebar-body .product-upload:nth-child(2) {
  margin-bottom: 65px;
}
@media (max-width: 1199px) {
  .sidebar-body .product-upload:nth-child(2) {
    margin-bottom: 0;
  }
}
.sidebar-body .product-upload form svg {
  width: 48px;
  height: 48px;
}
.sidebar-body .product-upload form h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
}
.sidebar-body .product-upload form span {
  color: var(--chart-text-color);
  font-size: 14px;
}
.sidebar-body .product-upload .dropzone {
  background: rgba(48, 126, 243, 0.1);
}

.product-buttons {
  margin: 15px 0 0 0;
  text-align: end;
  border-top: 1px solid var(--recent-dashed-border);
  padding: 12px 0 0 0;
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  left: 50%;
  transform: translateX(-50%);
}
[dir=rtl] .product-buttons {
  left: unset;
  right: 50%;
  transform: translateX(50%);
}
@media (max-width: 1199px) {
  .product-buttons {
    position: unset;
    width: unset;
    transform: unset;
  }
  [dir=rtl] .product-buttons {
    transform: unset;
  }
}
.product-buttons svg {
  width: 18px;
  height: 18px;
  stroke: var(--chart-text-color);
}
@media (max-width: 400px) {
  .product-buttons svg {
    width: 15px;
    height: 15px;
  }
}
.product-buttons .btn {
  border: 1px solid var(--recent-dashed-border);
  color: var(--chart-text-color);
  font-weight: 500;
}
@media (max-width: 1400px) {
  .product-buttons .btn {
    padding: 6px 16px;
  }
}
@media (max-width: 400px) {
  .product-buttons .btn {
    padding: 6px 8px;
  }
}
.product-buttons .btn:hover, .product-buttons .btn:active {
  background-color: var(--theme-default);
  color: #fdfeff;
}
.product-buttons .btn:hover svg, .product-buttons .btn:active svg {
  stroke: #fdfeff;
}

.category-button {
  padding-bottom: 30px;
  border-bottom: 1px dashed var(--recent-dashed-border);
}
@media (max-width: 575px) {
  .category-button {
    padding-bottom: 25px;
  }
}
.category-button a {
  padding: 12px;
}

.create-category .toolbar-box {
  margin-top: 12px;
}

.product-date .form-control[readonly] {
  background-color: var(--white);
}

.price-wrapper .radio-wrapper {
  justify-content: flex-start;
  margin-top: 4px;
}
.price-wrapper .radio-wrapper li {
  max-width: 200px;
}
.price-wrapper .radio-wrapper li .form-check-input {
  border-radius: 5px;
  background-color: var(--light-background);
}
.price-wrapper .radio-wrapper li .form-check-input ~ .form-check-label span {
  color: #4c5667;
  font-size: 15px;
  position: relative;
}
.price-wrapper .radio-wrapper li .form-check-input:checked {
  background-color: var(--light-background);
  border: 1px solid var(--theme-default);
  border-radius: 5px;
}
.price-wrapper .radio-wrapper li .form-check-input:checked ~ .form-check-label span {
  color: var(--theme-default);
  position: relative;
}

.advance-options ul {
  border-bottom: 1px solid var(--recent-dashed-border);
}
.advance-options ul.border-tab.nav-tabs .nav-item .nav-link {
  color: var(--body-font-color);
  border-bottom: 2px solid transparent;
  transition: all 0.3S ease-in-out;
}
.advance-options ul.border-tab.nav-tabs .nav-item .nav-link:active, .advance-options ul.border-tab.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid var(--theme-default);
  color: var(--body-font-color);
  transition: all 0.3S ease-in-out;
}

.meta-body {
  padding-top: 16px;
}
.meta-body .custom-input .form-check .form-check-input:checked {
  background-color: var(--theme-default);
}
.meta-body .custom-input .form-check .form-check-input:valid {
  border-color: var(--theme-default);
}
.meta-body .custom-input .form-check .form-check-input:valid ~ .form-check-label {
  color: rgba(155, 155, 155, 0.8);
}

.product-tag .tagify {
  border-radius: 4px;
  padding: 2px;
}

[dir=rtl] .tagify__tag {
  margin-right: 5px;
  margin-left: 0px;
}

[dir=rtl] .tagify__tag__removeBtn {
  margin-right: auto;
  margin-left: 4.6666666667px;
}

.list-product-header > div {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  margin-bottom: 14px;
}
.list-product-header .show {
  display: block;
}
.list-product-header .hide {
  display: none;
}
.list-product-header .collapse {
  line-height: 0.2;
}
.list-product-header .light-box {
  width: 39px;
  height: 39px;
  border-radius: 5px;
  background-color: var(--light-shade-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
}
.list-product-header .light-box svg {
  width: 17px;
  height: 17px;
}
.list-product-header a.btn {
  float: right;
}
[dir=rtl] .list-product-header a.btn {
  float: left;
}
.list-product-header a.btn i {
  padding-right: 8px;
}
[dir=rtl] .list-product-header a.btn i {
  padding-right: unset;
  padding-left: 8px;
}
@media (max-width: 575px) {
  .list-product-header a.btn {
    float: unset;
  }
}
.list-product-header .list-product-body {
  margin-top: 20px;
  padding: 0px;
  box-shadow: unset;
  border: none;
}
.list-product-header .list-product-body .form-select {
  font-size: 14px;
  border-color: var(--recent-dashed-border);
  color: var(--text-gray);
}

.list-product.list-category tr th:nth-child(2),
.list-product.list-category tr td:nth-child(2) {
  min-width: 60px;
}
.list-product.list-category tr th:nth-child(3),
.list-product.list-category tr td:nth-child(3) {
  min-width: 500px;
}
.list-product.list-category tr th:nth-child(4),
.list-product.list-category tr td:nth-child(4) {
  min-width: 150px;
}
.list-product.list-category tr th:nth-child(5),
.list-product.list-category tr td:nth-child(5) {
  min-width: 100px;
}
.list-product thead tr th:first-child .form-check {
  display: none;
}
.list-product .datatable-container {
  overflow-x: auto;
}
.list-product .datatable-dropdown {
  position: relative;
}
.list-product .datatable-dropdown label {
  color: var(--body-font-color);
}
.list-product .datatable-dropdown label:before {
  content: "\f107";
  position: absolute;
  top: 6px;
  left: 38px;
  font-family: "FontAwesome";
  font-size: 16px;
  margin: 0;
  color: var(--text-gray);
}
[dir=rtl] .list-product .datatable-dropdown label:before {
  right: 38px;
  left: unset;
}
.list-product .datatable-selector {
  appearance: none;
  min-width: 55px;
}
.list-product .datatable-selector:focus, .list-product .datatable-selector:focus-visible {
  outline: none;
}
[dir=rtl] .list-product .datatable-sorter::before, [dir=rtl] .list-product .datatable-sorter::after {
  right: unset;
  left: 4px;
}
.list-product tr th,
.list-product tr td {
  text-align: left;
}
[dir=rtl] .list-product tr th,
[dir=rtl] .list-product tr td {
  text-align: right;
}
.list-product tr th:nth-child(1) .datatable-sorter::before, .list-product tr th:nth-child(1) .datatable-sorter::after {
  border: none;
}
.list-product tr th:nth-child(1),
.list-product tr td:nth-child(1) {
  min-width: 20px;
}
.list-product tr th:nth-child(2),
.list-product tr td:nth-child(2) {
  min-width: 110px;
}
@media (max-width: 1460px) {
  .list-product tr th:nth-child(2),
  .list-product tr td:nth-child(2) {
    min-width: 230px;
  }
}
.list-product tr th:nth-child(3),
.list-product tr td:nth-child(3) {
  min-width: 90px;
}
.list-product tr th:nth-child(4),
.list-product tr td:nth-child(4) {
  min-width: 120px;
}
.list-product tr th:nth-child(5),
.list-product tr td:nth-child(5) {
  min-width: 120px;
}
.list-product tr th:nth-child(6),
.list-product tr td:nth-child(6) {
  min-width: 80px;
}
.list-product tr th:nth-child(7),
.list-product tr td:nth-child(7) {
  min-width: 100px;
}
.list-product tr th:nth-child(8),
.list-product tr td:nth-child(8) {
  min-width: 140px;
}
.list-product tr th:nth-child(9),
.list-product tr td:nth-child(9) {
  min-width: 105px;
}
.list-product:focus-visible {
  outline: 0;
}
.list-product .datatable-top {
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
}
.list-product .datatable-search {
  float: left;
}
[dir=rtl] .list-product .datatable-search {
  float: right;
}
.list-product .datatable-container:focus-visible {
  outline: 0;
}
.list-product .datatable-bottom .datatable-info {
  color: var(--body-font-color);
}
.list-product .starbox {
  cursor: pointer;
}
.list-product .starbox svg {
  width: 16px;
  height: 16px;
  fill: var(--chart-text-color);
}
.list-product .datatable-top {
  padding: 0;
}
.list-product .datatable-pagination li.datatable-hidden {
  visibility: visible;
}
.list-product .datatable-pagination li.datatable-hidden a {
  color: var(--theme-default);
  font-weight: 500;
  opacity: 1;
}
.list-product .datatable-pagination .datatable-active .datatable-pagination-list-item-link {
  background-color: var(--theme-default);
  color: var(--white);
}
.list-product .datatable-pagination .datatable-pagination-list-item-link {
  background-color: rgba(var(--light-shade-primary), 0.02);
  border-radius: 5px;
}

.table#project-status tbody tr td {
  vertical-align: inherit;
}

.product-names {
  display: flex;
  align-items: center;
  gap: 15px;
}
.product-names .light-product-box {
  min-width: 49px;
  height: 49px;
  border-radius: 3px;
  background-color: var(--chart-progress-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-names .light-product-box img {
  height: 26px;
}

.product-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.product-action svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  fill: var(--text-gray);
}

.datatable-input,
.datatable-selector {
  border: 1px solid var(--recent-dashed-border);
  border-radius: 5px;
  background: unset;
}

/**=====================
  3.14 Add-product CSS Start
==========================**/
/**=====================
    3.3 Cart CSS start
==========================**/
.cart .total-amount {
  text-align: right !important;
  margin-top: 30px;
}
.cart .amount-space {
  margin-left: 40px;
}
.cart .cart-btn-transform {
  text-transform: capitalize;
}
.cart .qty-box {
  width: 25%;
  margin: 0 auto;
}
.cart .qty-box .input-group .btn {
  background-color: #ddd !important;
  border-color: #ddd !important;
  padding: 8px 12px;
}

.cart-buttons {
  margin-top: 30px;
}
.cart-buttons .cart-btn {
  float: right;
  padding-right: 85px;
}

/**=====================
    3.3 Cart CSS Ends
==========================**/
/**=====================
   3.5 Chat CSS Start
==========================**/
.radial-bar.radial-bar-lg > img, .radial-bar.radial-bar-lg:after {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.radial-bar.radial-bar-sm > img, .radial-bar.radial-bar-sm:after {
  width: 35px;
  height: 35px;
  margin-left: 7.5px;
  margin-top: 7.5px;
  line-height: 35px;
}

.radial-bar.radial-bar-xs > img, .radial-bar.radial-bar-xs:after {
  width: 20px;
  height: 20px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 21px;
}

.chart-block canvas {
  width: 100% !important;
}
.chart-block .chart-overflow {
  overflow: hidden;
  text-align: center;
}

.candidcahrt {
  width: 100%;
  height: 470px;
}

.amcharts-export-canvas {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.amcharts-export-canvas .canvas-container {
  transition: opacity 0.5s ease-out;
}
.amcharts-export-canvas.active {
  display: block;
}
.amcharts-export-canvas.dropbox .canvas-container {
  opacity: 0.5;
}

.flot-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 400px;
}
.flot-chart-container #toggling-series-flot {
  width: 79% !important;
}

.all-chart .flot-chart-container {
  height: 350px;
}

.flot-chart-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center;
}
.flot-chart-placeholder .legend table {
  border-spacing: 5px;
}

.morris-hover {
  position: absolute;
  z-index: 1;
}
.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #9B9B9B;
  background: #fff;
  border: solid 1px #EAEAEA;
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgba(49, 49, 49, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  color: #fff;
  font: 10px Outfit, sans-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid rgba(49, 49, 49, 0.75);
  box-sizing: content-box;
  z-index: 10000;
  border-radius: 5px;
}
.chart-sparkline .jqsfield {
  color: #fff;
  font: 10px Outfit, sans-serif;
  text-align: left;
}

.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box;
}
.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  content: attr(data-label);
  background-color: #fff;
  z-index: 1;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}
.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  z-index: 3;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #EAEAEA 50%, transparent 50%, transparent), linear-gradient(162deg, #DC3545 50%, #EAEAEA 50%, #EAEAEA);
}

.radial-bar.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}
.radial-bar.radial-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}
.radial-bar.radial-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}
.pieLabel div {
  color: #fff !important;
}
.pieLabel div input[type=checkbox] {
  margin: 7px;
}

.knob-chart {
  min-height: 255px;
}
.knob-chart .chart-clock-main {
  position: relative;
  width: 200px;
  margin: 45px auto;
}
.knob-chart .chart-clock-main .clock-large {
  position: absolute;
  left: -48px;
  top: -45px;
}
.knob-chart .chart-clock-main .clock-medium {
  position: absolute;
  left: 2px;
  top: 5px;
}
.knob-chart .chart-clock-main .clock-small {
  position: absolute;
  left: 15px;
  top: 55px;
}

.peity-charts svg {
  width: 100%;
  height: 250px;
}
.peity-charts.peity-chart-primary svg polygon {
  fill: rgba(48, 126, 243, 0.2);
}
.peity-charts.peity-chart-primary svg polyline {
  stroke: var(--theme-default);
}

.bar-odd-even svg rect:nth-child(odd) {
  fill: var(--theme-default);
}
.bar-odd-even svg rect:nth-child(even) {
  fill: var(--theme-secondary);
}

.donut-peity svg path:nth-child(odd) {
  fill: var(--theme-default);
}
.donut-peity svg path:nth-child(even) {
  fill: var(--theme-secondary);
}

.small-donut ~ svg path:nth-child(odd),
.small-donut svg path:nth-child(odd),
.donut-peity2 ~ svg path:nth-child(odd),
.donut-peity2 svg path:nth-child(odd),
.pie-peity ~ svg path:nth-child(odd),
.pie-peity svg path:nth-child(odd) {
  fill: var(--theme-default);
}
.small-donut ~ svg path:nth-child(even),
.small-donut svg path:nth-child(even),
.donut-peity2 ~ svg path:nth-child(even),
.donut-peity2 svg path:nth-child(even),
.pie-peity ~ svg path:nth-child(even),
.pie-peity svg path:nth-child(even) {
  fill: #EAEAEA;
}

.small-donut2 ~ svg path:nth-child(odd) {
  fill: var(--theme-secondary);
}

.small-donut3 ~ svg path:nth-child(odd) {
  fill: #53A653;
}

.small-donut4 ~ svg path:nth-child(odd) {
  fill: #01A1B9;
}

.small-donut5 ~ svg path:nth-child(odd) {
  fill: #FFAA05;
}

.small-donut6 ~ svg path:nth-child(odd) {
  fill: #DC3545;
}

p.data-attributes svg {
  margin-right: 10px;
}

.knob-block {
  position: relative;
}
.knob-block input {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  margin-left: 0 !important;
  margin-top: -3px !important;
}

.knob-input-disable input {
  display: none !important;
}

.apex-chart {
  margin: 0 auto;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}

.apexcharts-menu-item {
  width: 110px;
}

.word-tree {
  height: 400px;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
  fill: var(--theme-default);
}
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: var(--theme-default);
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: var(--theme-secondary);
}
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
  fill: var(--theme-secondary);
}

.apache-cotainer {
  width: 100%;
  height: 350px;
  margin: 0;
}

.boxplot-cotainer {
  height: 420px;
}

.apache-cotainer-large {
  width: 100%;
  height: 500px;
  margin: 0;
}

.test-buttons button + button {
  margin-left: 10px;
}

.clip-chart-wrapper .test-chart-block {
  margin-bottom: -30px;
}

#echart-gauge .test-chart {
  width: 100%;
  height: 395px;
}

.polar-line {
  height: 350px;
}

#dotted {
  height: 390px;
}

.apexcharts-canvas .apexcharts-tooltip,
.apexcharts-canvas .apexcharts-xaxistooltip,
.apexcharts-canvas .apexcharts-yaxistooltip {
  z-index: 1;
}

@media (max-width: 1346px) {
  .flot-chart-container .flot-chart-placeholder ~ p.float-end {
    float: left !important;
  }
  [dir=rtl] .flot-chart-container .flot-chart-placeholder ~ p.float-end {
    float: right !important;
  }
}

/**=====================
    3.5 Chat CSS End
==========================**/
/**=====================
     14. Chat CSS Start
==========================**/
.status-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 44px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.user-image {
  height: 52px;
  margin-right: 5px;
}
.user-image .about {
  float: left;
  margin-top: 5px;
  padding-left: 10px;
}
.user-image .about .name {
  color: #2c323f;
  letter-spacing: 1px;
  font-weight: 500;
}

.left-sidebar-wrapper {
  padding: 20px;
  border-radius: 10px 0 0 10px;
  z-index: 1;
  border: 2px solid var(--white);
  border-right: unset;
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  /* Chrome, Edge, and Safari */
}
[dir=rtl] .left-sidebar-wrapper {
  border-left: unset;
}
@media (max-width: 767px) {
  .left-sidebar-wrapper {
    border-radius: 10px;
  }
}
.left-sidebar-wrapper * {
  scrollbar-width: auto;
  scrollbar-color: #e0ddff #ffffff;
}
.left-sidebar-wrapper *::-webkit-scrollbar {
  width: 6px;
}
.left-sidebar-wrapper *::-webkit-scrollbar-track {
  background: transparent;
}
.left-sidebar-wrapper *::-webkit-scrollbar-thumb {
  background-color: rgba(48, 126, 243, 0.2);
  border-radius: 10px;
  border: 3px solid transparent;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .input-group-text {
  background-color: var(--chart-progress-light);
  padding-right: 0;
  margin-right: 1px;
  border-right: none;
}
[dir=rtl] .left-sidebar-wrapper .left-sidebar-chat .input-group .input-group-text {
  padding-left: 0;
  padding-right: 12px;
  margin-left: 1px;
  border-left: none;
  margin-right: unset;
  border-right: unset;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .input-group-text svg {
  width: 16px;
  height: 16px;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .form-control {
  border-left: unset;
  background-color: var(--chart-progress-light);
}
[dir=rtl] .left-sidebar-wrapper .left-sidebar-chat .input-group .form-control {
  border-right: unset;
  border-left: 1px solid #ddd;
  border-radius: 0.25rem !important;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .form-control:focus, .left-sidebar-wrapper .left-sidebar-chat .input-group .form-control:focus-visible {
  border-color: var(--chart-dashed-border);
  box-shadow: unset;
}
.left-sidebar-wrapper .advance-options ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
}
.left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
  color: var(--body-font-color);
  border-bottom: 2px solid transparent;
  background-color: transparent;
  padding: 16px 57px;
  text-decoration: unset;
}
@media (max-width: 1855px) {
  .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
    padding: 16px 45px;
  }
}
@media (max-width: 1663px) {
  .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
    padding: 16px 30px;
  }
}
@media (max-width: 1423px) {
  .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
    padding: 16px 28px;
  }
}
.left-sidebar-wrapper .advance-options ul .nav-item .nav-link:active, .left-sidebar-wrapper .advance-options ul .nav-item .nav-link.active {
  border-bottom: 2px solid var(--theme-default);
  color: var(--theme-default);
}
.left-sidebar-wrapper .advance-options .common-space {
  padding: 11px 0;
}
.left-sidebar-wrapper .advance-options .common-space p {
  margin: 0;
  color: var(--chart-text-color);
}
.left-sidebar-wrapper .advance-options .common-space .header-top .btn {
  padding: 6px 12px;
}
.left-sidebar-wrapper .advance-options .common-space .header-top .btn:hover i {
  color: var(--white);
}
.left-sidebar-wrapper .advance-options .chats-user {
  border-bottom: unset;
  height: 559px;
  overflow-y: auto;
}
.left-sidebar-wrapper .advance-options .chats-user li {
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid var(--chart-dashed-border);
  cursor: pointer;
  padding: 11px;
}
.left-sidebar-wrapper .advance-options .chats-user li:hover {
  background-color: var(--light-slate);
}
@media (max-width: 1505px) {
  .left-sidebar-wrapper .advance-options .chats-user li {
    flex-wrap: wrap;
    gap: 1px;
  }
}
.left-sidebar-wrapper .advance-options .chats-user li:last-child {
  border-bottom: unset;
}
.left-sidebar-wrapper .advance-options .chats-user li .chat-time {
  display: flex;
  gap: 10px;
  align-items: center;
}
.left-sidebar-wrapper .advance-options .chats-user li .chat-time > div {
  text-align: left;
}
[dir=rtl] .left-sidebar-wrapper .advance-options .chats-user li .chat-time > div {
  text-align: right;
}
@media (max-width: 1498px) {
  .left-sidebar-wrapper .advance-options .chats-user li > div {
    width: 100%;
    text-align: end;
  }
  [dir=rtl] .left-sidebar-wrapper .advance-options .chats-user li > div {
    text-align: start;
  }
}

.active-profile {
  position: relative;
}
.active-profile img {
  width: 42px;
  height: 42px;
}
@media (max-width: 1650px) {
  .active-profile img {
    width: 33px;
    height: 33px;
  }
}
.active-profile .status {
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  bottom: 5%;
  right: -2px;
  border: 3px solid var(--white);
}
[dir=rtl] .active-profile .status {
  left: -2px;
  right: unset;
}
@media (max-width: 1600px) {
  .active-profile .status {
    width: 11px;
    height: 11px;
    border: 2px solid var(--white);
  }
}

.contact-edit i {
  color: var(--chart-text-color);
  cursor: pointer;
}
.contact-edit i::after {
  content: unset;
}
.contact-edit svg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
@media (max-width: 360px) {
  .contact-edit svg {
    width: 14px;
    height: 14px;
  }
}
.contact-edit .dropdown-menu .dropdown-item:hover {
  background-color: var(--light-background);
  opacity: 1;
  text-decoration: unset;
}
.contact-edit.chat-alert {
  width: 40px;
  height: 40px;
  background: var(--light-slate);
  display: fke;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
@media (max-width: 360px) {
  .contact-edit.chat-alert {
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 340px) {
  .contact-edit.chat-alert {
    display: none;
  }
}

.search-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.search-contacts .form-control {
  padding-inline: 35px;
}
.search-contacts .form-control:focus {
  box-shadow: unset;
  border-color: var(--chart-dashed-border);
}
.search-contacts svg {
  stroke: var(--chart-text-color);
  width: 18px;
  height: 18px;
  left: 10px;
  top: 10px;
  position: absolute;
}
[dir=rtl] .search-contacts svg {
  left: unset;
  right: 10px;
}
.search-contacts .mic-search {
  right: 10px;
  top: 8px;
  position: absolute;
  left: unset;
}
[dir=rtl] .search-contacts .mic-search {
  right: unset;
  left: 10px;
}

.contact-wrapper {
  padding-top: 14px;
  border-bottom: unset;
  height: 526px;
  overflow-y: auto;
}
.contact-wrapper > p {
  line-height: 0;
  margin: 0;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--body-font-color);
}
.contact-wrapper ul li {
  width: 100%;
  cursor: pointer;
}
.contact-wrapper ul li .chat-time {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contact-wrapper ul li .chat-time img {
  width: 42px;
  height: 42px;
}
@media (max-width: 1650px) {
  .contact-wrapper ul li .chat-time img {
    width: 33px;
    height: 33px;
  }
}
.contact-wrapper ul li .chat-time > div span {
  color: var(--body-font-color);
}
.contact-wrapper ul li svg {
  width: 18px;
  height: 18px;
}
.contact-wrapper ul:last-child .common-space {
  padding-bottom: unset;
}

.right-sidebar-chat {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 0;
  border: 2px solid var(--white);
  border-left: unset;
}
[dir=rtl] .right-sidebar-chat {
  border-right: unset;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 767px) {
  .right-sidebar-chat {
    border-radius: 10px;
  }
}

.right-sidebar-title {
  padding: 13px 25px;
  display: flex;
  justify-content: space-between;
}
.right-sidebar-title .common-space {
  width: 100%;
}
.right-sidebar-title .common-space .chat-time {
  display: flex;
  gap: 10px;
  align-items: center;
}
.right-sidebar-title .common-space .chat-time .active-profile img {
  width: 42px;
  height: 42px;
}
@media (max-width: 360px) {
  .right-sidebar-title .common-space .chat-time .active-profile img {
    width: 36px;
    height: 36px;
  }
}
.right-sidebar-title .common-space .chat-time div p {
  margin: 0;
  color: var(--chart-text-color);
}
.right-sidebar-title .common-space .chat-time ul li img {
  width: 42px;
  height: 42px;
}
.right-sidebar-title .common-space .chat-time.group-chat ul {
  display: flex;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li img {
  border: 3px solid transparent;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li + li {
  margin-left: -12px;
}
[dir=rtl] .right-sidebar-title .common-space .chat-time.group-chat ul li + li {
  margin-left: unset;
  margin-right: -12px;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li + li img {
  border: 3px solid var(--white);
}
.right-sidebar-title .common-space .chat-time.group-chat ul li img {
  width: 38px;
  height: 38px;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li .profile-count {
  width: 38px;
  height: 38px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(2), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(3), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(5), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(6) {
    display: none;
  }
}
@media (min-width: 341px) and (max-width: 410px) {
  .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(2), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(3), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(5), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(6) {
    display: none;
  }
}
@media (max-width: 1045px) {
  .right-sidebar-title .common-space .chat-time.group-chat > div span,
  .right-sidebar-title .common-space .chat-time.group-chat > div p {
    display: none;
  }
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background: linear-gradient(0deg, #F5F6FA, #F5F6FA), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  height: 620px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: rgba(48, 126, 243, 0.2);
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
[dir=rtl] .msg-img {
  margin-left: 10px;
  margin-right: unset;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
[dir=rtl] .msg-info-name {
  margin-left: 10px;
  margin-right: unset;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-img {
  background-image: url(../images/blog/4.jpg);
  width: 33px;
  height: 33px;
}
@media (max-width: 1200px) {
  .left-msg .msg-img {
    min-width: 16px;
    min-height: 16px;
  }
}
.left-msg .msg-bubble {
  position: relative;
  border-radius: 15px;
  background: var(--white);
  color: var(--chart-text-color);
  width: calc(100% - 33px);
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  color: var(--white);
  background: var(--theme-default);
  width: calc(100% - 33px);
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
  background-image: url(../images/blog/comment.jpg);
  width: 33px;
  height: 33px;
}
[dir=rtl] .right-msg .msg-img {
  margin: 0 10px 0 0px;
}
@media (max-width: 1200px) {
  .right-msg .msg-img {
    min-width: 16px;
    min-height: 16px;
  }
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: 1px solid var(--chart-dashed-border);
  background: linear-gradient(0deg, #F5F6FA, #F5F6FA), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  align-items: center;
}
.msger-inputarea input {
  width: 100px;
}
.msger-inputarea .dropdown-toggle::after {
  content: unset;
}
.msger-inputarea .dropdown-toggle .dropdown-menu {
  inset: unset !important;
}
.msger-inputarea .dropdown-form {
  position: relative;
}
.msger-inputarea .dropdown-form i {
  font-size: 16px;
  font-weight: 900;
  color: var(--theme-default);
  cursor: pointer;
  padding: 0;
}
.msger-inputarea .dropdown-form .chat-icon {
  position: absolute;
  left: 0 !important;
  bottom: 22px !important;
  background: transparent;
  box-shadow: unset;
}
[dir=rtl] .msger-inputarea .dropdown-form .chat-icon {
  left: unset;
  right: 0;
}
.msger-inputarea .dropdown-form .chat-icon div {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  opacity: 1;
}
.msger-inputarea .dropdown-form .chat-icon div svg {
  width: 42px;
  height: 42px;
  cursor: pointer;
}
.msger-inputarea .dropdown-form .chat-icon div:nth-child(1) svg {
  fill: var(--chart-text-color);
  stroke: none;
}
.msger-inputarea .dropdown-form .chat-icon div:nth-child(2) svg {
  stroke: var(--chart-text-color);
  fill: none;
}
.msger-inputarea .dropdown-form .chat-icon div:hover {
  box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
  opacity: 1;
}
.msger-inputarea .dropdown-form .chat-icon div:hover:nth-child(1) svg {
  fill: #363636;
}
.msger-inputarea .dropdown-form .chat-icon div:hover:nth-child(2) svg {
  stroke: var(--white);
}
.msger-inputarea .msger-send-btn i {
  color: var(--white);
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  flex: 1;
  background: transparent;
}
.msger-input:focus, .msger-input:focus-visible {
  outline: unset;
}

.fg-emoji-container {
  left: calc(10px + 1395 * (100vw - 320px) / 1600) !important;
}
@media (min-width: 860px) and (max-width: 945px) {
  .fg-emoji-container {
    left: 417px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 417px;
  }
}
@media (min-width: 686px) and (max-width: 860px) {
  .fg-emoji-container {
    left: 360px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 360px;
  }
}
@media (min-width: 588px) and (max-width: 770px) {
  .fg-emoji-container {
    left: 200px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 200px;
  }
}
@media (min-width: 546px) and (max-width: 588px) {
  .fg-emoji-container {
    left: 144px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 144px;
  }
}
@media (min-width: 500px) and (max-width: 546px) {
  .fg-emoji-container {
    left: 88px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 88px;
  }
}
@media (max-width: 500px) {
  .fg-emoji-container {
    left: 22px !important;
    width: 262px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 22px;
  }
}
.fg-emoji-container .fg-emoji-nav {
  background-color: var(--chart-text-color);
}
.fg-emoji-container #fg-emoji-picker-move {
  display: none;
}

.open-emoji {
  padding: 0;
}
.open-emoji button {
  z-index: 0;
  position: relative;
  padding: 0 14px;
  background: transparent;
}
.open-emoji button::before {
  content: "\f118";
  font-family: "FontAwesome";
  z-index: 1;
  position: relative;
  font-size: 20px;
  color: var(--text-gray);
}

.second-btn {
  z-index: 0;
  position: relative;
  padding: 0 14px;
  background: transparent;
}
.second-btn::before {
  content: "\f118";
  font-family: "FontAwesome";
  z-index: 1;
  position: relative;
  font-size: 20px;
  color: var(--text-gray);
}

.msger-send-btn {
  margin-left: 10px;
  background: var(--theme-default);
  width: 40px;
  height: 39px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.23s;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir=rtl] .msger-send-btn {
  margin-left: unset;
  margin-right: 10px;
}
.msger-send-btn svg {
  width: 1px;
  height: 12px;
}

.custom-name {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
@media (max-width: 1650px) {
  .custom-name {
    width: 33px;
    height: 33px;
  }
}

/**=====================
14.  Chat CSS Ends
==========================**/
/**=====================
   3.6 Checkout CSS start
==========================**/
.checkout .checkout-details {
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  padding: 40px;
}
.checkout .checkout-details .order-place {
  margin-top: 15px;
}

.order-box .title-box {
  padding-bottom: 20px;
  color: #444444;
  font-size: 22px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
}
.order-box .title-box span {
  width: 35%;
  float: right;
  font-weight: 600;
  text-align: right;
}
.order-box .title-box h4 {
  font-weight: 600;
}
.order-box .title-box .checkbox-title {
  display: flex;
  justify-content: space-between;
}
.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  width: 35%;
  float: right;
  text-align: right;
}
.order-box .sub-total .shipping-class {
  margin-bottom: 12px;
}
.order-box .sub-total .shipping-class .shopping-checkout-option {
  margin-top: -4px;
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: var(--theme-default);
  font-weight: 400;
  width: 35%;
  float: right;
  text-align: right;
}
.order-box .total {
  position: relative;
  margin-bottom: 30px;
}
.order-box .total li {
  position: relative;
  display: block;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  font-size: 18px;
}
.order-box .qty {
  position: relative;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}
.order-box .qty li {
  position: relative;
  display: block;
  font-size: 15px;
  color: #444444;
  line-height: 20px;
  margin-bottom: 20px;
}
.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
  font-weight: 400;
  width: 35%;
  text-align: right;
}

.radio-option {
  position: relative;
}

.img-paypal {
  width: 30%;
  margin-left: 15px;
}

/**=====================
    86. Checkout CSS Ends
==========================**/
/**=====================
    Responsive CSS Start
==========================**/
@media screen and (max-width: 1199px) {
  .checkout .checkout-details {
    padding: 25px;
  }
  .order-box .title-box span {
    width: 30%;
  }
  .order-box .sub-total li .count {
    width: 38%;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 46%;
  }
  .order-box .qty li span {
    width: 38%;
  }
  .order-box .total li .count {
    width: 38%;
  }
}
@media screen and (max-width: 991px) {
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 38%;
  }
}
@media screen and (max-width: 575px) {
  .checkout .checkout-details {
    padding: 15px;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 50%;
  }
  .order-box .sub-total li .count {
    width: 50%;
  }
}
/**=====================
   3.6 Checkout CSS ends
==========================**/
/**=====================
    3.7 Comingsoon CSS Start
==========================**/
.comingsoon-bgimg {
  background: url(../images/other-images/coming-soon-bg.jpg);
  background-position: bottom;
  background-size: cover;
}

.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
}
.comingsoon .comingsoon-inner h5 {
  font-size: 18px;
  letter-spacing: 1px;
  color: #9B9B9B;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 30px;
}
.comingsoon .comingsoon-inner .countdown {
  padding: 30px 0;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
}
.comingsoon .comingsoon-inner .countdown .time {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  width: 100px;
  height: 100px;
  font-size: 36px;
  background: var(--theme-default);
}
.comingsoon .comingsoon-inner .countdown .title {
  padding-top: 13px;
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.comingsoon .comingsoon-inner .countdown ul li {
  display: inline-block;
  text-transform: uppercase;
  margin: 0 20px;
}
.comingsoon .comingsoon-inner .countdown ul li span {
  display: block;
}
.comingsoon video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
}

/**=====================
  3.7 Comingsoon CSS Ends
==========================**/
/**=====================
    3.8 Contacts CSS Start
==========================**/
.alert-contactadd {
  background-color: #fff;
  border: 1px solid var(--theme-default);
  width: 12%;
  padding: 10px;
}

.updateimg {
  position: absolute;
  left: 15px;
  width: 100px;
  top: 32px;
  line-height: 93px;
  opacity: 0;
}

#right-history {
  top: 81px;
  right: -320px;
  height: 100%;
  position: fixed;
  width: 320px;
  z-index: 9;
  background-color: #fff;
  transition: 0.5s;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}
[dir=rtl] #right-history {
  right: unset;
  left: -320px;
}
#right-history h6 span a {
  color: #363636;
}
#right-history.show {
  right: 0;
  transition: 0.3s;
}
[dir=rtl] #right-history.show {
  right: unset;
  left: 0;
}

.history-details > div {
  padding: 30px 0;
}
.history-details .text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.history-details .d-flex {
  padding: 30px 15px;
  border-top: 1px solid #F3F3F3;
}
.history-details .d-flex i {
  padding: 5px;
  background: #fed112;
  color: #fff;
  border-radius: 100%;
}
.history-details .d-flex h6 {
  margin-bottom: 5px;
}

.modal-content .list-persons .profile-mail .d-flex img {
  width: 50px !important;
  height: 50px;
}
.modal-content .list-persons .profile-mail .email-general {
  padding-top: 25px;
}

.delete-contact {
  display: none;
}

.more-data {
  animation: displayTransition 1s;
}

.contact-editform {
  padding: 30px;
  animation: displayTransition 1s;
}
.contact-editform a {
  display: block;
}
.contact-editform button {
  margin-top: 30px;
}
.contact-editform .select2-container .select2-selection--single {
  height: 39px !important;
  border-color: #ced4da !important;
}

#deletemodal .modal-header {
  border-bottom: none;
}
#deletemodal .modal-header .delete-data span svg {
  vertical-align: middle;
  margin-right: 15px;
}

.contact-options li .btn-category {
  line-height: 39px;
  border-radius: 3px;
  padding-left: 40px !important;
  padding: 0;
  color: var(--theme-default);
  font-weight: 500;
}

.list-persons p {
  font-family: Roboto, sans-serif;
}
.list-persons .profile-mail {
  padding: 30px 0;
}
.list-persons .profile-mail .d-flex img {
  height: 100px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 h5 {
  margin-bottom: 5px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul {
  margin-top: 5px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul li {
  display: inline-block;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul li a {
  font-size: 13px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul li + li {
  padding-left: 10px;
  margin-left: 5px;
  border-left: 2px solid #F3F3F3;
}
.list-persons .profile-mail .email-general {
  padding-top: 50px;
}
.list-persons .profile-mail .email-general ul {
  padding-right: 20px;
}
.list-persons .profile-mail .email-general ul li {
  color: #9B9B9B;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.list-persons .profile-mail .email-general ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.list-persons .profile-mail .email-general ul li > span {
  float: right;
}
.list-persons .profile-mail .email-general p span {
  margin-left: 30px;
}
.list-persons .profile-mail .email-general .gender {
  margin-top: 30px;
}

.contacts-tabs .nav-pills {
  border-right: 1px solid #F3F3F3;
  height: 100%;
}
.contacts-tabs .nav-pills .nav-link.active,
.contacts-tabs .nav-pills .nav-link > .nav-link,
.contacts-tabs .nav-pills .show.active,
.contacts-tabs .nav-pills .show > .nav-link {
  background-color: rgba(48, 126, 243, 0.05);
  color: #363636;
}
.contacts-tabs .nav-pills .nav-link {
  color: #363636;
  padding: 0;
}
.contacts-tabs .nav-pills .nav-link .d-flex .img-50 {
  height: 50px;
}
.contacts-tabs .nav-pills .nav-link + .nav-link {
  border-top: 1px solid #F3F3F3;
}
.contacts-tabs .nav-pills .d-flex {
  padding: 20px;
}
.contacts-tabs .tab-content .tab-content-child {
  animation: displayTransition 1s;
}

@media only screen and (max-width: 991px) {
  #right-history {
    top: 60px;
  }
  .updateimg {
    top: 21px;
  }
  .list-persons .profile-mail {
    padding: 20px;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 20px;
  }
  .contact-editform button {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-editform {
    padding: 20px;
  }
  .contact-editform.pl-0 {
    padding-left: 20px !important;
  }
  .contacts-tabs .nav-pills {
    border-right: none;
    border-bottom: 1px solid #F3F3F3;
  }
}
@media only screen and (max-width: 575px) {
  #right-history {
    width: 270px;
  }
  .updateimg {
    top: 16px;
    left: 29px;
  }
  .contacts-tabs .d-flex img.m-r-20 {
    margin-right: 15px;
  }
  .contacts-tabs .nav-pills .d-flex {
    padding: 15px;
  }
  .list-persons .profile-mail {
    padding: 15px;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 15px;
  }
  .list-persons .nav-pills .nav-link .d-flex .flex-grow-1 {
    text-align: left;
  }
  .contact-editform {
    padding: 15px;
  }
  .contact-editform button {
    margin-top: 15px;
  }
  .contact-editform.pl-0 {
    padding-left: 15px !important;
  }
  .contact-editform form .row > div .row > div > div,
  .contact-options form .row > div .row > div > div {
    margin-bottom: 0;
  }
  .contact-editform form .row > div .row > div + div,
  .contact-options form .row > div .row > div + div {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .updateimg {
    line-height: 73px;
    width: 80px;
  }
  .list-persons .profile-mail .email-general p span {
    margin-left: 10px;
  }
  .list-persons .profile-mail .d-flex {
    display: block;
  }
  .list-persons .profile-mail .d-flex img {
    height: 80px;
    width: 80px !important;
    margin-bottom: 15px;
  }
}
.dark-contact .nav .nav-link .d-flex .flex-grow-1 h6 {
  color: var(--body-font-color);
}

/**=====================
   3.8 Contacts CSS Ends
==========================**/
.activity-dot-primary {
  min-width: 10px;
  height: 10px;
  background-color: #307EF3;
  border-radius: 100%;
  outline: 5px solid rgba(48, 126, 243, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-primary {
  min-width: 12px;
  height: 12px;
  background-color: #307EF3;
  outline: 5px solid rgba(48, 126, 243, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-secondary {
  min-width: 10px;
  height: 10px;
  background-color: #EBA31D;
  border-radius: 100%;
  outline: 5px solid rgba(235, 163, 29, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-secondary {
  min-width: 12px;
  height: 12px;
  background-color: #EBA31D;
  outline: 5px solid rgba(235, 163, 29, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-success {
  min-width: 10px;
  height: 10px;
  background-color: #53A653;
  border-radius: 100%;
  outline: 5px solid rgba(83, 166, 83, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-success {
  min-width: 12px;
  height: 12px;
  background-color: #53A653;
  outline: 5px solid rgba(83, 166, 83, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-danger {
  min-width: 10px;
  height: 10px;
  background-color: #DC3545;
  border-radius: 100%;
  outline: 5px solid rgba(220, 53, 69, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-danger {
  min-width: 12px;
  height: 12px;
  background-color: #DC3545;
  outline: 5px solid rgba(220, 53, 69, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-info {
  min-width: 10px;
  height: 10px;
  background-color: #01A1B9;
  border-radius: 100%;
  outline: 5px solid rgba(1, 161, 185, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-info {
  min-width: 12px;
  height: 12px;
  background-color: #01A1B9;
  outline: 5px solid rgba(1, 161, 185, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-light {
  min-width: 10px;
  height: 10px;
  background-color: #F3F3F3;
  border-radius: 100%;
  outline: 5px solid rgba(243, 243, 243, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-light {
  min-width: 12px;
  height: 12px;
  background-color: #F3F3F3;
  outline: 5px solid rgba(243, 243, 243, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-dark {
  min-width: 10px;
  height: 10px;
  background-color: #2c323f;
  border-radius: 100%;
  outline: 5px solid rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-dark {
  min-width: 12px;
  height: 12px;
  background-color: #2c323f;
  outline: 5px solid rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-warning {
  min-width: 10px;
  height: 10px;
  background-color: #FFAA05;
  border-radius: 100%;
  outline: 5px solid rgba(255, 170, 5, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-warning {
  min-width: 12px;
  height: 12px;
  background-color: #FFAA05;
  outline: 5px solid rgba(255, 170, 5, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-primary {
  min-width: 10px;
  height: 10px;
  background-color: #307EF3;
  border-radius: 100%;
  outline: 5px solid rgba(48, 126, 243, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-secondary {
  min-width: 10px;
  height: 10px;
  background-color: #EBA31D;
  border-radius: 100%;
  outline: 5px solid rgba(235, 163, 29, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-success {
  min-width: 10px;
  height: 10px;
  background-color: #53A653;
  border-radius: 100%;
  outline: 5px solid rgba(83, 166, 83, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-danger {
  min-width: 10px;
  height: 10px;
  background-color: #DC3545;
  border-radius: 100%;
  outline: 5px solid rgba(220, 53, 69, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-info {
  min-width: 10px;
  height: 10px;
  background-color: #01A1B9;
  border-radius: 100%;
  outline: 5px solid rgba(1, 161, 185, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-light {
  min-width: 10px;
  height: 10px;
  background-color: #F3F3F3;
  border-radius: 100%;
  outline: 5px solid rgba(243, 243, 243, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-dark {
  min-width: 10px;
  height: 10px;
  background-color: #2c323f;
  border-radius: 100%;
  outline: 5px solid rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.badge-dot-warning {
  min-width: 10px;
  height: 10px;
  background-color: #FFAA05;
  border-radius: 100%;
  outline: 5px solid rgba(255, 170, 5, 0.25);
  position: relative;
  z-index: 2;
}

.ecommerce-dashboard div.dataTables_wrapper {
  position: unset;
}
.ecommerce-dashboard div.dataTables_wrapper .dataTables_length {
  margin-bottom: 26px;
}
.ecommerce-dashboard div.dataTables_wrapper .dataTables_length label {
  display: none !important;
}
.ecommerce-dashboard div.dataTables_wrapper div.dataTables_filter {
  position: absolute;
  top: 10px;
  right: 53px;
}
@media (max-width: 575px) {
  .ecommerce-dashboard div.dataTables_wrapper div.dataTables_filter {
    top: 44px;
    left: 20px;
    text-align: start;
  }
  [dir=rtl] .ecommerce-dashboard div.dataTables_wrapper div.dataTables_filter {
    left: unset !important;
    right: 20px !important;
  }
}
[dir=rtl] .ecommerce-dashboard div.dataTables_wrapper div.dataTables_filter {
  left: 53px;
  right: unset;
}
.ecommerce-dashboard div.dataTables_wrapper div.dataTables_filter input[type=search] {
  width: 150px !important;
  height: 34px;
  border-radius: 6px;
  border-style: solid;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable {
  margin-top: 13px !important;
  border: none;
}
@media (max-width: 575px) {
  .ecommerce-dashboard div.dataTables_wrapper table.dataTable {
    margin-top: 42px !important;
  }
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.ecommerce-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even {
  background-color: transparent !important;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.ecommerce-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1 {
  background-color: transparent !important;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable thead {
  background-color: #F4F7F9;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th {
  border: none !important;
  text-transform: capitalize;
  font-family: Outfit;
  font-weight: 500;
  padding-top: 9px;
  padding-bottom: 9px;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:before, .ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:after {
  display: none;
}
[dir=rtl] .ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th {
  padding-right: 0px !important;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: 24px;
}
[dir=rtl] .ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: unset;
  padding-right: 24px !important;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:last-child {
  padding-right: 24px;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td {
  font-family: Outfit;
  font-weight: 500;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: 24px;
}
[dir=rtl] .ecommerce-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: unset;
  padding-right: 24px;
}
.ecommerce-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:nth-child(5) .btn {
  border-radius: 25px;
  padding: 7px 25px;
  min-width: 50px;
}
.ecommerce-dashboard .online-order:hover {
  transform: translateY(-5px);
}
.ecommerce-dashboard .online-order:hover .d-flex .order {
  animation: tada 1s ease-out infinite;
}
.ecommerce-dashboard .online-order .d-flex {
  justify-content: space-between;
}
.ecommerce-dashboard .online-order .d-flex .order {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ecommerce-dashboard .online-order .d-flex .order svg {
  width: 28px;
  height: 28px;
}
.ecommerce-dashboard .online-order .d-flex .order::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #307ef3);
}
.ecommerce-dashboard .online-order .d-flex .order::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #307ef3 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .d-flex .order span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #307ef3);
}
.ecommerce-dashboard .online-order .d-flex .order span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #307ef3 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .d-flex .arrow-chart {
  display: flex;
  align-items: end;
}
.ecommerce-dashboard .online-order .d-flex .arrow-chart svg {
  width: 20px;
  height: 20px;
}
.ecommerce-dashboard .online-order .d-flex .arrow-chart span {
  font-size: 16px;
  font-weight: 500;
}
.ecommerce-dashboard .online-order .online {
  margin-top: 18px;
}
.ecommerce-dashboard .online-order .online span {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.ecommerce-dashboard .online-order .online h2 {
  font-weight: 500;
}
.ecommerce-dashboard .online-order .offline-chart {
  margin-top: -75px;
}
.ecommerce-dashboard .online-order .revenue {
  margin-top: -38px;
}
.ecommerce-dashboard .online-order .user-details {
  display: flex;
  align-items: end;
  gap: 15px;
  justify-content: space-between;
}
@media (max-width: 1399px) {
  .ecommerce-dashboard .online-order .user-details {
    gap: 6px;
  }
}
@media (max-width: 575px) {
  .ecommerce-dashboard .online-order .user-details {
    flex-wrap: wrap;
  }
}
.ecommerce-dashboard .online-order .user-details ul {
  margin-left: -10px;
}
[dir=rtl] .ecommerce-dashboard .online-order .user-details ul {
  margin-right: -10px;
  margin-left: unset;
}
.ecommerce-dashboard .online-order .user-details ul li img {
  border: none;
}
.ecommerce-dashboard .online-order .user-details.customers ul li + li {
  margin-left: -18%;
}
[dir=rtl] .ecommerce-dashboard .online-order .user-details.customers ul li + li {
  margin-right: -18%;
  margin-left: unset;
}
.ecommerce-dashboard .online-order .progress {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  height: 10px;
  margin-top: 10px;
}
.ecommerce-dashboard .online-order .progress .progress-bar {
  border-radius: 50px;
}
.ecommerce-dashboard .online-order .offline-order .d-flex .order::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #eba31d);
}
.ecommerce-dashboard .online-order .offline-order .d-flex .order::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #eba31d 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .offline-order .d-flex .order span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #eba31d);
}
.ecommerce-dashboard .online-order .offline-order .d-flex .order span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #eba31d 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .revenue-order .d-flex .order::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #dc3545);
}
.ecommerce-dashboard .online-order .revenue-order .d-flex .order::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #dc3545 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .revenue-order .d-flex .order span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #dc3545);
}
.ecommerce-dashboard .online-order .revenue-order .d-flex .order span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #dc3545 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .feedback-card .d-flex .order::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #53a653);
}
.ecommerce-dashboard .online-order .feedback-card .d-flex .order::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #53a653 60%, transparent 40%);
}
.ecommerce-dashboard .online-order .feedback-card .d-flex .order span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #53a653);
}
.ecommerce-dashboard .online-order .feedback-card .d-flex .order span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #53a653 60%, transparent 40%);
}
.ecommerce-dashboard .sales-event .d-flex {
  align-items: center;
}
.ecommerce-dashboard .sales-event:hover {
  transform: translateY(-5px);
}
.ecommerce-dashboard .sales-event h3 {
  color: #363636;
  font-weight: 600;
}
.ecommerce-dashboard .sales-event p {
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 0;
  color: #9B9B9B;
}
.ecommerce-dashboard .sales-event .set-sales .btn.btn-primary {
  color: #fff;
  padding: 10px 20px;
  margin-right: 10px;
}
[dir=rtl] .ecommerce-dashboard .sales-event .set-sales .btn.btn-primary {
  margin-left: 10px;
  margin-right: unset;
}
.ecommerce-dashboard .sales-event .set-sales .btn.discount {
  border: 1px solid var(--theme-default);
  color: var(--theme-default);
  padding: 10px 20px;
}
.ecommerce-dashboard .sales-event .sale img {
  width: 198px;
}
.ecommerce-dashboard .state ul.d-flex {
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}
.ecommerce-dashboard .state ul.d-flex .balance-card .Countries {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ecommerce-dashboard .state ul.d-flex .balance-card .Countries .rounded-pill {
  background: var(--theme-default);
  padding: 4px 4px;
}
.ecommerce-dashboard .state ul.d-flex .balance-card .Countries h5 {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.ecommerce-dashboard .state ul.d-flex .balance-card h2 {
  font-weight: 500;
  color: #363636;
  margin-left: 12px;
}
[dir=rtl] .ecommerce-dashboard .state ul.d-flex .balance-card h2 {
  margin-right: 12px;
  margin-left: unset;
}
.ecommerce-dashboard .state .jvector-map-height {
  height: 190px;
  transform: translateY(15px);
}
.ecommerce-dashboard .state .jvector-map-height .jvectormap-zoomin, .ecommerce-dashboard .state .jvector-map-height .jvectormap-zoomout {
  display: none;
}
.ecommerce-dashboard .recent table thead tr th {
  padding-bottom: 8px;
  color: #363636;
  border-bottom: 1px solid #EAEAEA;
  font-weight: 500;
}
.ecommerce-dashboard .recent table thead tr th .form-check input {
  height: 15px;
}
.ecommerce-dashboard .recent table thead tr th .form-check input:checked {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.ecommerce-dashboard .recent table thead tr th .form-check input:focus {
  box-shadow: none;
}
.ecommerce-dashboard .recent table thead tr th:first-child, .ecommerce-dashboard .recent table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.ecommerce-dashboard .recent table thead tr th:first-child {
  min-width: 0px;
}
.ecommerce-dashboard .recent table tbody tr .form-check input {
  height: 15px;
}
.ecommerce-dashboard .recent table tbody tr .form-check input:checked {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.ecommerce-dashboard .recent table tbody tr .form-check input:focus {
  box-shadow: none;
}
.ecommerce-dashboard .recent table tbody tr td {
  border-bottom: 1px dashed #EAEAEA;
  font-weight: 500;
  padding: 17px 10px;
  border-top: none;
  color: rgba(44, 50, 63, 0.7215686275);
}
.ecommerce-dashboard .recent table tbody tr td:last-child {
  text-align: center;
}
@media (max-width: 991px) {
  .ecommerce-dashboard .recent table tbody tr td:last-child {
    min-width: 70px;
  }
}
.ecommerce-dashboard .recent table tbody tr td:last-child, .ecommerce-dashboard .recent table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.ecommerce-dashboard .recent table tbody tr td .d-flex img {
  margin-left: 0px;
}
[dir=rtl] .ecommerce-dashboard .recent table tbody tr td .d-flex img {
  margin-right: 0px;
  margin-left: unset;
}
.ecommerce-dashboard .recent table tbody tr td .d-flex .flex-shrink-0 img {
  margin-left: 0px;
}
[dir=rtl] .ecommerce-dashboard .recent table tbody tr td .d-flex .flex-shrink-0 img {
  margin-right: 0px;
  margin-left: unset;
}
.ecommerce-dashboard .recent table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
  font-weight: 500;
  color: rgba(44, 50, 63, 0.7215686275);
  transition: all 0.4s ease-in;
}
@media (max-width: 991px) {
  .ecommerce-dashboard .recent table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
    min-width: 110px;
  }
}
.ecommerce-dashboard .recent table tbody tr td h6 {
  transition: all 0.4s ease-in;
  font-weight: 500;
  color: rgba(44, 50, 63, 0.7215686275);
}
.ecommerce-dashboard .recent table tbody tr td span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.ecommerce-dashboard .recent table tbody tr .project-dot .d-flex {
  align-items: center;
  gap: 8px;
}
.ecommerce-dashboard .recent table tbody tr .project-dot .d-flex .flex-shrink-0 span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.ecommerce-dashboard .recent table tbody tr:last-child td {
  border-bottom: none;
  padding-bottom: 0;
}
.ecommerce-dashboard .categories-chart .categories-sales {
  margin: 40px 0px;
  display: grid;
}
.ecommerce-dashboard .categories-chart .categories-sales .d-flex .flex-shrink-0 span {
  width: 11px;
  height: 11px;
  border-radius: 15px;
}
.ecommerce-dashboard .categories-chart .categories-sales .d-flex .flex-grow-1 h6 {
  font-weight: 500;
  font-size: 14px;
  color: #9B9B9B;
}
.ecommerce-dashboard .categories-chart .categories-sales .d-flex h5 {
  font-weight: 500;
  font-size: 14px;
  color: #9B9B9B;
}
.ecommerce-dashboard .categories-chart .total-earn {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}
[dir=rtl] .ecommerce-dashboard .categories-chart .total-earn {
  margin-right: 20px;
  margin-left: unset;
}
.ecommerce-dashboard .categories-chart .total-earn h6 {
  color: #9B9B9B;
}
.ecommerce-dashboard .categories-chart .earned {
  padding: 0;
  margin-top: -118px;
}
.ecommerce-dashboard .unlock:hover {
  transform: translateY(-5px);
}
.ecommerce-dashboard .unlock .d-flex {
  align-items: center;
  justify-content: space-around;
}
.ecommerce-dashboard .unlock .d-flex p {
  font-size: 18px;
  font-weight: 400;
  color: #9B9B9B;
  margin-bottom: 0;
}
.ecommerce-dashboard .unlock .d-flex button {
  padding: 10px 17px;
}
.ecommerce-dashboard .unlock .d-flex button:hover {
  color: #fff;
}
.ecommerce-dashboard .recent-activity ul li.d-flex {
  margin-top: 35px;
}
.ecommerce-dashboard .recent-activity ul li.d-flex span {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #9B9B9B;
}
.ecommerce-dashboard .recent-activity ul li.d-flex p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 0;
  color: #363636;
}
.ecommerce-dashboard .recent-activity ul li.d-flex .activity-line {
  content: "";
  position: absolute;
  border: 1px solid #EAEAEA;
  top: 74px;
  left: 37px;
  height: 83%;
  z-index: 0;
}
[dir=rtl] .ecommerce-dashboard .recent-activity ul li.d-flex .activity-line {
  left: unset;
  right: 37px;
}
.ecommerce-dashboard .recent-activity ul li.d-flex img {
  z-index: 1;
}
.ecommerce-dashboard .recent-activity ul li .recent-images {
  margin-top: 5px;
}
.ecommerce-dashboard .recent-activity ul li .recent-images ul {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.ecommerce-dashboard .recent-activity ul li:hover .recent-images ul li img {
  animation: tada 1s ease-out infinite;
}
.ecommerce-dashboard .new-customers table tr {
  border-bottom: 1px dashed #EAEAEA;
}
.ecommerce-dashboard .new-customers table tr td {
  color: #363636;
  font-weight: 500;
  border-bottom: none;
  padding: 15px 0px 15px 0px;
}
.ecommerce-dashboard .new-customers table tr td .d-flex .flex-grow-1 a span {
  font-size: 14px;
  font-weight: 500;
  color: #363636;
}
.ecommerce-dashboard .new-customers table tr td .d-flex .flex-grow-1 a h5 {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.ecommerce-dashboard .new-customers table tr td:last-child {
  color: #9B9B9B;
}
.ecommerce-dashboard .new-customers table tr:first-child td {
  padding-top: 0;
}
.ecommerce-dashboard .new-customers table tr:last-child {
  border-bottom: none;
}
.ecommerce-dashboard .new-customers table tr:last-child td {
  padding-bottom: 0;
}
.ecommerce-dashboard .order-overview .d-flex {
  align-items: center;
}
.ecommerce-dashboard .order-overview .d-flex h2 {
  font-weight: 500;
}
.ecommerce-dashboard .order-overview .d-flex h6 {
  font-weight: 400;
  font-size: 16px;
  color: #9B9B9B;
}
.ecommerce-dashboard .order-overview .total-revenue {
  margin-top: 20px;
}
.ecommerce-dashboard .order-overview .total-revenue h5 {
  font-weight: 500;
  font-size: 14px;
}
.ecommerce-dashboard .order-overview .total-revenue span {
  font-weight: 400;
  font-size: 13px;
  color: #9B9B9B;
}
.ecommerce-dashboard .order-overview .total-revenue .progress {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  height: 10px;
  margin-top: 10px;
}
.ecommerce-dashboard .order-overview .total-revenue .progress .progress-bar {
  border-radius: 50px;
}
.ecommerce-dashboard .monthly-chart:hover {
  transform: translateY(-5px);
}
.ecommerce-dashboard .monthly-chart .d-flex {
  align-items: center;
}
.ecommerce-dashboard .monthly-chart .d-flex .monthly h5 {
  font-weight: 400;
  color: #9B9B9B;
}
.ecommerce-dashboard .monthly-chart .d-flex .monthly h3 {
  color: #363636;
  font-weight: 500;
}
.ecommerce-dashboard .monthly-chart .d-flex .growth {
  margin-top: -85px;
}
.ecommerce-dashboard .invoices:hover {
  transform: translateY(-5px);
}
.ecommerce-dashboard .invoices .d-flex {
  text-align: center;
}
.ecommerce-dashboard .invoices .d-flex .flex-shrink-0 {
  border-right: 1px dashed #EAEAEA;
}
[dir=rtl] .ecommerce-dashboard .invoices .d-flex .flex-shrink-0 {
  border-right: unset;
  border-left: 1px dashed #EAEAEA;
}
.ecommerce-dashboard .invoices .d-flex .flex-shrink-0 h5 {
  font-weight: 500;
  color: #9B9B9B;
}
.ecommerce-dashboard .invoices .d-flex .flex-shrink-0 h3 {
  font-weight: 500;
  color: #363636;
  margin-top: 6px;
  width: 110px;
}
.ecommerce-dashboard .invoices .d-flex .flex-grow-1 h5 {
  font-weight: 500;
  color: #9B9B9B;
}
.ecommerce-dashboard .invoices .d-flex .flex-grow-1 h3 {
  font-weight: 500;
  color: #363636;
  margin-top: 6px;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex {
  align-items: center;
  justify-content: space-between;
  margin: 22px 0;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 {
  margin-left: 8px;
}
[dir=rtl] .ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 {
  margin-right: 8px;
  margin-left: unset;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 h3 {
  font-weight: 500;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 h5 {
  font-weight: 500;
  margin-top: 5px;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 h5 del {
  font-weight: 400;
  color: #9B9B9B;
  margin-left: 10px;
}
[dir=rtl] .ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 h5 del {
  margin-right: 10px;
  margin-left: unset;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 h6 {
  margin-top: 18px;
  padding: 3px 7px;
  margin-right: 156px;
  color: #9B9B9B;
}
[dir=rtl] .ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 h6 {
  margin-right: unset;
  margin-left: 80px;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 p {
  font-size: 22px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 p span {
  color: #DC3545;
  font-weight: 600;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex ul {
  display: flex;
  gap: 12px;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex ul li {
  background-color: #F3F3F3;
  padding: 8px 12px;
  text-align: center;
  min-width: 58px;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex ul li h5 {
  color: var(--theme-default) !important;
  font-weight: 600;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex ul li span {
  font-weight: 500;
  font-size: 12px;
}
.ecommerce-dashboard .discount-card .special-offer .d-flex img {
  margin-right: 40px;
  z-index: 1;
  width: auto;
}
.ecommerce-dashboard .discount-card .owl-theme .owl-nav.disabled + .owl-dots {
  margin-bottom: -10px;
  margin-top: -15px;
}
.ecommerce-dashboard .product-slider .shap-block .rounded-shap i {
  right: 12%;
  top: -55%;
}
.ecommerce-dashboard .shap-block .rounded-shap i {
  background-color: rgba(48, 126, 243, 0.4);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
  z-index: 0;
}
.ecommerce-dashboard .shap-block .rounded-shap i:first-child {
  animation: ripple1 3s linear infinite;
}
.ecommerce-dashboard .shap-block .rounded-shap i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.ecommerce-dashboard .shap-block .rounded-shap i:nth-child(3) {
  animation: ripple3 3s linear infinite;
}
.ecommerce-dashboard .animated-bg i {
  background-color: #fff;
  box-shadow: 0 15px 30px 0 #fff;
  position: absolute;
  height: 8%;
  width: 8%;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
}
.ecommerce-dashboard .animated-bg i:first-child {
  animation: ripple1 3s linear infinite;
}
.ecommerce-dashboard .animated-bg i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.ecommerce-dashboard .animated-bg i:nth-child(3) {
  animation: ripple3 3s linear infinite;
}
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2.5);
  }
}

@media (max-width: 1750px) and (min-width: 1441px) {
  .ecommerce-dashboard .online-order .d-flex .order {
    width: 45px;
    height: 45px;
  }
  .ecommerce-dashboard .online-order .d-flex .order svg {
    width: 22px;
    height: 22px;
  }
  .ecommerce-dashboard .recent table thead tr th:nth-child(3) {
    display: none;
  }
  .ecommerce-dashboard .recent table tbody tr td:nth-child(3) {
    display: none;
  }
  .ecommerce-dashboard .recent table tbody tr td .d-flex .flex-grow-1 a h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .ecommerce-dashboard .state ul.d-flex {
    margin-left: 0;
    margin-right: 0;
  }
  .ecommerce-dashboard .recent-activity ul li.d-flex {
    margin-top: 18px;
  }
  .ecommerce-dashboard .recent-activity ul li.d-flex span {
    margin-bottom: 0;
  }
  .ecommerce-dashboard .recent-activity ul li.d-flex p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .ecommerce-dashboard .invoices .card-body {
    padding: 18px 6px;
  }
  .ecommerce-dashboard .invoices .d-flex .flex-shrink-0 h3 {
    width: 80px;
  }
}
@media (max-width: 1660px) and (min-width: 1441px) {
  .ecommerce-dashboard .sales-event h3 {
    font-size: 18px;
  }
  .ecommerce-dashboard .sales-event p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .ecommerce-dashboard .sales-event .set-sales {
    margin-top: -15px;
  }
  .ecommerce-dashboard .online-order .user-details.customers ul li + li {
    margin-left: -30%;
  }
  .ecommerce-dashboard .categories-chart .categories-sales .d-flex h5 {
    display: none;
  }
  .ecommerce-dashboard .order-overview .d-flex h6 {
    display: none;
  }
  .ecommerce-dashboard .new-customers table tr td:nth-child(2) {
    display: none;
  }
  .ecommerce-dashboard .discount-card .special-offer .d-flex img {
    display: none;
  }
  .ecommerce-dashboard .discount-card .owl-theme .owl-dots {
    margin-top: 10px !important;
  }
}
@media (max-width: 1440px) {
  .online-order {
    margin-bottom: 30px !important;
  }
  .invoices .d-flex .flex-shrink-0 h3 {
    width: 180px !important;
  }
}
@media (max-width: 1440px) and (min-width: 768px) {
  .ecommerce-dashboard .col-xl-100 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ecommerce-dashboard .col-xl-50 {
    flex: 0 0 auto;
    width: 50%;
  }
  .ecommerce-dashboard .col-xl-33 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .ecommerce-dashboard .col-xl-40 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .ecommerce-dashboard .col-xl-70 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .ecommerce-dashboard .pedding-start {
    padding-left: 0;
  }
  [dir=rtl] .ecommerce-dashboard .pedding-start {
    padding-right: 0;
    padding-left: unset;
  }
  .ecommerce-dashboard .pedding-end {
    padding-right: 0;
  }
  [dir=rtl] .ecommerce-dashboard .pedding-end {
    padding-left: 0;
    padding-right: unset;
  }
  .ecommerce-dashboard .margin-bottom {
    margin-bottom: 0 !important;
  }
  .ecommerce-dashboard .recent-activity ul li.d-flex p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}
@media (max-width: 991px) {
  .ecommerce-dashboard .recent-activity ul li.d-flex {
    margin-top: 18px;
  }
}
@media (max-width: 860px) and (min-width: 768px) {
  .ecommerce-dashboard .sales-event h3 {
    font-size: 18px;
  }
  .ecommerce-dashboard .sales-event p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .ecommerce-dashboard .sales-event .set-sales {
    margin-top: -15px;
  }
  .ecommerce-dashboard .state ul.d-flex {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .recent table thead tr th {
    min-width: 120px;
  }
  .new-customers table tbody tr td {
    min-width: 170px;
  }
}
@media (max-width: 767px) and (min-width: 576px) {
  .pedding-sm-start {
    padding-left: 0;
  }
}
@media (max-width: 575px) {
  .pedding-sm {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .ecommerce-dashboard .sales-event .card-body {
    padding-top: 18px !important;
  }
  .ecommerce-dashboard .sales-event .set-sales {
    margin-top: 10px;
  }
  .ecommerce-dashboard .sales-event .sale img {
    display: none;
  }
}
@media (max-width: 500px) {
  .ecommerce-dashboard .discount-card .special-offer .d-flex img {
    display: none;
  }
}
@media (max-width: 500px) {
  .ecommerce-dashboard .state ul.d-flex {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 400px) {
  .ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 p {
    font-size: 16px;
  }
  .ecommerce-dashboard .discount-card .special-offer .d-flex .flex-shrink-0 ul li:last-child {
    display: none;
  }
  .ecommerce-dashboard .invoices .d-flex .flex-shrink-0 h3 {
    width: 110px !important;
  }
}
@media (max-width: 360px) {
  .ecommerce-dashboard .categories-chart .categories-sales .d-flex h5 {
    display: none;
  }
  .ecommerce-dashboard .unlock .d-flex p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}
.project-dashboard div.dataTables_wrapper {
  position: unset;
}
.project-dashboard div.dataTables_wrapper .dataTables_length {
  margin-bottom: 26px;
}
.project-dashboard div.dataTables_wrapper .dataTables_length label {
  display: none !important;
}
.project-dashboard div.dataTables_wrapper div.dataTables_filter {
  position: absolute;
  top: 10px;
  right: 53px;
}
@media (max-width: 575px) {
  .project-dashboard div.dataTables_wrapper div.dataTables_filter {
    top: 44px;
    left: 20px;
    text-align: start;
  }
  [dir=rtl] .project-dashboard div.dataTables_wrapper div.dataTables_filter {
    left: unset !important;
    right: 20px !important;
  }
}
[dir=rtl] .project-dashboard div.dataTables_wrapper div.dataTables_filter {
  left: 53px;
  right: unset;
}
.project-dashboard div.dataTables_wrapper div.dataTables_filter input[type=search] {
  width: 150px !important;
  height: 34px;
  border-radius: 6px;
  border-style: solid;
}
.project-dashboard div.dataTables_wrapper table.dataTable {
  margin-top: 13px !important;
  border: none;
}
@media (max-width: 575px) {
  .project-dashboard div.dataTables_wrapper table.dataTable {
    margin-top: 42px !important;
  }
}
.project-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.project-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even {
  background-color: transparent !important;
}
.project-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.project-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1 {
  background-color: transparent !important;
}
.project-dashboard div.dataTables_wrapper table.dataTable thead {
  background-color: #F4F7F9;
}
.project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th {
  border: none !important;
  text-transform: capitalize;
  font-family: Outfit;
  font-weight: 500;
  padding-top: 9px;
  padding-bottom: 9px;
}
.project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:before, .project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:after {
  display: none;
}
[dir=rtl] .project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th {
  padding-right: 0px !important;
}
.project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: 24px;
}
[dir=rtl] .project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: unset;
  padding-right: 24px !important;
}
.project-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:last-child {
  padding-right: 24px;
}
.project-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td {
  font-family: Outfit;
  font-weight: 500;
}
.project-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: 24px;
}
[dir=rtl] .project-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: unset;
  padding-right: 24px;
}
.project-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:nth-child(5) .btn {
  border-radius: 25px;
  padding: 7px 25px;
  min-width: 50px;
}
.project-dashboard .web-card:hover {
  transform: translateY(-5px);
}
.project-dashboard .web-card:hover .d-flex .header-top .mobile-app {
  animation: tada 1s ease-out infinite;
}
.project-dashboard .web-card .d-flex {
  justify-content: space-between;
}
.project-dashboard .web-card .d-flex .header-top .mobile-app {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .web-card .d-flex .header-top .mobile-app svg {
  width: 22px;
  height: 22px;
}
.project-dashboard .web-card .d-flex .header-top .mobile-app::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #307ef3);
}
.project-dashboard .web-card .d-flex .header-top .mobile-app::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #307ef3 60%, transparent 40%);
}
.project-dashboard .web-card .d-flex .header-top .mobile-app span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #307ef3);
}
.project-dashboard .web-card .d-flex .header-top .mobile-app span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #307ef3 60%, transparent 40%);
}
.project-dashboard .web-card .d-flex .header-top .flex-grow-1 {
  margin-left: 17px;
}
[dir=rtl] .project-dashboard .web-card .d-flex .header-top .flex-grow-1 {
  margin-left: unset;
  margin-right: 17px;
}
.project-dashboard .web-card .d-flex .header-top .flex-grow-1 span {
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
}
.project-dashboard .web-card .d-flex.nft-card .header-top .mobile-app::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #53a653);
}
.project-dashboard .web-card .d-flex.nft-card .header-top .mobile-app::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #53a653 60%, transparent 40%);
}
.project-dashboard .web-card .d-flex.nft-card .header-top .mobile-app span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #53a653);
}
.project-dashboard .web-card .d-flex.nft-card .header-top .mobile-app span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #53a653 60%, transparent 40%);
}
.project-dashboard .web-card .d-flex.twitter-card .header-top .mobile-app::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #dc3545);
}
.project-dashboard .web-card .d-flex.twitter-card .header-top .mobile-app::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #dc3545 60%, transparent 40%);
}
.project-dashboard .web-card .d-flex.twitter-card .header-top .mobile-app span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #dc3545);
}
.project-dashboard .web-card .d-flex.twitter-card .header-top .mobile-app span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #dc3545 60%, transparent 40%);
}
.project-dashboard .web-card .comment ul {
  display: flex;
  margin-top: 12px;
}
.project-dashboard .web-card .comment ul li {
  background-color: #F6F6F6;
  border-radius: 25px;
  padding: 6px 15px;
  margin-right: 8px;
}
[dir=rtl] .project-dashboard .web-card .comment ul li {
  margin-left: 8px;
  margin-right: unset;
}
.project-dashboard .web-card .comment ul li:last-child {
  margin-right: unset;
}
[dir=rtl] .project-dashboard .web-card .comment ul li:last-child {
  margin-left: unset;
}
.project-dashboard .web-card .comment ul li svg {
  width: 20px;
  height: 20px;
  margin-bottom: -8px;
}
[dir=rtl] .project-dashboard .web-card .comment ul li svg {
  margin-left: 5px;
}
.project-dashboard .web-card .comment ul li span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.project-dashboard .web-card .flex-shrink-0 {
  margin-top: 25px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}
[dir=rtl] .project-dashboard .web-card .flex-shrink-0 {
  margin-right: 8px;
  margin-left: unset;
}
.project-dashboard .web-card .flex-shrink-0 .user-details {
  display: flex;
  align-items: end;
  gap: 15px;
  justify-content: space-between;
}
@media (max-width: 1399px) {
  .project-dashboard .web-card .flex-shrink-0 .user-details {
    gap: 6px;
  }
}
@media (max-width: 575px) {
  .project-dashboard .web-card .flex-shrink-0 .user-details {
    flex-wrap: wrap;
  }
}
.project-dashboard .web-card .flex-shrink-0 .user-details ul {
  margin-left: -10px;
}
[dir=rtl] .project-dashboard .web-card .flex-shrink-0 .user-details ul {
  margin-right: -10px;
  margin-left: unset;
}
.project-dashboard .web-card .flex-shrink-0 .user-details ul li img {
  border-radius: 10px;
}
.project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
  margin-left: -12%;
}
[dir=rtl] .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
  margin-right: -12%;
  margin-left: unset;
}
.project-dashboard .web-card .flex-shrink-0 .progress-value span {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
  margin-bottom: 5px;
}
.project-dashboard .web-card .flex-shrink-0 .progress-value h6 {
  font-size: 13px;
  color: #9B9B9B;
}
.project-dashboard .web-card .flex-shrink-0 .progress-value .progress {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  width: 220px;
  height: 8px;
}
.project-dashboard .web-card .flex-shrink-0 .progress-value .progress .progress-bar {
  border-radius: 50px;
}
.project-dashboard .project-summary table thead tr th {
  padding-bottom: 8px;
  color: #363636;
  border-bottom: 1px solid #EAEAEA;
  font-weight: 500;
}
.project-dashboard .project-summary table thead tr th .form-check input {
  height: 15px;
}
.project-dashboard .project-summary table thead tr th .form-check input:checked {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.project-dashboard .project-summary table thead tr th .form-check input:focus {
  box-shadow: none;
}
.project-dashboard .project-summary table thead tr th:first-child, .project-dashboard .project-summary table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.project-dashboard .project-summary table thead tr th:first-child {
  min-width: 0px;
}
.project-dashboard .project-summary table tbody tr .form-check input {
  height: 15px;
}
.project-dashboard .project-summary table tbody tr .form-check input:checked {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.project-dashboard .project-summary table tbody tr .form-check input:focus {
  box-shadow: none;
}
.project-dashboard .project-summary table tbody tr td {
  border-bottom: 1px dashed #EAEAEA;
  font-weight: 500;
  padding: 21px 10px;
  border-top: none;
  color: rgba(44, 50, 63, 0.7215686275);
}
.project-dashboard .project-summary table tbody tr td:last-child {
  text-align: center;
}
@media (max-width: 991px) {
  .project-dashboard .project-summary table tbody tr td:last-child {
    min-width: 70px;
  }
}
.project-dashboard .project-summary table tbody tr td:last-child, .project-dashboard .project-summary table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.project-dashboard .project-summary table tbody tr td .d-flex img {
  margin-left: 0px;
}
[dir=rtl] .project-dashboard .project-summary table tbody tr td .d-flex img {
  margin-right: 0px;
  margin-left: unset;
}
.project-dashboard .project-summary table tbody tr td .d-flex .flex-shrink-0 img {
  margin-left: 0px;
}
[dir=rtl] .project-dashboard .project-summary table tbody tr td .d-flex .flex-shrink-0 img {
  margin-right: 0px;
  margin-left: unset;
}
.project-dashboard .project-summary table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
  font-weight: 500;
  color: rgba(44, 50, 63, 0.7215686275);
  transition: all 0.4s ease-in;
}
@media (max-width: 991px) {
  .project-dashboard .project-summary table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
    min-width: 110px;
  }
}
.project-dashboard .project-summary table tbody tr td h6 {
  transition: all 0.4s ease-in;
  font-weight: 500;
  color: rgba(44, 50, 63, 0.7215686275);
}
.project-dashboard .project-summary table tbody tr td span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.project-dashboard .project-summary table tbody tr td .progress {
  height: 5px;
  width: 70px;
}
.project-dashboard .project-summary table tbody tr td:last-child .btn {
  border-radius: 25px;
  padding: 7px 16px;
  min-width: 50px;
}
.project-dashboard .project-summary table tbody tr:last-child td {
  border-bottom: none;
  padding-bottom: 0;
}
.project-dashboard .upcoming-launch:hover {
  transform: translateY(-5px);
}
.project-dashboard .upcoming-launch .meeting {
  background-color: #F6F6F6;
  padding: 10px 20px;
  border-radius: 5px;
}
.project-dashboard .upcoming-launch .meeting .d-flex {
  align-items: center;
  justify-content: space-between;
}
.project-dashboard .upcoming-launch .meeting .d-flex h4 {
  color: var(--theme-default) !important;
}
.project-dashboard .upcoming-launch .meeting .d-flex span {
  font-size: 13px;
  font-weight: 600;
  color: #363636;
}
.project-dashboard .upcoming-launch .meeting p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 12px;
  color: #9B9B9B;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 .btn {
  padding: 10px 16px;
  font-weight: 500;
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details {
  display: flex;
  align-items: end;
  gap: 15px;
  justify-content: space-between;
  margin-right: -25px;
}
[dir=rtl] .project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details {
  margin-right: unset;
  margin-left: -25px;
}
@media (max-width: 1399px) {
  .project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details {
    gap: 6px;
  }
}
@media (max-width: 575px) {
  .project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details {
    flex-wrap: wrap;
  }
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details ul {
  margin-left: -10px;
}
[dir=rtl] .project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details ul {
  margin-right: -10px;
  margin-left: unset;
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details ul li img {
  border-radius: 10px;
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details ul li .light-card {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #363636;
  background: #fff;
  border: 1px solid rgba(155, 155, 155, 0.2);
}
.project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details.customers ul li + li {
  margin-left: -12%;
}
[dir=rtl] .project-dashboard .upcoming-launch .meeting .flex-shrink-0 .user-details.customers ul li + li {
  margin-right: -12%;
  margin-left: unset;
}
.project-dashboard .design-card:hover {
  transform: translateY(-5px);
}
.project-dashboard .design-card .card-body {
  background-image: url(../../assets/images/dashboard-3/design-card.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  border-radius: 5px;
  text-align: left;
}
.project-dashboard .design-card h5 {
  color: #fff;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.project-dashboard .design-card p {
  margin-right: 80px;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.project-dashboard .design-card .btn {
  margin-top: 12px;
  background-color: #fff;
  color: #363636;
  padding: 10px 16px;
}
.project-dashboard .pending-project:hover {
  transform: translateY(-5px);
}
.project-dashboard .pending-project .d-flex {
  align-items: center;
}
.project-dashboard .pending-project .d-flex .flex-grow-1 span {
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
  margin-top: 3px;
}
.project-dashboard .pending-project .d-flex .flex-grow-1 p {
  margin-top: 5px;
  margin-bottom: -6px;
}
.project-dashboard .pending-project .d-flex .flex-grow-1 p.d-flex span[class*=bg-] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .pending-project .d-flex .flex-grow-1 p.d-flex span[class*=bg-] svg {
  position: absolute;
  padding-top: 0;
  width: 14px;
  height: 14px;
}
.project-dashboard .pending-project .d-flex .flex-shrink-0 .bg-light-primary {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .pending-project .d-flex .flex-shrink-0 .bg-light-primary svg {
  width: 20px;
  height: 20px;
  stroke: var(--theme-default);
}
.project-dashboard .pending-project .d-flex .flex-shrink-0 .bg-light-secondary {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .pending-project .d-flex .flex-shrink-0 .bg-light-secondary svg {
  width: 20px;
  height: 20px;
  stroke: var(--theme-secondary);
}
.project-dashboard .pending-project .d-flex .flex-shrink-0 .bg-light-danger {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .pending-project .d-flex .flex-shrink-0 .bg-light-danger svg {
  width: 20px;
  height: 20px;
  stroke: #DC3545;
}
.project-dashboard .budget-card .budget-chart {
  margin-top: -32px;
}
.project-dashboard .budget-card .budget-spent {
  background-color: #F6F6F6;
  border-radius: 5px;
  padding: 13px 25px;
  margin-bottom: 18px;
}
.project-dashboard .budget-card .budget-spent span {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.project-dashboard .budget-card .budget-spent h2 {
  font-weight: 500;
}
.project-dashboard .budget-card .d-flex {
  align-items: center;
  margin-top: 14px;
}
.project-dashboard .budget-card .d-flex .design-score {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .budget-card .d-flex .design-score svg {
  width: 20px;
  height: 20px;
}
.project-dashboard .budget-card .d-flex .flex-grow-1 {
  margin-left: 10px;
}
[dir=rtl] .project-dashboard .budget-card .d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 10px;
}
.project-dashboard .budget-card .d-flex .flex-grow-1 h6 {
  font-weight: 500;
  margin-bottom: 3px;
}
.project-dashboard .budget-card .d-flex .flex-grow-1 span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.project-dashboard .budget-card .d-flex .flex-shrink-0 .design-menu {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(155, 155, 155, 0.4);
}
.project-dashboard .budget-card .d-flex .flex-shrink-0 .design-menu i {
  color: #9B9B9B;
}
.project-dashboard .today-task .clients-project table thead {
  background-color: #F6F6F6;
}
.project-dashboard .today-task .clients-project table thead tr th {
  font-size: 14px;
  font-weight: 500;
  padding: 9px 9px;
  border-bottom: none;
  color: #9B9B9B;
}
.project-dashboard .today-task .clients-project table thead tr th:last-child {
  text-align: end;
}
.project-dashboard .today-task .clients-project table tbody tr td {
  padding: 14px 13px;
  border-bottom: 1px dashed #EAEAEA;
}
.project-dashboard .today-task .clients-project table tbody tr td .user-details {
  display: flex;
  align-items: end;
  gap: 15px;
  justify-content: space-between;
}
@media (max-width: 1399px) {
  .project-dashboard .today-task .clients-project table tbody tr td .user-details {
    gap: 6px;
  }
}
@media (max-width: 575px) {
  .project-dashboard .today-task .clients-project table tbody tr td .user-details {
    flex-wrap: wrap;
  }
}
.project-dashboard .today-task .clients-project table tbody tr td .user-details ul {
  margin-left: -10px;
}
[dir=rtl] .project-dashboard .today-task .clients-project table tbody tr td .user-details ul {
  margin-right: -10px;
  margin-left: unset;
}
.project-dashboard .today-task .clients-project table tbody tr td .user-details ul li img {
  border-radius: 10px;
  width: 24px;
}
.project-dashboard .today-task .clients-project table tbody tr td .user-details.customers ul li + li {
  margin-left: -12%;
}
[dir=rtl] .project-dashboard .today-task .clients-project table tbody tr td .user-details.customers ul li + li {
  margin-right: -14%;
  margin-left: unset;
}
.project-dashboard .today-task .clients-project table tbody tr td p {
  font-size: 14px;
  font-weight: 500;
  color: #363636;
  letter-spacing: 0;
  line-height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.project-dashboard .today-task .clients-project table tbody tr td:nth-child(2) {
  text-align: end;
}
[dir=rtl] .project-dashboard .today-task .clients-project table tbody tr td:nth-child(2) {
  text-align: unset;
}
.project-dashboard .today-task .clients-project table tbody tr td:last-child {
  text-align: end;
  font-size: 14px;
  font-weight: 500;
  min-width: 115px;
  color: #9B9B9B;
}
.project-dashboard .today-task .clients-project table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}
.project-dashboard .progress-chart .progress-card {
  margin-top: -30px;
}
.project-dashboard .progress-chart .d-flex {
  margin-top: -22px;
  align-items: center;
  justify-content: center;
}
.project-dashboard .progress-chart .d-flex span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 3px;
  color: #9B9B9B;
}
.project-dashboard .progress-chart .d-flex h6 {
  font-weight: 600;
}
.project-dashboard .progress-chart h5 {
  text-align: center;
  margin-top: 8px;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 500;
}
[dir=rtl] .project-dashboard .progress-chart h5 {
  margin-left: unset;
  margin-right: 15px;
}
.project-dashboard .progress-chart p {
  text-align: center;
  margin-top: 8px;
  margin-left: 15px;
  margin-right: 15px;
  color: #9B9B9B;
}
[dir=rtl] .project-dashboard .progress-chart p {
  margin-left: unset;
  margin-right: 15px;
}
.project-dashboard .right-background .upcoming-project:hover {
  transform: translateY(-5px);
}
.project-dashboard .right-background .upcoming-project .d-flex {
  align-items: center;
  justify-content: space-between;
}
.project-dashboard .right-background .upcoming-project .d-flex .flex-shrink-0 {
  border: 1px dashed #9B9B9B;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .right-background .upcoming-project .d-flex .flex-shrink-0 svg {
  width: 20px;
  height: 20px;
  margin-right: -4px;
  vertical-align: middle;
  fill: #9B9B9B;
}
.project-dashboard .right-background .redesign:hover {
  transform: translateY(-5px);
}
.project-dashboard .right-background .redesign h6 {
  font-weight: 500;
}
.project-dashboard .right-background .redesign h5 {
  font-weight: 600;
  margin-top: 5px;
}
.project-dashboard .right-background .redesign .flex-shrink-0 {
  display: flex;
  margin-top: 9px;
}
.project-dashboard .right-background .redesign .flex-shrink-0 svg {
  width: 28px;
  height: 28px;
  margin-top: 3px;
}
[dir=rtl] .project-dashboard .right-background .redesign .flex-shrink-0 svg {
  margin-left: 8px;
  margin-right: -12px;
}
.project-dashboard .right-background .redesign .flex-shrink-0 span {
  font-size: 16px;
  font-weight: 500;
  color: #9B9B9B;
}
.project-dashboard .right-background .redesign .d-flex {
  align-items: center;
  margin-top: 8px;
}
.project-dashboard .right-background .redesign .d-flex .deadline {
  position: relative;
  border: 3px solid var(--theme-default);
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .right-background .redesign .d-flex .deadline svg {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--theme-default);
}
.project-dashboard .right-background .redesign .d-flex .bussiness-app {
  position: relative;
  border: 3px solid var(--theme-secondary);
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .right-background .redesign .d-flex .bussiness-app svg {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--theme-secondary);
}
.project-dashboard .right-background .redesign .d-flex .nft-web {
  position: relative;
  border: 3px solid #53A653;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-dashboard .right-background .redesign .d-flex .nft-web svg {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #53A653;
}
.project-dashboard .right-background .redesign .d-flex .flex-grow-1 {
  margin-left: 12px;
}
[dir=rtl] .project-dashboard .right-background .redesign .d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 12px;
}
.project-dashboard .right-background .redesign .d-flex .flex-grow-1 span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.project-dashboard .right-background .redesign .d-flex .flex-grow-1 h6 {
  font-size: 14px;
  font-weight: 500;
  color: #363636;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker {
  background-color: unset;
  box-shadow: none;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--nav {
  border-bottom: none;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-action {
  background-color: unset;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-action svg path {
  stroke: #363636;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-title {
  color: #363636;
  font-weight: 500;
  background-color: rgba(48, 126, 243, 0.2);
  border-radius: 0;
  padding: 7px 25px;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-title i {
  color: #363636;
  font-weight: 500;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--content .datepicker--day-name {
  color: #363636;
  font-size: 14px;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--cell.-selected- {
  border-radius: 50%;
  box-shadow: 0px 0px 14px 0px rgba(48, 126, 243, 0.5);
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--cell.-current- {
  border-radius: 50%;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--cell-day {
  border-radius: 50%;
}
.project-dashboard .right-background .upcoming-meeting .default-datepicker .datepicker .datepicker--cell-day:focus {
  border-radius: 50%;
}
.project-dashboard .right-background .upcoming-meeting .today-weather .d-flex {
  align-items: center;
  padding-left: 18px;
}
[dir=rtl] .project-dashboard .right-background .upcoming-meeting .today-weather .d-flex {
  padding-left: unset;
  padding-right: 18px;
}
.project-dashboard .right-background .upcoming-meeting .today-weather .d-flex span {
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
  color: #9B9B9B;
}
[dir=rtl] .project-dashboard .right-background .upcoming-meeting .today-weather .d-flex span {
  margin-left: unset;
  margin-right: 5px;
}
.project-dashboard .right-background .upcoming-meeting .today-weather .d-flex .flex-grow-1 {
  font-size: 14px;
  font-weight: 600;
  text-align: end;
  color: #363636;
}
.project-dashboard .right-background .upcoming-meeting .today-weather .d-flex img {
  width: 38px;
  height: 38px;
}
.project-dashboard .right-background .timeline-calendar {
  overflow: auto;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar {
  height: 265px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line {
  overflow: auto;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line.fc-theme-standard th {
  border: none;
  background: unset;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-col-header-cell-cushion {
  padding: 15px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-scrollgrid-sync-table {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-divider {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-scrollgrid {
  border: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-scrollgrid table thead tr {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar {
  position: absolute;
  right: 246px;
  top: 8px;
  margin-top: 10px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-button-group {
  gap: 10px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button span {
  color: #363636;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk:last-child {
  display: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness {
  border: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event {
  border: none;
  top: 80px;
  min-width: 225px;
  min-height: 48px;
  max-height: 48px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main {
  background-color: rgba(48, 126, 243, 0.1);
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-sticky {
  color: #363636;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-sticky .fc-task-text-box .fc-task-text {
  color: #363636;
  font-weight: 500;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-sticky .fc-task-text-box:last-child span {
  color: #9B9B9B;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-event-main-frame {
  padding: 8px 12px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main:nth-child(3) {
  display: none !important;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-timegrid-col:nth-child(3) .fc-event-main {
  background-color: rgba(220, 53, 69, 0.1) !important;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-scrollgrid-section .fc-timegrid-slots table tbody tr {
  border: none;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-scrollgrid-section .fc-timegrid-slots table tbody tr td.fc-scrollgrid-shrink {
  padding: 10px;
}
.project-dashboard .right-background .timeline-calendar .custom-calendar .time-line .fc-scrollgrid-section .fc-timegrid-slots table tbody tr td.fc-timegrid-slot-minor {
  padding: 10px;
}
.project-dashboard .right-background .timeline-calendar .fc-theme-standard td {
  border: none;
  border-bottom: 1px dashed #EAEAEA !important;
}
.project-dashboard .right-background .timeline-calendar .fc-theme-standard td:first-child {
  border: none !important;
}

@media (max-width: 1850px) and (min-width: 1200px) {
  .project-summary table thead tr th:nth-child(4) {
    display: none;
  }
  .project-summary table tbody tr td:nth-child(4) {
    display: none;
  }
  .today-task .clients-project table tbody tr td .user-details.customers ul li + li {
    margin-left: -30% !important;
  }
  [dir=rtl] .today-task .clients-project table tbody tr td .user-details.customers ul li + li {
    margin-left: unset !important;
    margin-right: -30% !important;
  }
}
@media (max-width: 1850px) {
  .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: -20%;
  }
  [dir=rtl] .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: unset;
    margin-right: -20%;
  }
  .project-dashboard .web-card .flex-shrink-0 .progress-value .progress {
    width: 120px;
  }
}
@media (max-width: 1750px) {
  .project-dashboard .web-card .comment ul li {
    padding: 6px 6px;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) {
  .project-dashboard .project-summary table thead tr th:nth-child(5) {
    display: none;
  }
  .project-dashboard .project-summary table tbody tr td:nth-child(5) {
    display: none;
  }
  .project-dashboard .project-summary table tbody tr td .d-flex .flex-grow-1 a h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}
@media (max-width: 1580px) and (min-width: 992px) {
  .project-dashboard .pending-project .d-flex .flex-grow-1 p.d-flex span:last-child {
    display: none;
  }
}
@media (max-width: 1550px) {
  .project-dashboard .web-card .d-flex .header-top .flex-grow-1 {
    margin-left: 10px;
  }
  [dir=rtl] .project-dashboard .web-card .d-flex .header-top .flex-grow-1 {
    margin-left: unset;
    margin-right: 10px;
  }
  .project-dashboard .web-card .d-flex .header-top .flex-grow-1 h4 {
    font-size: 16px;
  }
  .project-dashboard .right-background .bg-light {
    padding: 14px !important;
  }
  .project-dashboard .right-background .upcoming-project .card-body {
    padding: 10px;
  }
  .project-dashboard .right-background .upcoming-project .d-flex h4 {
    font-size: 16px;
  }
  .project-dashboard .right-background .upcoming-project .d-flex .flex-shrink-0 {
    width: 38px;
    height: 38px;
  }
  .project-dashboard .right-background .redesign h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .project-dashboard .right-background .redesign .flex-shrink-0 span {
    font-size: 13px;
  }
  .project-dashboard .right-background .redesign .d-flex .flex-grow-1 h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .project-dashboard .right-background .timeline-calendar .custom-calendar {
    height: 295px;
  }
  .project-dashboard .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-title {
    padding: 7px 10px !important;
  }
  .project-dashboard .upcoming-meeting .today-weather .d-flex span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .project-dashboard .upcoming-launch .meeting {
    padding: 10px 12px;
  }
  .project-dashboard .upcoming-launch .meeting .d-flex h4 {
    font-size: 16px;
  }
  .project-dashboard .upcoming-launch .meeting .flex-shrink-0 .btn {
    padding: 10px 6px;
  }
}
@media (max-width: 1440px) {
  .project-dashboard .ps-20 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .project-dashboard .ps-20 {
    padding-right: 0.5rem !important;
    padding-left: unset;
  }
  .project-dashboard .pe-30 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .project-dashboard .pe-30 {
    padding-left: 1rem !important;
    padding-right: unset;
  }
}
@media (max-width: 1440px) and (min-width: 768px) {
  .project-dashboard .col-xl-100 {
    flex: 0 0 auto;
    width: 100%;
  }
  .project-dashboard .col-xl-50 {
    flex: 0 0 auto;
    width: 50%;
  }
  .project-dashboard .col-xl-33 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .project-dashboard .col-xl-40 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .project-dashboard .col-xl-70 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .project-dashboard .pedding-start {
    padding-left: 0;
  }
  [dir=rtl] .project-dashboard .pedding-start {
    padding-right: 0;
    padding-left: unset;
  }
  .project-dashboard .pedding-end {
    padding-right: 0;
  }
  [dir=rtl] .project-dashboard .pedding-end {
    padding-left: 0;
    padding-right: unset;
  }
  .project-dashboard .margin-bottom {
    margin-bottom: 0 !important;
  }
  .project-dashboard .right-background .redesign h5 {
    margin-top: 10px;
  }
  .project-dashboard .right-background .redesign .flex-shrink-0 {
    margin-top: 13px;
  }
  .project-dashboard .right-background .redesign .d-flex {
    margin-top: 10px;
  }
}
@media (max-width: 1199px) {
  .project-dashboard .project-summary table thead tr th {
    min-width: 130px;
  }
  .project-dashboard .project-summary table thead tr th:first-child {
    min-width: 0;
  }
  .project-dashboard .today-task .clients-project table thead tr th:first-child {
    min-width: 190px;
  }
  .project-dashboard .today-task .clients-project table thead tr th:nth-child(2) {
    min-width: 120px;
  }
}
@media (max-width: 768px) {
  .project-dashboard .pe-30 {
    padding-right: 0.5rem !important;
  }
  [dir=rtl] .project-dashboard .pe-30 {
    padding-left: 0.5rem !important;
    padding-right: unset;
  }
}
@media (max-width: 365px) {
  .project-dashboard .pending-project .d-flex .flex-grow-1 p.d-flex span:last-child {
    display: none;
  }
  .project-dashboard .web-card .comment ul li {
    padding: 4px 4px;
  }
  .project-dashboard .web-card .comment ul li svg {
    width: 17px;
    height: 17px;
  }
  .project-dashboard .web-card .comment ul li span {
    font-size: 12px;
  }
  .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: -26%;
  }
  [dir=rtl] .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: unset;
    margin-right: -26%;
  }
  .project-dashboard .datepicker--cell-day {
    height: 35px;
  }
}
.badge-light-primary {
  background-color: #e7f0fe;
  color: #307EF3;
}

.badge-light-secondary {
  background-color: #fbebcf;
  color: #EBA31D;
}

.badge-light-success {
  background-color: #d2e9d2;
  color: #53A653;
}

.badge-light-danger {
  background-color: #f9dadd;
  color: #DC3545;
}

.badge-light-info {
  background-color: #7eeefe;
  color: #01A1B9;
}

.badge-light-light {
  background-color: #b7b7c9;
  color: #52526c;
}

.badge-light-dark {
  background-color: #8490a9;
  color: #2c323f;
}

.badge-light-warning {
  background-color: #ffecc7;
  color: #FFAA05;
}

.badge-light-light {
  background-color: #e9e9ee;
}

.badge-light-primary {
  background-color: rgba(48, 126, 243, 0.1);
}

.badge-light-success {
  background-color: rgba(83, 166, 83, 0.15);
}

.badge-light-secondary {
  background-color: rgba(235, 163, 29, 0.1);
}

.badge-light-danger {
  background-color: rgba(220, 53, 69, 0.1);
}

.notification .recent-images ul {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.notification .recent-images li {
  padding-bottom: 22px;
  border: 1px dashed var(--recent-dashed-border);
  padding: 3px;
  border-radius: 2px;
}
.notification ul {
  position: relative;
}
.notification ul::before {
  position: absolute;
  content: "";
  border: 1px dashed #9B9B9B;
  opacity: 0.3;
  top: 12px;
  left: 2px;
  height: calc(100% - 12px);
}
[dir=rtl] .notification ul::before {
  left: unset;
  right: 2px;
}
.notification div[class*=activity-dot-] {
  margin-top: 3px;
  animation: round 1.3s ease-in-out infinite;
}
.notification .card .d-flex .w-100 p .badge {
  color: #fff;
  margin-left: 10px;
  font-weight: 500;
}
[dir=rtl] .notification .card .d-flex .w-100 p .badge {
  margin-left: unset;
  margin-right: 10px;
}
.notification .card .d-flex .w-100 h6 {
  margin-bottom: 5px;
  position: relative;
}
.notification .card .d-flex .w-100 h6 ~ p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-emphasis: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 260px;
}
@media (max-width: 767px) {
  .notification .card .d-flex .w-100 h6 ~ p {
    -webkit-line-clamp: unset;
    text-emphasis: unset;
    white-space: unset;
    text-overflow: unset;
    display: block;
    width: unset;
    overflow: visible;
  }
}
@keyframes round {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.notification .card .d-flex .w-100 span {
  color: #9B9B9B;
}
.notification .card .d-flex:nth-child(2) .media-body h6 .dot-notification {
  background-color: #FFAA05;
  border: 5px solid #ffde9e;
}
.notification .card .d-flex:last-child {
  margin-bottom: 0 !important;
}
.notification .date-content {
  padding: 4px 8px;
  border-radius: 5px;
}

@media only screen and (max-width: 991px) {
  .notification li {
    padding-bottom: 20px;
  }
  .notification .card .card-body .d-flex .w-100 p {
    margin: 0;
  }
  .notification .card .card-body .d-flex .w-100 h6 {
    margin-top: 10px;
  }
}
.default-dashboard div.dataTables_wrapper {
  position: unset;
}
.default-dashboard div.dataTables_wrapper .dataTables_length {
  margin-bottom: 26px;
}
.default-dashboard div.dataTables_wrapper .dataTables_length label {
  display: none !important;
}
.default-dashboard div.dataTables_wrapper div.dataTables_filter {
  position: absolute;
  top: 10px;
  right: 53px;
}
@media (max-width: 575px) {
  .default-dashboard div.dataTables_wrapper div.dataTables_filter {
    top: 44px;
    left: 20px;
    text-align: start;
  }
  [dir=rtl] .default-dashboard div.dataTables_wrapper div.dataTables_filter {
    left: unset !important;
    right: 20px !important;
  }
}
[dir=rtl] .default-dashboard div.dataTables_wrapper div.dataTables_filter {
  left: 23px;
  right: unset;
}
.default-dashboard div.dataTables_wrapper div.dataTables_filter input[type=search] {
  width: 150px !important;
  height: 34px;
  border-radius: 6px;
  border-style: solid;
}
.default-dashboard div.dataTables_wrapper table.dataTable {
  margin-top: 13px !important;
  border: none;
}
@media (max-width: 575px) {
  .default-dashboard div.dataTables_wrapper table.dataTable {
    margin-top: 42px !important;
  }
}
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even {
  background-color: transparent !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1 {
  background-color: transparent !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead {
  background-color: #F4F7F9;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th {
  border: none !important;
  text-transform: capitalize;
  font-family: Outfit;
  font-weight: 500;
  padding-top: 9px;
  padding-bottom: 9px;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:before, .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:after {
  display: none;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th {
  padding-right: 0px !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: 24px;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: unset;
  padding-right: 24px !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:last-child {
  padding-left: 24px;
}
.default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td {
  font-family: Outfit;
  font-weight: 500;
}
.default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: 24px;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: unset;
  padding-right: 24px;
}
.default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:last-child .btn {
  border-radius: 25px;
  padding: 7px 27px;
  min-width: 50px;
}
.default-dashboard .welcome-card:hover {
  transform: translateY(-5px);
}
.default-dashboard .welcome-card .card-body {
  background-image: url(../../assets/images/dashboard/bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  border-radius: 5px;
}
.default-dashboard .welcome-card h1 {
  color: #fff;
}
.default-dashboard .welcome-card p {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0;
  color: #E9E9E9;
}
.default-dashboard .welcome-card .btn {
  margin-top: 25px;
  border: 1px solid #fff;
  color: #fff;
  padding: 12px 25px;
}
.default-dashboard .total-earning:hover {
  transform: translateY(-5px);
}
.default-dashboard .total-earning .d-flex {
  align-items: center;
}
.default-dashboard .total-earning h3 {
  font-size: 16px;
  font-weight: 500;
}
.default-dashboard .total-earning h5 {
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
}
.default-dashboard .total-earning span {
  color: #9B9B9B;
  font-size: 18px;
  font-weight: 400;
  margin-top: 12px;
}
.default-dashboard .total-earning .incom-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}
.default-dashboard .news-update .d-flex {
  padding: 30px 0px 0px 0px;
}
.default-dashboard .news-update .d-flex img {
  border-radius: 10px;
}
.default-dashboard .news-update .d-flex .flex-grow-1 h5 {
  font-size: 15px;
  font-weight: 500;
  color: #363636;
  line-height: 17.64px;
  letter-spacing: 0;
}
.default-dashboard .news-update .d-flex .flex-grow-1 span {
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
  letter-spacing: 0;
}
.default-dashboard .news-update .d-flex .flex-shrink-0 button {
  border-radius: 50px;
  padding: 7px 14px;
  background-color: #F3F3F3;
  font-size: 12px;
  min-width: 85px;
  font-weight: 500;
  color: #363636;
}
.default-dashboard .product-chart .d-flex {
  align-items: center;
}
.default-dashboard .product-chart .d-flex span {
  font-weight: 400;
  font-size: 16px;
  color: #9B9B9B;
}
.default-dashboard .product-chart .d-flex h2 {
  font-weight: 500;
}
.default-dashboard .recent table thead tr th {
  padding-bottom: 8px;
  color: #363636;
  border-bottom: 1px solid #EAEAEA;
  font-weight: 500;
}
.default-dashboard .recent table thead tr th .form-check input {
  height: 15px;
}
.default-dashboard .recent table thead tr th .form-check input:checked {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.default-dashboard .recent table thead tr th .form-check input:focus {
  box-shadow: none;
}
.default-dashboard .recent table thead tr th:first-child, .default-dashboard .recent table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.default-dashboard .recent table thead tr th:first-child {
  min-width: 0px;
}
.default-dashboard .recent table tbody tr .form-check input {
  height: 15px;
}
.default-dashboard .recent table tbody tr .form-check input:checked {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.default-dashboard .recent table tbody tr .form-check input:focus {
  box-shadow: none;
}
.default-dashboard .recent table tbody tr td {
  border-bottom: 1px dashed #EAEAEA;
  font-weight: 500;
  padding: 17px 10px;
  border-top: none;
  color: rgba(44, 50, 63, 0.7215686275);
}
.default-dashboard .recent table tbody tr td:last-child {
  text-align: center;
}
@media (max-width: 991px) {
  .default-dashboard .recent table tbody tr td:last-child {
    min-width: 70px;
  }
}
.default-dashboard .recent table tbody tr td:last-child, .default-dashboard .recent table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.default-dashboard .recent table tbody tr td .d-flex img {
  margin-left: 0px;
}
.default-dashboard .recent table tbody tr td .d-flex .flex-shrink-0 img {
  margin-left: 0px;
}
.default-dashboard .recent table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
  font-weight: 500;
  color: rgba(44, 50, 63, 0.7215686275);
  transition: all 0.4s ease-in;
}
@media (max-width: 991px) {
  .default-dashboard .recent table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
    min-width: 110px;
  }
}
.default-dashboard .recent table tbody tr td h6 {
  transition: all 0.4s ease-in;
  font-weight: 500;
  color: rgba(44, 50, 63, 0.7215686275);
}
.default-dashboard .recent table tbody tr td span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.default-dashboard .recent table tbody tr .project-dot .d-flex {
  align-items: center;
  gap: 8px;
}
.default-dashboard .recent table tbody tr .project-dot .d-flex .flex-shrink-0 span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.default-dashboard .recent table tbody tr:last-child td {
  border-bottom: none;
  padding-bottom: 0;
}
.default-dashboard .sales .d-flex .flex-shrink-0 span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.default-dashboard .sales .d-flex .flex-shrink-0 span.bg-light-primary {
  background-color: rgba(48, 126, 243, 0.6) !important;
}
.default-dashboard .sales p {
  text-align: center;
  margin: 15px 0;
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.default-dashboard .sales .btn:last-child {
  background-color: #F6F6F6;
  padding: 14px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
.default-dashboard .product-chart {
  position: relative;
}
.default-dashboard .product-chart .shap-block .rounded-shap i {
  right: 43%;
  top: -44%;
}
.default-dashboard .shap-block .rounded-shap i {
  background-color: rgba(220, 53, 69, 0.4);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
}
.default-dashboard .shap-block .rounded-shap i:first-child {
  animation: ripple1 3s linear infinite;
}
.default-dashboard .shap-block .rounded-shap i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.default-dashboard .animated-bg i {
  background-color: #fff;
  box-shadow: 0 15px 30px 0 #fff;
  position: absolute;
  height: 8%;
  width: 8%;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
}
.default-dashboard .animated-bg i:first-child {
  animation: ripple1 3s linear infinite;
}
.default-dashboard .animated-bg i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
.default-dashboard .since:hover {
  transform: translateY(-5px);
}
.default-dashboard .since:hover .customer-card .dashboard-user {
  animation: tada 1s ease-out infinite;
}
.default-dashboard .customer-card {
  height: 55px;
  align-items: center;
  justify-content: space-between;
}
.default-dashboard .customer-card h3 {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.default-dashboard .customer-card h5 {
  font-size: 24px;
  font-weight: 500;
}
.default-dashboard .customer-card .dashboard-user {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.default-dashboard .customer-card .dashboard-user svg {
  width: 22px;
  height: 22px;
}
.default-dashboard .customer-card .dashboard-user::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #307ef3);
}
.default-dashboard .customer-card .dashboard-user::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #307ef3 60%, transparent 40%);
}
.default-dashboard .customer-card .dashboard-user span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #307ef3);
}
.default-dashboard .customer-card .dashboard-user span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #307ef3 60%, transparent 40%);
}
.default-dashboard .money .customer-card .dashboard-user::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #eba31d);
}
.default-dashboard .money .customer-card .dashboard-user::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #eba31d 60%, transparent 40%);
}
.default-dashboard .money .customer-card .dashboard-user span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #eba31d);
}
.default-dashboard .money .customer-card .dashboard-user span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #eba31d 60%, transparent 40%);
}
.default-dashboard .profit .customer-card .dashboard-user::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #dc3545);
}
.default-dashboard .profit .customer-card .dashboard-user::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #dc3545 60%, transparent 40%);
}
.default-dashboard .profit .customer-card .dashboard-user span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #dc3545);
}
.default-dashboard .profit .customer-card .dashboard-user span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #dc3545 60%, transparent 40%);
}
.default-dashboard .invoice-profit .customer-card .dashboard-user::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #53a653);
}
.default-dashboard .invoice-profit .customer-card .dashboard-user::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #53a653 60%, transparent 40%);
}
.default-dashboard .invoice-profit .customer-card .dashboard-user span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #53a653);
}
.default-dashboard .invoice-profit .customer-card .dashboard-user span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #53a653 60%, transparent 40%);
}
.default-dashboard .customer {
  margin-left: -7px;
}
[dir=rtl] .default-dashboard .customer {
  margin-left: unset;
  margin-right: -7px;
}
.default-dashboard .customer span {
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
}
.default-dashboard .customer span svg {
  width: 15px;
  height: 15px;
  fill: #53A653;
  stroke: #53A653;
}
.default-dashboard .review-slider .review {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.default-dashboard .review-slider .review img {
  width: 72px;
  height: 72px;
  margin: 0 auto;
}
.default-dashboard .review-slider .review .review-content {
  margin: 12px 0;
}
.default-dashboard .review-slider .review .review-content h2 {
  font-size: 16px;
  font-weight: 600;
}
.default-dashboard .review-slider .review .d-flex {
  align-items: center;
  background-color: #F3F3F3;
  height: 28px;
  padding: 8px;
  width: 92px;
  margin: 0 auto;
}
.default-dashboard .review-slider .review .d-flex h5 {
  font-size: 14px;
  font-weight: 400;
}
.default-dashboard .review-slider .review .d-flex .flex-grow-1 {
  border-left: 2px solid #EAEAEA;
}
.default-dashboard .review-slider .review .d-flex .flex-grow-1 h5 {
  margin-left: 6px;
}
[dir=rtl] .default-dashboard .review-slider .review .d-flex .flex-grow-1 h5 {
  margin-left: unset;
  margin-right: 6px;
}
.default-dashboard .review-slider .review p {
  color: #9B9B9B;
  margin-top: 15px;
}
.default-dashboard .sales.overview {
  position: relative;
}
.default-dashboard .sales.overview .shap-2 .rounded-shap i {
  right: 79.5%;
  top: 43%;
}
.default-dashboard .sales.overview .shap-2:nth-child(2) .rounded-shap i {
  right: 62.4%;
  top: 4%;
}
.default-dashboard .sales.overview .shap-2:nth-child(3) .rounded-shap i {
  right: 45.2%;
  top: 25%;
}
.default-dashboard .sales.overview .shap-2:nth-child(4) .rounded-shap i {
  right: 19.5%;
  top: 17%;
}
.default-dashboard .shap-2 .rounded-shap i {
  background-color: var(--theme-default);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
}
.default-dashboard .shap-2 .rounded-shap i:first-child {
  animation: ripple1 3s linear infinite;
}
.default-dashboard .shap-2 .rounded-shap i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.default-dashboard .animated-bg i {
  background-color: #fff;
  box-shadow: 0 15px 30px 0 #fff;
  position: absolute;
  height: 8%;
  width: 8%;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
}
.default-dashboard .animated-bg i:first-child {
  animation: ripple1 3s linear infinite;
}
.default-dashboard .animated-bg i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
.default-dashboard .active-task ul li {
  border-bottom: 1px dashed #EAEAEA;
  padding: 13px 0;
}
.default-dashboard .active-task ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.default-dashboard .active-task ul li.d-flex {
  align-items: center;
}
.default-dashboard .active-task ul li.d-flex .flex-shrink-0 .form-check .form-check-input {
  border: 1px solid #EAEAEA;
}
.default-dashboard .active-task ul li.d-flex .flex-grow-1 {
  margin-left: 10px;
}
[dir=rtl] .default-dashboard .active-task ul li.d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 10px;
}
.default-dashboard .active-task ul li.d-flex .flex-grow-1 h5 {
  font-weight: 500;
  color: #363636;
}
.default-dashboard .active-task ul li.d-flex .flex-grow-1 p {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.default-dashboard .active-task ul li .delete-option {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 5px;
  transition: all 0.4s ease-in;
}
.default-dashboard .active-task ul li .delete-option svg {
  width: 26px;
  height: 15px;
  vertical-align: bottom;
  stroke: #DC3545;
  fill: #DC3545;
}
.default-dashboard .active-task ul li .delete-option:hover {
  transform: translateY(-5px);
}
.default-dashboard .investment-card ul {
  display: flex;
  margin-top: 25px;
}
.default-dashboard .investment-card ul li {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
}
.default-dashboard .investment-card ul li h5 {
  font-weight: 500;
  color: #363636;
  margin-bottom: 5px;
}
.default-dashboard .investment-card ul li span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.default-dashboard .notification-box li {
  padding: 24px 0px;
}
.default-dashboard .notification-box li:first-child {
  padding-top: 0;
}
.default-dashboard .notification-box li:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}
.default-dashboard .notification-box li.d-flex {
  align-items: center;
  gap: 10px;
}
.default-dashboard .notification-box li.d-flex .flex-shrink-0 {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 a {
  text-decoration: none;
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 a h5 {
  color: #363636;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 a:hover h5 {
  color: var(--theme-default);
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 p {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.default-dashboard .leads .lead-status {
  display: flex;
  justify-content: space-between;
}
.default-dashboard .leads .lead-status ul li {
  padding: 5px 12px;
  margin-bottom: 5px;
  border-radius: 4px;
  min-width: 110px;
  background-color: #F6F6F6;
}
.default-dashboard .leads .lead-status ul li:first-child {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #9B9B9B;
  background-color: unset;
  padding: 0;
}
.default-dashboard .leads .lead-status ul li img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
[dir=rtl] .default-dashboard .leads .lead-status ul li img {
  margin-right: unset;
  margin-left: 8px;
}
.default-dashboard .statistics:hover {
  transform: translateY(-5px);
}
.default-dashboard .statistics .d-flex .flex-shrink-0 {
  border-right: 1px dashed #EAEAEA;
}
[dir=rtl] .default-dashboard .statistics .d-flex .flex-shrink-0 {
  border-right: unset;
  border-left: 1px dashed #EAEAEA;
}
.default-dashboard .statistics .d-flex .flex-shrink-0 h4 {
  font-weight: 500;
}
.default-dashboard .statistics .d-flex .flex-shrink-0 span {
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-default);
  margin-top: 6px;
}
.default-dashboard .statistics .d-flex .flex-shrink-0 .progress {
  width: 130px;
  height: 5px;
  margin-top: 20px;
  margin-right: 20px;
}
[dir=rtl] .default-dashboard .statistics .d-flex .flex-shrink-0 .progress {
  margin-right: unset;
  margin-left: 20px;
}
.default-dashboard .statistics .d-flex .flex-grow-1 {
  margin-left: 20px;
}
[dir=rtl] .default-dashboard .statistics .d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 20px;
}
.default-dashboard .statistics .d-flex .flex-grow-1 h4 {
  font-weight: 500;
}
.default-dashboard .statistics .d-flex .flex-grow-1 span {
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-secondary);
  margin-top: 6px;
}
.default-dashboard .statistics .d-flex .flex-grow-1 .progress {
  width: 130px;
  height: 5px;
  margin-top: 20px;
}

@media (max-width: 1870px) {
  .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 38%;
    top: -53%;
  }
}
@media (max-width: 1750px) and (min-width: 1441px) {
  .default-dashboard .leads .lead-status ul:last-child {
    display: none;
  }
  .default-dashboard .review-slider .review p {
    margin-top: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .default-dashboard .review-slider .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }
  .default-dashboard .recent table thead tr th:nth-child(4) {
    display: none;
  }
  .default-dashboard .recent table thead tr th:nth-child(5) {
    display: none;
  }
  .default-dashboard .recent table tbody tr td:nth-child(4) {
    display: none;
  }
  .default-dashboard .recent table tbody tr td:nth-child(5) {
    display: none;
  }
  .default-dashboard .sales p {
    font-size: 14px;
  }
  .default-dashboard .total-earning span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .default-dashboard .news-update .d-flex {
    padding: 22px 0px 0px 0px;
  }
  .default-dashboard .news-update .d-flex .flex-shrink-0 button {
    display: none;
  }
  .default-dashboard .news-update .d-flex .flex-grow-1 h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .default-dashboard .news-update .d-flex .flex-grow-1 span {
    display: none;
  }
}
@media (max-width: 1750px) and (min-width: 1441px) {
  .default-dashboard .statistics .d-flex .flex-shrink-0 h4 {
    font-size: 14px;
  }
  .default-dashboard .statistics .d-flex .flex-shrink-0 span {
    font-size: 13px;
  }
  .default-dashboard .statistics .d-flex .flex-shrink-0 .progress {
    width: 85px;
    margin-right: 12px;
  }
  [dir=rtl] .default-dashboard .statistics .d-flex .flex-shrink-0 .progress {
    margin-right: unset;
    margin-left: 12px;
  }
  .default-dashboard .statistics .d-flex .flex-grow-1 {
    margin-left: 12px;
  }
  [dir=rtl] .default-dashboard .statistics .d-flex .flex-grow-1 {
    margin-left: unset;
    margin-right: 12px;
  }
  .default-dashboard .statistics .d-flex .flex-grow-1 h4 {
    font-size: 14px;
  }
  .default-dashboard .statistics .d-flex .flex-grow-1 span {
    font-size: 13px;
  }
  .default-dashboard .statistics .d-flex .flex-grow-1 .progress {
    width: 85px;
  }
}
@media (max-width: 1660px) and (min-width: 1441px) {
  .default-dashboard .sales .d-flex {
    margin-top: 15px;
  }
  .default-dashboard .sales .d-flex .flex-grow-1 h5 {
    text-align: center;
  }
  .default-dashboard .sales .d-flex .flex-grow-1 h5 strong {
    display: none;
  }
  .default-dashboard .since .customer span:last-child {
    display: none;
  }
  .default-dashboard .welcome-card .d-flex .flex-grow-1 {
    text-align: center;
  }
  .default-dashboard .welcome-card .d-flex .flex-shrink-0 img {
    display: none;
  }
}
@media (max-width: 1580px) {
  .default-dashboard .notification-box li {
    padding: 10px 0;
  }
}
@media (max-width: 1550px) and (min-width: 1441px) {
  .default-dashboard .since .card-body {
    padding: 12px;
  }
  .default-dashboard .since .card-body .customer-card h3 {
    font-size: 14px;
  }
  .default-dashboard .since .card-body .customer-card h5 {
    font-size: 20px;
  }
  .default-dashboard .since .card-body .customer-card .dashboard-user {
    width: 35px;
    height: 35px;
  }
  .default-dashboard .since .card-body .customer-card .dashboard-user svg {
    width: 18px;
    height: 18px;
  }
  .default-dashboard .investment-card ul li:first-child {
    display: none;
  }
  .default-dashboard .leads .lead-status ul:nth-child(3) {
    display: none;
  }
}
@media (max-width: 1440px) and (min-width: 992px) {
  .default-dashboard .col-xl-100 {
    flex: 0 0 auto;
    width: 100%;
  }
  .default-dashboard .col-xl-50 {
    flex: 0 0 auto;
    width: 50%;
  }
  .default-dashboard .col-xl-40 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .default-dashboard .col-xl-70 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .default-dashboard .welcome-card .card-body {
    height: 296px;
  }
  .default-dashboard .welcome-card .card-body .d-flex {
    margin-top: 45px;
  }
}
@media (max-width: 1440px) {
  .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 43%;
    top: -45%;
  }
  .default-dashboard .news-update {
    margin-bottom: 0;
  }
  .default-dashboard .left-background {
    margin-bottom: 30px;
  }
  .default-dashboard .sales .d-flex {
    margin-top: 15px;
    margin-left: 75px;
  }
  [dir=rtl] .default-dashboard .sales .d-flex {
    margin-left: unset;
    margin-right: 75px;
  }
}
@media (max-width: 1313px) and (min-width: 1200px) {
  .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 38%;
    top: -54%;
  }
}
@media (max-width: 1280px) and (min-width: 1200px) {
  .default-dashboard .news-update .d-flex .flex-grow-1 span {
    display: none;
  }
  .default-dashboard .sales .d-flex {
    margin-left: 25px;
  }
  [dir=rtl] .default-dashboard .sales .d-flex {
    margin-left: unset;
    margin-right: 25px;
  }
  .default-dashboard .sales p {
    margin: 10px 0;
  }
  .default-dashboard .review-slider .review p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  .default-dashboard .notification-card .card {
    height: 150px;
    overflow: auto;
  }
}
@media (max-width: 1138px) {
  .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 38%;
    top: -54%;
  }
}
@media (max-width: 1080px) and (min-width: 992px) {
  .default-dashboard .total-earning span {
    font-size: 16px;
  }
  .default-dashboard .news-update .d-flex .flex-grow-1 span {
    display: none;
  }
  .default-dashboard .sales .d-flex {
    margin-left: 55px;
  }
  [dir=rtl] .default-dashboard .sales .d-flex {
    margin-left: unset;
    margin-right: 55px;
  }
  .default-dashboard .welcome-card h1 {
    font-size: 28px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .default-dashboard .total-earning span {
    font-size: 14px;
  }
  .default-dashboard .news-update .d-flex .flex-grow-1 span {
    display: none;
  }
  .default-dashboard .news-update .d-flex .flex-shrink-0 button {
    display: none;
  }
  .default-dashboard .sales .d-flex {
    margin-left: 28px;
  }
  [dir=rtl] .default-dashboard .sales .d-flex {
    margin-left: unset;
    margin-right: 28px;
  }
}
@media (max-width: 767px) {
  .default-dashboard .news-update {
    margin-bottom: 30px;
  }
  .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 42%;
    top: -45%;
  }
  .default-dashboard .recent table thead tr th {
    min-width: 120px;
  }
  .default-dashboard .recent table thead tr th:first-child {
    min-width: none;
  }
  .default-dashboard .leads .lead-status ul:nth-child(3) {
    display: none;
  }
  .default-dashboard .leads .lead-status ul:last-child {
    display: none;
  }
}
@media (max-width: 588px) {
  .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 38%;
    top: -53%;
  }
}
@media (max-width: 576px) {
  .default-dashboard .news-update .d-flex .flex-grow-1 span {
    display: none;
  }
  .default-dashboard .news-update .d-flex .flex-shrink-0 button {
    display: none;
  }
  .default-dashboard .sales .d-flex {
    margin-left: 30px;
  }
  [dir=rtl] .default-dashboard .sales .d-flex {
    margin-left: unset;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .notification li {
    padding-bottom: 15px;
  }
  .media-dropdown {
    display: block;
  }
  .media-dropdown .flex-grow-1 {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .default-dashboard .welcome-card .d-flex .flex-grow-1 {
    text-align: center;
  }
  .default-dashboard .welcome-card .d-flex .flex-shrink-0 img {
    display: none;
  }
  .default-dashboard .total-earning span {
    font-size: 16px;
  }
  .default-dashboard .news-update .d-flex {
    padding: 16px 0px 0px 0px;
  }
  .default-dashboard .news-update .d-flex img {
    width: 55px;
    height: 55px;
  }
  .default-dashboard .news-update .d-flex .flex-grow-1 a h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .default-dashboard .product-chart .d-flex span {
    display: none;
  }
  .default-dashboard .sales .d-flex {
    margin-left: 15px;
    text-align: center;
  }
  [dir=rtl] .default-dashboard .sales .d-flex {
    margin-left: unset;
    margin-right: 15px;
  }
  .default-dashboard .sales.overview .shap-2 .rounded-shap i {
    display: none;
  }
  .default-dashboard .notification-box li.d-flex .flex-grow-1 p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .default-dashboard .statistics .d-flex .flex-shrink-0 {
    border: none;
  }
  .default-dashboard .statistics .d-flex .flex-shrink-0 .progress {
    width: 180px;
    margin-right: 0;
  }
  .default-dashboard .statistics .d-flex .flex-grow-1 {
    display: none;
  }
}
@media (max-width: 360px) {
  .default-dashboard .leads .lead-status ul:nth-child(2) {
    display: none;
  }
  .default-dashboard .leads .lead-status ul:nth-child(4) {
    display: none;
  }
  .default-dashboard .total-earning span {
    display: none;
  }
  .default-dashboard .sales .d-flex {
    margin-left: 0;
  }
}
/**=====================
    3.11 General widget CSS Start
==========================**/
.balance-box {
  text-align: center;
  background-position: right;
  background-size: cover;
}
.balance-box .balance-img {
  display: inline-block;
  padding: 5px;
  border: 2px solid var(--chart-border);
  border-left-color: #53A653;
  border-bottom-color: #53A653;
  border-radius: 100%;
  position: relative;
  margin-bottom: 15px;
}
.balance-box .balance-img img {
  background-color: var(--balance-profie-bg);
  border-radius: 100%;
  margin-left: 0px;
}
.balance-box .balance-img .edit-icon {
  width: 26px;
  height: 26px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--white);
  background-color: #DFDFF4;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}
.balance-box .balance-img .edit-icon svg {
  width: 11px;
  height: 11px;
  fill: #307EF3;
}

.balance-profile ul {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 16px;
}
@media (max-width: 1660px) {
  .balance-profile ul {
    gap: 25px;
  }
}
.balance-profile ul li {
  position: relative;
}
.balance-profile ul li + li::before {
  position: absolute;
  content: "";
  left: -25px;
  top: 50%;
  height: 40px;
  transform: translateY(-50%);
  border: 1px dashed var(--recent-dashed-border);
}
[dir=rtl] .balance-profile ul li + li::before {
  left: unset;
  right: -25px;
}
@media (max-width: 1660px) {
  .balance-profile ul li + li::before {
    left: -16px;
  }
  [dir=rtl] .balance-profile ul li + li::before {
    right: -16px;
  }
}

.balance-box .card-body {
  min-height: 360px;
}
.balance-box .balance-profile span {
  font-weight: 600;
}
.balance-box .balance-profile h5 {
  font-size: 22px;
  font-weight: 700;
}

.general-widget .web-card:hover {
  transform: translateY(-5px);
}
.general-widget .web-card:hover .d-flex .header-top .mobile-app {
  animation: tada 1s ease-out infinite;
}
.general-widget .web-card .d-flex {
  justify-content: space-between;
}
.general-widget .web-card .d-flex .header-top .mobile-app {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.general-widget .web-card .d-flex .header-top .mobile-app svg {
  width: 22px;
  height: 22px;
}
.general-widget .web-card .d-flex .header-top .mobile-app::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #307ef3);
}
.general-widget .web-card .d-flex .header-top .mobile-app::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #307ef3 60%, transparent 40%);
}
.general-widget .web-card .d-flex .header-top .mobile-app span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #307ef3);
}
.general-widget .web-card .d-flex .header-top .mobile-app span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #307ef3 60%, transparent 40%);
}
.general-widget .web-card .d-flex .header-top .flex-grow-1 {
  margin-left: 17px;
}
[dir=rtl] .general-widget .web-card .d-flex .header-top .flex-grow-1 {
  margin-left: unset;
  margin-right: 17px;
}
.general-widget .web-card .d-flex .header-top .flex-grow-1 span {
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .web-card .d-flex.nft-card .header-top .mobile-app::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #53a653);
}
.general-widget .web-card .d-flex.nft-card .header-top .mobile-app::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #53a653 60%, transparent 40%);
}
.general-widget .web-card .d-flex.nft-card .header-top .mobile-app span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #53a653);
}
.general-widget .web-card .d-flex.nft-card .header-top .mobile-app span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #53a653 60%, transparent 40%);
}
.general-widget .web-card .d-flex.twitter-card .header-top .mobile-app::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #dc3545);
}
.general-widget .web-card .d-flex.twitter-card .header-top .mobile-app::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #dc3545 60%, transparent 40%);
}
.general-widget .web-card .d-flex.twitter-card .header-top .mobile-app span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #dc3545);
}
.general-widget .web-card .d-flex.twitter-card .header-top .mobile-app span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #dc3545 60%, transparent 40%);
}
.general-widget .web-card .comment ul {
  display: flex;
  margin-top: 12px;
}
.general-widget .web-card .comment ul li {
  background-color: #F6F6F6;
  border-radius: 25px;
  padding: 6px 15px;
  margin-right: 8px;
}
[dir=rtl] .general-widget .web-card .comment ul li {
  margin-left: 8px;
  margin-right: unset;
}
.general-widget .web-card .comment ul li:last-child {
  margin-right: unset;
}
[dir=rtl] .general-widget .web-card .comment ul li:last-child {
  margin-left: unset;
}
.general-widget .web-card .comment ul li svg {
  width: 20px;
  height: 20px;
  margin-bottom: -8px;
}
[dir=rtl] .general-widget .web-card .comment ul li svg {
  margin-left: 5px;
}
.general-widget .web-card .comment ul li span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .web-card .flex-shrink-0 {
  margin-top: 25px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}
[dir=rtl] .general-widget .web-card .flex-shrink-0 {
  margin-right: 8px;
  margin-left: unset;
}
.general-widget .web-card .flex-shrink-0 .user-details {
  display: flex;
  align-items: end;
  gap: 15px;
  justify-content: space-between;
}
@media (max-width: 1399px) {
  .general-widget .web-card .flex-shrink-0 .user-details {
    gap: 6px;
  }
}
@media (max-width: 575px) {
  .general-widget .web-card .flex-shrink-0 .user-details {
    flex-wrap: wrap;
  }
}
.general-widget .web-card .flex-shrink-0 .user-details ul {
  margin-left: -10px;
}
[dir=rtl] .general-widget .web-card .flex-shrink-0 .user-details ul {
  margin-right: -10px;
  margin-left: unset;
}
.general-widget .web-card .flex-shrink-0 .user-details ul li img {
  border-radius: 10px;
}
.general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
  margin-left: -12%;
}
[dir=rtl] .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
  margin-right: -12%;
  margin-left: unset;
}
.general-widget .web-card .flex-shrink-0 .progress-value span {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
  margin-bottom: 5px;
}
.general-widget .web-card .flex-shrink-0 .progress-value h6 {
  font-size: 13px;
  color: #9B9B9B;
}
.general-widget .web-card .flex-shrink-0 .progress-value .progress {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  width: 220px;
  height: 8px;
}
.general-widget .web-card .flex-shrink-0 .progress-value .progress .progress-bar {
  border-radius: 50px;
}
.general-widget .categories-chart .categories-sales {
  margin: 40px 0px;
  display: grid;
}
.general-widget .categories-chart .categories-sales .d-flex .flex-shrink-0 span {
  width: 11px;
  height: 11px;
  border-radius: 15px;
}
.general-widget .categories-chart .categories-sales .d-flex .flex-grow-1 h6 {
  font-weight: 500;
  font-size: 14px;
  color: #9B9B9B;
}
.general-widget .categories-chart .categories-sales .d-flex h5 {
  font-weight: 500;
  font-size: 14px;
  color: #9B9B9B;
}
.general-widget .categories-chart .total-earn {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}
[dir=rtl] .general-widget .categories-chart .total-earn {
  margin-right: 20px;
  margin-left: unset;
}
.general-widget .categories-chart .total-earn h6 {
  color: #9B9B9B;
}
.general-widget .categories-chart .earned {
  padding: 0;
  margin-top: -118px;
}
.general-widget .budget-card .budget-chart {
  margin-top: -32px;
}
.general-widget .budget-card .budget-spent {
  background-color: #F6F6F6;
  border-radius: 5px;
  padding: 13px 25px;
  margin-bottom: 10px;
}
.general-widget .budget-card .budget-spent span {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .budget-card .budget-spent h2 {
  font-weight: 500;
}
.general-widget .budget-card .d-flex {
  align-items: center;
  margin-top: 10px;
}
.general-widget .budget-card .d-flex .design-score {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.general-widget .budget-card .d-flex .design-score svg {
  width: 20px;
  height: 20px;
}
.general-widget .budget-card .d-flex .flex-grow-1 {
  margin-left: 10px;
}
[dir=rtl] .general-widget .budget-card .d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 10px;
}
.general-widget .budget-card .d-flex .flex-grow-1 h6 {
  font-weight: 500;
  margin-bottom: 3px;
}
.general-widget .budget-card .d-flex .flex-grow-1 span {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .budget-card .d-flex .flex-shrink-0 .design-menu {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(155, 155, 155, 0.4);
}
.general-widget .budget-card .d-flex .flex-shrink-0 .design-menu i {
  color: #9B9B9B;
}
.general-widget .order-overview .d-flex {
  align-items: center;
}
.general-widget .order-overview .d-flex h2 {
  font-weight: 500;
}
.general-widget .order-overview .d-flex h6 {
  font-weight: 400;
  font-size: 16px;
  color: #9B9B9B;
}
.general-widget .order-overview .total-revenue {
  margin-top: 20px;
}
.general-widget .order-overview .total-revenue h5 {
  font-weight: 500;
  font-size: 14px;
}
.general-widget .order-overview .total-revenue span {
  font-weight: 400;
  font-size: 13px;
  color: #9B9B9B;
}
.general-widget .order-overview .total-revenue .progress {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  height: 10px;
  margin-top: 10px;
}
.general-widget .order-overview .total-revenue .progress .progress-bar {
  border-radius: 50px;
}
.general-widget .monthly-chart:hover {
  transform: translateY(-5px);
}
.general-widget .monthly-chart .d-flex {
  align-items: center;
}
.general-widget .monthly-chart .d-flex .monthly h5 {
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .monthly-chart .d-flex .monthly h3 {
  color: #363636;
  font-weight: 500;
}
.general-widget .monthly-chart .d-flex .growth {
  margin-top: -85px;
}
.general-widget .invoices:hover {
  transform: translateY(-5px);
}
.general-widget .invoices .d-flex {
  text-align: center;
}
.general-widget .invoices .d-flex .flex-shrink-0 {
  border-right: 1px dashed #EAEAEA;
}
[dir=rtl] .general-widget .invoices .d-flex .flex-shrink-0 {
  border-right: unset;
  border-left: 1px dashed #EAEAEA;
}
.general-widget .invoices .d-flex .flex-shrink-0 h5 {
  font-weight: 500;
  color: #9B9B9B;
}
.general-widget .invoices .d-flex .flex-shrink-0 h3 {
  font-weight: 500;
  color: #363636;
  margin-top: 6px;
  width: 110px !important;
}
.general-widget .invoices .d-flex .flex-grow-1 h5 {
  font-weight: 500;
  color: #9B9B9B;
}
.general-widget .invoices .d-flex .flex-grow-1 h3 {
  font-weight: 500;
  color: #363636;
  margin-top: 6px;
}
.general-widget .since:hover {
  transform: translateY(-5px);
}
.general-widget .since:hover .customer-card .dashboard-user {
  animation: tada 1s ease-out infinite;
}
.general-widget .customer-card {
  height: 55px;
  align-items: center;
  justify-content: space-between;
}
.general-widget .customer-card h3 {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .customer-card h5 {
  font-size: 24px;
  font-weight: 500;
}
.general-widget .customer-card .dashboard-user {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.general-widget .customer-card .dashboard-user svg {
  width: 22px;
  height: 22px;
}
.general-widget .customer-card .dashboard-user::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #307ef3);
}
.general-widget .customer-card .dashboard-user::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #307ef3 60%, transparent 40%);
}
.general-widget .customer-card .dashboard-user span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #307ef3);
}
.general-widget .customer-card .dashboard-user span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #307ef3 60%, transparent 40%);
}
.general-widget .money .customer-card .dashboard-user::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #eba31d);
}
.general-widget .money .customer-card .dashboard-user::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #eba31d 60%, transparent 40%);
}
.general-widget .money .customer-card .dashboard-user span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #eba31d);
}
.general-widget .money .customer-card .dashboard-user span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #eba31d 60%, transparent 40%);
}
.general-widget .customer {
  margin-left: -7px;
  margin-top: 13px;
}
[dir=rtl] .general-widget .customer {
  margin-left: unset;
  margin-right: -7px;
}
.general-widget .customer span {
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .customer span svg {
  width: 15px;
  height: 15px;
  fill: #53A653;
  stroke: #53A653;
}
.general-widget .discount-card .special-offer .d-flex {
  align-items: center;
  justify-content: space-between;
  margin: 22px 0;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 {
  margin-left: 8px;
}
[dir=rtl] .general-widget .discount-card .special-offer .d-flex .flex-shrink-0 {
  margin-right: 8px;
  margin-left: unset;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 h3 {
  font-weight: 500;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 h5 {
  font-weight: 500;
  margin-top: 5px;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 h5 del {
  font-weight: 400;
  color: #9B9B9B;
  margin-left: 10px;
}
[dir=rtl] .general-widget .discount-card .special-offer .d-flex .flex-shrink-0 h5 del {
  margin-right: 10px;
  margin-left: unset;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 h6 {
  margin-top: 18px;
  padding: 3px 7px;
  margin-right: 156px;
  color: #9B9B9B;
}
[dir=rtl] .general-widget .discount-card .special-offer .d-flex .flex-shrink-0 h6 {
  margin-right: unset;
  margin-left: 80px;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 p {
  font-size: 22px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
}
.general-widget .discount-card .special-offer .d-flex .flex-shrink-0 p span {
  color: #DC3545;
  font-weight: 600;
}
.general-widget .discount-card .special-offer .d-flex ul {
  display: flex;
  gap: 12px;
}
.general-widget .discount-card .special-offer .d-flex ul li {
  background-color: #F3F3F3;
  padding: 8px 12px;
  text-align: center;
  min-width: 58px;
}
.general-widget .discount-card .special-offer .d-flex ul li h5 {
  color: var(--theme-default) !important;
  font-weight: 600;
}
.general-widget .discount-card .special-offer .d-flex ul li span {
  font-weight: 500;
  font-size: 12px;
}
.general-widget .discount-card .special-offer .d-flex img {
  z-index: 1;
  width: 110px;
}
.general-widget .discount-card .owl-theme .owl-nav.disabled + .owl-dots {
  margin-bottom: -10px;
  margin-top: -15px;
}
.general-widget .product-slider .shap-block .rounded-shap i {
  right: 12%;
  top: -55%;
}
.general-widget .shap-block .rounded-shap i {
  background-color: rgba(48, 126, 243, 0.4);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
  z-index: 0;
}
.general-widget .shap-block .rounded-shap i:first-child {
  animation: ripple1 3s linear infinite;
}
.general-widget .shap-block .rounded-shap i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.general-widget .shap-block .rounded-shap i:nth-child(3) {
  animation: ripple3 3s linear infinite;
}
.general-widget .animated-bg i {
  background-color: #fff;
  box-shadow: 0 15px 30px 0 #fff;
  position: absolute;
  height: 8%;
  width: 8%;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
}
.general-widget .animated-bg i:first-child {
  animation: ripple1 3s linear infinite;
}
.general-widget .animated-bg i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.general-widget .animated-bg i:nth-child(3) {
  animation: ripple3 3s linear infinite;
}
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2.5);
  }
}
.general-widget .total-earning:hover {
  transform: translateY(-5px);
}
.general-widget .total-earning .d-flex {
  align-items: center;
}
.general-widget .total-earning h3 {
  font-size: 16px;
  font-weight: 500;
}
.general-widget .total-earning h5 {
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
}
.general-widget .total-earning span {
  color: #9B9B9B;
  font-size: 18px;
  font-weight: 400;
  margin-top: 12px;
}
.general-widget .total-earning .incom-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}
.general-widget .total-earning span {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.general-widget .active-task ul li {
  border-bottom: 1px dashed #EAEAEA;
  padding: 9px 0;
}
.general-widget .active-task ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.general-widget .active-task ul li.d-flex {
  align-items: center;
}
.general-widget .active-task ul li.d-flex .flex-shrink-0 .form-check .form-check-input {
  border: 1px solid #EAEAEA;
}
.general-widget .active-task ul li.d-flex .flex-grow-1 {
  margin-left: 10px;
}
[dir=rtl] .general-widget .active-task ul li.d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 10px;
}
.general-widget .active-task ul li.d-flex .flex-grow-1 h5 {
  font-weight: 500;
  color: #363636;
}
.general-widget .active-task ul li.d-flex .flex-grow-1 p {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.general-widget .active-task ul li .delete-option {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 5px;
  transition: all 0.4s ease-in;
}
.general-widget .active-task ul li .delete-option svg {
  width: 26px;
  height: 15px;
  vertical-align: bottom;
  stroke: #DC3545;
  fill: #DC3545;
}
.general-widget .active-task ul li .delete-option:hover {
  transform: translateY(-5px);
}
.general-widget .online-order:hover {
  transform: translateY(-5px);
}
.general-widget .online-order:hover .d-flex .order {
  animation: tada 1s ease-out infinite;
}
.general-widget .online-order .d-flex {
  justify-content: space-between;
}
.general-widget .online-order .d-flex .order {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.general-widget .online-order .d-flex .order svg {
  width: 28px;
  height: 28px;
}
.general-widget .online-order .d-flex .order::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #307ef3);
}
.general-widget .online-order .d-flex .order::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #307ef3 60%, transparent 40%);
}
.general-widget .online-order .d-flex .order span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #307ef3);
}
.general-widget .online-order .d-flex .order span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #307ef3 60%, transparent 40%);
}
.general-widget .online-order .d-flex .arrow-chart {
  display: flex;
  align-items: end;
}
.general-widget .online-order .d-flex .arrow-chart svg {
  width: 20px;
  height: 20px;
}
.general-widget .online-order .d-flex .arrow-chart span {
  font-size: 16px;
  font-weight: 500;
}
.general-widget .online-order .online {
  margin-top: 18px;
}
.general-widget .online-order .online span {
  font-size: 16px;
  font-weight: 400;
  color: #9B9B9B;
}
.general-widget .online-order .online h2 {
  font-weight: 500;
}
.general-widget .online-order .offline-chart {
  margin-top: -75px;
}
.general-widget .online-order .revenue {
  margin-top: -38px;
}
.general-widget .online-order .user-details {
  display: flex;
  align-items: end;
  gap: 15px;
  justify-content: space-between;
}
@media (max-width: 1399px) {
  .general-widget .online-order .user-details {
    gap: 6px;
  }
}
@media (max-width: 575px) {
  .general-widget .online-order .user-details {
    flex-wrap: wrap;
  }
}
.general-widget .online-order .user-details ul {
  margin-left: -10px;
}
[dir=rtl] .general-widget .online-order .user-details ul {
  margin-right: -10px;
  margin-left: unset;
}
.general-widget .online-order .user-details ul li img {
  border: none;
}
.general-widget .online-order .user-details.customers ul li + li {
  margin-left: -18%;
}
[dir=rtl] .general-widget .online-order .user-details.customers ul li + li {
  margin-right: -18%;
  margin-left: unset;
}
.general-widget .online-order .progress {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  height: 10px;
  margin-top: 10px;
}
.general-widget .online-order .progress .progress-bar {
  border-radius: 50px;
}
.general-widget .online-order .offline-order .d-flex .order::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), #eba31d);
}
.general-widget .online-order .offline-order .d-flex .order::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #eba31d 60%, transparent 40%);
}
.general-widget .online-order .offline-order .d-flex .order span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: linear-gradient(360deg, rgba(255, 0, 0, 0), #eba31d);
}
.general-widget .online-order .offline-order .d-flex .order span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #eba31d 60%, transparent 40%);
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}
.header-top a i {
  font-size: 11px;
  margin-bottom: -2px;
}
.header-top a.btn {
  padding: 8px 12px;
}
@media (max-width: 575px) {
  .header-top a.btn {
    padding: 5px;
  }
}
.header-top a.btn.badge-light-primary:hover {
  background-color: var(--theme-default);
}

@media (max-width: 1850px) {
  .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: -20%;
  }
  [dir=rtl] .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: unset;
    margin-right: -20%;
  }
  .general-widget .web-card .flex-shrink-0 .progress-value .progress {
    width: 120px;
  }
}
@media (max-width: 1810px) and (min-width: 1441px) {
  .general-widget .discount-card .special-offer .d-flex img {
    display: none;
  }
}
@media (max-width: 1750px) {
  .general-widget .web-card .comment ul li {
    padding: 6px 6px;
  }
}
@media (max-width: 1660px) and (min-width: 1440px) {
  .general-widget .categories-chart .categories-sales .d-flex h5 {
    display: none;
  }
}
@media (max-width: 1550px) {
  .general-widget .web-card .d-flex .header-top .flex-grow-1 {
    margin-left: 10px;
  }
  [dir=rtl] .general-widget .web-card .d-flex .header-top .flex-grow-1 {
    margin-left: unset;
    margin-right: 10px;
  }
  .general-widget .web-card .d-flex .header-top .flex-grow-1 h4 {
    font-size: 16px;
  }
}
@media (max-width: 1440px) and (min-width: 768px) {
  .offline-card {
    display: none;
  }
}
@media (max-width: 1440px) and (min-width: 992px) {
  .general-widget .col-xl-100 {
    flex: 0 0 auto;
    width: 100%;
  }
  .general-widget .col-xl-50 {
    flex: 0 0 auto;
    width: 50%;
  }
  .general-widget .col-xl-33 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .general-widget .col-xl-30 {
    flex: 0 0 auto;
    width: 25%;
  }
  .general-widget .col-xl-40 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .general-widget .col-xl-70 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .general-widget .budget-card .budget-spent {
    display: none;
  }
  .general-widget .invoices .d-flex .flex-shrink-0 h3 {
    width: 70px !important;
  }
}
@media (max-width: 480px) {
  .general-widget .discount-card .special-offer .d-flex img {
    display: none;
  }
  .general-widget .discount-card .special-offer .d-flex .flex-shrink-0 p {
    font-size: 16px;
  }
  .general-widget .discount-card .special-offer .d-flex .flex-shrink-0 ul li:last-child {
    display: none;
  }
}
@media (max-width: 365px) {
  .general-widget .web-card .comment ul li {
    padding: 4px 4px;
  }
  .general-widget .web-card .comment ul li svg {
    width: 17px;
    height: 17px;
  }
  .general-widget .web-card .comment ul li span {
    font-size: 12px;
  }
  .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: -26%;
  }
  [dir=rtl] .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: unset;
    margin-right: -26%;
  }
  .general-widget .categories-chart .categories-sales .d-flex h5 {
    display: none;
  }
}
/**=====================
    3.11 General widget CSS Ends
==========================**/
/**=====================
  3.14 Ecommerce CSS Start
==========================**/
/**====== Product Start ======**/
.toggle-data {
  cursor: pointer;
}

.filter-block ul li {
  padding-top: 15px;
  color: #9B9B9B;
}
@media (max-width: 1500px) {
  .filter-block ul li {
    padding-top: 10px;
  }
}

.collection-filter-block svg {
  width: 50px;
  height: 25px;
  stroke: #307EF3;
  fill: rgba(48, 126, 243, 0.0901960784);
  margin-right: 10px;
}
.collection-filter-block h5 {
  margin-bottom: 0;
  font-size: 16px;
}
.collection-filter-block p {
  font-size: 13px;
}
.collection-filter-block .flex-grow-1 {
  opacity: 0.5;
}
.collection-filter-block li + li {
  margin-top: 16px;
}
.collection-filter-block ul.pro-services li svg {
  width: auto;
}

.qty-box .btn {
  background-color: transparent !important;
  border-color: #EAEAEA !important;
  padding: 8px 12px;
}
.qty-box .btn i {
  color: #9B9B9B;
}
.qty-box .btn svg {
  width: 14px !important;
  stroke: #2c323f;
}
.qty-box span {
  padding: 0 4px;
}
.qty-box .input-group {
  background-color: rgba(44, 50, 63, 0.1);
}
.qty-box input {
  background-color: rgba(44, 50, 63, 0.1);
  color: #2c323f;
  max-width: 44px;
  padding: 0;
  text-align: center;
  border: none;
  max-height: 37px;
}

.product-social li a {
  color: #9B9B9B;
  font-size: 15px;
  border: 1px solid #cccccc;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 2.3;
  transition: all 0.3s ease;
}
.product-social li a:hover {
  border-color: #307EF3;
  color: #307EF3;
  background-color: rgba(48, 126, 243, 0.1);
  transition: all 0.3s ease;
}
.product-social li:nth-child(n+2) {
  margin-left: 10px;
}
[dir=rtl] .product-social li:nth-child(n+2) {
  margin-left: unset;
  margin-right: 10px;
}

.product-sidebar .filter-section .card .card-header {
  padding: 16px 30px;
  border-radius: 5px;
}
.product-sidebar .filter-section .card .card-header h3 {
  position: relative;
}
.product-sidebar .filter-section .card .card-header h3 .pull-right i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 10px;
  width: 100%;
  height: 10px;
  text-align: right;
  cursor: pointer;
}
[dir=rtl] .product-sidebar .filter-section .card .card-header h3 .pull-right i {
  left: unset;
  right: 0;
  text-align: left;
}

.product-wrapper .product-sidebar .filter-section .card .left-filter {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: 0.3s;
  min-width: 320px;
}
.product-wrapper .product-sidebar .filter-section .card .left-filter .product-filter .irs-with-grid {
  margin-bottom: 15px;
}
.product-wrapper .product-grid .product-wrapper-grid {
  margin-left: 0;
  transition: 0.3s;
}
.product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
  z-index: 3;
  opacity: 1;
  visibility: visible;
  position: absolute;
  width: 100%;
  background-color: white;
  top: 53px;
  height: auto;
}

.d-none-productlist {
  display: none;
}
.d-none-productlist svg {
  vertical-align: middle;
  cursor: pointer;
}

.product-wrapper-grid.list-view .product-box {
  display: flex;
  align-items: center;
}
.product-wrapper-grid.list-view .product-box .product-img {
  width: 20%;
}
.product-wrapper-grid.list-view .product-box .product-details {
  text-align: left;
}

.slider-product {
  padding: 15px 0;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  margin-bottom: 15px;
}

.products-total {
  display: flex;
  align-items: center;
}

.grid-options {
  height: 33px;
  margin-left: 10px;
}
.grid-options ul li a {
  position: relative;
}
.grid-options ul li a .line-grid {
  position: absolute;
  width: 4px;
  height: 15px;
  top: 9px;
}
.grid-options ul li a .line-grid-1 {
  left: 12px;
}
.grid-options ul li a .line-grid-2 {
  left: 18px;
}
.grid-options ul li a .line-grid-3 {
  left: 36px;
}
.grid-options ul li a .line-grid-4 {
  left: 42px;
}
.grid-options ul li a .line-grid-5 {
  left: 48px;
}
.grid-options ul li a .line-grid-6 {
  left: 66px;
}
.grid-options ul li a .line-grid-7 {
  left: 72px;
}
.grid-options ul li a .line-grid-8 {
  left: 78px;
}
.grid-options ul li a .line-grid-9 {
  left: 84px;
}
.grid-options ul li a .line-grid-10 {
  left: 103px;
}
.grid-options ul li a .line-grid-11 {
  left: 109px;
}
.grid-options ul li a .line-grid-12 {
  left: 115px;
}
.grid-options ul li a .line-grid-13 {
  left: 121px;
}
.grid-options ul li a .line-grid-14 {
  left: 127px;
}
.grid-options ul li a .line-grid-15 {
  left: 133px;
}

.square-product-setting {
  height: 36px;
  vertical-align: middle;
}
.square-product-setting a {
  color: #363636;
}
.square-product-setting .icon-grid {
  padding: 7px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.square-product-setting .icon-grid svg {
  width: 20px;
  height: 20px;
}

.product-filter .banner-product {
  margin-top: 15px;
}
.product-filter h3 {
  margin-bottom: 15px;
}
.product-filter .color-selector {
  line-height: 0.9;
}
.product-filter .color-selector ul li {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #444;
  border-radius: 100%;
  cursor: pointer;
}
.product-filter .color-selector ul li.white {
  background-color: #fff;
}
.product-filter .color-selector ul li.gray {
  background-color: #9B9B9B;
}
.product-filter .color-selector ul li.black {
  background-color: #000;
}
.product-filter .color-selector ul li.orange {
  background-color: #ffb17a;
}
.product-filter .color-selector ul li.green {
  background-color: #6fb866;
}
.product-filter .color-selector ul li.pink {
  background-color: pink;
}
.product-filter .color-selector ul li.yellow {
  background-color: #f2f896;
}
.product-filter .color-selector ul li.blue {
  background-color: #63b4f2;
}
.product-filter .color-selector ul li.red {
  background-color: #ff647f;
}
.product-filter.new-products {
  margin-top: 20px;
}
.product-filter.new-products button {
  width: auto;
}
.product-filter.new-products .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -46px;
  right: 0;
}
.product-filter.new-products .owl-theme .owl-nav button:focus {
  outline: transparent;
}
.product-filter.new-products .owl-theme .owl-nav button span {
  font-size: 20px;
}
.product-filter.new-products .owl-theme .owl-nav button span:focus {
  outline-color: transparent;
}
.product-filter.new-products .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
  color: inherit;
}
.product-filter.new-products .owl-theme .owl-item .item .product-box .product-details {
  padding: 25px 0;
}
.product-filter.new-products .owl-theme .owl-item .item .product-box + .product-box {
  margin-top: 15px;
}

.select2-drpdwn-product .form-control {
  border: none;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-bottom: 10px;
  height: 36px;
}

.feature-products form .form-group {
  position: relative;
}
.feature-products form .form-group input {
  margin-bottom: 15px;
  border: none;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  height: 50px;
  padding-left: 30px;
}
.feature-products form .form-group i {
  position: absolute;
  top: 18px;
  right: 30px;
  color: #9B9B9B;
}

.invoice-table tr td label {
  font-weight: 600;
}

.product-box {
  border-radius: 10px;
  overflow: hidden;
}
.product-box .product-details {
  padding: 15px 25px;
}
.product-box .product-details > a {
  color: #363636;
}
.product-box .product-details p {
  margin-bottom: 0;
  opacity: 0.8;
  font-weight: 500;
}
.product-box .product-details h3 {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 20px;
}
.product-box .product-details h3 {
  color: #363636;
}
.product-box .product-details span {
  color: #9B9B9B;
}
.product-box .product-details .rating i {
  font-size: 16px;
  letter-spacing: 3px;
  color: #ffa800;
}
.product-box .modal .modal-header .product-box .product-details {
  padding: 25px 0;
}
.product-box .modal .modal-header .product-box .product-details h3 {
  text-transform: capitalize;
}
.product-box .modal .modal-header .product-box .product-details .product-price {
  font-size: 22px;
  margin-bottom: 10px;
}
.product-box .modal .modal-header .product-box .product-details .product-view {
  padding: 20px 0;
  border-top: 1px dotted #59667a;
  border-bottom: 1px dotted #59667a;
}
.product-box .modal .modal-header .product-box .product-details .product-size {
  margin: 20px 0;
}
.product-box .modal .modal-header .product-box .product-details .product-size ul li {
  display: inline-block;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset {
  margin-bottom: 20px;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group {
  width: 35%;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group .btn {
  padding: 5px 12px;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group .btn-primary {
  background-color: #59667a !important;
  border: 1px solid #59667a !important;
}
.product-box .modal .modal-header .product-box .product-img {
  width: 50%;
}
.product-box .modal .modal-header .btn-close {
  position: absolute;
  right: 15px;
  top: 10px;
}
.product-box .product-img {
  position: relative;
}
.product-box .product-img .product-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  border-radius: 100%;
  transform: scale(0);
  transition: all 0.3s ease;
}
.product-box .product-img .product-hover ul li {
  display: inline-block;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  padding: 10px 13px;
  background-color: #fff;
  font-size: 18px;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  cursor: pointer;
}
.product-box .product-img .product-hover ul li i {
  color: #59667a;
  font-size: 18px;
}
.product-box .product-img .product-hover ul li:hover {
  background-color: #307EF3;
  transition: all 0.3s ease;
}
.product-box .product-img .product-hover ul li:hover .btn,
.product-box .product-img .product-hover ul li:hover i {
  color: #fff;
}
.product-box:hover .product-hover {
  opacity: 1;
  border-radius: 0%;
  transform: scale(1);
  transition: all 0.3s ease;
}

.product-page-main {
  padding: 30px;
}
.product-page-main .owl-item .item {
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  background-color: #f8f8f8;
}
.product-page-main .owl-item.current .item {
  border: 1px solid #307EF3;
}
.product-page-main .product-slider {
  margin-bottom: 20px;
}
@media (min-width: 1400px) {
  .product-page-main .product-slider img {
    height: 520px;
    object-fit: cover;
  }
}
@media (min-width: 1500px) {
  .product-page-main .product-slider img {
    height: 400px;
    object-fit: cover;
  }
}
.product-page-main .product-color {
  margin-top: 10px;
}
.product-page-main .d-flex .rating i {
  font-size: 16px;
  letter-spacing: 3px;
  color: #ffa800;
}

.product-page-details h4 {
  color: #363636;
  text-transform: uppercase;
  font-size: 18px;
}
.product-page-details span {
  padding-left: 15px;
}

.br-theme-fontawesome-stars .br-widget a,
.br-theme-fontawesome-stars .br-widget a.br-selected:after,
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #ffa800 !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.product-price {
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
  color: #307EF3;
}
.product-price del {
  color: rgba(44, 50, 63, 0.4);
  padding-left: 10px;
  font-size: 80%;
}

.product-color li {
  display: inline-block;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  margin: 0 2px;
}

.product-page-main p {
  font-size: 15px;
}
.product-page-main .nav-link {
  text-transform: uppercase;
  font-weight: 500;
}

/**====== Product  Ends ======**/
.payment-opt li {
  display: inline-block;
}
.payment-opt li img {
  height: 20px;
  margin: 15px 15px 0 0;
}

/**=====================
  3.14 Ecommerce CSS End
==========================**/
@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .owl-carousel .owl-item img {
    max-width: 420px;
    margin: 0 auto;
  }
}
/**=====================
  3.15 Email-application CSS Start
==========================**/
.email-wrap .row .col-xl-3 + .col-xl-3 {
  padding-right: 0;
}
.email-wrap .row .col-xl-6 {
  padding-left: 0;
}
.email-wrap .dropdown .dropdown-toggle {
  background-color: rgba(48, 126, 243, 0.1);
  color: #307EF3;
  border-radius: 10px;
  padding: 5px 10px;
  width: 80px;
  text-align: left;
}
[dir=rtl] .email-wrap .dropdown .dropdown-toggle {
  text-align: right;
}
.email-wrap .dropdown .dropdown-toggle:after {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f107";
  border: none;
  font-family: "FontAwesome";
  font-size: 18px;
  margin: 0;
}
[dir=rtl] .email-wrap .dropdown .dropdown-toggle:after {
  right: unset;
  left: 10px;
}
.email-wrap .dropdown .dropdown-menu {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
}
.email-wrap .dropdown .dropdown-menu .dropdown-item {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #f5f5f5;
  background: #fff;
  line-height: 19px;
}
.email-wrap .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #fff;
}
.email-wrap .email-app-sidebar .d-flex img {
  border: 2px solid #EAEAEA;
}
.email-wrap .email-app-sidebar .main-menu > li {
  width: 100%;
  text-align: left;
}
.email-wrap .email-app-sidebar .main-menu > li a {
  line-height: 39px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
}
.email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: 10px;
}
.email-wrap .email-app-sidebar .main-menu > li a.active > a {
  color: #000;
}
.email-wrap .email-app-sidebar .main-menu > li a:hover {
  background-color: #f5f6f9;
  text-decoration: none;
}
.email-wrap .email-app-sidebar ul li a {
  display: flex;
  padding-left: 20px;
  align-items: center;
}
.email-wrap .email-app-sidebar ul li a > .title {
  width: 100%;
  color: #2c323f;
}
.email-wrap .email-app-sidebar ul li a > .badge {
  align-self: center;
  color: #9B9B9B;
}
.email-wrap .email-left-aside {
  float: left;
  width: 100%;
}
.email-wrap .email-left-aside .card-body {
  padding: 20px;
}
@media (min-width: 1200px) {
  .email-wrap .email-right-aside .email-body .pr-0 {
    padding-right: 0;
  }
  [dir=rtl] .email-wrap .email-right-aside .email-body .pr-0 {
    padding-right: 12px;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .email-wrap .email-right-aside .email-body .pl-0 {
    padding-left: 0;
  }
  [dir=rtl] .email-wrap .email-right-aside .email-body .pl-0 {
    padding-left: 12px;
    padding-right: 0;
  }
}
.email-wrap .email-right-aside .email-body .inbox {
  overflow: auto;
}
@media (max-width: 1199.98px) {
  .email-wrap .email-right-aside .email-body .inbox {
    display: flex;
    flex-wrap: wrap;
  }
}
.email-wrap .email-right-aside .email-body .inbox .d-flex {
  padding: 20px;
}
.email-wrap .email-right-aside .email-body .inbox .d-flex.active {
  background-color: #fdfdfd;
}
.email-wrap .email-right-aside .radius-left {
  border-radius: 8px;
  height: 100%;
}
.email-wrap .btn-mail {
  border-radius: 2px;
  text-align: left;
  padding: 13px 15px;
  margin: 20px 0 20px;
  letter-spacing: 1px;
  line-height: 12px !important;
}
.email-wrap p {
  margin-bottom: 0;
  color: #9B9B9B;
}
.email-wrap .flex-grow-1 {
  margin-top: 4px;
}
.email-wrap .flex-grow-1 h3 {
  margin-bottom: 2px;
  color: #363636;
  margin-top: 0;
}
.email-wrap .flex-grow-1 h3 small {
  font-size: 12px;
}
.email-wrap .flex-grow-1 h3 small span {
  color: #777777;
}
.email-wrap .flex-grow-1 p {
  color: #9B9B9B;
  font-size: 14px;
}
.email-wrap .email-top {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 52px;
}
.email-wrap .email-top i {
  cursor: pointer;
}
.email-wrap .email-top h3 {
  margin-bottom: 0;
  padding: 12px 0;
}
.email-wrap .email-top .d-flex {
  padding: 0;
}
.email-wrap .email-wrapper {
  padding: 20px;
}
.email-wrap .email-wrapper hr {
  margin-top: 0;
  margin-bottom: 20px;
}
.email-wrap .email-wrapper .d-inline-block {
  width: 100%;
}
.email-wrap .email-wrapper h4 {
  margin-bottom: 11px;
  float: left;
  font-weight: unset;
}
.email-wrap .email-wrapper .right-download {
  float: right;
}
.email-wrap .email-wrapper p + p {
  margin-bottom: 20px;
}
.email-wrap .email-wrapper h3 {
  margin-bottom: 20px;
}
.email-wrap .email-content .email-top .user-emailid:after {
  content: "";
  position: relative;
  height: 20px;
  width: 1px;
  right: 0;
  top: 3px;
  z-index: 2;
  border: 1px solid #EAEAEA;
  float: right;
  padding: 0;
  margin: 0 20px;
}
.email-wrap .email-body .attachment ul li img {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.email-wrap .email-body .attachment .list-inline .list-inline-item {
  padding-right: 13px;
}
.email-wrap .email-body .email-compose .cke_contents.cke_reset {
  max-height: 178px;
  width: 100%;
  border: 1px solid #EAEAEA;
}
.email-wrap .email-body .email-compose .email-top h4 {
  padding: 11px 0;
}
.email-wrap .actions li {
  display: inline-block;
  margin-right: 25px;
}

/**=====================
  3.15 Email-application CSS Ends
==========================**/
/**=====================
    2.25 Letter_box CSS Start
==========================**/
.email-right-aside .email-list {
  display: block;
}
.email-right-aside .email-list.hide {
  display: none;
}
.email-right-aside .email-read {
  display: none;
}
.email-right-aside .email-read.show {
  display: block;
}
.email-right-aside .modal-footer {
  text-align: end;
}

.email-main-wrapper .email-app-sidebar .nav-pills .nav-item:active, .email-main-wrapper .email-app-sidebar .nav-pills .nav-item.active {
  border-radius: 5px;
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link {
  color: var(--chart-text-color);
  gap: 13px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding: 8px 20px;
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link:hover {
  background-color: unset;
  color: var(--theme-default);
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active {
  background-color: var(--light-shade-primary);
  color: var(--theme-default);
  border-radius: 5px;
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active svg, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active svg {
  stroke: rgba(155, 155, 155, 0.8);
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active::after, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active::after {
  content: "";
  border-right: 3px solid var(--theme-default);
  position: absolute;
  top: 9px;
  width: 4px;
  right: 0;
  height: 35px;
  border: 2px solid var(--theme-default);
  border-radius: 100px 0 0 100px;
}
[dir=rtl] .email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active::after, [dir=rtl] .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active::after {
  right: unset;
  left: 0;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active::after, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active::after {
    top: 8px;
    height: 28px;
  }
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link svg {
  width: 18px;
  height: 18px;
  stroke: rgba(155, 155, 155, 0.8);
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link div {
  width: calc(100% - 13px - 18px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .nav-pills .nav-link div {
    font-size: 15px;
  }
}
.email-main-wrapper .email-app-sidebar span {
  background-color: rgba(48, 126, 243, 0.2);
  color: var(--theme-default);
  border-radius: 20px;
  padding: 5px 13px;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar span {
    padding: 3px 10px;
  }
}
.email-main-wrapper .email-app-sidebar .compose-mail {
  display: block;
  text-align: center;
}
.email-main-wrapper .email-app-sidebar .compose-mail .nav-link.active {
  padding: 15px;
  background-color: var(--theme-default);
  color: var(--white);
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .compose-mail .nav-link.active {
    padding: 10px;
  }
}
.email-main-wrapper .email-app-sidebar .compose-mail .nav-link.active::before {
  content: unset;
}
.email-main-wrapper .email-app-sidebar .compose-mail .nav-link i {
  font-size: 13px;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .email-category.nav-pills .nav-item .nav-link {
    padding: 3px 20px;
  }
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .taged-mail.nav-pills .nav-item .nav-link {
    padding: 3px 20px;
  }
}
.email-main-wrapper .email-app-sidebar .taged-mail.nav-pills .nav-item:first-child a {
  margin-top: 16px;
}
.email-main-wrapper .email-app-sidebar .taged-mail.nav-pills .nav-item svg {
  width: 24px;
  height: 24px;
}
.email-main-wrapper .email-app-sidebar .emailbox.btn {
  width: 100%;
  margin-bottom: 15px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 576px) {
  .email-main-wrapper .email-app-sidebar .emailbox.btn {
    padding: 7px;
  }
}
.email-main-wrapper .email-app-sidebar .emailbox.btn i {
  padding-right: 12px;
}
[dir=rtl] .email-main-wrapper .email-app-sidebar .emailbox.btn i {
  padding-right: unset;
  padding-left: 12px;
}

.email-body .mail-header-wrapper {
  padding: 16px;
  box-shadow: 0px 5px 10px 0px rgba(82, 82, 108, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper {
    padding: 12px;
  }
}
@media (max-width: 600px) {
  .email-body .mail-header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
.email-body .mail-header-wrapper .mail-header .form-check {
  display: flex;
  align-items: center;
}
@media (max-width: 420px) {
  .email-body .mail-header-wrapper .mail-header .form-check {
    align-items: flex-start;
  }
}
.email-body .mail-header-wrapper .mail-header .form-check .form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 9px;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-header .form-check .form-check-input {
    margin-right: 8px;
  }
  [dir=rtl] .email-body .mail-header-wrapper .mail-header .form-check .form-check-input {
    margin-left: 8px;
    margin-right: unset;
  }
}
.email-body .mail-header-wrapper .mail-header .form-check .form-check-input:focus {
  box-shadow: unset;
}
@media (max-width: 424px) {
  .email-body .mail-header-wrapper .mail-header ul {
    gap: 12px;
  }
}
@media (max-width: 351px) {
  .email-body .mail-header-wrapper .mail-header ul {
    flex-direction: column;
  }
}
.email-body .mail-header-wrapper .mail-header ul li a {
  display: flex;
  align-items: center;
  gap: 9px;
  color: var(--chart-text-color);
  position: relative;
  padding-bottom: 0;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-header ul li a {
    padding: 7px;
  }
}
.email-body .mail-header-wrapper .mail-header ul li a::before {
  content: "";
  position: absolute;
  bottom: -23px;
  left: 0;
  width: 0;
  background-color: var(--theme-default);
  height: 2px;
  transition: all 0.1s ease-in;
}
[dir=rtl] .email-body .mail-header-wrapper .mail-header ul li a::before {
  left: unset;
  right: 0;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-header ul li a::before {
    bottom: -15px;
  }
}
@media (max-width: 991px) {
  .email-body .mail-header-wrapper .mail-header ul li a::before {
    bottom: -13px;
  }
}
@media (max-width: 351px) {
  .email-body .mail-header-wrapper .mail-header ul li a::before {
    bottom: -2px;
  }
}
.email-body .mail-header-wrapper .mail-header ul li a.active {
  color: var(--theme-default);
}
.email-body .mail-header-wrapper .mail-header ul li a.active::before {
  width: 100%;
  transition: all 0.1s ease-in;
}
.email-body .mail-header-wrapper .mail-header ul li a svg {
  width: 22px;
  height: 22px;
}
@media (max-width: 424px) {
  .email-body .mail-header-wrapper .mail-header ul li a svg {
    display: none;
  }
}
.email-body .mail-header-wrapper .mail-body {
  display: flex;
  gap: 12px;
}
@media (max-width: 1500px) {
  .email-body .mail-header-wrapper .mail-body {
    justify-content: end;
  }
}
@media (max-width: 600px) {
  .email-body .mail-header-wrapper .mail-body {
    display: none;
  }
}
.email-body .mail-header-wrapper .mail-body .mail-search {
  position: relative;
}
@media (max-width: 1500px) {
  .email-body .mail-header-wrapper .mail-body .mail-search {
    width: 55%;
  }
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-body .mail-search {
    width: 48%;
  }
}
@media (max-width: 1400px) {
  .email-body .mail-header-wrapper .mail-body .mail-search {
    display: none;
  }
}
.email-body .mail-header-wrapper .mail-body .mail-search .form-control {
  display: inline-block;
  padding: 8px 30px;
}
.email-body .mail-header-wrapper .mail-body .mail-search i {
  position: absolute;
  top: 12px;
  left: 11px;
}
[dir=rtl] .email-body .mail-header-wrapper .mail-body .mail-search i {
  left: 0;
  right: 11px;
}
.email-body .light-square {
  width: 40px;
  height: 39px;
  border-radius: 8px;
  background-color: var(--light-slate);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 992px) {
  .email-body .light-square {
    width: 34px;
    height: 35px;
  }
}
.email-body .light-square.dropdown-toggle::after {
  content: "";
  display: none;
}
.email-body .light-square .dropdown-menu .dropdown-item {
  border-top: 0;
}
.email-body .light-square .dropdown-menu .dropdown-item:hover {
  background-color: #cccccc;
}
.email-body .light-square .dropdown-menu .dropdown-item i {
  padding-right: 12px;
}
[dir=rtl] .email-body .light-square .dropdown-menu .dropdown-item i {
  padding-right: 0;
  padding-left: 12px;
}
.email-body .mail-body-wrapper ul li {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--chart-dashed-border);
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .email-timing {
  display: none;
  transition: all 0.3s ease-in-out;
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .email-options {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: flex-end;
  min-width: 100px;
  transition: all 0.3s ease-in-out;
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .email-options i {
  font-size: 18px;
  color: #9B9B9B;
}
@media (max-width: 375px) {
  .email-body .mail-body-wrapper ul li:hover .inbox-message .email-options i {
    font-size: 16px;
  }
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .show {
  display: block;
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .hide {
  display: none;
}
@media (max-width: 460px) {
  .email-body .mail-body-wrapper ul li.inbox-data {
    display: block;
  }
}
.email-body .mail-body-wrapper ul li:last-child {
  border-bottom: unset;
}
.email-body .mail-body-wrapper ul li .inbox-user {
  min-width: 290px;
  display: flex;
  align-items: center;
  gap: 12px;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user {
    min-width: 280px;
  }
}
@media (max-width: 576px) {
  .email-body .mail-body-wrapper ul li .inbox-user {
    min-width: 260px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .form-check-input {
  width: 18px;
  height: 18px;
}
.email-body .mail-body-wrapper ul li .inbox-user .form-check-input:focus {
  box-shadow: unset;
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border {
  width: 50px;
  height: 50px;
  border: 2px solid var(--chart-dashed-border);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border {
    width: 42px;
    height: 42px;
  }
}
@media (max-width: 355px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border {
    display: none;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div {
  width: 40px;
  height: 40px;
  background-color: rgba(48, 126, 243, 0.2);
  border-radius: 100%;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div {
    width: 32px;
    height: 32px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 900;
}
@media (max-width: 1200px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div p {
    font-size: 13px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border .circle-success {
  background-color: #D6EED4;
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border img {
  border-radius: 100%;
  height: 38px;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border img {
    height: 32px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}
@media (max-width: 700px) {
  .email-body .mail-body-wrapper ul li .inbox-message {
    display: block;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data span {
  font-weight: 500;
  color: var(--chart-text-color);
  letter-spacing: 0.3;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data span span {
  font-weight: 400;
  line-height: 1.9;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data .badge {
  border-radius: 20px;
}
@media (max-width: 1236px) {
  .email-body .mail-body-wrapper ul li .inbox-message .email-data .badge {
    margin-bottom: 5px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data .badge:last-child {
  margin-bottom: 0;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-timing {
  display: flex;
  align-items: center;
  min-width: 80px;
  transition: all 0.3s ease-in-out;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-timing span {
  color: #9B9B9B;
}
@media (max-width: 768px) {
  .email-body .mail-body-wrapper ul li .inbox-message .email-timing {
    justify-content: flex-end;
    height: 18px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-options {
  display: none;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .email-body .mail-body-wrapper ul li .inbox-message .email-options {
    height: 18px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-options .show {
  display: block;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-options .hide {
  display: none;
}
.email-body .mail-body-wrapper ul li.page-item {
  padding: 0;
}
.email-body .mail-body-wrapper .hidden {
  display: none !important;
}
.email-body .mail-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px;
}
.email-body .mail-pagination .pagination-button,
.email-body .mail-pagination .pagination-number {
  padding: 0px 12px;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  border: unset;
}
.email-body .mail-pagination .pagination-button.disabled {
  background-color: var(--light-background);
  color: var(--chart-text-color);
  opacity: 1;
}
.email-body .mail-pagination .pagination-number {
  padding: 6px 12px;
}
.email-body .mail-pagination .pagination-number.active {
  background-color: var(--theme-default);
  color: var(--white);
}
.email-body .mail-pagination .pagination-index {
  display: flex;
  gap: 12px;
}

.compose-modal form .form-control {
  position: relative;
}
.compose-modal form .add-bcc {
  position: absolute;
  top: 2px;
  right: 16px;
}
[dir=rtl] .compose-modal form .add-bcc {
  right: unset;
  left: 16px;
}
.compose-modal form .add-bcc .btn {
  padding: 5px 8px;
  background-color: var(--light-shade-primary);
  color: var(--theme-default);
}

.mail-header-wrapper.header-wrapper1 {
  flex-direction: unset;
}
@media (max-width: 440px) {
  .mail-header-wrapper.header-wrapper1 {
    flex-direction: column;
    gap: 10px;
  }
}
.mail-header-wrapper .mail-header1 {
  display: flex;
  align-items: center;
  gap: 18px;
}
.mail-header-wrapper .mail-header1 span {
  font-weight: 500;
  color: var(--body-font-color);
}
.mail-header-wrapper .mail-header1 .btn-email {
  width: 20px;
  height: 20px;
}
.mail-header-wrapper .mail-body1 {
  display: flex;
  gap: 10px;
}
.mail-header-wrapper .mail-body1 svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 480px) {
  .mail-header-wrapper .mail-body1 svg {
    width: 18px;
    height: 18px;
  }
}

.mail-body-wrapper .user-mail-wrapper {
  padding: 16px;
}
.mail-body-wrapper .user-mail-wrapper .user-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--chart-dashed-border);
}
@media (max-width: 390px) {
  .mail-body-wrapper .user-mail-wrapper .user-title {
    flex-direction: column;
    padding-bottom: 12px;
    justify-content: unset;
    align-items: unset;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title > div {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 12px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .rounded-border {
  width: 50px;
  height: 50px;
  border: 2px solid var(--chart-dashed-border);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .rounded-border img {
  height: 38px;
  border-radius: 100%;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .dropdown-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-gray);
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .dropdown-button svg {
  width: 16px;
  height: 16px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security {
  padding: 16px;
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  min-width: 350px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security p {
  margin-bottom: 8px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security p:last-child {
  margin-bottom: 0;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security p span {
  color: var(--body-font-color);
}
@media (max-width: 475px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: -27px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: unset;
    right: -27px;
  }
}
@media (max-width: 445px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: -70px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: unset;
    right: -70px;
  }
}
@media (max-width: 395px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    min-width: 270px;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
  top: 30px;
  z-index: 1;
}
[dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
  left: unset;
  right: 0;
}
@media (max-width: 575px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 475px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: -27px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: unset;
    right: -27px;
  }
}
@media (max-width: 445px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: -70px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: unset;
    right: -70px;
  }
}
@media (max-width: 395px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    min-width: 270px;
  }
}
@media (max-width: 390px) {
  .mail-body-wrapper .user-mail-wrapper .user-title .inbox-options {
    padding-bottom: 0;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title .inbox-options span {
  color: var(--chart-text-color);
}
@media (max-width: 623px) {
  .mail-body-wrapper .user-mail-wrapper .user-title .inbox-options span {
    display: none;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title .inbox-options svg {
  width: 18px;
  height: 18px;
}
.mail-body-wrapper .user-mail-wrapper .user-body {
  padding: 14px 0;
  border-bottom: 1px solid var(--chart-dashed-border);
}
.mail-body-wrapper .user-mail-wrapper .user-body p:nth-child(1) {
  padding-bottom: 14px;
}
.mail-body-wrapper .user-mail-wrapper .user-body p:nth-child(2), .mail-body-wrapper .user-mail-wrapper .user-body p:nth-child(4) {
  line-height: 1.8;
  margin-bottom: 10px;
}
.mail-body-wrapper .user-mail-wrapper .user-body .mail-subcontent {
  padding-top: 14px;
}
.mail-body-wrapper .user-mail-wrapper .user-body .mail-subcontent p {
  padding-bottom: 0;
}
.mail-body-wrapper .user-mail-wrapper .user-footer {
  padding: 14px 0;
  border-bottom: 1px solid var(--chart-dashed-border);
}
.mail-body-wrapper .user-mail-wrapper .user-footer > div:not(.toolbar-box) {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 12px;
  cursor: pointer;
}
.mail-body-wrapper .user-mail-wrapper .user-footer svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 480px) {
  .mail-body-wrapper .user-mail-wrapper .user-footer svg {
    width: 18px;
    height: 18px;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-footer .attchment-file {
  align-items: center;
  gap: 30px;
  padding: 12px;
  background-color: var(--light-background);
}
.mail-body-wrapper .user-mail-wrapper .user-footer .attchment-file .common-flex img {
  height: 36px;
}
.mail-body-wrapper .user-mail-wrapper .send-btn {
  text-align: end;
  padding-top: 14px;
}
.mail-body-wrapper .user-mail-wrapper .send-btn i {
  margin-left: 8px;
  font-size: 12px;
}
[dir=rtl] .mail-body-wrapper .user-mail-wrapper .send-btn i {
  margin-left: 0;
  margin-right: 8px;
}

.toolbar-box {
  display: block;
  padding: 0;
  border: 1px solid var(--chart-dashed-border);
  border-radius: 5px;
}
.toolbar-box .ql-toolbar.ql-snow {
  border: 0;
}
.toolbar-box .ql-container.ql-snow {
  border: 0;
  min-height: 100px;
}
[dir=rtl] .toolbar-box .ql-editor {
  text-align: right;
}
.toolbar-box .ql-editor p:nth-child(3) {
  margin: 12px 0;
}
.toolbar-box .ql-editor p:nth-child(8), .toolbar-box .ql-editor p:nth-child(9) {
  margin: 12px 0;
}
.toolbar-box .ql-bold:hover,
.toolbar-box .ql-italic:hover,
.toolbar-box .ql-underline:hover,
.toolbar-box .ql-list:hover,
.toolbar-box .ql-link:hover,
.toolbar-box .ql-image:hover {
  color: var(--theme-default);
}
.toolbar-box .ql-bold:hover svg .ql-stroke,
.toolbar-box .ql-bold:hover svg .ql-fill,
.toolbar-box .ql-italic:hover svg .ql-stroke,
.toolbar-box .ql-italic:hover svg .ql-fill,
.toolbar-box .ql-underline:hover svg .ql-stroke,
.toolbar-box .ql-underline:hover svg .ql-fill,
.toolbar-box .ql-list:hover svg .ql-stroke,
.toolbar-box .ql-list:hover svg .ql-fill,
.toolbar-box .ql-link:hover svg .ql-stroke,
.toolbar-box .ql-link:hover svg .ql-fill,
.toolbar-box .ql-image:hover svg .ql-stroke,
.toolbar-box .ql-image:hover svg .ql-fill {
  stroke: var(--theme-default);
}
.toolbar-box .ql-bold button.ql-active .ql-stroke,
.toolbar-box .ql-italic button.ql-active .ql-stroke,
.toolbar-box .ql-underline button.ql-active .ql-stroke,
.toolbar-box .ql-list button.ql-active .ql-stroke,
.toolbar-box .ql-link button.ql-active .ql-stroke,
.toolbar-box .ql-image button.ql-active .ql-stroke {
  stroke: var(--theme-default);
}

#editor .ql-editing,
#editor1 .ql-editing {
  left: 60.375px !important;
}
[dir=rtl] #editor .ql-editing,
[dir=rtl] #editor1 .ql-editing {
  left: 0;
  right: 60.375px !important;
}
#editor .ql-editing.ql-out-bottom,
#editor1 .ql-editing.ql-out-bottom {
  visibility: visible !important;
}
#editor .ql-tooltip,
#editor1 .ql-tooltip {
  left: 35.9688px !important;
}
[dir=rtl] #editor .ql-tooltip,
[dir=rtl] #editor1 .ql-tooltip {
  left: 0;
  right: 35.9688px !important;
}
@media (max-width: 430px) {
  #editor .ql-tooltip,
  #editor1 .ql-tooltip {
    left: 2.9688px !important;
  }
  [dir=rtl] #editor .ql-tooltip,
  [dir=rtl] #editor1 .ql-tooltip {
    left: 0;
    right: 2.9688px !important;
  }
}
#editor .ql-tooltip.ql-out-bottom,
#editor1 .ql-tooltip.ql-out-bottom {
  visibility: visible !important;
}
@media (max-width: 430px) {
  #editor .ql-tooltip.ql-out-bottom input[type=text],
  #editor1 .ql-tooltip.ql-out-bottom input[type=text] {
    width: 94px;
  }
}

.important-mail {
  width: 18px;
  height: 18px;
  fill: transparent;
  stroke: var(--chart-text-color);
  cursor: pointer;
}
.important-mail:active, .important-mail.active {
  fill: #FFAA05;
  stroke: #FFAA05;
}

.bookmark-box {
  stroke: var(--chart-text-color);
}
.bookmark-box:active, .bookmark-box.active {
  stroke: var(--theme-default);
}

.dropdown-subtitle > p {
  color: #52526c;
  font-weight: 500;
}

/**=====================
    2.25 Letter_box CSS End
==========================**/
/**=====================
    3.16 Errorpage CSS Start
==========================**/
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: #fff;
}
.error-wrapper.maintenance-bg {
  background-image: url(../images/other-images/maintenance-bg.jpg);
  background-color: rgba(255, 255, 255, 0.6);
  background-blend-mode: overlay;
}
.error-wrapper svg.maintanance {
  height: 420px;
}
.error-wrapper .maintenance-icons li i {
  color: #59667a;
  font-size: 50px;
  animation: rotate 8s infinite linear;
}
.error-wrapper .maintenance-icons li:nth-child(2) i {
  font-size: 100px;
  margin-left: 110px;
  margin-top: -25px;
  animation: rotate 8s infinite linear;
}
.error-wrapper .maintenance-icons li:nth-child(3) i {
  animation: rotate 8s infinite linear;
  font-size: 150px;
  margin-top: -85px;
  margin-right: 120px;
}
.error-wrapper svg {
  width: 100%;
  height: 380px;
}
.error-wrapper .maintenance-heading {
  margin-top: 20px;
}
.error-wrapper .maintenance-heading .headline {
  font-size: 99px;
  font-weight: 900;
  letter-spacing: 10px;
  color: #307EF3;
  z-index: 2;
  position: relative;
}
.error-wrapper .error-heading {
  margin-top: 115px;
}
.error-wrapper .error-heading .headline {
  font-size: 285px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: -130px;
}
.error-wrapper .error {
  margin: 70px 0 100px;
  color: #000;
}
.error-wrapper .error .error-title {
  font-size: 9.5rem;
  color: #fff;
  text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
}
.error-wrapper .error .line-bottom {
  width: 10%;
  height: 5px;
  background-color: #FFAA05;
  border: none;
}
.error-wrapper .error .c-white {
  color: #fff;
}
.error-wrapper .error .error-btn {
  text-align: center;
}
.error-wrapper .svg-wrraper {
  margin-bottom: 35px;
}
.error-wrapper .svg-wrraper svg {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 440px;
}
.error-wrapper h3 {
  font-weight: 700;
  margin-bottom: 20px;
}
.error-wrapper .sub-content {
  font-size: 18px;
  color: #363636;
  letter-spacing: 1px;
  line-height: 35px;
  font-weight: normal;
  z-index: 3;
  margin-bottom: 35px;
}

@media (max-width: 1199px) {
  .error-wrapper svg {
    height: 320px;
  }
}
@media (max-width: 991px) {
  .error-wrapper svg {
    height: 280px;
  }
  .error-wrapper .svg-wrraper svg {
    height: 450px;
  }
  .error-wrapper .sub-content {
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  .error-wrapper h3 {
    margin-bottom: 18px;
  }
  .error-wrapper .sub-content {
    margin-bottom: 30px;
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .error-wrapper .svg-wrraper {
    margin-bottom: 25px;
  }
  .error-wrapper .svg-wrraper svg {
    height: 380px;
  }
  .error-wrapper .sub-content {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .error-wrapper .svg-wrraper svg {
    height: auto;
  }
}
/**=====================
    3.16 Errorpage CSS Start
==========================**/
/**=====================
    3.17 Faq CSS start
==========================**/
.knowledgebase-bg {
  height: 500px;
  border-radius: 15px;
  margin-bottom: 30px;
}

.knowledgebase-search {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 5%;
}
.knowledgebase-search .form-inline {
  background-color: #fff;
  width: 500px;
  padding: 12px 50px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  position: relative;
}
.knowledgebase-search .form-inline ::placeholder {
  color: #cccccc;
}
.knowledgebase-search .form-inline svg {
  position: absolute;
  left: 20px;
  width: 20px;
  top: 17px;
  stroke: #cccccc;
}

.faq-articles h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.faq-wrap .bg-primary .card-body {
  background-color: transparent !important;
}

.faq-widgets {
  align-items: center;
}
.faq-widgets .flex-grow-1 h3 {
  font-weight: 500;
  margin-bottom: 8px;
  color: #fff;
}
.faq-widgets .flex-grow-1 p {
  font-size: 14px;
  padding-right: 20px;
  color: rgba(255, 255, 255, 0.9) !important;
}
.faq-widgets svg {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

.faq-wrap .faq-header h4 {
  font-weight: 600;
  font-size: 20px;
}
.faq-wrap .faq-widgets .flex-grow-1 h3 {
  margin-bottom: 8px;
  color: #fff;
}

.header-faq {
  margin-bottom: 30px;
}
.header-faq h5 {
  font-weight: 600;
}

.features-faq.card .card-body,
.features-faq.card .card-footer {
  padding: 20px 25px;
}
.features-faq .faq-image img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}
.features-faq .card-body h6 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
}

.add-project.table td {
  vertical-align: middle;
}
.add-project .text-inherit {
  color: black;
}

.faq-accordion.default-according {
  margin-bottom: 30px;
}
.faq-accordion.default-according .card .btn-link {
  display: flex;
}
.faq-accordion.default-according .card .btn-link svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.faq-accordion.default-according .card .faq-header {
  position: relative;
  padding: 15px;
}
.faq-accordion.default-according .card .faq-header svg {
  position: absolute;
  right: 30px;
  top: 18px;
  width: 20px;
  height: 20px;
}
.faq-accordion.default-according .card .faq-header span {
  margin-top: 3px;
}
.faq-accordion.default-according .faq-body {
  padding: 20px !important;
}
.faq-accordion.default-according .card-mb-faq {
  margin-bottom: 30px;
}

.faq-title {
  margin: 30px 0;
}
.faq-title h3 {
  font-weight: 600;
}

.faq-form {
  position: relative;
}
.faq-form .search-icon {
  position: absolute;
  right: 12px;
  top: 9px;
  width: 20px;
  height: 20px;
}

.navigation-btn {
  margin-bottom: 20px;
}
.navigation-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-btn a svg {
  width: 15px;
  height: 15px;
}

.navigation-option ul li {
  position: relative;
  padding: 8px 8px 8px 0;
}
.navigation-option ul li:hover {
  background-color: #fdfeff;
}
.navigation-option ul li a {
  padding-left: 40px;
  color: #363636;
}
.navigation-option ul li a svg {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 11px;
  left: 15px;
}

.updates-faq {
  width: 40px;
  height: 40px;
  border: 2px solid var(--theme-default);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.updates-faq svg {
  width: 18px;
  height: 18px;
}

.updates-bottom-time p {
  margin-bottom: 2px;
}
.updates-bottom-time p + p {
  color: #999999;
}

.updates-faq-main {
  margin-bottom: 20px;
}
.updates-faq-main a {
  font-weight: 500;
}
.updates-faq-main:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 1399.98px) {
  .faq-widgets svg {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 1199px) {
  .faq-accordion {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .faq-accordion.default-according .card .faq-header {
    padding: 20px !important;
  }
  .faq-accordion.default-according .card .faq-header svg {
    right: 20px;
    top: 20px;
  }
  .navigation-option ul li {
    padding: 10px 10px 10px 0;
  }
  .navigation-option ul li a svg {
    top: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .xs-mt-search {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .xs-mt-search {
    margin-top: 0px;
  }
  .faq-widgets svg {
    width: 30px;
    height: 30px;
  }
  .faq-title {
    margin: 15px 0;
  }
  .header-faq {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .faq-accordion {
    margin-bottom: 0px;
  }
  .faq-accordion .faq-header {
    padding: 15px !important;
  }
  .faq-accordion .faq-header .pull-right {
    float: right;
  }
  .faq-accordion .faq-header svg {
    right: 15px;
    top: 15px;
  }
  .navigation-btn,
  .updates-faq-main {
    margin-bottom: 15px;
  }
}
/**=====================
    3.17 Faq CSS end
==========================**/
/**=====================
   3.18 File CSS
==========================**/
.files-content {
  display: flex;
  margin-bottom: -10px;
}
.files-content li {
  margin-right: 20px;
  margin-bottom: 10px;
}
.files-content li .files-list .file-left {
  background-color: rgba(48, 126, 243, 0.05);
  border: 1px solid #EAEAEA;
  padding: 18px 22px;
  border-radius: 5px;
}

.file-sidebar .card .card-body,
.file-sidebar .card .card-header,
.file-content .card .card-body,
.file-content .card .card-header {
  padding: 20px !important;
}
.file-sidebar .card .card-body .file-manager,
.file-sidebar .card .card-header .file-manager,
.file-content .card .card-body .file-manager,
.file-content .card .card-header .file-manager {
  padding-top: unset !important;
}
.file-sidebar .card .card-body .form-group input,
.file-sidebar .card .card-header .form-group input,
.file-content .card .card-body .form-group input,
.file-content .card .card-header .form-group input {
  line-height: 0;
}

.file-manager .folder {
  margin-bottom: -12px;
}
.file-manager .folder li:nth-child(n+2) {
  margin-left: 12px;
  margin-bottom: 12px;
}
.file-manager .folder li:nth-child(5), .file-manager .folder li:nth-child(9), .file-manager .folder li:nth-child(13) {
  margin-left: 0;
}

.file-sidebar ul li + li {
  margin-top: 8px;
}
.file-sidebar .btn {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  font-weight: 400;
}
.file-sidebar .btn.btn-light:hover {
  color: #307EF3 !important;
}
.file-sidebar .btn.btn-light:hover svg {
  stroke: #307EF3;
}
.file-sidebar .btn svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;
}
.file-sidebar .pricing-plan {
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  overflow: hidden;
}
.file-sidebar .pricing-plan h6 {
  font-size: 14px;
  margin-bottom: 2px;
  color: #9B9B9B;
}
.file-sidebar .pricing-plan h5 {
  font-weight: 500;
  font-size: 20px;
}
.file-sidebar .pricing-plan p {
  margin-bottom: 5px;
  color: #9B9B9B;
}
.file-sidebar .pricing-plan .btn {
  display: inline-block;
  font-size: 12px;
}
.file-sidebar .pricing-plan .bg-img {
  position: absolute;
  top: 40px;
  opacity: 0.1;
  transform: rotate(-45deg);
  right: -40px;
}

.file-manager h5 {
  font-size: 18px;
  margin-bottom: 8px;
}
.file-manager > h6 {
  opacity: 0.6;
  font-weight: 400 !important;
  font-size: 15px;
  margin-bottom: 12px;
}
.file-manager .files-gap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.file-manager .files-gap.list-group {
  flex-direction: unset;
}
.file-manager p {
  font-size: 12px;
  color: #999999;
  margin-bottom: 0;
}

.folder h6 {
  opacity: 0.9;
  font-weight: 500 !important;
  font-size: 14px;
}

.files-content,
.quick-file {
  flex-wrap: wrap;
}

.quick-file {
  margin-bottom: -10px;
}
.quick-file li {
  margin-right: 20px;
  margin-bottom: 10px;
}
.quick-file li:last-child {
  margin-right: 0;
}
.quick-file li h6 {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin-top: 8px;
}
.quick-file li .quick-box {
  background-color: rgba(48, 126, 243, 0.05);
  border: 1px solid #EAEAEA;
  padding: 20px 24px;
  border-radius: 5px;
}
.quick-file li .quick-box i {
  font-size: 30px;
  margin-right: 0;
}

.file-content .folder-box .files-list h6 {
  font-size: 16px;
  font-weight: 400;
}
.file-content .folder-box .files-list i {
  margin-right: 0;
}
.file-content .ellips {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.7;
}
.file-content .form-inline {
  border: 1px solid #EAEAEA;
  padding: 0 20px;
  border-radius: 5px;
}
.file-content .form-inline i {
  padding-right: 10px;
  color: #9B9B9B;
  line-height: 3;
}
.file-content .form-inline input::-webkit-input-placeholder {
  color: #9B9B9B;
}
.file-content .form-inline input:focus {
  outline: none !important;
}
.file-content .search-form input {
  padding: 5px 10px 5px 70px;
  border-radius: 5px;
}
.file-content .search-form .form-group:before {
  left: 82px;
  top: 37px;
}
.file-content .search-form .form-group:after {
  top: 39px;
  left: 53px;
}
.file-content .btn svg {
  height: 16px;
  margin-right: 2px;
  vertical-align: -3px;
}
.file-content h4 {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 1;
}
.file-content .folder .folder-box {
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  padding: 15px;
  background-color: #fff;
  width: calc(25% - 15px);
  display: inline-block;
  margin: 2px 0;
  position: relative;
}
.file-content .folder .folder-box h6 {
  font-size: 16px;
}
.file-content .folder .folder-box .ellips {
  top: 22px;
  right: 22px;
}
.file-content .folder .folder-box:nth-child(1) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 100000ms;
}
.file-content .folder .folder-box:nth-child(2) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 200000ms;
}
.file-content .folder .folder-box:nth-child(3) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 300000ms;
}
.file-content .folder .folder-box:nth-child(4) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 400000ms;
}
.file-content .folder .folder-box:nth-child(5) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 500000ms;
}
.file-content .folder .folder-box:nth-child(6) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 600000ms;
}
.file-content .folder .folder-box:nth-child(7) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 700000ms;
}
.file-content .folder .folder-box:nth-child(8) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 800000ms;
}
.file-content .folder .folder-box:nth-child(9) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 900000ms;
}
.file-content .folder .folder-box:nth-child(10) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1000000ms;
}
.file-content .folder .folder-box:nth-child(11) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1100000ms;
}
.file-content .folder .folder-box:nth-child(12) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1200000ms;
}
.file-content .folder .folder-box:nth-child(13) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1300000ms;
}
.file-content .folder .folder-box:nth-child(14) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1400000ms;
}
.file-content .folder .folder-box:nth-child(15) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1500000ms;
}

.md-sidebar .md-sidebar-toggle {
  display: none;
  width: fit-content;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

@media screen and (max-width: 1440px) {
  .file-content .folder .folder-box .d-flex {
    display: block !important;
    text-align: center;
  }
  .file-content .folder .folder-box .d-flex .flex-grow-1 {
    margin-left: 0 !important;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1366px) {
  .file-content .folder {
    margin-bottom: -10px;
  }
  .file-content .folder .folder-box {
    margin-bottom: 10px;
    margin-right: 0;
    padding: 13px;
  }
  .file-content .folder .folder-box .d-flex i {
    font-size: 30px;
  }
  .file-content .card-header .btn {
    padding: 8px 15px;
    z-index: 0;
  }
  .file-sidebar .btn {
    padding: 8px 15px;
  }
}
@media screen and (max-width: 1199px) {
  .job-sidebar .job-left-aside {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0;
    opacity: 1;
    visibility: hidden;
    overflow-y: scroll;
    z-index: 3;
    max-width: 270px;
    height: 600 px;
    background-color: #fff;
    border: 1 px solid #e6edef;
    padding: 12px;
  }
  .job-sidebar .job-toggle {
    display: block !important;
    margin-bottom: 20px;
  }
  .job-sidebar .job-left-aside.open {
    opacity: 1;
    visibility: visible;
  }
  .md-sidebar .md-sidebar-toggle {
    display: block;
    width: fit-content;
  }
  .md-sidebar .md-sidebar-aside {
    position: absolute;
    top: 70%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    width: 280px;
  }
  .md-sidebar .md-sidebar-aside.open {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 768px) {
  .file-content .form-inline {
    display: inline-block;
  }
  .file-content .folder {
    margin-bottom: -10px;
  }
  .file-content .folder .folder-box {
    width: calc(50% - 20px);
    margin-bottom: 10px;
    margin-right: 8px;
  }
  .file-content .folder .folder-box:nth-child(odd) {
    margin-left: 0;
  }
}
@media screen and (max-width: 420px) {
  .file-content .folder .folder-box {
    width: 100%;
    margin-right: unset;
  }
  .file-content h4 {
    font-size: 20px;
  }
  .file-content .card-header .btn {
    padding: 7px 10px;
    font-size: 12px;
  }
  .file-manager .folder li:nth-child(n+2) {
    margin-left: 0;
  }
  .file-manager > h6 {
    font-size: 14px;
  }
}
/**=====================
    3.18 File CSS
==========================**/
/**=====================
    3.19 Gallery CSS Start
==========================**/
.profile-img-style img,
.scroll-bar-wrap img {
  width: 100%;
}

.gallery {
  margin-bottom: -30px;
}
.gallery > a {
  margin-bottom: 30px;
}
.gallery > a:before {
  content: "\edee";
  font-family: IcoFont;
  position: absolute;
  height: calc(100% - 10px);
  left: 20px;
  width: calc(100% - 40px);
  background-color: rgba(255, 255, 255, 0.27);
  top: 5px;
  transform: scale(0);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #000;
}
.gallery > a:hover:before {
  transform: scale(1);
  transition: all 0.3s ease;
}
.gallery > a .img-thumbnail {
  border-radius: 0;
}

.gallery-with-description {
  margin-bottom: -30px;
}
.gallery-with-description a > div {
  padding: 10px;
  border: 1px solid #e4e6ec;
  margin-bottom: 30px;
  border-radius: 0 0 5px 5px;
}
.gallery-with-description a:hover {
  text-decoration: none !important;
}
.gallery-with-description h4 {
  color: #2c323f;
  margin-top: 15px;
  font-size: 18px;
}
.gallery-with-description p {
  color: #b5b5b5;
}

.lg-backdrop {
  background-color: #fff;
}

.lg-outer .lg-actions .lg-next,
.lg-outer .lg-actions .lg-prev {
  background-color: rgb(255, 255, 255);
  border: 1px solid #EAEAEA;
}
.lg-outer .lg-actions .lg-next:hover,
.lg-outer .lg-actions .lg-prev:hover {
  color: #2c323f;
}
.lg-outer .lg-toolbar {
  background-color: rgba(255, 255, 255, 0.45);
  border-bottom: 1px solid #2c323f;
}
.lg-outer .lg-toolbar .lg-icon:hover {
  color: #2c323f;
}
.lg-outer #lg-counter {
  color: #2c323f;
}
.lg-outer .lg-sub-html {
  background-color: rgba(255, 255, 255, 0.45);
}
.lg-outer .lg-sub-html h4 {
  font-size: inherit;
  color: #2c323f;
}
.lg-outer .lg-sub-html p {
  color: #2c323f;
}
.lg-outer .lg-thumb-outer {
  background-color: #EAEAEA;
}
.lg-outer .lg-thumb-item {
  padding: 4px;
  border: 1px solid #EAEAEA;
  border-radius: 0;
}
.lg-outer .lg-thumb-item .active, .lg-outer .lg-thumb-item:hover {
  border-color: #000;
}

.gallery a:before, .gallery a:after {
  content: none;
}
.gallery .img-hover > div {
  overflow: hidden;
}
.gallery .hover-1 img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-1 img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.gallery .hover-2 img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  transform: translateX(0px);
}
.gallery .hover-2:hover img {
  transform: translateX(40px) scale(1.2);
}
.gallery .hover-3 img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-3:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.gallery .hover-4 img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-4:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.gallery .hover-5 img {
  margin-left: 30px;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-5:hover img {
  margin-left: 0;
}
.gallery .hover-6 img {
  -webkit-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-6:hover img {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}
.gallery .hover-7 img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-7:hover img {
  -webkit-filter: blur(0);
  filter: blur(0);
}
.gallery .hover-8 img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-8:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.gallery .hover-9 img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-9:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}
.gallery .hover-10 img {
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-10:hover img {
  -webkit-filter: grayscale(100%) blur(3px);
  filter: grayscale(100%) blur(3px);
}
.gallery .hover-11 img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-11:hover img {
  opacity: 0.5;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.gallery .hover-13:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.gallery .hover-14 div {
  position: relative;
}
.gallery .hover-14 div:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.gallery .hover-14 div:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.gallery .hover-15 div {
  position: relative;
}
.gallery .hover-15 div:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.gallery .hover-15 div:hover:before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
* {
  box-sizing: border-box;
}
*:after, *:before {
  box-sizing: border-box;
}

.grid {
  position: relative;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */
.grid-item img {
  max-width: 100%;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.gallery-with-description .grid-item img {
  border: 0;
  padding: 0;
}

/**=====================
    3.19 Gallery CSS Ends
==========================**/
/**=====================
    3.20 Helper CSS start
==========================**/
.helper-common-box {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
}
.helper-common-box:last-child {
  margin-bottom: 0;
}
.helper-common-box.helper-p-wrapper {
  gap: 12px;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.border-wrapper {
  padding: 20px;
}

.helper-box {
  width: 40px;
  height: 40px;
  border-radius: 25%;
}
.helper-box.helper-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}

.helper-radius {
  width: 60px;
  height: 30px;
}

.fill-wrapper {
  background-color: #F3F3F3;
}

.gradient-border {
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 1200px) {
  .gradient-border {
    gap: calc(13px + 9 * (100vw - 320px) / 880);
  }
}

.radius-wrapper {
  background-color: #EAEAEA;
}

.font-wrapper {
  display: flex;
  align-items: center;
  padding: 12px 20px;
}

.helper-padding {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.helper-p-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.helper-p-box span {
  color: var(--body-font-color);
}

.common-p-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

/**=====================
    3.20 Helper CSS end
==========================**/
/**=====================
    3.21 Internationalization CSS start
==========================**/
.hdg_main {
  position: relative;
}

.main .langChoice {
  position: absolute;
  right: 40px;
  top: 8px;
  padding: 10px 12px;
  color: #307EF3;
  border: 1px solid #307EF3;
  background-color: rgba(48, 126, 243, 0.1);
}

@media (max-width: 991px) {
  .main .langChoice {
    right: 20px;
    top: 11px;
    padding: 8px;
  }
}
@media only screen and (max-width: 575px) {
  .main .langChoice {
    top: 14px;
    padding: 4px 7px;
  }
  .language-xs {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .language-header {
    padding-bottom: 60px !important;
  }
  .main .langChoice {
    left: 0;
    margin: 0 auto;
    top: 50px;
    width: 30%;
    right: 0;
  }
}
/**=====================
    3.21 Internationalization CSS End
==========================**/
/**=====================
    3.22 Job-search CSS Start
==========================**/
.filter-cards-view .job-filter:first-child {
  margin-bottom: 30px;
}
.filter-cards-view .checkbox-animated {
  margin-top: 30px;
  margin-bottom: 30px;
}

.location-checkbox span {
  color: #999999;
  padding-left: 33px;
}
[dir=rtl] .location-checkbox span {
  padding-left: unset;
  padding-right: 33px;
}

.job-accordion .card {
  margin-bottom: 30px;
}
.job-accordion .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.job-search p {
  margin-top: 15px;
}
.job-search .d-flex .flex-grow-1 h6 {
  margin-bottom: 3px;
}
.job-search .d-flex .flex-grow-1 h6 span {
  font-size: 13px;
  color: #999999;
  font-weight: normal;
}
.job-search .d-flex .flex-grow-1 h6 span.badge {
  color: #fff;
}
.job-search .d-flex .flex-grow-1 p {
  margin-top: 0;
  color: #999999;
}
.job-search .job-description {
  margin-top: 30px;
}
.job-search .job-description h3 {
  margin-bottom: 10px;
}
.job-search .job-description h6 {
  font-weight: 600;
}
.job-search .job-description p {
  margin-top: 0;
  margin-bottom: 6px;
}
.job-search .job-description ul {
  list-style-type: disc;
  list-style-position: inside;
}
.job-search .job-description ul li {
  margin-bottom: 10px;
}
.job-search .job-description ul li:last-child {
  margin-bottom: 0;
}
.job-search .job-description .theme-form {
  margin-top: 30px;
  margin-bottom: 30px;
}
.job-search .job-description .theme-form .row div[class^=col-] + div[class^=col-] .select2-container {
  margin-top: 18px;
}
.job-search .job-description .theme-form .row div[class^=col-] .job-select2 ~ .select2-container {
  margin-top: 0 !important;
}
.job-search .job-description .theme-form .row div[class^=col-] .input-group .datepicker-here {
  font-size: 14px;
  border: 1px solid #EAEAEA;
  padding-top: 12px;
  padding-bottom: 12px;
}
.job-search .card-body {
  background-color: rgba(48, 126, 243, 0.02);
}

.select2-container .select2-selection--single {
  border-radius: 0.25rem !important;
  border-color: #EAEAEA;
  height: 47px !important;
  padding: 10px;
}

.job-pagination {
  margin-bottom: 30px;
}
.job-pagination nav ul {
  justify-content: center;
}

@media only screen and (max-width: 1366px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .filter-cards-view {
    padding: 20px !important;
  }
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 20px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .job-accordion .animate-chk {
    padding: 20px !important;
  }
  .job-search p {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .filter-cards-view {
    padding: 15px !important;
  }
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 15px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-accordion .animate-chk {
    padding: 15px !important;
  }
  .job-search p {
    margin-top: 15px;
  }
  .job-search .d-flex {
    display: block;
    text-align: center;
  }
  .job-search .d-flex .flex-grow-1 h6 .pull-right {
    float: none;
    margin-top: 3px;
    display: block;
  }
  .job-search .d-flex .flex-grow-1 h6 a {
    display: block;
  }
  .job-search .d-flex .flex-grow-1 p span + span {
    display: block;
  }
  .job-search .d-flex img {
    margin-bottom: 15px;
  }
  .job-search .d-flex .m-r-20 {
    margin-right: 0;
  }
  .job-search .job-description {
    margin-top: 15px;
  }
  .job-search .job-description .theme-form {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-search .job-description .theme-form .row div[class^=col-] + div[class^=col-] .select2-container {
    margin-top: 2px;
  }
  .job-search .job-description .theme-form .xs-mt-period {
    margin-top: 15px;
  }
  .job-pagination {
    margin-bottom: 15px;
  }
  .xl-mt-job {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .job-search .job-description .btn {
    padding: 6px 12px;
  }
}
.select-date .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 9px;
  right: 12px;
}

/**=====================
    3.22 Job-search CSS End
==========================**/
/**=====================
  3.23 Jsgrid CSS Start
==========================**/
.jsgrid .jsgrid-grid-body .jsgrid-cell {
  padding: 0.75rem;
}
.jsgrid .jsgrid-pager-container {
  text-align: right;
}
.jsgrid .jsgrid-pager {
  padding: 0;
  margin-top: 20px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button {
  margin-right: -5px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button:first-child {
  border-radius: 5px 0 0 5px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button {
  margin-left: -5px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button:last-child {
  border-radius: 0 5px 5px 0;
}
.jsgrid .jsgrid-pager [class*=jsgrid-pager] {
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  border: 1px solid #F3F3F3;
}
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  color: #2c323f;
}
.jsgrid .jsgrid-pager .jsgrid-pager-current-page {
  color: #2c323f;
}
.jsgrid .jsgrid-selected-row > .jsgrid-cell {
  background: #F3F3F3;
  border-color: #fafafa;
}
.jsgrid .jsgrid-header-row > .jsgrid-header-cell {
  padding: 0.75rem;
  font-weight: 500;
}

/**=====================
  3.23 Jsgrid CSS Ends
==========================**/
/**=====================
  3.24 Kanban CSS start
==========================**/
.jkanban-container .btn {
  margin-bottom: 10px;
}
.jkanban-container .note a {
  display: contents;
}

.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background: #F3F3F3;
  border-bottom: 1px solid #ddd;
}
.kanban-board-header.bg-info ~ .kanban-drag {
  background-color: rgba(1, 161, 185, 0.3) !important;
}
.kanban-board-header.bg-warning ~ .kanban-drag {
  background-color: rgba(255, 170, 5, 0.3) !important;
}
.kanban-board-header.bg-success ~ .kanban-drag {
  background-color: rgba(83, 166, 83, 0.3) !important;
}

.kanban-container {
  display: contents;
}
.kanban-container footer:empty {
  display: none;
}

.kanban-container {
  display: contents;
}

.kanban-board {
  margin-bottom: 30px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(33.3333333333% - 30px) !important;
}
.kanban-board .kanban-drag {
  background: #F3F3F3;
}

.kanban-item {
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
}
.kanban-item .kanban-box .badge {
  line-height: 13px;
}
.kanban-item p {
  opacity: 0.7;
}
.kanban-item:last-child {
  margin-bottom: 0;
}
.kanban-item .list {
  display: inline-block;
  opacity: 0.4;
}
.kanban-item .list li {
  display: inline-block;
  font-size: 12px;
  border-right: 1px solid #cccccc;
  padding-right: 5px;
}
.kanban-item .list li i {
  margin-right: 5px;
}
.kanban-item .list li:last-child {
  border: none;
}
.kanban-item .kanban-box {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: block;
  color: inherit;
  background-color: #fff;
}
.kanban-item .kanban-box:hover {
  color: inherit;
}
.kanban-item .kanban-box + .kanban-box {
  margin-top: 20px;
}
.kanban-item .kanban-box .badge {
  font-weight: 400;
}
.kanban-item .kanban-box h6 {
  font-size: 16px;
  margin: 10px 0px 10px 0px;
  font-weight: 500;
}
.kanban-item .kanban-box .dropdown {
  display: inline-block;
  right: 10px;
  position: absolute;
  top: 20px;
}
.kanban-item .kanban-box .dropdown .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  text-align: left;
}
.kanban-item .kanban-box .dropdown svg {
  height: 14px;
}
.kanban-item .kanban-box .d-flex {
  justify-content: space-between;
}
.kanban-item .kanban-box .dropdown-toggle:after {
  content: none;
}
.kanban-item .kanban-box .customers ul li + li {
  margin-left: -10px;
}
.kanban-item .kanban-box .date {
  font-size: 11px;
  opacity: 0.5;
}

footer {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .jkanban-container .btn {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .kanban-container .kanban-board {
    width: 320px !important;
  }
}
@media only screen and (max-width: 600px) {
  .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
    padding: 10px 15px;
  }
  .kanban-container .kanban-board {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 480px) {
  .jkanban-container .card-body {
    padding: 20px 0 !important;
  }
  .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
    padding: 10px 15px;
  }
  .kanban-container .kanban-board {
    width: 270px !important;
  }
}
/**=====================
   3.24 Kanban CSS ends
==========================**/
/**=====================
    3.25 Knowledgebase CSS start
==========================**/
.browse .browse-articles span {
  vertical-align: middle;
  margin-right: 5px;
}
.browse .browse-articles span:nth-child(2) {
  padding-left: 20px;
}
[dir=rtl] .browse .browse-articles span:nth-child(2) {
  padding-left: unset;
  padding-right: 20px;
}
.browse .browse-articles span svg {
  width: 14px;
  height: 20px;
  vertical-align: text-top;
  position: absolute;
  left: 0;
  top: 0;
}
[dir=rtl] .browse .browse-articles span svg {
  left: unset;
  right: 0;
}
.browse .browse-articles h4 {
  font-weight: 500;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  color: #59667a;
  padding-left: 25px;
  position: relative;
  line-height: 1.6;
  font-size: 16px;
}
[dir=rtl] .browse .browse-articles h4 {
  padding-left: unset;
  padding-right: 25px;
}
.browse .browse-articles h4 span svg {
  width: 16px;
  height: 16px;
  vertical-align: text-top;
  top: 4px;
  left: 7px;
}
[dir=rtl] .browse .browse-articles h4 span svg {
  left: unset;
  right: 5px;
}
.browse .browse-articles ul li {
  padding: 8px;
  border-radius: 8px;
}
.browse .browse-articles ul li a {
  color: #363636;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.browse .browse-articles ul li:hover {
  background-color: #fdfeff;
}

@media only screen and (max-width: 1366px) {
  .browse div[class^=col-]:nth-child(3) {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .browse .browse-articles ul li a span:nth-child(2) {
    width: 158px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.badge.pull-right {
  padding-top: 6px;
}

.knowledgebase .bg-primary .card-body {
  background-color: transparent;
}

/**=====================
    3.25 Knowledgebase CSS End
==========================**/
/**=====================
    3.26 Landing CSS Start
==========================**/
::selection {
  background-color: rgba(48, 126, 243, 0.2);
  color: #307EF3;
}

::-moz-selection {
  background-color: #307EF3;
  color: #307EF3;
}

.landing-page {
  position: relative;
}
.landing-page .lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: 4;
}
.landing-page .lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 14%;
  background: rgba(255, 255, 255, 0.05);
  overflow: hidden;
  z-index: 4;
}
.landing-page .lines .line:after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -20%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: drop 30s 10s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.28, 0.26, 0, 0.97);
}
.landing-page .lines .line::before {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -80%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: drop 30s 10s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.28, 0.26, 0, 0.97);
}
.landing-page .lines .line:nth-child(1) {
  left: 5%;
}
.landing-page .lines .line:nth-child(1)::after {
  animation-delay: 3s;
}
.landing-page .lines .line:nth-child(1)::before {
  animation-delay: 5s;
}
.landing-page .lines .line:nth-child(2) {
  left: 15%;
}
.landing-page .lines .line:nth-child(2)::after {
  animation-delay: 4s;
}
.landing-page .lines .line:nth-child(2)::before {
  animation-delay: 6s;
}
.landing-page .lines .line:nth-child(3) {
  left: 25%;
}
.landing-page .lines .line:nth-child(3)::after {
  animation-delay: 3s;
}
.landing-page .lines .line:nth-child(3)::before {
  animation-delay: 5s;
}
.landing-page .lines .line:nth-child(4) {
  left: 35%;
}
.landing-page .lines .line:nth-child(4)::after {
  animation-delay: 4s;
}
.landing-page .lines .line:nth-child(4)::before {
  animation-delay: 6s;
}
.landing-page .lines .line:nth-child(5) {
  left: 45%;
}
.landing-page .lines .line:nth-child(5)::after {
  animation-delay: 3s;
}
.landing-page .lines .line:nth-child(5)::before {
  animation-delay: 5s;
}
.landing-page .lines .line:nth-child(6) {
  left: 55%;
}
.landing-page .lines .line:nth-child(6)::after {
  animation-delay: 4s;
}
.landing-page .lines .line:nth-child(6)::before {
  animation-delay: 6s;
}
.landing-page .lines .line:nth-child(7) {
  left: 65%;
}
.landing-page .lines .line:nth-child(7)::after {
  animation-delay: 3s;
}
.landing-page .lines .line:nth-child(7)::before {
  animation-delay: 5s;
}
.landing-page .lines .line:nth-child(8) {
  left: 75%;
}
.landing-page .lines .line:nth-child(8)::after {
  animation-delay: 4s;
}
.landing-page .lines .line:nth-child(8)::before {
  animation-delay: 6s;
}
.landing-page .lines .line:nth-child(9) {
  left: 85%;
}
.landing-page .lines .line:nth-child(9)::after {
  animation-delay: 3s;
}
.landing-page .lines .line:nth-child(9)::before {
  animation-delay: 5s;
}
.landing-page .lines .line:nth-child(10) {
  left: 95%;
}
.landing-page .lines .line:nth-child(10)::after {
  animation-delay: 4s;
}
.landing-page .lines .line:nth-child(10)::before {
  animation-delay: 6s;
}
.landing-page .section-py-space {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #fff;
  position: relative;
  z-index: 5;
}
.landing-page .section-pt-space {
  padding-top: 70px;
}
.landing-page .section-pb-space {
  padding-bottom: 70px;
}
.landing-page #core-feature, .landing-page #faq-section {
  background-color: #F3F8FF;
}
.landing-page .title {
  margin-bottom: 35px;
}
.landing-page .title h2 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: fit-content;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 0;
}
.landing-page .title h2:before {
  background-color: #EBA31D;
  content: "";
  display: block;
  height: 2px;
  width: 75px;
  margin-bottom: 7px;
}
.landing-page .title h2:after {
  background-color: #307EF3;
  content: "";
  display: block;
  margin-left: auto;
  height: 2px;
  width: 75px;
  margin-top: 5px;
}
.landing-page .custom-container {
  max-width: 1600px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.landing-page .navbar-nav {
  align-items: center;
}
.landing-page .navbar-nav .nav-item {
  position: relative;
}
.landing-page .navbar-nav .nav-item .nav-link {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 12px;
  transition: all 0.3s ease;
  opacity: 0.7;
}
.landing-page .navbar-nav .nav-item .nav-link:hover, .landing-page .navbar-nav .nav-item .nav-link.active {
  opacity: 1;
  text-shadow: 0 0 0 #307EF3;
  transition: all 0.3s ease;
  color: #307EF3;
}
.landing-page .btn-landing {
  border-radius: 5px;
  color: #fff;
  background-color: #307EF3;
  padding: 14px 23px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: fit-content;
}
.landing-page .btn-landing:hover {
  color: #fff;
}
.landing-page .landing-header {
  background-color: #323C58;
  padding-top: 18px;
  padding-bottom: 18px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.landing-page .landing-header ul.landing-menu li.nav-item.menu-back {
  border-bottom: 1px solid #EAEAEA;
  padding: 22px 20px;
  text-align: right;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  display: none;
  cursor: pointer;
  margin-bottom: 15px;
}
.landing-page .landing-header ul.landing-menu li.nav-item.menu-back i {
  font-size: 16px;
  margin-left: 10px;
}
.landing-page .landing-header ul.landing-menu li.nav-item a.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 10px 20px;
  position: relative;
}
.landing-page .landing-header ul.landing-menu li.nav-item:hover a.nav-link {
  color: #EBA31D;
}
.landing-page .landing-header .buy-block {
  display: flex;
  align-items: center;
}
.landing-page .landing-header .buy-block .toggle-menu {
  margin-left: 20px;
  display: none;
  cursor: pointer;
}
.landing-page .landing-header .buy-block .toggle-menu i {
  font-size: 24px;
  color: #fff;
}
.landing-page .landing-home {
  background-image: url("../images/landing/landing-home/home-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.landing-page .landing-home .landing-home-contain {
  margin-top: 90px;
  padding-top: 105px;
  text-align: center;
  position: relative;
  z-index: 9;
}
.landing-page .landing-home .landing-home-contain .landing-icon {
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto 15px;
  padding: 10px;
  border-radius: 15px;
  background-color: #fff;
}
.landing-page .landing-home .landing-home-contain .landing-icon li + li {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
}
.landing-page .landing-home .landing-home-contain .landing-logo {
  margin-bottom: 25px;
}
.landing-page .landing-home .landing-home-contain h2 {
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1.2;
}
.landing-page .landing-home .landing-home-contain h2 span {
  color: #fff;
  font-weight: 800;
  display: block;
}
.landing-page .landing-home .landing-home-contain p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 38px;
  width: 58%;
  margin-left: auto;
  margin-right: auto;
}
.landing-page .landing-home .landing-home-contain .btn-home-list li {
  display: inline-block;
}
.landing-page .landing-home .landing-home-contain .btn-home-list li .btn {
  text-transform: capitalize;
  padding: 13px 23px;
  font-size: 16px;
}
.landing-page .landing-home .landing-home-contain .btn-home-list li:nth-child(n+2) {
  margin-left: 10px;
}
.landing-page .landing-home .landing-card .animation-card img {
  box-shadow: -13px 8px 41px rgba(42, 54, 80, 0.25);
}
.landing-page .landing-home .landing-chart {
  margin-top: 107px;
}
.landing-page .landing-home .landing-chart .animation-card img {
  box-shadow: -13px 8px 41px rgba(42, 54, 80, 0.25);
}
.landing-page .landing-home .landing-chart .animation-card.extra-shadow img {
  box-shadow: -37px 15px 67px rgba(0, 0, 0, 0.5);
  animation: move2 1.2s infinite alternate;
}
.landing-page .landing-home .landing-table {
  margin-top: 118px;
}
.landing-page .landing-home .landing-table .animation-card img {
  box-shadow: -13px 8px 41px rgba(42, 54, 80, 0.25);
}
.landing-page .landing-home .landing-progress {
  margin-top: 52px;
}
.landing-page .landing-home .landing-progress .animation-card img {
  box-shadow: -13px 8px 41px rgba(42, 54, 80, 0.25);
}
.landing-page .landing-home .animation-card {
  margin-bottom: 22px;
  border: none;
}
.landing-page .components .d-flex {
  border: 1px solid #307EF3;
  padding: 18px 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 15px 0 0 0px;
  transition: all 0.4s;
}
.landing-page .components .d-flex span {
  position: relative;
  padding: 10px;
  background: rgba(48, 126, 243, 0.1);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-page .components .d-flex span::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  height: 118%;
  width: 118%;
  border-radius: 50px;
  border-width: 1px;
  border-style: dashed;
  border-color: #307EF3;
}
.landing-page .components .d-flex svg {
  width: 35px;
  height: 35px;
  fill: #307EF3;
}
.landing-page .components .d-flex:hover {
  background-color: #307EF3;
  transform: translateY(-5px);
  transition: all 0.4s;
}
.landing-page .components .d-flex:hover h3 {
  color: #fff;
}
.landing-page .components .d-flex:hover span {
  background: #fff;
}
.landing-page .components .d-flex:hover span::before {
  border-color: #fff;
  animation: rotate 8s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes move1 {
  0% {
    transform: rotate(0deg) translateX(15px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(15px) rotate(-360deg);
  }
}
@keyframes move2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
.landing-page .demo-section .demo-box {
  background-color: #F3F8FF;
  padding: 20px;
  border-radius: 5px;
}
.landing-page .demo-section .demo-box .img-wrraper {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.landing-page .demo-section .demo-box .img-wrraper img {
  transition: all 0.5s ease;
  width: 100%;
}
.landing-page .demo-section .demo-box:hover .img-wrraper img {
  transform: scale(1.02);
}
.landing-page .demo-section .demo-box .d-flex {
  align-items: center;
  margin-bottom: 20px;
}
.landing-page .demo-section .demo-box .d-flex .dashboard-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-page .demo-section .demo-box .d-flex .dashboard-icon svg {
  width: 18px;
  height: 18px;
  fill: #307EF3;
}
.landing-page .demo-section .demo-box .d-flex .flex-grow-1 h6 {
  font-size: 18px;
  font-weight: 500;
}
.landing-page .demo-section .demo-box .d-flex .flex-shrink-0 {
  display: flex;
  align-items: center;
  gap: 5px;
}
.landing-page .demo-section .demo-box .d-flex .flex-shrink-0 span {
  color: #307EF3;
  font-weight: 500;
}
.landing-page .demo-section .demo-box .d-flex .flex-shrink-0 svg {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  transition: all 0.4s;
}
.landing-page .demo-section .demo-box h5 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  color: #363636;
  transition: all 0.4s;
}
.landing-page .demo-section .demo-box:hover h5 {
  color: #307EF3;
  transition: all 0.4s;
}
.landing-page .demo-section .demo-box:hover .flex-shrink-0 svg {
  margin-left: 5px;
  transition: all 0.4s;
}
.landing-page .demo-section .demo-block {
  margin-bottom: -26px;
  padding: 0 28px;
  justify-content: center;
}
.landing-page .demo-section .demo-block > div {
  margin-bottom: 26px;
}
.landing-page .unique-cards {
  background-color: #fff;
}
.landing-page .unique-cards .img-wrraper {
  border-radius: 10px;
  overflow: hidden;
}
.landing-page .framework {
  background-color: #F3F8FF;
}
.landing-page .framework .nav {
  margin-bottom: 50px;
}
.landing-page .framework .nav .nav-item:nth-child(n+2) {
  margin-left: 20px;
}
.landing-page .framework .nav .nav-link {
  border-radius: 10px;
  padding: 15px 30px;
  align-items: center;
  background-color: rgba(48, 126, 243, 0.8);
  position: relative;
}
.landing-page .framework .nav .nav-link h5 {
  font-weight: 700;
  color: #fff;
}
.landing-page .framework .nav .nav-link p {
  color: #fff;
}
.landing-page .framework .nav .nav-link.active, .landing-page .framework .nav .nav-link.show {
  background-color: rgba(48, 126, 243, 0.8);
}
.landing-page .framework .nav .nav-link.active:before, .landing-page .framework .nav .nav-link.show:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 25px solid rgba(48, 126, 243, 0.8);
}
.landing-page .framework .nav .nav-link img {
  height: 35px;
  margin-right: 15px;
}
.landing-page .framework ul.framworks-list {
  text-align: center;
}
.landing-page .framework ul.framworks-list li {
  display: inline-block;
  background: #fff;
  padding: 40px 10px;
  margin-bottom: 30px;
  border-radius: 25px;
  margin: 10px 0 0 10px;
  height: 160px;
  width: 160px;
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
  box-shadow: 0 3px 9px 3px rgba(169, 184, 200, 0.15);
}
.landing-page .framework ul.framworks-list li h5 {
  margin-top: 10px;
  margin-bottom: unset;
  transition: all 0.5s ease;
}
.landing-page .framework ul.framworks-list li img {
  transition: all 0.5s ease;
}
.landing-page .framework ul.framworks-list li:hover img {
  transform: scale(0.9);
}
.landing-page .framework ul.framworks-list li:hover h5 {
  color: #307EF3;
}
.landing-page .core-feature {
  background-color: #fff;
}
.landing-page .core-feature .feature-block {
  margin-bottom: -25px;
  justify-content: center;
}
.landing-page .core-feature .feature-block > div {
  margin-bottom: 25px;
}
.landing-page .core-feature .feature-box {
  border: 1px solid #EAEAEA;
  padding: 35px 30px;
  text-align: center;
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}
.landing-page .core-feature .feature-box .icon-wrraper {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  margin-bottom: 20px;
  background-color: rgba(48, 126, 243, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease;
}
.landing-page .core-feature .feature-box .icon-wrraper svg {
  width: 40%;
  height: auto;
  color: #307EF3;
  transition: all 0.2s ease;
}
.landing-page .core-feature .feature-box h4 {
  font-weight: 500;
}
.landing-page .core-feature .feature-box h3 {
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 700;
}
.landing-page .core-feature .feature-box p {
  font-size: 16px;
  color: #999999;
  margin-bottom: unset;
}
.landing-page .core-feature .feature-box:hover .icon-wrraper {
  background-color: #307EF3;
}
.landing-page .core-feature .feature-box:hover .icon-wrraper svg {
  color: #fff;
}
.landing-page #Applications .demo-block figure {
  margin-bottom: 26px;
}
.landing-page .color-primary {
  background-color: #6362e7;
}
.landing-page .color-secondary {
  background-color: #10539c;
}
.landing-page .color-success {
  background-color: #2C5F2D;
}
.landing-page .color-danger {
  background-color: #0E7C7B;
}
.landing-page .color-info {
  background-color: #5f4b8b;
}
.landing-page .color-light {
  background-color: #c38c81;
}
.landing-page .color-theme {
  background: url("../images/landing/landing-home/home-bg.png");
}
.landing-page .color-theme .owl-theme h3 {
  padding: 18px 0;
  text-align: center;
  margin: 0;
}
.landing-page .counter-sec {
  background-color: #fff;
}
.landing-page .counter-sec .counter-box {
  text-align: center;
  border: 1px solid #EAEAEA;
  padding: 25px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}
.landing-page .counter-sec .counter-box .count-number {
  background-color: rgba(48, 126, 243, 0.1);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  transition: all 0.5s ease;
}
.landing-page .counter-sec .counter-box .count-number h3 {
  font-size: 40px;
  margin-bottom: unset;
  color: #307EF3;
  transition: all 0.5s ease;
}
.landing-page .counter-sec .counter-box .count-detail h4 {
  text-transform: capitalize;
}
.landing-page .counter-sec .counter-box .count-detail p {
  font-size: 16px;
  color: #999999;
  margin-bottom: unset;
}
.landing-page .counter-sec .counter-box:hover .count-number {
  background-color: #307EF3;
}
.landing-page .counter-sec .counter-box:hover .count-number h3 {
  color: #fff;
}
.landing-page .faq-section .title h2 {
  margin-bottom: 12px;
}
.landing-page .faq-section .title h2 span {
  color: #EBA31D;
}
.landing-page .faq-section .title p {
  text-align: center;
  color: #6c757d;
  font-size: 18px;
}
.landing-page .faq-section .faq-block {
  margin-bottom: -25px;
  justify-content: center;
}
.landing-page .faq-section .faq-block > div {
  margin-bottom: 25px;
}
.landing-page .faq-section .faq-box {
  border: 1px solid #EAEAEA;
  padding: 45px 30px;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}
.landing-page .faq-section .faq-box h4 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}
.landing-page .faq-section .faq-box p {
  font-size: 16px;
  color: #999999;
  margin-bottom: unset;
}
.landing-page .faq-section .faq-box:hover {
  transform: translateY(-5px);
  background-color: #307EF3;
  transition: all 0.4s;
}
.landing-page .faq-section .faq-box:hover h4 {
  color: #fff;
}
.landing-page .faq-section .faq-box:hover p {
  color: rgba(255, 255, 255, 0.6);
}
.landing-page .faq-section .faq-box:hover p a {
  color: #EBA31D;
}
.landing-page .landing-footer .footer-contain {
  text-align: center;
}
.landing-page .landing-footer .footer-contain img {
  margin-bottom: 20px;
}
.landing-page .landing-footer .footer-contain h2 {
  font-size: calc(18px + 17 * (100vw - 320px) / 1600);
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1.2;
}
.landing-page .landing-footer .footer-contain .star-rate {
  margin-bottom: 40px;
}
.landing-page .landing-footer .footer-contain .star-rate li {
  display: inline-block;
}
.landing-page .landing-footer .footer-contain .star-rate li i {
  font-size: calc(16px + 14 * (100vw - 320px) / 1600);
}
.landing-page .landing-footer .footer-contain .btn-footer a:nth-child(n+2) {
  margin-left: 10px;
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
.tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  color: #fff;
  background: var(--theme-default);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-default);
  opacity: 0.5;
  transition: all 0.3s ease;
}
.tap-top:hover {
  transition: all 0.3s ease;
  opacity: 1;
}
.tap-top svg {
  width: 20px;
}

.gradient-10 {
  background: linear-gradient(140deg, #4A86FB 45%, #EBA31D 68.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.classic-effect {
  position: relative;
  overflow: hidden;
}
.classic-effect:before, .classic-effect:after {
  background: var(--theme-default);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s steps(4);
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}
.classic-effect:before {
  left: 0;
  transition-delay: 0s;
}
.classic-effect:after {
  left: 25%;
  transition-delay: 0.025s;
}
.classic-effect span:before, .classic-effect span:after {
  background: var(--theme-default);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s steps(4);
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}
.classic-effect span:before {
  left: 50%;
  transition-delay: 0.05s;
}
.classic-effect span:after {
  left: 75%;
  transition-delay: 0.15s;
}
.classic-effect:hover:before, .classic-effect:hover:after {
  bottom: 0;
  opacity: 0.5;
}
.classic-effect:hover span:before, .classic-effect:hover span:after {
  bottom: 0;
  opacity: 0.5;
}

.action .shap-block .rounded-shap i {
  right: -1%;
  top: -55%;
}

.action-app .shap-block .rounded-shap i {
  left: -1%;
  top: -75%;
}

.action-components .shap-block .rounded-shap i {
  right: -1%;
  top: -55%;
}

.action-count .shap-block .rounded-shap i {
  left: -1%;
  top: -20%;
}

.shap-block .rounded-shap i {
  background-color: rgba(48, 126, 243, 0.2);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
  z-index: 0;
}
.shap-block .rounded-shap i:first-child {
  animation: ripple1 3s linear infinite;
}
.shap-block .rounded-shap i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.shap-block .rounded-shap i:nth-child(3) {
  animation: ripple3 3s linear infinite;
}

.animated-bg i {
  background-color: #fff;
  box-shadow: 0 15px 30px 0 #fff;
  position: absolute;
  height: 8%;
  width: 8%;
  border-radius: 50%;
  opacity: 0.3;
  transform: scale(1.3);
  animation: ripple1 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
}
.animated-bg i:first-child {
  animation: ripple1 3s linear infinite;
}
.animated-bg i:nth-child(2) {
  animation: ripple2 3s linear infinite;
}
.animated-bg i:nth-child(3) {
  animation: ripple3 3s linear infinite;
}

@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2.5);
  }
}
/**=====================
    3.26 Landing CSS Ends
==========================**/
/**=====================
    3.27 Language CSS start
==========================**/
.current_lang {
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
}

.lang.selected {
  display: none;
}
.lang span.lang-txt {
  display: inline-flex;
  margin-left: 8px;
  font-weight: 500;
}
.lang span span {
  color: #999;
  font-weight: 400;
  margin-left: 5px;
}

/*more lang*/
.more_lang {
  transform: translateY(-20px);
  opacity: 0;
  cursor: pointer;
  display: none;
  transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
}

.translate_wrapper.active .more_lang {
  display: block;
  position: absolute;
  background-color: #fff;
  top: 47px;
  right: 0;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
}
.translate_wrapper.active .more_lang:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  right: 20px;
  z-index: 2;
}
.translate_wrapper.active .more_lang:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #bdc2c5;
  content: "";
  top: -7px;
  position: absolute;
  right: 20px;
  z-index: 1;
}

.more_lang.active {
  opacity: 1;
  transform: translateY(0px);
}
.more_lang .lang {
  padding: 10px 15px;
  display: flex;
}
.more_lang .lang:hover {
  background: var(--theme-default);
  color: #fff;
}
.more_lang .lang:hover span {
  color: #fff;
}

.translate_wrapper.active .more_lang .lang {
  border-bottom: 1px solid #eaeaea;
}

/**=====================
    3.27 Language CSS end
==========================**/
/**=====================
     3.28 Learning CSS start
==========================**/
.learning-header {
  margin-bottom: 10px;
}

.categories {
  padding: 0;
}
.categories .learning-header {
  padding-left: 18px;
}
.categories ul li {
  padding: 10px 18px;
  transition: all 0.4s;
}
.categories ul li a {
  color: #363636;
}
.categories ul li:hover {
  background-color: rgba(48, 126, 243, 0.1);
  transition: all 0.4s;
}

.upcoming-course .d-flex + .d-flex {
  margin-top: 30px;
}

.job-accordion {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .learning-comment {
    margin-left: -14px !important;
    float: left !important;
  }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 30px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .categories {
    padding: 20px 0;
  }
  .upcoming-course .d-flex + .d-flex {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .categories {
    padding: 15px 0;
  }
  .upcoming-course .d-flex + .d-flex {
    margin-top: 15px;
  }
}
/**=====================
     3.28 Learning CSS end
==========================**/
/**=====================
    3.29 Login CSS Start
==========================**/
.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: url(../images/other-images/coming-soon-bg.jpg);
  background-position: center;
  padding: 30px 12px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}
.login-card .logo {
  display: block;
  margin-bottom: 30px;
  text-align: center;
}
.login-card .btn-showcase .btn {
  line-height: 1;
  padding: 10px 15px;
  margin: 0;
}
.login-card .btn-showcase .btn + .btn {
  margin-left: 5px;
}
.login-card .btn-showcase .btn svg {
  height: 16px;
  vertical-align: bottom;
}
.login-card .login-main {
  width: 450px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  margin: 0 auto;
  background-color: #fff;
}
.login-card .login-main .theme-form h4 {
  margin-bottom: 5px;
}
.login-card .login-main .theme-form label {
  font-size: 15px;
  letter-spacing: 0.4px;
}
.login-card .login-main .theme-form .checkbox label::before {
  background-color: #f9f9fa;
  border: 1px solid #dfdfdf;
}
.login-card .login-main .theme-form .or {
  position: relative;
}
.login-card .login-main .theme-form .or:before {
  content: "";
  position: absolute;
  width: 65%;
  height: 2px;
  background-color: rgba(48, 126, 243, 0.1);
  top: 9px;
  z-index: 0;
  right: 0;
}
.login-card .login-main .theme-form input {
  background-color: rgba(48, 126, 243, 0.1);
  transition: all 0.3s ease;
}
.login-card .login-main .theme-form input::-webkit-input-placeholder {
  color: #999999;
}
.login-card .login-main .theme-form input:hover, .login-card .login-main .theme-form input:focus {
  border: 1px solid #b9c1cc;
  box-shadow: none !important;
  transition: all 0.3s ease;
}
.login-card .login-main .theme-form p {
  margin-bottom: 25px;
  font-size: 14px;
  color: #9B9B9B;
}
.login-card .login-main .theme-form .form-group {
  margin-bottom: 10px;
  position: relative;
}
.login-card .login-main .theme-form .link {
  position: absolute;
  top: 10px;
  right: 0;
}

.show-hide {
  position: absolute;
  top: 52px;
  right: 20px;
  transform: translateY(-50%);
}
.show-hide span {
  cursor: pointer;
  font-size: 13px;
  color: var(--theme-default);
}
.show-hide span.show:before {
  content: "show";
}
.show-hide span:before {
  content: "hide";
}

.needs-validation .invalid-feedback {
  color: #9B9B9B;
}
.needs-validation .show-hide {
  right: 30px;
}
.needs-validation .invalid-tooltip {
  right: 0px;
}
.needs-validation .invalid-tooltip.username_tootip {
  top: 36px;
  right: 0;
}

/**=====================
    3.29 Login CSS Ends
==========================**/
/**=====================
	3.30 Megaoption CSS Start
==========================**/
.mega-inline {
  margin-bottom: -30px;
  display: flex;
}
.mega-inline .card {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.mega-inline .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mega-inline .d-flex .radio {
  margin-left: 3px;
}
[dir=rtl] .mega-inline .d-flex .radio {
  margin-right: 3px;
  margin-left: unset;
}

.mega-vertical {
  display: flex;
  flex-wrap: wrap;
}
.mega-vertical .card {
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.mega-vertical .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mega-vertical .d-flex .radio {
  margin-left: 3px;
}

.mega-title {
  font-weight: 600;
}

.mega-horizontal {
  display: flex;
  flex-wrap: wrap;
}
.mega-horizontal .card {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.mega-horizontal .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mega-horizontal .d-flex .radio {
  margin-left: 3px;
}

.plain-style .card {
  box-shadow: none;
  border: none !important;
}

.border-style .card {
  box-shadow: none;
  border: 1px solid #EAEAEA !important;
}

.offer-style .card {
  box-shadow: none;
  border: 1px dashed #EAEAEA !important;
}

.rating-star-wrapper {
  color: #797979;
}

.card-wrapper .payment-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--chart-dashed-border);
}
.card-wrapper .payment-wrapper:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: unset;
}
.card-wrapper .payment-wrapper .payment-second img {
  width: 50px;
}
.card-wrapper .payment-wrapper .payment-second .mega-icons {
  width: 20px;
  height: 20px;
}

.main-upgrade {
  margin: 0 auto;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-upgrade > div:first-child {
  margin-bottom: 20px;
}
.main-upgrade i {
  font-size: calc(40px + 10 * (100vw - 320px) / 1600);
  display: block;
  color: #307EF3;
}
.main-upgrade p {
  line-height: 1.4;
}
.main-upgrade .variation-box {
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .main-upgrade .variation-box {
    display: grid;
    grid-template-columns: auto auto;
    gap: 18px;
  }
}
@media (max-width: 700px) {
  .main-upgrade .variation-box {
    display: grid;
    grid-template-columns: auto auto;
    gap: 18px;
  }
}
@media (max-width: 380px) {
  .main-upgrade .variation-box {
    grid-template-columns: unset;
  }
}
.main-upgrade .variation-box .selection-box {
  position: relative;
  height: 100px;
  border: 1px dashed #307EF3;
  min-width: 125px;
  border-radius: 15px;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .main-upgrade .variation-box .selection-box {
    min-width: calc(110px + 15 * (100vw - 1200px) / 720);
  }
}
.main-upgrade .variation-box .selection-box .custom--mega-checkbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  transition: 0.5s ease;
}
.main-upgrade .variation-box .selection-box input {
  position: absolute;
  top: 0;
  left: 0;
  width: 118px;
  height: 96px;
  opacity: 0;
  cursor: pointer;
}
[dir=rtl] .main-upgrade .variation-box .selection-box input {
  left: unset;
  right: 0;
}
.main-upgrade .variation-box .selection-box input[type=checkbox]:checked ~ div {
  background-color: rgba(48, 126, 243, 0.2);
  border-radius: 15px;
}

.megaoptions-border-space-sm .d-flex .radio label {
  padding-left: 18px;
}
[dir=rtl] .megaoptions-border-space-sm .d-flex .radio label {
  padding-left: unset;
  padding-right: 18px;
}
@media (min-width: 618px) and (max-width: 1199px) {
  .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 {
    flex-direction: row;
    gap: 18px;
  }
}
@media (max-width: 618px) {
  .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 {
    flex-direction: column;
    gap: 12px;
  }
}
.megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 .flex-grow-1 {
  margin-top: -6px;
}
@media (max-width: 618px) {
  .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 .flex-grow-1 {
    margin-left: 0px;
  }
  [dir=rtl] .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 .flex-grow-1 {
    margin-left: unset;
    margin-right: 0;
  }
}
.megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1:first-child {
  margin-bottom: 0;
}

.megaoption-space .badge.pull-right,
.megaoptions-border-space-sm .badge.pull-right {
  margin-bottom: 12px;
}

/**=====================
    3.30 Megaoption CSS Ends
==========================**/
/**=====================
    3.31 Order-history CSS start
==========================**/
.prooduct-details-box .close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 16px;
  cursor: pointer;
}
.prooduct-details-box .btn {
  line-height: 13px;
}
.prooduct-details-box .d-flex {
  border: 1px solid #d7e2e9;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  width: 100%;
  position: relative;
}
.prooduct-details-box .price.d-flex {
  border: none !important;
  padding: 0;
}
.prooduct-details-box .rating {
  color: #ffaf45;
  letter-spacing: 3px;
}
.prooduct-details-box .product-name a {
  color: gray;
}
.prooduct-details-box .product-name h6 {
  margin-bottom: 0;
}
.prooduct-details-box .btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.order-history .input-group {
  text-align: right;
  max-width: 300px;
}
.order-history table th,
.order-history table td {
  text-align: center;
}
.order-history table thead tr th {
  vertical-align: middle;
  border-top: none;
  background-color: white;
  border: 1px solid #EAEAEA;
  padding: 1.25rem;
  font-size: 16px;
}
.order-history table .qty-box {
  width: 120px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}
.order-history table .qty-box input {
  width: 44px;
  max-width: 44px;
  background-color: transparent;
  border: none !important;
}
.order-history table .qty-box .input-group {
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #EAEAEA;
  overflow: hidden;
}
.order-history table .qty-box .btn {
  background-color: transparent;
  border: none;
}
.order-history table .qty-box .btn.bootstrap-touchspin-down {
  border-right: 1px solid;
}
[dir=rtl] .order-history table .qty-box .btn.bootstrap-touchspin-down {
  border-right: unset;
  border-left: 1px solid;
}
.order-history table .qty-box .btn.bootstrap-touchspin-up {
  border-left: 1px solid;
}
[dir=rtl] .order-history table .qty-box .btn.bootstrap-touchspin-up {
  border-left: unset;
  border-right: 1px solid;
}
.order-history table tbody tr td {
  vertical-align: middle;
}
.order-history table tbody tr td .product-name a {
  font-size: 15px;
  color: #9B9B9B;
}
.order-history table tbody tr td .product-name .order-process {
  font-size: 13px;
  padding-left: 15px;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle {
  position: relative;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #9B9B9B;
  left: -15px;
  top: -6px;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle.shipped-order:before {
  background-color: #53A653;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle.cancel-order:before {
  background-color: #DC3545;
}

@media only screen and (max-width: 1199px) {
  .order-history table {
    width: 900px;
    overflow: auto;
  }
}
/**=====================
    3.31 Order-history CSS End
==========================**/
/**=====================
  3.32 Page CSS Start
==========================**/
/*test Nav bar color */
.pagination-theme .page-item .page-link {
  color: var(--theme-default) !important;
}
.pagination-theme .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default);
}

.pagination-primary .page-item .page-link {
  color: var(--theme-default) !important;
}
.pagination-primary .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default);
}

.pagination-secondary .page-item .page-link {
  color: #EBA31D !important;
}
.pagination-secondary .page-item.active .page-link {
  color: #fff !important;
  background-color: #EBA31D !important;
  border-color: #EBA31D;
}

.pagination-success .page-item .page-link {
  color: #53A653 !important;
}
.pagination-success .page-item.active .page-link {
  color: #fff !important;
  background-color: #53A653 !important;
  border-color: #53A653;
}

.pagination-danger .page-item .page-link {
  color: #DC3545 !important;
}
.pagination-danger .page-item.active .page-link {
  color: #fff !important;
  background-color: #DC3545 !important;
  border-color: #DC3545;
}

.pagination-info .page-item .page-link {
  color: #01A1B9 !important;
}
.pagination-info .page-item.active .page-link {
  color: #fff !important;
  background-color: #01A1B9 !important;
  border-color: #01A1B9;
}

.pagination-dark .page-item .page-link {
  color: #2c323f !important;
}
.pagination-dark .page-item.active .page-link {
  color: #fff !important;
  background-color: #2c323f !important;
  border-color: #2c323f;
}

.pagination-warning .page-item .page-link {
  color: #FFAA05 !important;
}
.pagination-warning .page-item.active .page-link {
  color: #fff !important;
  background-color: #FFAA05 !important;
  border-color: #FFAA05;
}

.pagin-border-primary .page-item.active, .pagin-border-primary .page-item:active {
  background-color: rgba(48, 126, 243, 0.2);
}
.pagin-border-primary .page-item .page-link {
  border-color: rgba(48, 126, 243, 0.2);
}
.pagin-border-primary .page-item .page-link:hover, .pagin-border-primary .page-item .page-link:focus {
  background-color: rgba(48, 126, 243, 0.2);
}
.pagin-border-primary .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-secondary .page-item.active, .pagin-border-secondary .page-item:active {
  background-color: #FFE0E8;
}
.pagin-border-secondary .page-item .page-link {
  border-color: #FFE0E8;
}
.pagin-border-secondary .page-item .page-link:hover, .pagin-border-secondary .page-item .page-link:focus {
  background-color: #FFE0E8;
}
.pagin-border-secondary .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-success .page-item.active, .pagin-border-success .page-item:active {
  background-color: #D6EED4;
}
.pagin-border-success .page-item .page-link {
  border-color: #D6EED4;
}
.pagin-border-success .page-item .page-link:hover, .pagin-border-success .page-item .page-link:focus {
  background-color: #D6EED4;
}
.pagin-border-success .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-danger .page-item.active, .pagin-border-danger .page-item:active {
  background-color: #FEDDDB;
}
.pagin-border-danger .page-item .page-link {
  border-color: #FEDDDB;
}
.pagin-border-danger .page-item .page-link:hover, .pagin-border-danger .page-item .page-link:focus {
  background-color: #FEDDDB;
}
.pagin-border-danger .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-warning .page-item.active, .pagin-border-warning .page-item:active {
  background-color: #FFF0D2;
}
.pagin-border-warning .page-item .page-link {
  border-color: #FFF0D2;
}
.pagin-border-warning .page-item .page-link:hover, .pagin-border-warning .page-item .page-link:focus {
  background-color: #FFF0D2;
}
.pagin-border-warning .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-info .page-item.active, .pagin-border-info .page-item:active {
  background-color: #D5F5FE;
}
.pagin-border-info .page-item .page-link {
  border-color: #D5F5FE;
}
.pagin-border-info .page-item .page-link:hover, .pagin-border-info .page-item .page-link:focus {
  background-color: #D5F5FE;
}
.pagin-border-info .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-light .page-item.active, .pagin-border-light .page-item:active {
  background-color: #F9F9FB;
}
.pagin-border-light .page-item .page-link {
  border-color: #F9F9FB;
}
.pagin-border-light .page-item .page-link:hover, .pagin-border-light .page-item .page-link:focus {
  background-color: #F9F9FB;
}
.pagin-border-light .page-item .page-link:focus {
  box-shadow: unset;
}

.pagin-border-dark .page-item.active, .pagin-border-dark .page-item:active {
  background-color: #EAEBF2;
}
.pagin-border-dark .page-item .page-link {
  border-color: #EAEBF2;
}
.pagin-border-dark .page-item .page-link:hover, .pagin-border-dark .page-item .page-link:focus {
  background-color: #EAEBF2;
}
.pagin-border-dark .page-item .page-link:focus {
  box-shadow: unset;
}

@media (max-width: 576px) {
  .pagination-lg .page-item .page-link {
    font-size: calc(15px + 5 * (100vw - 320px) / 256);
    padding: calc(12px + 0 * (100vw - 320px) / 256) calc(12px + 12 * (100vw - 320px) / 256);
  }
}

@media (max-width: 339px) {
  .pagination {
    justify-content: center;
  }
}
.pagination .page-item:active {
  background-color: unset;
}
.pagination .page-item .page-link.rounded-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}

/**=====================
    3.32 Page CSS Ends
==========================**/
/**=====================
    3.33 Pricing CSS Start
==========================**/
.pricing-simple {
  box-shadow: 0 0 10px 5px rgba(68, 102, 242, 0.05);
  margin-bottom: 0 !important;
}
.pricing-simple .card-body {
  border-bottom: none;
}
.pricing-simple .card-body h3 {
  position: relative;
  margin-bottom: 38px;
}
.pricing-simple .card-body h3:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -10px;
  background-color: #999999;
}
.pricing-simple .card-body h2 {
  font-size: 30px;
  line-height: 0.9;
  margin-bottom: 30px;
  font-weight: 400;
}

.pricingtable {
  text-align: center;
  padding-top: 45px;
  padding-bottom: 45px;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
}
.pricingtable:before, .pricingtable:after {
  content: "";
  background-color: var(--theme-default);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: absolute;
  right: -213px;
  z-index: -1;
  opacity: 0.1;
}

.pricingtable:after {
  background-color: var(--theme-default);
  height: 300px;
  width: 300px;
  top: auto;
  bottom: -210px;
  right: auto;
  left: -160px;
  opacity: 0.1;
}
.pricingtable .title {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.pricingtable .price-value {
  color: #fff;
  background-color: var(--theme-default);
  width: 130px;
  height: 130px;
  padding: 45px 0 0;
  margin: 0 auto 25px;
  border-radius: 50%;
}
.pricingtable .price-value .duration {
  margin: 0 0 0 -5px;
}
.pricingtable .price-value .amount {
  font-size: 58px;
  font-weight: 700;
  line-height: 45px;
  display: inline-block;
}
.pricingtable .price-value .currency {
  vertical-align: top;
}
.pricingtable .pricing-content {
  margin: 0 0 25px;
  display: inline-block;
}
.pricingtable .pricing-content li {
  font-weight: 500;
  text-align: left;
  margin: 0 0 20px;
  position: relative;
  padding-left: 30px;
}
.pricingtable .pricing-content li:last-child {
  margin: 0;
}
.pricingtable .pricing-content li:before {
  content: "\f00c";
  color: var(--theme-default);
  font-family: FontAwesome;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 0;
}

@media (max-width: 1470px) {
  .pricing-block > div {
    flex: 50%;
  }
  .pricing-block > div:nth-child(n+3) {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .pricing-simple .card-body h1 {
    margin-bottom: 20px;
  }
  .pricing-simple .card-body h3 {
    margin-bottom: 28px;
  }
  .pricing-block > div:nth-child(n+2) {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .pricing-simple .card-body h1 {
    margin-bottom: 15px;
  }
  .pricing-block > div {
    flex: 100%;
  }
  .pricingtable {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
/**=====================
   3.33 Pricing CSS Ends
==========================**/
/**=====================
   3.34 Progress CSS Start
==========================**/
.progress-showcase .progress {
  margin-bottom: 10px;
}
.progress-showcase .progress:last-child {
  margin-bottom: 0;
}

.sm-progress-bar {
  height: 8px;
}

.lg-progress-bar {
  height: 20px;
}

.progress-wrapper {
  height: 1px;
}

.progress-btn {
  background-color: #f6f9fd;
  border-color: #f6f9fd;
}

.small-progressbar {
  position: relative;
  overflow: visible;
}
.small-progressbar::after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #307EF3;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
[dir=rtl] .small-progressbar::after {
  left: -9px;
  right: unset;
}
.small-progressbar .progress-label {
  position: absolute;
  top: -26px;
  right: -6px;
  font-weight: 700;
}
[dir=rtl] .small-progressbar .progress-label {
  right: unset;
  left: -6px;
}
.small-progressbar .animate-circle {
  position: absolute;
  top: -11px;
  right: -18px;
  height: 30px;
  width: 30px;
  border: 14px solid #307EF3;
  border-radius: 70px;
  animation: heart 1s ease-in-out;
  animation-iteration-count: infinite;
}
[dir=rtl] .small-progressbar .animate-circle {
  right: unset;
  left: -18px;
}

@keyframes heart {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.progress-b-space .progress {
  margin-bottom: 35px;
}

/**=====================
   3.34 Progress CSS Ends
==========================**/
/**=====================
   3.35 Projectlist CSS Start
==========================**/
.project-list .row {
  margin: 15px;
}
.project-list button:focus {
  outline: none !important;
}
.project-list .theme-form .form-group {
  margin-bottom: 15px;
}
.project-list .border-tab.nav-tabs .nav-item .nav-link {
  border: 1px solid transparent;
  padding: 5px 30px 5px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.project-list .btn {
  margin-right: 5px;
  vertical-align: -12px;
  float: right;
}
[dir=rtl] .project-list .btn {
  float: left;
}
.project-list .btn svg {
  vertical-align: sub;
  height: 16px;
}
.project-list ul {
  margin-bottom: 0 !important;
  border-bottom: 0;
}
.project-list ul li svg {
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}

.tab-content .row > div.col-xl-4:nth-child(1) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 100000ms;
}
.tab-content .row > div.col-xl-4:nth-child(2) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 200000ms;
}
.tab-content .row > div.col-xl-4:nth-child(3) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 300000ms;
}
.tab-content .row > div.col-xl-4:nth-child(4) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 400000ms;
}
.tab-content .row > div.col-xl-4:nth-child(5) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 500000ms;
}
.tab-content .row > div.col-xl-4:nth-child(6) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 600000ms;
}
.tab-content .row > div.col-xl-4:nth-child(7) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 700000ms;
}
.tab-content .row > div.col-xl-4:nth-child(8) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 800000ms;
}
.tab-content .row > div.col-xl-4:nth-child(9) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 900000ms;
}
.tab-content .row > div.col-xl-4:nth-child(10) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1000000ms;
}
.tab-content .row > div.col-xl-4:nth-child(11) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1100000ms;
}
.tab-content .row > div.col-xl-4:nth-child(12) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1200000ms;
}
.tab-content .row > div.col-xl-4:nth-child(13) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1300000ms;
}
.tab-content .row > div.col-xl-4:nth-child(14) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1400000ms;
}
.tab-content .row > div.col-xl-4:nth-child(15) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1500000ms;
}

.project-cards .tab-content {
  margin-bottom: -30px;
}

.project-box {
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  padding: 30px;
  background-color: white;
  margin-bottom: 30px;
}
.project-box h3 {
  font-weight: 500 !important;
  margin-bottom: 8px;
}
.project-box .badge {
  position: absolute;
  right: 30px;
  top: 15px;
  line-height: 13px;
}
.project-box .project-status p {
  margin-bottom: 5px;
  font-weight: 800;
}
.project-box .d-flex {
  margin-bottom: 15px;
}
.project-box .d-flex .flex-grow-1 {
  opacity: 0.5;
}
.project-box .details div {
  margin-bottom: 5px;
  opacity: 0.7;
}

.projectdetails .card .card-body {
  padding: 20px;
}
.projectdetails .card .card-footer {
  padding: 20px;
}
.projectdetails .card select {
  width: 90px;
  height: 28px;
  font-size: 11px;
  right: 20px;
  top: 20px;
}

.projectmore .details .col-4 {
  margin-bottom: 5px;
}
.projectmore .details .col-4:nth-child(even) {
  opacity: 0.5;
}
.projectmore h5 {
  font-size: 20px;
  font-weight: 600;
}
.projectmore .task-list {
  position: relative;
}
.projectmore .task-list ul li {
  margin-bottom: 5px;
}
.projectmore .task-list ul + ul {
  position: absolute;
  left: 50%;
  top: 0;
}

.new-users.activity .gradient-round {
  padding: 13px;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  top: unset !important;
}
.new-users.activity .gradient-round::after {
  display: none;
}

/**=====================
   3.35 Projectlist CSS Ends
==========================**/
/**=====================
   3.36 Rating CSS Start
==========================**/
@-webkit-keyframes shake {
  30% {
    transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
  }
  60% {
    transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
  }
  100% {
    transform: perspective(240px) translateZ(4px);
  }
}
@keyframes shake {
  30% {
    transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
  }
  60% {
    transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
  }
  100% {
    transform: perspective(240px) translateZ(4px);
  }
}
@-webkit-keyframes tear {
  0% {
    opacity: 0;
    transform: translateY(-2px) scale(0) translateZ(0);
  }
  50% {
    transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
  }
  20%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
  }
}
@keyframes tear {
  0% {
    opacity: 0;
    transform: translateY(-2px) scale(0) translateZ(0);
  }
  50% {
    transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
  }
  20%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
  }
}
@-webkit-keyframes toggle {
  50% {
    transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
  }
}
@keyframes toggle {
  50% {
    transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
  }
}
@-webkit-keyframes angry {
  40% {
    background: var(--active);
  }
  45% {
    box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
  }
}
@keyframes angry {
  40% {
    background: var(--active);
  }
  45% {
    box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
  }
}
.feedback {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.feedback {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.feedback li {
  position: relative;
  border-radius: 50%;
  background: var(--sb, var(--normal));
  box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
  transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
  -webkit-tap-highlight-color: transparent;
}
.feedback li:not(:last-child) {
  margin-right: 20px;
}
.feedback li div {
  width: 40px;
  height: 40px;
  position: relative;
  transform: perspective(240px) translateZ(4px);
}
.feedback li div svg {
  display: block;
  position: absolute;
  left: var(--l, 9px);
  top: var(--t, 13px);
  width: var(--w, 8px);
  height: var(--h, 2px);
  transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  fill: none;
  stroke: var(--s);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke 0.4s;
}
.feedback li div:before {
  display: block;
  position: absolute;
  left: var(--l, 9px);
  top: var(--t, 13px);
  width: var(--w, 8px);
  height: var(--h, 2px);
  transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  content: "";
  z-index: var(--zi, 1);
  border-radius: var(--br, 1px);
  background: var(--b, var(--e, var(--normal-eye)));
  transition: background 0.4s;
}
.feedback li div:after {
  display: block;
  position: absolute;
  left: var(--l, 9px);
  top: var(--t, 13px);
  width: var(--w, 8px);
  height: var(--h, 2px);
  transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  content: "";
  z-index: var(--zi, 1);
  border-radius: var(--br, 1px);
  background: var(--b, var(--e, var(--normal-eye)));
  transition: background 0.4s;
}
.feedback li div svg.eye {
  --s: var(--e, var(--normal-eye));
  --t: 17px;
  --w: 7px;
  --h: 4px;
}
.feedback li div svg.eye.right {
  --l: 23px;
}
.feedback li div svg.mouth {
  --s: var(--m, var(--normal-mouth));
  --l: 11px;
  --t: 23px;
  --w: 18px;
  --h: 7px;
}
.feedback li:not(.active) {
  cursor: pointer;
}
.feedback li:not(.active):active {
  transform: scale(0.925);
}
.feedback li.angry {
  --step-1-rx: -24deg;
  --step-1-ry: 20deg;
  --step-2-rx: -24deg;
  --step-2-ry: -20deg;
}
.feedback li.angry div:before {
  --r: 20deg;
}
.feedback li.angry div:after {
  --l: 23px;
  --r: -20deg;
}
.feedback li.angry div svg.eye {
  stroke-dasharray: 4.55;
  stroke-dashoffset: 8.15;
}
.feedback li.angry.active {
  -webkit-animation: angry 1s linear;
  animation: angry 1s linear;
}
.feedback li.angry.active div:before {
  --middle-y: -2px;
  --middle-r: 22deg;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.angry.active div:after {
  --middle-y: 1px;
  --middle-r: -18deg;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.sad {
  --step-1-rx: 20deg;
  --step-1-ry: -12deg;
  --step-2-rx: -18deg;
  --step-2-ry: 14deg;
}
.feedback li.sad div:before {
  --b: var(--active-tear);
  --sc: 0;
  --w: 5px;
  --h: 5px;
  --t: 15px;
  --br: 50%;
}
.feedback li.sad div:after {
  --b: var(--active-tear);
  --sc: 0;
  --w: 5px;
  --h: 5px;
  --t: 15px;
  --br: 50%;
  --l: 25px;
}
.feedback li.sad div svg.eye {
  --t: 16px;
}
.feedback li.sad div svg.mouth {
  --t: 24px;
  stroke-dasharray: 9.5;
  stroke-dashoffset: 33.25;
}
.feedback li.sad.active div:before {
  -webkit-animation: tear 0.6s linear forwards;
  animation: tear 0.6s linear forwards;
}
.feedback li.sad.active div:after {
  -webkit-animation: tear 0.6s linear forwards;
  animation: tear 0.6s linear forwards;
}
.feedback li.ok {
  --step-1-rx: 4deg;
  --step-1-ry: -22deg;
  --step-1-rz: 6deg;
  --step-2-rx: 4deg;
  --step-2-ry: 22deg;
  --step-2-rz: -6deg;
}
.feedback li.ok div:before {
  --l: 12px;
  --t: 17px;
  --h: 4px;
  --w: 4px;
  --br: 50%;
  box-shadow: 12px 0 0 var(--e, var(--normal-eye));
}
.feedback li.ok div:after {
  --l: 13px;
  --t: 26px;
  --w: 14px;
  --h: 2px;
  --br: 1px;
  --b: var(--m, var(--normal-mouth));
}
.feedback li.ok.active div:before {
  --middle-s-y: .35;
  -webkit-animation: toggle 0.2s linear forwards;
  animation: toggle 0.2s linear forwards;
}
.feedback li.ok.active div:after {
  --middle-s-x: .5;
  -webkit-animation: toggle 0.7s linear forwards;
  animation: toggle 0.7s linear forwards;
}
.feedback li.good {
  --step-1-rx: -14deg;
  --step-1-rz: 10deg;
  --step-2-rx: 10deg;
  --step-2-rz: -8deg;
}
.feedback li.good div:before {
  --b: var(--m, var(--normal-mouth));
  --w: 5px;
  --h: 5px;
  --br: 50%;
  --t: 22px;
  --zi: 0;
  opacity: 0.5;
  box-shadow: 16px 0 0 var(--b);
  filter: blur(2px);
}
.feedback li.good div:after {
  --sc: 0;
}
.feedback li.good div svg.eye {
  --t: 15px;
  --sc: -1;
  stroke-dasharray: 4.55;
  stroke-dashoffset: 8.15;
}
.feedback li.good div svg.mouth {
  --t: 22px;
  --sc: -1;
  stroke-dasharray: 13.3;
  stroke-dashoffset: 23.75;
}
.feedback li.good.active div svg.mouth {
  --middle-y: 1px;
  --middle-s: -1;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.happy div {
  --step-1-rx: 18deg;
  --step-1-ry: 24deg;
  --step-2-rx: 18deg;
  --step-2-ry: -24deg;
}
.feedback li.happy div:before {
  --sc: 0;
}
.feedback li.happy div:after {
  --b: var(--m, var(--normal-mouth));
  --l: 11px;
  --t: 23px;
  --w: 18px;
  --h: 8px;
  --br: 0 0 8px 8px;
}
.feedback li.happy div svg.eye {
  --t: 14px;
  --sc: -1;
}
.feedback li.happy.active div:after {
  --middle-s-x: .95;
  --middle-s-y: .75;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.active {
  --sb: var(--active);
  --sh: var(--active-shadow);
  --m: var(--active-mouth);
  --e: var(--active-eye);
}
.feedback li.active div {
  -webkit-animation: shake 0.8s linear forwards;
  animation: shake 0.8s linear forwards;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}

/**=====================
   3.36 Rating CSS Ends
==========================**/
/**=====================
    3.37 Social-app CSS start
==========================**/
.user-profile .hovercard .socialheader {
  background: url(../images/social-app/social-image.png);
}
.user-profile .hovercard .user-image .share-icons {
  position: absolute;
  right: 100px;
  top: -27px;
}
.user-profile .hovercard .user-image .share-icons li {
  display: inline-block;
  margin-right: 10px;
}
.user-profile .hovercard .user-image .share-icons li .social-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  border-radius: 100%;
}
.user-profile .hovercard .user-image .share-icons li .social-icon i {
  color: #fff;
  font-size: 25px;
}
.user-profile .hovercard .user-image .share-icons li:last-child {
  margin-right: 0;
}
.user-profile .card-footer > div h3 {
  font-size: 16px;
}

.market-tabs .border-tab.nav-tabs .nav-item .nav-link.active {
  border-bottom: none !important;
}
.market-tabs .nav {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.market-tabs .nav .nav-item {
  width: 100%;
}
.market-tabs .nav .nav-item .nav-link {
  height: 81px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 30px;
  text-align: center;
}

.social-status .d-flex {
  align-items: center;
}
.social-status .d-flex .flex-grow-1 span:first-child {
  color: #363636;
  font-size: 14px;
}

.social-chat .d-flex .flex-grow-1 span {
  font-size: 16px;
}

.tabs-scoial {
  border-bottom: none;
  margin-bottom: 20px !important;
  margin-top: 20px;
}
.tabs-scoial li a {
  font-weight: 600;
}
.tabs-scoial li a svg circle {
  color: #999999;
}

.social-network span {
  margin-bottom: 30px;
  display: block;
}

.social-list .d-flex {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.social-list .d-flex:last-child {
  margin-bottom: 0;
}
.social-list .d-flex .flex-grow-1 a {
  color: #999999;
}
.social-list .d-flex .flex-grow-1 span {
  font-weight: 600;
}
.social-list .d-flex .flex-grow-1:hover a {
  color: var(--theme-default);
}

.photos {
  margin-bottom: -15px;
}
.photos ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 70px;
}
.photos ul li:nth-child(4n) {
  margin-right: 0;
}

.new-users-social {
  margin-bottom: 30px;
}
.new-users-social svg {
  cursor: pointer;
}
.new-users-social .d-flex img {
  width: 58px;
}
.new-users-social .d-flex .flex-grow-1 {
  margin-top: 10px;
}
.new-users-social svg circle {
  color: #999999;
}

.like-content span i {
  font-size: 14px;
}

.filter-cards-view span {
  color: #363636;
  font-size: 16px;
  font-weight: 500;
}

.timeline-content p {
  margin-top: 30px;
  color: #999999;
}
.timeline-content .comment-number i {
  color: #999999;
  margin-right: 20px;
}
.timeline-content .comments-box {
  margin-top: 30px;
}
.timeline-content .comments-box .input-group {
  margin-top: 6px;
}
.timeline-content .comments-box .input-group input {
  border-radius: 10px 10px 10px 10px !important;
}
.timeline-content .comments-box .input-group input:focus-visible {
  outline: none;
}
.timeline-content .comments-box .input-group input:focus {
  border: none;
  box-shadow: none;
}
.timeline-content .comments-box .input-group .input-txt-bx::placeholder {
  color: #999999;
}
.timeline-content .comments-box .input-group .input-group-append .btn i {
  font-size: 20px;
  display: flex;
  color: #999999;
}

.social-chat {
  margin-top: 30px;
}
.social-chat span span {
  font-weight: normal;
  color: #999999;
  font-size: 12px;
}
.social-chat .flex-grow-1 {
  padding: 30px;
  border: 1px solid #F3F3F3;
  position: relative;
}
.social-chat .flex-grow-1 p {
  margin-top: 5px;
}
.social-chat .flex-grow-1:before {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #59667a;
}
.social-chat .flex-grow-1:after {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}
.social-chat .your-msg,
.social-chat .other-msg {
  margin-bottom: 30px;
}
.social-chat .other-msg {
  margin-left: 40px;
}

.socialprofile span {
  color: #999999;
}
.socialprofile .social-btngroup {
  margin: 30px 0;
}
.socialprofile .likes-profile h5 span {
  color: #363636;
}
.socialprofile .social-group {
  margin-top: 30px;
}
.socialprofile .social-group ul li:nth-child(4) {
  margin-left: -22px;
}

.social-status form .form-group .form-control-social {
  border: 1px solid #F3F3F3;
  padding-left: 15px;
  margin-bottom: 30px;
}
.social-status .d-flex {
  position: relative;
  margin-bottom: 30px;
}
.social-status .d-flex:last-child {
  margin-bottom: 0;
}
.social-status .d-flex .social-status {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #fff;
  bottom: 0;
  left: 35px;
  border-radius: 100%;
}
.social-status .d-flex .social-online {
  border: 3px solid #53A653;
}
.social-status .d-flex .social-busy {
  border: 3px solid #FFAA05;
}
.social-status .d-flex .social-offline {
  border: 3px solid #59667a;
}
.social-status .d-flex .flex-grow-1 p {
  color: #999999;
  margin-bottom: 5px;
}
.social-status .d-flex .flex-grow-1 span + span {
  color: #999999;
}
.social-status .d-flex .flex-grow-1 .light-span {
  color: #999999;
}

.social-header h5 span:first-child {
  line-height: 1.48;
}
.social-header h5 span svg circle {
  color: #999999;
}

.details-about + .details-about {
  margin-top: 30px;
}
.details-about .your-details p {
  color: #999999;
}

.avatar-showcase .friend-pic {
  margin-right: 8px;
  margin-bottom: 10px;
}
.avatar-showcase .pepole-knows ul li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
}
.avatar-showcase .pepole-knows ul li:last-child {
  margin-right: 0;
}
.avatar-showcase .pepole-knows ul li .add-friend h6 {
  margin-top: 3px;
}

.your-details span {
  font-size: 16px;
}

.add-friend span {
  font-weight: 600;
}

.activity-log .my-activity + .my-activity {
  margin-top: 30px;
}
.activity-log .my-activity p {
  margin-bottom: 5px;
}
.activity-log .my-activity p span svg {
  width: 15px;
  height: 15px;
}

/**=====================
    3.37 Social-app CSS end
==========================**/
/**=====================
    3.38 Sweet-alert CSS Start
==========================**/
.swal-modal .swal-title {
  font-size: 24px;
}
.swal-modal .swal-text {
  font-size: 17px;
}

/**=====================
    3.38 Sweet-alert CSS ends
==========================**/
/**=====================
    3.39 Task CSS Start
==========================**/
.modal-open .datepickers-container {
  z-index: 1075;
}

.date-details {
  align-items: center;
}
.date-details > div + div {
  margin-left: 10px;
}

.taskadd table tr td {
  border-top: none;
}
.taskadd table tr td:first-child {
  padding-left: 30px;
}
.taskadd table tr td:last-child {
  padding-right: 30px;
}
.taskadd table tr td svg {
  width: 18px;
  height: 18px;
  color: #59667a;
}
.taskadd table tr:first-child td {
  padding-top: 30px;
}
.taskadd table tr:last-child td {
  padding-bottom: 30px;
}

@media only screen and (max-width: 1660px) {
  .taskadd table tr td:first-child {
    min-width: 160px;
  }
  .taskadd table tr td:nth-child(3) {
    min-width: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .date-details {
    display: block !important;
    margin-bottom: -10px;
  }
  .date-details .d-inline-block {
    margin-bottom: 10px;
  }
  .taskadd table tr td:first-child {
    padding-left: 20px;
  }
  .taskadd table tr:first-child td {
    padding-top: 20px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .taskadd table tr td:nth-child(2) {
    min-width: 745px;
  }
}
@media only screen and (max-width: 575px) {
  .taskadd table tr td:first-child {
    padding-left: 15px;
  }
  .taskadd table tr:first-child td {
    padding-top: 15px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 15px;
  }
}
/**=====================
  3.39 Task CSS Ends
==========================**/
/**=====================
    3.40 Timeline-v CSS Start
==========================**/
.timeline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.time-content {
  display: flex;
  align-items: center;
  gap: 4px;
}
.time-content i {
  font-size: 18px;
}

.dark-timeline ul li.d-flex {
  margin-top: 17px;
}

.activity .d-flex {
  margin-bottom: 30px;
}
.activity .d-flex:last-child {
  margin-bottom: 0;
}
.activity .d-flex .gradient-round {
  position: relative;
  width: 53px;
  height: 53px;
  background-image: linear-gradient(26deg, var(--theme-default) 0%, #a26cf8 100%);
  border-radius: 100%;
  top: -18px;
}
.activity .d-flex .gradient-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .gradient-round svg path,
.activity .d-flex .gradient-round svg line,
.activity .d-flex .gradient-round svg circle {
  color: #fff;
}
.activity .d-flex .gradient-round.gradient-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -77px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .gradient-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .gradient-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .flex-grow-1 h6 {
  font-weight: 600;
  margin-bottom: 30px;
}
.activity .d-flex .flex-grow-1 h6 span {
  font-weight: normal;
  color: #999999;
}
.activity .d-flex .flex-grow-1 p {
  width: 80%;
}

.timeline-small .d-flex {
  margin-bottom: 30px;
}
.timeline-small .d-flex:last-child {
  margin-bottom: 0;
}
.timeline-small .d-flex .timeline-round {
  position: relative;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  border-radius: 15px;
  top: -18px;
}
.timeline-small .d-flex .timeline-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .timeline-round svg path,
.timeline-small .d-flex .timeline-round svg line,
.timeline-small .d-flex .timeline-round svg circle {
  color: #fff;
}
.timeline-small .d-flex .timeline-round.timeline-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -88px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .timeline-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .timeline-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .flex-grow-1 h6 {
  margin-bottom: 30px;
}
.timeline-small .d-flex .flex-grow-1 h6 span {
  font-weight: normal;
  color: #999999;
}
.timeline-small .d-flex .flex-grow-1 p {
  width: 80%;
}

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1170px) {
  #cd-timeline::before {
    left: 50% !important;
    margin-left: -2px;
  }
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #F3F3F3;
  }
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
  .cd-timeline-img > i {
    font-size: 18px;
    top: 50%;
    left: 50%;
    padding: 3px;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 0.6s;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    animation: cd-bounce-2 0.6s;
  }
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    animation: cd-bounce-2-inverse 0.6s;
  }
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #fff;
  }
}
#cd-timeline {
  position: relative;
  padding: 2em 0;
}

#cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  font-size: 1.5rem;
  height: 100%;
  width: 2px;
  background: #F3F3F3;
  left: -15px;
}

.cd-timeline-block {
  position: relative;
  margin: 30px 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-img {
  width: 40px;
  height: 40px;
  left: 51%;
  margin-left: -30px;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  border-radius: 50px;
}
.cd-timeline-img i {
  display: block;
  position: relative;
  left: 45%;
  top: 44%;
  margin-left: -12px;
  margin-top: -12px;
  font-size: 20px;
  color: #fff;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cd-timeline-content {
  position: relative;
  background: #F3F3F3;
  border-radius: 4px;
  padding: 30px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
}
.cd-timeline-content p {
  padding-top: 9px;
}
.cd-timeline-content h4 {
  margin-bottom: 0;
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-content audio {
  width: 100%;
}
.cd-timeline-content .cd-date {
  font-size: 13px;
  display: inline-block;
  float: left;
  padding: 0.8em 0 0;
  opacity: 0.7;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 28px;
  right: 100%;
  height: 0;
  width: 0;
  border: 15px solid transparent;
  border-left: 15px solid #F3F3F3;
}
[dir=rtl] .cd-timeline-content::before {
  border-right: 15px solid #F3F3F3;
  border-left: unset;
}
@media (max-width: 1169px) {
  .cd-timeline-content::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content .cd-date {
    font-size: 14px;
    padding-bottom: 0;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 1169px) {
  .cd-timeline-block .cd-timeline-img {
    left: -2px;
    height: 30px;
    width: 30px;
    margin-left: -30px;
    top: 14px;
    z-index: 7;
  }
  .cd-timeline-block .cd-timeline-img > i {
    font-size: 15px;
    top: 16px;
    left: 17px;
    padding: 3px;
  }
  [dir=rtl] .cd-timeline-block .cd-timeline-img > i {
    left: unset;
    right: 4px;
    margin-right: unset;
  }
  .landing-main #cd-timeline::before {
    left: -39px;
  }
  .landing-main .cd-timeline-block .cd-timeline-img {
    left: -34px;
    height: 50px;
    width: 50px;
    top: 1px;
    z-index: 8;
  }
  .landing-main .cd-timeline-block .cd-timeline-img > i {
    font-size: 23px;
    top: 21px;
    left: 7px;
    padding: 3px;
  }
}
@media only screen and (max-width: 576px) {
  .cd-timeline-content {
    padding: 15px;
  }
  .cd-timeline-content h4 {
    font-size: 18px;
  }
}
.main-timeline ul::before {
  left: 5px;
}
[dir=rtl] .main-timeline ul::before {
  right: 5px;
  left: unset;
}

.square-timeline {
  position: relative;
}
.square-timeline::before {
  border: 1px dashed #cccccc;
  content: "";
  margin-left: 0px;
  position: absolute;
  top: 0;
  left: 18px;
  width: 1px;
  height: 100%;
}
[dir=rtl] .square-timeline::before {
  margin-left: unset;
  margin-right: 0;
  left: unset;
  right: 18px;
}
.square-timeline .timeline-event {
  position: relative;
}
.square-timeline .timeline-event:hover .timeline-event-icon {
  transform: rotate(-105deg);
  background-color: #52526c;
}
.square-timeline .timeline-event:hover .timeline-event-wrapper .timeline-thumbnail {
  box-shadow: inset 40em 0 0 0 #FFF0D2;
}
.square-timeline .timeline-event .timeline-event-wrapper {
  padding: 0 0 0 14px;
  position: relative;
  top: -7px;
  left: 38px;
  width: 95%;
}
[dir=rtl] .square-timeline .timeline-event .timeline-event-wrapper {
  left: unset;
  right: 38px;
  padding: 0 14px 0 0;
}
@media (max-width: 1200px) {
  .square-timeline .timeline-event .timeline-event-wrapper {
    width: calc(84% + 11 * (100vw - 320px) / 880);
  }
}
.square-timeline .timeline-event .timeline-event-wrapper .designer-details {
  padding-top: 20px;
}
.square-timeline .timeline-event .timeline-event-wrapper .timeline-thumbnail {
  transition: box-shadow 0.5s ease-in 0.1s;
  color: #2c323f !important;
  background-color: #d2e4ff;
  box-shadow: inset 0 0 0 0em #D5F5FE;
  display: inline-block;
  margin-bottom: 12px;
  padding: 2px 25px;
}
.square-timeline .timeline-event .timeline-event-wrapper .list-group-item-action:hover, .square-timeline .timeline-event .timeline-event-wrapper .list-group-item-action:active, .square-timeline .timeline-event .timeline-event-wrapper .list-group-item-action:focus {
  background-color: unset;
}
.square-timeline .timeline-event .timeline-event-wrapper > div {
  display: inline-block;
  width: 100%;
}
.square-timeline .timeline-event .timeline-event-icon {
  transition: 0.2s ease-in;
  transform: rotate(45deg);
  background-color: #307EF3;
  outline: 6px solid rgba(48, 126, 243, 0.2);
  display: block;
  margin: 0px;
  position: absolute;
  top: 0;
  left: 14px;
  width: 10px;
  height: 10px;
}
[dir=rtl] .square-timeline .timeline-event .timeline-event-icon {
  right: 14px;
  left: unset;
}

.list-inline {
  display: flex;
  justify-content: center;
}
.list-inline.events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}
.list-inline.events .event-list p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.list-inline.events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 75px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 2px 4px;
}
.list-inline.events .event-list .event-date p {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 1.5;
  margin-top: 7px;
}

.timeline-list.timeline-border {
  border-bottom: 1px solid #cccccc;
}
.timeline-list.list-inline {
  padding-bottom: 0;
}
.timeline-list.list-inline.events .event-list {
  padding-top: 35px;
}
.timeline-list.list-inline.events .event-list::before {
  content: "";
  height: 0;
  border-right: unset;
}
.timeline-list.list-inline.events .event-list .event-date {
  top: 2px;
}
.timeline-list.list-inline.events .event-list .event-date p {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 1.5;
  margin-top: 7px;
}
.timeline-list.list-inline.events .event-list .read-more-btn {
  position: relative;
  margin-bottom: 3px;
}
.timeline-list.list-inline .vertical-line {
  height: 45px;
  width: 1px;
  margin: 0 auto;
  position: relative;
  border: 1px dashed #307EF3;
}

.list-group.main-lists-content {
  padding-top: 20px;
}
@media (max-width: 1218px) {
  .list-group .list-group-item .timeline-icon {
    display: none;
  }
}

@media (max-width: 1400px) {
  .horizontal-timeline {
    display: none;
  }
}

/**=====================
    3.40 Timeline-v CSS Ends
==========================**/
/**=====================
    3.41 User-profile CSS start
==========================**/
.user-profile .ttl-info h6 {
  font-size: 12px;
  color: #59667a;
}
.user-profile .border-right {
  border-right: 1px solid #F3F3F3;
}
.user-profile hr {
  margin: 30px 0;
}
.user-profile .hovercard .cardheader {
  background: url(../images/other-images/bg-profile.png);
  background-size: cover;
  background-position: 10%;
  height: 470px;
}
.user-profile .hovercard .user-image {
  position: relative;
  height: 0;
}
.user-profile .hovercard .user-image .avatar {
  margin-top: -80px;
}
.user-profile .hovercard .user-image .avatar img {
  width: 100px;
  height: 100px;
  max-width: 155px;
  max-height: 155px;
  border-radius: 50%;
  border: 7px solid #fff;
}
.user-profile .hovercard .user-image .icon-wrapper {
  position: absolute;
  bottom: -20px;
  left: 51%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 17px;
  box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
}
.user-profile .hovercard .info {
  padding: 30px;
}
.user-profile .hovercard .info .title {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #2c323f;
  vertical-align: middle;
}
.user-profile .hovercard .info .title a {
  color: #363636;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
.user-profile .hovercard .info .desc {
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  color: #59667a;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.user-profile .follow {
  margin-top: 20px;
}
.user-profile .follow .follow-num {
  font-size: 20px;
  color: #363636;
  font-family: Outfit, sans-serif;
  font-weight: 500;
}
.user-profile .follow span {
  color: #59667a;
}
.user-profile .profile-img-style {
  padding: 30px;
}
.user-profile .profile-img-style .img-container {
  margin-top: 30px;
}
.user-profile .profile-img-style .user-name {
  font-size: 14px;
  color: #363636;
  margin: 0;
  font-weight: 600;
}
.user-profile .profile-img-style p {
  line-height: 30px;
  font-size: 14px;
  color: #9B9B9B;
  margin-bottom: 0;
}
.user-profile .profile-img-style .d-flex img {
  width: 50px;
}
.user-profile .like-comment li a {
  color: #9B9B9B;
}
.user-profile .like-comment li:first-child a:hover, .user-profile .like-comment li:first-child a:active {
  color: #DC3545;
}
.user-profile .like-comment span {
  font-family: Outfit, sans-serif;
}
.user-profile .social-media a {
  padding: 0 15px;
  color: #59667a;
  font-size: 24px;
  cursor: pointer;
}
.user-profile .social-media a:hover {
  color: var(--theme-default);
}

.edit-profile .profile-title .d-flex {
  align-items: center;
  margin-bottom: 30px;
}
.edit-profile .profile-title .d-flex .flex-grow-1 {
  margin-left: 14px;
}
[dir=rtl] .edit-profile .profile-title .d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 14px;
}
.edit-profile table thead tr th {
  padding: 18px 0px 18px 18px;
}
.edit-profile table tbody tr td {
  padding: 8px 0px 8px 18px;
}

/**=====================
    3.41 User-profile CSS Ends
==========================**/
/**=====================
    3.42 Wishlist CSS start
==========================**/
.wishlist table tr td svg {
  color: #9B9B9B;
  cursor: pointer;
}

/**=====================
    3.42 Wishlist CSS Ends
==========================**/
/**=====================
  38. Landing CSS Start
==========================**/
@media (max-width: 1660px) {
  .landing-page .landing-home .landing-card {
    margin-top: 24px;
  }
  .landing-page .landing-home .landing-progress {
    margin-top: 65px;
  }
}
@media (max-width: 1366px) {
  .landing-page .landing-home .landing-card {
    margin-top: 50px;
  }
  .landing-page .landing-home .landing-progress {
    margin-top: 80px;
  }
  .landing-page .landing-home .landing-home-contain h2 {
    font-size: 46px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .landing-page .demo-section .demo-box {
    padding: 15px;
  }
  .landing-page .demo-section .demo-box .demo-title {
    padding-top: 10px;
  }
  .landing-page .demo-section .demo-box .demo-title h3 {
    font-size: 18px;
  }
}
@media (max-width: 1199px) {
  .landing-page .landing-home .landing-card {
    margin-top: 68px;
  }
  .landing-page .landing-home .landing-progress {
    margin-top: 90px;
  }
  .landing-page .landing-home .landing-home-contain h2 {
    font-size: 34px;
  }
  .landing-page .landing-home .landing-home-contain p {
    width: 60%;
    margin-bottom: 22px;
  }
  .landing-page .components .d-flex span::before {
    left: -4px;
    top: -4px;
  }
  .landing-page .components .d-flex svg {
    width: 25px;
    height: 25px;
    fill: #307EF3;
  }
  .landing-page .shap-block {
    display: none;
  }
  .landing-page .demo-section .demo-box .d-flex .flex-shrink-0 {
    display: none;
  }
  .landing-page .section-py-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .landing-page .section-pt-space {
    padding-top: 60px;
  }
  .landing-page .section-pb-space {
    padding-bottom: 60px;
  }
  .landing-page .title h2 {
    font-size: 28px;
  }
  .landing-page .landing-header ul.landing-menu li.nav-item a.nav-link {
    padding: 10px 10px;
  }
  .landing-page .landing-header .buy-block .btn-landing {
    padding: 10px 20px;
  }
  .landing-page .landing-home .landing-home-contain h2 {
    font-size: 26px;
  }
  .landing-page .counter-sec .counter-box {
    padding: 20px 20px;
  }
  .landing-page .counter-sec .counter-box .count-number {
    width: 60px;
    height: 60px;
  }
  .landing-page .counter-sec .counter-box .count-number h3 {
    font-size: 22px;
  }
  .landing-page .demo-section .demo-box {
    padding: 20px;
  }
  .landing-page .demo-section .demo-box .img-wrraper ul.demo-link li a {
    display: block;
  }
  .landing-page .demo-section .demo-box .img-wrraper ul.demo-link li a svg {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .landing-page .framework .nav {
    margin-bottom: 30px;
  }
  .landing-page .framework ul.framworks-list li {
    width: 150px;
    height: 150px;
  }
  .landing-page .framework ul.framworks-list li img {
    height: 40px;
  }
}
@media (max-width: 991px) {
  .landing-page .demo-section .demo-block {
    padding: 0 18px;
  }
  .landing-page .section-py-space {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .landing-page .section-pt-space {
    padding-top: 50px;
  }
  .landing-page .section-pb-space {
    padding-bottom: 50px;
  }
  .landing-page .landing-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .landing-page .landing-header ul.landing-menu {
    position: fixed;
    top: 0;
    right: -320px;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    display: block;
    transition: all 0.5s ease;
  }
  .landing-page .landing-header ul.landing-menu.open {
    right: 0;
  }
  .landing-page .landing-header ul.landing-menu li.nav-item {
    display: block;
    background-color: #fff;
    text-align: left;
  }
  .landing-page .landing-header ul.landing-menu li.nav-item.menu-back {
    display: block;
  }
  .landing-page .landing-header ul.landing-menu li.nav-item a.nav-link {
    color: #363636;
    padding: 10px 20px;
  }
  .landing-page .landing-header ul.landing-menu li.nav-item:hover a.nav-link {
    color: #307EF3;
  }
  .landing-page .landing-header .buy-block .toggle-menu {
    display: block;
  }
  .landing-page .btn-landing {
    padding: 10px 25px;
  }
  .landing-page .landing-home {
    position: relative;
    z-index: 1;
  }
  .landing-page .landing-home .landing-home-contain {
    text-align: center;
    width: 80%;
    margin: auto;
    padding-top: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .landing-page .landing-home .landing-home-contain p {
    width: 90%;
  }
  .landing-page .landing-home .landing-home-contain .btn-landing {
    margin-right: auto;
    margin-left: auto;
  }
  .landing-page .framework .nav .nav-link img {
    height: 35px;
    width: auto;
  }
  .landing-page .framework .nav .nav-item:nth-child(n+2) {
    margin-left: 10px;
  }
  .landing-page .counter-sec .counter-block {
    margin-bottom: -30px;
  }
  .landing-page .counter-sec .counter-block > div {
    margin-bottom: 30px;
  }
  .landing-page .counter-sec .counter-box {
    padding: 35px 25px;
  }
  .landing-page .landing-footer .footer-contain img {
    height: 100px;
  }
  .landing-page .landing-footer .footer-contain .star-rate {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .landing-page .title h2 {
    font-size: 24px;
  }
  .landing-page .landing-home .landing-card {
    display: none;
  }
  .landing-page .landing-home .landing-progress {
    display: none;
  }
  .landing-page .landing-home .landing-table {
    display: none;
  }
  .landing-page .landing-home .landing-chart {
    display: none;
  }
  .landing-page .section-py-space {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .landing-page .section-pt-space {
    padding-top: 40px;
  }
  .landing-page .section-pb-space {
    padding-bottom: 40px;
  }
  .landing-page .landing-home {
    margin-bottom: 0;
  }
  .landing-page .landing-home .landing-home-contain {
    height: 520px;
    padding-top: 60px;
    padding-bottom: 0;
  }
  .landing-page .landing-home .landing-home-contain h6 {
    margin-bottom: 10px;
  }
  .landing-page .landing-home .landing-home-contain p {
    width: auto;
    margin-bottom: 25px;
  }
  .landing-page .demo-section .demo-block {
    margin-bottom: -20px;
  }
  .landing-page .demo-section .demo-block > div {
    margin-bottom: 20px;
  }
  .landing-page .demo-section .demo-box {
    width: fit-content;
    margin: auto;
  }
  .landing-page .demo-section .demo-box .img-wrraper ul.demo-link li:nth-child(n+2) {
    margin-left: 5px;
  }
  .landing-page .demo-section .demo-box .img-wrraper ul.demo-link li a {
    font-size: 10px;
  }
  .landing-page .demo-section .demo-box .demo-title h3 {
    font-size: 18px;
  }
  .landing-page .counter-sec .counter-box .count-number {
    width: 50px;
    height: 50px;
  }
  .landing-page .counter-sec .counter-box .count-detail h4 {
    font-size: 20px;
  }
  .landing-page .core-feature .feature-box .icon-wrraper {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .landing-page .core-feature .feature-box h4 {
    font-size: 20px;
  }
  .landing-page .framework .nav .nav-link img {
    height: 35px;
  }
  .landing-page .framework .nav .nav-link h5 {
    font-size: 16px;
  }
  .landing-page .framework .nav .nav-link.active:before {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 15px solid rgba(48, 126, 243, 0.8);
  }
  .landing-page .framework ul.framworks-list li {
    width: 145px;
    height: 145px;
  }
  .landing-page .framework ul.framworks-list li img {
    height: 40px;
  }
  .landing-page .faq-section .title p {
    text-align: center;
    font-size: 14px;
  }
  .landing-page .landing-footer .footer-contain img {
    height: 80px;
    margin-bottom: 10px;
  }
  .landing-page .landing-footer .footer-contain h2 {
    margin-bottom: 5px;
  }
  .landing-page .landing-footer .footer-contain p {
    margin-bottom: 10px;
  }
  .landing-page .landing-footer .footer-contain .star-rate {
    margin-bottom: 25px;
  }
  .landing-page .landing-footer .footer-contain .btn-footer .btn {
    padding: 10px 25px;
    font-size: 14px;
    line-height: 1;
  }
}
@media (max-width: 575px) {
  .landing-page .custom-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .landing-page .demo-section .demo-box {
    padding: 15px;
  }
  .landing-page .demo-section .demo-box .demo-title h3 {
    font-size: 16px;
  }
  .landing-page .framework .nav {
    display: flex;
  }
  .landing-page .core-feature .feature-box {
    padding: 30px 20px;
  }
  .landing-page .faq-section .title p {
    display: none;
  }
  .landing-page .faq-section .faq-box p {
    font-size: 14px;
  }
  .landing-page .counter-sec .counter-box {
    height: auto;
    padding: 30px 20px;
  }
  .landing-page .counter-sec .counter-box .count-number {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
  }
  .landing-page .counter-sec .counter-box .count-number h3 {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .owl-theme .owl-dots .owl-dot span {
    width: 6px !important;
    height: 6px !important;
    margin: 2px 2px !important;
  }
  .landing-page .demo-section .demo-block {
    padding: 0 12px;
  }
  .landing-page .title h2 {
    font-size: 20px;
  }
  .landing-page .landing-home .landing-home-contain h2 {
    font-size: 22px;
  }
  .landing-page .landing-header .buy-block .toggle-menu {
    margin-left: 10px;
  }
  .landing-page .framework .nav .nav-item {
    width: auto;
  }
  .landing-page .framework .nav .nav-link {
    display: block !important;
    padding: 10px 15px;
  }
  .landing-page .framework .nav .nav-link.active:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 5px solid rgba(48, 126, 243, 0.8);
  }
  .landing-page .framework .nav .nav-link img {
    height: 20px;
    margin-right: unset;
  }
  .landing-page .framework .nav .nav-link .text-start {
    display: none;
  }
  .landing-page .framework ul.framworks-list li {
    width: 110px;
    height: 110px;
    padding: 25px 0;
  }
  .landing-page .framework ul.framworks-list li img {
    height: 30px;
  }
  .landing-page .landing-footer .footer-contain .btn-footer a:nth-child(n+2) {
    margin-left: 5px;
  }
  .landing-page .landing-footer .footer-contain .btn-footer .btn {
    padding: 10px 15px;
  }
}
@media (max-width: 420px) {
  .landing-page .landing-home .landing-home-contain .landing-icon li a {
    font-size: 16px;
  }
}
@media (max-width: 417px) {
  .navbar-brand {
    margin-right: 5px;
  }
  .landing-page .landing-home .landing-home-contain {
    height: 680px;
  }
  .landing-page .landing-home .landing-home-contain .btn-home-list li:nth-child(n+2) {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .landing-page .demo-section .demo-box .demo-title.btn-showcase .btn {
    margin-right: 8px;
  }
  .landing-page .demo-section .demo-box .demo-title .btn {
    padding: 6px 10px;
  }
  .landing-page .landing-footer .footer-contain .btn-footer .btn:last-child {
    margin-top: 10px;
  }
}
/**=====================
     38. Landing CSS Ends
==========================**/
/* ---------------------
	*** themes ***
-----------------------*/
/**=====================
	4.1. Dark CSS Start
==========================**/
body.dark-only {
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31;
}
body.dark-only .page-body .bg-light {
  background-color: #21283b !important;
}
body.dark-only .page-body .card .card-body, body.dark-only .page-body .card .card-footer {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .form-control {
  background-color: #1f2639 !important;
  border-color: #374558 !important;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .wizard-4 .step-container {
  background-color: #1f2639;
  border-color: #374558;
}
body.dark-only .wizard-4 .login-card .login-main {
  background-color: #191f31;
}
body.dark-only .total-balance {
  background-blend-mode: overlay;
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  background-color: #1f2639;
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div .dropdown-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only h1, body.dark-only h2, body.dark-only h3, body.dark-only h4, body.dark-only h5, body.dark-only h6 {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .icon-state .switch-state:after {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .checkout .form-control {
  background-color: #191f31 !important;
  color: rgba(255, 255, 255, 0.6) !important;
  border: none;
}
body.dark-only .sidebar-toggle {
  stroke: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-title .row h3 {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .left-header .left-menu-header ul.header-left li ul.onhover-show-div {
  background-color: #1f2639;
}
body.dark-only .left-header .left-menu-header ul.header-left li ul.onhover-show-div::before {
  border-bottom: 7px solid #1f2639;
}
body.dark-only .left-header .left-menu-header ul.header-left li ul.onhover-show-div li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: #1f2639;
  color: #fff;
}
body.dark-only .apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #374558;
  background: #191f31;
}
body.dark-only .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  border: 1px solid #374558;
  background: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .apexcharts-tooltip-series-group {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .apexcharts-yaxistooltip,
body.dark-only .apexcharts-xaxistooltip {
  color: rgba(255, 255, 255, 0.6);
  background: #191f31;
  border: 1px solid #374558;
}
body.dark-only .apexcharts-yaxistooltip-left::before, body.dark-only .apexcharts-yaxistooltip-left::after {
  border-left-color: #374558;
}
body.dark-only .apexcharts-xaxistooltip-bottom::before, body.dark-only .apexcharts-xaxistooltip-bottom::after {
  border-bottom-color: #374558;
}
body.dark-only .bottom-content .apexcharts-canvas span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .apexcharts-canvas .apexcharts-tooltip {
  border: 1px solid #374558;
}
body.dark-only .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  margin-bottom: 0;
}
body.dark-only .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .apexcharts-canvas .apexcharts-yaxis text {
  fill: #98a6ad;
}
body.dark-only .apexcharts-xaxistooltip {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #374558;
}
body.dark-only .apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #374558;
}
body.dark-only .apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #374558;
  background: #191f31;
}
body.dark-only .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text {
  color: #98a6ad;
}
body.dark-only .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #1f2639;
  border-bottom: 1px solid #374558;
  color: #98a6ad;
}
body.dark-only .apexcharts-yaxis text {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .invoice-2 tr.back {
  background-color: #1f2639 !important;
}
body.dark-only .invoice-2 tr.back td {
  background-color: #1f2639 !important;
}
body.dark-only .invoice-4 tr.back td {
  background-color: #1f2639 !important;
}
body.dark-only .invoice-5 .back {
  background-color: #1f2639 !important;
}
body.dark-only .invoice-5 .back td {
  background-color: #1f2639 !important;
}
body.dark-only .social-widget .social-icons {
  box-shadow: 0px 42.1092px 27.8569px #191f31, 0px 8.90772px 7.12618px #191f31, 0px 2.02448px 3.44162px #191f31;
}
body.dark-only .calendar-basic .md-sidebar-aside {
  background: #1f2639;
}
body.dark-only .calendar-basic #external-events {
  border-color: #374558;
}
body.dark-only .calendar-basic .calendar-default .fc .fc-daygrid-day-number,
body.dark-only .calendar-basic .calendar-default .fc .fc-col-header-cell-cushion {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .clockpicker-popover .popover-title {
  background-color: #1f2639;
}
body.dark-only .clockpicker-popover.bottom > .arrow {
  border-bottom-color: #191f31;
}
body.dark-only .clockpicker-popover.bottom > .arrow:after {
  border-bottom-color: #1f2639;
}
body.dark-only .clockpicker-popover.top > .arrow {
  border-top-color: #191f31;
}
body.dark-only .clockpicker-popover.top > .arrow::after {
  border-top-color: #1f2639;
}
body.dark-only .clockpicker-popover.left > .arrow {
  border-left-color: #191f31;
}
body.dark-only .clockpicker-popover.left > .arrow::after {
  border-left-color: #1f2639;
}
body.dark-only .clockpicker-popover .popover-title {
  border-bottom-color: #1f2639;
}
body.dark-only .edit-profile .form-control {
  background-color: #191f31 !important;
  border: none;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .edit-profile .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .fc-theme-standard .fc-scrollgrid,
body.dark-only .fc-theme-standard .fc-list {
  border-color: #374558;
}
body.dark-only .fc-theme-standard .fc-list-day-cushion {
  background-color: #191f31;
}
body.dark-only .fc .fc-list-event:hover td {
  background-color: #191f31;
}
body.dark-only .view-container .apexcharts-canvas .apexcharts-point-annotations circle {
  outline: 25px solid rgba(62, 61, 114, 0.3490196078);
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
  border: 1px solid #374558;
}
body.dark-only .since .customer-card.b-l-primary {
  border: none !important;
  border-left: 2px solid #307EF3 !important;
  background-color: unset !important;
}
body.dark-only .since .customer-card.b-l-secondary {
  border: none !important;
  border-left: 2px solid #EBA31D !important;
  background-color: unset !important;
}
body.dark-only .since .customer-card.b-l-danger {
  border: none !important;
  border-left: 2px solid #DC3545 !important;
  background-color: unset !important;
}
body.dark-only .since .customer-card.b-l-success {
  border: none !important;
  border-left: 2px solid #53A653 !important;
  background-color: unset !important;
}
body.dark-only .sales .btn:last-child {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .review-slider .review .d-flex {
  background-color: #191f31;
}
body.dark-only .active-task ul li {
  border-bottom: 1px dashed #374558;
}
body.dark-only .leads .lead-status ul li {
  background-color: #191f31;
}
body.dark-only .leads .lead-status ul li.b-l-primary {
  border: none !important;
  border-left: 2px solid #307EF3 !important;
}
body.dark-only .leads .lead-status ul li.b-l-secondary {
  border: none !important;
  border-left: 2px solid #EBA31D !important;
}
body.dark-only .leads .lead-status ul li.b-l-danger {
  border: none !important;
  border-left: 2px solid #DC3545 !important;
}
body.dark-only .statistics .d-flex .flex-shrink-0 {
  border-right: 1px dashed #374558;
}
body.dark-only .ecommerce-dashboard .recent-activity ul li.d-flex .activity-line {
  border: 1px solid #374558;
}
body.dark-only .ecommerce-dashboard .recent-activity ul li.d-flex .activity-line::before {
  border-top: 1px solid #374558;
}
body.dark-only .ecommerce-dashboard .invoices .d-flex .flex-shrink-0 {
  border-right: 1px dashed #374558;
}
body.dark-only .ecommerce-dashboard .new-customers table tr td .d-flex .flex-grow-1 a span {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .project-dashboard .upcoming-launch .meeting {
  background-color: #191f31;
}
body.dark-only .project-dashboard .upcoming-launch .meeting .d-flex span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .project-dashboard .today-task .clients-project table thead {
  background-color: #191f31;
}
body.dark-only .project-dashboard .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-action svg path {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-only .project-dashboard .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .project-dashboard .upcoming-meeting .default-datepicker .datepicker .datepicker--nav .datepicker--nav-title i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .web-card .comment ul li {
  background-color: #191f31;
}
body.dark-only .web-card .comment ul li span {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .budget-card .budget-spent {
  background-color: #191f31;
}
body.dark-only .discount-card .special-offer .d-flex ul li {
  background-color: #191f31;
}
body.dark-only .currency-widget div[class*=bg-light-] {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .btn.badge-light-primary:hover {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .portfolio-chart-container .apexcharts-canvas .apexcharts-radialbar image {
  display: none;
}
body.dark-only svg.f-light {
  fill: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
body.dark-only .course-widget .btn-light {
  background-color: #191f31 !important;
}
body.dark-only .schedule-list > li {
  box-shadow: 4px 7px 17px 5px rgba(25, 31, 49, 0.2);
}
body.dark-only .upcoming-event-wrap .apexcharts-canvas .apexcharts-data-labels rect {
  fill: none;
  stroke: none;
}
body.dark-only .page-wrapper.advance-layout .page-header {
  background: #1f2639;
}
body.dark-only .page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper.only-body {
  background: #191f31;
}
body.dark-only .balance-card .svg-box {
  background: #1f2639;
  box-shadow: none;
}
body.dark-only .balance-card .svg-box svg {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .f-light {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: #1f2639;
  color: #fff;
}
body.dark-only .card {
  background-color: #1f2639;
  border: 1px solid #374558;
}
body.dark-only .ecommerce-widget {
  border: 1px solid #374558 !important;
}
body.dark-only .btn-light {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .form-select {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .add-project .text-inherit {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .knowledgebase-search {
  color: #363636;
}
body.dark-only .knowledgebase-search .form-control-plaintext {
  color: #363636;
}
body.dark-only .toast {
  border: 1px solid #374558;
}
body.dark-only .toast .toast-header {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .toast .toast-dark {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .btn-close {
  filter: brightness(0.8) invert(1);
}
body.dark-only .prooduct-details-box .d-flex {
  border: 1px solid #374558;
}
body.dark-only .product-price del {
  color: #98a6ad;
}
body.dark-only .ProfileCard {
  border: 1px solid #374558 !important;
}
body.dark-only .form-control-plaintext {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .form-select {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .box-layout {
  background-color: #191f31;
}
body.dark-only .box-layout .page-wrapper .page-header .header-wrapper, body.dark-only .box-layout.page-wrapper .page-header .header-wrapper {
  border: 1px solid #374558;
}
body.dark-only .form-check-input {
  background-color: #191f31;
}
body.dark-only .icon-lists div:hover {
  background-color: #191f31;
}
body.dark-only .checkbox-wrapper li .form-check-input,
body.dark-only .radio-wrapper li .form-check-input {
  background-color: transparent;
}
body.dark-only .switch-state:before {
  background-color: #1f2639;
}
body.dark-only .tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #323846;
}
body.dark-only .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #323846;
  border-top-color: transparent;
}
body.dark-only .tooltip.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #323846;
  border-top-color: transparent;
}
body.dark-only .tooltip.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #323846;
  border-top-color: transparent;
}
body.dark-only .tooltip .tooltip-inner {
  background-color: #323846;
  color: #fff;
}
body.dark-only .tooltip .tooltip-arrow:before {
  border-top-color: #323846;
}
body.dark-only .page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6:first-child h6 {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-only .page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
  color: #fff;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
  background-color: #191f31 !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:last-child {
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
  background-color: #191f31 !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
  stroke: #fff;
}
body.dark-only .floating-wrapper .form-floating input::placeholder {
  color: transparent;
}
body.dark-only .knowledgebase-search h3 {
  color: #363636;
}
body.dark-only .cart-dropdown li .d-flex .flex-grow-1 span {
  color: rgba(255, 255, 255, 0.8) !important;
}
body.dark-only .cart-dropdown li .view-cart, body.dark-only .cart-dropdown li .view-checkout {
  color: rgba(255, 255, 255, 0.8) !important;
}
body.dark-only .txt-dark {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .qty-box input {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-only .customizer-contain {
  color: #191f31;
}
body.dark-only .customizer-contain .customizer-body .main-layout .box-layout {
  background-color: #fff;
}
body.dark-only .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .breadcrumb-item + .breadcrumb-item::before {
  color: unset;
}
body.dark-only .browse .browse-articles h4 {
  border-bottom: 1px solid #374558;
}
body.dark-only .comment-box .d-flex img {
  border: 1px solid #374558;
}
body.dark-only .comment-box .comment-social li {
  color: #cccccc;
}
body.dark-only .comment-box .comment-social li:first-child {
  border-right: 1px solid #EAEAEA;
}
body.dark-only .msger-chat {
  background: #191f31;
}
body.dark-only .left-msg .msg-bubble {
  background: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .contact-edit.chat-alert {
  background: #191f31;
}
body.dark-only .contact-edit i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .right-msg .msg-bubble {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .right-sidebar-title .common-space .chat-time div p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .msger-inputarea {
  background: #1f2639;
}
body.dark-only .left-sidebar-wrapper .advance-options .chats-user li:hover {
  background-color: #191f31;
}
body.dark-only .left-sidebar-wrapper .advance-options .contact-wrapper p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .left-sidebar-wrapper .advance-options .common-space p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .left-sidebar-wrapper .advance-options .common-space .chat-time span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .login-dark {
  background-color: #191f31;
  background-blend-mode: overlay;
}
body.dark-only .login-dark .login-main {
  background-color: #1f2639;
}
body.dark-only .login-card .btn-showcase .btn {
  background-color: #1f2639 !important;
  color: #fff;
  border-color: #1f2639 !important;
}
body.dark-only .login-card .login-main {
  box-shadow: 0 0 37px rgba(255, 255, 255, 0.05);
}
body.dark-only .login-card .login-main .theme-form input {
  background-color: #191f31 !important;
}
body.dark-only .login-card .login-main .theme-form .or:before {
  background-color: #1f2639;
}
body.dark-only .login-card .login-main .theme-form .checkbox label::before {
  background-color: #191f31 !important;
  border-color: #1f2639 !important;
}
body.dark-only .login-card .login-main .theme-form .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .login-card .login-main .theme-form .or:before {
  background-color: #191f31;
}
body.dark-only .H_ui ~ div {
  background-color: #191f31 !important;
}
body.dark-only .swal-icon--success:after,
body.dark-only .swal-icon--success:before {
  background: #1f2639;
}
body.dark-only .swal-icon--success__hide-corners {
  background-color: #1f2639;
}
body.dark-only .note-editor.note-frame .note-status-output {
  border-top: 1px solid #1f2639;
}
body.dark-only .note-editor.note-frame .note-statusbar {
  border-top: 1px solid #1f2639;
}
body.dark-only .note-editor.note-frame .note-statusbar .note-resizebar {
  background-color: #1f2639;
}
body.dark-only .light-font {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-only .page-link {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .b-r-light {
  border-right: 1px solid #374558 !important;
}
body.dark-only .history-details .d-flex {
  border-top: 1px solid #374558;
}
body.dark-only .modal-header {
  border-bottom: 1px solid #374558;
}
body.dark-only #right-history {
  background-color: #1f2639;
  box-shadow: 0 0 9px #191f31;
}
body.dark-only #right-history h6 span a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .ProfileCard:hover {
  color: rgba(255, 255, 255, 0.6);
  background-color: #1f2639;
}
body.dark-only .translate_wrapper.active .more_lang:before {
  border-bottom: 7px solid #1f2639;
}
body.dark-only .translate_wrapper.active .more_lang .lang {
  border-bottom: 1px solid #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon {
  stroke: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon {
  fill: rgba(255, 255, 255, 0.6);
  stroke: transparent;
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .sidebar-link.active svg.stroke-icon {
  stroke: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-only .page-wrapper.modern-type .page-body-wrapper .page-title {
  background-color: transparent;
  border: none;
}
body.dark-only .page-wrapper .sidebar-main-title p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active svg {
  stroke: var(--theme-default);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
  background: #1f2639;
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  background: #1f2639;
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active, body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  color: var(--theme-default);
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu {
  background: #1f2639;
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  background: #1f2639;
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  background: #1f2639;
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  background-color: #1f2639;
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  background-color: rgba(48, 126, 243, 0.2);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
  color: var(--theme-default);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
  color: var(--theme-default);
  stroke: var(--theme-default);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper.material-type .page-header .header-wrapper {
  border-radius: 15px 15px 0 0;
}
body.dark-only .page-wrapper.material-icon .page-header .header-wrapper {
  background-color: transparent;
}
body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
  color: #fff;
}
body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
  color: #fff;
}
body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
  border: 1px solid #374558;
  background: #191f31;
  color: #fff;
}
body.dark-only .page-wrapper.material-icon .page-header .header-logo-wrapper {
  background-color: transparent;
}
body.dark-only .page-wrapper.material-icon .page-body-wrapper .page-body {
  background-color: transparent;
}
body.dark-only .page-wrapper.advance-layout {
  background-color: #191f31;
}
body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper {
  background: transparent;
}
body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper .header-logo-wrapper {
  background: transparent;
}
body.dark-only .page-wrapper.advance-layout .page-body-wrapper {
  background: transparent;
}
body.dark-only .page-wrapper.advance-layout .page-body-wrapper .page-body {
  background: transparent;
}
body.dark-only .page-wrapper.material-icon .page-body-wrapper .page-title {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
body.dark-only .page-wrapper .page-header .header-wrapper .search-full {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-wrapper .search-full input {
  color: rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 768px) {
  body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right ul li .profile-dropdown li svg {
    stroke: #fff;
  }
}
body.dark-only ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
body.dark-only .kanban-board-header {
  background-color: #191f31;
  border-bottom: 1px solid #374558;
}
body.dark-only .kanban-board .kanban-drag {
  background: #191f31;
}
body.dark-only .kanban-container .kanban-item .kanban-box {
  border: 1px solid #374558;
  background-color: #191f31;
}
body.dark-only .project-box {
  border: 1px solid rgba(48, 126, 243, 0.15);
  background-color: #191f31;
}
body.dark-only .file-content .form-inline {
  border: 1px solid #374558;
}
body.dark-only .file-content .files .file-box {
  border: 1px solid rgba(48, 126, 243, 0.15);
  background-color: #191f31;
}
body.dark-only .file-content .files .file-box .file-top {
  background-color: #1f2639;
  border: 1px solid rgba(48, 126, 243, 0.15);
}
body.dark-only .file-content .folder .folder-box {
  border: 1px solid rgba(48, 126, 243, 0.15);
  background-color: #191f31;
}
body.dark-only .file-sidebar .pricing-plan {
  border: 1px solid rgba(48, 126, 243, 0.15) !important;
}
body.dark-only .file-sidebar .btn-light {
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31 !important;
  border: 1px solid rgba(48, 126, 243, 0.15) !important;
}
body.dark-only #lnb {
  border-right: 1px solid #374558;
}
body.dark-only .lnb-new-schedule,
body.dark-only .lnb-calendars > div {
  border-bottom: 1px solid #374558;
}
body.dark-only #menu .sidebar-list .btn-default {
  color: rgba(255, 255, 255, 0.2);
}
body.dark-only .tui-full-calendar-timegrid-hour {
  background-color: #1f2639;
  color: #fff !important;
}
body.dark-only .tui-full-calendar-timegrid-gridline {
  border-bottom: 1px solid #374558 !important;
}
body.dark-only .tui-full-calendar-time-date,
body.dark-only .tui-full-calendar-weekday-grid-line,
body.dark-only .tui-full-calendar-left,
body.dark-only .tui-full-calendar-timegrid-timezone {
  border-right-color: #374558 !important;
}
body.dark-only .tui-full-calendar-popup {
  color: #363636;
}
body.dark-only #menu .btn-default {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only #menu .btn-default:hover {
  color: #363636;
}
body.dark-only #menu .dropdown-menu {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .tui-full-calendar-dayname-container,
body.dark-only .tui-full-calendar-splitter {
  border-top-color: #374558 !important;
  border-bottom-color: #374558 !important;
}
body.dark-only span.tui-full-calendar-dayname-date-area {
  color: #fff !important;
}
body.dark-only .tui-full-calendar-layout {
  background-color: #1f2639 !important;
}
body.dark-only .contacts-tabs .nav-pills {
  border-right: 1px solid #374558;
}
body.dark-only .contacts-tabs .nav-pills .nav-link + .nav-link {
  border-top: 1px solid #374558;
}
body.dark-only .list-persons .profile-mail .d-flex .flex-grow-1 ul li + li {
  border-left: 2px solid #374558;
}
body.dark-only .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
  background-color: #1f2639;
}
body.dark-only .apexcharts-gridline {
  stroke: #374558;
}
body.dark-only .apexcharts-tooltip-title,
body.dark-only .apexcharts-tooltip-series-group,
body.dark-only .apexcharts-tooltip.light,
body.dark-only .apexcharts-menu-item {
  color: #000;
}
body.dark-only .small-donut ~ svg path:nth-child(even),
body.dark-only .small-donut svg path:nth-child(even),
body.dark-only .donut-peity2 ~ svg path:nth-child(even),
body.dark-only .donut-peity2 svg path:nth-child(even),
body.dark-only .pie-peity ~ svg path:nth-child(even),
body.dark-only .pie-peity svg path:nth-child(even) {
  fill: #191f31;
}
body.dark-only .timeline-list.timeline-border {
  border-bottom: 1px solid #374558;
}
body.dark-only .appointment-table table tr td.img-content-box .font-roboto {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .notification .card .d-flex .w-100 p span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .notification .card .d-flex .w-100 span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .datepicker {
  background-color: #323846;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .datepicker .datepicker--cell.-current- {
  color: #307EF3;
}
body.dark-only .datepicker .datepicker--cell.-current-.-focus- {
  color: #fff;
}
body.dark-only .datepicker .datepicker--cell-day.-other-month-,
body.dark-only .datepicker .datepicker--cell-year.-other-decade- {
  color: rgba(255, 255, 255, 0.4);
}
body.dark-only .datepicker .datepicker--nav {
  border-bottom: 1px solid #374558;
}
body.dark-only .datepicker .datepicker--pointer {
  background: #323846;
  border-top-color: #323846;
  border-right-color: #323846;
}
body.dark-only .default-datepicker .datepicker-inline .datepicker {
  background: #1f2639;
}
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name,
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day,
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name,
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name.-selected-,
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day.-selected-,
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name.-selected-,
body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-selected- {
  color: #fff;
}
body.dark-only .alert-primary {
  background-color: rgba(48, 126, 243, 0.8) !important;
  border-color: rgba(48, 126, 243, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-primary .progress {
  height: 5px;
  background-color: #2b7bf3;
  border-radius: 0;
}
body.dark-only .alert-primary .progress-bar {
  background-color: white;
}
body.dark-only .alert-primary.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-primary.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-primary.outline .btn-close,
body.dark-only .alert-primary.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-secondary {
  background-color: rgba(235, 163, 29, 0.8) !important;
  border-color: rgba(235, 163, 29, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-secondary .progress {
  height: 5px;
  background-color: #eba118;
  border-radius: 0;
}
body.dark-only .alert-secondary .progress-bar {
  background-color: white;
}
body.dark-only .alert-secondary.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-secondary.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-secondary.outline .btn-close,
body.dark-only .alert-secondary.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-success {
  background-color: rgba(83, 166, 83, 0.8) !important;
  border-color: rgba(83, 166, 83, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-success .progress {
  height: 5px;
  background-color: #51a351;
  border-radius: 0;
}
body.dark-only .alert-success .progress-bar {
  background-color: #fbfdfb;
}
body.dark-only .alert-success.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-success.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-success.outline .btn-close,
body.dark-only .alert-success.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-danger {
  background-color: rgba(220, 53, 69, 0.8) !important;
  border-color: rgba(220, 53, 69, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-danger .progress {
  height: 5px;
  background-color: #db3141;
  border-radius: 0;
}
body.dark-only .alert-danger .progress-bar {
  background-color: white;
}
body.dark-only .alert-danger.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-danger.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-danger.outline .btn-close,
body.dark-only .alert-danger.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-warning {
  background-color: rgba(255, 170, 5, 0.8) !important;
  border-color: rgba(255, 170, 5, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-warning .progress {
  height: 5px;
  background-color: #ffa800;
  border-radius: 0;
}
body.dark-only .alert-warning .progress-bar {
  background-color: white;
}
body.dark-only .alert-warning.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-warning.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-warning.outline .btn-close,
body.dark-only .alert-warning.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-info {
  background-color: rgba(1, 161, 185, 0.8) !important;
  border-color: rgba(1, 161, 185, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-info .progress {
  height: 5px;
  background-color: #019db4;
  border-radius: 0;
}
body.dark-only .alert-info .progress-bar {
  background-color: #baf6ff;
}
body.dark-only .alert-info.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-info.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-info.outline .btn-close,
body.dark-only .alert-info.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-light {
  background-color: rgba(243, 243, 243, 0.8) !important;
  border-color: rgba(243, 243, 243, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-light .progress {
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 0;
}
body.dark-only .alert-light .progress-bar {
  background-color: white;
}
body.dark-only .alert-light.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-light.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-light.outline .btn-close,
body.dark-only .alert-light.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .alert-dark {
  background-color: rgba(44, 50, 63, 0.8) !important;
  border-color: rgba(44, 50, 63, 0.9) !important;
  color: #fff;
}
body.dark-only .alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}
body.dark-only .alert-dark .progress-bar {
  background-color: #a8b0c2;
}
body.dark-only .alert-dark.inverse {
  background-color: transparent !important;
}
body.dark-only .alert-dark.inverse .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .alert-dark.outline .btn-close,
body.dark-only .alert-dark.outline-2x .btn-close {
  filter: brightness(1) invert(1);
}
body.dark-only .dismiss-text .alert-dismissible .btn-close {
  filter: unset;
}
body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
  color: #363636 !important;
}
body.dark-only .options > div {
  border: 1px solid #374558;
}
body.dark-only .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #191f31;
}
body.dark-only .pricing-simple {
  box-shadow: 1px 1px 2px 1px #191f31 !important;
}
body.dark-only .pricing-block {
  box-shadow: 0 0 10px 5px rgba(48, 126, 243, 0.05) !important;
}
body.dark-only .search-page ul.search-info li + li {
  border-left: 1px solid #191f31;
}
body.dark-only .browse .browse-articles h6 {
  border-bottom: 1px solid #374558;
}
body.dark-only .product-box .product-details h3 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .job-search .job-description .theme-form .row div[class^=col-] .input-group .datepicker-here {
  border: 1px solid #191f31;
}
body.dark-only .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded .product-icons {
  background-color: #1f2639;
}
body.dark-only .calendar-wrap .fc-unthemed td,
body.dark-only .calendar-wrap .fc-unthemed th {
  border-color: #191f31;
}
body.dark-only .mega-inline .card,
body.dark-only .mega-horizontal .card,
body.dark-only .mega-vertical .card {
  box-shadow: 1px 1px 7px 0 rgba(55, 69, 88, 0.5) !important;
}
body.dark-only .mega-inline.plain-style .card, body.dark-only .mega-inline.border-style .card, body.dark-only .mega-inline.offer-style .card,
body.dark-only .mega-horizontal.plain-style .card,
body.dark-only .mega-horizontal.border-style .card,
body.dark-only .mega-horizontal.offer-style .card,
body.dark-only .mega-vertical.plain-style .card,
body.dark-only .mega-vertical.border-style .card,
body.dark-only .mega-vertical.offer-style .card {
  box-shadow: none !important;
}
body.dark-only .mega-inline.border-style .card,
body.dark-only .mega-horizontal.border-style .card,
body.dark-only .mega-vertical.border-style .card {
  border: 1px solid #374558 !important;
}
body.dark-only .mega-inline.offer-style .card,
body.dark-only .mega-horizontal.offer-style .card,
body.dark-only .mega-vertical.offer-style .card {
  border: 1px dashed #374558 !important;
}
body.dark-only .setting-dot .setting-bg {
  background-color: var(--theme-secondary);
}
body.dark-only .bookmark.pull-right {
  border: none;
}
body.dark-only .dropdown-menu.show {
  background-color: #191f31;
}
body.dark-only .popover {
  background-color: #191f31;
}
body.dark-only .popover.bs-popover-bottom .popover-arrow:after {
  border-bottom-color: #191f31;
  border-right-color: transparent;
}
body.dark-only .popover.bs-popover-top .popover-arrow:after {
  border-right-color: transparent;
  border-top-color: #191f31;
}
body.dark-only .popover.bs-popover-start .popover-arrow:after {
  border-left-color: #191f31;
}
body.dark-only .popover .popover-header {
  background-color: #1f2639;
}
body.dark-only .popover .popover-arrow:after {
  border-right-color: #191f31;
}
body.dark-only .popover .popover-body {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .popover code {
  background-color: unset;
}
body.dark-only .apex-chart-container .apexcharts-legend .apexcharts-legend-series span {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .apexcharts-canvas svg .apexcharts-title-text {
  fill: #fff;
}
body.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
  fill: #fff;
}
body.dark-only .apexcharts-canvas svg .apexcharts-yaxis .apexcharts-yaxis-texts-g .apexcharts-yaxis-label {
  fill: #fff;
}
body.dark-only .apexcharts-canvas svg .apexcharts-xaxis .apexcharts-xaxis-texts-g .apexcharts-xaxis-label {
  fill: #fff;
}
body.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-label {
  fill: #fff;
}
body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-value {
  fill: #fff;
}
body.dark-only .flatpickr-current-month, body.dark-only .flatpickr-weekday {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .flatpickr-months .flatpickr-prev-month, body.dark-only .flatpickr-months .flatpickr-next-month {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .flatpickr-day {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .flatpickr-day.prevMonthDay, body.dark-only .flatpickr-day.nextMonthDay {
  color: #323846;
}
body.dark-only .Typeahead-menu {
  background-color: #191f31;
}
body.dark-only .ecommerce-widget {
  border: 1px solid #374558;
}
body.dark-only .bookmark ul li .search-form .form-control-search input {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .bookmark ul li .search-form .form-control-search:before {
  background: #374558;
}
body.dark-only .todo-wrap .card-header.b-bottom {
  border-bottom: #374558;
}
body.dark-only .email-wrap .email-top h3 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-wrap .flex-grow-1 h3 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-wrap .email-app-sidebar ul li a > .title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-wrap .email-app-sidebar .main-menu > li a:hover > .title {
  color: #2c323f;
}
body.dark-only .email-wrap .email-wrapper h3 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-wrap .email-wrapper .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .email-wrap .email-wrapper .actions li {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-wrap .email-wrapper .theme-form label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-wrap .email-wrapper hr {
  border-top: 1px solid #374558;
}
body.dark-only .email-wrap .email-top {
  border-bottom: 1px solid #2c323f;
}
body.dark-only .email-wrap .email-right-aside .email-body .inbox .d-flex.active {
  background-color: #191f31;
}
body.dark-only .flatpickr-monthDropdown-month {
  background-color: #1f2639 !important;
}
body.dark-only .vertical-main-wizard .header-vertical-wizard {
  background-color: #191f31;
}
body.dark-only .cart .qty-box .input-group .btn {
  background-color: #2e3648 !important;
  border-color: #374558 !important;
}
body.dark-only .checkout .checkout-details {
  background-color: #191f31;
  border: 1px solid #374558;
  padding: 40px;
}
body.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
  border: 2px solid #1f2639;
}
body.dark-only .pricingtable {
  background-color: #191f31;
}
body.dark-only .order-box .title-box {
  color: #bfc2c6;
  border-bottom: 1px solid #374558;
}
body.dark-only .order-box .qty {
  border-bottom: 1px solid #374558;
}
body.dark-only .order-box .qty li {
  color: #bfc2c6;
}
body.dark-only .order-box .qty li span {
  color: #bfc2c6;
}
body.dark-only .order-box .sub-total li {
  color: #bfc2c6;
}
body.dark-only .order-box .total li {
  color: #bfc2c6;
}
body.dark-only .dropdown-menu .dropdown-item {
  background-color: unset;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .shopping-checkout-option .checkbox_animated:after {
  border: 2px solid #1f2639;
}
body.dark-only .animate-chk .radio_animated:after {
  border: 2px solid #374558;
}
body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(48, 126, 243, 0.15);
}
body.dark-only .email-wrap p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-body .mail-body-wrapper ul li .inbox-message .email-data span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-body .light-square {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-main-wrapper .email-app-sidebar .nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-title.fs-5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .custom-input label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .product-box .product-img .product-hover ul li .btn {
  color: #98a6ad;
}
body.dark-only .product-box .product-img .product-hover ul li:hover .btn {
  color: #fff;
}
body.dark-only .radio_animated:after,
body.dark-only .checkbox_animated:after {
  background: #191f31;
  border: 2px solid #191f31;
}
body.dark-only .slider-product {
  border-top: 1px solid #374558;
  border-bottom: 1px solid #374558;
}
body.dark-only .square-product-setting .icon-grid {
  background-color: #1f2639;
}
body.dark-only .square-product-setting .icon-grid svg {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .active-order-table table thead tr th,
body.dark-only .market-table table thead tr th {
  border-top: none !important;
}
body.dark-only .active-order-table table tbody tr td,
body.dark-only .market-table table tbody tr td {
  border-bottom: 1px solid #374558;
}
body.dark-only .active-order-table table tbody tr:last-child td,
body.dark-only .market-table table tbody tr:last-child td {
  border-bottom: none;
}
body.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
  border-bottom: 1px solid #374558;
}
body.dark-only .flot-chart-container .legend .table tbody {
  background-color: #1f2639;
  color: #fff;
}
body.dark-only .flot-chart-container .legend .table tbody .legendLabel {
  padding-left: 5px;
}
body.dark-only .google-visualization-tooltip text {
  fill: rgba(0, 0, 0, 0.7) !important;
}
body.dark-only .nav-dark .nav-link,
body.dark-only .nav-pills.nav-dark .nav-link {
  color: #fff;
}
body.dark-only .loader-wrapper {
  background-color: #191f31;
}
body.dark-only .loader-wrapper .loader {
  background-color: #191f31 !important;
}
body.dark-only .page-wrapper {
  /* Main Header start */
  /* Main Header ends */
}
body.dark-only .page-wrapper .page-header .translate_wrapper.active .more_lang {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-header .translate_wrapper.active .lang {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header form {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-wrapper li i {
  color: #F3F3F3;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right.right-header > ul > li .profile-media .flex-grow-1 p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .d-flex .flex-grow-1 .txt-dark {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div {
  background-color: #191f31;
  box-shadow: 0 0 14px #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div li {
  border-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:before {
  border-bottom: 7px solid #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:after {
  border-bottom: 7px solid #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li:last-child {
  background-color: transparent;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div .bg-light {
  background-color: #282e38 !important;
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg path,
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg line,
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg circle {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg line,
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg path,
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg circle {
  color: var(--theme-default) !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .d-flex .flex-grow-1 .txt-dark {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div {
  background-color: #191f31;
  box-shadow: 0 0 2px 2px #1f2639;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:before {
  border-bottom: 7px solid #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:after {
  border-bottom: 7px solid #191f31;
}
body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content .bookmark-icon {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-logo-wrapper {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-dark {
  display: none;
}
body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-light {
  display: block;
}
body.dark-only .page-wrapper .page-body-wrapper {
  /* body start*/
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td p {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .header-small {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .statistics p {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .flex-grow-1 p,
body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .professor-block p,
body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .flex-grow-1 p,
body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .professor-block p {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .logs-element span + span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-block .progress-title span + span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .ecommerce-icons div span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .new-users .d-flex .flex-grow-1 p,
body.dark-only .page-wrapper .page-body-wrapper .page-body .recent-notification .d-flex .flex-grow-1 p {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .d-flex .flex-grow-1 span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .progress-change span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td {
  border-top: none !important;
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr:last-child td {
  border-bottom: none;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .notifiaction-media .d-flex .flex-grow-1 .circle-left {
  border: 5px solid #334154;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner:last-child {
  border-bottom: none;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table table tbody tr td {
  border-top: none !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .number-widgets .d-flex .flex-grow-1 h6 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .d-flex .gradient-round.gradient-line-1:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .d-flex .gradient-round.small-line:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .d-flex .gradient-round.medium-line:after {
  background-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .d-flex .flex-grow-1 h6 span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card.custom-card .card-header {
  border-bottom: none !important;
}
body.dark-only .page-wrapper .page-body-wrapper .custom-card .card-header img {
  background-color: #000;
  opacity: 0.8;
}
body.dark-only .page-wrapper .page-body-wrapper .page-title .row h3 small {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card:not(.email-body) {
  border: none;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) {
  background-color: #1f2639;
  box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).widget-1 {
  background-blend-mode: overlay;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).widget-1 .widget-round .bg-round {
  box-shadow: 1px 2px 21px -2px rgba(14, 14, 16, 0.83);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).widget-1 .widget-round .bg-round .half-circle {
  background: rgba(49, 53, 66, 0);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).social-profile {
  background-blend-mode: overlay;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table.table-light tr th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table.table-light tr td {
  color: #363636;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) table thead .border-bottom-light th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) table thead .border-bottom-light td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) table tbody .border-bottom-light th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) table tbody .border-bottom-light td {
  border-bottom: 1px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).trending-card {
  background-color: #010314;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).explore-card {
  background-color: #1f2639;
  background-blend-mode: overlay;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).balance-box {
  background-blend-mode: overlay;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > rect {
  fill: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > g text {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > rect {
  fill: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > text {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body h1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body h2,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body h3,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body h4,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body h5,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body h6 {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body .d-flex {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body .d-flex p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body .gradient-border .font-wrapper {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header {
  background-color: transparent;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header > span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h2,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h3,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h4,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h5,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h6 {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i.fa-cog {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header.note-toolbar {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-body {
  background-color: transparent !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark a {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline-2x,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #animation-box .animate-widget p {
  color: #98a6ad !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .grid-item img {
  background-color: #1f2639;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .line {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) thead,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tbody,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tfoot,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tr,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) th {
  border-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead th {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead .border-bottom-primary th {
  border-bottom: 1px solid var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger td {
  color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-primary,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-secondary,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-success,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-info,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-warning,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-danger {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-light {
  color: #000;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .thead-light th {
  color: #000;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary td {
  border-bottom: 1px solid var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*=bg-] th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*=bg-] td {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd) {
  --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover td {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-double,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dotted,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dashed {
  border-left-color: #374558;
  border-right-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered {
  border-color: #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered th {
  border-color: #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-inverse tbody tr:nth-of-type(odd):hover td {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical tr,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical td {
  border-right: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody td {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-footer {
  background-color: #1f2639;
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch .switch-state {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch input:checked + .switch-state {
  background-color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bg-white {
  background-color: #fff !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-l-light {
  border-left: 1px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-grid {
  stroke: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-label {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) hr {
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-dark,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light-2x {
  color: #fff !important;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light:hover {
  color: #000 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-right {
  border-right: 1px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder .flot-text {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder svg text {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart svg g rect:first-child {
  fill: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart rect:nth-child(6) {
  fill: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#line-chart rect, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart1 rect, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart2 rect {
  fill: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > rect {
  fill: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > g > g text {
  fill: rgb(177, 177, 178);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > text {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .radial-bar:after {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num .color-bottom {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-r-light {
  border-right: 1px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-labels .ct-label,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-labels .ct-label,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-labels .ct-label,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-labels .ct-label {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-grid,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-grid,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-grid,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-grid {
  stroke: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #browser-uses-chart svg text,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #website-visiter-chart svg text {
  fill: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .clipboaard-container .form-control {
  background-color: #191f31;
  color: #98a6ad;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .img-thumbnail {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper button {
  color: #000;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-danger,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-success,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-primary {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length select {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_info,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_processing,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter input[type=search] {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover > .sorting_1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr:hover > .sorting_1 {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead td {
  border-bottom: 2px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable input,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable select {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody tr {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
  border: 1px solid #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .table-striped tbody tr:nth-of-type(odd) {
  --bs-table-accent-bg: $dark-card-background;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.current, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  border-color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  color: #98a6ad !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled :active {
  color: #98a6ad !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody td,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody td {
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even:hover > .sorting_1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
  background-color: #36405E;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.hover tbody tr:hover,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover {
  background-color: #36405E;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td {
  border-top: 1px solid #374558;
  border-right: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th:first-child,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td:first-child {
  border-left: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_2,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_3,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_1,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_2,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_3 {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #example-style-3_wrapper #example-style-3 tfoot {
  border-top: 2px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.disabled .page-link {
  background-color: #1f2639;
  border-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
  color: rgba(255, 255, 255, 0.6);
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item:hover .page-link {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.active .page-link {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .icon {
  color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-container-small {
  background-color: #1f2639;
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td span,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td p {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table h6 {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link.active, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:focus, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:hover {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a {
  background-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-active, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-selected,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-active,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-selected,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-active,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-selected,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-active,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-selected,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-active,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-selected {
  background-color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a {
  border: 2px solid #374558;
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-active, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-selected {
  border: 2px solid var(--theme-default);
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-selected:after, body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-active:after,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-active:after {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .scroll-demo {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form input {
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form .form-group:before {
  background: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(odd) .cd-timeline-content::before {
  border-left-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  border-right-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .breadcrumb.bg-white {
  background-color: #1f2639 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris-daily svg,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris svg,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #browser-uses-chart svg,
body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #website-visiter-chart svg {
  opacity: 0.5;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body #viewhtml .render {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
  border-color: #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .wizard-4 ul.anchor li a.disabled {
  color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description h4 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description a > div {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description p {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-header {
  background-color: #1f2639;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell {
  background: #1f2639;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input {
  background-color: #191f31;
  border-color: #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input:focus,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input:focus,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input:focus,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input:focus {
  outline: none;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row select,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row select {
  background-color: #191f31;
  border-color: #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-row > .jsgrid-cell {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-alt-row > .jsgrid-cell {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-cell,
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-body {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-selected-row > .jsgrid-cell {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager [class*=jsgrid-pager] {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager .jsgrid-pager-page a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-pager-current-page {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 700;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .sub-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .card .sub-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a:hover {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon .separator {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according li .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon.default-according.style-1 li button:hover {
  color: var(--theme-default) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover a {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div {
  background-color: #191f31;
  box-shadow: 0 0 14px 0 #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li p {
  color: #fff;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg path,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg line,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polyline,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polygon,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg rect {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg path,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg line,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polyline,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polygon,
body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg rect {
  color: var(--theme-default) !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .onhover-dropdown:hover .onhover-show-div:before {
  border-bottom: 7px solid #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .btn-link {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
  color: #98a6ad;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
  border: 1px solid #374558;
  border-top: none;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .light-card {
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border {
  border: 1px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-1 {
  border: 1px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-2 {
  border: 2px solid #374558;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-showcase span.border-2 {
  border: 2px solid #374558 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-3 {
  border: 3px solid #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-4 {
  border: 4px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-5 {
  border: 5px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-6 {
  border: 6px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-7 {
  border: 7px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-8 {
  border: 8px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-9 {
  border: 9px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-10 {
  border: 10px solid #191f31 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-primary {
  border: 1px solid #307EF3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-primary {
  border: none !important;
  border-top: 1px solid #307EF3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-primary {
  border: 1px solid #307EF3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-primary {
  border: none !important;
  border-bottom: 1px solid #307EF3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-primary {
  border: 1px solid #307EF3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-primary {
  border: none !important;
  border-left: 1px solid #307EF3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-primary {
  border: 1px solid #307EF3;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-primary {
  border: none !important;
  border-right: 1px solid #307EF3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-primary {
  border: 1px solid #307EF3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-primary {
  border: 1px solid #307EF3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-secondary {
  border: 1px solid #EBA31D !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-secondary {
  border: none !important;
  border-top: 1px solid #EBA31D !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-secondary {
  border: 1px solid #EBA31D !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-secondary {
  border: none !important;
  border-bottom: 1px solid #EBA31D !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-secondary {
  border: 1px solid #EBA31D !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-secondary {
  border: none !important;
  border-left: 1px solid #EBA31D !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-secondary {
  border: 1px solid #EBA31D;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-secondary {
  border: none !important;
  border-right: 1px solid #EBA31D !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-secondary {
  border: 1px solid #EBA31D !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-secondary {
  border: 1px solid #EBA31D !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-success {
  border: 1px solid #53A653 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-success {
  border: none !important;
  border-top: 1px solid #53A653 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-success {
  border: 1px solid #53A653 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-success {
  border: none !important;
  border-bottom: 1px solid #53A653 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-success {
  border: 1px solid #53A653 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-success {
  border: none !important;
  border-left: 1px solid #53A653 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-success {
  border: 1px solid #53A653;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-success {
  border: none !important;
  border-right: 1px solid #53A653 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-success {
  border: 1px solid #53A653 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-success {
  border: 1px solid #53A653 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-danger {
  border: 1px solid #DC3545 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-danger {
  border: none !important;
  border-top: 1px solid #DC3545 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-danger {
  border: 1px solid #DC3545 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-danger {
  border: none !important;
  border-bottom: 1px solid #DC3545 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-danger {
  border: 1px solid #DC3545 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-danger {
  border: none !important;
  border-left: 1px solid #DC3545 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-danger {
  border: 1px solid #DC3545;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-danger {
  border: none !important;
  border-right: 1px solid #DC3545 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-danger {
  border: 1px solid #DC3545 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-danger {
  border: 1px solid #DC3545 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-warning {
  border: 1px solid #FFAA05 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-warning {
  border: none !important;
  border-top: 1px solid #FFAA05 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-warning {
  border: 1px solid #FFAA05 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-warning {
  border: none !important;
  border-bottom: 1px solid #FFAA05 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-warning {
  border: 1px solid #FFAA05 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-warning {
  border: none !important;
  border-left: 1px solid #FFAA05 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-warning {
  border: 1px solid #FFAA05;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-warning {
  border: none !important;
  border-right: 1px solid #FFAA05 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-warning {
  border: 1px solid #FFAA05 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-warning {
  border: 1px solid #FFAA05 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-info {
  border: 1px solid #01A1B9 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-info {
  border: none !important;
  border-top: 1px solid #01A1B9 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-info {
  border: 1px solid #01A1B9 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-info {
  border: none !important;
  border-bottom: 1px solid #01A1B9 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-info {
  border: 1px solid #01A1B9 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-info {
  border: none !important;
  border-left: 1px solid #01A1B9 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-info {
  border: 1px solid #01A1B9;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-info {
  border: none !important;
  border-right: 1px solid #01A1B9 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-info {
  border: 1px solid #01A1B9 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-info {
  border: 1px solid #01A1B9 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-light {
  border: 1px solid #F3F3F3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-light {
  border: none !important;
  border-top: 1px solid #F3F3F3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-light {
  border: 1px solid #F3F3F3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-light {
  border: none !important;
  border-bottom: 1px solid #F3F3F3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-light {
  border: 1px solid #F3F3F3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-light {
  border: none !important;
  border-left: 1px solid #F3F3F3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-light {
  border: 1px solid #F3F3F3;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-light {
  border: none !important;
  border-right: 1px solid #F3F3F3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-light {
  border: 1px solid #F3F3F3 !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-light {
  border: 1px solid #F3F3F3 !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-dark {
  border: 1px solid #2c323f !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-t-dark {
  border: none !important;
  border-top: 1px solid #2c323f !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-t-dark {
  border: 1px solid #2c323f !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-b-dark {
  border: none !important;
  border-bottom: 1px solid #2c323f !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-dark {
  border: 1px solid #2c323f !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-l-dark {
  border: none !important;
  border-left: 1px solid #2c323f !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-l-dark {
  border: 1px solid #2c323f;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-r-dark {
  border: none !important;
  border-right: 1px solid #2c323f !important;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-dark {
  border: 1px solid #2c323f !important;
  background-color: #191f31;
}
body.dark-only .page-wrapper .page-body-wrapper .page-body .border-dark {
  border: 1px solid #2c323f !important;
}
body.dark-only .page-wrapper .page-body-wrapper .footer {
  border-top: 1px solid #191f31;
  background-color: #1f2639;
}
body.dark-only .page-wrapper .page-body-wrapper .footer p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .custom-select,
body.dark-only .page-wrapper .page-body-wrapper .custom-file-label {
  background: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .page-body-wrapper .footer-fix {
  background-color: #1f2639;
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .note textarea {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .dt-button-info {
  background-color: #1f2639;
  border: 1px solid #98a6ad;
}
body.dark-only .page-wrapper .dt-button-info h2 {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .chat-box .about .name {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .chat-box .chat-menu {
  border-left: 1px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .icon-wrapper {
  background-color: #1f2639;
  box-shadow: 1px 1px 3px 1px #191f31;
}
body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .avatar img {
  border: 5px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .social-media a {
  color: #98a6ad;
}
body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow span {
  color: #98a6ad;
}
body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .chat-box .status {
  color: #98a6ad;
}
body.dark-only .page-wrapper .chat-box .status p {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
  color: #98a6ad;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header img {
  box-shadow: 1px 1px 4px 1px #191f31;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .my-message {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .message {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  background-color: #191f31;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message {
  background-color: #1f2639;
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message .input-group .form-control {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li a {
  color: #98a6ad;
}
body.dark-only .page-wrapper pre {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .scorlled {
  background-color: #191f31;
}
body.dark-only .page-wrapper .input-group-air {
  box-shadow: 0 3px 20px 0 #374558;
}
body.dark-only .page-wrapper .input-group-text {
  background-color: #1f2639;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .input-group-text i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .input-group-solid .input-group-text,
body.dark-only .page-wrapper .input-group-solid .form-control {
  background: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .semilight-bg-color,
body.dark-only .page-wrapper .header-bg-color {
  background-color: #fff;
}
body.dark-only .page-wrapper .list-group-item {
  background-color: #1f2639;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .list-group-item + .list-group-item {
  border-top-width: 0;
}
body.dark-only .page-wrapper .list-group-item.active {
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default);
  color: #fff;
}
body.dark-only .page-wrapper .list-group-item:hover, body.dark-only .page-wrapper .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
body.dark-only .page-wrapper .list-group-item-action:hover:not(.active), body.dark-only .page-wrapper .list-group-item-action:focus {
  background-color: #191f31;
}
body.dark-only .page-wrapper .list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
body.dark-only .page-wrapper .dark-list .list-group .border-left-primary {
  border-left: 4px solid var(--theme-default);
}
body.dark-only .page-wrapper .dark-list .list-group .border-left-secondary {
  border-left: 4px solid var(--theme-secondary);
}
body.dark-only .page-wrapper .dark-list .list-group .border-left-warning {
  border-left: 4px solid #FFAA05;
}
body.dark-only .page-wrapper .dark-list .list-group .border-left-success {
  border-left: 4px solid #53A653;
}
body.dark-only .page-wrapper .dark-list .list-group .border-left-info {
  border-left: 4px solid #01A1B9;
}
body.dark-only .page-wrapper .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
body.dark-only .page-wrapper .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
body.dark-only .page-wrapper .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
body.dark-only .page-wrapper .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
body.dark-only .page-wrapper .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
body.dark-only .page-wrapper .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
body.dark-only .page-wrapper .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
body.dark-only .page-wrapper .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
body.dark-only .page-wrapper .auth-bg {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}
body.dark-only .page-wrapper .auth-bg .authentication-box .card {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group input {
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label::before {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .auth-bg .authentication-box h3,
body.dark-only .page-wrapper .auth-bg .authentication-box h4,
body.dark-only .page-wrapper .auth-bg .authentication-box h6 {
  color: #fff;
}
body.dark-only .page-wrapper .auth-bg-video {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.7);
}
body.dark-only .page-wrapper .auth-bg-video .authentication-box h4,
body.dark-only .page-wrapper .auth-bg-video .authentication-box h3 {
  color: #fff;
}
body.dark-only .page-wrapper .auth-bg-video .authentication-box h6 {
  color: #98a6ad;
}
body.dark-only .page-wrapper .auth-bg-video .authentication-box .card {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type=text],
body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type=password] {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .checkbox label::before {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
  color: #374558;
}
body.dark-only .page-wrapper .error-wrapper .maintenance-icons li:nth-child(3) i {
  color: #374558;
}
body.dark-only .page-wrapper .modal-content {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .modal-content .modal-header {
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .modal-content .modal-header .close {
  color: #98a6ad;
}
body.dark-only .page-wrapper .modal-content .modal-footer {
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .comingsoon {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}
body.dark-only .page-wrapper .comingsoon .comingsoon-inner h5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown {
  border-top: 1px solid #374558;
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown .title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .comingsoon .comingsoon-inner .coming-soon-bottom-link a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .theme-form .login-divider {
  border-top: 1px solid #374558;
}
body.dark-only .page-wrapper .theme-form .login-divider:before {
  background: #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .authentication-main {
  background-color: #374558;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .card {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .reset-password-link {
  color: #98a6ad;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group label {
  color: #98a6ad;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group .form-control {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border-color: #374558;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .opt-box {
  background-color: #374558;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h4 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h6 {
  color: #98a6ad;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h3 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type=text],
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type=password] {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .checkbox label::before {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .page-wrapper .vertical-menu-main {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .mega-menu .title {
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #374558;
}
body.dark-only .page-wrapper .mega-menu .list-unstyled div a:hover {
  color: var(--theme-default);
}
body.dark-only .page-wrapper .default-according .card {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .default-according .card .btn-link {
  background-color: #1f2639;
  border: 1px solid #374558;
  border-bottom: none;
  color: #fff;
}
body.dark-only .page-wrapper .default-according .card .text-muted {
  color: #98a6ad !important;
}
body.dark-only .page-wrapper .default-according .bg-primary .btn-link {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
}
body.dark-only .page-wrapper .default-according .bg-secondary .btn-link {
  background-color: var(--theme-secondary);
  border: 1px solid var(--theme-secondary);
}
body.dark-only .page-wrapper .collapse .card-body {
  background-color: #1f2639;
}
body.dark-only .page-wrapper .balance-widget.card-body {
  background-color: #1f2639;
  background-blend-mode: overlay;
}
@media screen and (max-width: 1660px) {
  body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid #374558;
  }
  body.dark-only .page-wrapper .chat-box .chat-history .total-time h2 {
    color: rgba(255, 255, 255, 0.6);
  }
  body.dark-only .page-wrapper .caller-img img {
    opacity: 0.7;
  }
  body.dark-only .page-wrapper .chat-box .chat-history .call-content > div {
    background-color: rgba(0, 0, 0, 0.75);
    background-blend-mode: overlay;
  }
}
@media only screen and (max-width: 1199px) {
  body.dark-only .page-wrapper .mobile-title.d-none {
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper .md-sidebar .job-sidebar {
    background-color: #1f2639;
  }
  body.dark-only .page-wrapper .chat-menu {
    border-top: 1px solid #374558;
    background-color: #1f2639;
  }
  body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
    color: #2c323f;
  }
}
@media only screen and (max-width: 575.98px) {
  body.dark-only .page-wrapper .user-profile .hovercard .info .user-designation {
    border-top: 1px solid #374558;
    border-bottom: 1px solid #374558;
  }
  body.dark-only .page-wrapper .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, body.dark-only .page-wrapper .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    border-right: 1px solid #374558;
  }
}
body.dark-only .lg-backdrop {
  background-color: #191f31;
}
body.dark-only .lg-outer .lg-thumb-outer {
  background-color: #1f2639;
}
body.dark-only .drag {
  background-color: #1f2639;
}
body.dark-only .drag form {
  background: #1f2639;
  box-shadow: 0 0 30px #374558;
  border: 1px dotted #374558;
}
body.dark-only .drag form input {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .drag form .help-block {
  text-transform: capitalize;
  color: #98a6ad;
}
body.dark-only .draggable input {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .draggable p {
  color: #98a6ad;
}
body.dark-only .draggable select {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .draggable .radio label::before,
body.dark-only .draggable .checkbox label::before {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .search-page .info-block {
  border: 1px solid #374558;
}
body.dark-only .search-page .info-block a {
  color: #fff;
}
body.dark-only .social-status .d-flex .flex-grow-1 span:first-child {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .filter-cards-view span {
  color: rgba(255, 255, 255, 0.8);
}
body.dark-only .icofont-ui-edit {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .select2-container--default .select2-selection--multiple,
body.dark-only .select2-container--default .select2-selection--single {
  background-color: #191f31;
  border: 1px solid #191f31 !important;
}
body.dark-only .select2-container--default .select2-search--inline .select2-search__field {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #191f31;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1f2639 !important;
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .select2-dropdown {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only .select2-drpdwn .form-control-primary {
  border-color: var(--theme-default) !important;
  color: var(--theme-default) !important;
}
body.dark-only .select2-drpdwn .form-control-secondary {
  border-color: var(--theme-secondary) !important;
  color: var(--theme-secondary) !important;
}
body.dark-only .select2-drpdwn .form-control-success {
  border-color: #53A653 !important;
  color: #53A653 !important;
}
body.dark-only .select2-drpdwn .form-control-info {
  border-color: #01A1B9 !important;
  color: #01A1B9 !important;
}
body.dark-only .select2-drpdwn .form-control-warning {
  border-color: #FFAA05 !important;
  color: #FFAA05 !important;
}
body.dark-only .select2-drpdwn .form-control-danger {
  border-color: #DC3545 !important;
  color: #DC3545 !important;
}
body.dark-only .select2-drpdwn .form-control-inverse {
  border-color: #fff !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-primary-fill {
  background-color: var(--theme-default) !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-secondary-fill {
  background-color: var(--theme-secondary) !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-success-fill {
  background-color: #53A653 !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-info-fill {
  background-color: #01A1B9 !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-warning-fill {
  background-color: #FFAA05 !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-danger-fill {
  background-color: #DC3545 !important;
  color: #fff !important;
}
body.dark-only .select2-drpdwn .form-control-inverse-fill {
  background-color: #fff !important;
  color: #000 !important;
}
body.dark-only .select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #374558;
}
body.dark-only .theme-form input[type=text],
body.dark-only .theme-form input[type=email],
body.dark-only .theme-form input[type=search],
body.dark-only .theme-form input[type=password],
body.dark-only .theme-form input[type=number],
body.dark-only .theme-form input[type=tel],
body.dark-only .theme-form input[type=date],
body.dark-only .theme-form input[type=datetime-local],
body.dark-only .theme-form input[type=time],
body.dark-only .theme-form input[type=datetime-local],
body.dark-only .theme-form input[type=month],
body.dark-only .theme-form input[type=week],
body.dark-only .theme-form input[type=url],
body.dark-only .theme-form input[type=file],
body.dark-only .theme-form textarea,
body.dark-only .theme-form select {
  border: 1px solid #374558;
  background-color: #191f31 !important;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .theme-form .form-group select.form-control:not([size]):not([multiple]) {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border-color: #374558;
}
body.dark-only .theme-form .form-group input[type=text],
body.dark-only .theme-form .form-group input[type=email],
body.dark-only .theme-form .form-group input[type=search],
body.dark-only .theme-form .form-group input[type=password],
body.dark-only .theme-form .form-group input[type=number],
body.dark-only .theme-form .form-group input[type=tel],
body.dark-only .theme-form .form-group input[type=date],
body.dark-only .theme-form .form-group input[type=datetime-local],
body.dark-only .theme-form .form-group input[type=time],
body.dark-only .theme-form .form-group input[type=datetime-local],
body.dark-only .theme-form .form-group input[type=month],
body.dark-only .theme-form .form-group input[type=week],
body.dark-only .theme-form .form-group input[type=url],
body.dark-only .theme-form .form-group input[type=file],
body.dark-only .theme-form .form-group select {
  border: 1px solid #374558;
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .theme-form .form-group input[type=text]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=email]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=search]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=password]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=number]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=tel]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=date]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=time]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=month]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=week]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=url]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group input[type=file]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group select::-webkit-input-placeholder {
  color: #98a6ad;
}
body.dark-only .theme-form .form-group .form-control {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .theme-form .form-group textarea {
  border-color: #374558;
}
body.dark-only .theme-form .form-divider {
  border-top: 1px solid #374558;
}
body.dark-only .theme-form .form-divider::before {
  background: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .CodeMirror {
  background: #1f2639;
  border: 1px solid #98a6ad;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .CodeMirror .CodeMirror-code pre {
  background: #1f2639;
}
body.dark-only .editor-toolbar {
  border-top: 1px solid #98a6ad;
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}
body.dark-only .editor-toolbar a {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .editor-toolbar a:hover, body.dark-only .editor-toolbar a.active {
  background: #374558;
}
body.dark-only .editor-toolbar i.separator {
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}
body.dark-only .editor-toolbar.fullscreen::before {
  background: linear-gradient(to right, #191f31 0, rgba(0, 0, 0, 0) 100%);
}
body.dark-only .editor-toolbar.fullscreen::after {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, #191f31 100%);
}
body.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: #374558;
}
body.dark-only .editor-preview {
  background-color: #1f2639;
}
body.dark-only .editor-toolbar.fullscreen,
body.dark-only .editor-preview-side {
  background: #1f2639;
  border: 1px solid #98a6ad;
}
body.dark-only .u-step {
  background: #374558;
}
body.dark-only .u-step.active, body.dark-only .u-step.current {
  background: var(--theme-default);
  color: #fff;
}
body.dark-only .u-step-title,
body.dark-only .u-pearl-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .u-step-number {
  background-color: #1f2639;
}
body.dark-only .u-pearl:before {
  background-color: #374558;
}
body.dark-only .u-pearl-number,
body.dark-only .u-pearl-icon {
  background: #191f31;
  border: 2px solid #191f31;
}
body.dark-only .u-pearl.disabled .u-pearl-icon,
body.dark-only .u-pearl.disabled .u-pearl-number {
  background: #374558;
  border: 2px solid #374558;
}
body.dark-only .u-pearl.disabled:after {
  background-color: #334053;
}
body.dark-only .u-pearl.error:after {
  background-color: #191f31;
}
body.dark-only .modal-body .form-bookmark label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-body .form-bookmark .form-control {
  background-color: #191f31 !important;
  border: none;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-body .form-bookmark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-body h4 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-footer {
  border-top: 1px solid #191f31;
}
body.dark-only .note-editor.note-frame {
  border-color: #374558;
}
body.dark-only .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .swal-modal {
  background-color: #1f2639;
}
body.dark-only .swal-modal .swal-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .swal-modal .swal-text {
  color: #98a6ad;
}
body.dark-only .swal-modal .swal-content__input {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .nav-tabs {
  border-bottom: 1px solid #374558;
}
body.dark-only .nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .nav-tabs .nav-link.active {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
  border-color: #374558 #374558 #1f2639;
}
body.dark-only .nav-tabs .nav-link:hover, body.dark-only .nav-tabs .nav-link:focus {
  border-color: #374558 #374558 #323846;
}
body.dark-only .nav-tabs .nav-item.show .nav-link {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
  border-color: #374558 #374558 #1f2639;
}
body.dark-only .nav-primary .nav-link.active {
  background-color: var(--theme-default);
}
body.dark-only .nav-primary .nav-item.show .nav-link {
  background-color: var(--theme-default);
}
body.dark-only .custom-tab.nav-tabs {
  border-bottom: none;
}
body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active, body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus, body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #1f2639 #374558 #374558;
}
body.dark-only .border-tab .nav-link.active {
  background-color: transparent;
}
body.dark-only .border-tab .show > .nav-link {
  background-color: transparent;
}
body.dark-only .border-tab.nav-left .nav-link,
body.dark-only .border-tab.nav-right .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .border-tab.nav-left .nav-link.active,
body.dark-only .border-tab.nav-right .nav-link.active {
  color: var(--theme-default);
}
body.dark-only .border-tab.nav-left .show > .nav-link,
body.dark-only .border-tab.nav-right .show > .nav-link {
  color: var(--theme-default);
}
body.dark-only .border-tab.nav-left.nav-secondary .nav-link.active, body.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus, body.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
  border-left-color: var(--theme-secondary);
  color: var(--theme-secondary) !important;
}
body.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: var(--theme-secondary);
  color: var(--theme-secondary) !important;
}
body.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
  color: var(--theme-secondary) !important;
  border-left-color: var(--theme-secondary);
}
body.dark-only .border-tab.nav-left.nav-success .nav-link.active, body.dark-only .border-tab.nav-left.nav-success .nav-link:focus, body.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
  color: #53A653 !important;
}
body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #53A653;
  color: #53A653 !important;
}
body.dark-only .border-tab.nav-left.nav-success .nav-item.show {
  color: #53A653 !important;
  border-left-color: #53A653;
}
body.dark-only .border-tab.nav-right.nav-info .nav-link.active, body.dark-only .border-tab.nav-right.nav-info .nav-link:focus, body.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
  color: #01A1B9 !important;
}
body.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
  border-left-color: #01A1B9;
  color: #01A1B9 !important;
}
body.dark-only .border-tab.nav-right.nav-info .nav-item.show {
  color: #01A1B9 !important;
  border-left-color: #01A1B9;
}
body.dark-only .border-tab.nav-secondary .nav-link.active, body.dark-only .border-tab.nav-secondary .nav-link:focus, body.dark-only .border-tab.nav-secondary .nav-link:hover {
  color: var(--theme-secondary) !important;
}
body.dark-only .border-tab.nav-secondary .nav-item.show {
  color: var(--theme-secondary) !important;
}
body.dark-only .border-tab.nav-success .nav-link.active, body.dark-only .border-tab.nav-success .nav-link:focus, body.dark-only .border-tab.nav-success .nav-link:hover {
  color: #53A653 !important;
}
body.dark-only .border-tab.nav-success .nav-item.show {
  color: #53A653 !important;
}
body.dark-only .dropdown-divider {
  border-top: 1px solid #374558;
}
body.dark-only .icon-hover-bottom {
  background-color: #1f2639;
  box-shadow: 0px 0px 1px 1px #374558;
}
body.dark-only .icon-hover-bottom .icon-title {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .icon-hover-bottom span {
  color: #98a6ad;
}
body.dark-only .icon-hover-bottom .form-group input {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .icon-hover-bottom .icon-first i {
  color: #98a6ad;
}
body.dark-only code {
  background-color: #191f31;
  border-radius: 2px;
}
body.dark-only #cd-timeline::before {
  background: #374558;
}
body.dark-only .timeliny {
  border-top: 1px solid #374558;
  border-bottom: 1px solid #374558;
}
body.dark-only .timeliny::before {
  background-color: #fff;
}
body.dark-only .timeliny .timeliny-dot {
  background-color: #1f2639;
  border: 1px solid #fff;
}
body.dark-only .timeliny .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
  background-color: #374558;
  color: rgba(255, 255, 255, 0.6);
  border: none;
}
body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover {
  background-color: #fff;
}
body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover::before {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
  background-color: #1f2639;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
  background: #1f2639;
  border-bottom: 1px solid #374558;
}
body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
  color: #fff;
}
body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box:hover:not(.active) {
  background: #1f2639;
  border: 1px solid #374558;
}
body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .task-label {
  color: var(--theme-default);
}
body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn:hover {
  border: 1px solid #53A653 !important;
}
body.dark-only .todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea {
  background-color: #1f2639;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .user-profile .ttl-info h6 {
  color: #98a6ad;
}
body.dark-only .user-profile .ttl-info span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .user-profile .hovercard .info .title a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .user-profile .hovercard .user-image .avatar img {
  border: 10px solid #1f2639;
}
body.dark-only .user-profile .hovercard .user-image .icon-wrapper {
  background-color: #1f2639;
}
body.dark-only .user-profile .hovercard .tabs-scoial {
  border-bottom: none !important;
}
body.dark-only .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .user-profile .profile-img-style .user-name {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .user-profile .profile-img-style p {
  color: #98a6ad;
}
body.dark-only .introjs-helperLayer {
  background-color: rgba(54, 64, 74, 0.9);
  border: rgba(255, 255, 255, 0.5);
}
body.dark-only .jstree-default .jstree-node,
body.dark-only .jstree-default .jstree-icon {
  color: #455869 !important;
}
body.dark-only .jstree-default .jstree-anchor {
  color: #98a6ad;
}
body.dark-only .jstree-default .jstree-clicked {
  color: #fff;
  background-color: transparent;
}
body.dark-only .jstree-default .jstree-hovered {
  background-color: #191f31;
  border: 1px solid #374558;
}
body.dark-only span.twitter-typeahead .tt-menu {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only span.twitter-typeahead .tt-suggestion {
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31;
  border-top: 1px solid #374558;
}
body.dark-only span.twitter-typeahead .tt-suggestion:hover, body.dark-only span.twitter-typeahead .tt-suggestion:focus {
  background-color: #1f2639;
}
body.dark-only .typography small {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .code-box-copy pre {
  background-color: #191f31;
}
body.dark-only .code-box-copy pre code {
  background-color: #191f31;
}
body.dark-only .code-box-copy pre[class*=language-] {
  border: 1px solid #374558;
}
body.dark-only .code-box-copy .code-box-copy__btn {
  background-color: #191f31;
  border: 1px solid #374558;
  color: #fff;
}
body.dark-only .code-box-copy code[class*=language-],
body.dark-only .code-box-copy pre[class*=language-] {
  text-shadow: 0px 1px #000;
}
body.dark-only .code-box-copy code[class*=language-] ::selection,
body.dark-only .code-box-copy pre[class*=language-] ::selection {
  text-shadow: none;
  background: #1f2639;
}
body.dark-only table.fixedHeader-floating {
  background-color: #191f31;
}
body.dark-only .note textarea {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .dt-button-info {
  background-color: #1f2639;
  border: 1px solid #374558;
}
body.dark-only .dt-button-info h2 {
  background-color: #1f2639;
  border-bottom: 1px solid #374558;
}
body.dark-only pre {
  background-color: #191f31;
}
body.dark-only #keytable_wrapper .dataTables_paginate,
body.dark-only #column-selector_wrapper .dataTables_paginate,
body.dark-only #auto-fill_wrapper .dataTables_paginate,
body.dark-only #custom-button_wrapper .dataTables_paginate,
body.dark-only #class-button_wrapper .dataTables_paginate,
body.dark-only #keyboard-btn_wrapper .dataTables_paginate,
body.dark-only #multilevel-btn_wrapper .dataTables_paginate,
body.dark-only #pagelength-btn_wrapper .dataTables_paginate,
body.dark-only #basic-colreorder_wrapper .dataTables_paginate,
body.dark-only #state-saving_wrapper .dataTables_paginate,
body.dark-only #real-time_wrapper .dataTables_paginate,
body.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
body.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
body.dark-only #export-button_wrapper .dataTables_paginate,
body.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
body.dark-only #cust-json_wrapper .dataTables_paginate,
body.dark-only #basic-key-table_wrapper .dataTables_paginate,
body.dark-only #focus-cell_wrapper .dataTables_paginate,
body.dark-only #responsive_wrapper .dataTables_paginate,
body.dark-only #new-cons_wrapper .dataTables_paginate,
body.dark-only #show-hidden-row_wrapper .dataTables_paginate,
body.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
body.dark-only #full-row_wrapper .dataTables_paginate,
body.dark-only #rest-column_wrapper .dataTables_paginate {
  border: none;
}
body.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
body.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
  background-color: #374558;
}
body.dark-only .error-wrapper {
  background-color: rgba(54, 64, 74, 0.8);
}
body.dark-only .error-wrapper .sub-content {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .b-light {
  border: 1px solid #374558 !important;
}
body.dark-only .modal-content {
  background-color: #1f2639;
}
body.dark-only .modal-content .modal-header {
  border-bottom: 1px solid #374558;
}
body.dark-only .modal-content .modal-header .close {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
body.dark-only .modal-content .ui-front .form-control {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid #374558;
}
body.dark-only .modal .theme-close {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .token.atrule,
body.dark-only .token.attr-value,
body.dark-only .token.keyword {
  color: #01A1B9;
}
body.dark-only .token.boolean, body.dark-only .token.constant, body.dark-only .token.deleted, body.dark-only .token.number, body.dark-only .token.property, body.dark-only .token.symbol, body.dark-only .token.tag {
  color: #DC3545;
}
body.dark-only .loader-box.card-loader {
  background-color: #1f2639;
}
body.dark-only .my-gallery.gallery-with-description img {
  border: 1px solid #374558 !important;
  border-bottom: none !important;
}
body.dark-only .alert-primary.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-secondary.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-success.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-danger.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-warning.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-info.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-light.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-dark.inverse {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .alert-theme span + span + span {
  border-left: 5px solid var(--theme-default);
  background-color: #191f31;
  border-radius: 4px;
}
body.dark-only .alert-theme i {
  color: #fff;
  padding: 20px;
  margin-right: 20px !important;
}
body.dark-only .user-card .user-deatils h6 {
  color: #98a6ad;
}
body.dark-only .user-card .card-footer > div:first-child {
  border-right: 1px solid #374558;
}
body.dark-only .user-card .card-footer .user-footer h6 {
  color: #98a6ad;
}
body.dark-only .user-card .card-footer .user-footer svg path,
body.dark-only .user-card .card-footer .user-footer svg rect {
  color: #98a6ad;
}
body.dark-only .order-history table .qty-box button {
  background-color: #191f31 !important;
  border: none !important;
}
body.dark-only .order-history table .qty-box .input-group {
  border-color: #374558;
}
body.dark-only .order-history table thead tr th {
  background-color: #191f31;
}
body.dark-only .order-history table tr td,
body.dark-only .order-history table tr th {
  border-top: none !important;
}
body.dark-only .order-history .title-orders {
  background-color: #191f31;
}
body.dark-only .navigation-option ul li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .navigation-option ul li:hover {
  background-color: #191f31;
}
body.dark-only .product-box .product-details h6 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .product-box .product-details > a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .product-box .product-details span {
  color: #98a6ad;
}
body.dark-only .product-box .product-img .product-hover ul li {
  background-color: #1f2639;
}
body.dark-only .product-box .product-img .product-hover ul li:hover {
  background-color: #000;
  color: #fff;
}
body.dark-only .browse .browse-articles ul li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .browse .browse-articles ul li:hover {
  background-color: #191f31;
}
body.dark-only .radio-primary input[type=radio] + label::before {
  border-color: var(--theme-default) !important;
}
body.dark-only .radio-primary input[type=radio] + label::after {
  background-color: var(--theme-default);
}
body.dark-only .radio-primary input[type=radio]:checked + label::before {
  border-color: var(--theme-default) !important;
}
body.dark-only .radio-primary input[type=radio]:checked + label::after {
  background-color: var(--theme-default);
}
body.dark-only .radio-secondary input[type=radio] + label::before {
  border-color: #EBA31D !important;
}
body.dark-only .radio-secondary input[type=radio] + label::after {
  background-color: #EBA31D;
}
body.dark-only .radio-secondary input[type=radio]:checked + label::before {
  border-color: #EBA31D !important;
}
body.dark-only .radio-secondary input[type=radio]:checked + label::after {
  background-color: #EBA31D;
}
body.dark-only .radio-success input[type=radio] + label::before {
  border-color: #53A653 !important;
}
body.dark-only .radio-success input[type=radio] + label::after {
  background-color: #53A653;
}
body.dark-only .radio-success input[type=radio]:checked + label::before {
  border-color: #53A653 !important;
}
body.dark-only .radio-success input[type=radio]:checked + label::after {
  background-color: #53A653;
}
body.dark-only .radio-danger input[type=radio] + label::before {
  border-color: #DC3545 !important;
}
body.dark-only .radio-danger input[type=radio] + label::after {
  background-color: #DC3545;
}
body.dark-only .radio-danger input[type=radio]:checked + label::before {
  border-color: #DC3545 !important;
}
body.dark-only .radio-danger input[type=radio]:checked + label::after {
  background-color: #DC3545;
}
body.dark-only .radio-info input[type=radio] + label::before {
  border-color: #01A1B9 !important;
}
body.dark-only .radio-info input[type=radio] + label::after {
  background-color: #01A1B9;
}
body.dark-only .radio-info input[type=radio]:checked + label::before {
  border-color: #01A1B9 !important;
}
body.dark-only .radio-info input[type=radio]:checked + label::after {
  background-color: #01A1B9;
}
body.dark-only .radio-light input[type=radio] + label::before {
  border-color: #F3F3F3 !important;
}
body.dark-only .radio-light input[type=radio] + label::after {
  background-color: #F3F3F3;
}
body.dark-only .radio-light input[type=radio]:checked + label::before {
  border-color: #F3F3F3 !important;
}
body.dark-only .radio-light input[type=radio]:checked + label::after {
  background-color: #F3F3F3;
}
body.dark-only .radio-dark input[type=radio] + label::before {
  border-color: #2c323f !important;
}
body.dark-only .radio-dark input[type=radio] + label::after {
  background-color: #2c323f;
}
body.dark-only .radio-dark input[type=radio]:checked + label::before {
  border-color: #2c323f !important;
}
body.dark-only .radio-dark input[type=radio]:checked + label::after {
  background-color: #2c323f;
}
body.dark-only .radio-warning input[type=radio] + label::before {
  border-color: #FFAA05 !important;
}
body.dark-only .radio-warning input[type=radio] + label::after {
  background-color: #FFAA05;
}
body.dark-only .radio-warning input[type=radio]:checked + label::before {
  border-color: #FFAA05 !important;
}
body.dark-only .radio-warning input[type=radio]:checked + label::after {
  background-color: #FFAA05;
}
body.dark-only .checkbox-primary label::before {
  border-color: var(--theme-default) !important;
}
body.dark-only .checkbox-secondary label::before {
  border-color: #EBA31D !important;
}
body.dark-only .checkbox-success label::before {
  border-color: #53A653 !important;
}
body.dark-only .checkbox-danger label::before {
  border-color: #DC3545 !important;
}
body.dark-only .checkbox-info label::before {
  border-color: #01A1B9 !important;
}
body.dark-only .checkbox-light label::before {
  border-color: #F3F3F3 !important;
}
body.dark-only .checkbox-dark label::before {
  border-color: #2c323f !important;
}
body.dark-only .checkbox-warning label::before {
  border-color: #FFAA05 !important;
}
body.dark-only .checkbox-solid-primary label:before {
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default) !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-primary input[type=checkbox]:checked + label::before {
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default) !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-primary input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-secondary label:before {
  background-color: #EBA31D !important;
  border-color: #EBA31D !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-secondary input[type=checkbox]:checked + label::before {
  background-color: #EBA31D !important;
  border-color: #EBA31D !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-secondary input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-success label:before {
  background-color: #53A653 !important;
  border-color: #53A653 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-success input[type=checkbox]:checked + label::before {
  background-color: #53A653 !important;
  border-color: #53A653 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-success input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-danger label:before {
  background-color: #DC3545 !important;
  border-color: #DC3545 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-danger input[type=checkbox]:checked + label::before {
  background-color: #DC3545 !important;
  border-color: #DC3545 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-danger input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-info label:before {
  background-color: #01A1B9 !important;
  border-color: #01A1B9 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-info input[type=checkbox]:checked + label::before {
  background-color: #01A1B9 !important;
  border-color: #01A1B9 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-info input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-light label:before {
  background-color: #F3F3F3 !important;
  border-color: #F3F3F3 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-light input[type=checkbox]:checked + label::before {
  background-color: #F3F3F3 !important;
  border-color: #F3F3F3 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-light input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-dark label:before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-dark input[type=checkbox]:checked + label::before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-dark input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .checkbox-solid-warning label:before {
  background-color: #FFAA05 !important;
  border-color: #FFAA05 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-warning input[type=checkbox]:checked + label::before {
  background-color: #FFAA05 !important;
  border-color: #FFAA05 !important;
  color: #fff !important;
}
body.dark-only .checkbox-solid-warning input[type=checkbox]:checked + label::after {
  color: #fff !important;
}
body.dark-only .bootstrap-datetimepicker-widget ul li {
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #374558;
}
body.dark-only .bootstrap-datetimepicker-widget tr th:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td:hover {
  background-color: #1f2639;
}
body.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
  background-color: #1f2639;
}
body.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover, body.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
  background-color: #1f2639;
}
body.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
  background-color: #1f2639;
}
body.dark-only .btn-transparent {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only #cal-basic .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after,
body.dark-only #cal-basic .fc-toolbar .fc-right .fc-next-button .fc-icon-right-single-arrow:after {
  color: #98a6ad;
}
body.dark-only #cal-basic .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after,
body.dark-only #cal-basic .fc-toolbar .fc-right .fc-prev-button .fc-icon-left-single-arrow:after {
  color: #98a6ad;
}
body.dark-only #cal-basic .fc-toolbar .fc-left .fc-button-group .fc-month-button {
  color: #fff !important;
}
body.dark-only .fc-button-group .fc-basicWeek-button,
body.dark-only .fc-button-group .fc-basicDay-button,
body.dark-only .fc-button-group .fc-agendaWeek-button,
body.dark-only .fc-button-group .fc-agendaDay-button {
  color: #000 !important;
}
body.dark-only .fc-button-group .fc-month-button {
  color: #fff !important;
}
body.dark-only .btn-light:not([disabled]):not(.disabled).active.active {
  background-color: #191f31 !important;
}
body.dark-only .btn-light.disabled, body.dark-only .btn-light:disabled {
  color: rgba(54, 54, 54, 0.5) !important;
}
body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-month-button,
body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .btn-light {
  color: #000 !important;
}
body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button {
  color: #fff !important;
}
body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button:hover {
  color: #000 !important;
}
body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .btn-light,
body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .btn-light {
  color: #000 !important;
}
body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button,
body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button,
body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button {
  color: #fff !important;
}
body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover {
  color: #000 !important;
}
body.dark-only .categories ul li a {
  color: #98a6ad;
}
body.dark-only .categories ul li:hover {
  background-color: #191f31;
}
body.dark-only .socialprofile .likes-profile h5 span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .socialprofile span {
  color: #98a6ad;
}
body.dark-only .social-status .d-flex .flex-grow-1 span + span {
  color: #98a6ad;
}
body.dark-only .social-status .d-flex .flex-grow-1 p,
body.dark-only .social-status .d-flex .flex-grow-1 .light-span {
  color: #98a6ad;
}
body.dark-only .filter-cards-view p,
body.dark-only .timeline-content p {
  color: #98a6ad;
}
body.dark-only .filter-cards-view .comment-number i,
body.dark-only .timeline-content .comment-number i {
  color: #98a6ad;
}
body.dark-only .filter-cards-view .comments-box .input-group .form-control,
body.dark-only .timeline-content .comments-box .input-group .form-control {
  border: none !important;
}
body.dark-only .filter-cards-view .comments-box .input-group .input-group-append,
body.dark-only .timeline-content .comments-box .input-group .input-group-append {
  background-color: #191f31;
}
body.dark-only .social-chat .flex-grow-1 {
  border: 1px solid #374558;
}
body.dark-only .social-chat .flex-grow-1:after {
  border-right: 7px solid #1f2639;
}
body.dark-only .social-chat span span {
  color: #98a6ad;
}
body.dark-only .details-about .your-details p {
  color: #98a6ad;
}
body.dark-only .activity-log .my-activity p {
  color: #98a6ad;
}
body.dark-only .bookmark ul li a {
  color: rgba(255, 255, 255, 0.6);
}
@media only screen and (max-width: 991.98px) {
  body.dark-only .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    border-right: 1px solid #374558;
  }
}
@media only screen and (max-width: 767.98px) {
  body.dark-only .page-wrapper.material-icon .page-header {
    background-color: #1f2639;
  }
}
body.dark-only .apexcharts-tooltip.light {
  border-color: #374558;
  background-color: #191f31;
}
body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
  border-color: #374558;
}
body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-label,
body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-value {
  color: #fff;
}
body.dark-only .dark-alert .alert p {
  color: #191f31;
}
body.dark-only .dark-txt p strong {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .live-dark .btn-close {
  filter: initial;
}
body.dark-only .alert-light {
  color: #191f31 !important;
}
body.dark-only .dark-input-type .input-group .input-group-text {
  background: transparent;
  border: unset;
}
body.dark-only .heading-dropdown .dropdown-menu > li:first-child > a {
  background-color: #f9f9fa;
}
body.dark-only .heading-dropdown .dropdown-menu a.dropdown-item {
  color: #374558;
}
body.dark-only .dark-accordion .accordion-item {
  border: 1px solid #374558;
  background-color: #374558;
}
body.dark-only .dark-accordion .accordion-item .accordion-collapse p {
  color: #191f31;
}
body.dark-only .dark-accordion .accordion-item .accordion-collapse .accordion-body {
  background-color: #1f2639;
}
body.dark-only .dark-accordion .accordion-item .accordion-collapse .accordion-body h6 {
  border: 0;
}
body.dark-only .dark-accordion .accordion-item:last-of-type .accordion-button.collapsed {
  background-color: #1f2639;
}
body.dark-only .dark-accordion .collapse-wrapper {
  color: #191f31;
}
body.dark-only .dark-accordion.card {
  border-radius: unset;
}
body.dark-only .accordions-content li {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .dark-list .list-group .list-group-item + .list-group-item {
  border-top-width: 1px;
}
body.dark-only .dark-list .list-group .list-group-item .form-check-input {
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: transparent;
}
body.dark-only .dark-list .list-light-dark {
  background-color: #191f31;
}
body.dark-only .card-wrapper .sub-title {
  border-bottom: unset !important;
}
body.dark-only .dark-blockquote .blockquote p {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .dark-sign-up h3 {
  color: #1f2639;
}
body.dark-only .dark-sign-up p {
  color: #1f2639;
}
body.dark-only .dark-sign-up .modal-body form .form-label {
  color: #1f2533;
}
body.dark-only .dark-sign-up .modal-body form .form-check .form-check-label {
  color: #1f2533;
}
body.dark-only .modal-dialog .modal-content .dark-modal .large-modal-header svg {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-dialog .modal-content .dark-modal .large-modal-header h6 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-dialog .modal-content .dark-modal .large-modal-body svg {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-dialog .modal-content .dark-modal .large-modal-body p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .modal-toggle-wrapper .dark-toggle-btn {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .social-profile {
  background-attachment: fixed;
}
body.dark-only .social-profile .social-details h5 a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .dark-helper {
  background-color: rgba(25, 31, 49, 0.4);
}
body.dark-only .dark-helper h6,
body.dark-only .dark-helper span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .dark-helper .text-muted {
  color: #1f2639 !important;
}
body.dark-only .font-dark {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .grid-showcase span {
  border: 1px solid #374558;
}
body.dark-only .progress {
  background-color: #191f31 !important;
}
body.dark-only .treejs .treejs-node__disabled {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .treejs .treejs-switcher:before {
  border-top: 4px solid rgba(255, 255, 255, 0.6);
}
body.dark-only .irs-min,
body.dark-only .irs-max {
  color: #98a6ad;
  background-color: #323846;
}
body.dark-only .ribbon-wrapper.alert-light-light,
body.dark-only .ribbon-wrapper-bottom.alert-light-light,
body.dark-only .ribbon-vertical-left-wrapper.alert-light-light,
body.dark-only .ribbon-wrapper-right.alert-light-light,
body.dark-only .ribbon-vertical-right-wrapper.alert-light-light {
  background-color: #191f31;
}
body.dark-only .quick-file li .quick-box {
  border: 1px solid #374558;
}
body.dark-only .files-content li .files-list .file-left {
  border: 1px solid #374558;
}
body.dark-only .pagination .page-item .page-link.rounded-circle {
  color: rgba(255, 255, 255, 0.6) !important;
}
body.dark-only .dark-timeline .date-content {
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: #191f31 !important;
}
body.dark-only .student-leader-wrapper .student-leader-content {
  border-bottom: 1px solid #374558;
}
body.dark-only .attendance-card .left-overview-content .svg-box {
  box-shadow: unset;
  border: 1px solid #374558;
}
body.dark-only .task-table .main-task-wrapper .square-white {
  box-shadow: unset;
  border: 1px solid transparent;
}
body.dark-only .income-wrapper ul li + li {
  border-left: 1px dashed #374558;
}
@media (max-width: 1735px) {
  body.dark-only .income-wrapper ul li + li {
    border-left: unset;
  }
}
body.dark-only .icons-box-2 .open-options .dropdown-menu {
  background-color: #191f31;
}
body.dark-only .performance-wrapper .performance-right p {
  color: var(--body-font-color);
}
body.dark-only .main-product-wrapper .product-body .product-search.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  color: #98a6ad;
}
body.dark-only .main-product-wrapper .product-body .product-search.input-group .input-group-text {
  border: 1px solid #374558;
}
body.dark-only .main-product-wrapper .product-body .product-search.input-group .form-control {
  border: 1px solid #374558;
  border-left: unset;
  background-color: #1f2639;
}
[dir=rtl] body.dark-only .main-product-wrapper .product-body .product-search.input-group .form-control {
  border: 1px solid #374558;
  border-right: unset;
}
body.dark-only .our-product-wrapper {
  box-shadow: 0px 3px 17px rgba(113, 113, 137, 0.18);
}
body.dark-only .grid-showcase span {
  background-color: #191f31;
}
body.dark-only .custom-input #validationCustom03.form-control,
body.dark-only .custom-input #validationCustom03 .form-select,
body.dark-only .custom-input #validationCustom05.form-control,
body.dark-only .custom-input #validationCustom05 .form-select,
body.dark-only .custom-input #validationCustomUsername.form-control,
body.dark-only .custom-input #validationCustomUsername .form-select {
  border-color: #191f31;
}
body.dark-only .form-control[type=file]:not(:disabled):not([readonly]) {
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31;
  border-color: #191f31;
}
body.dark-only .custom-input .form-control,
body.dark-only .custom-input .form-select {
  background-color: #191f31;
  border-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .vertical-main-wizard .header-vertical-wizard .nav-link:active .vertical-wizard .vertical-wizard-content h6, body.dark-only .vertical-main-wizard .header-vertical-wizard .nav-link.active .vertical-wizard .vertical-wizard-content h6 {
  color: #98a6ad;
}
body.dark-only .selected-box {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .selected-box .options-container {
  background-color: #191f31;
}
body.dark-only .tagify__dropdown__wrapper {
  background-color: #191f31;
}
body.dark-only .tagify.customLook .tagify__tag-text {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only caption {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only td.highlight {
  background-color: #191f31 !important;
}
body.dark-only .card-wrapper .form-control,
body.dark-only .dark-field .form-control {
  background-color: #191f31 !important;
  border-color: #191f31 !important;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .form-control-sm {
  background-color: #191f31;
  border-color: #191f31;
}
body.dark-only .dark-inputs .form-control.btn-square {
  background-color: #191f31;
  border-color: #191f31;
}
body.dark-only .dark-inputs .form-control.btn-pill {
  background-color: #191f31;
  border-color: #191f31;
}
body.dark-only .dark-inputs .form-control.input-air-primary {
  background-color: #191f31;
  border-color: #191f31;
}
body.dark-only .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard .stroke-icon-wizard {
  color: #191f31;
}
body.dark-only .touchspin-wrapper input[type=number].input-touchspin,
body.dark-only .pre-post-touchspin input[type=number].input-touchspin {
  background-color: #191f31;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .variation-box .selection-box input[type=checkbox]:checked ~ div {
  color: #1f2533;
}
body.dark-only .flatpickr-calendar {
  box-shadow: unset;
}
body.dark-only .flatpickr-calendar.animate {
  background-color: #191f31;
}
body.dark-only .flatpickr-time {
  border: 1px solid #323846;
}
body.dark-only .flatpickr-time input {
  background: #191f31;
}
body.dark-only .main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:hover {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .tagify__dropdown__item--active {
  background-color: #191f31;
}
body.dark-only .tagify__input {
  color: #98a6ad;
}
body.dark-only .tagify__input::before {
  color: #98a6ad;
}
body.dark-only .tagify {
  border-color: #374558;
  --tags-focus-border-color: #374558;
}
body.dark-only .international-num input {
  border-color: #374558;
  background-color: #1f2639;
}
body.dark-only .international-num .results ul {
  border: 1px solid #374558;
}
body.dark-only .iti__country-list {
  background-color: #1f2639;
}
body.dark-only .select-box .selection-option:hover {
  background-color: #191f31;
}
body.dark-only .select-box .options-container.active ~ .search-box input {
  background-color: rgba(255, 255, 255, 0.6);
}
body.dark-only .table-hover > tbody > tr:hover {
  --bs-table-accent-bg: $dark-card-background;
}
body.dark-only .filepond--drop-label {
  background-color: #191f31;
}
body.dark-only .animate-widget .p-25 {
  background-color: #191f31;
}
body.dark-only .main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert {
  background-color: #191f31;
  border-color: #323846;
}
body.dark-only .main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert div {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .main-qr-code .modal-toggle-wrapper form .form-control {
  background-color: #191f31;
  border-color: #323846;
}
body.dark-only .authentication-options .radio-wrapper li .form-check-label span > span {
  color: #98a6ad;
}
body.dark-only .typeahead-wrapper .theme-form .form-control {
  background-color: #191f31 !important;
  border-color: #191f31 !important;
}
body.dark-only .compose-modal form .form-control {
  background-color: #191f31;
  border-color: #374558;
  color: #98a6ad;
}
body.dark-only .toolbar-box .ql-snow .ql-stroke {
  stroke: #98a6ad;
}
body.dark-only .toolbar-box .ql-snow .ql-tooltip {
  background-color: #191f31;
  box-shadow: unset;
  border: 0;
}
body.dark-only .toolbar-box .ql-snow .ql-tooltip.ql-editing input[type=text] {
  background-color: #1f2639;
  border: 0;
  color: #98a6ad;
}
body.dark-only .toolbar-box .ql-snow a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .toolbar-box .ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-body .mail-header-wrapper .mail-body .mail-search .form-control {
  background-color: #191f31 !important;
  border: 0;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-body .mail-header-wrapper .mail-body .mail-search i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-body .mail-header-wrapper .mail-body .dropdown-menu .dropdown-item {
  background: #191f31;
  opacity: 1;
  border: 0;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .email-body .mail-header-wrapper .mail-body .dropdown-menu .dropdown-item:hover {
  background-color: #1f2639;
}
body.dark-only .inbox-options .light-square.btn-group .dropdown-menu {
  background: #2b2b2b;
}
body.dark-only .inbox-options .light-square.btn-group .dropdown-menu .dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.6);
  color: #98a6ad;
}
body.dark-only .inbox-security {
  background-color: #191f31;
}
body.dark-only .mail-pagination .pagination-number,
body.dark-only .mail-pagination .pagination-button {
  background-color: #323846;
}
body.dark-only .flatpicker-calender.product-date .form-control.flatpickr-input {
  border: unset;
  background-color: #191f31;
}
body.dark-only .price-wrapper .radio-wrapper li .form-check-input {
  border: 1px solid #374558;
}
body.dark-only .price-wrapper .radio-wrapper li .form-check-input ~ .form-check-label span {
  color: #98a6ad;
}
body.dark-only .product-buttons .btn:hover, body.dark-only .product-buttons .btn:active {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-only .product-buttons .btn svg {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-only .list-product .datatable-pagination .datatable-active .datatable-pagination-list-item-link {
  background-color: var(--theme-default);
  color: var(--white);
}
body.dark-only .list-product .datatable-pagination .datatable-pagination-list-item-link {
  background-color: #191f31;
  color: #98a6ad;
}
body.dark-only .list-product .datatable-input:focus {
  outline: unset;
}
body.dark-only .list-product .datatable-selector {
  color: rgba(255, 255, 255, 0.6);
  background-color: #191f31;
}
body.dark-only .list-product-header .collapse .list-product-body {
  border: unset !important;
}
body.dark-sidebar .toggle-sidebar svg {
  stroke: var(--theme-default);
}
body.dark-sidebar .page-wrapper .sidebar-main-title p {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .logo-wrapper .for-light {
  display: block;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .logo-wrapper .for-dark {
  display: none;
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  background-color: #1f2639;
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
  background: #1f2639;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
  background-color: #1f2639;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  background: #1f2639;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active, body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  color: var(--theme-default);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu {
  background: #1f2639;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  background: #1f2639;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  background-color: #1f2639;
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  background: #1f2639;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  border-bottom: 1px solid #374558;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  stroke: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  background-color: #1f2639;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  border-bottom: 1px solid #374558;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  background-color: rgba(48, 126, 243, 0.2);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
  color: var(--theme-default);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
  color: var(--theme-default);
  stroke: var(--theme-default);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.6);
}
body .for-dark {
  display: none;
}
body[class*=dark-] .for-dark {
  display: block;
}
body[class*=dark-] .for-light {
  display: none;
}

/**=====================
	4.1. Dark CSS ends
==========================**/
/**=====================
  4.2 Theme-customizer CSS Start
==========================**/
.customizer-links {
  position: fixed;
  right: 0px;
  top: 50%;
  z-index: 3;
  transform: translate(0, -50%);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
}
.customizer-links > .nav {
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}
.customizer-links.open {
  right: 330px;
  border-radius: 8px 0 0 8px;
  transition: all 0.3s ease;
}
.customizer-links .nav-link {
  padding: 0;
  position: relative;
}
.customizer-links .nav-link:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(48, 126, 243, 0.1);
  border-radius: 5px;
  transform: scale(0);
  transition: all 0.3s ease;
}
.customizer-links .nav-link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 12px;
  border-width: 5px 0 5px 5px;
  border-left-color: #a9c9fa;
  opacity: 0;
  transform: scale(0);
  color: #fff;
  left: -10px;
  z-index: 2;
  transition: all 0.3s ease;
}
.customizer-links .nav-link:hover i {
  color: var(--theme-default);
}
.customizer-links .nav-link:hover:before, .customizer-links .nav-link:hover:after {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease;
}
.customizer-links .nav-link:hover span {
  opacity: 1;
  right: 48px;
  transform: scale(1);
  transition: all 0.3s ease;
}
.customizer-links .nav-link + .nav-link {
  margin-bottom: 5px;
}
.customizer-links .nav-link div img {
  height: 22px;
  margin: 6px 6px;
  width: 22px;
}
.customizer-links .nav-link span {
  position: absolute;
  right: -60px;
  width: max-content;
  text-align: right;
  padding: 7px 10px;
  display: block;
  top: 0;
  border-radius: 5px;
  transform: scale(0);
  transition: all 0.3s ease;
  opacity: 0;
  color: var(--theme-default);
  background-color: #c1d8fb;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
}
.customizer-links .nav-link i {
  font-size: 17px;
  padding: 8px 10px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  color: gray;
  border-radius: 5px;
}

.customizer-contain {
  direction: ltr;
  position: fixed;
  height: 100vh;
  top: 79px;
  width: 333px;
  right: -335px;
  background-color: #fff;
  z-index: 8;
  transition: all 0.3s ease;
}
.customizer-contain.open {
  right: 0px;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
}
.customizer-contain li {
  position: relative;
}
.customizer-contain li.active {
  box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
}
.customizer-contain h6 {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 12px;
  padding-top: 12px;
  margin-bottom: 5px;
  color: #191f31 !important;
}
.customizer-contain .customizer-header {
  padding: 15px 25px;
  border-bottom: 1px solid #F3F3F3;
}
.customizer-contain .customizer-header .icon-close {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  font-weight: 900;
  right: 24px;
  color: var(--theme-default);
}
.customizer-contain .customizer-header h5 {
  text-transform: uppercase;
  color: #191f31 !important;
}
.customizer-contain .customizer-body {
  padding: 15px 25px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.customizer-contain .customizer-body .fade:not(.show) {
  display: none;
}
.customizer-contain .customizer-body .unlimited-color-layout {
  display: flex;
  align-items: center;
}
.customizer-contain .customizer-body .unlimited-color-layout .btn {
  font-size: 15px;
  padding: 4px 20px;
}
.customizer-contain .customizer-body .unlimited-color-layout input {
  border: 1px solid #cccccc;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5px;
}
.customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
  height: 70px;
  width: 80px;
  border: 1px solid #f6f7fb;
  border-radius: 5px;
}
.customizer-contain .customizer-body .tab-pane > h6:first-child {
  padding-top: 0;
  margin-top: 0;
}
.customizer-contain .customizer-body li {
  cursor: pointer;
  opacity: 0.9;
}
.customizer-contain .customizer-body li.active {
  opacity: 1;
}
.customizer-contain .customizer-body .color-layout {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 3px;
  border: 1px solid #b8b8b8;
  padding: 3px;
}
.customizer-contain .customizer-body .color-layout > div {
  background-color: #307EF3;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-2] div {
  background-color: #33BFBF;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-3] div {
  background-color: #006666;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-4] div {
  background-color: #7A70BA;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-5] div {
  background-color: #6271EB;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-6] div {
  background-color: #3949ab;
}
.customizer-contain .customizer-body .dark > li {
  background-color: #2f3c4e;
}
.customizer-contain .customizer-body .main-layout > li {
  height: 65px;
  width: 85px;
  border: 1px solid #F3F3F3;
  border-radius: 10px;
  display: inline-block;
}
.customizer-contain .customizer-body .main-layout > li + li {
  margin-left: 7px;
}
.customizer-contain .customizer-body .main-layout .box-layout .body .badge {
  left: -14px;
}
.customizer-contain .customizer-body .sidebar-type > li,
.customizer-contain .customizer-body .sidebar-setting > li {
  height: 70px;
  width: 85px;
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  display: inline-block;
}
.customizer-contain .customizer-body .sidebar-type > li + li,
.customizer-contain .customizer-body .sidebar-setting > li + li {
  margin-left: 7px;
}
.customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.bg-dark, .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.sidebar {
  width: 100%;
  height: 10px;
  display: block;
}
.customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.bg-light, .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.body {
  height: calc(100% - 10px);
  width: 100%;
}
.customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar].body-compact .body ul li.body {
  width: calc(100% - 25px);
}
.customizer-contain .customizer-body .layout-types {
  margin-bottom: -30px;
}
.customizer-contain .customizer-body .layout-types > li {
  display: block;
  width: unset;
  height: unset;
  margin-bottom: 30px;
}
.customizer-contain .customizer-body .layout-types > li .layout-img h6 {
  padding-top: 0;
  text-transform: inherit;
  text-align: center;
}
.customizer-contain .customizer-body .layout-types .box-layout {
  background: unset;
}

.layout-grid:not(.customizer-color) li {
  padding: 5px;
}
.layout-grid:not(.customizer-color) li > .header {
  height: 9px;
  border-radius: 3px;
  position: relative;
}
.layout-grid:not(.customizer-color) li > .header ul {
  position: absolute;
  top: -8px;
  left: 5px;
}
.layout-grid:not(.customizer-color) li > .header ul > li {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  top: calc(50% - 3px);
  background-color: var(--theme-default);
  margin-right: 2px;
  padding: 0;
}
.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
  background-color: var(--theme-secondary);
}
.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
  background-color: #53A653;
}
.layout-grid:not(.customizer-color) li > .body {
  background-color: #F3F3F3;
  height: calc(100% - 16px);
  border-radius: 3px;
  position: relative;
  margin-top: 5px;
  text-align: center;
  line-height: 3.1;
}
.layout-grid:not(.customizer-color) li > .body ul {
  height: 100%;
  background-color: #fff;
}
.layout-grid:not(.customizer-color) li > .body ul .sidebar {
  width: 18px;
  height: 100%;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  padding: 0;
}
.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
  width: 12px;
}
.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
  width: calc(100% - 19px);
}
.layout-grid:not(.customizer-color) li > .body ul .body {
  width: calc(100% - 25px);
  padding: 0;
  margin: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.layout-grid:not(.customizer-color) li > .body ul .body .badge {
  position: absolute;
}

/**=====================
    4.2 Theme-customizer CSS Ends
==========================**/
/**=====================
  4.3 Update CSS Start
==========================**/
.jsgrid-filter-row select,
.jsgrid-filter-row input {
  padding: 6px 10px;
  border: 1px solid #f2f4ff;
  background: #fff;
}

.popover {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 0 20px rgba(48, 126, 243, 0.1);
  box-shadow: 0 0 20px rgba(48, 126, 243, 0.1);
}
.popover .popover-header {
  background-color: #d2e4ff;
  color: #307EF3;
  border-bottom: none;
}
.popover .popover-body {
  color: rgba(43, 43, 43, 0.7);
}

.dropdown-menu.show {
  background-color: #fff;
  z-index: 2;
}

.toast-container {
  position: unset;
}

.animate-widget .p-25 {
  background-color: #fff;
}

.tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #d2e4ff;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #d2e4ff;
  border-top-color: transparent;
}
.tooltip.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #d2e4ff;
  border-top-color: transparent;
}
.tooltip.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #d2e4ff;
  border-top-color: transparent;
}
.tooltip .tooltip-inner {
  background-color: #d2e4ff;
  color: #307EF3;
}
.tooltip .tooltip-arrow:before {
  border-top-color: #d2e4ff;
}

.dropdown-basic .dropdown .dropdown-content a {
  padding: 6px 16px;
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  border-top: 1px solid #EAEAEA;
  background: #fff;
}
.dropdown-basic .dropdown .dropdown-content a:hover {
  background-color: #fff;
}
.dropdown-basic .dropdown .dropdown-content .dropdown-header {
  padding: 8px 16px;
  font-weight: 400;
  color: #2c323f;
  font-size: 13px;
}
.dropdown-basic .dropup .dropup-content {
  top: auto;
}

.default-according .card .card-header {
  padding: 0;
  border: none;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.default-according .card .card-header.bg-primary .btn, .default-according .card .card-header.bg-secondary .btn {
  border-color: transparent;
}
.default-according .card .card-header i {
  position: initial;
  font-size: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}
[dir=rtl] .default-according .card .card-header i {
  margin-right: unset;
  margin-left: 5px;
}
.default-according .card .card-header h5 {
  margin-top: 0;
}
.default-according .card .card-header .btn-link {
  padding: 12px 18px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.7px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
[dir=rtl] .default-according .card .card-header .btn-link {
  text-align: right;
}
@media (max-width: 575px) {
  .default-according .card .card-header .btn-link {
    padding: 12px 15px;
  }
}
.default-according .card .card-body {
  padding: 15px 20px;
  line-height: 22px;
  font-size: 14px;
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #2c323f;
}

.icon-dropdown .dropdown-toggle {
  border: none;
}

.collapse-horizontal .card-body {
  width: 240px;
}

.nav-tabs {
  border-bottom-color: #EAEAEA;
}
.nav-tabs .nav-bottom .nav-item .nav-link.active {
  border-color: #EAEAEA;
}

.nav-tabs .nav-item.show .nav-link {
  font-weight: 500;
}
.nav-tabs .nav-link {
  color: #2c323f;
}
.nav-tabs .nav-link.active {
  font-weight: 500;
}
.nav-tabs.nav-bottom .nav-item .nav-link.active {
  border-color: #EAEAEA #EAEAEA #fff;
}

.dropdown-menu {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
  z-index: 1;
}
.dropdown-menu .dropdown-item {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #EAEAEA;
  background: #fff;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #fff;
}
.dropdown-menu .dropdown-item:first-child {
  border-top: none;
}
.dropdown-menu .dropdown-divider {
  margin: 0;
  border-top: 1px solid #cccccc;
}

.blockquote > :last-child {
  margin-top: 0;
}

.modal-content {
  background-color: #fff;
}

.border-tab .nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #307EF3;
}
.border-tab .nav-tabs .nav-item .nav-link.show {
  border-bottom: 2px solid #307EF3;
}
.border-tab .nav-tabs .nav-item .nav-link:focus {
  border-bottom: 2px solid #307EF3;
}

.timeliny .timeliny-dot::before {
  font-size: 18px;
}

.component .input-group .btn {
  line-height: 32px;
  text-transform: capitalize;
}

.login-card .login-main .theme-form label {
  font-size: 14px;
}
.login-card .login-main .theme-form .show-hide {
  top: 50%;
}

.faq-accordion .card .btn-link svg {
  margin-left: 20px;
}
@media (max-width: 575.98px) {
  .faq-accordion .card .btn-link svg {
    margin-left: 10px;
  }
}

.job-filter .faq-form .form-control {
  font-size: 14px;
}
.job-filter .faq-form .search-icon {
  width: 16px;
  height: 16px;
}

.note-btn-group .dropdown-toggle.show ~ .dropdown-menu {
  display: block;
  top: 30px;
}

.carousel .carousel-indicators {
  margin-bottom: 3px;
}

.mouse-wheel .owl-stage-outer .owl-stage {
  width: 5600px !important;
}
.mouse-wheel .owl-stage-outer .owl-stage .owl-item {
  width: 225.333px !important;
}

.carousel-item .carousel-caption {
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 26px 10px;
  background-color: rgba(31, 38, 57, 0.51);
}
.carousel-item .carousel-opacity {
  background-color: rgba(243, 243, 243, 0.51);
}

.carousel .carousel-control-prev-icon {
  width: calc(23px + 9 * (100vw - 320px) / 1600);
  height: calc(23px + 9 * (100vw - 320px) / 1600);
}
.carousel .carousel-control-next-icon {
  width: calc(23px + 9 * (100vw - 320px) / 1600);
  height: calc(23px + 9 * (100vw - 320px) / 1600);
}

[dir=rtl] .rating-header h4 {
  direction: ltr;
}

.animate-img .animate-widget img {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  object-fit: cover;
}

.modal-popup.modal {
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
}
.modal-popup.modal .modal-dialog .theme-close {
  width: calc(25px + 15 * (100vw - 320px) / 1600);
  height: calc(25px + 15 * (100vw - 320px) / 1600);
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
}

@media (max-width: 442px) {
  .animated-modal .form-select {
    width: 100% !important;
  }
}

.task-container .badge {
  line-height: 13px;
}

.starter-main .alert-primary.inverse {
  padding: 13px 20px 13px 65px;
  border-color: #307EF3;
  color: #2c323f;
}
[dir=rtl] .starter-main .alert-primary.inverse {
  padding: 13px 65px 13px 20px;
}
.starter-main .alert-primary.inverse:before {
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #307EF3;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
[dir=rtl] .starter-main .alert-primary.inverse:before {
  border-right: 7px solid #307EF3;
  border-left: unset;
}
.starter-main .alert-primary.inverse i {
  padding: 17px 20px;
  display: flex;
  align-items: center;
  background-color: #307EF3;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
[dir=rtl] .starter-main .alert-primary.inverse i {
  right: 0;
  left: unset;
}

/**=====================
    4.3 Update CSS Ends
==========================**/
/**=====================
    5.1 Footer CSS Start
==========================**/
.footer {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  padding: 15px;
  bottom: 0;
  left: 0;
  margin-left: 246px;
  transition: 0.5s;
}
.footer.footer-dark {
  background-color: #1f2639;
}
.footer.footer-dark p {
  color: #fff;
}

.footer-fix {
  width: calc(100% - 246px);
  position: fixed;
}

/**=====================
    5.1 Footer CSS Ends
==========================**/
/**=====================
  5.2 Grid CSS Start
==========================**/
.grid-showcase {
  margin-bottom: -10px;
}
.grid-showcase span {
  display: block;
  border: 1px solid #F3F3F3;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(48, 126, 243, 0.05) !important;
  color: #307EF3 !important;
  font-weight: 500;
}

.grid-align {
  margin-bottom: -30px;
}
.grid-align .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid rgba(48, 126, 243, 0.02);
  margin-bottom: 30px;
  background-color: rgba(48, 126, 243, 0.02) !important;
}

/**=====================
    5.2 Grid CSS Ends
==========================**/
/**=====================
  5.3 Header CSS Start
==========================**/
.left-header .d-flex {
  align-items: center;
}
.left-header h6 {
  line-height: 1.6;
}
@media (max-width: 1199px) {
  .left-header h6 {
    width: 160px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (max-width: 991px) {
  .left-header h6 {
    width: 120px;
  }
}
@media (max-width: 767px) {
  .left-header h6 {
    width: 66px;
  }
}
@media (max-width: 755px) {
  .left-header {
    display: none;
  }
}
.left-header img {
  margin-top: -12px;
  height: 26px;
}
.left-header i {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: -4px;
}

.onhover-show-div {
  top: 55px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  transition: all linear 0.3s;
}
.onhover-show-div li {
  padding: 5px 30px;
}
.onhover-show-div li:before {
  display: none;
}
.onhover-show-div li a svg {
  margin-top: 0 !important;
}
.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #363636 !important;
}

.left-header {
  display: flex;
}
.left-header .left-menu-header ul.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}
.left-header .left-menu-header ul.header-left li {
  padding: 0 15px 0px 0px;
  position: relative;
}
[dir=rtl] .left-header .left-menu-header ul.header-left li {
  padding: 0px 0px 0px 15px;
}
@media (max-width: 1550px) and (min-width: 1200px) {
  .left-header .left-menu-header ul.header-left li {
    padding: 0 8px 0px 0px;
  }
  [dir=rtl] .left-header .left-menu-header ul.header-left li {
    padding: 0 0px 0px 8px;
  }
}
.left-header .left-menu-header ul.header-left li a {
  color: #363636;
  font-weight: 500;
}
.left-header .left-menu-header ul.header-left li a:hover {
  color: var(--theme-default) !important;
  letter-spacing: 1.5px;
  transition: all 0.3s ease;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div {
  min-width: 190px;
  padding: 20px 0;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li {
  padding: 6px 25px;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right {
  position: relative;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right:hover ul {
  display: block;
  transition: all 0.5s ease-in;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right ul {
  padding: 20px 0;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right ul li {
  padding: 6px 25px;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right ul li.flyout-right {
  position: relative;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right ul li.flyout-right:hover ul {
  display: block;
  transition: all 0.8s ease-in;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right ul li.flyout-right ul {
  display: none;
}
.left-header .left-menu-header ul.header-left li ul.onhover-show-div li.flyout-right ul {
  position: absolute;
  top: 0;
  left: calc(100% + 3px);
  min-width: 200px;
  display: none;
  background-color: #fff;
  border-radius: 5px;
}
.left-header .left-menu-header ul.header-left li:hover span.f-w-600::before {
  width: 120px;
  transition: all 0.5s ease-in;
}
.left-header .left-menu-header ul.header-left li span.f-w-500 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #307EF3;
  padding: 6px 18px;
  background-color: rgba(48, 126, 243, 0.1);
  border-radius: 5px;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 1550px) and (min-width: 1200px) {
  .left-header .left-menu-header ul.header-left li span.f-w-500 {
    padding: 6px 10px;
  }
}
.left-header .left-menu-header ul.header-left li span {
  font-size: 14px;
  line-height: 1.8;
  color: #363636;
}
.left-header .left-menu-header ul.header-left li span svg {
  width: 22px;
  height: 22px;
  fill: #307EF3;
  margin-right: 10px;
}
[dir=rtl] .left-header .left-menu-header ul.header-left li span svg {
  margin-right: unset;
  margin-left: 10px;
}
.left-header .mega-menu-container {
  min-width: 1100px;
  position: absolute;
  width: calc(100vw - 800px);
  height: 0;
  border-radius: 0px;
  background-color: #fff;
  top: 59px;
  left: -30px;
  border-top: 1px solid #EAEAEA;
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 20px;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  opacity: 0;
  visibility: hidden;
  display: block !important;
  transition: all 0.2s ease-in-out;
}
.left-header .mega-menu-container.show {
  opacity: 1;
  visibility: visible;
  height: 300px;
}
.left-header .mega-menu-container .link-section > div h6 {
  margin-top: 20px;
}
.left-header .mega-menu-container .link-section li {
  width: 100%;
  padding: 7px 0 7px 15px;
}
.left-header .mega-menu-container .link-section li a {
  transition: all 0.3s ease;
}
.left-header .mega-menu-container .link-section li:hover a {
  color: var(--theme-default) !important;
  letter-spacing: 1.5px;
  transition: all 0.3s ease;
}
.left-header .mega-menu-container .mega-box + .mega-box {
  padding-left: 30px;
  border-left: 1px solid #EAEAEA;
}
.left-header .mega-menu-container .mega-box .icon li {
  position: relative;
}
.left-header .mega-menu-container .mega-box .icon li:hover:before {
  color: var(--theme-default);
  transition: all 0.3s ease;
}
.left-header .mega-menu-container .mega-box .icon li:before {
  content: "\f101";
  font-family: fontawesome;
  position: absolute;
  top: 7px;
  left: 0px;
}
.left-header .mega-menu-container .mega-box .svg-icon ~ div h6 {
  margin-top: 10px;
  margin-bottom: 5px;
}
.left-header .mega-menu-container .mega-box .svg-icon li {
  padding-left: 0px;
}
.left-header .mega-menu-container .mega-box .svg-icon li:hover:before {
  color: var(--theme-default);
  transition: all 0.3s ease;
}
.left-header .mega-menu-container .mega-box .svg-icon li a svg {
  width: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
.left-header .mega-menu-container .submenu-title {
  margin-top: 20px;
  margin-left: 20px;
}
.left-header .mega-menu-container .according-menu {
  display: none;
}
.left-header .input-group {
  padding: 5px 15px;
  border-radius: 30px;
  overflow: hidden;
  background-color: white;
}
.left-header .input-group .input-group-prepend .input-group-text {
  background-color: transparent;
  border: none;
  padding: 0;
  height: 40px;
  width: 40px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-header .input-group .input-group-prepend .input-group-text i {
  color: #307EF3;
}
.left-header .input-group .form-control {
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
}
.left-header .input-group .form-control:focus {
  box-shadow: none;
}
.left-header .input-group .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.offcanvas .page-wrapper .page-body-wrapper .page-body,
.offcanvas-bookmark .page-wrapper .page-body-wrapper .page-body {
  position: relative;
  filter: blur(5px) grayscale(100%);
}

.offcanvas {
  position: unset;
  visibility: visible;
  background: unset;
  z-index: 2;
}

.toggle-sidebar {
  position: absolute;
  right: 30px;
  top: 28px;
  cursor: pointer;
}
.toggle-sidebar svg {
  width: 24px;
  height: 24px;
}
.toggle-sidebar:before {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: rgba(48, 126, 243, 0.1);
  border-radius: 100%;
  left: -11px;
  z-index: -2;
  top: -8px;
  transform: scale(0);
  transition: all 0.3s ease;
}
.toggle-sidebar:hover:before {
  height: 40px;
  width: 40px;
  transform: scale(1);
  transition: all 0.3s ease;
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
}
.onhover-show-div {
  top: 55px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  transition: all linear 0.3s;
}
.onhover-show-div li a svg {
  margin-top: 0 !important;
}
.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #363636 !important;
}

/**======Main Header css Ends ======**/
/*=======Mega menu css start=======*/
.mega-menu .default-according .card .btn-link {
  font-weight: 500;
  color: gray;
}
.mega-menu .default-according .card .card-body {
  font-size: 12px;
}
.mega-menu .onhover-show-div {
  width: 1500px;
}
.mega-menu .card-body {
  padding: 20px;
}
.mega-menu div > div a {
  margin-bottom: 0px;
  display: inline-block;
  color: #9B9B9B;
}
.mega-menu .list-unstyled div a {
  padding: 8px 35px 8px 0;
  transition: 0.5s;
}
.mega-menu .list-unstyled div a:hover {
  padding: 8px 35px 8px 10px;
  transition: 0.5s;
}

footer {
  padding: 17px 30px 17px 30px !important;
}
footer p {
  color: #363636;
  font-weight: 500;
}
footer p .footer-icon {
  width: 22px;
  height: 18px;
  vertical-align: middle;
}

/*=======Mega menu css end=======*/
/**=====================
    5.3 Header CSS Ends
==========================**/
/**=====================
    5.4 Navs CSS Start
==========================**/
#accordionoc #collapseicon,
#accordionoc #collapseicon1 {
  padding-top: 0px;
}
#accordionoc li button {
  display: flex;
  align-items: center;
}

.navs-icon.default-according.style-1 li button {
  position: relative;
}
.navs-icon.default-according.style-1 li button:hover {
  color: var(--theme-default) !important;
}
.navs-icon.default-according.style-1 li button[aria-expanded=true]:before {
  right: 2px;
  top: 6px;
}
.navs-icon.default-according.style-1 li button[aria-expanded=false]:before {
  right: 2px;
  top: 6px;
}

.navs-icon {
  padding: 30px;
}
.navs-icon li {
  padding: 9px 0;
}
.navs-icon li a {
  color: #363636;
}
.navs-icon li a:hover {
  color: var(--theme-default);
}
.navs-icon li ul {
  padding-top: 9px;
}

.onhover-dropdown.navs-dropdown:hover .onhover-show-div:before, .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  right: 77px;
  left: unset;
}

.icon-lists {
  font-family: inherit;
}

/**=====================
    5.4 Navs CSS Ends
==========================**/
/**=====================
  5.5 Search CSS Start
==========================**/
.search-page .theme-form input {
  border: 1px solid #307EF3;
  padding-left: 20px;
  border-radius: 10px 0 0 10px;
  flex: 1 1 auto;
}
.search-page .theme-form input::placeholder {
  color: #307EF3;
}
.search-page .theme-form input:focus {
  outline: none;
}
.search-page .theme-form .input-group .btn {
  line-height: 32px;
  font-size: 16px;
}
.search-page .theme-form .input-group .form-control-plaintext {
  background: transparent;
}
.search-page h5 {
  line-height: 1.4;
}
.search-page .nav-link {
  font-weight: 500;
  text-transform: uppercase;
}
.search-page .border-tab.nav-tabs .material-border {
  border-bottom-width: 1px;
}
.search-page .border-tab.nav-tabs .nav-item .nav-link {
  font-size: 15px;
  padding: 10px 18px;
}
.search-page .d-flex .flex-grow-1 {
  overflow: hidden;
}
.search-page .info-block {
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #F3F3F3;
}
.search-page .info-block a {
  color: #656565;
  margin-bottom: 3px;
  display: block;
}
.search-page .info-block h5 {
  color: #307EF3 !important;
}
.search-page .info-block + .info-block {
  margin-top: 20px;
}
.search-page .border-tab.nav-tabs {
  align-items: left;
  justify-content: end;
}
.search-page .border-tab.nav-tabs .nav-item {
  width: auto;
}
.search-page .search-links h5 {
  margin-bottom: 10px;
}
.search-page p {
  text-transform: lowercase;
  margin-bottom: 0;
  color: #a5a2a2;
}
.search-page ul.search-info li {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #586082;
}
.search-page ul.search-info li + li {
  border-left: 1px solid #a8b0c2;
  padding-left: 8px;
  margin-left: 8px;
}
.search-page ul.search-info li i {
  color: #FFAA05;
}
.search-page ul.search-info li i:not(:last-child) {
  margin-right: 4px;
}
.search-page #video-links .embed-responsive + .embed-responsive {
  margin-top: 30px;
}

.search-list {
  margin-bottom: 30px;
  width: auto;
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #307EF3;
  border: none;
}
.search-list .nav-item {
  background-color: rgba(48, 126, 243, 0.1);
}
.search-list .nav-item .nav-link {
  border: none;
  padding: 0.7rem 1.5rem;
  opacity: 0.5;
}
.search-list .nav-item .nav-link.active {
  background-color: rgba(48, 126, 243, 0.1);
  color: var(--theme-default);
  opacity: 1;
}
.search-list .nav-item.show, .search-list .nav-item.active {
  background-color: rgba(48, 126, 243, 0.1);
}

/**=====================
  5.5 Search CSS Ends
==========================**/
/**=====================
    5.6 Select2 CSS Start
==========================**/
.dropzone {
  background: unset;
}
.dropzone.dropzone-secondary {
  border: 2px dashed #EBA31D;
}
.dropzone.dropzone-secondary i {
  color: #EBA31D;
}
@media (max-width: 360px) {
  .dropzone .note {
    min-width: unset !important;
  }
}
.dropzone .dz-preview {
  margin: 28px 18px !important;
  box-shadow: unset;
}
.dropzone .dz-preview .dz-details {
  top: unset;
  bottom: -6px;
  position: relative;
  padding-bottom: 6px;
  background-color: unset;
}
[dir=rtl] .dropzone .dz-preview .dz-details {
  left: unset;
  right: 32px;
}
.dropzone .dz-preview:hover .dz-error-message {
  display: none !important;
}
.dropzone .dz-error-mark .dz-remove {
  position: relative;
}

.dz-preview .dz-remove {
  cursor: pointer;
}

.dropzone-secondary .dz-preview .dz-remove {
  color: #EBA31D;
}

.select2 {
  max-width: 100%;
}

.add-post #cke_text-box {
  border: 1px solid #EAEAEA;
}
.add-post form .m-checkbox-inline label {
  margin-bottom: 0;
}
.add-post form .form-control {
  border: 1px solid #EAEAEA;
}
.add-post form .col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}
.add-post form .col-form-label .select2-container--default {
  margin-top: 10px;
  max-width: 100%;
}
.add-post form .col-form-label .select2-container--default .selection .select2-selection .select2-search__field {
  padding: 2px;
}
.add-post form .col-form-label .select2-container--default .select2-selection--multiple {
  border: 1px solid #EAEAEA;
  max-width: 100%;
}
.add-post form .col-form-label .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  margin-top: 6px !important;
}
.add-post .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: var(--theme-default);
  border: 1px solid var(--theme-default);
  color: var(--white);
}
.add-post .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: var(--white);
  float: right;
  margin-left: 0.5rem;
}
[dir=rtl] .add-post .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  float: left;
  margin-left: unset;
  margin-right: 0.5rem;
}
.add-post .dropzone {
  margin-bottom: 30px;
}

.select2-container--open .select2-dropdown {
  z-index: 7;
}

.select2-container {
  width: 100% !important;
}
.select2-container.select2 .selection {
  display: block !important;
}

.select2-drpdwn .select2-selection {
  border-radius: 0 !important;
  border-color: #F3F3F3 !important;
  height: 40px !important;
  padding: 5px;
}
.select2-drpdwn .form-control {
  border-radius: 5px;
}
.select2-drpdwn .form-control-primary {
  border-color: var(--theme-default);
  color: var(--theme-default);
}
.select2-drpdwn .form-control-secondary {
  border-color: var(--theme-secondary);
  color: var(--theme-secondary);
}
.select2-drpdwn .form-control-success {
  border-color: #53A653;
  color: #53A653;
}
.select2-drpdwn .form-control-info {
  border-color: #01A1B9;
  color: #01A1B9;
}
.select2-drpdwn .form-control-warning {
  border-color: #FFAA05;
  color: #FFAA05;
}
.select2-drpdwn .form-control-danger {
  border-color: #DC3545;
  color: #DC3545;
}
.select2-drpdwn .form-control-inverse {
  border-color: #2c323f;
  color: #2c323f;
}
.select2-drpdwn .form-control-primary-fill {
  background-color: var(--theme-default);
  color: #fff;
}
.select2-drpdwn .form-control-secondary-fill {
  background-color: var(--theme-secondary);
  color: #fff;
}
.select2-drpdwn .form-control-success-fill {
  background-color: #53A653;
  color: #fff;
}
.select2-drpdwn .form-control-info-fill {
  background-color: #01A1B9;
  color: #fff;
}
.select2-drpdwn .form-control-warning-fill {
  background-color: #FFAA05;
  color: #fff;
}
.select2-drpdwn .form-control-danger-fill {
  background-color: #DC3545;
  color: #fff;
}
.select2-drpdwn .form-control-inverse-fill {
  background-color: #2c323f;
  color: #fff;
}

.filepond--drop-label .filepond--label-action {
  color: #DC3545;
  text-decoration: none;
}

.filepond--list .filepond--file {
  background-color: #307EF3;
}

/* Do not show the "remove tag" (x) button when only a single tag remains */
.customLook {
  display: inline-block;
  min-width: 0;
  border: none;
}
.customLook .tagify__tag {
  margin-top: 0;
}
.customLook .tagify__tag:only-of-type .tagify__tag__removeBtn {
  display: none;
}
.customLook .tagify__tag:hover .tagify__tag__removeBtn {
  transform: none;
  opacity: 1;
  margin-left: -1ch;
}
.customLook .tagify__tag__removeBtn {
  opacity: 0;
  transform: translateX(-6px) scale(0.5);
  margin-left: -3ch;
  transition: 0.12s;
}
.customLook + button {
  color: var(--theme-default);
  font: bold 1.4em/1.65 Arial;
  border: 0;
  background: none;
  box-shadow: 0 0 0 2px inset var(--theme-default);
  border-radius: 50%;
  width: 1.65em;
  height: 1.65em;
  cursor: pointer;
  outline: none;
  transition: 0.1s ease-out;
  margin: 0 0 0 5px;
  vertical-align: top;
}
.customLook + button:hover {
  box-shadow: 0 0 0 5px inset var(--theme-default);
}
.customLook .tagify__input {
  display: none;
}

.tagify--empty .tagify__input::before {
  color: var(--chart-text-color);
}

.customSuggestionsList > div {
  max-height: 300px;
  min-height: 50px;
  border: 2px solid var(--chart-dashed-border);
  overflow: auto;
}
.customSuggestionsList .empty {
  color: var(--theme-default);
  font-size: 20px;
  text-align: center;
  padding: 1em;
}

.tagify__dropdown.extra-properties .tagify__dropdown__item > img {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  transform: scale(0.75);
  margin-right: 5px;
  border-radius: 2px;
  transition: 0.12s ease-out;
}
.tagify__dropdown.extra-properties .tagify__dropdown__item:hover > img {
  transform: none;
  margin-right: 12px;
}
.tagify__dropdown.extra-properties .tagify__dropdown__item--active > img {
  transform: none;
  margin-right: 12px;
}

.tagify.countries .tagify__input {
  min-width: 175px;
}
.tagify.countries tag {
  white-space: nowrap;
}
.tagify.countries tag img {
  display: inline-block;
  height: 16px;
  margin-right: 3px;
  border-radius: 2px;
  pointer-events: none;
}

.tagify--select {
  width: 100%;
}

.tagify__dropdown__wrapper {
  background: var(--white);
  border-color: var(--recent-dashed-border);
}

.tagify__dropdown__item--active {
  background-color: #cccccc;
  color: var(--body-font-color);
}

.tagify {
  --tags-focus-border-color: #cccccc;
}

.tagify__input {
  color: #2c323f;
}

.tagify--select::after {
  color: var(--chart-text-color);
}
[dir=rtl] .tagify--select::after {
  right: unset;
  left: 0;
  transform: translate(150%, -50%) scaleX(1.2) rotate(90deg);
}
[dir=rtl] .tagify--select .tagify__tag {
  right: unset;
  left: 1.8em;
}

[dir=rtl] .tagify--select[aria-expanded=true]::after {
  transform: translate(150%, -50%) rotate(270deg) scale(1.2);
}

.international-num input {
  width: calc(240px + 10 * (100vw - 320px) / 1600);
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #cccccc;
}
.international-num input:focus {
  outline-color: #cccccc;
}
.international-num input::placeholder {
  color: #cccccc;
}
.international-num .results ul {
  border: 1px solid #cccccc;
  margin-top: 12px;
  padding: 8px;
  line-height: 1.9;
}

.iti--allow-dropdown .iti__flag {
  background-image: url("../images/forms/flags.png");
}

.select-box {
  position: relative;
  display: flex;
  width: 400px;
  flex-direction: column;
}
.select-box .options-container {
  background: var(--recent-box-bg);
  color: var(--body-font-color);
  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  order: 1;
}
.select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: var(--view-grid-bg);
  border-radius: 0 8px 8px 0;
}
.select-box .options-container::-webkit-scrollbar-thumb {
  background: var(--view-grid-bg);
  border-radius: 0 8px 8px 0;
}
.select-box .options-container.active {
  max-height: 240px;
  opacity: 1;
  overflow-y: scroll;
  margin-top: 53px;
}
@media (min-width: 1007px) and (max-width: 1920px) {
  .select-box .options-container.active {
    max-width: calc(265px + 135 * (100vw - 1007px) / 913);
  }
}
@media (min-width: 768px) and (max-width: 1006px) {
  .select-box .options-container.active {
    max-width: calc(295px + 55 * (100vw - 1007px) / 238);
  }
}
@media (max-width: 767px) {
  .select-box .options-container.active {
    max-width: calc(242px + 173 * (100vw - 320px) / 447);
    margin-top: 46px;
  }
}
.select-box .options-container.active + .selected-box::after {
  transform: rotateX(180deg);
  top: -6px;
}
.select-box .options-container.active ~ .search-box input {
  opacity: 1;
  pointer-events: auto;
}
.select-box .selection-option {
  padding: 12px 24px;
  cursor: pointer;
}
.select-box .selection-option:hover {
  background: #cccccc;
}
.select-box .selection-option .radio {
  display: none;
}
.select-box label {
  cursor: pointer;
}

.selected-box {
  background: var(--light2);
  color: #333;
  position: relative;
  order: 0;
  padding: 12px 24px;
  cursor: pointer;
}
@media (min-width: 1007px) and (max-width: 1920px) {
  .selected-box {
    max-width: calc(265px + 135 * (100vw - 1007px) / 913);
  }
}
@media (min-width: 768px) and (max-width: 1006px) {
  .selected-box {
    max-width: calc(295px + 55 * (100vw - 1007px) / 238);
  }
}
@media (max-width: 767px) {
  .selected-box {
    max-width: calc(242px + 173 * (100vw - 320px) / 447);
  }
}
.selected-box::after {
  content: "\f078";
  font-family: "FontAwesome";
  position: absolute;
  height: 100%;
  width: 32px;
  right: 10px;
  top: 12px;
  transition: all 0.4s;
}
[dir=rtl] .selected-box::after {
  left: 10px;
  right: unset;
}

.search-box input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  border: 2px solid var(--chart-dashed-border);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
  border-radius: 6px;
}
@media (min-width: 1007px) and (max-width: 1920px) {
  .search-box input {
    max-width: calc(265px + 135 * (100vw - 1007px) / 913);
  }
}
@media (min-width: 768px) and (max-width: 1006px) {
  .search-box input {
    max-width: calc(295px + 55 * (100vw - 1007px) / 238);
  }
}
@media (max-width: 767px) {
  .search-box input {
    max-width: calc(242px + 173 * (100vw - 320px) / 447);
    padding: 9px 16px;
  }
}
.search-box input:focus {
  outline: none;
}

/**=====================
    5.6 Select2 CSS Ends
==========================**/
/**=====================
  5.7 Sidebar CSS Start
==========================**/
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  position: absolute;
  content: "";
  top: 58%;
  width: 8px;
  border-top: 1px solid #fff;
}

.d-flex.media-wrapper {
  width: 100%;
  display: -webkit-flex;
}
.d-flex.media-wrapper .flex-grow-1 {
  flex: unset;
}
.d-flex .flex-grow-1 {
  flex: unset;
}

.heart {
  text-align: end;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 0;
  background: rgba(155, 155, 155, 0.4);
  top: -10px;
  left: 27px;
  transition: height 0.5s;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li [dir=rtl] .mega-menu-container::before, [dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li [dir=rtl] .sidebar-submenu::before {
  left: unset;
  right: 27px;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

.onhover-show-div {
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
}
.onhover-dropdown:hover .onhover-show-div:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 2;
}
.onhover-dropdown:hover .onhover-show-div:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #d7e2e9;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

.page-wrapper {
  position: relative;
}
.page-wrapper .page-header {
  max-width: 100vw;
  position: fixed;
  top: 0;
  z-index: 8;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.07);
}
.page-wrapper .page-header .header-wrapper {
  width: 100%;
  align-items: center;
  padding: 16px 30px;
  position: relative;
}
@media (max-width: 1550px) and (min-width: 1200px) {
  .page-wrapper .page-header .header-wrapper {
    padding: 16px 20px;
  }
}
.page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-menu {
  display: none;
}
.page-wrapper .page-header .header-wrapper .serchinput {
  display: none !important;
}
.page-wrapper .page-header .header-wrapper .serchinput .serchbox {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .serchinput .search-form {
  top: 52px;
  position: absolute;
  transition: all linear 0.3s;
  right: -50px;
  transform: translateY(-35px) scaleY(0);
  opacity: 0;
  visibility: hidden;
  width: 180px;
  margin-left: 12px;
}
@media (max-width: 480px) {
  .page-wrapper .page-header .header-wrapper .serchinput .search-form {
    right: -85px;
  }
}
.page-wrapper .page-header .header-wrapper .serchinput .search-form.open {
  transform: translateY(0px) scaleY(1);
  opacity: 1;
  visibility: visible;
  transition: all linear 0.3s;
}
.page-wrapper .page-header .header-wrapper .serchinput .search-form input {
  padding: 10px 15px;
  background-color: #fdfeff;
}
.page-wrapper .page-header .header-wrapper .nav-right {
  justify-content: flex-end;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  stroke: none;
  fill: #9B9B9B;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg path {
  color: #363636;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li .mode.active svg {
  rotate: 180deg;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode {
  cursor: pointer;
  text-align: center;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode i {
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode i.fa-lightbulb-o {
  font-weight: 500;
  font-size: 22px;
  transition: all 0.3s ease;
  animation: zoomIn 300ms ease-in-out;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .header-search {
  cursor: pointer;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 {
  margin-left: 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 span {
  font-weight: 500;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 p {
  font-size: 12px;
  line-height: 1;
  color: rgba(54, 54, 54, 0.7);
}
.page-wrapper .page-header .header-wrapper .nav-right > ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li {
  padding: 9px 14px;
  display: inline-block;
  position: relative;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
  margin-right: unset;
  margin-left: 15px;
}
@media (max-width: 1550px) and (min-width: 1200px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 6px 8px;
    margin-right: 8px;
  }
  [dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    margin-right: unset;
    margin-left: 8px;
  }
}
@media (max-width: 630px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 3px 4px;
    margin-right: 5px;
  }
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li:last-child {
  border: none !important;
  margin-right: 0;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li:last-child {
  margin-right: unset;
  margin-left: 0;
}
@media (max-width: 630px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li:last-child {
    display: none;
  }
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li .badge {
  position: absolute;
  right: -5px;
  top: -6px;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 700;
  font-family: work-Sans, sans-serif;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul {
  left: inherit;
  right: -10px;
  width: 130px;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:before, .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:after {
  left: inherit;
  right: 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li {
  display: block;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a {
  font-size: 14px;
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul .flag-icon {
  font-size: 16px;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip {
  width: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  overflow: initial;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip::after, .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip::before {
  display: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content {
  text-align: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content .bookmark-icon {
  width: 52px;
  height: 52px;
  background-color: #f9f9f9;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card {
  width: 300px;
  height: 260px;
  background-color: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner {
  position: relative;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front,
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  width: 300px;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front button,
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button {
  background-color: transparent;
  color: #307EF3;
  border: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front button:focus,
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button:focus {
  outline: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
  background-color: #fff;
  transition: 0.3s;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
  stroke: #2c323f;
  fill: none;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg:hover {
  stroke: var(--theme-default);
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
  background-color: #fff;
  transform: rotateY(180deg);
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:first-child {
  margin-top: 20px;
  height: 140px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button {
  width: 100%;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
  border-radius: 25px;
  padding: 6px 12px;
  width: 100%;
  border: 1px solid #ddd;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input:focus {
  outline: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner.flipped {
  transform: rotateY(180deg);
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner.flipped .front {
  opacity: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
  width: 330px;
  top: 50px;
  right: 0;
  left: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div .dropdown-title {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid rgba(145, 142, 153, 0.3);
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul {
  padding: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .message-img {
  padding: 5px;
  border-radius: 50%;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .message-img img {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: auto;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .flex-grow-1 {
  margin-left: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .flex-grow-1 h5 a {
  color: #363636;
}
[class=dark-only] .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .flex-grow-1 h5 a {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .flex-grow-1 p {
  margin-bottom: 0;
  opacity: 0.6;
  line-height: 1.4;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul li .d-flex .notification-right svg {
  vertical-align: middle;
  width: 18px;
  margin-top: -10px;
  stroke: #9B9B9B;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div.message-dropdown li:last-child {
  padding-bottom: 0;
  text-align: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li {
  padding: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li + li {
  border-top: 1px solid rgba(145, 142, 153, 0.3);
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li p {
  font-size: 14px;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li a {
  letter-spacing: 0.3px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li:last-child {
  padding-bottom: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex img {
  width: 40px;
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .status-circle {
  left: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .flex-grow-1 > span {
  letter-spacing: 0.8px;
  padding-right: 10px;
  display: inline-block;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .flex-grow-1 p {
  margin-bottom: 8px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:before, .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:after {
  right: 10px !important;
  left: unset !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-box {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-box {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown {
  width: 270px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown ul {
  padding: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li {
  margin: 0 15px;
  padding: 10px 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex {
  align-items: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex .flex-grow-1 {
  margin-right: 20px;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex .flex-grow-1 {
  margin-right: unset;
  margin-left: 20px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex .flex-grow-1 span {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #363636;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex .flex-grow-1 h6 {
  color: #9B9B9B;
  margin-bottom: 5px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li + li {
  margin-top: 0;
  border-top: 1px solid rgba(145, 142, 153, 0.1);
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li:last-child {
  padding: 10px 0;
  border-top: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .view-cart, .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .view-checkout {
  font-size: 16px;
  font-weight: 500;
  color: #363636;
  transition: all 0.4s;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .view-cart:hover, .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .view-checkout:hover {
  color: var(--theme-default);
  transition: all 0.4s;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown p {
  line-height: 1.3;
  margin-top: 3px;
  opacity: 0.6;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle {
  position: absolute;
  right: 0;
  top: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle a i {
  color: #9B9B9B;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle svg {
  width: 14px;
  stroke: #fff;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total {
  border-bottom: 1px solid rgba(145, 142, 153, 0.1);
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total h5 {
  font-weight: 500;
  color: #9B9B9B;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total h5 span {
  font-size: 14px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .view-checkout {
  text-decoration: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-box svg {
  animation: swing 1.5s ease infinite;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
  top: 52px;
  width: 300px;
  right: -20px !important;
  left: unset;
}
@media (max-width: 575.98px) {
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    width: calc(100vw - 30px);
  }
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li {
  background-color: #f9f9f9;
  border-radius: 5px;
  border-top: none !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li:last-child {
  text-align: center;
  background-color: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p span {
  font-size: 11px;
  vertical-align: middle;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown:before, .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown:after {
  right: 28px !important;
  left: unset !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  width: 160px;
  top: 57px;
  left: -12px;
  padding: 0 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
  padding: 10px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
  text-decoration: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a span {
  color: var(--theme-default);
  transition: color 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a svg {
  stroke: var(--theme-default);
  transition: stroke 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a svg path {
  stroke: var(--theme-default);
  transition: stroke 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li + li {
  margin-top: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:last-child {
  padding-top: 10px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 10px;
  stroke: #2c323f;
  fill: none;
}
.page-wrapper .page-body-wrapper {
  background-color: white;
}
.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 136px);
  margin-top: 136px;
  padding: 0 15px 0 15px;
  position: relative;
  transition: 0.5s;
}
.page-wrapper .page-body-wrapper .page-body:before {
  animation: fadeIn 0.5s ease-in-out;
}
.page-wrapper .page-body-wrapper .page-title {
  padding: 18px 30px;
  margin: 0 -27px 0;
}
.page-wrapper .page-body-wrapper .page-title > .row {
  margin: 0;
}
.page-wrapper .page-body-wrapper .page-title > .row .col-6 {
  padding: 0;
}
.page-wrapper .page-body-wrapper .page-title .row {
  align-items: center;
}
.page-wrapper .page-body-wrapper .page-title .row h3 {
  text-transform: capitalize;
  color: #363636;
  font-weight: 600;
}
.page-wrapper .page-body-wrapper .page-title .row h3 small {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #9B9B9B;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  justify-content: flex-end;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb li:first-child {
  position: relative;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(48, 126, 243, 0.1);
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item {
  color: #307EF3;
  font-weight: 500;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "/";
  color: #307EF3;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
  width: 16px;
  height: 16px;
  stroke: #307EF3;
  vertical-align: middle;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item.active {
  font-weight: 100;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon {
  stroke: #fff;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon {
  fill: #fff;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg {
  transition: all 0.3s ease;
  position: relative;
  content: "";
  width: 35px;
  height: 35px;
  background-color: #4F5875;
  padding: 8px;
  top: 0;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 50px;
  box-shadow: 1.5px 0.33px 16px 0px rgba(79, 88, 117, 0.6);
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active span {
  transition: all 0.3s ease;
  position: relative;
  content: "";
  width: 168px;
  height: 35px;
  background-color: #4F5875;
  padding: 8px 14px;
  top: 0;
  left: 8px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 50px;
  box-shadow: 1.5px 0.33px 16px 0px rgba(79, 88, 117, 0.6);
}
[dir=rtl] .page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active span {
  left: unset;
  right: 3px;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active span::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 5px;
  background-color: #4F5875;
  left: -8px;
  top: 15px;
}
[dir=rtl] .page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active span::before {
  left: unset;
  right: -8px;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content li a {
  text-transform: capitalize;
}
.page-wrapper .sidebar-wrapper[data-layout=stroke-svg] .stroke-icon {
  display: inline-block;
}
.page-wrapper .sidebar-wrapper[data-layout=stroke-svg] .fill-icon {
  display: none;
}
.page-wrapper .sidebar-wrapper[data-layout=fill-svg] .stroke-icon {
  display: none;
}
.page-wrapper .sidebar-wrapper[data-layout=fill-svg] .fill-icon {
  display: inline-block;
}
.page-wrapper.horizontal-wrapper [data-simplebar] {
  position: relative;
}
.page-wrapper.horizontal-wrapper .footer {
  margin-left: 0;
}
.page-wrapper.horizontal-wrapper .header-logo-wrapper {
  width: 150px;
}
.page-wrapper.horizontal-wrapper .header-logo-wrapper .toggle-sidebar {
  display: none;
}
.page-wrapper.horizontal-wrapper .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 19px;
  right: 0px;
  color: #307EF3;
}
[dir=rtl] .page-wrapper.horizontal-wrapper .sidebar-list i.fa-thumb-tack {
  left: 0;
  right: unset;
}
.page-wrapper.horizontal-wrapper .sidebar-list:hover i.fa-thumb-tack {
  visibility: hidden;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
  height: 54px;
  line-height: inherit;
  top: 76px;
  background: #fff;
  box-shadow: 0px 9px 24px rgba(89, 102, 122, 0.05);
  overflow-x: hidden;
  z-index: 1;
  overflow-y: visible;
  position: fixed;
  width: 100vw;
  border-top: 1px solid #EAEAEA;
  z-index: 3;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .simplebar-content {
  display: flex;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper li {
  display: inline-block;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .badge {
  position: absolute;
  top: 2px;
  right: 0px;
  z-index: 1;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .logo-icon-wrapper {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
  text-align: left;
  position: relative;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  position: absolute;
  top: 0px;
  padding: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow.disabled,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow.disabled {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow {
  left: 0;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  right: 0;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  display: -webkit-box !important;
  padding: 0 5px;
  transition: all 0.5s ease;
  white-space: nowrap;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list a.active .according-menu i:before {
  content: "-";
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list a .according-menu {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list ul.sidebar-submenu li a.active:after {
  background-color: var(--theme-default);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title.show {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon {
  stroke: #9B9B9B;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon {
  fill: #9B9B9B;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:first-child {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  display: block;
  color: #363636;
  line-height: 23px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 5px 15px;
  margin: 0 3px;
  letter-spacing: 0.04em;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a:hover {
  background-color: rgba(48, 126, 243, 0.2);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active {
  background-color: #9ac0f9;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active svg,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active span {
  color: var(--theme-default);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a .badge {
  position: absolute;
  top: -5px;
  margin-left: 1px;
  right: 10px;
  padding: 0.2em 0.7em;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a svg {
  width: 18px;
  height: 18px;
  vertical-align: sub;
  margin-right: 10px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  position: fixed;
  z-index: 4;
  box-shadow: 0 0 1px 0 #9B9B9B;
  padding: 15px 0px 15px 0px;
  background: white;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  background: transparent;
  padding: 4px 35px;
  display: block;
  transition: all 0.5s ease;
  line-height: 1.9;
  color: #363636;
  letter-spacing: 0.06em;
  font-weight: 500;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  left: 20px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  color: var(--theme-default);
  letter-spacing: 1.5px;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover:after {
  background-color: var(--theme-default);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 4;
  box-shadow: 0 0 1px 0 #9B9B9B;
  padding: 30px;
  background: white;
  width: 95%;
  border-radius: 4px;
  display: block !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box {
  width: 20%;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin-left: -13px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content {
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
  border: 0 !important;
  background: transparent;
  color: #363636;
  border-radius: 0 !important;
  text-transform: capitalize;
  padding: 3px 0;
  font-size: 13px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:hover {
  color: var(--theme-default);
  letter-spacing: 1.5px;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:hover:after {
  color: var(--theme-default);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:after {
  position: absolute;
  content: "";
  left: -12px;
  top: 50%;
  width: 5px;
  height: 1px;
  background-color: #59667a;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover > a:after {
  left: 0;
  right: 0;
  opacity: 1;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu {
  position: unset;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper.overlay-white {
  background-color: rgba(255, 255, 255, 0.08);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav i {
  font-size: 24px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-top: 135px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main-title {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper.scorlled .sidebar-wrapper {
  display: none;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-header {
  width: 100%;
  box-shadow: none;
}
.page-wrapper.horizontal-wrapper .page-header .header-wrapper .form-control-plaintext {
  width: auto !important;
}
.page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
  width: 30%;
}
@media (max-width: 1428px) and (min-width: 1324px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 32%;
  }
}
@media (max-width: 1323px) and (min-width: 1200px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 37%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 32%;
  }
}
@media (max-width: 1428px) and (min-width: 1324px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
    width: 55%;
  }
}
@media (max-width: 1323px) and (min-width: 1200px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
    width: 40%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
    width: 50%;
  }
}
.page-wrapper.horizontal-wrapper.enterprice-type footer {
  margin-left: 0;
}
.page-wrapper.material-icon .card {
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(48, 126, 243, 0.1);
}
.page-wrapper .sidebar-main-title h6 {
  font-size: 14px;
  font-weight: 600;
  color: #307EF3;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 0.4px;
}
.page-wrapper.compact-wrapper .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 11px;
  right: -9px;
  color: #307EF3;
  z-index: 9999;
}
[dir=rtl] .page-wrapper.compact-wrapper .sidebar-list i.fa-thumb-tack {
  left: -9px;
  right: unset;
}
.page-wrapper.compact-wrapper .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
  cursor: pointer;
}
.page-wrapper.compact-wrapper .sidebar-list.pined {
  order: -1;
}
.page-wrapper.compact-wrapper .pin-title {
  order: -1;
}
.page-wrapper.compact-wrapper .page-header {
  margin-left: 246px;
  width: calc(100% - 246px);
}
.page-wrapper.compact-wrapper .page-header.close_icon {
  margin-left: 71px;
  width: calc(100% - 71px);
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
  height: auto;
  line-height: inherit;
  background: #36405E;
  width: 246px;
  text-align: left;
  transition: 0.3s;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  padding: 18px 18px;
  background-color: #4F5875;
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
  width: auto;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .badge {
  opacity: 0;
  width: 0;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  max-width: 86px;
  margin: 0;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  text-align: center;
  padding: 12px 14px;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main-title {
  display: none !important;
  opacity: 0;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-icon-wrapper {
  text-align: right;
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
  width: 246px;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .badge {
  opacity: 1;
  width: auto;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  max-width: unset;
  margin: initial;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  text-align: left;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  text-align: right;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  padding: 12px 15px;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a span {
  display: inline-block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main-title {
  opacity: 1;
  display: block !important;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-wrapper {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-icon-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .mobile-sidebar {
  transform: translate(183px);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
  margin-left: 71px;
  transition: 0.5s;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer-fix {
  width: calc(100% - 90px);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
  height: calc(100vh - 118px);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link::before {
  right: 20px;
  left: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a span {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .according-menu {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .left-arrow,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .right-arrow {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 22px;
  right: 50px;
  color: #307EF3;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list i.fa-thumb-tack {
  left: 50px;
  right: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
  cursor: pointer;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list.pined, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list.pin-title {
  order: -1;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .pin-title {
  order: -1;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  height: calc(100vh - 115px);
  left: -300px;
  z-index: 99;
  transition: color 1s ease;
  overflow: auto;
  color: rgba(0, 0, 0, 0);
  margin-top: 20px;
  margin-bottom: 30px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a {
  font-weight: 500;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  width: 35px;
  height: 35px;
  padding: 8px;
  vertical-align: -2px;
  float: none;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  letter-spacing: 0.7px;
  text-transform: capitalize;
  color: #fff;
  width: 168px;
  height: 35px;
  padding: 8px 14px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.link-nav .according-menu {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li:last-child {
  border-bottom: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links.opennav {
  left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .back-btn {
  display: block;
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .back-btn .mobile-back {
  padding: 20px;
  color: #363636;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  display: block;
  width: 100%;
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title {
  padding: 25px 0 10px 20px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title {
  display: none !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title.show {
  display: block;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title {
  padding: 25px 20px 10px 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  background: #4F5875;
  width: calc(100% - 40px);
  height: 1px;
  transform: translateX(-50%);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title:nth-child(2) {
  margin-top: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title:nth-child(2)::before {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-list .badge {
  position: absolute;
  right: 50px;
  top: 14.5px;
  padding: 0.45em 0.8em 0.33em;
  z-index: 1;
  font-size: 10px;
  letter-spacing: 0.7px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  border-radius: 10px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  padding: 12px 15px 5px 15px !important;
  position: relative;
  color: #222222;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow {
  right: 20px;
  position: absolute;
  top: 10px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow i {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow:before {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:first-child {
  display: none !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  width: 100%;
  padding: 0;
  position: relative !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
  padding-left: 75px !important;
  font-weight: 400;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu .submenu-title .according-menu {
  top: 7px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu.opensubmenu {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover > a svg {
  transition: all 0.3s ease;
  position: relative;
  content: "";
  width: 35px;
  height: 35px;
  background-color: #4F5875;
  padding: 8px;
  top: 0;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 50px;
  box-shadow: 1.5px 0.33px 16px 0px rgba(79, 88, 117, 0.6);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu {
  display: none;
  position: relative !important;
  right: 0;
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu.opensubchild {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu li a {
  padding: 6px 45px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu li a:after {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  padding: 7px 25px;
  font-size: 14px;
  color: #fff;
  display: block;
  position: relative;
  letter-spacing: 0.06em;
  font-weight: 500;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active {
  color: var(--theme-default);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  left: 55px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  margin-left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  padding: 0;
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container.opensubmenu {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box {
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title {
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 12px 15px 5px 75px;
  font-size: 14px;
  position: relative;
  color: #fff;
  display: block;
  letter-spacing: 0.06em;
  font-weight: 500;
  cursor: pointer;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after {
  left: 55px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title .according-menu {
  top: 10px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 75px !important;
  font-size: 14px;
  position: relative;
  color: #fff;
  display: block;
  letter-spacing: 0.06em;
  font-weight: 500;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a:after {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a.active {
  color: var(--theme-default);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
  line-height: 1.9;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover {
  margin-left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu .sidebar-title.active ~ .mega-menu-container {
  display: block !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link {
  transition: all 0.5s ease;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  transition: all 0.5s ease;
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .simplebar-content {
  display: flex;
  flex-direction: column;
}
.page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
  flex-basis: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  position: absolute;
  right: 30px;
  top: 14px;
  display: block;
  font-size: 18px;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  vertical-align: middle;
  color: #fff;
  font-weight: 500;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
  border: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav {
  position: absolute;
  z-index: 2;
  right: 145px;
  top: 6px;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
  font-size: 18px;
  padding-left: 5px;
  text-transform: uppercase;
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  margin-left: 246px;
}
.page-wrapper.compact-wrapper.dark-sidebar .sidebar-main-title h6, .page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title h6 {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar .toggle-sidebar svg, .page-wrapper.compact-wrapper.color-sidebar .toggle-sidebar svg {
  stroke: var(--theme-default);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon {
  stroke: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon {
  fill: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.stroke-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.stroke-icon {
  stroke: var(--theme-default);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.fill-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.fill-icon {
  fill: var(--theme-default);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title::before, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title::before {
  background: #374558;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
  background-color: #1f2639;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a span, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.stroke-icon, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.stroke-icon {
  stroke: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.fill-icon, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.fill-icon {
  fill: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  background-color: rgba(48, 126, 243, 0.2);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
  color: var(--theme-default);
  stroke: var(--theme-default);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-default);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .logo-wrapper .for-light, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .logo-wrapper .for-light {
  display: none;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .logo-wrapper .for-dark, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .logo-wrapper .for-dark {
  display: block;
}
.page-wrapper.compact-wrapper.dark-sidebar .according-menu i, .page-wrapper.compact-wrapper.color-sidebar .according-menu i {
  color: rgba(255, 255, 255, 0.6);
}
.page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
  background-color: #2f3c4e;
}
.page-wrapper.compact-sidebar .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content {
  display: flex;
  flex-direction: column;
}
.page-wrapper.compact-sidebar .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 54px;
  color: #307EF3;
  width: 20px;
  height: 20px;
  background-color: rgba(48, 126, 243, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
[dir=rtl] .page-wrapper.compact-sidebar .sidebar-list i.fa-thumb-tack {
  left: 54px;
  right: unset;
}
.page-wrapper.compact-sidebar .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
  cursor: pointer;
  z-index: 1;
}
.page-wrapper.compact-sidebar .sidebar-list.pined {
  order: -1;
}
.page-wrapper.compact-sidebar .pin-title {
  order: -1;
}
.page-wrapper.compact-sidebar ~ .bg-overlay1 {
  transition: 0.8s;
}
.page-wrapper.compact-sidebar ~ .bg-overlay1.active {
  z-index: 8;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
}
.page-wrapper.compact-sidebar .toggle-sidebar {
  position: relative;
  right: unset;
  top: unset;
  margin-right: 15px;
}
.page-wrapper.compact-sidebar .sidebar-main-title {
  display: none;
}
.page-wrapper.compact-sidebar .logo-wrapper {
  display: none;
}
.page-wrapper.compact-sidebar .logo-icon-wrapper {
  display: block;
  text-align: center;
  padding: 24px 30px;
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-sidebar .page-header {
  margin-left: 150px;
  width: calc(100% - 150px);
}
.page-wrapper.compact-sidebar .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
  width: 30%;
}
@media (max-width: 1578px) and (min-width: 1274px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 38%;
  }
}
@media (max-width: 1273px) and (min-width: 1200px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 45%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 36%;
  }
}
@media (max-width: 767px) and (min-width: 756px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 28%;
  }
}
@media (max-width: 1199px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header h6 {
    width: 132px;
  }
}
@media (max-width: 767px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header h6 {
    width: 83px;
  }
}
.page-wrapper.compact-sidebar .page-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}
.page-wrapper.compact-sidebar .page-body-wrapper .page-body {
  margin-top: 80px;
  margin-left: 150px;
}
.page-wrapper.compact-sidebar .page-body-wrapper .footer {
  margin-left: 150px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  height: auto;
  line-height: inherit;
  background: #fff;
  width: 150px;
  text-align: left;
  transition: 0.3s;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .left-arrow,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .right-arrow {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  margin: 30px 0;
  height: calc(100vh - 113px);
  overflow: auto;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a {
  color: #2c323f;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  text-transform: capitalize;
  color: #2c323f;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active {
  background: rgba(48, 126, 243, 0.1);
  color: #307EF3;
  border-radius: 20px;
  margin: 0 10px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active span {
  color: #307EF3;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
  animation: fadeInLeft 300ms ease-in-out;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  position: fixed;
  top: 0px;
  height: calc(100vh - 0px);
  overflow: auto;
  left: 150px;
  width: 230px;
  background-color: #fff;
  box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
  transition: all 0.5s ease-in-out;
  display: none;
  padding-top: 20px;
  z-index: -1;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  padding: 15px 15px 15px 40px;
  display: block;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .sub-arrow,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .sub-arrow {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
  position: absolute;
  right: 20px;
  top: 16px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a.active,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a.active {
  background-color: rgba(48, 126, 243, 0.1);
  color: #307EF3;
  transition: all 0.3s ease;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li .submenu-content li a.active,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li .submenu-content li a.active {
  background-color: transparent;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu > li > a::after,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container > li > a::after {
  position: absolute;
  content: "";
  left: 20px;
  top: 48%;
  width: 8px;
  border-top: 2px solid rgba(155, 155, 155, 0.4);
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section {
  text-align: left;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 15px 15px 40px;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5::after,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5::after {
  position: absolute;
  content: "";
  left: 20px;
  top: 48%;
  width: 6px;
  border-top: 2px solid #595c60;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list {
  position: relative;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
  position: absolute;
  left: 53%;
  top: 7px;
  font-weight: 500;
  font-size: 9px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link {
  text-align: center;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link svg {
  margin: 0 auto 6px;
  width: 24px;
  height: 24px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link > span {
  display: block;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  word-break: break-word;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link .according-menu {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu {
  text-align: center;
  padding: 0;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu svg {
  width: 24px;
  height: 24px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu .according-menu {
  position: absolute;
  right: 20px;
  top: 13px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=stroke-svg] .sidebar-links .mega-menu svg.stroke-icon {
  display: block;
  margin: 0 auto 6px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=stroke-svg] .sidebar-links .mega-menu svg.fill-icon {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=fill-svg] .sidebar-links .mega-menu svg.stroke-icon {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=fill-svg] .sidebar-links .mega-menu svg.fill-icon {
  display: block;
  margin: 0 auto 6px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
  transform: translateX(-150px);
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
  margin-left: 0px;
}
.page-wrapper.compact-small .toggle-sidebar {
  position: relative;
  right: unset;
  top: unset;
  margin-right: 15px;
}
.page-wrapper.compact-small .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 22px;
  color: #307EF3;
  width: 20px;
  height: 20px;
  background-color: rgba(48, 126, 243, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
[dir=rtl] .page-wrapper.compact-small .sidebar-list i.fa-thumb-tack {
  left: 22px;
  right: unset;
}
.page-wrapper.compact-small .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
}
.page-wrapper.compact-small .sidebar-list.pined, .page-wrapper.compact-small .sidebar-list.pin-title {
  order: -1;
}
.page-wrapper.compact-small .sidebar-main-title,
.page-wrapper.compact-small .logo-wrapper {
  display: none;
}
.page-wrapper.compact-small .logo-icon-wrapper {
  display: block;
  text-align: center;
  padding: 27px 30px;
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-small .page-header {
  margin-left: 90px;
  width: calc(100% - 90px);
}
.page-wrapper.compact-small .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-small .page-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}
.page-wrapper.compact-small .page-body-wrapper .page-body {
  margin-left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper .footer {
  margin-left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper > div {
  width: 90px;
}
@media (max-width: 420px) {
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 105px);
  }
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  display: block;
  padding: 15px 0;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  padding: 10px 15px 10px 40px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .sub-arrow,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .sub-arrow {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
  top: 11px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link svg {
  margin: 0 auto;
  width: 20px;
  height: 20px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link > span {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu svg {
  margin: 0 auto;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu a > span {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon {
  transform: translateX(-150px);
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
  margin-left: 0px;
}
.page-wrapper.only-body {
  background-color: #f2f0fa;
}
.page-wrapper.only-body .page-body-wrapper {
  background-color: #f2f0fa;
}
.page-wrapper.only-body .page-header {
  position: relative;
}
.page-wrapper.only-body .page-header .header-logo-wrapper {
  display: none;
}
.page-wrapper.only-body .page-header .header-wrapper {
  padding: 0 !important;
}
.page-wrapper.only-body .page-header .header-wrapper .left-header {
  display: none;
}
.page-wrapper.only-body .page-header .header-wrapper .nav-right {
  position: absolute;
  right: 60px;
  top: 58px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full {
  top: 37px;
  width: calc(100% - 50px);
  left: 25px;
}
[dir=rtl] .page-wrapper.only-body .page-header .header-wrapper .search-full {
  left: unset;
  right: 25px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
  background-color: #fff;
  border-radius: 15px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full .Typeahead-menu {
  top: 78px;
  width: calc(100% - 30px);
  margin: 0 auto;
  right: 0;
}
.page-wrapper.only-body .page-body-wrapper .page-title {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-wrapper.only-body .sidebar-wrapper,
.page-wrapper.only-body footer {
  display: none;
}
.page-wrapper.only-body .page-body-wrapper .page-body {
  margin-top: 0px;
  min-height: calc(100vh - 0px);
}
.page-wrapper.only-body .page-body-wrapper .page-title {
  padding-left: 30px;
  padding-right: 30px;
  margin: 30px 0;
  border-radius: 15px;
}
@media (max-width: 991.98px) {
  .page-wrapper.only-body .page-body-wrapper .page-title {
    padding-left: 20px;
    padding-right: 20px;
    margin: 20px 0;
  }
}
.page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6 {
  flex: 0 0 100%;
  max-width: 100%;
}
.page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb {
  margin-top: 5px;
  justify-content: flex-start;
}
.page-wrapper.modern-type .progress-chart-wrap {
  margin-left: -22px;
}
[dir=rtl] .page-wrapper.modern-type .progress-chart-wrap {
  margin-left: unset;
  margin-right: -22px;
}
.page-wrapper.modern-type .ecommerce-widget {
  border: 1px solid #9ac0f9 !important;
}
.page-wrapper.modern-type .file-sidebar .pricing-plan {
  border: 1px solid #9ac0f9 !important;
}
.page-wrapper.modern-type .alert-primary {
  color: #307EF3;
}
.page-wrapper.modern-type .project-box {
  background-color: #9ac0f9;
}
.page-wrapper.modern-type .nav-tabs .nav-link.active {
  background-color: transparent;
}
.page-wrapper.modern-type .simplebar-mask {
  top: 10px;
}
.page-wrapper.modern-type .page-header {
  margin-left: 0px;
  width: calc(100% - 0px);
}
.page-wrapper.modern-type .page-header.close_icon {
  margin-left: 0px;
  width: calc(100% - 0px);
}
.page-wrapper.modern-type .page-header .header-wrapper .left-header {
  width: 30%;
}
@media (max-width: 1428px) and (min-width: 1200px) {
  .page-wrapper.modern-type .page-header .header-wrapper .left-header {
    width: 37%;
  }
}
@media (max-width: 818px) {
  .page-wrapper.modern-type .page-header .header-wrapper .left-header {
    width: 36%;
  }
}
@media (max-width: 1669px) {
  .page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: 50%;
  }
}
@media (max-width: 1428px) and (min-width: 1200px) {
  .page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: 40%;
  }
}
.page-wrapper.modern-type .page-header .header-wrapper .header-logo-wrapper {
  display: flex;
  align-items: center;
}
.page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
  display: block;
  margin-right: 30px;
}
.page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
  display: block;
  position: relative;
  top: unset;
  right: unset;
  margin-right: 15px;
}
.page-wrapper.modern-type .page-body-wrapper .page-title {
  background-color: transparent;
  padding-bottom: 0;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
  margin: 30px !important;
  top: 80px;
  bottom: 0;
  border-radius: 15px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  height: calc(100vh - 160px);
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon .logo-icon-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
  margin-left: 0px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
  margin-left: 0px;
  margin-right: 0px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper .page-body {
  margin-left: 296px;
}
.page-wrapper.modern-type .page-body-wrapper .page-body .container-fluid {
  max-width: 1500px;
}
.page-wrapper.modern-type .page-body-wrapper .footer {
  margin-left: 360px;
  margin-right: 70px;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .page-wrapper.modern-type .page-body-wrapper .footer {
    margin-right: 0;
    margin-left: 0;
  }
}
.page-wrapper.material-type {
  position: relative;
}
.page-wrapper.material-type::before {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 300px;
  background-image: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-default) 79.68%);
}
.page-wrapper.material-type .card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.page-wrapper.material-type .page-header {
  position: relative;
  max-width: 1560px;
  margin: 50px auto 0;
  border-radius: 15px 15px 0 0;
}
.page-wrapper.material-type .page-header .header-wrapper .left-header {
  width: 31%;
}
@media (max-width: 1600px) {
  .page-wrapper.material-type .page-header .header-wrapper .left-header {
    width: 33%;
  }
}
@media (max-width: 1366px) {
  .page-wrapper.material-type .page-header .header-wrapper .left-header {
    width: 38%;
  }
}
@media (max-width: 1199px) {
  .page-wrapper.material-type .page-header .header-wrapper .left-header {
    width: 30%;
  }
}
@media (max-width: 1600px) {
  .page-wrapper.material-type .page-header .header-wrapper .nav-right {
    width: 40%;
  }
}
@media (max-width: 1199px) {
  .page-wrapper.material-type .page-header .header-wrapper .nav-right {
    width: 45%;
  }
}
.page-wrapper.material-type .page-body-wrapper {
  background-image: url(../images/other-images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.5);
}
.page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
  position: relative;
  max-width: 1560px;
  margin: 0 auto;
  top: unset;
  height: 56px;
}
.page-wrapper.material-type .page-body-wrapper .page-body {
  margin: 0 auto;
  max-width: 1560px;
  background-color: white;
  box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
  border-radius: 0 0 15px 15px;
}
.page-wrapper.material-type .footer {
  margin-top: 40px;
}
.page-wrapper.material-type.compact-wrapper .page-header .header-wrapper .left-header {
  width: 38%;
}
@media (max-width: 991px) {
  .page-wrapper.material-type.compact-wrapper .page-header .header-wrapper .nav-right {
    width: 50%;
  }
}
.page-wrapper.advance-layout {
  background-blend-mode: overlay;
  background-color: white;
}
.page-wrapper.advance-layout .card {
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ecf3fa;
}
.page-wrapper.advance-layout .page-header {
  position: relative;
  background-color: #fff;
  margin: 0 auto;
}
.page-wrapper.advance-layout .page-body-wrapper {
  background: transparent;
}
.page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
  position: relative !important;
  top: unset !important;
  height: unset;
  box-shadow: none;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper > div {
  overflow-x: hidden;
}
.page-wrapper.advance-layout .page-body-wrapper .page-body {
  margin-top: 0 !important;
}

@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}
.md-sidebar {
  position: relative;
}
.md-sidebar .md-sidebar-toggle {
  display: none;
  width: fit-content;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media screen and (max-width: 1660px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li:nth-child(2) {
    display: none;
  }
  .page-wrapper .page-header .header-wrapper .serchinput {
    display: block !important;
  }
}
@media screen and (max-width: 1480px) {
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -18%;
    width: 91%;
  }
}
@media screen and (max-width: 1470px) {
  .left-header .left-menu-header ul.header-left li:nth-child(n+2)::before {
    display: none;
  }
}
@media screen and (max-width: 1460px) {
  .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -54%;
    width: 75%;
  }
  .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    margin-right: 8px;
  }
}
@media screen and (max-width: 1366px) {
  .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -49%;
    width: 70%;
  }
  .profile-dropdown.onhover-show-div {
    right: 0;
    left: unset !important;
  }
  .profile-dropdown.onhover-show-div:before, .profile-dropdown.onhover-show-div:after {
    right: 10px !important;
    left: unset !important;
  }
}
@media screen and (max-width: 1199px) {
  .mobile-title.d-none {
    display: flex !important;
    justify-content: space-between;
    padding: 20px 30px 17px;
    border-bottom: 1px solid #ddd;
    margin: 0 -15px;
  }
  .mobile-title.d-none h5 {
    color: var(--theme-default);
  }
  .mobile-title.d-none svg {
    stroke: var(--theme-default) !important;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -35%;
    width: 56%;
  }
  .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-list ul {
    padding: 20px 0;
    width: 180px;
  }
  .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-list ul li a {
    color: #363636;
    font-weight: 600;
  }
  .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-menu {
    display: block !important;
    width: 40px;
    height: 40px;
    background-color: rgba(48, 126, 243, 0.2);
    position: relative;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1199px) and (max-width: 630px) {
  .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-menu {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 1199px) {
  .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-menu svg {
    width: 20px;
    height: 20px;
    transform: translate(50%, -50%);
    font-size: 18px;
    position: absolute;
    top: 50%;
  }
  [dir=rtl] .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-menu svg {
    left: 0;
  }
}
@media screen and (max-width: 1199px) and (max-width: 630px) {
  .page-wrapper .page-header .header-wrapper .left-header .left-menu-header .app-menu svg {
    width: 18px;
    height: 18px;
  }
}
@media screen and (max-width: 1199px) {
  .header-left {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .page-header .header-wrapper .nav-right .notification-dropdown {
    top: 46px;
  }
  .responsive-btn {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-mask {
    top: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
    width: 200px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a .sub-arrow {
    position: absolute;
    right: 20px;
    top: 5px;
    color: #59667a;
    font-weight: 100;
    transition: all 0.5s ease;
    font-size: 15px;
    top: 5px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .opensubmegamenu {
    display: none;
    border-radius: 4px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover .opensubmegamenu {
    display: block;
    position: absolute;
    left: 200px;
    background-color: #fff;
    top: -10px;
    padding: 15px 0px 15px 0px;
    box-shadow: 0 0 1px 0 #898989;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover .sub-arrow {
    right: 16px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.hoverd .sidebar-submenu {
    opacity: 1;
    visibility: visible;
    margin-top: 8px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .mega-menu-container {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .page-wrapper.material-icon {
    position: relative;
  }
  .page-wrapper.material-icon::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-default) 79.68%);
  }
  .page-wrapper.material-icon .toggle-sidebar {
    color: rgba(255, 255, 255, 0.8);
  }
  .page-wrapper.material-icon .toggle-sidebar:hover > svg {
    color: #fff !important;
    stroke: #fff !important;
  }
  .page-wrapper.material-icon .page-header {
    position: relative;
    background: transparent;
    padding-left: 90px;
    max-width: 1660px;
    margin: 0 auto;
    width: calc(100% - 0px);
    box-shadow: none;
  }
  .page-wrapper.material-icon .page-header .f-light {
    color: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right ul li svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right ul li .profile-dropdown li svg {
    stroke: #363636;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li {
    color: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li .notification-box svg,
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li .cart-box svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li > a > svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li > svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
    color: #363636;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
    color: #363636;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 p {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper.material-icon .page-header .header-wrapper .left-header .left-menu-header .app-menu {
    width: 35px;
    height: 35px;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .left-header .left-menu-header .app-menu svg {
    width: 18px;
    height: 18px;
  }
  .page-wrapper.material-icon .page-header.close_icon {
    padding-left: 0;
    margin: 0 auto !important;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-body {
    margin-top: 0 !important;
    padding-left: 90px;
    max-width: 1660px;
    margin: 0 auto;
  }
  .page-wrapper.material-icon .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
    padding-left: 15px;
    margin: 0 auto !important;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title {
    color: #fff;
    background-color: transparent;
    border-bottom: none;
    box-shadow: none;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 992px) {
  .page-wrapper.material-icon::before {
    height: 240px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar-wrapper .menu .brand-logo img {
    width: 120px;
  }
}
@media screen and (max-width: 576px) {
  .toggle-nav i {
    font-size: 20px;
  }
}
/**=====================
  5.7 Sidebar CSS Ends
==========================**/
/**=====================
    5.8 Rtl CSS Start
==========================**/
html[dir=rtl] ul {
  padding-right: 0;
}
html[dir=rtl] .ms-auto {
  margin-left: unset !important;
  margin-right: auto !important;
}
html[dir=rtl] .slider-wrapper {
  direction: ltr;
}
html[dir=rtl] .f-right {
  float: left;
}
html[dir=rtl] .papernote-wrap .note-labels ul {
  gap: 9px;
}
html[dir=rtl] .form-check-inline {
  margin-right: unset;
  margin-left: 1rem;
}
html[dir=rtl] .knowledgebase-search .form-inline svg {
  left: unset;
  right: 20px;
}
html[dir=rtl] .switch-showcase .form-check .form-check-input {
  float: left;
}
html[dir=rtl] .show-hide {
  position: absolute;
  left: 20px;
  right: unset;
}
html[dir=rtl] .login-card .login-main .theme-form .link {
  position: absolute;
  top: 10px;
  left: 0;
  right: unset;
}
html[dir=rtl] .login-card .login-main .theme-form .or:before {
  left: 0;
  right: unset;
}
html[dir=rtl] .comment-box .d-flex h4 span {
  padding-left: unset;
  padding-right: 10px;
}
html[dir=rtl] .pill-input-group span:last-child {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  border-top-left-radius: 1.3rem !important;
  border-bottom-left-radius: 1.3rem !important;
}
html[dir=rtl] .pill-input-group span:first-child {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-top-right-radius: 1.3rem !important;
  border-bottom-right-radius: 1.3rem !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .toggle-sidebar {
  right: unset;
  left: 30px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
  padding: 0;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .badge {
  left: 50px !important;
  right: unset !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon {
  margin-right: 0;
  margin-left: unset;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .logo-icon-wrapper {
  text-align: left;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .logo-icon-wrapper img {
  margin-left: 8px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .page-body,
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
  margin-left: 0;
  margin-right: 80px;
  transition: 0.5s;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  margin-left: 0;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
  display: none !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .according-menu {
  display: none;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  margin-left: 13px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover {
  transform: translate(0px);
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
  display: block !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  float: none;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .according-menu {
  display: block;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu:after {
  right: 30px;
  left: unset;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
  padding-right: 75px !important;
  padding-left: unset !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a:after {
  right: 55px;
  left: unset;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 246px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  margin-right: unset;
  margin-left: 5px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  right: unset;
  left: 30px;
}
html[dir=rtl] .page-wrapper.only-body .page-header .header-wrapper .nav-right {
  right: unset;
  left: 30px;
}
html[dir=rtl] .email-wrap .actions li {
  margin-right: unset;
  margin-left: 25px;
}
html[dir=rtl] .date-details > div + div {
  margin-left: unset;
  margin-right: 10px;
}
html[dir=rtl] .contact-editform.ps-0 {
  padding-left: 20px !important;
}
html[dir=rtl] .contact-options li .btn-category {
  padding-left: unset !important;
  padding-right: 40px;
}
html[dir=rtl] .list-persons .profile-mail .email-general ul {
  padding-right: unset;
  padding-left: 20px;
}
html[dir=rtl] .list-persons .profile-mail .email-general ul li > span {
  float: left;
}
html[dir=rtl] .list-persons .profile-mail .d-flex .flex-grow-1 ul li + li {
  padding-left: unset;
  padding-right: 10px;
  margin-left: unset;
  margin-right: 5px;
  border-left: unset;
  border-right: 1px solid #F3F3F3;
}
html[dir=rtl] .file-content .form-inline i {
  padding-right: unset;
  padding-left: 10px;
}
html[dir=rtl] .file-sidebar .btn svg {
  margin-right: unset;
  margin-left: 8px;
}
html[dir=rtl] .file-sidebar .pricing-plan .bg-img {
  right: unset;
  transform: rotate(45deg);
  left: -40px;
}
html[dir=rtl] .form-select {
  background-position: left 0.75rem center;
}
html[dir=rtl] .customers ul li + li {
  margin-left: unset;
  margin-right: -10%;
}
html[dir=rtl] .project-list .border-tab.nav-tabs .nav-item .nav-link {
  padding: 5px 0 5px 15px !important;
}
html[dir=rtl] .project-box .badge {
  right: unset;
  left: 30px;
}
html[dir=rtl] .file-content .form-inline i {
  padding-right: unset;
  padding-left: 10px;
}
html[dir=rtl] .quick-file li {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .file-manager .folder li:nth-child(n+2) {
  margin-left: unset;
  margin-right: 12px;
}
html[dir=rtl] .file-content .folder .folder-box .ellips {
  left: 22px;
  right: unset;
}
html[dir=rtl] .file-sidebar .btn svg {
  margin-right: unset;
  margin-left: 8px;
}
html[dir=rtl] .files-content li {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .kanban-board {
  float: right;
}
html[dir=rtl] .kanban-item .kanban-box .customers ul li + li {
  margin-left: unset;
  margin-right: -10px;
}
html[dir=rtl] .kanban-item .list li i {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .page-wrapper.horizontal-wrapper .header-logo-wrapper {
  text-align: right;
}
html[dir=rtl] .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-right: 0;
}
html[dir=rtl] .page-wrapper.horizontal-wrapper .page-body-wrapper footer {
  margin-right: 0;
}
html[dir=rtl] .page-wrapper.material-type .page-body-wrapper .page-body {
  margin-right: auto;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:first-child {
  margin-right: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 {
  margin-right: 15px;
  margin-left: unset;
}
html[dir=rtl] .page-header .header-wrapper .toggle-sidebar {
  margin-left: 2px;
  margin-right: unset;
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
  left: -20px !important;
  right: unset !important;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    left: 12px !important;
  }
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full input {
  padding-left: unset;
  padding-right: 60px;
  text-align: right;
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
  left: unset;
  right: 30px;
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
  right: unset;
  left: 0;
}
html[dir=rtl] .page-wrapper.compact-sidebar .toggle-sidebar {
  margin-left: 15px;
  margin-right: unset;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-header {
  margin-left: unset;
  margin-right: 150px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-header.close_icon {
  margin-right: 0;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 150px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper .footer {
  margin-left: unset;
  margin-right: 150px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
  animation: fadeInRight 1000ms ease-in-out;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  right: 150px;
  left: unset;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  padding: 15px 40px 15px 15px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
  right: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu > li > a::after,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container > li > a::after {
  left: unset;
  right: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  padding: 15px 40px 15px 15px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5::after,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5::after {
  left: unset;
  right: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
  left: unset;
  right: 53%;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link {
  text-align: center;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu {
  text-align: center;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu .according-menu {
  right: unset;
  left: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
  transform: translateX(150px);
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
  margin-right: 0px;
}
html[dir=rtl] .page-wrapper.compact-small .page-header {
  margin-right: 90px;
}
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  right: 90px;
}
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper .footer,
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper .page-body {
  margin-right: 90px;
}
html[dir=rtl] .page-wrapper.material-icon .page-header {
  padding-left: unset;
  padding-right: 90px;
  margin: 0 auto;
}
html[dir=rtl] .page-wrapper.material-icon .page-header.close_icon {
  padding-right: 0;
}
html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper .page-body {
  padding-left: 15px;
  padding-right: 90px;
  margin: 0 auto;
}
html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
  padding-right: 15px;
  margin: 0 auto !important;
}
html[dir=rtl] .apexcharts-canvas {
  direction: ltr;
}
html[dir=rtl] .email-wrap .btn-mail {
  text-align: right;
}
html[dir=rtl] .email-wrap .email-app-sidebar ul li a {
  padding-left: unset;
  padding-right: 20px;
}
html[dir=rtl] .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  text-align: center;
}
html[dir=rtl] .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: right;
}
html[dir=rtl] .bookmark-wrap .details-bookmark .row > div + div {
  padding-left: 15px !important;
}
html[dir=rtl] .appointment-table table tr td .status-circle {
  left: unset;
  right: 35px;
}
html[dir=rtl] .notification .card .d-flex .w-100 p .badge {
  margin-left: unset;
  margin-right: 10px;
}
html[dir=rtl] .progress-gradient-primary {
  background-image: linear-gradient(to right, var(--theme-default), transparent);
}
html[dir=rtl] .progress-gradient-primary::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-primary .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-secondary {
  background-image: linear-gradient(to right, #EBA31D, transparent);
}
html[dir=rtl] .progress-gradient-secondary::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-secondary .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-success {
  background-image: linear-gradient(to right, #53A653, transparent);
}
html[dir=rtl] .progress-gradient-success::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-success .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-danger {
  background-image: linear-gradient(to right, #DC3545, transparent);
}
html[dir=rtl] .progress-gradient-danger::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-danger .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-info {
  background-image: linear-gradient(to right, #01A1B9, transparent);
}
html[dir=rtl] .progress-gradient-info::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-info .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-light {
  background-image: linear-gradient(to right, #F3F3F3, transparent);
}
html[dir=rtl] .progress-gradient-light::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-light .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-dark {
  background-image: linear-gradient(to right, #2c323f, transparent);
}
html[dir=rtl] .progress-gradient-dark::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-dark .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-warning {
  background-image: linear-gradient(to right, #FFAA05, transparent);
}
html[dir=rtl] .progress-gradient-warning::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-warning .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-animate span {
  left: -10px;
  right: unset;
}
html[dir=rtl] .default-dashboard .left-background.ps-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
html[dir=rtl] .default-dashboard .since .customer-card.b-l-primary {
  border-left: unset !important;
  border-right: 2px solid #307EF3 !important;
}
html[dir=rtl] .default-dashboard .since .customer-card.b-l-secondary {
  border-left: unset !important;
  border-right: 2px solid #EBA31D !important;
}
html[dir=rtl] .default-dashboard .since .customer-card.b-l-danger {
  border-left: unset !important;
  border-right: 2px solid #DC3545 !important;
}
html[dir=rtl] .default-dashboard .since .customer-card.b-l-success {
  border-left: unset !important;
  border-right: 2px solid #53A653 !important;
}
html[dir=rtl] .default-dashboard .customer {
  margin-right: -5px;
  margin-left: unset;
}
html[dir=rtl] .default-dashboard .leads .lead-status ul li.b-l-primary {
  border-left: unset !important;
  border-right: 3px solid #307EF3 !important;
}
html[dir=rtl] .default-dashboard .leads .lead-status ul li.b-l-secondary {
  border-left: unset !important;
  border-right: 3px solid #EBA31D !important;
}
html[dir=rtl] .default-dashboard .leads .lead-status ul li.b-l-danger {
  border-left: unset !important;
  border-right: 3px solid #DC3545 !important;
}
html[dir=rtl] .default-dashboard .review-slider .review .d-flex .pull-right.ms-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
html[dir=rtl] .right-background .bg-light {
  margin-left: 15px;
}
html[dir=rtl] .fg-emoji-container {
  left: 135px !important;
  top: 155px !important;
}
html[dir=rtl] .me-4 {
  margin-left: 1.5rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .pe-4 {
  padding-left: 1.5rem !important;
  padding-right: unset !important;
}
html[dir=rtl] .me-1 {
  margin-left: 0.25rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .navs-dropdown .onhover-show-div {
  right: 0;
}
html[dir=rtl] .default-according.style-1 button {
  text-align: right;
}
html[dir=rtl] .default-according.style-1 button:before {
  left: 20px;
  right: unset;
}
@media (max-width: 575px) {
  html[dir=rtl] .default-according.style-1 button:before {
    left: 15px;
  }
}
html[dir=rtl] .default-according.style-1 button i {
  right: 18px;
  left: unset;
}
html[dir=rtl] .default-according .card .card-header i {
  right: 18px;
  left: unset;
}
html[dir=rtl] .faq-wrap .faq-accordion .card .card-header .btn-link {
  padding-right: 20px !important;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .faq-wrap .faq-accordion .card .card-header .btn-link {
    padding-right: 10px !important;
  }
}
html[dir=rtl] .ProfileCard-details {
  padding-right: 30px;
  padding-left: unset;
  float: right;
}
html[dir=rtl] .starter-main .card-body ul {
  padding-right: 30px;
  padding-left: unset;
}
html[dir=rtl] .theme-tab .tab-title li a svg {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .spent .spent-graph .project-budget {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .support-table table tbody tr td:first-child {
  padding-right: 0;
  padding-left: unset;
}
html[dir=rtl] .order-box .sub-total .shipping-class .shopping-checkout-option,
html[dir=rtl] .order-box .sub-total .shipping-class span,
html[dir=rtl] .order-box .sub-total li .shopping-checkout-option,
html[dir=rtl] .order-box .sub-total li span,
html[dir=rtl] .order-box .total .shipping-class .shopping-checkout-option,
html[dir=rtl] .order-box .total .shipping-class span,
html[dir=rtl] .order-box .total li .shopping-checkout-option,
html[dir=rtl] .order-box .total li span,
html[dir=rtl] .order-box .qty .shipping-class .shopping-checkout-option,
html[dir=rtl] .order-box .qty .shipping-class span,
html[dir=rtl] .order-box .qty li .shopping-checkout-option,
html[dir=rtl] .order-box .qty li span {
  float: left;
}
html[dir=rtl] .order-box .sub-total li .count,
html[dir=rtl] .order-box .total li .count,
html[dir=rtl] .order-box .qty li .count {
  float: left;
}
html[dir=rtl] .img-paypal {
  margin-right: 15px;
  margin-left: unset;
}
html[dir=rtl] .cart .qty-box .input-group .btn {
  border-radius: 0 !important;
}
html[dir=rtl] .product-qnty fieldset .input-group .input-group-prepend .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .grid-options {
  margin-right: 10px;
  margin-left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-1 {
  right: 12px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-2 {
  right: 18px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-3 {
  right: 36px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-4 {
  right: 42px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-5 {
  right: 48px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-6 {
  right: 66px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-7 {
  right: 72px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-8 {
  right: 78px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-9 {
  right: 84px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-10 {
  right: 103px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-11 {
  right: 109px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-12 {
  right: 115px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-13 {
  right: 121px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-14 {
  right: 127px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-15 {
  right: 133px;
  left: unset;
}
html[dir=rtl] .product-filter.new-products .owl-theme .owl-nav {
  left: 0;
  right: unset;
}
html[dir=rtl] .product-filter.new-products .owl-theme .owl-item .item .product-box .product-details.text-start {
  text-align: left !important;
}
html[dir=rtl] .product-sidebar .filter-section .card .card-header h6 .pull-right i {
  left: auto;
  right: unset;
}
html[dir=rtl] .prooduct-details-box .btn {
  right: unset;
  left: 15px;
}
html[dir=rtl] .prooduct-details-box .close {
  right: unset;
  left: 10px;
}
html[dir=rtl] .collection-filter-block svg {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .feature-products form .form-group i {
  left: 30px;
  right: unset;
}
html[dir=rtl] .product-wrapper-grid.list-view .product-box .product-details {
  text-align: right;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .breadcrumb {
  margin-left: unset;
}
html[dir=rtl] .bookmark ul li + li {
  margin-left: unset;
  margin-right: 10px;
}
html[dir=rtl] .bookmark ul li .search-form .form-control-search {
  left: -50px;
  right: unset;
}
html[dir=rtl] .bookmark ul li .search-form .form-control-search:before {
  right: 50px;
  left: unset;
}
html[dir=rtl] .bookmark ul li .search-form .form-control-search:after {
  right: 20px;
  left: unset;
}
html[dir=rtl] .me-0 {
  margin-left: 0 !important;
  margin-right: unset !important;
}
html[dir=rtl] .footer .pull-right {
  float: none;
}
html[dir=rtl] .checkbox_animated,
html[dir=rtl] .radio_animated {
  margin: 0 8px 0 16px;
}
html[dir=rtl] .button.remove {
  left: -10px;
  right: unset;
}
html[dir=rtl] .code-box-copy__btn {
  left: 11px;
  right: unset;
}
html[dir=rtl] ul.nav-menus {
  border-bottom: none;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li {
  text-align: right;
}
html[dir=rtl] .badge + .badge {
  margin-right: 5px;
  margin-left: unset;
}
html[dir=rtl] .offset-xl-3 {
  margin-right: 25%;
  margin-left: unset;
}
html[dir=rtl] .m-l-10 {
  margin-right: 10px !important;
  margin-left: unset !important;
}
html[dir=rtl] .m-r-20 {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .m-r-30 {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .m-r-5 {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .text-start {
  text-align: right !important;
}
html[dir=rtl] .m-r-10 {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .pe-3 {
  padding-left: 1rem !important;
  padding-right: unset !important;
}
html[dir=rtl] .p-r-0 {
  padding-left: 0;
  padding-right: 15px;
}
html[dir=rtl] .m-l-20 {
  margin-right: 20px !important;
  margin-left: unset !important;
}
html[dir=rtl] .ps-3 {
  padding-right: 1rem !important;
}
html[dir=rtl] .b-r-light {
  border-left: 1px solid #F3F3F3 !important;
  border-right: unset !important;
}
html[dir=rtl] .float-end {
  float: left !important;
}
html[dir=rtl] .float-start {
  float: right !important;
}
html[dir=rtl] .text-end {
  text-align: left !important;
}
html[dir=rtl] .border-right {
  border-left: 1px solid #dee2e6 !important;
  border-right: unset !important;
}
html[dir=rtl] .pe-0 {
  padding-left: 0 !important;
  padding-right: 12px !important;
}
html[dir=rtl] .ps-0 {
  padding-right: 0 !important;
  padding-left: unset !important;
}
html[dir=rtl] .ms-1 {
  margin-left: unset !important;
  margin-right: 0.25rem !important;
}
html[dir=rtl] .owl-carousel {
  direction: ltr;
}
html[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
html[dir=rtl] .btn-group > :not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .btn-group > .btn:nth-child(2) {
  border-radius: 0 !important;
}
html[dir=rtl] .btn-group .btn-group > :not(:first-child) > .btn {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .btn-group .btn + .btn,
html[dir=rtl] .btn-group .btn + .btn-group {
  margin-right: -1px;
  border-right: none;
}
html[dir=rtl] .btn-group .btn-group + .btn,
html[dir=rtl] .btn-group .btn-group + .btn-group {
  margin-right: -1px;
}
html[dir=rtl] .btn-group .btn-group-vertical .btn + .btn,
html[dir=rtl] .btn-group .btn-group-vertical .btn + .btn-group {
  margin-right: -1px;
}
html[dir=rtl] .btn-group .btn-group-vertical .btn-group + .btn,
html[dir=rtl] .btn-group .btn-group-vertical .btn-group + .btn-group {
  margin-right: -1px;
}
html[dir=rtl] .me-3 {
  margin-right: unset !important;
  margin-left: 1rem !important;
}
html[dir=rtl] .me-5 {
  margin-left: 3rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .ms-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .ms-4 {
  margin-left: unset !important;
  margin-right: 1.5rem !important;
}
html[dir=rtl] .m-r-15 {
  margin-left: 15px;
  margin-right: unset;
}
html[dir=rtl] .b-l-light {
  border-right: 1px solid #F3F3F3 !important;
  border-left: unset !important;
}
html[dir=rtl] .p-l-0 {
  padding-left: unset;
  padding-right: 0px;
}
html[dir=rtl] .ps-4 {
  padding-right: 1.5rem !important;
  padding-left: unset !important;
}
html[dir=rtl] .me-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .pe-2 {
  padding-right: unset !important;
  padding-left: 0.5rem !important;
}
html[dir=rtl] .alert {
  text-align: right;
}
html[dir=rtl] .pull-right {
  float: left;
}
html[dir=rtl] .form-inline .form-group {
  margin-left: 15px;
  margin-right: 0;
}
html[dir=rtl] .card {
  text-align: right;
}
html[dir=rtl] .card.social-profile {
  text-align: center;
}
html[dir=rtl] .card .card-header h5:not(.mb-0) {
  float: right;
}
html[dir=rtl] .card .card-header span {
  clear: both;
}
html[dir=rtl] .card .card-header .card-header-right {
  left: 35px;
  right: unset;
}
html[dir=rtl] .card .card-header .card-header-right .card-option.list-unstyled {
  padding-right: 0;
  padding-left: unset;
}
html[dir=rtl] .card .card-header .pull-left {
  float: right;
}
html[dir=rtl] .card.full-card {
  left: 0;
  right: unset;
}
html[dir=rtl] .card .card-body .alert svg {
  margin-left: 4px;
  margin-right: unset;
}
html[dir=rtl] .card .card-body .value-left {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .card .card-body .square {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-sm .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-md .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-lg .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xl .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xxl .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
}
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-sm .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-md .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-lg .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xl .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xxl .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 0;
  border-left-width: 1px;
}
html[dir=rtl] .explore-card {
  text-align: left;
}
html[dir=rtl] .explore-card .group-btn {
  justify-content: flex-end;
}
html[dir=rtl] .balance-box {
  text-align: center;
}
html[dir=rtl] .purchase-card {
  text-align: center;
}
html[dir=rtl] .modal-header .btn-close {
  margin: -0.5rem auto -0.5rem -0.5rem;
}
html[dir=rtl] .modal-footer > :not(:first-child) {
  margin-right: 0.5rem;
  margin-left: 0;
}
html[dir=rtl] .form-inline .form-group .col-form-label {
  margin-left: 5px;
  margin-right: 0;
}
html[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: unset;
}
html[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.5rem;
  padding-right: unset;
  float: right;
}
html[dir=rtl] code[class*=language-],
html[dir=rtl] pre[class*=language-] {
  text-align: right;
}
html[dir=rtl] .input-group-prepend {
  margin-left: -1px;
  margin-right: unset;
}
html[dir=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
html[dir=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: unset;
  margin-right: -1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .was-validated .form-control:invalid,
html[dir=rtl] .form-control.is-invalid,
html[dir=rtl] .was-validated .form-control:valid,
html[dir=rtl] .form-control.is-valid {
  background-position: left calc(0.375em + 0.1875rem) center;
  padding-left: calc(1.5em + 0.75rem);
  padding-right: 12px;
}
html[dir=rtl] .input-group > .form-control:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .input-group > .input-group-prepend > .btn,
html[dir=rtl] .input-group > .input-group-prepend > .input-group-text,
html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn,
html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text,
html[dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
html[dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .input-group > .input-group-append > .btn,
html[dir=rtl] .input-group > .input-group-append > .input-group-text,
html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .btn,
html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
html[dir=rtl] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
html[dir=rtl] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .border-tab.nav-left .nav-link {
  text-align: left;
}
html[dir=rtl] .socialprofile .social-group ul li:nth-child(4) {
  margin-right: -22px;
}
html[dir=rtl] .social-status form .form-group .form-control-social {
  padding-right: 15px;
  padding-left: unset;
}
html[dir=rtl] .social-status .d-flex .social-status {
  right: 35px;
  left: unset;
}
html[dir=rtl] .social-chat .other-msg {
  margin-right: 40px;
  margin-left: unset;
}
html[dir=rtl] .social-chat .flex-grow-1:before {
  left: 100%;
  right: unset;
  border-left: 7px solid var(-semi-dark);
  border-right: unset;
}
html[dir=rtl] .social-chat .flex-grow-1:after {
  left: 100%;
  right: unset;
  border-left: 7px solid #fff;
  border-right: unset;
}
html[dir=rtl] .timeline-content .comment-number i {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .photos ul li {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .avatar-showcase .friend-pic {
  margin-left: 8px;
  margin-right: unset;
}
html[dir=rtl] .avatar-showcase .pepole-knows ul li {
  margin-left: 21px;
  margin-right: unset;
}
html[dir=rtl] .avatar-showcase .pepole-knows ul li:last-child {
  margin-left: 0;
}
html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
  left: 100px;
  right: unset;
}
html[dir=rtl] .user-profile .hovercard .user-image .share-icons li {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .user-profile .hovercard .user-image .share-icons li:last-child {
  margin-left: 0;
}
html[dir=rtl] .faq-form .search-icon {
  left: 12px;
  right: unset;
}
html[dir=rtl] .faq-widgets .flex-grow-1 p {
  padding-left: 20px;
  padding-right: unset;
}
html[dir=rtl] .header-faq,
html[dir=rtl] .faq-title {
  text-align: right;
}
html[dir=rtl] .faq-accordion.default-according .card .faq-header svg {
  left: 30px;
  right: unset;
}
html[dir=rtl] .faq-accordion.default-according .card .btn-link svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .navigation-option ul li {
  padding: 8px 0 8px 8px;
}
html[dir=rtl] .navigation-option ul li a {
  padding-right: 40px;
  padding-left: unset;
}
html[dir=rtl] .navigation-option ul li a svg {
  right: 15px;
  left: unset;
}
html[dir=rtl] .updates-faq {
  margin-right: unset;
  margin-left: 20px;
}
html[dir=rtl] .browse .browse-articles span svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .categories .learning-header {
  padding-right: 15px;
  padding-left: unset;
}
html[dir=rtl] .main .langChoice {
  left: 30px;
  right: unset;
}
html[dir=rtl] .pricing-block svg {
  direction: ltr;
}
html[dir=rtl] p.data-attributes svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .avatars .avatar {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .avatars .avatar:last-child {
  margin-left: 0;
}
html[dir=rtl] .avatars .avatar .status {
  left: 4px;
  right: unset;
}
html[dir=rtl] .customers.avatar-group {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .card .card-header h5 {
  float: none !important;
}
html[dir=rtl] .knob-chart .chart-clock-main .clock-small {
  right: 10px;
  left: unset;
}
html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
  left: 30px;
  right: unset;
}
html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks .btn-label {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn {
  text-align: left;
}
html[dir=rtl] .todo .notification-popup {
  left: 10px;
  right: unset;
}
html[dir=rtl] .todo-options .badges-todo h6 {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .todo-options .badges-todo h6:last-child {
  margin-left: 0;
}
html[dir=rtl] .todo-list-btns .dropdown-basic .btn-group .form-group .checkbox {
  padding-right: 14px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
html[dir=rtl] .todo-list-btns .dropdown-basic .separated-btn {
  margin-right: -6px;
  margin-left: unset;
}
html[dir=rtl] .todo-list-btns .dropdown-basic .separated-btn .btn {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
html[dir=rtl] .search-todo .dropdown-basic .btn-group {
  margin-left: 18px;
  margin-right: unset;
}
html[dir=rtl] .sticky-note .note {
  float: right;
}
html[dir=rtl] .mega-menu .list-unstyled div a {
  padding: 8px 0 8px 35px;
}
html[dir=rtl] .mega-menu .list-unstyled div a:hover {
  padding: 8px 10px 8px 35px;
}
html[dir=rtl] #testimonial .owl-stage-outer .owl-stage .owl-item .slide--item .d-flex .me-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .needs-validation .input-group > .form-control:not(:last-child),
html[dir=rtl] .needs-validation .input-group > .custom-select:not(:last-child),
html[dir=rtl] .form-row .input-group > .form-control:not(:last-child),
html[dir=rtl] .form-row .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .form-check {
  padding-right: 1.25rem;
  padding-left: unset;
}
html[dir=rtl] .form-check .form-check-input {
  float: right;
  margin-left: unset;
  margin-right: -1.5em;
}
html[dir=rtl] .form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: 10px;
}
html[dir=rtl] .mega-menu .default-according .card .btn-link {
  text-align: right;
}
html[dir=rtl] .mega-menu .default-according.style-1 button[aria-expanded=true]:before,
html[dir=rtl] .mega-menu .default-according.style-1 button[aria-expanded=false]:before {
  right: unset;
  left: 20px;
}
html[dir=rtl] .email-wrap .row .col-xl-6 {
  padding-right: 0;
  padding-left: 15px;
}
html[dir=rtl] .email-wrap .row .col-xl-3 + .col-xl-3 {
  padding-left: 0;
  padding-right: 15px;
}
html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li {
  text-align: right;
}
html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .email-wrap .email-app-sidebar ul {
  padding-right: 0;
}
html[dir=rtl] .email-wrap .email-content .email-top .user-emailid:after {
  float: left;
}
html[dir=rtl] .email-wrap .email-right-aside .email-body .pe-0 {
  padding-right: unset !important;
}
html[dir=rtl] .email-wrap .email-right-aside .radius-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 4px;
}
html[dir=rtl] #renderRange {
  float: left;
  padding-left: unset;
  padding-right: 12px;
}
html[dir=rtl] .event-calendar {
  direction: ltr;
}
html[dir=rtl] .calendar-wrap .fc {
  direction: rtl;
  text-align: right;
}
html[dir=rtl] .calendar-wrap .fc .fc-toolbar .fc-left {
  float: right;
}
html[dir=rtl] .calendar-wrap .fc .fc-toolbar .fc-right {
  float: left;
}
html[dir=rtl] .calendar-wrap .fc .fc-toolbar > * > * {
  margin-left: 0;
}
html[dir=rtl] .calendar-wrap .fc-events-container {
  text-align: right;
}
html[dir=rtl] .calendar-wrap .fc-scroller {
  margin-right: unset;
  margin-left: -17px;
}
html[dir=rtl] .fc-agenda-view .fc-day-grid .fc-row {
  margin-right: 0 !important;
}
html[dir=rtl] .fc-unthemed .fc-row {
  margin-right: 0 !important;
}
html[dir=rtl] .btn-group-showcase .btn-group {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .btn-showcase .btn {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .btn-square.dropdown-toggle {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir=rtl].button-builder ul.the-icons li {
  float: right;
}
html[dir=rtl].button-builder .color-slelector {
  float: right;
}
html[dir=rtl].button-builder .custom-button-color .form-control {
  border-radius: 0 5px 5px 0;
}
html[dir=rtl].button-builder .custom-button-color .form-control.pull-left {
  float: right;
}
html[dir=rtl].button-builder .hint-label {
  float: right;
  padding: 4px 20px 0 0;
}
html[dir=rtl] #customer-review .owl-item img {
  order: 2;
}
html[dir=rtl] .options > div {
  margin: 0 0 8px 8px;
}
html[dir=rtl] .modal-dialog .modal-content .modal-body p a {
  margin-left: 0;
}
html[dir=rtl] .drag-box fieldset .component .form-group .text-lg-left {
  text-align: right !important;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .btn,
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .btn-right,
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group #prependedcheckbox {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group #appendedcheckbox {
  border-right-color: #EAEAEA;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}
html[dir=rtl] .drag form {
  text-align: right;
}
html[dir=rtl] .drag form input {
  text-align: right;
}
html[dir=rtl] .drag form label {
  text-align: right !important;
}
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .btn,
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .btn-right,
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .drag form .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}
html[dir=rtl] .draggable label {
  text-align: right;
}
html[dir=rtl] .form-builder-column .tab-content .tab-pane .theme-form .ui-draggable .input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .form-group .input-group #buttondropdown,
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group #buttondropdown {
  margin-right: unset;
  margin-left: 15px;
}
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .form-group .input-group .input-group-btn button,
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group .input-group-btn button {
  border-radius: 4px !important;
}
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .text-lg-left {
  text-align: right !important;
}
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .btn,
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .btn-right,
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .form-builder .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .logo-wrapper {
  box-shadow: 9px 0 20px rgba(89, 102, 122, 0.1) !important;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .logo-wrapper a {
  transform: translate(-64px);
}
html[dir=rtl] .search-page .nav i {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .product-stts {
  display: inline-block;
}
html[dir=rtl] .product-stts .ms-1 {
  margin-right: 0.25rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .search-form input {
  padding: 10px 70px 10px 10px;
  text-align: right;
}
html[dir=rtl] .icon-hover-bottom .icon-title {
  text-align: right;
}
html[dir=rtl] .icon-lists div i {
  margin: 0 10px 0 15px;
}
html[dir=rtl] .icon-lists ul {
  padding-right: 0;
}
html[dir=rtl] ul.icon-lists {
  padding-right: 30px;
}
html[dir=rtl] .floated-customizer-btn.third-floated-btn {
  left: 35px;
  right: unset;
}
html[dir=rtl] .floated-customizer-btn > span {
  margin-right: 10px;
}
html[dir=rtl] .floated-customizer-panel {
  text-align: right;
  left: -85px;
  right: unset;
}
html[dir=rtl] .floated-customizer-panel.active {
  left: 35px;
  right: unset;
}
html[dir=rtl] .floated-customizer-panel .close-customizer-btn {
  left: 30px;
  right: unset;
}
html[dir=rtl] .floated-customizer-panel ul {
  padding-right: 0;
}
html[dir=rtl] .form-builder-2-header > div nav {
  float: left;
}
html[dir=rtl] .radio label {
  padding-left: 0;
  padding-right: 15px;
}
html[dir=rtl] .radio label::before {
  right: 0;
  margin-right: -20px;
  left: unset;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .row h3 {
  text-align: right;
  padding-left: 20px;
  padding-right: unset !important;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .row h6 {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-header {
  margin-right: 246px;
  margin-left: unset;
  width: calc(100% - 246px);
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-header.close_icon {
  margin-right: 71px;
  margin-left: unset;
  width: calc(100% - 71px);
}
html[dir=rtl] .page-wrapper.modern-type .page-header {
  margin-right: 0;
  width: calc(100% - 0px);
}
html[dir=rtl] .page-wrapper.modern-type .page-header.close_icon {
  margin-right: 0;
  width: calc(100% - 0px);
}
html[dir=rtl] .page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
  margin-right: unset;
  margin-left: 30px;
}
html[dir=rtl] .page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
  margin-right: unset;
  margin-left: 15px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper .page-body {
  margin-right: 310px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper .footer {
  margin-left: 55px;
  margin-right: 360px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
  margin-right: 0px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
  margin-right: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right {
  text-align: right;
}
html[dir=rtl] .page-header .header-wrapper .nav-right .onhover-show-div {
  right: unset;
  left: 0;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .page-header .header-wrapper .nav-right .onhover-show-div {
    left: 12px;
  }
}
@media (max-width: 575.98px) {
  html[dir=rtl] .page-header .header-wrapper .nav-right .cart-dropdown li {
    padding: 10px;
  }
}
html[dir=rtl] .page-header .header-wrapper .nav-right .cart-dropdown .close-circle {
  right: unset;
  left: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li {
  text-align: right;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li .lang-txt {
  margin-left: 0;
  margin-right: 8px;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:before {
  right: 0;
  left: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li .dot {
  left: 17px;
  right: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:before {
  right: 10px;
  left: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:after {
  right: 10px;
  left: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:last-child {
  padding-left: 0;
  padding-right: 0 !important;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:last-child {
  padding-right: 20px;
  padding-left: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li i.ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .page-header .header-wrapper .mobile-sidebar {
  padding-left: 20px;
  padding-right: unset;
}
html[dir=rtl] .page-header.open {
  margin-right: 0;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div {
  right: initial;
  left: -30px;
  padding: 0;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div:before {
  right: inherit !important;
  left: 35px !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div:after {
  right: inherit !important;
  left: 35px !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li {
  margin-right: 0 !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li span.badge {
  margin-left: unset !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li span svg {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li .notification-icon {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .card-body .alert svg ~ p {
  padding-left: unset;
  padding-right: 20px;
}
html[dir=rtl] .alert i {
  margin-left: 5px;
  margin-right: 0;
}
html[dir=rtl] .inverse {
  padding: 13px 65px 13px 20px;
}
html[dir=rtl] .inverse i {
  left: unset;
  right: 0;
}
html[dir=rtl] .alert-primary.inverse:before {
  border-right: 7px solid #307EF3;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-secondary.inverse:before {
  border-right: 7px solid #EBA31D;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-success.inverse:before {
  border-right: 7px solid #53A653;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-danger.inverse:before {
  border-right: 7px solid #DC3545;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-warning.inverse:before {
  border-right: 7px solid #FFAA05;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-info.inverse:before {
  border-right: 7px solid #01A1B9;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-light.inverse:before {
  border-right: 7px solid #F3F3F3;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-dark.inverse:before {
  border-right: 7px solid #2c323f;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-dismissible .btn-close {
  left: 0;
  right: unset;
}
html[dir=rtl] .alert-dismissible .btn-close span {
  padding-left: 0;
  padding-right: unset;
}
html[dir=rtl] .icofont.icofont-truck {
  display: inline-block;
}
html[dir=rtl] .blog-box .blog-details {
  padding-left: 20px;
  text-align: right;
}
html[dir=rtl] .blog-box .blog-details .blog-social {
  padding-right: 0;
}
html[dir=rtl] .blog-box .blog-details .blog-social li {
  padding-left: 40px;
  padding-right: unset;
}
html[dir=rtl] .blog-box .blog-details .blog-social li:first-child {
  border-left: 1px solid #777777;
  border-right: unset;
  padding-left: 40px;
  padding-right: unset;
}
html[dir=rtl] .blog-box .blog-details .blog-social li + li {
  margin-right: 30px;
  margin-left: unset;
  padding-left: 0;
}
html[dir=rtl] .blog-box .blog-details-main .blog-social li {
  border-left: 1px dotted;
  border-right: unset;
}
html[dir=rtl] .blog-box .blog-details-main .blog-social li:last-child {
  border-left: none;
}
html[dir=rtl] .blog-box .blog-date span {
  font-size: 36px;
  font-weight: 500;
  padding-left: 5px;
}
html[dir=rtl] .comment-box .d-flex img {
  margin-left: 45px;
  margin-right: unset;
}
html[dir=rtl] .comment-box .d-flex h6 {
  text-align: right;
}
html[dir=rtl] .comment-box ul ul {
  margin-right: 135px;
  margin-left: unset;
  padding-right: 0;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .comment-box ul ul {
    margin-right: 30px;
  }
}
html[dir=rtl] .comment-box .comment-social {
  text-align: left;
}
html[dir=rtl] .comment-box .comment-social li {
  padding-left: 0;
  padding-right: 20px;
}
html[dir=rtl] .comment-box .comment-social li:first-child {
  border-left: 1px solid #EAEAEA;
  border-right: unset;
  padding-right: 0;
  padding-left: 20px;
}
html[dir=rtl] .button-builder-wrap .form-group .btn {
  margin-left: 15px;
  margin-right: 0;
  padding: 9px;
}
html[dir=rtl] .button-builder ul.the-icons {
  padding-right: 0 !important;
}
html[dir=rtl] .call-chat-body .chat-box .chat-right-aside .chat .chat-history .caller-img.ps-0 {
  padding-left: 15px !important;
}
html[dir=rtl] .chat-box .user-image {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}
html[dir=rtl] .chat-box .people-list .search i {
  left: 10px;
  right: unset;
}
html[dir=rtl] .chat-box .about {
  float: right;
  padding-right: 10px;
  padding-left: unset;
  text-align: right;
}
html[dir=rtl] .chat-box .chat-menu {
  border-right: 1px solid #F3F3F3;
  border-left: unset;
}
html[dir=rtl] .chat-box .chat-menu .nav {
  padding-right: 0;
}
html[dir=rtl] .chat-box .chat-menu.ps-0 {
  padding-left: 15px !important;
}
html[dir=rtl] .chat-box .chat-right-aside .chat .chat-message .smiley-box {
  margin-left: 0.5rem;
  margin-right: unset;
}
html[dir=rtl] .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
  border-radius: 5px 0 0 5px;
}
html[dir=rtl] .chat-box .chat-right-aside.pe-0 {
  padding-right: 15px !important;
}
html[dir=rtl] .chat-box .chat .chat-message .text-box .input-group-append {
  margin-right: -2px;
}
html[dir=rtl] .chat-msg-box ul {
  padding-right: 0;
}
html[dir=rtl] .chat-left-aside .status-circle {
  left: unset;
  right: 40px;
}
html[dir=rtl] .chat-right-aside .chat .chat-header .chat-menu-icons {
  padding-left: 0;
  padding-right: unset;
}
html[dir=rtl] .chat-right-aside .chat .chat-header .chat-menu-icons.float-sm-right {
  float: left !important;
}
html[dir=rtl] .chat-right-aside .chat .chat-header img {
  float: right;
}
html[dir=rtl] .cke_toolbar,
html[dir=rtl] .cke_toolgroup,
html[dir=rtl] a.cke_button,
html[dir=rtl] .cke_combo_text {
  float: right;
}
html[dir=rtl] .cke_reset_all,
html[dir=rtl] .cke_reset_all *,
html[dir=rtl] .cke_reset_all a,
html[dir=rtl] .cke_reset_all textarea {
  text-align: right;
}
html[dir=rtl] .cke_combo_text {
  padding-left: unset;
  padding-right: 10px;
}
html[dir=rtl] #editor1 #cke_editor1 #cke_1_contents iframe html {
  direction: rtl !important;
}
html[dir=rtl] #clockdiv ul {
  padding-right: 0;
}
html[dir=rtl] .custom-card .card-footer > div + div {
  border-right: 1px solid #EAEAEA;
  border-left: unset !important;
}
html[dir=rtl] .card-social {
  padding-right: 0;
}
html[dir=rtl] .card-absolute .card-header {
  right: 15px;
  left: unset;
}
html[dir=rtl] .dataTables_wrapper .dataTables_filter input[type=search] {
  margin-right: 10px;
  margin-left: 0;
}
html[dir=rtl] .dataTables_wrapper .dataTables_length {
  float: right;
}
html[dir=rtl] .dataTables_wrapper .dataTables_filter {
  margin-left: unset;
  margin-right: 15px;
  float: left;
}
html[dir=rtl] .dataTables_wrapper .dataTables_info {
  float: right;
}
html[dir=rtl] .dataTables_wrapper .advance-5_filter {
  margin-left: 0;
}
html[dir=rtl] .dataTables_wrapper .btn-group button {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .dataTables_wrapper .btn-group button:last-child {
  margin-left: 0;
}
html[dir=rtl] .dataTables_wrapper table.dataTable tbody td.select-checkbox:after,
html[dir=rtl] .dataTables_wrapper table.dataTable tbody th.select-checkbox:after {
  margin-left: 31px;
}
html[dir=rtl] div.dataTables_wrapper div.dataTables_paginate {
  margin-right: 15px !important;
  margin-left: 0 !important;
  float: left;
}
html[dir=rtl] table.dataTable thead > tr > th {
  padding-left: 30px;
  padding-right: 12px;
}
html[dir=rtl] table.dataTable thead > tr > th:before {
  left: 1em !important;
  right: unset;
}
html[dir=rtl] table.dataTable thead > tr > th:after {
  left: 0.5em !important;
  right: unset;
}
html[dir=rtl] table.dataTable thead .sorting:before,
html[dir=rtl] table.dataTable thead .sorting_asc:before,
html[dir=rtl] table.dataTable thead .sorting_desc:before,
html[dir=rtl] table.dataTable thead .sorting_asc_disabled:before,
html[dir=rtl] table.dataTable thead .sorting_desc_disabled:before {
  left: 1em !important;
  right: unset;
}
html[dir=rtl] .dataTables_scrollHeadInner {
  padding-right: unset !important;
  padding-left: 0 !important;
}
html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-right: 0;
  padding-left: 0;
}
html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
  padding-left: 0;
}
html[dir=rtl] .dt-plugin-buttons button.m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}
html[dir=rtl] .datetime-picker .theme-form .form-group label {
  text-align: left !important;
}
html[dir=rtl] .datetime-picker .theme-form .form-group .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .datetime-picker .theme-form .form-group .input-group .form-control {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .dropdown-basic .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
html[dir=rtl] .dropdown-basic .separated-btn .btn {
  border-radius: 0.25rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir=rtl] .dropdown-basic .btn-group {
  margin-right: unset;
  margin-left: 18px;
}
html[dir=rtl] .error-wrapper .col-md-8.offset-md-2 {
  margin: 0 auto !important;
}
html[dir=rtl] .product-price del {
  padding-left: 20px;
  padding-right: 0;
}
html[dir=rtl] .product-box .modal .modal-header .btn-close {
  right: unset;
  left: 15px;
}
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-left-color: #007bff;
}
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-left:before,
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-right:before {
  right: 0;
  left: unset;
  border-left: 15px solid #2a3142;
}
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-info:before {
  border-left-color: transparent;
}
html[dir=rtl] .product-box .ribbon-warning.ribbon-clip:before {
  border-left-color: #ff850d;
  border-right-color: unset;
}
html[dir=rtl] .product-box .ribbon-clip {
  right: -14px;
  left: unset;
}
html[dir=rtl] .product-box .ribbon-clip:before {
  right: 0;
  left: unset;
}
html[dir=rtl] .product-hover ul {
  padding-right: 0;
}
html[dir=rtl] .customer-review .d-flex img {
  margin-left: 25px;
  margin-right: 0;
}
html[dir=rtl] .nav {
  padding-right: 0;
}
html[dir=rtl] .custom-tab {
  padding: 4px;
}
html[dir=rtl] .custom-file-label::after {
  left: 0;
  right: unset;
}
html[dir=rtl] .wizard-4 ul.anchor {
  float: right;
}
html[dir=rtl] .wizard-4 .step-container {
  clear: unset;
}
html[dir=rtl] .wizard-4 .form-group {
  text-align: right;
}
html[dir=rtl] .wizard-4 .action-bar .btn {
  float: left;
}
html[dir=rtl] .f1 .f1-buttons {
  text-align: left;
}
html[dir=rtl] .f1 .f1-steps .f1-step {
  float: right;
}
html[dir=rtl] .jstree-default .jstree-node {
  margin-left: unset;
  margin-right: 24px;
  background-position: -292px -4px;
  background-repeat: repeat-y;
}
html[dir=rtl] .img-cropper .btn-group {
  margin-right: 0;
  margin-left: 10px;
}
html[dir=rtl] .img-cropper .btn-group button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
html[dir=rtl] .img-cropper .btn-group button:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .docs-toggles .btn-group .btn {
  border-radius: 0;
}
html[dir=rtl] .docs-toggles .btn-group .btn:last-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
html[dir=rtl] .docs-toggles .btn-group .btn:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .jsgrid-button + .jsgrid-button {
  margin-right: 5px;
  margin-left: unset;
}
html[dir=rtl] .list-group {
  padding-right: 0;
}
html[dir=rtl] .list-group i,
html[dir=rtl] .list-group img {
  margin-left: 10px;
  margin-right: 0;
}
html[dir=rtl] .authentication-box {
  text-align: right;
}
html[dir=rtl] .mega-horizontal .offset-sm-3 {
  margin-right: 25%;
  margin-left: unset;
}
html[dir=rtl] .navs-icon svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .navs-icon .fa {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .nav-list .nav-list-disc {
  text-align: right;
  padding-right: 0;
}
html[dir=rtl] .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  left: 77px;
  right: unset;
}
html[dir=rtl] .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  left: 77px;
  right: unset;
}
html[dir=rtl] .navs-dropdown .onhover-show-div .navs-icon li {
  text-align: right;
}
html[dir=rtl] .navs-icon.default-according.style-1 li button[aria-expanded=true]:before {
  left: 2px;
  right: unset;
}
html[dir=rtl] .navs-icon.default-according.style-1 li button[aria-expanded=false]:before {
  left: 2px;
  right: unset;
}
html[dir=rtl] .search-form .form-group:after {
  right: 53px;
  left: unset;
}
html[dir=rtl] .search-form .form-group:before {
  right: 82px;
  left: unset;
}
html[dir=rtl] .order-history table tbody tr td .product-name .order-process .order-process-circle:before {
  right: -15px;
  left: unset;
}
html[dir=rtl] .product-page-details span {
  padding-right: 15px;
  padding-left: 0;
}
html[dir=rtl] .m-checkbox-inline label {
  margin-left: 20px;
  margin-right: 0;
}
html[dir=rtl] .form-label-align-right label {
  text-align: left;
}
html[dir=rtl] .br-theme-bars-reversed .br-widget .br-current-rating,
html[dir=rtl] .br-theme-bars-movie .br-widget .br-current-rating,
html[dir=rtl] .br-theme-bars-1to10 .br-widget .br-current-rating,
html[dir=rtl] .br-theme-bars-square .br-widget .br-current-rating {
  float: right;
}
html[dir=rtl] .br-theme-bars-reversed .br-widget a,
html[dir=rtl] .br-theme-bars-movie .br-widget a,
html[dir=rtl] .br-theme-bars-1to10 .br-widget a,
html[dir=rtl] .br-theme-bars-square .br-widget a {
  float: right;
}
html[dir=rtl] .ribbon-left {
  right: auto;
  left: -2px;
}
html[dir=rtl] .ribbon-vertical-left {
  right: auto;
  left: 12px;
}
html[dir=rtl] .ribbon-vertical-right {
  right: auto;
  left: 12px;
}
html[dir=rtl] .star-ratings ul.search-info {
  padding-right: 0;
}
html[dir=rtl] .star-ratings ul.search-info li + li {
  border-right: 1px solid #a5afc6;
  border-left: unset;
  padding-right: 8px;
  padding-left: 0;
  margin-right: 8px;
  margin-left: 0;
}
html[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 10px !important;
  right: unset !important;
}
html[dir=rtl] .select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: right;
}
html[dir=rtl] .selection .select2-selection .select2-search__field {
  text-align: right;
}
html[dir=rtl] .select2-results__option {
  text-align: right;
}
html[dir=rtl] .editor-statusbar {
  text-align: left;
}
html[dir=rtl] .editor-statusbar span {
  margin-right: 1em;
  margin-left: unset;
}
html[dir=rtl] .CodeMirror-scroll {
  margin-left: -30px;
  margin-right: unset;
}
html[dir=rtl] .primary-color ul,
html[dir=rtl] .yellow-color ul {
  padding-right: 0;
}
html[dir=rtl] .u-step-desc {
  text-align: right;
}
html[dir=rtl] .u-step-number {
  right: 20px;
  left: unset;
}
html[dir=rtl] .u-step-number ~ .u-step-desc {
  margin-right: 50px;
}
html[dir=rtl] .u-pearl:after {
  left: 0;
  right: unset;
}
html[dir=rtl] .u-pearl:before {
  right: 0;
  left: unset;
}
html[dir=rtl] .u-step-icon {
  float: right;
  margin-left: 0.5em;
  margin-right: unset;
}
html[dir=rtl] .list-icons {
  padding-right: 0;
}
html[dir=rtl] .card-header.note-toolbar .note-color .dropdown-toggle {
  padding-right: 5px;
}
html[dir=rtl] .card-header.note-toolbar .form-group.draggable {
  text-align: right;
}
html[dir=rtl] .card-header.note-toolbar .form-check {
  padding-right: 24px;
  padding-left: unset;
}
html[dir=rtl] .card-header.note-toolbar form .custom-control {
  padding-right: 24px;
  padding-left: unset;
}
html[dir=rtl] .card-header.note-toolbar form .custom-control .custom-control-label::after {
  right: 0;
  left: unset;
}
html[dir=rtl] .card-header.note-toolbar form .custom-control .custom-control-label::before {
  right: 0;
  left: unset;
}
html[dir=rtl] .dropdown-toggle::after {
  margin-right: 0.255em;
  margin-left: unset;
}
html[dir=rtl] .checkbox label {
  padding-right: 10px;
  padding-left: unset;
}
html[dir=rtl] .checkbox label::before {
  right: 0;
  left: unset;
  margin-right: -16px;
  margin-left: unset;
}
html[dir=rtl] .checkbox-wrapper li label,
html[dir=rtl] .radio-wrapper li label {
  padding-right: 0;
}
html[dir=rtl] .radio label::after {
  right: 5px;
  left: unset;
  margin-right: -20px;
  margin-left: unset;
}
html[dir=rtl] .cd-timeline-img i {
  right: 40%;
  left: unset;
  margin-right: -12px;
  margin-left: unset;
}
html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: left;
  border-left: 7px solid #eeeeee;
  border-right: unset;
}
html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
  right: auto;
  left: 122%;
  text-align: left;
}
html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  right: auto;
  left: 100%;
  border: 15px solid transparent;
  border-left: 15px solid #f4f4f4;
}
html[dir=rtl] .cd-timeline-content .cd-date {
  right: 122%;
  left: unset;
}
html[dir=rtl] .introjs-tooltip {
  right: 0;
  left: unset;
}
html[dir=rtl] .introjs-helperNumberLayer {
  right: -16px;
  left: unset;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext {
  text-align: right;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons {
  text-align: left;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-skipbutton {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton {
  -webkit-border-radius: 0.2em 0 0 0.2em;
  -moz-border-radius: 0.2em 0 0 0.2em;
  border-radius: 0.2em 0 0 0.2em;
}
html[dir=rtl] .list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: unset;
}
html[dir=rtl] .like-comment ul.list-inline {
  padding-right: 0;
}
html[dir=rtl] .typeahead {
  text-align: right;
}
html[dir=rtl] .listing ul {
  padding-right: 0;
}
html[dir=rtl] .listing ul .icofont {
  float: right;
}
html[dir=rtl] .gallery .hover-5 img {
  margin-right: 30px;
}
html[dir=rtl] .gallery .hover-5:hover img {
  margin-right: 0;
}
html[dir=rtl] .lg-outer {
  text-align: right;
}
html[dir=rtl] .lg-toolbar .lg-icon {
  float: left;
}
html[dir=rtl] #lg-counter {
  padding-right: 20px;
  padding-left: unset;
  float: right;
}
html[dir=rtl] .chart-widget-top .text-end {
  text-align: left !important;
}
html[dir=rtl] .chart-widget-top .num .ms-1 {
  margin-right: 0.25rem !important;
}
html[dir=rtl] .bar-chart-widget .earning-details i {
  left: -240px;
}
html[dir=rtl] .bar-chart-widget .bottom-content .b-r-light {
  border-left: 1px solid #eeeeee !important;
  border-right: unset !important;
}
html[dir=rtl] .bar-chart-widget .bottom-content .num .ms-1 {
  margin-right: 0.25rem !important;
}
html[dir=rtl] .chart-vertical-center #myDoughnutGraph,
html[dir=rtl] .chart-vertical-center #myPolarGraph {
  width: auto !important;
}
html[dir=rtl] .crm-activity ul.dates li + li {
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 5px;
  border-left: unset;
  padding-left: unset;
  margin-left: unset;
}
html[dir=rtl] div.dt-buttons {
  float: right;
}
html[dir=rtl] .dt-button-collection {
  left: -39px !important;
}
html[dir=rtl] .dropzone .dz-preview .dz-error-mark,
html[dir=rtl] .dropzone .dz-preview .dz-success-mark {
  right: 50%;
  margin-right: -27px;
  margin-left: unset;
  left: unset;
}
html[dir=rtl] .page-wrapper .page-body-wrapper footer {
  margin-right: 246px;
  margin-left: unset;
}
html[dir=rtl] .page-wrapper .page-body-wrapper footer p i {
  margin-right: 5px;
  margin-left: unset;
}
html[dir=rtl] .footer-copyright {
  text-align: right;
}
html[dir=rtl] .footer-links {
  text-align: left;
}
html[dir=rtl] .page-item:first-child .page-link {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
html[dir=rtl] .page-item:last-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .popover.top > .arrow:after,
html[dir=rtl] .popover.bottom > .arrow:after {
  margin-right: -10px;
  margin-left: unset;
}
html[dir=rtl] .bootstrap-touchspin > .input-group-append > .btn,
html[dir=rtl] .bootstrap-touchspin > .input-group-append > .input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
html[dir=rtl] .bootstrap-touchspin > .form-control:not(:last-child),
html[dir=rtl] .bootstrap-touchspin > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .typeahead span.twitter-typeahead .tt-suggestion {
  text-align: right;
}
html[dir=rtl] .typeahead .league-name {
  text-align: right;
  margin-right: 5px;
}
html[dir=rtl] .follow .text-md-right {
  text-align: left !important;
}
html[dir=rtl] .jvectormap-zoomin,
html[dir=rtl] .jvectormap-zoomout,
html[dir=rtl] .jvectormap-goback {
  right: 10px;
  left: unset;
}
html[dir=rtl] .customizer-links {
  right: unset;
  left: 0;
}
html[dir=rtl] .customizer-links > .nav {
  padding-right: 10px;
}
html[dir=rtl] .customizer-links .nav-link:after {
  right: -10px;
  left: unset;
  border-width: 5px 5px 5px 0;
  border-left-color: unset;
  border-right-color: #a9c9fa;
}
html[dir=rtl] .customizer-links .nav-link span {
  right: unset;
  left: -60px;
}
html[dir=rtl] .customizer-links .nav-link:hover span {
  left: 48px;
}
html[dir=rtl] .customizer-links.open {
  right: unset;
  left: 330px;
  border-radius: 0 8px 8px 0;
}
html[dir=rtl] .customizer-contain {
  width: 330px;
  right: unset;
  left: -330px;
}
html[dir=rtl] .customizer-contain.open {
  right: unset;
  left: 0px;
}
html[dir=rtl] .customizer-contain .customizer-header .icon-close {
  left: unset;
  right: 30px;
}
html[dir=rtl] .box-layout.page-wrapper.horizontal-wrapper .page-header {
  padding-right: 250px;
  padding-left: unset;
}
html[dir=rtl] .box-layout.page-wrapper.horizontal-wrapper .page-header.open {
  padding-right: 0;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 230px;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
  width: 1280px;
  padding-left: 0;
  left: 50%;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-header {
  margin-left: auto;
  margin-right: auto;
  padding-left: unset;
  padding-right: 230px;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-header.close_icon {
  padding-right: 100px;
}
html[dir=rtl] body.dark-only .custom-card .card-footer > div + div {
  border-right: 1px solid #374558;
}
html[dir=rtl] body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(odd) .cd-timeline-content::before {
  border-left: none;
  border-right-color: #191f31;
}
html[dir=rtl] body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  border-left-color: #191f31;
  border-right: unset;
}
html[dir=rtl] body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-r-light,
html[dir=rtl] body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-right {
  border-right: none !important;
  border-left: 1px solid #374558 !important;
}
@media screen and (max-width: 1660px) {
  html[dir=rtl] .caller-img {
    left: unset;
    right: 15px;
  }
}
@media only screen and (max-width: 1366px) {
  html[dir=rtl] .profile-dropdown.onhover-show-div {
    right: unset !important;
    left: 0 !important;
  }
  html[dir=rtl] .product-wrapper .product-grid .feature-products {
    margin-right: 0;
  }
  html[dir=rtl] .product-grid {
    margin-right: 0;
  }
  html[dir=rtl] .d-none-productlist {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .photos ul li:nth-child(3n) {
    margin-left: 15px;
    margin-right: unset;
  }
  html[dir=rtl] .chat-left-aside .status-circle {
    left: unset;
    right: 22px;
  }
}
@media (min-width: 1200px) {
  html[dir=rtl] .float-xl-end {
    float: left !important;
  }
  html[dir=rtl] .chat-box .pe-xl-0 {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }
  html[dir=rtl] .chat-box .ps-xl-0 {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }
  html[dir=rtl] .pixelstrap ul a:hover .sub-arrow, html[dir=rtl] .pixelstrap ul a:focus .sub-arrow, html[dir=rtl] .pixelstrap ul a:active .sub-arrow, html[dir=rtl] .pixelstrap ul a.highlighted .sub-arrow {
    left: 3px;
    right: unset;
  }
}
@media (min-width: 992px) {
  html[dir=rtl] .text-lg-start {
    text-align: right !important;
  }
}
@media only screen and (max-width: 1199.98px) {
  html[dir=rtl] .jkanban-container .btn {
    margin-left: unset;
    margin-right: 20px;
  }
  html[dir=rtl] .link-section > div > h6:before {
    right: unset;
    left: 10px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li .submenu li:first-child {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 45px;
  }
  html[dir=rtl] .email-wrap .row .col-xl-6 {
    padding-right: 15px;
  }
  html[dir=rtl] .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-left: 15px;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li {
    text-align: right;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li a i {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar ul {
    padding-right: 0;
  }
  html[dir=rtl] .email-wrap .email-content .email-top .user-emailid:after {
    float: left;
  }
  html[dir=rtl] .email-wrap .email-right-aside .email-body .pe-0 {
    padding-right: unset !important;
  }
  html[dir=rtl] .email-wrap .email-right-aside .radius-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 4px;
  }
  html[dir=rtl] .chat-menu-icons {
    text-align: left;
  }
  html[dir=rtl] .chat-box .chat-history .row .col-sm-7 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  html[dir=rtl] .chat-menu {
    left: 0;
    right: unset;
  }
}
@media only screen and (max-width: 991.98px) {
  html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
    left: 20px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
    transform: translate(285px);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
    transform: translate(285px);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper .back-btn {
    float: left;
    transform: rotate(180deg);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    margin-right: 0;
    border-right: none;
    padding-right: 0;
    border-left: 1px solid #ddd;
    margin-left: 15px;
    padding-left: 15px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-header {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-header {
    padding-right: 0;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper .page-body {
    padding-right: 15px;
  }
  html[dir=rtl] .faq-accordion .faq-header svg {
    left: 20px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-logo-wrapper .logo-wrapper img {
    margin-left: 15px;
    margin-right: unset;
  }
  html[dir=rtl] .page-body-wrapper .page-body,
  html[dir=rtl] .page-body-wrapper footer {
    margin-right: 0 !important;
  }
  html[dir=rtl] .main .langChoice {
    left: 20px;
  }
  html[dir=rtl] .cd-timeline-img i {
    right: 50% !important;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .breadcrumb {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 60px);
  }
  html[dir=rtl] .page-wrapper .page-header {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-wrapper.close_icon {
    transform: translate(285px);
  }
  html[dir=rtl] .card-body .value-left {
    margin-left: 20px;
  }
  html[dir=rtl] .bookmark ul li .search-form .form-control-search {
    left: -127px;
  }
  html[dir=rtl] .card .card-header .card-header-right {
    left: 15px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  html[dir=rtl] .page-wrapper.compact-wrapper.material-type .page-body-wrapper div.sidebar-wrapper {
    left: unset;
    right: 0;
  }
}
@media (min-width: 768px) {
  html[dir=rtl] .float-md-end {
    float: left !important;
  }
  html[dir=rtl] .income-wrap .ps-md-0 {
    padding-left: 30px !important;
    padding-right: 0 !important;
  }
  html[dir=rtl] .text-md-end {
    text-align: left !important;
  }
  html[dir=rtl] .offset-md-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  html[dir=rtl] .text-md-start {
    text-align: right !important;
  }
  html[dir=rtl] .ecommerce-widget .text-md-end {
    text-align: left !important;
  }
  html[dir=rtl] .ecommerce-widget .text-md-end ul {
    text-align: left;
  }
}
@media only screen and (max-width: 767.98px) {
  html[dir=rtl] .list-persons .profile-mail .email-general ul {
    padding-left: 0;
  }
  html[dir=rtl] .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-right: 0;
  }
  html[dir=rtl] .product-sidebar {
    transform: translateX(300px) scaleX(1);
  }
  html[dir=rtl] .product-sidebar.open {
    transform: translateX(0px) scaleX(1);
  }
  html[dir=rtl] .feature-products .filter-toggle {
    margin-right: 10px;
    margin-left: unset;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 25px !important;
  }
  html[dir=rtl] .ecommerce-widget .text-md-right {
    text-align: left !important;
  }
  html[dir=rtl] .bar-chart-widget .earning-details i {
    left: -147px !important;
  }
}
@media (min-width: 576px) {
  html[dir=rtl] .offset-sm-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  html[dir=rtl] .text-sm-end {
    text-align: left !important;
  }
  html[dir=rtl] .float-sm-end {
    float: left !important;
  }
  html[dir=rtl] .me-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
}
@media only screen and (max-width: 575.98px) {
  html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
    left: 15px;
  }
  html[dir=rtl] .list-persons .nav-pills .nav-link .d-flex .flex-grow-1 {
    text-align: right;
  }
  html[dir=rtl] .mega-menu {
    padding-left: unset;
    padding-right: 15px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li {
    margin-right: unset;
    margin-left: 1px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    left: 0;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, html[dir=rtl] .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    margin-left: 8px;
    padding-left: 8px;
    margin-right: unset;
    padding-right: unset;
    border-right: unset;
    border-left: 1px solid #ddd;
  }
  html[dir=rtl] .translate_wrapper.active .more_lang.active {
    left: unset;
    right: 12px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:last-child {
    padding-right: 10px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul.notification-dropdown {
    left: unset !important;
    right: 15px !important;
  }
  html[dir=rtl] .faq-accordion .faq-header svg {
    left: 15px;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 15px !important;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons li {
    margin-left: 0px;
  }
  html[dir=rtl] .main .langChoice {
    left: 15px;
  }
  html[dir=rtl] .page-wrapper .search-form .form-group {
    margin-left: 0;
  }
  html[dir=rtl] .card-body .value-left {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 480px) {
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    right: unset;
    left: 20px;
  }
  html[dir=rtl] .knob-chart .chart-clock-main {
    width: unset;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-small {
    right: 32px;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-large {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-medium {
    right: 32px;
    left: unset;
  }
}
@media only screen and (max-width: 420px) {
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    right: 112px;
  }
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    right: 80px;
  }
}
@media only screen and (max-width: 360px) {
  html[dir=rtl] .prooduct-details-box .close {
    left: 0;
  }
  html[dir=rtl] .social-app-profile .hovercard .user-image .avatar {
    margin-top: -47px;
  }
  html[dir=rtl] .social-app-profile .hovercard .user-image .avatar img {
    width: 85px;
    height: 85px;
  }
  html[dir=rtl] .social-chat .other-msg {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1460px) {
  html[dir=rtl] .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    margin-left: 8px;
    margin-right: unset;
  }
}

/**=====================
    5.8 Rtl CSS Ends
==========================**/
/**=====================
  5.9 Box-layout CSS start
==========================**/
.box-layout {
  background-image: url(../images/other-images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.5);
}
@media only screen and (min-width: 1280px) {
  .box-layout .learning-filter {
    order: -1;
  }
  .box-layout .artist-table .appointment-table.customer-table table tr td:last-child {
    min-width: 100px;
  }
  .box-layout.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu {
    background-color: #191f31;
  }
  .box-layout .email-wrap .email-left-aside .email-app-sidebar .d-flex {
    display: block !important;
  }
  .box-layout .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email {
    width: 100%;
    text-align: center;
  }
  .box-layout .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .box-layout .email-wrap .email-left-aside .email-app-sidebar .d-flex .flex-grow-1 {
    text-align: center;
  }
  .box-layout .widget-1.mb-xl-0 {
    margin-bottom: 25px !important;
  }
  .box-layout .radial-progress-card .radial-chart-wrap {
    margin-right: -10px;
  }
  .box-layout .currency-widget div[class*=bg-light-] {
    display: none;
  }
  .box-layout .box-order .order-1 {
    order: 1 !important;
  }
  .box-layout .activity-card .appointment-table.customer-table {
    height: 187px;
  }
  .box-layout .transaction-table tr td:first-child {
    min-width: 150px;
  }
  .box-layout .welcome-card .welcome-img {
    height: 170px;
    bottom: -22px;
  }
  .box-layout .comment-box ul ul {
    margin-left: 0;
  }
  .box-layout .blog-page .blog-box.blog-shadow {
    height: 455px;
  }
  .box-layout .blog-page .blog-box.blog-shadow img {
    height: 455px;
  }
  .box-layout .blog-box .blog-details .blog-social {
    margin-top: 12px;
  }
  .box-layout .blog-box .blog-details .blog-social li:first-child {
    padding-right: 8px;
  }
  .box-layout .blog-box .blog-details .blog-social li + li {
    padding-left: 8px;
  }
  .box-layout .blog-box.blog-list .blog-details {
    margin: 10px;
  }
  .box-layout .blog-box .blog-details-main .blog-social li {
    padding: 0px 5px;
  }
  .box-layout .purchase-card img {
    margin: -210px auto 0;
    width: 80%;
  }
  .box-layout .purchase-card.discover {
    margin-top: 90px;
  }
  .box-layout .purchase-card.discover img {
    margin-top: -90px;
    width: 64%;
  }
  .box-layout .apache-cotainer-large > div,
  .box-layout .apache-cotainer-large canvas,
  .box-layout #line-visual > div,
  .box-layout #line-visual canvas,
  .box-layout #line-visulH > div,
  .box-layout #line-visulH canvas {
    width: 100% !important;
  }
  .box-layout .page-wrapper .page-header .header-wrapper .nav-right > ul > li:nth-child(2) {
    display: none;
  }
  .box-layout .page-wrapper .page-header .header-wrapper .serchinput {
    display: block !important;
  }
  .box-layout .page-wrapper.horizontal-wrapper .header-logo-wrapper {
    display: none;
  }
  .box-layout .left-header .left-menu-header ul.header-left li span.f-w-500 {
    padding: 6px 10px;
  }
  .box-layout .page-wrapper .page-header .header-wrapper {
    padding: 18px 30px;
  }
  .box-layout .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 6px 8px;
    margin-right: 8px;
  }
  .box-layout .page-wrapper.box-layout, .box-layout.page-wrapper.box-layout {
    padding-top: 40px;
    margin-top: 0 !important;
  }
  .box-layout .page-wrapper.compact-wrapper, .box-layout.page-wrapper.compact-wrapper {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper {
    width: 246px;
    top: 40px;
    border-radius: 10px 0 0 10px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
    width: 1280px;
    margin-left: 0px;
    padding-left: 100px !important;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .footer-fix, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .footer-fix {
    padding-left: 0;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: calc(100vh - 196px);
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper {
    box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
    border-radius: 10px 0 0 0;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    margin-top: 10px;
    margin-bottom: 20px;
    height: calc(100vh - 191px);
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 130px);
    margin-top: 0;
    top: 80px;
    overflow: scroll;
    max-height: calc(100vh - 220px);
    padding-bottom: 80px;
    margin-left: 246px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #EAEAEA;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
    width: 6px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(48, 126, 243, 0.15);
  }
  .box-layout .page-wrapper.compact-sidebar .page-header, .box-layout.page-wrapper.compact-sidebar .page-header {
    padding-left: 148px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
    top: 40px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
    display: none;
    transition: 0.5s;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 166px);
    margin: 0;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 470px;
    top: 40px;
    height: calc(100vh - 84px);
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
    animation: unset;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .footer, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .footer {
    margin-left: 0;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body {
    min-height: calc(100vh - 90px);
    margin-top: 0;
    top: 80px;
    overflow: scroll;
    max-height: calc(100vh - 170px);
    padding-bottom: 80px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #EAEAEA;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar {
    width: 6px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(48, 126, 243, 0.15);
  }
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-header, .box-layout.page-wrapper.compact-sidebar.compact-small .page-header {
    padding-left: 90px;
  }
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-header.close_icon, .box-layout.page-wrapper.compact-sidebar.compact-small .page-header.close_icon {
    padding-left: 0;
  }
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container, .box-layout.page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 410px;
  }
  .box-layout .page-wrapper.horizontal-wrapper:before, .box-layout.page-wrapper.horizontal-wrapper:before {
    display: none;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
    margin-top: 80px;
    min-height: calc(100vh - 310px);
    max-height: calc(100vh - 230px);
    overflow: scroll;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #EAEAEA;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
    width: 6px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(48, 126, 243, 0.15);
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .footer, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .footer {
    padding-left: 15px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
    width: 1280px;
    top: 72px;
    margin: 0;
    overflow-x: unset;
    position: relative;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu {
    left: 0%;
    transform: translate(0, 0);
    top: 125px;
    background-color: #fff;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-header, .box-layout.page-wrapper.horizontal-wrapper .page-header {
    padding-left: 0;
  }
  .box-layout .page-wrapper.modern-type .page-header, .box-layout.page-wrapper.modern-type .page-header {
    padding-left: 0px;
  }
  .box-layout .page-wrapper.modern-type .page-header .header-wrapper .left-header, .box-layout.page-wrapper.modern-type .page-header .header-wrapper .left-header {
    width: 36%;
  }
  .box-layout .page-wrapper.modern-type .page-header .header-wrapper .nav-right, .box-layout.page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: 46%;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .page-body, .box-layout.page-wrapper.modern-type .page-body-wrapper .page-body {
    margin-left: 260px;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper {
    top: 120px;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 311px);
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: calc(100vh - 316px);
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon ~ footer, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
    width: 1280px;
    left: 50%;
    margin-left: 0;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .footer, .box-layout.page-wrapper.modern-type .page-body-wrapper .footer {
    margin-left: 0;
  }
  .box-layout .page-wrapper .page-header, .box-layout.page-wrapper .page-header {
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    transition: 0.5s;
    padding-left: 246px;
    margin-top: 40px;
    border-radius: 10px;
    width: unset;
  }
  .box-layout .page-wrapper .page-header.close_icon, .box-layout.page-wrapper .page-header.close_icon {
    padding-left: 71px;
    margin-left: auto;
    width: calc(100% - 0px);
    transition: 0.5s;
  }
  .box-layout .page-wrapper .page-header .header-wrapper, .box-layout.page-wrapper .page-header .header-wrapper {
    border-radius: 0 10px 0 0;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right,
  .box-layout .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header, .box-layout.page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right,
  .box-layout.page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 36%;
  }
  .box-layout .page-wrapper.material-type .page-body-wrapper, .box-layout.page-wrapper.material-type .page-body-wrapper {
    margin-top: 0;
  }
  .box-layout .page-wrapper.material-type .page-body-wrapper .page-body, .box-layout.page-wrapper.material-type .page-body-wrapper .page-body {
    margin-top: 0;
  }
  .box-layout .page-wrapper.material-type .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
    top: 0;
  }
  .box-layout .page-wrapper.material-icon .page-header.close_icon, .box-layout.page-wrapper.material-icon .page-header.close_icon {
    margin-top: 40px !important;
  }
  .box-layout .page-wrapper.material-icon .page-body-wrapper, .box-layout.page-wrapper.material-icon .page-body-wrapper {
    margin-top: 0;
  }
  .box-layout .page-wrapper.material-icon .page-body-wrapper .page-body, .box-layout.page-wrapper.material-icon .page-body-wrapper .page-body {
    top: 0;
    min-height: calc(100vh - 170px);
    padding-bottom: 40px;
  }
  .box-layout .page-wrapper.advance-layout .page-header, .box-layout.page-wrapper.advance-layout .page-header {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  }
  .box-layout .page-wrapper.advance-layout .page-body-wrapper, .box-layout.page-wrapper.advance-layout .page-body-wrapper {
    margin-top: 0;
  }
  .box-layout .page-wrapper .page-body-wrapper, .box-layout.page-wrapper .page-body-wrapper {
    width: 1280px;
    box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
    margin: 0 auto;
    overflow: hidden;
  }
  .box-layout .page-wrapper .page-body-wrapper .todo .todo-list-wrapper #todo-list li .task-responsive, .box-layout.page-wrapper .page-body-wrapper .todo .todo-list-wrapper #todo-list li .task-responsive {
    min-width: 1087px;
    overflow: auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .active-order-table, .box-layout.page-wrapper .page-body-wrapper .active-order-table {
    max-width: 443px;
    overflow: auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .active-order-table table tbody tr td p, .box-layout.page-wrapper .page-body-wrapper .active-order-table table tbody tr td p {
    width: 100px;
  }
  .box-layout .page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.gradient-line-1:after, .box-layout.page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.gradient-line-1:after {
    height: 57px;
    bottom: -64px;
  }
  .box-layout .page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.small-line:after, .box-layout.page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.small-line:after {
    height: 36px;
    bottom: -43px;
  }
  .box-layout .page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.medium-line:after, .box-layout.page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.medium-line:after {
    height: 40px;
    bottom: -48px;
  }
  .box-layout .page-wrapper .page-body-wrapper footer, .box-layout.page-wrapper .page-body-wrapper footer {
    width: 1280px;
    margin: 0 auto;
    padding-left: 275px !important;
    margin-bottom: 47px !important;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 0 0 10px;
  }
  .box-layout .page-wrapper .page-body-wrapper .blockquote-footer, .box-layout.page-wrapper .page-body-wrapper .blockquote-footer {
    margin-left: 0;
    width: 100%;
  }
  .box-layout .page-wrapper .page-body-wrapper .footer-fix, .box-layout.page-wrapper .page-body-wrapper .footer-fix {
    padding-left: 230px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div {
    z-index: 8;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px;
    left: 15px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button {
    width: 40%;
    font-size: 14px;
    margin: 0 auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid #717171;
    width: 50px;
    height: 50px;
    padding: 8px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img {
    width: 38%;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2 {
    font-size: 28px;
    color: #717171;
  }
  .box-layout .page-wrapper .page-body-wrapper canvas#myLineCharts, .box-layout.page-wrapper .page-body-wrapper canvas#myLineCharts {
    width: 100%;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-right-aside, .box-layout.page-wrapper .page-body-wrapper .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }
  .box-layout .page-wrapper .page-body-wrapper .caller-img, .box-layout.page-wrapper .page-body-wrapper .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }
  .box-layout .page-wrapper .page-body-wrapper .caller-img img, .box-layout.page-wrapper .page-body-wrapper .caller-img img {
    opacity: 0.7;
  }
  .box-layout .page-wrapper .page-body-wrapper .browser-widget img, .box-layout.page-wrapper .page-body-wrapper .browser-widget img {
    height: 65px;
  }
  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-header img, .box-layout.page-wrapper .page-body-wrapper .custom-card .card-header img {
    margin-top: -73px;
  }
  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-profile img, .box-layout.page-wrapper .page-body-wrapper .custom-card .card-profile img {
    height: 130px;
    top: -17px;
  }
  .box-layout .page-wrapper .page-body-wrapper .select2, .box-layout.page-wrapper .page-body-wrapper .select2 {
    width: 901.781px;
  }
  .box-layout .page-wrapper ul.close_icon > li label, .box-layout.page-wrapper ul.close_icon > li label {
    padding: 0;
  }
  .box-layout .page-wrapper ul.close_icon > li label:after, .box-layout.page-wrapper ul.close_icon > li label:after {
    display: none;
  }
  .box-layout .page-wrapper .bookmark ul, .box-layout.page-wrapper .bookmark ul {
    margin-right: -1px;
  }
  .box-layout .page-wrapper #batchDelete .jsgrid-grid-header .jsgrid-table tr th .btn, .box-layout.page-wrapper #batchDelete .jsgrid-grid-header .jsgrid-table tr th .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .box-layout .page-wrapper .btn-group-showcase .btn-radio .btn-group .radio input[type=radio], .box-layout.page-wrapper .btn-group-showcase .btn-radio .btn-group .radio input[type=radio] {
    display: none;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph, .box-layout.page-wrapper .d-flex.bitcoin-graph {
    display: block;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .top-bitcoin, .box-layout.page-wrapper .d-flex.bitcoin-graph .top-bitcoin {
    display: inline-block;
    vertical-align: middle;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .flex-grow-1, .box-layout.page-wrapper .d-flex.bitcoin-graph .flex-grow-1 {
    margin-top: 30px;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content .bitcoin-numbers h6, .box-layout.page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content .bitcoin-numbers h6 {
    font-size: 14PX;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content.text-right, .box-layout.page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content.text-right {
    text-align: center !important;
  }
  .box-layout .page-wrapper .alert.inverse p, .box-layout.page-wrapper .alert.inverse p {
    max-width: 238px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .box-layout .page-wrapper .auth-bg-effect .second-effect, .box-layout.page-wrapper .auth-bg-effect .second-effect {
    left: 55%;
  }
  .box-layout .page-wrapper .auth-bg-video video, .box-layout.page-wrapper .auth-bg-video video {
    min-width: 67%;
    width: 67%;
  }
  .box-layout .page-wrapper .page-builder .ge-canvas.ge-layout-desktop, .box-layout.page-wrapper .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 40px;
  }
  .box-layout .page-wrapper .page-builder .ge-addRowGroup, .box-layout.page-wrapper .page-builder .ge-addRowGroup {
    margin-bottom: 10px;
  }
  .box-layout .page-wrapper .pricing-wrapper-card, .box-layout.page-wrapper .pricing-wrapper-card {
    padding: 50px 20px;
  }
  .box-layout .page-wrapper .card .blog-box.blog-grid.set-min-height, .box-layout.page-wrapper .card .blog-box.blog-grid.set-min-height {
    min-height: 400px;
  }
  .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily, .box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily {
    min-height: 430px;
  }
  .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris, .box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris {
    min-height: 430px;
  }
  .box-layout .page-wrapper .box-col-12, .box-layout.page-wrapper .box-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box-layout .page-wrapper .box-col-6, .box-layout.page-wrapper .box-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .box-layout .page-wrapper .box-col-3, .box-layout.page-wrapper .box-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .box-layout .page-wrapper .box-col-7, .box-layout.page-wrapper .box-col-7 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .box-layout .page-wrapper .box-col-5, .box-layout.page-wrapper .box-col-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .box-layout .page-wrapper .box-col-8, .box-layout.page-wrapper .box-col-8 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .box-layout .page-wrapper .box-col-4e, .box-layout.page-wrapper .box-col-4e {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .box-layout .page-wrapper .box-col-4, .box-layout.page-wrapper .box-col-4 {
    flex: 0 0 33.33%;
    max-width: 33%;
  }
  .box-layout .page-wrapper .box-col-8e, .box-layout.page-wrapper .box-col-8e {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .box-layout .page-wrapper .box-col-none, .box-layout.page-wrapper .box-col-none {
    display: none !important;
  }
  .box-layout .page-wrapper .chat-box .chat-right-aside, .box-layout.page-wrapper .chat-box .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .box-layout .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i, .box-layout.page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    font-size: 19px;
  }
  .box-layout .page-wrapper .chat-box .toogle-bar, .box-layout.page-wrapper .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important;
  }
  .box-layout .page-wrapper .chat-menu, .box-layout.page-wrapper .chat-menu {
    right: 0;
    border-top: 1px solid #ddd;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 81px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    transition: all linear 0.3s;
  }
  .box-layout .page-wrapper .chat-menu.show, .box-layout.page-wrapper .chat-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }
  .box-layout .page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar, .box-layout.page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar {
    stroke-width: 23px !important;
  }
  .box-layout .page-wrapper .social-app-profile #friends .box-col-4, .box-layout.page-wrapper .social-app-profile #friends .box-col-4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .box-layout .page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset, .box-layout.page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset {
    max-height: 165px;
  }
  .box-layout .page-wrapper .email-wrap .row .col-xl-6, .box-layout.page-wrapper .email-wrap .row .col-xl-6 {
    padding-left: 12px;
  }
  .box-layout .page-wrapper .email-wrap .row .col-xl-3 + .col-xl-3, .box-layout.page-wrapper .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 12px;
  }
  .box-layout .page-wrapper .email-wrap .email-right-aside .email-body .inbox, .box-layout.page-wrapper .email-wrap .email-right-aside .email-body .inbox {
    height: 644px;
  }
  .box-layout .page-wrapper .email-wrap .email-content .email-top .user-emailid:after, .box-layout.page-wrapper .email-wrap .email-content .email-top .user-emailid:after {
    right: -10px;
  }
  .box-layout .page-wrapper .todo .notification-popup, .box-layout.page-wrapper .todo .notification-popup {
    right: 320px;
  }
  .box-layout .page-wrapper .touchspin, .box-layout.page-wrapper .touchspin {
    padding: 0 10px;
  }
  .box-layout .page-wrapper .vertical-menu-main, .box-layout.page-wrapper .vertical-menu-main {
    width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .box-layout .page-wrapper .vertical-menu-main .mega-menu, .box-layout.page-wrapper .vertical-menu-main .mega-menu {
    width: 1050px !important;
    max-width: 1050px !important;
    left: -320px !important;
  }
  .box-layout .page-wrapper .comingsoon video, .box-layout.page-wrapper .comingsoon video {
    min-width: 67%;
    width: 67%;
  }
  .box-layout .default-dashboard .review-slider .review .review-content {
    margin: 20px 0;
  }
  .box-layout .default-dashboard .review-slider .review p {
    margin-top: 20px;
    font-size: 18px;
  }
  .box-layout .default-dashboard .sales .d-flex {
    margin-left: 55px;
  }
  .box-layout .default-dashboard .sales p {
    margin: 10px 0;
  }
  .box-layout .default-dashboard .welcome-card .card-body {
    height: 296px;
  }
  .box-layout .default-dashboard .welcome-card .card-body .d-flex {
    margin-top: 45px;
  }
  .box-layout .default-dashboard .news-update {
    margin-bottom: 0;
  }
  .box-layout .default-dashboard .left-background {
    margin-bottom: 30px;
  }
  .box-layout .default-dashboard .product-chart .shap-block .rounded-shap i {
    right: 38%;
    top: -53%;
  }
  .box-layout .proorder-xl-1 {
    order: 1;
  }
  .box-layout .proorder-xl-2 {
    order: 2;
  }
  .box-layout .proorder-xl-3 {
    order: 3;
  }
  .box-layout .proorder-xl-4 {
    order: 4;
  }
  .box-layout .proorder-xl-5 {
    order: 5;
  }
  .box-layout .proorder-xl-6 {
    order: 6;
  }
  .box-layout .proorder-xl-7 {
    order: 7;
  }
  .box-layout .proorder-xl-8 {
    order: 8;
  }
  .box-layout .proorder-xl-9 {
    order: 9;
  }
  .box-layout .proorder-xl-10 {
    order: 10;
  }
  .box-layout .proorder-xl-11 {
    order: 11;
  }
  .box-layout .proorder-xl-12 {
    order: 12;
  }
  .box-layout .proorder-xl-13 {
    order: 13;
  }
  .box-layout .pedding-start {
    padding-left: 0;
  }
  .box-layout .pedding-end {
    padding-right: 0;
  }
  .box-layout .margin-bottom {
    margin-bottom: 0 !important;
  }
  .box-layout .online-order {
    margin-bottom: 30px !important;
  }
  .box-layout .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: -20%;
  }
  [dir=rtl] .box-layout .project-dashboard .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: unset;
    margin-right: -20%;
  }
  .box-layout .project-dashboard .web-card .flex-shrink-0 .progress-value .progress {
    width: 120px;
  }
  .box-layout .project-dashboard .web-card .comment ul li {
    padding: 6px 12px;
  }
  .box-layout .project-dashboard .project-summary table thead tr th:nth-child(4) {
    display: none;
  }
  .box-layout .project-dashboard .project-summary table thead tr th:nth-child(5) {
    display: none;
  }
  .box-layout .project-dashboard .project-summary table tbody tr td:nth-child(4) {
    display: none;
  }
  .box-layout .project-dashboard .project-summary table tbody tr td:nth-child(5) {
    display: none;
  }
  .box-layout .project-dashboard .today-task .clients-project table tbody tr td .user-details.customers ul li + li {
    margin-left: -30% !important;
  }
  [dir=rtl] .box-layout .project-dashboard .today-task .clients-project table tbody tr td .user-details.customers ul li + li {
    margin-left: unset !important;
    margin-right: -30% !important;
  }
  .box-layout .project-dashboard .ps-20 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .box-layout .project-dashboard .ps-20 {
    padding-right: 0.5rem !important;
    padding-left: unset;
  }
  .box-layout .project-dashboard .pe-30 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .box-layout .project-dashboard .pe-30 {
    padding-left: 1rem !important;
    padding-right: unset;
  }
  .box-layout .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: -20%;
  }
  [dir=rtl] .box-layout .general-widget .web-card .flex-shrink-0 .user-details.customers ul li + li {
    margin-left: unset;
    margin-right: -20%;
  }
  .box-layout .general-widget .web-card .flex-shrink-0 .progress-value .progress {
    width: 120px;
  }
  .box-layout .general-widget .web-card .comment ul li {
    padding: 6px 10px;
  }
  .box-layout .general-widget .budget-card .budget-spent {
    display: none;
  }
  .box-layout .general-widget .offline-card {
    display: none;
  }
}

/**=====================
  5.9 Box-layout CSS Ends
==========================**/
/**=====================
     62. Responsive CSS Start
==========================**/
/* ========= min and max scss for 1200 to 1366 screen ========= */
@media (max-width: 1800px) {
  .todo-wrap .todo-list-body .task-container div.d-flex {
    min-width: 240px;
    justify-content: flex-end;
  }
  .caller-img {
    display: none;
  }
}
@media screen and (max-width: 1660px) {
  .photos ul li {
    width: 26%;
  }
  .bar-chart canvas {
    width: 100% !important;
  }
  .chat-box .chat-history .call-icons ul li {
    border: 1px solid #9B9B9B;
  }
  .chat-box .chat-history .total-time h2 {
    color: #9B9B9B;
  }
  .chat-box .chat-history .call-content .btn {
    width: auto;
    margin: 0 auto;
  }
  .chat-box .chat-history .call-content > div {
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 25px;
  }
  .chat-left-aside .people-list {
    height: 520px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 440px;
  }
  .chat-box .people-list ul {
    max-height: 450px;
    overflow: auto;
  }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 625px;
  }
  .call-chat-body .chat-box .people-list ul {
    max-height: 550px;
    overflow: auto;
  }
  .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }
  .caller-img img {
    opacity: 0.7;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -78px;
  }
}
@media screen and (max-width: 1600px) {
  .page-wrapper.horizontal-wrapper.material-type .page-header {
    max-width: 1320px;
  }
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .sidebar-wrapper,
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .page-body {
    max-width: 1320px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-right: 10px;
  }
}
@media (max-width: 1470px) {
  .basic_table .table-responsive .table tbody tr td:nth-child(n+2) {
    min-width: 180px;
  }
  .signal-table.table-responsive .table tbody tr td:nth-child(n+2) {
    min-width: 240px;
  }
}
@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .xl-none {
    display: none;
  }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%;
  }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .xl-30 {
    max-width: 30%;
    flex: 0 0 30%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}
@media (max-width: 1280px) {
  .img-gallery-set1,
  .img-gallery-set5 {
    display: none;
  }
  .box-layout.page-wrapper.horizontal-wrapper .page-header {
    padding-left: unset;
    max-width: unset;
    left: unset;
  }
  .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper {
    width: unset;
  }
}
@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons,
  .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .flot-chart-container-small {
    height: 227px;
  }
  .call-chat-sidebar {
    max-width: 262px;
  }
  .product-box .product-details {
    padding: 15px;
  }
  .product-box .product-details p {
    margin-bottom: 10px;
  }
  .file-manager .folder .folder-box p span {
    float: unset;
    display: block;
  }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px 10px;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0px 5px;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 10px;
  }
  .browser-widget .media-img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .browser-widget img {
    height: 50px;
  }
  .blog-page .blog-box.blog-shadow {
    height: 455px;
  }
  .blog-page .blog-box.blog-shadow img {
    height: 455px;
  }
  .blog-box .blog-details .blog-social {
    margin-top: 12px;
  }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 8px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 8px;
  }
  .blog-box.blog-list .blog-details {
    margin: 10px;
  }
  .flot-chart-container {
    height: 130px;
  }
  .custom-card .card-profile img {
    height: 115px;
  }
  .custom-card .profile-details h4 {
    font-size: 18px;
  }
  .custom-card .profile-details h6 {
    margin-bottom: 24px;
  }
  .custom-card .card-footer > div h3 {
    font-size: 18px;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex {
    display: block !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email {
    width: 100%;
    text-align: center;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .flex-grow-1 {
    text-align: center;
  }
  .email-wrap .email-right-aside .email-body .attachment ul li img {
    width: 73px;
    height: 73px;
  }
  .email-wrap .email-right-aside .email-body .email-top {
    line-height: 50px;
  }
  .email-wrap .email-right-aside .email-body .email-top .float-right {
    display: none !important;
  }
  .email-wrap .email-right-aside .email-body .email-top h5 {
    padding: 13px 0;
  }
  .email-wrap .email-right-aside .email-body .inbox {
    max-height: 668px;
    overflow: auto;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex {
    padding: 15px;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex .media-size-email .me-3 {
    margin-right: 10px !important;
  }
  .email-wrap .email-right-aside .email-body .inbox .flex-grow-1 h4 {
    font-size: 16px;
  }
  .email-wrap .email-right-aside .email-body .email-compose .cke_contents.cke_reset {
    max-height: 144px;
    width: 99%;
  }
  .email-wrap .email-right-aside .email-body .email-compose .compose-border {
    margin: 0;
  }
  .crm-activity {
    height: 291px;
    overflow-y: auto;
  }
  .crm-activity .d-flex .flex-grow-1 .dates {
    display: flex;
  }
  .chat-body {
    padding: 10px;
  }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
  }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .chat-box .chat-history .call-icons ul li {
    width: 50px;
    height: 50px;
    padding: 7px;
  }
  .chat-box .user-image {
    width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px;
  }
  .chat-box .about {
    margin-top: 0;
  }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #59667a !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 376px;
    overflow-y: auto;
  }
  .chat-box .chat-menu .user-profile .image .avatar img {
    width: 100px;
    height: 100px;
  }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .chat-left-aside .people-list {
    height: 420px;
  }
  .chat-left-aside .people-list ul {
    max-height: 376px;
    overflow-y: auto;
  }
  .chat-left-aside .status-circle {
    top: 4px;
    left: 36px;
  }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px 10px;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0px 10px;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 10px;
  }
  .browser-widget .media-img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .browser-widget img {
    height: 50px;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex {
    display: block;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email {
    width: 100%;
    text-align: center;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .flex-grow-1 {
    text-align: center;
  }
  .email-wrap .email-right-aside .email-body .attachment ul li img {
    width: 73px;
    height: 73px;
  }
  .email-wrap .email-right-aside .email-body .email-top {
    line-height: 50px;
  }
  .email-wrap .email-right-aside .email-body .email-top .float-right {
    display: none !important;
  }
  .email-wrap .email-right-aside .email-body .email-top h5 {
    padding: 13px 0;
  }
  .email-wrap .email-right-aside .email-body .inbox {
    max-height: 668px;
    overflow: auto;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex {
    display: block;
    text-align: center;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .email-wrap .email-right-aside .email-body .inbox .flex-grow-1 {
    text-align: start;
  }
  .email-wrap .email-right-aside .email-body .inbox .flex-grow-1 h6 {
    font-size: 14px;
  }
  .email-wrap .email-right-aside .email-body .email-compose .cke_contents.cke_reset {
    max-height: 144px;
    width: 99%;
  }
  .email-wrap .email-right-aside .email-body .email-compose .compose-border {
    margin: 0;
  }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 181px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .bookmark-wrap .task-sidebar {
    height: auto;
  }
  .btn-radio .btn-group .btn {
    padding: 4px 8px;
  }
  .grid-options ul li:last-child {
    display: none;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 25%;
  }
  .cart .qty-box {
    width: 40%;
  }
  .alert-dismissible strong ~ p {
    display: inline;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 95%;
  }
  .map-js-height {
    height: 350px;
  }
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }
  .chat-right-aside .chat .chat-message .smiley-box {
    width: 45px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 364px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
    margin-top: -30px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .message {
    width: 100%;
    padding: 15px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    font-size: 20px;
  }
  .chat-box .chat-history .receiver-img img {
    height: 150px;
  }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 565px;
  }
  .equal-height-xl {
    height: 517px;
  }
  .authentication-main .auth-innerright {
    background-size: cover;
  }
  .grp-btns {
    display: inline-block;
  }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%;
  }
  .page-builder .btn-group {
    display: inline-block;
    margin-bottom: 10px;
  }
  .page-builder .btn-grid {
    margin-bottom: 15px;
    margin-right: 0px;
    border-right: none;
    display: inline-block;
  }
  .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 50px;
  }
  .ecommerce-widget .total-num {
    font-size: 23px;
    letter-spacing: 0px;
  }
  .support-ticket-font .total-num {
    font-size: 22px;
  }
  .order-history table.dataTable.table thead th {
    padding-right: 50px;
  }
  .custom-card {
    padding: 15px;
  }
  .custom-card .card-social li a {
    padding: 12px;
  }
  .map-chart .chart-container {
    height: 300px;
  }
  .speed-chart .chart-container {
    height: 250px;
    margin-top: -40px;
    margin-bottom: 100px;
  }
  .candidcahrt {
    height: 360px;
  }
  .status-details h3 {
    font-size: 18px;
  }
  .chart-widget-top .total-value {
    font-size: 20px;
  }
  .status-widget .card-body {
    padding: 10px;
  }
  .browser-widget .d-flex {
    flex-wrap: wrap;
  }
  .browser-widget .d-flex .flex-grow-1 h3 {
    font-size: 20px;
  }
  .browser-widget .d-flex .flex-grow-1 p {
    margin-bottom: 2px;
  }
  .browser-widget .d-flex .flex-grow-1 h4 {
    font-size: 20px;
  }
  .testimonial i {
    font-size: 46px;
  }
  .testimonial p {
    margin-top: 20px;
  }
  .redial-social-widget {
    width: 125px;
    height: 125px;
  }
  .redial-social-widget i {
    width: 100px;
    height: 100px;
    font-size: 35px;
  }
  .chat-body {
    padding: 10px;
  }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
  }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .chat-box .chat-history .call-icons ul li {
    width: 50px;
    height: 50px;
    padding: 7px;
  }
  .chat-box .user-image {
    width: 45px;
    height: 45px;
  }
  .chat-box .about {
    margin-top: 0;
  }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #59667a !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 455px;
    overflow-y: auto;
  }
  .chat-box .chat-menu .user-profile {
    margin-top: 0;
  }
  .chat-box .chat-menu .user-profile .image .avatar img {
    width: 100px;
    height: 100px;
  }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .chat-box .chat-menu .user-profile .user-content p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .chat-box .chat-menu .user-profile .user-content h5 {
    margin: 20px 0;
  }
  .chat-box .chat-menu .user-profile .user-content hr {
    margin: 20px 0;
  }
  .chat-box .chat-menu .user-profile .follow .follow-num {
    font-size: 20px;
  }
  .chat-left-aside .people-list {
    height: 490px;
  }
  .chat-left-aside .people-list ul {
    max-height: 434px;
    overflow-y: auto;
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }
  .mega-menu .onhover-show-div {
    left: 164px;
  }
  .img-cropper .docs-toggles {
    margin-top: 8px;
  }
  .bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
    left: -21px;
  }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 8px;
  }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 67px !important;
  }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 67px !important;
  }
  .reset-password-box .theme-form .form-group .btn {
    padding: 6px 22px;
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1200px) {
  .page-wrapper.horizontal-wrapper.material-type .page-header {
    max-width: 1140px;
  }
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .sidebar-wrapper,
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .page-body {
    max-width: 1140px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .md-sidebar .md-sidebar-toggle {
    display: block;
  }
  .md-sidebar .md-sidebar-aside {
    position: absolute;
    top: 40px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    width: 280px;
  }
  [dir=rtl] .md-sidebar .md-sidebar-aside {
    left: unset;
    right: 0;
  }
  .md-sidebar .md-sidebar-aside.open {
    opacity: 1;
    visibility: visible;
  }
  .md-sidebar .job-sidebar {
    background-color: rgb(240, 240, 240);
    padding: 20px;
  }
  .md-sidebar .job-sidebar .faq-accordion.default-according {
    margin-bottom: 0;
  }
  .md-sidebar .job-sidebar .faq-accordion.default-according .row > div:last-child .card {
    margin-bottom: 0;
  }
  .learning-block > div:first-child {
    order: 2;
  }
  .file-sidebar {
    padding-right: 12px;
  }
  [dir=rtl] .file-sidebar {
    padding-right: 0;
  }
  .col-xl-7.b-center.bg-size {
    top: 0;
    height: 100vh;
    opacity: 0.5;
    position: fixed;
  }
  .login-card {
    background: transparent;
  }
  .knowledgebase-bg {
    height: 400px;
  }
  .knowledgebase-search .form-inline {
    width: 400px;
    padding: 7px 45px;
  }
  .knowledgebase-search .form-inline svg {
    top: 14px;
  }
  .btn-group .btn {
    padding: 0.375rem 1.25rem;
  }
  .link-section > div.active > h6:before {
    content: "\f107";
    transition: content 0.3s ease;
  }
  .link-section > div > h6 {
    position: relative;
  }
  .link-section > div > h6:before {
    content: "\f105";
    font-family: fontAwesome;
    position: absolute;
    right: 10px;
    font-size: 18px;
    transition: content 0.3s ease;
  }
  .alert-theme {
    min-width: 420px;
  }
  .mega-menu-header .header-wrapper .vertical-mobile-sidebar {
    display: block;
    margin-right: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right {
    position: unset;
    padding: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul li .submenu li:first-child {
    padding-left: 15px !important;
  }
  .page-wrapper .page-header .header-wrapper .mobile-sidebar .switch-sm .switch {
    margin-top: 6px;
  }
  .photos ul li {
    width: auto;
  }
  .photos ul li:nth-child(3n) {
    margin-right: 15px;
  }
  .user-profile .hovercard .user-image .share-icons {
    position: absolute;
    right: 45px;
    top: -22px;
  }
  .user-profile .hovercard .user-image .share-icons li {
    color: #fff;
    font-size: 25px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 40px;
    height: 40px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon i {
    font-size: 18px;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    height: 33px;
    bottom: -52px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    height: 18px;
    bottom: -34px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    height: 30px;
    bottom: -52px;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 100%;
  }
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 32%;
  }
  .debit-card {
    order: 1;
  }
  .typography .row div + div {
    margin-top: 30px;
  }
  .listing .card-body .row > div + div {
    margin-top: 30px;
  }
  .flot-chart-container {
    height: 200px;
  }
  .img-cropper #putData {
    margin-bottom: 10px;
  }
  .img-cropper .docs-toggles > .dropdown {
    margin-bottom: 0;
  }
  .error-wrapper .maintenance-icons li i {
    color: #F3F3F3;
    font-size: 40px;
  }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 80px;
    margin-left: 90px;
  }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 120px;
    margin-top: -75px;
    margin-right: 110px;
  }
  div.dataTables_wrapper div.dataTables_paginate ~ .dataTables_filter {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .jvector-map-height {
    height: 280px;
  }
  .blog-list .blog-details p {
    margin-bottom: 5px;
  }
  .custom-card .card-profile .card-footer > div h3 {
    font-size: 18px;
  }
  .equal-height-lg {
    min-height: unset !important;
  }
  .mega-menu .onhover-show-div {
    height: 435px;
    overflow-x: scroll;
    left: 215px;
  }
  .mega-menu .mega-bg {
    display: none;
  }
  .mega-menu .lg-mt {
    margin-top: 25px;
  }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%;
  }
  .blog-single .comment-box ul .comment-social {
    margin-left: 0;
  }
  [dir=rtl] .blog-single .comment-box ul .comment-social {
    margin-left: unset;
    margin-right: 0;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 30px;
  }
  .error-wrapper .maintenance-heading .headline {
    font-size: 50px;
    margin-top: -15%;
    margin-bottom: 0px;
  }
  .error-wrapper .maintenance-heading .cloud-second {
    margin-top: -25px;
  }
  .testimonial p {
    margin-top: 17px;
  }
  .browser-widget {
    padding: 0px;
  }
  .browser-widget img {
    height: 50px;
  }
  .browser-widget .d-flex .flex-grow-1 h4 {
    font-size: 17px;
  }
  .browser-widget .d-flex .media-img {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .crm-overall .overall-chart {
    height: 200px;
  }
  .product-table table.dataTable thead > tr > th {
    padding-right: 50px;
  }
  .chat-menu-icons {
    display: block;
    clear: both;
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid #EAEAEA;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 60px;
    position: absolute !important;
    z-index: 3;
    background-color: #fff;
    transition: all linear 0.3s;
  }
  .chat-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 444px;
    overflow-y: auto;
  }
  .chat-box .btn-lg {
    font-size: 12px;
  }
  .chat-box .people-list ul {
    height: auto;
  }
  .chat-box .chat-right-aside .chat .chat-header {
    height: auto;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    font-size: 19px;
  }
  .chat-box .chat-right-aside .chat .chat-message {
    margin: 0;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 25px;
  }
  .chat-box .chat-history .row .col-sm-7 {
    padding-right: 0;
  }
  .chat-box .border-tab.nav-tabs {
    width: 100%;
  }
  .chat-box .border-tab.nav-tabs .nav-item {
    margin-right: 5px;
  }
  .chat-box .chat-left-aside .people-list {
    height: 493px;
  }
  .chat-box .chat-history .call-content > div {
    padding-top: 70px;
  }
  .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important;
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body .chat-box {
    overflow: auto;
    height: auto;
  }
  .email-wrap .email-right-aside .email-body .b-r-light {
    border-right: none !important;
  }
  .email-wrap .email-right-aside .radius-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 15px;
  }
  .email-wrap .row .col-xl-6 {
    padding-left: 15px;
  }
  .icon-lists div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 209px;
  }
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  .ribbon-space-bottom {
    top: 9px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 98.5% !important;
  }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 95px !important;
  }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 95px !important;
  }
  .profile-img-style #aniimated-thumbnials-3 {
    text-align: center;
  }
  .vertical-menu-mt {
    margin-top: 80px !important;
  }
  .vertical-menu-mt .xl-none {
    display: block;
  }
  .calendar-wrap .fc-day-grid-event {
    width: 87%;
  }
  .product-page-main .product-page-details h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper.horizontal-wrapper.material-type .page-header {
    max-width: 930px;
  }
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .sidebar-wrapper,
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .page-body {
    max-width: 930px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-left: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .todo-list-wrapper #todo-list li .task-container .task-label {
    min-width: 460px;
  }
  .project-cards .tab-content {
    margin-bottom: -20px;
  }
  .project-box {
    margin-bottom: 20px;
  }
  .page-wrapper.modern-type .simplebar-mask {
    top: 70px;
  }
  .page-wrapper.compact-sidebar .simplebar-mask {
    top: 87px;
  }
  .page-wrapper.compact-sidebar .page-header {
    margin-left: 0;
    width: calc(100% - 0px);
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links li .sidebar-link.active ~ .sidebar-submenu {
    display: none !important;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper .page-body {
    margin-top: 60px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .search-full .Typeahead-menu {
    top: 65px;
  }
  .page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
    position: fixed !important;
    top: 0 !important;
  }
  .product-box .modal .modal-header .product-box .product-details {
    padding-left: 15px;
    padding-right: 15px;
  }
  .search-form .form-group:before {
    top: 33px;
  }
  .search-form .form-group:after {
    top: 35px;
  }
  .mobile-title.d-none {
    padding: 11px 30px 10px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    top: 50px;
  }
  .page-wrapper .page-header .header-wrapper .search-full input {
    line-height: 50px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    top: 20px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
    top: 18px;
  }
  .page-wrapper .current_lang .lang span.lang-txt {
    display: none;
  }
  .customizer-contain {
    top: 62px;
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .card.full-card {
    top: 60px;
    width: calc(100vw - 0px);
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery figure {
    margin-bottom: 20px;
  }
  #aniimated-thumbnials figure:nth-child(12), #aniimated-thumbnials figure:nth-child(11), #aniimated-thumbnials figure:nth-child(10), #aniimated-thumbnials figure:nth-child(9) {
    margin-bottom: 20px;
  }
  .photoswipe-pb-responsive {
    padding-bottom: 25px !important;
  }
  table.dataTable.fixedHeader-locked, table.dataTable.fixedHeader-floating {
    left: 0 !important;
    width: 100% !important;
  }
  .page-wrapper .section-space {
    padding: 60px 0;
  }
  .page-wrapper .card .card-header .tab-content .m-t-30,
  .page-wrapper .card .card-body .tab-content .m-t-30,
  .page-wrapper .card .card-footer .tab-content .m-t-30 {
    margin-top: 20px !important;
  }
  .page-wrapper .card .card-header .tab-content .m-b-30,
  .page-wrapper .card .card-body .tab-content .m-b-30,
  .page-wrapper .card .card-footer .tab-content .m-b-30 {
    margin-bottom: 20px !important;
  }
  .page-wrapper .page-body-wrapper .footer-fix {
    margin-left: 0px;
    padding-right: 15px;
    width: calc(100% - 0px) !important;
  }
  .page-wrapper.compact-wrapper .page-header {
    margin-left: 0 !important;
    width: calc(100% - 0px) !important;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .maximize {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .header-logo-wrapper {
    display: flex;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    display: block !important;
    width: 40px;
    height: 40px;
    background-color: rgba(48, 126, 243, 0.2);
    position: unset;
    border-radius: 10px;
    margin-right: 10px;
  }
  /*Form Wizard One start*/
  /*Form Wizard One ends*/
  /*Form Wizard Three Start*/
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  /*Form Wizard Four ends*/
}
@media only screen and (max-width: 991.98px) and (max-width: 630px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    width: 35px;
    height: 35px;
  }
}
@media only screen and (max-width: 991.98px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg {
    width: 20px;
    height: 20px;
    transform: translate(50%, -50%);
    font-size: 18px;
    position: absolute;
    top: 50%;
    stroke: #363636;
  }
  [dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg {
    left: 15px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 575px) {
  [dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg {
    left: 7px;
  }
}
@media only screen and (max-width: 991.98px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar:before {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-nav {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
    top: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
    transform: translate(-285px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper {
    padding: 17px 30px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper .back-btn {
    display: inline-block;
    float: right;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 95px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
    transform: translate(-285px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: unset;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-top: 60px;
    padding: 0 15px;
  }
  .page-wrapper.compact-sidebar .page-header .header-wrapper .maximize {
    display: none;
  }
  .page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
    display: none;
    margin-right: 0;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
    margin: 0 !important;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-wrapper {
    display: block;
  }
  .page-wrapper.material-icon .page-header {
    padding-left: 0;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-body {
    padding-left: 15px;
  }
  .page-wrapper .page-header .header-wrapper {
    margin: 0;
    padding: 12px 30px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification {
    top: 10px;
  }
  .page-wrapper .page-header .header-wrapper > .mobile-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .page-wrapper .page-header .header-wrapper .header-logo-wrapper {
    margin-right: 0;
  }
  .page-wrapper .page-header .header-logo-wrapper .logo-wrapper img {
    margin-top: 0;
  }
  .page-body-wrapper .page-body,
  .page-body-wrapper footer {
    margin-left: 0 !important;
  }
  .page-body-wrapper .page-body #customer-review .owl-stage-outer,
  .page-body-wrapper footer #customer-review .owl-stage-outer {
    width: 100% !important;
  }
  .default-according .card {
    margin-bottom: 10px !important;
  }
  .default-according .card .card-header {
    padding: 0 !important;
  }
  .faq-wrap .faq-accordion.default-according .card .faq-header {
    padding: 20px 20px 0 !important;
  }
  .crm-activity > li + li {
    margin-top: 10px;
    padding-top: 10px;
  }
  .add-post .dropzone {
    margin-bottom: 20px;
  }
  .customers.avatar-group {
    margin-right: 20px;
  }
  .socialprofile .social-btngroup {
    margin: 20px 0;
  }
  .socialprofile .social-group {
    margin-top: 20px;
  }
  .social-status form .form-group .form-control-social {
    margin-bottom: 20px;
  }
  .social-status .d-flex {
    margin-bottom: 20px;
  }
  .timeline-content p,
  .timeline-content .comments-box {
    margin-top: 20px;
  }
  .social-chat {
    margin-top: 20px;
  }
  .social-chat .flex-grow-1 {
    padding: 20px;
  }
  .social-chat .your-msg,
  .social-chat .other-msg {
    margin-bottom: 20px;
  }
  .social-network span {
    margin-bottom: 20px;
  }
  .details-about + .details-about {
    margin-top: 20px;
  }
  .activity-log .my-activity + .my-activity {
    margin-top: 20px;
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  .timeline-small .d-flex {
    margin-bottom: 20px;
  }
  .timeline-small .d-flex .flex-grow-1 h6 {
    margin-bottom: 20px;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 75%;
  }
  .timeline-small .d-flex .timeline-round {
    width: 45px;
    height: 45px;
    top: -14px;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -41px;
    height: 25px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    bottom: -26px;
    height: 14px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -33px;
    height: 17px;
  }
  .timeline-small .d-flex .timeline-round svg {
    top: 14px;
  }
  .helper-classes {
    padding: 20px;
  }
  .floated-customizer-btn,
  .floated-customizer-panel {
    display: none;
  }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 20px;
  }
  .ecommerce-widget .progress-showcase {
    margin-top: 20px;
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options ul li:nth-child(3) {
    display: none;
  }
  .feature-products form .form-group i {
    right: 20px;
  }
  .tilt-showcase .tilt-image {
    text-align: center;
  }
  .tilt-showcase .mt-4 {
    margin-top: 20px !important;
  }
  .todo .todo-list-wrapper .mark-all-tasks {
    top: 20px;
    right: 20px;
  }
  .custom-card {
    padding-bottom: 0;
  }
  .custom-card .card-footer {
    padding: 0 15px 15px 15px !important;
  }
  .custom-card .card-header {
    border: none;
  }
  .custom-card .dashboard-card {
    padding: 0;
  }
  .custom-card .card-profile img {
    height: 155px;
  }
  .display-1 {
    font-size: calc(30px + 34 * (100vw - 320px) / 880);
  }
  .display-2 {
    font-size: calc(25px + 31 * (100vw - 320px) / 880);
  }
  .display-3 {
    font-size: calc(20px + 20 * (100vw - 320px) / 880);
  }
  .display-4 {
    font-size: calc(15px + 9 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h1 {
    font-size: calc(16px + 18 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h2 {
    font-size: calc(18px + 12 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h3 {
    font-size: calc(17px + 5 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h4 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h4 {
    font-size: calc(15px + 5 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h5 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h5 {
    font-size: calc(13px + 5 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h6 {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h6 {
    font-size: calc(12px + 3 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  h1 {
    font-size: calc(20px + 14 * (100vw - 320px) / 880);
  }
  h2 {
    font-size: calc(18px + 10 * (100vw - 320px) / 880);
  }
  h3 {
    font-size: calc(17px + 9 * (100vw - 320px) / 880);
  }
  h4 {
    font-size: calc(17px + 5 * (100vw - 320px) / 880);
  }
  .card-absolute .card-header {
    padding: 10px 15px !important;
  }
  .comingsoon .comingsoon-inner .countdown .time {
    width: 80px;
    height: 80px;
    font-size: 26px;
  }
  .calendar-wrap .basic-calendar .external-events .fc-event + .fc-event {
    margin-top: 10px;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-bottom: -10px;
    margin-top: 15px;
  }
  .card .card-header h5 {
    font-size: 18px;
  }
  .card .card-header .card-header-right {
    top: 12px;
    right: 15px;
  }
  .page-builder .ge-addRowGroup {
    width: 100%;
  }
  .user-profile .hovercard .info .user-designation {
    margin-bottom: 20px;
  }
  .search-list {
    justify-content: center;
  }
  .blog-single .comment-box .comment-social li {
    padding-left: 15px;
  }
  .error-wrapper {
    padding: 35px 0;
  }
  .error-wrapper .sub-content {
    font-size: 14px;
    line-height: 25px;
    margin-top: 0px;
  }
  .error-wrapper .error-heading {
    margin-top: 30px;
  }
  .error-wrapper .error-heading .headline {
    font-size: 180px;
    margin-top: 0;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 30px;
  }
  .editor_container .CodeMirror {
    min-height: 250px;
    height: 250px;
  }
  .search-page .info-block {
    flex-wrap: wrap;
  }
  .search-page .tab-content .pb-4 {
    padding-bottom: 20px !important;
  }
  .xl-none {
    display: block;
  }
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  .form-wizard .btn-mb {
    margin-bottom: 20px;
  }
  .f1 .f1-steps {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .wizard-4 ul.anchor {
    padding-right: 20px;
    width: 40%;
  }
  .wizard-4 ul.anchor li a.done h4:before {
    top: -8px;
  }
  .wizard-4 ul.anchor li h4 {
    padding: 11px 18px;
    width: 50px;
  }
  .wizard-4 ul.anchor li + li a {
    padding-left: 65px;
  }
  [dir=rtl] .wizard-4 ul.anchor li + li a {
    padding-right: 65px;
  }
  .wizard-4 .step-container {
    width: 60%;
  }
  .wizard-4 .step-container div.content .wizard-title {
    width: 380px;
  }
  .wizard-4 .login-card .login-main {
    width: 380px;
  }
  .wizard-4 .msg-box {
    top: 25px !important;
  }
  .border-tab.nav-tabs {
    margin-bottom: 20px;
  }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 20px;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px;
  }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 20px !important;
  }
  .search-page .info-block + .info-block {
    padding-top: 20px;
    margin-top: 20px;
  }
  .search-page #video-links .embed-responsive + .embed-responsive {
    margin-top: 20px;
  }
  .lg-mt {
    margin-top: 20px;
  }
  .gallery-with-description {
    margin-bottom: -20px;
  }
  .gallery-with-description a > div {
    margin-bottom: 20px;
  }
  .clipboaard-container p {
    margin-bottom: 8px;
  }
  .m-b-30 {
    margin-bottom: 20px !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar ul li hr {
    margin: 13px 0;
  }
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
  }
  .navs-icon .main-section {
    padding-top: 20px;
  }
  .navs-icon .separator {
    margin: 20px 0;
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown button {
    margin-top: 20px;
  }
  .navs-dropdown .onhover-show-div {
    top: 68px;
  }
  .animated-modal .form-group {
    width: 100%;
    display: block;
  }
  .animated-modal .mr-4 {
    margin-right: 0 !important;
  }
  .steps-md-mt {
    margin-top: 20px;
  }
  .btn-group-wrapper .m-b-30:last-child {
    margin-bottom: 0 !important;
  }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 20px;
  }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 20px;
  }
  .chat-box .chat-left-aside .people-list {
    height: 483px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-right: 5px;
  }
  .card .card-body form.animated-modal {
    display: flex;
  }
  .card .card-body form.animated-modal .animated-modal-md-mb {
    margin-bottom: 20px !important;
  }
  .wizard-4 .msg-box {
    top: 40px;
    right: 0%;
    bottom: unset;
    left: unset;
  }
  .theme-form.form-inline {
    display: flex;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .page-wrapper.compact-wrapper.material-type::before {
    height: 230px;
  }
  .page-wrapper.compact-wrapper.material-type .page-header {
    width: calc(100% - 60px) !important;
    margin: 50px auto 0 !important;
  }
  .page-wrapper.compact-wrapper.material-type .page-body-wrapper {
    margin: 0 30px;
  }
  .page-wrapper.compact-wrapper.material-type .page-body-wrapper div.sidebar-wrapper {
    left: 0;
  }
  .page-wrapper.compact-wrapper.material-type .page-body-wrapper .page-body {
    margin-top: 0;
  }
}
@media only screen and (max-width: 810px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li .profile-media .flex-grow-1 {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  .wizard-4 ul.anchor {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .wizard-4 .step-container {
    width: 100%;
  }
  .contact-editform {
    padding-left: 20px !important;
  }
  .event-calendar {
    display: block !important;
  }
  .event-calendar #right {
    width: 100%;
  }
  .event-calendar #calendar {
    clear: both;
  }
  .file-content .d-md-flex {
    text-align: center;
  }
  .file-content .d-md-flex .flex-grow-1 {
    margin-top: 15px;
    text-align: center !important;
  }
  .page-wrapper.material-type::before {
    display: none;
  }
  .page-wrapper.material-type .page-header {
    margin-top: 0;
    border-radius: 0;
  }
  .page-wrapper.material-type .page-body-wrapper .page-body {
    margin-top: 0 !important;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right > ul > li {
    margin-right: -2px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right .nav-menus {
    margin-right: -10px;
  }
  .new-products .owl-carousel .product-box.row > div {
    width: auto;
  }
  .profile-media .flex-grow-1 {
    display: none;
  }
  .form-inline .form-group:not(.form-control-search) {
    width: 100%;
  }
  .form-inline .form-group:not(.form-control-search) + .form-group {
    margin-top: 10px;
  }
  .form-inline .form-group:not(.form-control-search) input {
    width: 100%;
  }
  .credit-card .text-center img {
    margin-bottom: 20px;
  }
  .starter-kit-fix .page-body {
    margin-bottom: 78px;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 12px;
  }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 25px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 25px;
  }
  .footer-copyright ~ div {
    text-align: center;
  }
  .footer-copyright ~ div .heart {
    text-align: center;
    margin-top: 5px;
  }
  .user-profile .hovercard .user-image .share-icons {
    right: 25px;
    top: -19px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 35px;
    height: 35px;
  }
  .tabs-scoial.border-tab.nav-tabs {
    display: flex;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 100%;
  }
  .my-gallery figure:nth-child(9), .my-gallery figure:nth-child(10) {
    margin-bottom: 20px;
  }
  .project-list .btn {
    float: left;
    margin-top: 5px;
  }
  [dir=rtl] .project-list .btn {
    float: right;
    margin-right: unset;
    margin-left: 5px;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    font-size: 0;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn:before {
    position: absolute;
    content: "\e661";
    font-family: themify;
    font-size: 18px;
    top: 11px;
    left: 20px;
  }
  .chat-box .border-tab.nav-tabs {
    display: flex;
  }
  .chat-box .chat-menu .nav-tabs .nav-item a {
    height: 50px;
  }
  .chat-box .chat-history .call-content > div {
    padding-top: 40px;
    height: auto;
  }
  .chat-box .chat-history .receiver-img {
    margin-bottom: unset;
  }
  .chat-box .chat-left-aside .people-list {
    height: auto;
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
  }
  .call-chat-sidebar .people-list {
    height: auto;
  }
  .chat-left-aside .people-list ul {
    max-height: 100px;
  }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
  }
  .clockpicker-align-top .arrow {
    display: none;
  }
  .form-builder-column .form-body {
    min-height: 250px;
  }
  .form-builder-2-header {
    flex-wrap: wrap;
  }
  .form-builder-2-header > div {
    width: 100%;
    margin-top: 10px;
  }
  .form-builder-2-header > div nav {
    float: none;
  }
  .lg-mt-col {
    margin-top: 20px;
  }
  .form-builder .drag-box fieldset {
    padding: 20px;
    margin-top: 20px;
  }
  .form-builder #components {
    margin-top: 20px;
  }
  .form-builder .component label {
    padding-top: 0px;
  }
  .mega-menu .onhover-show-div {
    height: 415px;
    left: 0;
    top: 61px;
    padding: 20px;
  }
  .mega-menu .lg-mt {
    margin-top: 15px;
  }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px 0 0 10px;
  }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer > a {
    padding: 0 5px;
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider.theme-form .form-group {
    margin-bottom: 0;
  }
  .note-editor .btn-group .btn {
    font-size: 11px;
  }
  .text-md-right {
    text-align: right;
  }
  .star-ratings .stars {
    padding: 0;
  }
  .form-inline.d-inline-block {
    display: block !important;
    width: 100%;
  }
  .form-inline.d-inline-block .form-control {
    margin-bottom: 10px;
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
  }
  .reset-password-box .card {
    padding: 20px;
  }
  .custom-card .card-profile img {
    height: 136px;
    bottom: 0;
  }
  .error-wrapper .maintenance-icons li i {
    color: #F3F3F3;
    font-size: 30px;
  }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 60px;
    margin-top: -10px;
    margin-left: 70px;
  }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 90px;
    margin-right: 80px;
  }
  .authentication-main .auth-innerright {
    min-height: unset;
    padding: 0;
  }
  .authentication-main .auth-innerright .social-media li {
    font-size: 14px;
  }
  .loader-box {
    justify-content: center;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
  div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
  }
  div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
    float: none;
  }
  div.dataTables_wrapper div.dataTables_paginate {
    float: none;
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
  .calendar-wrap .fc-basic-view .fc-day-number {
    padding: 0 0px !important;
  }
  .calendar-wrap .fc-events-container {
    margin-top: 0px;
  }
  .calendar-wrap .fc-events-container h6,
  .calendar-wrap .fc-events-container .checkbox {
    text-align: right;
  }
  .calendar-wrap .checkbox label {
    margin-top: 0;
  }
  .calendar-wrap .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after {
    font-size: 12px !important;
  }
  .calendar-wrap .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after {
    font-size: 12px !important;
  }
  .calendar-wrap .fc-toolbar .fc-left .fc-today-button,
  .calendar-wrap .fc-toolbar .fc-right .fc-today-button {
    padding-top: 0px !important;
  }
  .calendar-wrap .fc {
    margin-top: 0px;
  }
  .calendar-wrap .fc button {
    font-size: 14px !important;
    padding: 0 5px !important;
  }
  .calendar-wrap .fc .fc-left {
    margin-bottom: 10px;
  }
  .calendar-wrap .fc .fc-toolbar > div {
    display: block !important;
    float: none !important;
  }
  .calendar-wrap .fc .fc-toolbar > * :first-child {
    font-size: 18px;
  }
  .calendar-wrap .fc .fc-toolbar > * > * {
    float: none !important;
  }
  .btn-group-wrapper {
    text-align: center;
  }
  .btn-group-wrapper .m-b-30 {
    margin-bottom: 15px !important;
  }
  .btn-group-showcase {
    text-align: center;
  }
  .btn-group-showcase .btn-group {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .btn-group .btn {
    font-size: 14px;
    padding: 0.375rem 1.3rem;
  }
  .animate-widget .text-center {
    display: none;
  }
  .mega-menu .onhover-show-div {
    height: 350px;
  }
  .page-builder .ge-canvas .column {
    padding: 0 !important;
  }
  .page-builder .ge-content-type-ckeditor h3 {
    font-size: 20px;
  }
  .page-builder .ge-content-type-ckeditor p {
    font-size: 12px;
  }
  .page-builder .ge-row-icon {
    display: none !important;
  }
  /* font-awesom icon page */
  .icon-hover-bottom .form-group input {
    min-width: 270px;
  }
  .user-profile .hovercard .cardheader {
    height: 350px;
  }
  .user-profile .hovercard .info {
    padding: 35px;
  }
  .user-profile .hovercard .info .ttl-info {
    margin-bottom: 20px;
  }
  .user-profile .hovercard .info .ttl-sm-mb-0 {
    margin-bottom: 0;
  }
  .calendar-wrap .fc button {
    padding: 5px;
  }
  .calendar-wrap .fc-day-grid-event {
    margin: 0;
    padding: 5px;
    width: inherit;
  }
  .calendar-wrap .fc-toolbar h2 {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold;
  }
  .calendar-wrap .basic-calendar .external-events {
    margin-top: 0px;
  }
  .blog-single .comment-box .d-flex h6 {
    margin-bottom: 10px;
  }
  .blog-single .comment-box .d-flex img {
    margin-right: 30px;
  }
  .blog-single .comment-box .comment-social {
    margin-bottom: 5px;
  }
  .blog-single .comment-box .comment-social li:first-child {
    padding-left: 0;
    padding-right: 15px;
  }
  .search-page #image-links .info-block.m-t-30 {
    margin-top: 0 !important;
  }
  .authentication-main .authentication-box {
    width: 100%;
  }
  .authentication-main .auth-innerright {
    display: inherit;
  }
  .map-chart .chart-container {
    height: 300px;
  }
  .candidcahrt {
    height: 250px;
  }
  .bar-chart-widget .earning-details i {
    right: 0;
  }
  .serial-chart .chart-container {
    height: 300px;
  }
  .bottom-content {
    padding: 10px;
  }
  .bottom-content p {
    font-size: 12px;
  }
  .icon-hover-bottom .icon-popup {
    display: block;
  }
  .icon-hover-bottom .icon-popup > .icon-last {
    display: block;
    margin-top: 15px;
  }
  .icon-hover-bottom .icon-popup > .icon-last .form-inline .form-group {
    display: flex;
  }
  .icon-hover-bottom .icon-popup div .flag-icon {
    width: 30px;
    height: auto;
  }
  .icon-hover-bottom .form-group input {
    display: block;
    width: auto !important;
  }
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text {
    text-align: left !important;
  }
  [dir=rtl] .sm-left-text {
    text-align: right !important;
  }
  .email-wrap .email-right-aside .email-content .email-top .d-flex {
    padding-bottom: 0;
  }
  .email-wrap .email-right-aside .email-content .email-top .float-right {
    float: left !important;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment {
    text-align: center;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li:last-child {
    padding-right: 0;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 140px;
    height: 140px;
  }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px;
  }
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  .card-block .table-responsive .table tbody tr td span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
  .card-block .table-responsive .progress-content tbody tr td span {
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 50px;
  }
  .card-block .table-responsive .checkbox-td-width tbody tr td,
  .card-block .table-responsive .radio-first-col-width tbody tr td {
    min-width: 200px !important;
  }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 119px !important;
  }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 119px !important;
  }
  .jsgrid .jsgrid-pager-container {
    text-align: center;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: auto;
  }
  .chat-box .chat-menu .nav-tabs .nav-item {
    width: 32.33%;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 433px;
    margin-bottom: 15px;
  }
  .chat-box .chat-menu #info-profile,
  .chat-box .chat-menu #info-contact {
    margin-bottom: 15px;
  }
  .hovercard .info .follow .text-md-right {
    text-align: center;
  }
  .profile-img-style .like-comment-sm-mb {
    margin-bottom: 20px;
  }
  .reset-password-box .theme-form .form-group .btn {
    margin-top: 15px !important;
  }
  .lg-outer .lg-actions .lg-next,
  .lg-outer .lg-actions .lg-prev {
    top: 48%;
  }
  .feature-products .filter-toggle {
    padding: 8px 15px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 5px;
  }
  .feature-products .select2-drpdwn-product {
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
  [dir=rtl] .feature-products .select2-drpdwn-product {
    float: right;
    margin-right: unset;
    margin-left: 10px;
  }
  .feature-products span.f-w-600 {
    padding-top: 15px;
    display: inline-block;
  }
  .feature-products .text-right {
    text-align: left !important;
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  .grid-options ul li {
    display: none;
  }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 3;
    width: 300px;
  }
  .product-sidebar.open {
    transform: translateX(0px) scaleX(1);
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    visibility: visible;
    opacity: 1;
    height: auto;
    top: -45px;
  }
  .product-sidebar.open:before, .product-sidebar.open:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    right: 120px;
    top: -7px;
  }
  .product-sidebar .filter-section .card-header {
    display: none;
  }
  .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-left: 0;
  }
  .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
    top: unset;
    min-width: unset;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 42%;
  }
  .product-filter .product-box {
    flex-wrap: nowrap;
  }
  .product-filter .product-box .product-img {
    width: 100px;
  }
  .product-filter .product-box .product-details {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 575.98px) {
  .todo .todo-list-wrapper .mark-all-tasks .mark-all-tasks-container {
    display: none;
  }
  .border-tab.nav-tabs {
    display: block;
  }
  .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 15px 5px 0 !important;
  }
  .page-wrapper .page-header .header-wrapper .search-full input {
    padding-left: 30px;
    line-height: 40px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group::before {
    top: 14px;
    left: 12px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    top: 18px;
    right: 0;
    width: 18px;
    height: 18px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right {
    right: 30px;
  }
  .date-picker .theme-form > .row > div:last-child {
    margin-top: 0;
  }
  .search-page .info-block {
    display: block;
  }
  .form-bookmark .row > div .row > .col-sm-6 + .col-sm-6 {
    margin-top: 10px;
  }
  .btn {
    padding: 6px 16px;
  }
  .alert-theme {
    min-width: 380px;
  }
  .knowledgebase-bg {
    height: 250px;
  }
  .login-card .login-main .theme-form .or:before {
    width: 55%;
  }
  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.7);
  }
  .knowledgebase-search .form-inline {
    width: 90%;
    padding: 3px 45px;
  }
  .knowledgebase-search .form-inline svg {
    top: 10px;
  }
  .knowledgebase-search > div {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-right: 10px;
  }
  .knowledgebase-search > div h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .knowledgebase-search > div .form-group {
    margin-bottom: 0;
  }
  .invoice .text-md-end {
    margin-top: 10px;
  }
  .invoice .text-right {
    text-align: right;
  }
  .invoice .text-xs-center {
    text-align: center;
  }
  .login-card {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .login-card .logo {
    margin-bottom: 10px;
  }
  .login-card .login-main {
    width: auto;
    padding: 20px;
  }
  .login-card .login-main .theme-form .form-group {
    margin-bottom: 5px;
  }
  .login-card .login-main .theme-form p {
    margin-bottom: 5px;
  }
  .login-card .btn-showcase .btn {
    width: 100%;
  }
  .login-card .btn-showcase .btn + .btn {
    margin-left: 0;
    margin-top: 5px;
  }
  .bookmark ul li a svg {
    height: 16px;
    width: 16px;
  }
  .bookmark ul li + li {
    margin-left: 5px;
  }
  .page-header .header-wrapper .nav-right .cart-box .badge,
  .page-header .header-wrapper .nav-right .notification-box .badge {
    font-size: 10px;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child {
    display: block;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child h3 {
    padding-right: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right ul li i {
    font-size: 14px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right ul li svg {
    width: 18px;
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb {
    margin-top: 8px;
    justify-content: flex-start;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper, .page-wrapper.compact-sidebar .page-header .header-wrapper .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    border-right: 1px solid #ddd;
    padding-right: 8px;
    margin-right: 8px;
  }
  /* alert responsive css start here */
}
@media only screen and (max-width: 575.98px) and (max-width: 630px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    margin-right: 4px;
  }
}
@media only screen and (max-width: 575.98px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg, .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar svg {
    width: 18px;
    stroke: #363636;
  }
  .page-wrapper.compact-sidebar .sidebar-wrapper .back-btn {
    display: none;
  }
  .page-wrapper.compact-sidebar .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    margin-right: 0;
  }
  .page-wrapper .page-header .header-wrapper {
    padding: 12px 15px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul {
    top: 58px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul .search-form i {
    display: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li.px-0 {
    padding: 6px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul li .flag-icon {
    font-size: 14px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul li .profile-media img {
    width: 25px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown.onhover-show-div:before, .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown.onhover-show-div:after {
    left: 24px !important;
    right: unset !important;
  }
  .page-wrapper .page-body-wrapper .page-title .btn-group .btn {
    padding: 8px 30px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-top: 53px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 79px);
  }
  .alert-dismissible {
    padding-right: 30px;
  }
  .alert-dismissible p {
    max-width: 102px;
  }
  .customizer-links {
    display: none;
  }
  .m-r-30 {
    margin-right: 15px;
  }
  .wishlist .text-end {
    text-align: left !important;
  }
  .add-post form .m-checkbox-inline label {
    margin-right: 15px;
  }
  .add-post .dropzone {
    margin-bottom: 15px;
  }
  .wizard-4 .msg-box {
    top: 10px !important;
  }
  .wizard-4 .login-card .login-main {
    width: 280px;
  }
  .wizard-4 .step-container div.content .wizard-title {
    width: 280px;
  }
  .wizard-4 .action-bar {
    right: 10px;
  }
  .wizard-4 .action-bar [dir=rtl] {
    left: 10px;
  }
  .customers.avatar-group {
    margin-right: 15px;
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  .alert-theme i {
    margin-right: 10px !important;
  }
  .alert-theme button {
    top: 0px !important;
  }
  .bar-chart-widget .earning-details i {
    right: -30px;
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery figure {
    margin-bottom: 15px;
  }
  .my-gallery figure:nth-child(9), .my-gallery figure:nth-child(10) {
    margin-bottom: 15px;
  }
  .my-gallery.gallery-with-description figure:last-child {
    margin-bottom: 20px;
  }
  #aniimated-thumbnials figure:nth-child(12), #aniimated-thumbnials figure:nth-child(11), #aniimated-thumbnials figure:nth-child(10), #aniimated-thumbnials figure:nth-child(9) {
    margin-bottom: 15px;
  }
  .photoswipe-pb-responsive {
    padding-bottom: 30px !important;
  }
  .avatar-showcase .pepole-knows {
    text-align: center;
  }
  .avatar-showcase .pepole-knows ul li {
    margin-right: 14px;
  }
  .social-app-profile .hovercard .user-image .avatar {
    margin-top: -56px;
  }
  .social-app-profile .hovercard .user-image .avatar img {
    width: 100px;
    height: 100px;
  }
  .social-app-profile .hovercard .user-image .icon-wrapper {
    left: 52%;
    top: 10px;
    height: 30px;
    width: 30px;
    font-size: 15px;
  }
  .social-app-profile .hovercard .user-image .icon-wrapper:hover {
    font-size: 12px;
  }
  .social-app-profile .hovercard .user-image .share-icons {
    right: 15px;
  }
  .social-app-profile .hovercard .user-image .share-icons li {
    margin-right: 0px;
  }
  .social-app-profile .hovercard .info .tabs-scoial .user-designation {
    border-top: none;
    border-bottom: none;
    margin-bottom: 0;
    padding: 0px 0px;
  }
  .social-app-profile .tabs-scoial {
    margin-bottom: 0 !important;
  }
  .socialprofile .social-btngroup {
    margin: 15px 0;
  }
  .socialprofile .social-group {
    margin-top: 15px;
  }
  .social-status form .form-group .form-control-social {
    margin-bottom: 15px;
  }
  .social-status .d-flex {
    margin-bottom: 15px;
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .tabs-scoial {
    position: relative;
    padding-top: 80px;
  }
  .tabs-scoial li:nth-child(3) {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .tabs-scoial.border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px;
    height: 60px;
  }
  .timeline-content p,
  .timeline-content .comments-box {
    margin-top: 15px;
  }
  .timeline-content .comments-box .d-flex .m-r-20 {
    margin-right: 10px;
  }
  .timeline-content .comments-box .d-flex .flex-grow-1 .input-group .input-group-append .btn {
    padding: 6px;
  }
  .timeline-content .comment-number i {
    margin-right: 15px;
  }
  #cd-timeline {
    margin-right: 0;
  }
  .social-chat {
    margin-top: 15px;
  }
  .social-chat .flex-grow-1 {
    padding: 15px;
  }
  .social-chat .your-msg,
  .social-chat .other-msg {
    margin-bottom: 15px;
  }
  .social-network span {
    margin-bottom: 15px;
  }
  .social-list .d-flex {
    margin-bottom: 15px;
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header h5 span.pull-right {
    float: right !important;
    margin-top: 0 !important;
  }
  .social-header h5 span.pull-right svg {
    width: 18px;
    height: 18px;
  }
  .activity-log .my-activity + .my-activity {
    margin-top: 15px;
  }
  .photos ul li {
    width: 26%;
  }
  .photos ul li:nth-child(3n) {
    margin-right: 0;
  }
  .chat-box .chat-history .call-content > div {
    padding-top: 30px;
  }
  .timeline-small .d-flex {
    margin-bottom: 15px;
  }
  .timeline-small .d-flex .flex-grow-1 h6 {
    margin-bottom: 15px;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -39px;
    height: 30px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    bottom: -17px;
    height: 8px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -30px;
    height: 22px;
  }
  .helper-classes {
    padding: 15px;
  }
  .form-builder-2-header ul li {
    text-align: left;
  }
  .form-builder-2-header .form-inline {
    display: block;
    width: 100%;
  }
  .form-builder-2-header .form-inline .form-group {
    width: 100%;
    margin-right: 0;
  }
  .form-builder-2-header .form-inline button {
    width: 100%;
    text-align: left;
  }
  .page-builder .btn-grid {
    margin-bottom: 7px;
    padding-right: 5px;
  }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 15px;
  }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px;
  }
  .page-builder .ge-canvas.ge-layout-desktop [class*=col-] {
    width: inherit !important;
  }
  .wizard-4 .step-container div.content {
    margin-top: 10px;
  }
  .nav-tabs .nav-item.show .nav-link {
    border-top: 0;
    border-bottom: 0;
  }
  .nav-tabs .nav-link {
    border-top: 0;
    border-bottom: 0;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus {
    border-top: 0;
    border-bottom: 0;
  }
  .border-tab.nav-tabs .nav-item {
    width: 100% !important;
  }
  .border-tab .ecommerce-widget .progress-showcase {
    margin-top: 15px;
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  .editor_container .CodeMirror {
    min-height: 190px;
    height: 190px;
  }
  .order-history table.dataTable.table thead th {
    padding-right: 60px;
  }
  .border-tab.nav-left .nav-link {
    text-align: center;
    border-left: none;
  }
  .border-tab.nav-right .nav-link {
    text-align: center;
    border-right: none;
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 10px;
  }
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-color li {
    width: 15px;
    height: 15px;
  }
  .product-social li a {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .product-social li:nth-child(n+2) {
    margin-left: 5px;
  }
  [dir=rtl] .product-social li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 5px;
  }
  .product-page-main {
    padding: 15px;
  }
  .product-page-main .product-page-details h3 {
    font-size: 24px;
  }
  .todo .action-box.large {
    height: 25px;
    width: 25px;
  }
  .todo .action-box.large .icon {
    font-size: 14px;
    vertical-align: -3px;
  }
  .todo .todo-list-wrapper #todo-list li .task-container .task-label {
    font-size: 14px;
  }
  .todo .todo-list-wrapper .mark-all-tasks {
    right: 15px;
  }
  .datetime-picker label {
    text-align: left !important;
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker .text-right {
    text-align: left !important;
  }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 15px !important;
  }
  ul.pagination li:before {
    line-height: 3;
  }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
    padding-left: 0 !important;
  }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
    padding-right: 0 !important;
  }
  div.dataTables_wrapper div.dataTables_length {
    margin-bottom: 15px;
  }
  div.dataTables_wrapper div.dataTables_length select {
    min-height: 30px;
  }
  div.dataTables_wrapper table.dataTable th,
  div.dataTables_wrapper table.dataTable td {
    padding: 0.5rem;
  }
  div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 15px !important;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button {
    padding: 1px 7px;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous {
    font-size: 0;
    padding: 0;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous a {
    border: none !important;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous:before {
    content: "\e64a";
    font-family: themify;
    font-size: 11px;
    padding: 0 5px;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.next {
    font-size: 0;
    padding: 0;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.next a {
    border: none !important;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.next:before {
    content: "\e649";
    font-family: themify;
    font-size: 11px;
    padding: 0 5px;
  }
  div.dataTables_wrapper .dataTables_filter {
    margin-bottom: 15px;
  }
  div.dataTables_wrapper .dataTables_filter input[type=search] {
    height: 30px;
  }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style {
    padding: 15px;
  }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style .img-container {
    margin-top: 15px;
  }
  .page-wrapper .page-body-wrapper .user-profile hr {
    margin: 15px 0;
  }
  .page-wrapper .page-body-wrapper .user-profile .like-comment {
    margin-top: 15px;
  }
  .jvector-map-height {
    height: 250px;
  }
  .user-profile .order-sm-0 {
    order: -1;
  }
  .user-profile .hovercard .info .ttl-info {
    text-align: center !important;
  }
  .user-profile .hovercard .info .ttl-xs-mt {
    margin-top: 20px;
  }
  .icon-hover-bottom .form-group {
    display: block !important;
    margin-bottom: 0;
  }
  .icon-hover-bottom .form-group input {
    width: 100% !important;
  }
  .icon-hover-bottom .form-group .btn {
    margin-top: 15px;
  }
  .icon-hover-bottom .icon-popup {
    padding: 15px;
  }
  .icon-hover-bottom svg.climacon {
    height: 50px;
    width: 50px;
  }
  .blog-box.blog-shadow:before {
    box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
  }
  .blog-box .blog-details p,
  .blog-box .blog-details .single-blog-content-top {
    margin-top: 15px;
  }
  .blog-box .blog-details h4 {
    margin-top: 15px;
  }
  .blog-box .blog-details .blog-social {
    margin-top: 15px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 20px;
  }
  .blog-box .blog-details .blog-social li + li > span {
    display: none;
  }
  .blog-box .blog-details .blog-social li:first-child {
    margin-right: 10px;
    padding-right: 20px;
  }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .comment-box ul ul {
    margin-left: 30px;
  }
  .comment-box li .d-flex {
    display: block;
  }
  .comment-box li .d-flex img {
    height: 50px;
    width: 50px;
    padding: 3px;
    margin-bottom: 10px;
  }
  .ace-editor {
    height: 320px;
  }
  .gallery > a {
    margin-bottom: 15px;
    text-align: center;
  }
  .card .card-header h5 {
    font-size: 17px;
  }
  .theme-form .form-group {
    margin-bottom: 15px;
  }
  .page-wrapper .page-body-wrapper .default-according .card .card-header,
  .page-wrapper .page-body-wrapper .default-according .card .card-body,
  .page-wrapper .page-body-wrapper .default-according .card .card-footer {
    padding: 0.75rem 1.25rem;
  }
  .page-wrapper .page-body-wrapper .card {
    margin-bottom: 20px;
  }
  .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-t-30,
  .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-t-30,
  .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-t-30 {
    margin-top: 20px !important;
  }
  .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-b-30,
  .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-b-30,
  .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-b-30 {
    margin-bottom: 20px !important;
  }
  .page-wrapper .page-body-wrapper .page-title {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .page-wrapper .page-body-wrapper .page-title .row h3 {
    font-size: 20px;
  }
  .alert-primary.inverse {
    background-color: transparent;
  }
  .alert-secondary.inverse {
    background-color: transparent;
  }
  .alert-success.inverse {
    background-color: transparent;
  }
  .alert-danger.inverse {
    background-color: transparent;
  }
  .alert-warning.inverse {
    background-color: transparent;
  }
  .alert-info.inverse {
    background-color: transparent;
  }
  .alert-light.inverse {
    background-color: transparent;
  }
  .alert-dark.inverse {
    background-color: transparent;
  }
  .alert .close {
    height: 100%;
  }
  .user-profile hr {
    margin: 15px 0;
  }
  .user-profile .hovercard .cardheader {
    height: 300px;
  }
  .user-profile .hovercard .info {
    padding: 20px;
  }
  .user-profile .hovercard .info .user-designation {
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    margin-bottom: 15px;
    padding: 15px 0px;
  }
  .user-profile .hovercard .social-media a {
    margin-right: 0px;
    font-size: 16px;
  }
  .user-profile .hovercard .follow .follow-num {
    font-size: 20px;
  }
  .user-profile .profile-img-style .pictures img {
    margin-bottom: 10px;
  }
  .custom-card .card-footer > div h6 {
    font-size: 14px;
    font-weight: 600;
  }
  .custom-card .card-footer > div h3 {
    font-size: 24px;
  }
  .calendar-wrap .fc-toolbar .fc-center {
    margin-top: 10px;
  }
  .calendar-wrap a.fc-more {
    font-size: 0.7em;
  }
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 15px;
  }
  .blog-box.blog-shadow .blog-details p {
    margin-bottom: 0;
  }
  .blog-box.blog-shadow .blog-details h4 {
    margin-bottom: 10px;
  }
  .blog-box.blog-list .blog-details {
    padding-top: 0;
  }
  .blog-box .blog-details {
    padding: 20px;
  }
  .blog-box .blog-date span {
    font-size: 25px;
  }
  .blog-single .blog-box .blog-details {
    padding: 0;
  }
  .error-wrapper {
    padding: 20px 0;
  }
  .error-wrapper .btn {
    margin-top: 15px;
  }
  .error-wrapper .img-100 {
    width: 50px !important;
  }
  .error-wrapper .error-heading {
    margin-top: 20px;
  }
  .error-wrapper .error-heading .cloud-second {
    margin-top: -60px;
  }
  .error-wrapper .error-heading .headline {
    font-size: 150px;
  }
  .error-wrapper .maintenance-heading .cloud-second {
    display: none;
  }
  .error-wrapper .maintenance-heading .headline {
    font-size: 25px;
    margin-top: -10%;
    letter-spacing: 2px;
  }
  .comingsoon .comingsoon-inner .coming-soon-bottom-link {
    margin-top: 20px;
    line-height: 1;
  }
  .comingsoon .comingsoon-inner h5 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .comingsoon .comingsoon-inner .countdown {
    padding: 20px 0px;
  }
  .comingsoon .comingsoon-inner .countdown ul li {
    margin: 0 7px;
  }
  .comingsoon .comingsoon-inner .countdown .title {
    font-size: 12px;
  }
  .comingsoon .comingsoon-inner .countdown .time {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    margin: 0 auto;
  }
  .stepwizard {
    margin-bottom: 15px;
  }
  .f1 .f1-steps {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .wizard-4 ul.anchor {
    width: 100%;
    padding-bottom: 15px;
  }
  .wizard-4 .step-container {
    width: 100%;
  }
  .status-widget .text-sm-right {
    text-align: right;
  }
  .mega-menu {
    padding-left: 15px;
  }
  .mega-menu .onhover-show-div {
    height: 405px;
    left: 0;
    top: 61px;
    padding: 15px;
  }
  .mega-menu .lg-mt {
    margin-top: 10px;
  }
  .mega-menu .xs-mt {
    margin-top: 15px;
  }
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
  }
  .navs-icon .main-section {
    padding-top: 15px;
  }
  .navs-icon .separator {
    margin: 15px 0;
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown button {
    margin-top: 15px;
  }
  .navs-dropdown .onhover-show-div {
    top: 64px;
  }
  .lg-mt {
    margin-top: 15px;
  }
  #aniimated-thumbnials a:last-child img {
    margin-bottom: 15px;
  }
  .tilt-showcase .mt-4 {
    margin-top: 15px !important;
  }
  .tilt-showcase ol .m-b-20 {
    margin-bottom: 15px !important;
  }
  .xs-mt {
    margin-top: 15px;
  }
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  .animated-modal .form-group .form-control {
    width: 50%;
    margin: 0 auto;
  }
  .animated-modal .animated-modal-md-mb {
    margin-bottom: 15px !important;
  }
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important;
  }
  #API-chield-row_wrapper #API-chield-row tbody tr td:first-child {
    min-width: 20px;
  }
  .chart-block .flot-chart-container p#choices {
    width: 100%;
  }
  .chart-block .flot-chart-container #toggling-series-flot {
    width: 100% !important;
  }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 15px;
  }
  .ecommerce-widget .progress-showcase {
    margin-top: 15px;
  }
  .reset-password-box .theme-form .form-group .btn {
    margin-top: 13px !important;
  }
  .gallery-with-description a > div {
    margin-bottom: 15px;
  }
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  .authentication-main {
    padding: 30px 15px;
  }
  .translate_wrapper.active .more_lang:before, .translate_wrapper.active .more_lang:after {
    display: none;
  }
  .translate_wrapper.active .more_lang.active {
    position: fixed;
    width: calc(100vw - 30px);
    left: 15px;
    top: 60px;
  }
  .onhover-dropdown:hover .onhover-show-div {
    opacity: 0;
    transform: none;
    visibility: hidden;
  }
  .onhover-dropdown:hover .onhover-show-div.active {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    overflow: hidden;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: calc(100vw - 30px);
    position: fixed;
    top: 60px;
    right: 12px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    width: calc(100vw - 30px);
    position: fixed;
    top: 60px;
    left: 15px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown {
    left: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    top: 43px;
  }
  .wizard-4 .action-bar .btn {
    margin: 0 2px;
  }
  .dropdown-basic .btn-group {
    margin-right: unset;
  }
}
@media only screen and (max-width: 480px) {
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: 280px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right {
    display: none;
  }
  .chat-menu {
    top: 120px;
  }
  .product-wrapper-grid.list-view .product-box {
    display: block;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 100%;
  }
  .pagination-lg .page-link {
    font-size: 1rem;
  }
  h6 {
    font-size: 14px;
  }
  .prooduct-details-box .flex-grow-1 {
    margin-left: 0rem !important;
  }
  .knob-block .chart-clock-main {
    margin: auto;
    width: auto;
  }
  .knob-block .chart-clock-main .clock-medium {
    position: absolute;
    left: 33px;
    top: 33px;
  }
  .knob-block .chart-clock-main .clock-medium canvas {
    width: 180px !important;
    height: 180px !important;
  }
  .knob-block .chart-clock-main .clock-small {
    left: 34px;
    top: 74px;
  }
  .knob-block .chart-clock-main .clock-large {
    left: 0;
    top: 0;
    margin: 0 auto;
  }
  .knob-block .chart-clock-main .clock-large canvas {
    width: 245px !important;
    height: 245px !important;
  }
  .knob-block > div:not(.chart-clock-main) canvas {
    width: 245px !important;
    height: 245px !important;
  }
  .wizard-4 .action-bar .btn {
    width: 100%;
  }
  .wizard-4 .action-bar .btn + .btn {
    margin-top: 10px;
  }
  ul.notification-dropdown.onhover-show-div {
    width: 260px;
    right: -80px;
  }
  ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
    right: 89px !important;
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg .page-link {
    padding: 0.3rem 0.65rem;
  }
  .nav-pills li {
    width: 100%;
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom .icon-first {
    margin-right: 10px;
  }
  .icon-hover-bottom .icon-popup .icon-class {
    display: none;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 0;
  }
  .custom-card {
    padding: 0;
  }
  .custom-card .card-footer > div h3 {
    font-size: 20px;
  }
  .custom-card .card-social li {
    padding: 5px 0;
  }
  .custom-card .profile-details h6 {
    margin-bottom: 15px;
  }
  .custom-card .profile-details h4 {
    font-size: 20px;
  }
  .custom-card .card-profile img {
    height: 93px;
  }
  .card .card-header .card-header-right i {
    margin: 0 3px;
    font-size: 14px;
  }
  .card .card-header > span + span {
    display: none;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -50px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -40px;
  }
  .dropdown-basic {
    text-align: center;
  }
  .dropup-basic {
    text-align: center;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 10px;
  }
  .blog-box.blog-shadow .blog-details h4 {
    font-size: 14px;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 83px;
    height: 83px;
  }
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px;
  }
  .mega-menu .onhover-show-div {
    height: 405px;
    left: 0;
    top: 61px;
    padding: 15px;
  }
  .badge {
    padding: 0.42em 0.7em;
  }
  .alert-dismissible .close {
    top: -1px;
  }
  .alert-dismissible .close span {
    font-size: 19px;
  }
  .notify-alert {
    width: 90%;
  }
  .chat-box .chat-right-aside .chat .chat-header {
    display: block;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
    margin-top: 15px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 420px) {
  .product-sidebar.open {
    top: -75px;
  }
  .alert-theme {
    font-size: 12px;
    min-width: 300px;
  }
  .alert-theme button {
    right: 30px !important;
  }
  .alert-theme i {
    display: none;
  }
  .alert-theme span + span + span {
    padding: 15px;
  }
  .and-many-more {
    font-size: 37px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
    width: 112px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 112px;
    width: 190px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper > div {
    width: 80px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 80px;
    width: 210px;
  }
  .login-card .login-main .theme-form .link {
    position: unset;
  }
}
@media only screen and (max-width: 360px) {
  .product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group {
    width: 45%;
  }
  .prooduct-details-box .close {
    right: 0;
    top: 5px;
  }
  .prooduct-details-box .btn {
    padding: 4px 8px;
  }
  .prooduct-details-box .d-flex {
    padding: 15px;
    display: block;
  }
  .prooduct-details-box .d-flex .flex-grow-1 {
    margin-top: 15px;
  }
  .datepicker--nav-action {
    width: 20px;
    margin-left: -10px;
    background-color: transparent;
  }
  .datepicker--nav-action path {
    stroke: var(--theme-default);
  }
  .clockpicker-align-top {
    top: 426px !important;
  }
  .note {
    min-width: 250px;
  }
  .datepicker {
    width: 239px;
  }
  .add-post form .m-checkbox-inline label {
    margin-bottom: 8px;
  }
  .add-post form .m-checkbox-inline label:last-child {
    margin-bottom: 0;
  }
  .map-js-height {
    height: 250px;
  }
  .bar-chart-widget .earning-details i {
    right: -73px;
  }
  .status-widget svg {
    width: 18px;
    height: 18px;
  }
  .tabs-scoial.border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px 5px;
    font-size: 14px;
  }
  .user-profile .hovercard .user-image .share-icons {
    right: 6px;
    top: -15px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 25px;
    height: 25px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon i {
    font-size: 14px;
  }
  .social-chat .other-msg {
    margin-left: 15px;
  }
  .social-header h5 span.pull-right {
    display: none;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -74px;
    height: 56px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    bottom: -32px;
    height: 14px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -59px;
    height: 40px;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }
  .custom-card .dashboard-card {
    padding-top: 0;
  }
  .custom-card .card-profile img {
    bottom: 25px;
  }
  .custom-card .card-footer > div h6 {
    font-size: 12px;
  }
  .flot-chart-container {
    height: 230px;
  }
  .map-block {
    height: 250px;
  }
  .img-cropper .docs-tooltip {
    font-size: 11px;
  }
  .img-cropper .docs-toggles .btn-group .btn {
    padding: 0.375rem 0.2rem;
  }
  .blog-box .blog-details p {
    line-height: 1.5;
  }
  .blog-box .blog-details .blog-social li:last-child {
    padding-left: 0;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 10px;
  }
  .button-builder-wrap .btn-lg {
    font-size: 18px;
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  .browser-widget .d-flex i {
    font-size: 52px;
  }
  .browser-widget .d-flex .flex-grow-1 h4 {
    font-size: 20px;
  }
  .bar-chart-widget .bottom-content .num {
    font-size: 17px;
  }
  .search-page .theme-form .input-group .form-control-plaintext {
    padding-left: 10px;
  }
  .search-page .theme-form .input-group .btn {
    padding: 6px 4px;
  }
  .search-page .info-block {
    padding: 20px;
  }
  .wizard-4 ul.anchor {
    padding-top: 15px;
  }
  .wizard-4 .msg-box {
    top: 40px !important;
  }
  .xs-width-100 {
    min-width: 100%;
  }
  .email-wrap .action-wrapper .actions li {
    margin-right: 5px;
  }
  .email-wrap .email-body .attachment .list-inline .list-inline-item {
    padding-right: 5px;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 65px;
    height: 65px;
  }
  .email-wrap .email-right-aside .email-top .dropdown-menu {
    left: -58px;
  }
  .form-builder #components .component .form-group #button1id {
    margin-bottom: 15px;
  }
  .default-according .card .card-header i {
    font-size: 14px;
    top: 24px;
  }
  .default-according .card .btn-link {
    display: block;
    width: 100%;
    padding-left: 17px;
  }
  .star-ratings .stars .title {
    line-height: 1;
  }
  .user-profile .hovercard .social-media a {
    padding: 0 6px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 93% !important;
  }
  .step-container {
    height: 280px !important;
  }
  .sort-panel .d-inline .btn {
    margin-left: 87px;
  }
  .jsgrid .jsgrid-pager-container .jsgrid-pager .jsgrid-pager-page {
    padding: 0.5em 0.62em;
  }
  .chat-box .chat-right-aside {
    flex: 0 0 96%;
    max-width: 96%;
  }
  .chat-box .chat-right-aside .chat .chat-message {
    width: calc(100% - 20px);
  }
  .error-wrapper .error-heading .headline {
    font-size: 100px;
  }
  .lg-toolbar .lg-icon {
    width: 40px;
  }
  .calendar-wrap a.fc-more {
    font-size: 0.59em;
  }
  .calendar-wrap .fc th {
    font-size: 10px;
  }
  .modal-footer {
    display: block;
    text-align: center;
  }
  .order-box .qty li {
    font-size: 14px;
  }
  .order-box .qty li span {
    font-size: 14px;
  }
  .order-box ul li span {
    text-align: right;
  }
  .order-box ul li label {
    text-align: right;
    font-size: 14px;
  }
  .order-box .sub-total li .count {
    font-size: 14px;
  }
  .checkout .text-end {
    text-align: left !important;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) {
  .product-wrapper-grid.list-view .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sidebar-wrapper .back-btn {
    display: none;
  }
}
@media (width: 768px) and (height: 1024px) {
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 210px);
  }
}
/**=====================
     62. Responsive CSS Ends
==========================**/